/* Theme Colors */
/* industrial elements */
/* industrial indicators */
/* industrial moduls */
/* contrast */
.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #354f68 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #5a9956 !important;
}

.border-info {
  border-color: #5299e0 !important;
}

.border-warning {
  border-color: #f2d585 !important;
}

.border-danger {
  border-color: #ff4136 !important;
}

.border-light {
  border-color: #b9d6e6 !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-ice {
  border-color: #eef5fa !important;
}

.border-super-light {
  border-color: #deedf6 !important;
}

.border-steel {
  border-color: #71a3bd !important;
}

.border-ocean {
  border-color: #48748b !important;
}

.border-midnight {
  border-color: #354f68 !important;
}

.border-cosmos {
  border-color: #273a4d !important;
}

.border-deepdark {
  border-color: #0b1b2c !important;
}

.border-indicator-yellow {
  border-color: #fece67 !important;
}

.border-indicator-green {
  border-color: #6ad09b !important;
}

.border-indicator-blue {
  border-color: #75a4d3 !important;
}

.border-indicator-red {
  border-color: #da1e49 !important;
}

.border-indicator-orange {
  border-color: #f48400 !important;
}

.border-indicator-neutral {
  border-color: #b5c0b7 !important;
}

.border-indicator-yellow-light {
  border-color: #ffeeca !important;
}

.border-indicator-green-light {
  border-color: #c3efd8 !important;
}

.border-indicator-blue-light {
  border-color: #deedf6 !important;
}

.border-indicator-red-light {
  border-color: #ffdbe3 !important;
}

.border-indicator-orange-light {
  border-color: #ffe3c2 !important;
}

.border-indicator-neutral-light {
  border-color: #e4e9e5 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle, .module .icon-container {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.module-container {
  padding: 2px;
  margin-right: auto;
  margin-left: auto;
}

.module-container [class^="icon-"],
.module-container [class*=" icon-"] {
  vertical-align: baseline;
}

.module-container .row {
  margin-right: 0;
  margin-left: 0;
}

.module-container .row [class^="col-"],
.module-container .row [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.module-container .module-categorie-frame {
  padding: 2px;
}

.module-container .module-categorie-frame .module-categorie {
  font-family: khand, Arial, Helvetica, sans-serif;
  background-color: #fff;
  border: 5px solid #fff;
  border-radius: 18px;
}

.module-container .module-categorie-frame .module-categorie .header {
  padding-left: 5px;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
}

.module-container .module-categorie-frame .module-categorie .body {
  padding: 3px;
  background-color: #b9d6e6;
  border-radius: 15;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.module-container .module-categorie-frame .module-categorie .header + .body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.module-frame {
  width: 100%;
  height: 106px;
  padding: 3px;
  display: block;
  position: relative;
}

.module-frame:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.module-frame.disabled {
  cursor: not-allowed;
}

.module-frame:hover {
  text-decoration: none;
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

.module-frame.h-1.centered .icon-container {
  width: 55px;
  height: 55px;
  font-size: 40px;
}

.module-frame.h-1.centered .title {
  font-size: 12px;
}

.module-frame.h-1.centered .module {
  padding: 10px;
}

.module-frame.h-1.left-aligned .icon-container {
  display: block;
  width: 64px;
  min-width: 64px;
  height: 64px;
  margin-right: 15px;
  margin-left: unset;
  font-size: 40px;
}

.module-frame.h-1.left-aligned .title {
  display: block;
  font-size: 17px;
  text-align: left;
}

.module-frame.h-1.left-aligned .module {
  display: flex;
  align-items: center;
  padding: 15px;
  text-align: left;
}

.module-frame.h-2 {
  height: 212px;
}

.module-frame.h-2 .icon-container {
  width: 110px;
  height: 110px;
  margin-bottom: 10px;
  font-size: 54px;
}

.module-frame.h-2 .module {
  padding: 30px;
}

.module-frame.h-2 .title {
  font-size: 17px;
}

.module-frame:disabled .module, .module-frame.disabled .module,
fieldset:disabled .module-frame .module {
  pointer-events: none;
  opacity: 0.65;
}

.module-frame.module-frame-h-1-centered .icon-container {
  width: 55px;
  height: 55px;
  font-size: 40px;
}

.module-frame.module-frame-h-1-centered .title {
  font-size: 12px;
}

.module-frame.module-frame-h-1-centered .module {
  padding: 10px;
}

.module-frame.module-frame-h-1-left-aligned .icon-container {
  display: block;
  width: 64px;
  min-width: 64px;
  height: 64px;
  margin-right: 15px;
  margin-left: unset;
  font-size: 40px;
}

.module-frame.module-frame-h-1-left-aligned .title {
  display: block;
  font-size: 17px;
  text-align: left;
}

.module-frame.module-frame-h-1-left-aligned .module {
  display: flex;
  align-items: center;
  padding: 15px;
  text-align: left;
}

.module-frame.module-frame-h-2 {
  height: 203px;
}

.module-frame.module-frame-h-2 .icon-container {
  width: 110px;
  height: 110px;
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: auto;
  font-size: 54px;
}

.module-frame.module-frame-h-2 .module {
  display: block;
  padding: 30px;
  text-align: center;
}

.module-frame.module-frame-h-2 .title {
  display: block;
  font-size: 17px;
  text-align: center;
}

@media (min-width: 576px) {
  .module-frame.module-frame-sm-h-1-centered .icon-container {
    width: 55px;
    height: 55px;
    font-size: 40px;
  }
  .module-frame.module-frame-sm-h-1-centered .title {
    font-size: 12px;
  }
  .module-frame.module-frame-sm-h-1-centered .module {
    padding: 10px;
  }
  .module-frame.module-frame-sm-h-1-left-aligned .icon-container {
    display: block;
    width: 64px;
    min-width: 64px;
    height: 64px;
    margin-right: 15px;
    margin-left: unset;
    font-size: 40px;
  }
  .module-frame.module-frame-sm-h-1-left-aligned .title {
    display: block;
    font-size: 17px;
    text-align: left;
  }
  .module-frame.module-frame-sm-h-1-left-aligned .module {
    display: flex;
    align-items: center;
    padding: 15px;
    text-align: left;
  }
  .module-frame.module-frame-sm-h-2 {
    height: 203px;
  }
  .module-frame.module-frame-sm-h-2 .icon-container {
    width: 110px;
    height: 110px;
    margin-right: auto;
    margin-bottom: 10px;
    margin-left: auto;
    font-size: 54px;
  }
  .module-frame.module-frame-sm-h-2 .module {
    display: block;
    padding: 30px;
    text-align: center;
  }
  .module-frame.module-frame-sm-h-2 .title {
    display: block;
    font-size: 17px;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .module-frame.module-frame-md-h-1-centered .icon-container {
    width: 55px;
    height: 55px;
    font-size: 40px;
  }
  .module-frame.module-frame-md-h-1-centered .title {
    font-size: 12px;
  }
  .module-frame.module-frame-md-h-1-centered .module {
    padding: 10px;
  }
  .module-frame.module-frame-md-h-1-left-aligned .icon-container {
    display: block;
    width: 64px;
    min-width: 64px;
    height: 64px;
    margin-right: 15px;
    margin-left: unset;
    font-size: 40px;
  }
  .module-frame.module-frame-md-h-1-left-aligned .title {
    display: block;
    font-size: 17px;
    text-align: left;
  }
  .module-frame.module-frame-md-h-1-left-aligned .module {
    display: flex;
    align-items: center;
    padding: 15px;
    text-align: left;
  }
  .module-frame.module-frame-md-h-2 {
    height: 203px;
  }
  .module-frame.module-frame-md-h-2 .icon-container {
    width: 110px;
    height: 110px;
    margin-right: auto;
    margin-bottom: 10px;
    margin-left: auto;
    font-size: 54px;
  }
  .module-frame.module-frame-md-h-2 .module {
    display: block;
    padding: 30px;
    text-align: center;
  }
  .module-frame.module-frame-md-h-2 .title {
    display: block;
    font-size: 17px;
    text-align: center;
  }
}

@media (min-width: 992px) {
  .module-frame.module-frame-lg-h-1-centered .icon-container {
    width: 55px;
    height: 55px;
    font-size: 40px;
  }
  .module-frame.module-frame-lg-h-1-centered .title {
    font-size: 12px;
  }
  .module-frame.module-frame-lg-h-1-centered .module {
    padding: 10px;
  }
  .module-frame.module-frame-lg-h-1-left-aligned .icon-container {
    display: block;
    width: 64px;
    min-width: 64px;
    height: 64px;
    margin-right: 15px;
    margin-left: unset;
    font-size: 40px;
  }
  .module-frame.module-frame-lg-h-1-left-aligned .title {
    display: block;
    font-size: 17px;
    text-align: left;
  }
  .module-frame.module-frame-lg-h-1-left-aligned .module {
    display: flex;
    align-items: center;
    padding: 15px;
    text-align: left;
  }
  .module-frame.module-frame-lg-h-2 {
    height: 203px;
  }
  .module-frame.module-frame-lg-h-2 .icon-container {
    width: 110px;
    height: 110px;
    margin-right: auto;
    margin-bottom: 10px;
    margin-left: auto;
    font-size: 54px;
  }
  .module-frame.module-frame-lg-h-2 .module {
    display: block;
    padding: 30px;
    text-align: center;
  }
  .module-frame.module-frame-lg-h-2 .title {
    display: block;
    font-size: 17px;
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .module-frame.module-frame-xl-h-1-centered .icon-container {
    width: 55px;
    height: 55px;
    font-size: 40px;
  }
  .module-frame.module-frame-xl-h-1-centered .title {
    font-size: 12px;
  }
  .module-frame.module-frame-xl-h-1-centered .module {
    padding: 10px;
  }
  .module-frame.module-frame-xl-h-1-left-aligned .icon-container {
    display: block;
    width: 64px;
    min-width: 64px;
    height: 64px;
    margin-right: 15px;
    margin-left: unset;
    font-size: 40px;
  }
  .module-frame.module-frame-xl-h-1-left-aligned .title {
    display: block;
    font-size: 17px;
    text-align: left;
  }
  .module-frame.module-frame-xl-h-1-left-aligned .module {
    display: flex;
    align-items: center;
    padding: 15px;
    text-align: left;
  }
  .module-frame.module-frame-xl-h-2 {
    height: 203px;
  }
  .module-frame.module-frame-xl-h-2 .icon-container {
    width: 110px;
    height: 110px;
    margin-right: auto;
    margin-bottom: 10px;
    margin-left: auto;
    font-size: 54px;
  }
  .module-frame.module-frame-xl-h-2 .module {
    display: block;
    padding: 30px;
    text-align: center;
  }
  .module-frame.module-frame-xl-h-2 .title {
    display: block;
    font-size: 17px;
    text-align: center;
  }
}

@media (min-width: 1570px) {
  .module-frame.module-frame-xxl-h-1-centered .icon-container {
    width: 55px;
    height: 55px;
    font-size: 40px;
  }
  .module-frame.module-frame-xxl-h-1-centered .title {
    font-size: 12px;
  }
  .module-frame.module-frame-xxl-h-1-centered .module {
    padding: 10px;
  }
  .module-frame.module-frame-xxl-h-1-left-aligned .icon-container {
    display: block;
    width: 64px;
    min-width: 64px;
    height: 64px;
    margin-right: 15px;
    margin-left: unset;
    font-size: 40px;
  }
  .module-frame.module-frame-xxl-h-1-left-aligned .title {
    display: block;
    font-size: 17px;
    text-align: left;
  }
  .module-frame.module-frame-xxl-h-1-left-aligned .module {
    display: flex;
    align-items: center;
    padding: 15px;
    text-align: left;
  }
  .module-frame.module-frame-xxl-h-2 {
    height: 203px;
  }
  .module-frame.module-frame-xxl-h-2 .icon-container {
    width: 110px;
    height: 110px;
    margin-right: auto;
    margin-bottom: 10px;
    margin-left: auto;
    font-size: 54px;
  }
  .module-frame.module-frame-xxl-h-2 .module {
    display: block;
    padding: 30px;
    text-align: center;
  }
  .module-frame.module-frame-xxl-h-2 .title {
    display: block;
    font-size: 17px;
    text-align: center;
  }
}

.module-dummy {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #fff;
}

.module {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 10px;
}

.module .icon-container {
  position: relative;
  width: 80px;
  height: 80px;
  margin-right: auto;
  margin-left: auto;
  font-size: 40px;
  text-align: center;
}

.module .icon-container .badge.module-badge {
  position: absolute;
  top: -5px;
  left: 0px;
  font-size: 0.875rem;
}

.module .icon-container i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.module .title {
  font-family: khand, Arial, Helvetica, sans-serif;
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
}

.module:hover {
  text-decoration: none;
}

.module:focus, .module.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(53, 79, 104, 0.25);
}

.module-informations {
  font-size: 0.875rem;
  text-align: center;
  font-weight: bold;
  width: calc(100% - 6px);
  position: absolute;
  bottom: 4px;
  left: 3px;
  right: 3px;
  display: flex;
}

.module-informations .module-information {
  margin-left: 1px;
  margin-right: 1px;
  background-color: rgba(255, 255, 255, 0.9);
}

.module-informations .module-information:first-child {
  border-bottom-left-radius: 10px;
}

.module-informations .module-information:last-child {
  border-bottom-right-radius: 10px;
}

.module-informations .module-information:hover {
  text-decoration: none;
}

.module-ice {
  color: #273a4d;
  background: #eef5fa linear-gradient(180deg, #eef5fa, #eef5fa) repeat-x;
  border-color: #eef5fa;
}

.module-ice .icon-container {
  background-color: #d0e4f1;
}

.module-ice .icon-container.light {
  background-color: white;
}

.module-ice:hover {
  color: #273a4d;
  background: #d0e4f1 linear-gradient(180deg, #d5e6f3, #d0e4f1) repeat-x;
  border-color: color-yig(#d0e4f1);
}

.module-ice:focus, .module-ice.focus {
  color: #273a4d;
  background: #d0e4f1 linear-gradient(180deg, #d5e6f3, #d0e4f1) repeat-x;
  border-color: color-yig(#d0e4f1);
  box-shadow: 0 0 0 0.2rem rgba(208, 217, 224, 0.5);
}

.module-ice:active, .module-ice.active,
.show > .module-ice.dropdown-toggle {
  color: #273a4d;
  background-color: #c7deee;
  background-image: none;
  border-color: #bdd8ec;
}

.module-ice:active:focus, .module-ice.active:focus,
.show > .module-ice.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(208, 217, 224, 0.5);
}

.module-ice:disabled, .module-ice.disabled {
  color: #273a4d;
  background-color: #eef5fa;
  background-image: none;
  border-color: #eef5fa;
}

.module-ice-10 {
  color: #273a4d;
  background: rgba(238, 245, 250, 0.1) linear-gradient(180deg, rgba(238, 245, 250, 0.235), rgba(238, 245, 250, 0.1)) repeat-x;
  border-color: rgba(238, 245, 250, 0.1);
}

.module-ice-10 .icon-container {
  background-color: rgba(208, 228, 241, 0.1);
}

.module-ice-10 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.1);
}

.module-ice-10:hover {
  color: #273a4d;
  background: rgba(208, 228, 241, 0.1) linear-gradient(180deg, rgba(231, 241, 248, 0.235), rgba(208, 228, 241, 0.1)) repeat-x;
  border-color: color-yig(rgba(208, 228, 241, 0.1));
}

.module-ice-10:focus, .module-ice-10.focus {
  color: #273a4d;
  background: rgba(208, 228, 241, 0.1) linear-gradient(180deg, rgba(231, 241, 248, 0.235), rgba(208, 228, 241, 0.1)) repeat-x;
  border-color: color-yig(rgba(208, 228, 241, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(85, 101, 117, 0.5);
}

.module-ice-10:active, .module-ice-10.active,
.show > .module-ice-10.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(199, 222, 238, 0.1);
  background-image: none;
  border-color: rgba(189, 216, 236, 0.1);
}

.module-ice-10:active:focus, .module-ice-10.active:focus,
.show > .module-ice-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(85, 101, 117, 0.5);
}

.module-ice-10:disabled, .module-ice-10.disabled {
  color: #273a4d;
  background-color: rgba(238, 245, 250, 0.1);
  background-image: none;
  border-color: rgba(238, 245, 250, 0.1);
}

.module-ice-20 {
  color: #273a4d;
  background: rgba(238, 245, 250, 0.2) linear-gradient(180deg, rgba(238, 245, 250, 0.32), rgba(238, 245, 250, 0.2)) repeat-x;
  border-color: rgba(238, 245, 250, 0.2);
}

.module-ice-20 .icon-container {
  background-color: rgba(208, 228, 241, 0.2);
}

.module-ice-20 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.2);
}

.module-ice-20:hover {
  color: #273a4d;
  background: rgba(208, 228, 241, 0.2) linear-gradient(180deg, rgba(227, 238, 247, 0.32), rgba(208, 228, 241, 0.2)) repeat-x;
  border-color: color-yig(rgba(208, 228, 241, 0.2));
}

.module-ice-20:focus, .module-ice-20.focus {
  color: #273a4d;
  background: rgba(208, 228, 241, 0.2) linear-gradient(180deg, rgba(227, 238, 247, 0.32), rgba(208, 228, 241, 0.2)) repeat-x;
  border-color: color-yig(rgba(208, 228, 241, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(116, 130, 144, 0.5);
}

.module-ice-20:active, .module-ice-20.active,
.show > .module-ice-20.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(199, 222, 238, 0.2);
  background-image: none;
  border-color: rgba(189, 216, 236, 0.2);
}

.module-ice-20:active:focus, .module-ice-20.active:focus,
.show > .module-ice-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(116, 130, 144, 0.5);
}

.module-ice-20:disabled, .module-ice-20.disabled {
  color: #273a4d;
  background-color: rgba(238, 245, 250, 0.2);
  background-image: none;
  border-color: rgba(238, 245, 250, 0.2);
}

.module-ice-30 {
  color: #273a4d;
  background: rgba(238, 245, 250, 0.3) linear-gradient(180deg, rgba(238, 245, 250, 0.405), rgba(238, 245, 250, 0.3)) repeat-x;
  border-color: rgba(238, 245, 250, 0.3);
}

.module-ice-30 .icon-container {
  background-color: rgba(208, 228, 241, 0.3);
}

.module-ice-30 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.3);
}

.module-ice-30:hover {
  color: #273a4d;
  background: rgba(208, 228, 241, 0.3) linear-gradient(180deg, rgba(223, 236, 246, 0.405), rgba(208, 228, 241, 0.3)) repeat-x;
  border-color: color-yig(rgba(208, 228, 241, 0.3));
}

.module-ice-30:focus, .module-ice-30.focus {
  color: #273a4d;
  background: rgba(208, 228, 241, 0.3) linear-gradient(180deg, rgba(223, 236, 246, 0.405), rgba(208, 228, 241, 0.3)) repeat-x;
  border-color: color-yig(rgba(208, 228, 241, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(139, 152, 164, 0.5);
}

.module-ice-30:active, .module-ice-30.active,
.show > .module-ice-30.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(199, 222, 238, 0.3);
  background-image: none;
  border-color: rgba(189, 216, 236, 0.3);
}

.module-ice-30:active:focus, .module-ice-30.active:focus,
.show > .module-ice-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(139, 152, 164, 0.5);
}

.module-ice-30:disabled, .module-ice-30.disabled {
  color: #273a4d;
  background-color: rgba(238, 245, 250, 0.3);
  background-image: none;
  border-color: rgba(238, 245, 250, 0.3);
}

.module-ice-40 {
  color: #273a4d;
  background: rgba(238, 245, 250, 0.4) linear-gradient(180deg, rgba(238, 245, 250, 0.49), rgba(238, 245, 250, 0.4)) repeat-x;
  border-color: rgba(238, 245, 250, 0.4);
}

.module-ice-40 .icon-container {
  background-color: rgba(208, 228, 241, 0.4);
}

.module-ice-40 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.4);
}

.module-ice-40:hover {
  color: #273a4d;
  background: rgba(208, 228, 241, 0.4) linear-gradient(180deg, rgba(221, 235, 245, 0.49), rgba(208, 228, 241, 0.4)) repeat-x;
  border-color: color-yig(rgba(208, 228, 241, 0.4));
}

.module-ice-40:focus, .module-ice-40.focus {
  color: #273a4d;
  background: rgba(208, 228, 241, 0.4) linear-gradient(180deg, rgba(221, 235, 245, 0.49), rgba(208, 228, 241, 0.4)) repeat-x;
  border-color: color-yig(rgba(208, 228, 241, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(156, 168, 178, 0.5);
}

.module-ice-40:active, .module-ice-40.active,
.show > .module-ice-40.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(199, 222, 238, 0.4);
  background-image: none;
  border-color: rgba(189, 216, 236, 0.4);
}

.module-ice-40:active:focus, .module-ice-40.active:focus,
.show > .module-ice-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 168, 178, 0.5);
}

.module-ice-40:disabled, .module-ice-40.disabled {
  color: #273a4d;
  background-color: rgba(238, 245, 250, 0.4);
  background-image: none;
  border-color: rgba(238, 245, 250, 0.4);
}

.module-ice-50 {
  color: #273a4d;
  background: rgba(238, 245, 250, 0.5) linear-gradient(180deg, rgba(238, 245, 250, 0.575), rgba(238, 245, 250, 0.5)) repeat-x;
  border-color: rgba(238, 245, 250, 0.5);
}

.module-ice-50 .icon-container {
  background-color: rgba(208, 228, 241, 0.5);
}

.module-ice-50 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.5);
}

.module-ice-50:hover {
  color: #273a4d;
  background: rgba(208, 228, 241, 0.5) linear-gradient(180deg, rgba(219, 234, 244, 0.575), rgba(208, 228, 241, 0.5)) repeat-x;
  border-color: color-yig(rgba(208, 228, 241, 0.5));
}

.module-ice-50:focus, .module-ice-50.focus {
  color: #273a4d;
  background: rgba(208, 228, 241, 0.5) linear-gradient(180deg, rgba(219, 234, 244, 0.575), rgba(208, 228, 241, 0.5)) repeat-x;
  border-color: color-yig(rgba(208, 228, 241, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(169, 180, 190, 0.5);
}

.module-ice-50:active, .module-ice-50.active,
.show > .module-ice-50.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(199, 222, 238, 0.5);
  background-image: none;
  border-color: rgba(189, 216, 236, 0.5);
}

.module-ice-50:active:focus, .module-ice-50.active:focus,
.show > .module-ice-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(169, 180, 190, 0.5);
}

.module-ice-50:disabled, .module-ice-50.disabled {
  color: #273a4d;
  background-color: rgba(238, 245, 250, 0.5);
  background-image: none;
  border-color: rgba(238, 245, 250, 0.5);
}

.module-ice-60 {
  color: #273a4d;
  background: rgba(238, 245, 250, 0.6) linear-gradient(180deg, rgba(238, 245, 250, 0.66), rgba(238, 245, 250, 0.6)) repeat-x;
  border-color: rgba(238, 245, 250, 0.6);
}

.module-ice-60 .icon-container {
  background-color: rgba(208, 228, 241, 0.6);
}

.module-ice-60 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.6);
}

.module-ice-60:hover {
  color: #273a4d;
  background: rgba(208, 228, 241, 0.6) linear-gradient(180deg, rgba(217, 233, 244, 0.66), rgba(208, 228, 241, 0.6)) repeat-x;
  border-color: color-yig(rgba(208, 228, 241, 0.6));
}

.module-ice-60:focus, .module-ice-60.focus {
  color: #273a4d;
  background: rgba(208, 228, 241, 0.6) linear-gradient(180deg, rgba(217, 233, 244, 0.66), rgba(208, 228, 241, 0.6)) repeat-x;
  border-color: color-yig(rgba(208, 228, 241, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(180, 190, 200, 0.5);
}

.module-ice-60:active, .module-ice-60.active,
.show > .module-ice-60.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(199, 222, 238, 0.6);
  background-image: none;
  border-color: rgba(189, 216, 236, 0.6);
}

.module-ice-60:active:focus, .module-ice-60.active:focus,
.show > .module-ice-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(180, 190, 200, 0.5);
}

.module-ice-60:disabled, .module-ice-60.disabled {
  color: #273a4d;
  background-color: rgba(238, 245, 250, 0.6);
  background-image: none;
  border-color: rgba(238, 245, 250, 0.6);
}

.module-ice-70 {
  color: #273a4d;
  background: rgba(238, 245, 250, 0.7) linear-gradient(180deg, rgba(238, 245, 250, 0.745), rgba(238, 245, 250, 0.7)) repeat-x;
  border-color: rgba(238, 245, 250, 0.7);
}

.module-ice-70 .icon-container {
  background-color: rgba(208, 228, 241, 0.7);
}

.module-ice-70 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.7);
}

.module-ice-70:hover {
  color: #273a4d;
  background: rgba(208, 228, 241, 0.7) linear-gradient(180deg, rgba(216, 232, 243, 0.745), rgba(208, 228, 241, 0.7)) repeat-x;
  border-color: color-yig(rgba(208, 228, 241, 0.7));
}

.module-ice-70:focus, .module-ice-70.focus {
  color: #273a4d;
  background: rgba(208, 228, 241, 0.7) linear-gradient(180deg, rgba(216, 232, 243, 0.745), rgba(208, 228, 241, 0.7)) repeat-x;
  border-color: color-yig(rgba(208, 228, 241, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(189, 199, 207, 0.5);
}

.module-ice-70:active, .module-ice-70.active,
.show > .module-ice-70.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(199, 222, 238, 0.7);
  background-image: none;
  border-color: rgba(189, 216, 236, 0.7);
}

.module-ice-70:active:focus, .module-ice-70.active:focus,
.show > .module-ice-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(189, 199, 207, 0.5);
}

.module-ice-70:disabled, .module-ice-70.disabled {
  color: #273a4d;
  background-color: rgba(238, 245, 250, 0.7);
  background-image: none;
  border-color: rgba(238, 245, 250, 0.7);
}

.module-ice-80 {
  color: #273a4d;
  background: rgba(238, 245, 250, 0.8) linear-gradient(180deg, rgba(238, 245, 250, 0.83), rgba(238, 245, 250, 0.8)) repeat-x;
  border-color: rgba(238, 245, 250, 0.8);
}

.module-ice-80 .icon-container {
  background-color: rgba(208, 228, 241, 0.8);
}

.module-ice-80 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.8);
}

.module-ice-80:hover {
  color: #273a4d;
  background: rgba(208, 228, 241, 0.8) linear-gradient(180deg, rgba(215, 231, 243, 0.83), rgba(208, 228, 241, 0.8)) repeat-x;
  border-color: color-yig(rgba(208, 228, 241, 0.8));
}

.module-ice-80:focus, .module-ice-80.focus {
  color: #273a4d;
  background: rgba(208, 228, 241, 0.8) linear-gradient(180deg, rgba(215, 231, 243, 0.83), rgba(208, 228, 241, 0.8)) repeat-x;
  border-color: color-yig(rgba(208, 228, 241, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(196, 206, 214, 0.5);
}

.module-ice-80:active, .module-ice-80.active,
.show > .module-ice-80.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(199, 222, 238, 0.8);
  background-image: none;
  border-color: rgba(189, 216, 236, 0.8);
}

.module-ice-80:active:focus, .module-ice-80.active:focus,
.show > .module-ice-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 206, 214, 0.5);
}

.module-ice-80:disabled, .module-ice-80.disabled {
  color: #273a4d;
  background-color: rgba(238, 245, 250, 0.8);
  background-image: none;
  border-color: rgba(238, 245, 250, 0.8);
}

.module-ice-90 {
  color: #273a4d;
  background: rgba(238, 245, 250, 0.9) linear-gradient(180deg, rgba(238, 245, 250, 0.915), rgba(238, 245, 250, 0.9)) repeat-x;
  border-color: rgba(238, 245, 250, 0.9);
}

.module-ice-90 .icon-container {
  background-color: rgba(208, 228, 241, 0.9);
}

.module-ice-90 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.9);
}

.module-ice-90:hover {
  color: #273a4d;
  background: rgba(208, 228, 241, 0.9) linear-gradient(180deg, rgba(214, 231, 243, 0.915), rgba(208, 228, 241, 0.9)) repeat-x;
  border-color: color-yig(rgba(208, 228, 241, 0.9));
}

.module-ice-90:focus, .module-ice-90.focus {
  color: #273a4d;
  background: rgba(208, 228, 241, 0.9) linear-gradient(180deg, rgba(214, 231, 243, 0.915), rgba(208, 228, 241, 0.9)) repeat-x;
  border-color: color-yig(rgba(208, 228, 241, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(203, 212, 219, 0.5);
}

.module-ice-90:active, .module-ice-90.active,
.show > .module-ice-90.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(199, 222, 238, 0.9);
  background-image: none;
  border-color: rgba(189, 216, 236, 0.9);
}

.module-ice-90:active:focus, .module-ice-90.active:focus,
.show > .module-ice-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(203, 212, 219, 0.5);
}

.module-ice-90:disabled, .module-ice-90.disabled {
  color: #273a4d;
  background-color: rgba(238, 245, 250, 0.9);
  background-image: none;
  border-color: rgba(238, 245, 250, 0.9);
}

.module-super-light {
  color: #273a4d;
  background: #deedf6 linear-gradient(180deg, #e0eef7, #deedf6) repeat-x;
  border-color: #deedf6;
}

.module-super-light .icon-container {
  background-color: #c0ddee;
}

.module-super-light .icon-container.light {
  background-color: white;
}

.module-super-light:hover {
  color: #273a4d;
  background: #c0ddee linear-gradient(180deg, #c7e0f0, #c0ddee) repeat-x;
  border-color: color-yig(#c0ddee);
}

.module-super-light:focus, .module-super-light.focus {
  color: #273a4d;
  background: #c0ddee linear-gradient(180deg, #c7e0f0, #c0ddee) repeat-x;
  border-color: color-yig(#c0ddee);
  box-shadow: 0 0 0 0.2rem rgba(195, 210, 221, 0.5);
}

.module-super-light:active, .module-super-light.active,
.show > .module-super-light.dropdown-toggle {
  color: #273a4d;
  background-color: #b6d7eb;
  background-image: none;
  border-color: #acd2e8;
}

.module-super-light:active:focus, .module-super-light.active:focus,
.show > .module-super-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(195, 210, 221, 0.5);
}

.module-super-light:disabled, .module-super-light.disabled {
  color: #273a4d;
  background-color: #deedf6;
  background-image: none;
  border-color: #deedf6;
}

.module-super-light-10 {
  color: #273a4d;
  background: rgba(222, 237, 246, 0.1) linear-gradient(180deg, rgba(234, 243, 249, 0.235), rgba(222, 237, 246, 0.1)) repeat-x;
  border-color: rgba(222, 237, 246, 0.1);
}

.module-super-light-10 .icon-container {
  background-color: rgba(192, 221, 238, 0.1);
}

.module-super-light-10 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.1);
}

.module-super-light-10:hover {
  color: #273a4d;
  background: rgba(192, 221, 238, 0.1) linear-gradient(180deg, rgba(227, 239, 247, 0.235), rgba(192, 221, 238, 0.1)) repeat-x;
  border-color: color-yig(rgba(192, 221, 238, 0.1));
}

.module-super-light-10:focus, .module-super-light-10.focus {
  color: #273a4d;
  background: rgba(192, 221, 238, 0.1) linear-gradient(180deg, rgba(227, 239, 247, 0.235), rgba(192, 221, 238, 0.1)) repeat-x;
  border-color: color-yig(rgba(192, 221, 238, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(81, 99, 116, 0.5);
}

.module-super-light-10:active, .module-super-light-10.active,
.show > .module-super-light-10.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(182, 215, 235, 0.1);
  background-image: none;
  border-color: rgba(172, 210, 232, 0.1);
}

.module-super-light-10:active:focus, .module-super-light-10.active:focus,
.show > .module-super-light-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(81, 99, 116, 0.5);
}

.module-super-light-10:disabled, .module-super-light-10.disabled {
  color: #273a4d;
  background-color: rgba(222, 237, 246, 0.1);
  background-image: none;
  border-color: rgba(222, 237, 246, 0.1);
}

.module-super-light-20 {
  color: #273a4d;
  background: rgba(222, 237, 246, 0.2) linear-gradient(180deg, rgba(232, 242, 248, 0.32), rgba(222, 237, 246, 0.2)) repeat-x;
  border-color: rgba(222, 237, 246, 0.2);
}

.module-super-light-20 .icon-container {
  background-color: rgba(192, 221, 238, 0.2);
}

.module-super-light-20 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.2);
}

.module-super-light-20:hover {
  color: #273a4d;
  background: rgba(192, 221, 238, 0.2) linear-gradient(180deg, rgba(220, 236, 245, 0.32), rgba(192, 221, 238, 0.2)) repeat-x;
  border-color: color-yig(rgba(192, 221, 238, 0.2));
}

.module-super-light-20:focus, .module-super-light-20.focus {
  color: #273a4d;
  background: rgba(192, 221, 238, 0.2) linear-gradient(180deg, rgba(220, 236, 245, 0.32), rgba(192, 221, 238, 0.2)) repeat-x;
  border-color: color-yig(rgba(192, 221, 238, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(110, 127, 142, 0.5);
}

.module-super-light-20:active, .module-super-light-20.active,
.show > .module-super-light-20.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(182, 215, 235, 0.2);
  background-image: none;
  border-color: rgba(172, 210, 232, 0.2);
}

.module-super-light-20:active:focus, .module-super-light-20.active:focus,
.show > .module-super-light-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(110, 127, 142, 0.5);
}

.module-super-light-20:disabled, .module-super-light-20.disabled {
  color: #273a4d;
  background-color: rgba(222, 237, 246, 0.2);
  background-image: none;
  border-color: rgba(222, 237, 246, 0.2);
}

.module-super-light-30 {
  color: #273a4d;
  background: rgba(222, 237, 246, 0.3) linear-gradient(180deg, rgba(230, 241, 248, 0.405), rgba(222, 237, 246, 0.3)) repeat-x;
  border-color: rgba(222, 237, 246, 0.3);
}

.module-super-light-30 .icon-container {
  background-color: rgba(192, 221, 238, 0.3);
}

.module-super-light-30 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.3);
}

.module-super-light-30:hover {
  color: #273a4d;
  background: rgba(192, 221, 238, 0.3) linear-gradient(180deg, rgba(215, 233, 244, 0.405), rgba(192, 221, 238, 0.3)) repeat-x;
  border-color: color-yig(rgba(192, 221, 238, 0.3));
}

.module-super-light-30:focus, .module-super-light-30.focus {
  color: #273a4d;
  background: rgba(192, 221, 238, 0.3) linear-gradient(180deg, rgba(215, 233, 244, 0.405), rgba(192, 221, 238, 0.3)) repeat-x;
  border-color: color-yig(rgba(192, 221, 238, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(131, 148, 162, 0.5);
}

.module-super-light-30:active, .module-super-light-30.active,
.show > .module-super-light-30.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(182, 215, 235, 0.3);
  background-image: none;
  border-color: rgba(172, 210, 232, 0.3);
}

.module-super-light-30:active:focus, .module-super-light-30.active:focus,
.show > .module-super-light-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(131, 148, 162, 0.5);
}

.module-super-light-30:disabled, .module-super-light-30.disabled {
  color: #273a4d;
  background-color: rgba(222, 237, 246, 0.3);
  background-image: none;
  border-color: rgba(222, 237, 246, 0.3);
}

.module-super-light-40 {
  color: #273a4d;
  background: rgba(222, 237, 246, 0.4) linear-gradient(180deg, rgba(229, 240, 248, 0.49), rgba(222, 237, 246, 0.4)) repeat-x;
  border-color: rgba(222, 237, 246, 0.4);
}

.module-super-light-40 .icon-container {
  background-color: rgba(192, 221, 238, 0.4);
}

.module-super-light-40 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.4);
}

.module-super-light-40:hover {
  color: #273a4d;
  background: rgba(192, 221, 238, 0.4) linear-gradient(180deg, rgba(211, 231, 243, 0.49), rgba(192, 221, 238, 0.4)) repeat-x;
  border-color: color-yig(rgba(192, 221, 238, 0.4));
}

.module-super-light-40:focus, .module-super-light-40.focus {
  color: #273a4d;
  background: rgba(192, 221, 238, 0.4) linear-gradient(180deg, rgba(211, 231, 243, 0.49), rgba(192, 221, 238, 0.4)) repeat-x;
  border-color: color-yig(rgba(192, 221, 238, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(146, 163, 176, 0.5);
}

.module-super-light-40:active, .module-super-light-40.active,
.show > .module-super-light-40.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(182, 215, 235, 0.4);
  background-image: none;
  border-color: rgba(172, 210, 232, 0.4);
}

.module-super-light-40:active:focus, .module-super-light-40.active:focus,
.show > .module-super-light-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(146, 163, 176, 0.5);
}

.module-super-light-40:disabled, .module-super-light-40.disabled {
  color: #273a4d;
  background-color: rgba(222, 237, 246, 0.4);
  background-image: none;
  border-color: rgba(222, 237, 246, 0.4);
}

.module-super-light-50 {
  color: #273a4d;
  background: rgba(222, 237, 246, 0.5) linear-gradient(180deg, rgba(228, 240, 247, 0.575), rgba(222, 237, 246, 0.5)) repeat-x;
  border-color: rgba(222, 237, 246, 0.5);
}

.module-super-light-50 .icon-container {
  background-color: rgba(192, 221, 238, 0.5);
}

.module-super-light-50 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.5);
}

.module-super-light-50:hover {
  color: #273a4d;
  background: rgba(192, 221, 238, 0.5) linear-gradient(180deg, rgba(208, 229, 242, 0.575), rgba(192, 221, 238, 0.5)) repeat-x;
  border-color: color-yig(rgba(192, 221, 238, 0.5));
}

.module-super-light-50:focus, .module-super-light-50.focus {
  color: #273a4d;
  background: rgba(192, 221, 238, 0.5) linear-gradient(180deg, rgba(208, 229, 242, 0.575), rgba(192, 221, 238, 0.5)) repeat-x;
  border-color: color-yig(rgba(192, 221, 238, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(159, 175, 188, 0.5);
}

.module-super-light-50:active, .module-super-light-50.active,
.show > .module-super-light-50.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(182, 215, 235, 0.5);
  background-image: none;
  border-color: rgba(172, 210, 232, 0.5);
}

.module-super-light-50:active:focus, .module-super-light-50.active:focus,
.show > .module-super-light-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(159, 175, 188, 0.5);
}

.module-super-light-50:disabled, .module-super-light-50.disabled {
  color: #273a4d;
  background-color: rgba(222, 237, 246, 0.5);
  background-image: none;
  border-color: rgba(222, 237, 246, 0.5);
}

.module-super-light-60 {
  color: #273a4d;
  background: rgba(222, 237, 246, 0.6) linear-gradient(180deg, rgba(227, 239, 247, 0.66), rgba(222, 237, 246, 0.6)) repeat-x;
  border-color: rgba(222, 237, 246, 0.6);
}

.module-super-light-60 .icon-container {
  background-color: rgba(192, 221, 238, 0.6);
}

.module-super-light-60 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.6);
}

.module-super-light-60:hover {
  color: #273a4d;
  background: rgba(192, 221, 238, 0.6) linear-gradient(180deg, rgba(205, 228, 241, 0.66), rgba(192, 221, 238, 0.6)) repeat-x;
  border-color: color-yig(rgba(192, 221, 238, 0.6));
}

.module-super-light-60:focus, .module-super-light-60.focus {
  color: #273a4d;
  background: rgba(192, 221, 238, 0.6) linear-gradient(180deg, rgba(205, 228, 241, 0.66), rgba(192, 221, 238, 0.6)) repeat-x;
  border-color: color-yig(rgba(192, 221, 238, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(169, 185, 197, 0.5);
}

.module-super-light-60:active, .module-super-light-60.active,
.show > .module-super-light-60.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(182, 215, 235, 0.6);
  background-image: none;
  border-color: rgba(172, 210, 232, 0.6);
}

.module-super-light-60:active:focus, .module-super-light-60.active:focus,
.show > .module-super-light-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(169, 185, 197, 0.5);
}

.module-super-light-60:disabled, .module-super-light-60.disabled {
  color: #273a4d;
  background-color: rgba(222, 237, 246, 0.6);
  background-image: none;
  border-color: rgba(222, 237, 246, 0.6);
}

.module-super-light-70 {
  color: #273a4d;
  background: rgba(222, 237, 246, 0.7) linear-gradient(180deg, rgba(226, 239, 247, 0.745), rgba(222, 237, 246, 0.7)) repeat-x;
  border-color: rgba(222, 237, 246, 0.7);
}

.module-super-light-70 .icon-container {
  background-color: rgba(192, 221, 238, 0.7);
}

.module-super-light-70 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.7);
}

.module-super-light-70:hover {
  color: #273a4d;
  background: rgba(192, 221, 238, 0.7) linear-gradient(180deg, rgba(203, 227, 241, 0.745), rgba(192, 221, 238, 0.7)) repeat-x;
  border-color: color-yig(rgba(192, 221, 238, 0.7));
}

.module-super-light-70:focus, .module-super-light-70.focus {
  color: #273a4d;
  background: rgba(192, 221, 238, 0.7) linear-gradient(180deg, rgba(203, 227, 241, 0.745), rgba(192, 221, 238, 0.7)) repeat-x;
  border-color: color-yig(rgba(192, 221, 238, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(177, 193, 204, 0.5);
}

.module-super-light-70:active, .module-super-light-70.active,
.show > .module-super-light-70.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(182, 215, 235, 0.7);
  background-image: none;
  border-color: rgba(172, 210, 232, 0.7);
}

.module-super-light-70:active:focus, .module-super-light-70.active:focus,
.show > .module-super-light-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(177, 193, 204, 0.5);
}

.module-super-light-70:disabled, .module-super-light-70.disabled {
  color: #273a4d;
  background-color: rgba(222, 237, 246, 0.7);
  background-image: none;
  border-color: rgba(222, 237, 246, 0.7);
}

.module-super-light-80 {
  color: #273a4d;
  background: rgba(222, 237, 246, 0.8) linear-gradient(180deg, rgba(225, 239, 247, 0.83), rgba(222, 237, 246, 0.8)) repeat-x;
  border-color: rgba(222, 237, 246, 0.8);
}

.module-super-light-80 .icon-container {
  background-color: rgba(192, 221, 238, 0.8);
}

.module-super-light-80 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.8);
}

.module-super-light-80:hover {
  color: #273a4d;
  background: rgba(192, 221, 238, 0.8) linear-gradient(180deg, rgba(202, 226, 240, 0.83), rgba(192, 221, 238, 0.8)) repeat-x;
  border-color: color-yig(rgba(192, 221, 238, 0.8));
}

.module-super-light-80:focus, .module-super-light-80.focus {
  color: #273a4d;
  background: rgba(192, 221, 238, 0.8) linear-gradient(180deg, rgba(202, 226, 240, 0.83), rgba(192, 221, 238, 0.8)) repeat-x;
  border-color: color-yig(rgba(192, 221, 238, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(184, 200, 211, 0.5);
}

.module-super-light-80:active, .module-super-light-80.active,
.show > .module-super-light-80.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(182, 215, 235, 0.8);
  background-image: none;
  border-color: rgba(172, 210, 232, 0.8);
}

.module-super-light-80:active:focus, .module-super-light-80.active:focus,
.show > .module-super-light-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 200, 211, 0.5);
}

.module-super-light-80:disabled, .module-super-light-80.disabled {
  color: #273a4d;
  background-color: rgba(222, 237, 246, 0.8);
  background-image: none;
  border-color: rgba(222, 237, 246, 0.8);
}

.module-super-light-90 {
  color: #273a4d;
  background: rgba(222, 237, 246, 0.9) linear-gradient(180deg, rgba(225, 238, 247, 0.915), rgba(222, 237, 246, 0.9)) repeat-x;
  border-color: rgba(222, 237, 246, 0.9);
}

.module-super-light-90 .icon-container {
  background-color: rgba(192, 221, 238, 0.9);
}

.module-super-light-90 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.9);
}

.module-super-light-90:hover {
  color: #273a4d;
  background: rgba(192, 221, 238, 0.9) linear-gradient(180deg, rgba(200, 225, 240, 0.915), rgba(192, 221, 238, 0.9)) repeat-x;
  border-color: color-yig(rgba(192, 221, 238, 0.9));
}

.module-super-light-90:focus, .module-super-light-90.focus {
  color: #273a4d;
  background: rgba(192, 221, 238, 0.9) linear-gradient(180deg, rgba(200, 225, 240, 0.915), rgba(192, 221, 238, 0.9)) repeat-x;
  border-color: color-yig(rgba(192, 221, 238, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(190, 205, 216, 0.5);
}

.module-super-light-90:active, .module-super-light-90.active,
.show > .module-super-light-90.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(182, 215, 235, 0.9);
  background-image: none;
  border-color: rgba(172, 210, 232, 0.9);
}

.module-super-light-90:active:focus, .module-super-light-90.active:focus,
.show > .module-super-light-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(190, 205, 216, 0.5);
}

.module-super-light-90:disabled, .module-super-light-90.disabled {
  color: #273a4d;
  background-color: rgba(222, 237, 246, 0.9);
  background-image: none;
  border-color: rgba(222, 237, 246, 0.9);
}

.module-light {
  color: #273a4d;
  background: #b9d6e6 linear-gradient(180deg, #c1dbe9, #b9d6e6) repeat-x;
  border-color: #b9d6e6;
}

.module-light .icon-container {
  background-color: #9dc5dc;
}

.module-light .icon-container.light {
  background-color: #f1f7fa;
}

.module-light:hover {
  color: #273a4d;
  background: #9dc5dc linear-gradient(180deg, #a9cde0, #9dc5dc) repeat-x;
  border-color: color-yig(#9dc5dc);
}

.module-light:focus, .module-light.focus {
  color: #273a4d;
  background: #9dc5dc linear-gradient(180deg, #a9cde0, #9dc5dc) repeat-x;
  border-color: color-yig(#9dc5dc);
  box-shadow: 0 0 0 0.2rem rgba(163, 191, 207, 0.5);
}

.module-light:active, .module-light.active,
.show > .module-light.dropdown-toggle {
  color: #273a4d;
  background-color: #93c0d9;
  background-image: none;
  border-color: #8abad5;
}

.module-light:active:focus, .module-light.active:focus,
.show > .module-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(163, 191, 207, 0.5);
}

.module-light:disabled, .module-light.disabled {
  color: #273a4d;
  background-color: #b9d6e6;
  background-image: none;
  border-color: #b9d6e6;
}

.module-light-10 {
  color: #273a4d;
  background: rgba(185, 214, 230, 0.1) linear-gradient(180deg, rgba(226, 238, 245, 0.235), rgba(185, 214, 230, 0.1)) repeat-x;
  border-color: rgba(185, 214, 230, 0.1);
}

.module-light-10 .icon-container {
  background-color: rgba(157, 197, 220, 0.1);
}

.module-light-10 .icon-container.light {
  background-color: rgba(241, 247, 250, 0.1);
}

.module-light-10:hover {
  color: #273a4d;
  background: rgba(157, 197, 220, 0.1) linear-gradient(180deg, rgba(219, 234, 243, 0.235), rgba(157, 197, 220, 0.1)) repeat-x;
  border-color: color-yig(rgba(157, 197, 220, 0.1));
}

.module-light-10:focus, .module-light-10.focus {
  color: #273a4d;
  background: rgba(157, 197, 220, 0.1) linear-gradient(180deg, rgba(219, 234, 243, 0.235), rgba(157, 197, 220, 0.1)) repeat-x;
  border-color: color-yig(rgba(157, 197, 220, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(73, 94, 112, 0.5);
}

.module-light-10:active, .module-light-10.active,
.show > .module-light-10.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(147, 192, 217, 0.1);
  background-image: none;
  border-color: rgba(138, 186, 213, 0.1);
}

.module-light-10:active:focus, .module-light-10.active:focus,
.show > .module-light-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(73, 94, 112, 0.5);
}

.module-light-10:disabled, .module-light-10.disabled {
  color: #273a4d;
  background-color: rgba(185, 214, 230, 0.1);
  background-image: none;
  border-color: rgba(185, 214, 230, 0.1);
}

.module-light-20 {
  color: #273a4d;
  background: rgba(185, 214, 230, 0.2) linear-gradient(180deg, rgba(218, 233, 242, 0.32), rgba(185, 214, 230, 0.2)) repeat-x;
  border-color: rgba(185, 214, 230, 0.2);
}

.module-light-20 .icon-container {
  background-color: rgba(157, 197, 220, 0.2);
}

.module-light-20 .icon-container.light {
  background-color: rgba(241, 247, 250, 0.2);
}

.module-light-20:hover {
  color: #273a4d;
  background: rgba(157, 197, 220, 0.2) linear-gradient(180deg, rgba(207, 227, 238, 0.32), rgba(157, 197, 220, 0.2)) repeat-x;
  border-color: color-yig(rgba(157, 197, 220, 0.2));
}

.module-light-20:focus, .module-light-20.focus {
  color: #273a4d;
  background: rgba(157, 197, 220, 0.2) linear-gradient(180deg, rgba(207, 227, 238, 0.32), rgba(157, 197, 220, 0.2)) repeat-x;
  border-color: color-yig(rgba(157, 197, 220, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(95, 118, 136, 0.5);
}

.module-light-20:active, .module-light-20.active,
.show > .module-light-20.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(147, 192, 217, 0.2);
  background-image: none;
  border-color: rgba(138, 186, 213, 0.2);
}

.module-light-20:active:focus, .module-light-20.active:focus,
.show > .module-light-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 118, 136, 0.5);
}

.module-light-20:disabled, .module-light-20.disabled {
  color: #273a4d;
  background-color: rgba(185, 214, 230, 0.2);
  background-image: none;
  border-color: rgba(185, 214, 230, 0.2);
}

.module-light-30 {
  color: #273a4d;
  background: rgba(185, 214, 230, 0.3) linear-gradient(180deg, rgba(212, 230, 240, 0.405), rgba(185, 214, 230, 0.3)) repeat-x;
  border-color: rgba(185, 214, 230, 0.3);
}

.module-light-30 .icon-container {
  background-color: rgba(157, 197, 220, 0.3);
}

.module-light-30 .icon-container.light {
  background-color: rgba(241, 247, 250, 0.3);
}

.module-light-30:hover {
  color: #273a4d;
  background: rgba(157, 197, 220, 0.3) linear-gradient(180deg, rgba(197, 221, 235, 0.405), rgba(157, 197, 220, 0.3)) repeat-x;
  border-color: color-yig(rgba(157, 197, 220, 0.3));
}

.module-light-30:focus, .module-light-30.focus {
  color: #273a4d;
  background: rgba(157, 197, 220, 0.3) linear-gradient(180deg, rgba(197, 221, 235, 0.405), rgba(157, 197, 220, 0.3)) repeat-x;
  border-color: color-yig(rgba(157, 197, 220, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(112, 136, 154, 0.5);
}

.module-light-30:active, .module-light-30.active,
.show > .module-light-30.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(147, 192, 217, 0.3);
  background-image: none;
  border-color: rgba(138, 186, 213, 0.3);
}

.module-light-30:active:focus, .module-light-30.active:focus,
.show > .module-light-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(112, 136, 154, 0.5);
}

.module-light-30:disabled, .module-light-30.disabled {
  color: #273a4d;
  background-color: rgba(185, 214, 230, 0.3);
  background-image: none;
  border-color: rgba(185, 214, 230, 0.3);
}

.module-light-40 {
  color: #273a4d;
  background: rgba(185, 214, 230, 0.4) linear-gradient(180deg, rgba(207, 227, 238, 0.49), rgba(185, 214, 230, 0.4)) repeat-x;
  border-color: rgba(185, 214, 230, 0.4);
}

.module-light-40 .icon-container {
  background-color: rgba(157, 197, 220, 0.4);
}

.module-light-40 .icon-container.light {
  background-color: rgba(241, 247, 250, 0.4);
}

.module-light-40:hover {
  color: #273a4d;
  background: rgba(157, 197, 220, 0.4) linear-gradient(180deg, rgba(190, 217, 232, 0.49), rgba(157, 197, 220, 0.4)) repeat-x;
  border-color: color-yig(rgba(157, 197, 220, 0.4));
}

.module-light-40:focus, .module-light-40.focus {
  color: #273a4d;
  background: rgba(157, 197, 220, 0.4) linear-gradient(180deg, rgba(190, 217, 232, 0.49), rgba(157, 197, 220, 0.4)) repeat-x;
  border-color: color-yig(rgba(157, 197, 220, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(125, 149, 167, 0.5);
}

.module-light-40:active, .module-light-40.active,
.show > .module-light-40.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(147, 192, 217, 0.4);
  background-image: none;
  border-color: rgba(138, 186, 213, 0.4);
}

.module-light-40:active:focus, .module-light-40.active:focus,
.show > .module-light-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 149, 167, 0.5);
}

.module-light-40:disabled, .module-light-40.disabled {
  color: #273a4d;
  background-color: rgba(185, 214, 230, 0.4);
  background-image: none;
  border-color: rgba(185, 214, 230, 0.4);
}

.module-light-50 {
  color: #273a4d;
  background: rgba(185, 214, 230, 0.5) linear-gradient(180deg, rgba(203, 225, 237, 0.575), rgba(185, 214, 230, 0.5)) repeat-x;
  border-color: rgba(185, 214, 230, 0.5);
}

.module-light-50 .icon-container {
  background-color: rgba(157, 197, 220, 0.5);
}

.module-light-50 .icon-container.light {
  background-color: rgba(241, 247, 250, 0.5);
}

.module-light-50:hover {
  color: #273a4d;
  background: rgba(157, 197, 220, 0.5) linear-gradient(180deg, rgba(185, 214, 230, 0.575), rgba(157, 197, 220, 0.5)) repeat-x;
  border-color: color-yig(rgba(157, 197, 220, 0.5));
}

.module-light-50:focus, .module-light-50.focus {
  color: #273a4d;
  background: rgba(157, 197, 220, 0.5) linear-gradient(180deg, rgba(185, 214, 230, 0.575), rgba(157, 197, 220, 0.5)) repeat-x;
  border-color: color-yig(rgba(157, 197, 220, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(134, 160, 177, 0.5);
}

.module-light-50:active, .module-light-50.active,
.show > .module-light-50.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(147, 192, 217, 0.5);
  background-image: none;
  border-color: rgba(138, 186, 213, 0.5);
}

.module-light-50:active:focus, .module-light-50.active:focus,
.show > .module-light-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(134, 160, 177, 0.5);
}

.module-light-50:disabled, .module-light-50.disabled {
  color: #273a4d;
  background-color: rgba(185, 214, 230, 0.5);
  background-image: none;
  border-color: rgba(185, 214, 230, 0.5);
}

.module-light-60 {
  color: #273a4d;
  background: rgba(185, 214, 230, 0.6) linear-gradient(180deg, rgba(200, 223, 236, 0.66), rgba(185, 214, 230, 0.6)) repeat-x;
  border-color: rgba(185, 214, 230, 0.6);
}

.module-light-60 .icon-container {
  background-color: rgba(157, 197, 220, 0.6);
}

.module-light-60 .icon-container.light {
  background-color: rgba(241, 247, 250, 0.6);
}

.module-light-60:hover {
  color: #273a4d;
  background: rgba(157, 197, 220, 0.6) linear-gradient(180deg, rgba(180, 211, 229, 0.66), rgba(157, 197, 220, 0.6)) repeat-x;
  border-color: color-yig(rgba(157, 197, 220, 0.6));
}

.module-light-60:focus, .module-light-60.focus {
  color: #273a4d;
  background: rgba(157, 197, 220, 0.6) linear-gradient(180deg, rgba(180, 211, 229, 0.66), rgba(157, 197, 220, 0.6)) repeat-x;
  border-color: color-yig(rgba(157, 197, 220, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(142, 169, 185, 0.5);
}

.module-light-60:active, .module-light-60.active,
.show > .module-light-60.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(147, 192, 217, 0.6);
  background-image: none;
  border-color: rgba(138, 186, 213, 0.6);
}

.module-light-60:active:focus, .module-light-60.active:focus,
.show > .module-light-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(142, 169, 185, 0.5);
}

.module-light-60:disabled, .module-light-60.disabled {
  color: #273a4d;
  background-color: rgba(185, 214, 230, 0.6);
  background-image: none;
  border-color: rgba(185, 214, 230, 0.6);
}

.module-light-70 {
  color: #273a4d;
  background: rgba(185, 214, 230, 0.7) linear-gradient(180deg, rgba(198, 222, 235, 0.745), rgba(185, 214, 230, 0.7)) repeat-x;
  border-color: rgba(185, 214, 230, 0.7);
}

.module-light-70 .icon-container {
  background-color: rgba(157, 197, 220, 0.7);
}

.module-light-70 .icon-container.light {
  background-color: rgba(241, 247, 250, 0.7);
}

.module-light-70:hover {
  color: #273a4d;
  background: rgba(157, 197, 220, 0.7) linear-gradient(180deg, rgba(177, 209, 227, 0.745), rgba(157, 197, 220, 0.7)) repeat-x;
  border-color: color-yig(rgba(157, 197, 220, 0.7));
}

.module-light-70:focus, .module-light-70.focus {
  color: #273a4d;
  background: rgba(157, 197, 220, 0.7) linear-gradient(180deg, rgba(177, 209, 227, 0.745), rgba(157, 197, 220, 0.7)) repeat-x;
  border-color: color-yig(rgba(157, 197, 220, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(149, 175, 192, 0.5);
}

.module-light-70:active, .module-light-70.active,
.show > .module-light-70.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(147, 192, 217, 0.7);
  background-image: none;
  border-color: rgba(138, 186, 213, 0.7);
}

.module-light-70:active:focus, .module-light-70.active:focus,
.show > .module-light-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 175, 192, 0.5);
}

.module-light-70:disabled, .module-light-70.disabled {
  color: #273a4d;
  background-color: rgba(185, 214, 230, 0.7);
  background-image: none;
  border-color: rgba(185, 214, 230, 0.7);
}

.module-light-80 {
  color: #273a4d;
  background: rgba(185, 214, 230, 0.8) linear-gradient(180deg, rgba(196, 220, 234, 0.83), rgba(185, 214, 230, 0.8)) repeat-x;
  border-color: rgba(185, 214, 230, 0.8);
}

.module-light-80 .icon-container {
  background-color: rgba(157, 197, 220, 0.8);
}

.module-light-80 .icon-container.light {
  background-color: rgba(241, 247, 250, 0.8);
}

.module-light-80:hover {
  color: #273a4d;
  background: rgba(157, 197, 220, 0.8) linear-gradient(180deg, rgba(174, 207, 226, 0.83), rgba(157, 197, 220, 0.8)) repeat-x;
  border-color: color-yig(rgba(157, 197, 220, 0.8));
}

.module-light-80:focus, .module-light-80.focus {
  color: #273a4d;
  background: rgba(157, 197, 220, 0.8) linear-gradient(180deg, rgba(174, 207, 226, 0.83), rgba(157, 197, 220, 0.8)) repeat-x;
  border-color: color-yig(rgba(157, 197, 220, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(154, 181, 198, 0.5);
}

.module-light-80:active, .module-light-80.active,
.show > .module-light-80.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(147, 192, 217, 0.8);
  background-image: none;
  border-color: rgba(138, 186, 213, 0.8);
}

.module-light-80:active:focus, .module-light-80.active:focus,
.show > .module-light-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(154, 181, 198, 0.5);
}

.module-light-80:disabled, .module-light-80.disabled {
  color: #273a4d;
  background-color: rgba(185, 214, 230, 0.8);
  background-image: none;
  border-color: rgba(185, 214, 230, 0.8);
}

.module-light-90 {
  color: #273a4d;
  background: rgba(185, 214, 230, 0.9) linear-gradient(180deg, rgba(194, 220, 234, 0.915), rgba(185, 214, 230, 0.9)) repeat-x;
  border-color: rgba(185, 214, 230, 0.9);
}

.module-light-90 .icon-container {
  background-color: rgba(157, 197, 220, 0.9);
}

.module-light-90 .icon-container.light {
  background-color: rgba(241, 247, 250, 0.9);
}

.module-light-90:hover {
  color: #273a4d;
  background: rgba(157, 197, 220, 0.9) linear-gradient(180deg, rgba(171, 206, 225, 0.915), rgba(157, 197, 220, 0.9)) repeat-x;
  border-color: color-yig(rgba(157, 197, 220, 0.9));
}

.module-light-90:focus, .module-light-90.focus {
  color: #273a4d;
  background: rgba(157, 197, 220, 0.9) linear-gradient(180deg, rgba(171, 206, 225, 0.915), rgba(157, 197, 220, 0.9)) repeat-x;
  border-color: color-yig(rgba(157, 197, 220, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(159, 186, 203, 0.5);
}

.module-light-90:active, .module-light-90.active,
.show > .module-light-90.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(147, 192, 217, 0.9);
  background-image: none;
  border-color: rgba(138, 186, 213, 0.9);
}

.module-light-90:active:focus, .module-light-90.active:focus,
.show > .module-light-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(159, 186, 203, 0.5);
}

.module-light-90:disabled, .module-light-90.disabled {
  color: #273a4d;
  background-color: rgba(185, 214, 230, 0.9);
  background-image: none;
  border-color: rgba(185, 214, 230, 0.9);
}

.module-steel {
  color: #273a4d;
  background: #71a3bd linear-gradient(180deg, #84afc6, #71a3bd) repeat-x;
  border-color: #71a3bd;
}

.module-steel .icon-container {
  background-color: #5792b1;
}

.module-steel .icon-container.light {
  background-color: #a5c5d5;
}

.module-steel:hover {
  color: #fff;
  background: #5792b1 linear-gradient(180deg, #6ea1bc, #5792b1) repeat-x;
  border-color: color-yig(#5792b1);
}

.module-steel:focus, .module-steel.focus {
  color: #fff;
  background: #5792b1 linear-gradient(180deg, #6ea1bc, #5792b1) repeat-x;
  border-color: color-yig(#5792b1);
  box-shadow: 0 0 0 0.2rem rgba(102, 147, 172, 0.5);
}

.module-steel:active, .module-steel.active,
.show > .module-steel.dropdown-toggle {
  color: #fff;
  background-color: #508cab;
  background-image: none;
  border-color: #4c85a3;
}

.module-steel:active:focus, .module-steel.active:focus,
.show > .module-steel.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 147, 172, 0.5);
}

.module-steel:disabled, .module-steel.disabled {
  color: #273a4d;
  background-color: #71a3bd;
  background-image: none;
  border-color: #71a3bd;
}

.module-steel-10 {
  color: #273a4d;
  background: rgba(113, 163, 189, 0.1) linear-gradient(180deg, rgba(209, 226, 236, 0.235), rgba(113, 163, 189, 0.1)) repeat-x;
  border-color: rgba(113, 163, 189, 0.1);
}

.module-steel-10 .icon-container {
  background-color: rgba(87, 146, 177, 0.1);
}

.module-steel-10 .icon-container.light {
  background-color: rgba(165, 197, 213, 0.1);
}

.module-steel-10:hover {
  color: #fff;
  background: rgba(87, 146, 177, 0.1) linear-gradient(180deg, rgba(203, 222, 233, 0.235), rgba(87, 146, 177, 0.1)) repeat-x;
  border-color: color-yig(rgba(87, 146, 177, 0.1));
}

.module-steel-10:focus, .module-steel-10.focus {
  color: #fff;
  background: rgba(87, 146, 177, 0.1) linear-gradient(180deg, rgba(203, 222, 233, 0.235), rgba(87, 146, 177, 0.1)) repeat-x;
  border-color: color-yig(rgba(87, 146, 177, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(56, 82, 103, 0.5);
}

.module-steel-10:active, .module-steel-10.active,
.show > .module-steel-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(80, 140, 171, 0.1);
  background-image: none;
  border-color: rgba(76, 133, 163, 0.1);
}

.module-steel-10:active:focus, .module-steel-10.active:focus,
.show > .module-steel-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 82, 103, 0.5);
}

.module-steel-10:disabled, .module-steel-10.disabled {
  color: #273a4d;
  background-color: rgba(113, 163, 189, 0.1);
  background-image: none;
  border-color: rgba(113, 163, 189, 0.1);
}

.module-steel-20 {
  color: #273a4d;
  background: rgba(113, 163, 189, 0.2) linear-gradient(180deg, rgba(190, 213, 226, 0.32), rgba(113, 163, 189, 0.2)) repeat-x;
  border-color: rgba(113, 163, 189, 0.2);
}

.module-steel-20 .icon-container {
  background-color: rgba(87, 146, 177, 0.2);
}

.module-steel-20 .icon-container.light {
  background-color: rgba(165, 197, 213, 0.2);
}

.module-steel-20:hover {
  color: #fff;
  background: rgba(87, 146, 177, 0.2) linear-gradient(180deg, rgba(180, 207, 222, 0.32), rgba(87, 146, 177, 0.2)) repeat-x;
  border-color: color-yig(rgba(87, 146, 177, 0.2));
}

.module-steel-20:focus, .module-steel-20.focus {
  color: #fff;
  background: rgba(87, 146, 177, 0.2) linear-gradient(180deg, rgba(180, 207, 222, 0.32), rgba(87, 146, 177, 0.2)) repeat-x;
  border-color: color-yig(rgba(87, 146, 177, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(68, 99, 120, 0.5);
}

.module-steel-20:active, .module-steel-20.active,
.show > .module-steel-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(80, 140, 171, 0.2);
  background-image: none;
  border-color: rgba(76, 133, 163, 0.2);
}

.module-steel-20:active:focus, .module-steel-20.active:focus,
.show > .module-steel-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 99, 120, 0.5);
}

.module-steel-20:disabled, .module-steel-20.disabled {
  color: #273a4d;
  background-color: rgba(113, 163, 189, 0.2);
  background-image: none;
  border-color: rgba(113, 163, 189, 0.2);
}

.module-steel-30 {
  color: #273a4d;
  background: rgba(113, 163, 189, 0.3) linear-gradient(180deg, rgba(176, 204, 220, 0.405), rgba(113, 163, 189, 0.3)) repeat-x;
  border-color: rgba(113, 163, 189, 0.3);
}

.module-steel-30 .icon-container {
  background-color: rgba(87, 146, 177, 0.3);
}

.module-steel-30 .icon-container.light {
  background-color: rgba(165, 197, 213, 0.3);
}

.module-steel-30:hover {
  color: #fff;
  background: rgba(87, 146, 177, 0.3) linear-gradient(180deg, rgba(162, 196, 213, 0.405), rgba(87, 146, 177, 0.3)) repeat-x;
  border-color: color-yig(rgba(87, 146, 177, 0.3));
}

.module-steel-30:focus, .module-steel-30.focus {
  color: #fff;
  background: rgba(87, 146, 177, 0.3) linear-gradient(180deg, rgba(162, 196, 213, 0.405), rgba(87, 146, 177, 0.3)) repeat-x;
  border-color: color-yig(rgba(87, 146, 177, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(76, 111, 133, 0.5);
}

.module-steel-30:active, .module-steel-30.active,
.show > .module-steel-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(80, 140, 171, 0.3);
  background-image: none;
  border-color: rgba(76, 133, 163, 0.3);
}

.module-steel-30:active:focus, .module-steel-30.active:focus,
.show > .module-steel-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 111, 133, 0.5);
}

.module-steel-30:disabled, .module-steel-30.disabled {
  color: #273a4d;
  background-color: rgba(113, 163, 189, 0.3);
  background-image: none;
  border-color: rgba(113, 163, 189, 0.3);
}

.module-steel-40 {
  color: #273a4d;
  background: rgba(113, 163, 189, 0.4) linear-gradient(180deg, rgba(165, 197, 214, 0.49), rgba(113, 163, 189, 0.4)) repeat-x;
  border-color: rgba(113, 163, 189, 0.4);
}

.module-steel-40 .icon-container {
  background-color: rgba(87, 146, 177, 0.4);
}

.module-steel-40 .icon-container.light {
  background-color: rgba(165, 197, 213, 0.4);
}

.module-steel-40:hover {
  color: #fff;
  background: rgba(87, 146, 177, 0.4) linear-gradient(180deg, rgba(149, 187, 207, 0.49), rgba(87, 146, 177, 0.4)) repeat-x;
  border-color: color-yig(rgba(87, 146, 177, 0.4));
}

.module-steel-40:focus, .module-steel-40.focus {
  color: #fff;
  background: rgba(87, 146, 177, 0.4) linear-gradient(180deg, rgba(149, 187, 207, 0.49), rgba(87, 146, 177, 0.4)) repeat-x;
  border-color: color-yig(rgba(87, 146, 177, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(82, 120, 143, 0.5);
}

.module-steel-40:active, .module-steel-40.active,
.show > .module-steel-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(80, 140, 171, 0.4);
  background-image: none;
  border-color: rgba(76, 133, 163, 0.4);
}

.module-steel-40:active:focus, .module-steel-40.active:focus,
.show > .module-steel-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 120, 143, 0.5);
}

.module-steel-40:disabled, .module-steel-40.disabled {
  color: #273a4d;
  background-color: rgba(113, 163, 189, 0.4);
  background-image: none;
  border-color: rgba(113, 163, 189, 0.4);
}

.module-steel-50 {
  color: #273a4d;
  background: rgba(113, 163, 189, 0.5) linear-gradient(180deg, rgba(156, 191, 210, 0.575), rgba(113, 163, 189, 0.5)) repeat-x;
  border-color: rgba(113, 163, 189, 0.5);
}

.module-steel-50 .icon-container {
  background-color: rgba(87, 146, 177, 0.5);
}

.module-steel-50 .icon-container.light {
  background-color: rgba(165, 197, 213, 0.5);
}

.module-steel-50:hover {
  color: #fff;
  background: rgba(87, 146, 177, 0.5) linear-gradient(180deg, rgba(139, 180, 202, 0.575), rgba(87, 146, 177, 0.5)) repeat-x;
  border-color: color-yig(rgba(87, 146, 177, 0.5));
}

.module-steel-50:focus, .module-steel-50.focus {
  color: #fff;
  background: rgba(87, 146, 177, 0.5) linear-gradient(180deg, rgba(139, 180, 202, 0.575), rgba(87, 146, 177, 0.5)) repeat-x;
  border-color: color-yig(rgba(87, 146, 177, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(87, 127, 150, 0.5);
}

.module-steel-50:active, .module-steel-50.active,
.show > .module-steel-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(80, 140, 171, 0.5);
  background-image: none;
  border-color: rgba(76, 133, 163, 0.5);
}

.module-steel-50:active:focus, .module-steel-50.active:focus,
.show > .module-steel-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(87, 127, 150, 0.5);
}

.module-steel-50:disabled, .module-steel-50.disabled {
  color: #273a4d;
  background-color: rgba(113, 163, 189, 0.5);
  background-image: none;
  border-color: rgba(113, 163, 189, 0.5);
}

.module-steel-60 {
  color: #273a4d;
  background: rgba(113, 163, 189, 0.6) linear-gradient(180deg, rgba(149, 187, 207, 0.66), rgba(113, 163, 189, 0.6)) repeat-x;
  border-color: rgba(113, 163, 189, 0.6);
}

.module-steel-60 .icon-container {
  background-color: rgba(87, 146, 177, 0.6);
}

.module-steel-60 .icon-container.light {
  background-color: rgba(165, 197, 213, 0.6);
}

.module-steel-60:hover {
  color: #fff;
  background: rgba(87, 146, 177, 0.6) linear-gradient(180deg, rgba(131, 175, 198, 0.66), rgba(87, 146, 177, 0.6)) repeat-x;
  border-color: color-yig(rgba(87, 146, 177, 0.6));
}

.module-steel-60:focus, .module-steel-60.focus {
  color: #fff;
  background: rgba(87, 146, 177, 0.6) linear-gradient(180deg, rgba(131, 175, 198, 0.66), rgba(87, 146, 177, 0.6)) repeat-x;
  border-color: color-yig(rgba(87, 146, 177, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(91, 132, 156, 0.5);
}

.module-steel-60:active, .module-steel-60.active,
.show > .module-steel-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(80, 140, 171, 0.6);
  background-image: none;
  border-color: rgba(76, 133, 163, 0.6);
}

.module-steel-60:active:focus, .module-steel-60.active:focus,
.show > .module-steel-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(91, 132, 156, 0.5);
}

.module-steel-60:disabled, .module-steel-60.disabled {
  color: #273a4d;
  background-color: rgba(113, 163, 189, 0.6);
  background-image: none;
  border-color: rgba(113, 163, 189, 0.6);
}

.module-steel-70 {
  color: #273a4d;
  background: rgba(113, 163, 189, 0.7) linear-gradient(180deg, rgba(144, 183, 204, 0.745), rgba(113, 163, 189, 0.7)) repeat-x;
  border-color: rgba(113, 163, 189, 0.7);
}

.module-steel-70 .icon-container {
  background-color: rgba(87, 146, 177, 0.7);
}

.module-steel-70 .icon-container.light {
  background-color: rgba(165, 197, 213, 0.7);
}

.module-steel-70:hover {
  color: #fff;
  background: rgba(87, 146, 177, 0.7) linear-gradient(180deg, rgba(124, 170, 195, 0.745), rgba(87, 146, 177, 0.7)) repeat-x;
  border-color: color-yig(rgba(87, 146, 177, 0.7));
}

.module-steel-70:focus, .module-steel-70.focus {
  color: #fff;
  background: rgba(87, 146, 177, 0.7) linear-gradient(180deg, rgba(124, 170, 195, 0.745), rgba(87, 146, 177, 0.7)) repeat-x;
  border-color: color-yig(rgba(87, 146, 177, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(95, 137, 161, 0.5);
}

.module-steel-70:active, .module-steel-70.active,
.show > .module-steel-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(80, 140, 171, 0.7);
  background-image: none;
  border-color: rgba(76, 133, 163, 0.7);
}

.module-steel-70:active:focus, .module-steel-70.active:focus,
.show > .module-steel-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 137, 161, 0.5);
}

.module-steel-70:disabled, .module-steel-70.disabled {
  color: #273a4d;
  background-color: rgba(113, 163, 189, 0.7);
  background-image: none;
  border-color: rgba(113, 163, 189, 0.7);
}

.module-steel-80 {
  color: #273a4d;
  background: rgba(113, 163, 189, 0.8) linear-gradient(180deg, rgba(139, 180, 202, 0.83), rgba(113, 163, 189, 0.8)) repeat-x;
  border-color: rgba(113, 163, 189, 0.8);
}

.module-steel-80 .icon-container {
  background-color: rgba(87, 146, 177, 0.8);
}

.module-steel-80 .icon-container.light {
  background-color: rgba(165, 197, 213, 0.8);
}

.module-steel-80:hover {
  color: #fff;
  background: rgba(87, 146, 177, 0.8) linear-gradient(180deg, rgba(119, 167, 192, 0.83), rgba(87, 146, 177, 0.8)) repeat-x;
  border-color: color-yig(rgba(87, 146, 177, 0.8));
}

.module-steel-80:focus, .module-steel-80.focus {
  color: #fff;
  background: rgba(87, 146, 177, 0.8) linear-gradient(180deg, rgba(119, 167, 192, 0.83), rgba(87, 146, 177, 0.8)) repeat-x;
  border-color: color-yig(rgba(87, 146, 177, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(98, 141, 166, 0.5);
}

.module-steel-80:active, .module-steel-80.active,
.show > .module-steel-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(80, 140, 171, 0.8);
  background-image: none;
  border-color: rgba(76, 133, 163, 0.8);
}

.module-steel-80:active:focus, .module-steel-80.active:focus,
.show > .module-steel-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(98, 141, 166, 0.5);
}

.module-steel-80:disabled, .module-steel-80.disabled {
  color: #273a4d;
  background-color: rgba(113, 163, 189, 0.8);
  background-image: none;
  border-color: rgba(113, 163, 189, 0.8);
}

.module-steel-90 {
  color: #273a4d;
  background: rgba(113, 163, 189, 0.9) linear-gradient(180deg, rgba(135, 178, 200, 0.915), rgba(113, 163, 189, 0.9)) repeat-x;
  border-color: rgba(113, 163, 189, 0.9);
}

.module-steel-90 .icon-container {
  background-color: rgba(87, 146, 177, 0.9);
}

.module-steel-90 .icon-container.light {
  background-color: rgba(165, 197, 213, 0.9);
}

.module-steel-90:hover {
  color: #fff;
  background: rgba(87, 146, 177, 0.9) linear-gradient(180deg, rgba(114, 164, 190, 0.915), rgba(87, 146, 177, 0.9)) repeat-x;
  border-color: color-yig(rgba(87, 146, 177, 0.9));
}

.module-steel-90:focus, .module-steel-90.focus {
  color: #fff;
  background: rgba(87, 146, 177, 0.9) linear-gradient(180deg, rgba(114, 164, 190, 0.915), rgba(87, 146, 177, 0.9)) repeat-x;
  border-color: color-yig(rgba(87, 146, 177, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(100, 144, 169, 0.5);
}

.module-steel-90:active, .module-steel-90.active,
.show > .module-steel-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(80, 140, 171, 0.9);
  background-image: none;
  border-color: rgba(76, 133, 163, 0.9);
}

.module-steel-90:active:focus, .module-steel-90.active:focus,
.show > .module-steel-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(100, 144, 169, 0.5);
}

.module-steel-90:disabled, .module-steel-90.disabled {
  color: #273a4d;
  background-color: rgba(113, 163, 189, 0.9);
  background-image: none;
  border-color: rgba(113, 163, 189, 0.9);
}

.module-ocean {
  color: #fff;
  background: #48748b linear-gradient(180deg, #61879c, #48748b) repeat-x;
  border-color: #48748b;
}

.module-ocean .icon-container {
  background-color: #3b5f72;
}

.module-ocean .icon-container.light {
  background-color: #6c9bb3;
}

.module-ocean:hover {
  color: #fff;
  background: #3b5f72 linear-gradient(180deg, #567586, #3b5f72) repeat-x;
  border-color: color-yig(#3b5f72);
}

.module-ocean:focus, .module-ocean.focus {
  color: #fff;
  background: #3b5f72 linear-gradient(180deg, #567586, #3b5f72) repeat-x;
  border-color: color-yig(#3b5f72);
  box-shadow: 0 0 0 0.2rem rgba(99, 137, 156, 0.5);
}

.module-ocean:active, .module-ocean.active,
.show > .module-ocean.dropdown-toggle {
  color: #fff;
  background-color: #375869;
  background-image: none;
  border-color: #325161;
}

.module-ocean:active:focus, .module-ocean.active:focus,
.show > .module-ocean.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 137, 156, 0.5);
}

.module-ocean:disabled, .module-ocean.disabled {
  color: #fff;
  background-color: #48748b;
  background-image: none;
  border-color: #48748b;
}

.module-ocean-10 {
  color: #fff;
  background: rgba(72, 116, 139, 0.1) linear-gradient(180deg, rgba(200, 215, 225, 0.235), rgba(72, 116, 139, 0.1)) repeat-x;
  border-color: rgba(72, 116, 139, 0.1);
}

.module-ocean-10 .icon-container {
  background-color: rgba(59, 95, 114, 0.1);
}

.module-ocean-10 .icon-container.light {
  background-color: rgba(108, 155, 179, 0.1);
}

.module-ocean-10:hover {
  color: #fff;
  background: rgba(59, 95, 114, 0.1) linear-gradient(180deg, rgba(197, 211, 219, 0.235), rgba(59, 95, 114, 0.1)) repeat-x;
  border-color: color-yig(rgba(59, 95, 114, 0.1));
}

.module-ocean-10:focus, .module-ocean-10.focus {
  color: #fff;
  background: rgba(59, 95, 114, 0.1) linear-gradient(180deg, rgba(197, 211, 219, 0.235), rgba(59, 95, 114, 0.1)) repeat-x;
  border-color: color-yig(rgba(59, 95, 114, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(213, 223, 228, 0.5);
}

.module-ocean-10:active, .module-ocean-10.active,
.show > .module-ocean-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(55, 88, 105, 0.1);
  background-image: none;
  border-color: rgba(50, 81, 97, 0.1);
}

.module-ocean-10:active:focus, .module-ocean-10.active:focus,
.show > .module-ocean-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 223, 228, 0.5);
}

.module-ocean-10:disabled, .module-ocean-10.disabled {
  color: #fff;
  background-color: rgba(72, 116, 139, 0.1);
  background-image: none;
  border-color: rgba(72, 116, 139, 0.1);
}

.module-ocean-20 {
  color: #fff;
  background: rgba(72, 116, 139, 0.2) linear-gradient(180deg, rgba(174, 195, 207, 0.32), rgba(72, 116, 139, 0.2)) repeat-x;
  border-color: rgba(72, 116, 139, 0.2);
}

.module-ocean-20 .icon-container {
  background-color: rgba(59, 95, 114, 0.2);
}

.module-ocean-20 .icon-container.light {
  background-color: rgba(108, 155, 179, 0.2);
}

.module-ocean-20:hover {
  color: #fff;
  background: rgba(59, 95, 114, 0.2) linear-gradient(180deg, rgba(169, 187, 197, 0.32), rgba(59, 95, 114, 0.2)) repeat-x;
  border-color: color-yig(rgba(59, 95, 114, 0.2));
}

.module-ocean-20:focus, .module-ocean-20.focus {
  color: #fff;
  background: rgba(59, 95, 114, 0.2) linear-gradient(180deg, rgba(169, 187, 197, 0.32), rgba(59, 95, 114, 0.2)) repeat-x;
  border-color: color-yig(rgba(59, 95, 114, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(184, 201, 210, 0.5);
}

.module-ocean-20:active, .module-ocean-20.active,
.show > .module-ocean-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(55, 88, 105, 0.2);
  background-image: none;
  border-color: rgba(50, 81, 97, 0.2);
}

.module-ocean-20:active:focus, .module-ocean-20.active:focus,
.show > .module-ocean-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 201, 210, 0.5);
}

.module-ocean-20:disabled, .module-ocean-20.disabled {
  color: #fff;
  background-color: rgba(72, 116, 139, 0.2);
  background-image: none;
  border-color: rgba(72, 116, 139, 0.2);
}

.module-ocean-30 {
  color: #fff;
  background: rgba(72, 116, 139, 0.3) linear-gradient(180deg, rgba(155, 181, 195, 0.405), rgba(72, 116, 139, 0.3)) repeat-x;
  border-color: rgba(72, 116, 139, 0.3);
}

.module-ocean-30 .icon-container {
  background-color: rgba(59, 95, 114, 0.3);
}

.module-ocean-30 .icon-container.light {
  background-color: rgba(108, 155, 179, 0.3);
}

.module-ocean-30:hover {
  color: #fff;
  background: rgba(59, 95, 114, 0.3) linear-gradient(180deg, rgba(148, 170, 182, 0.405), rgba(59, 95, 114, 0.3)) repeat-x;
  border-color: color-yig(rgba(59, 95, 114, 0.3));
}

.module-ocean-30:focus, .module-ocean-30.focus {
  color: #fff;
  background: rgba(59, 95, 114, 0.3) linear-gradient(180deg, rgba(148, 170, 182, 0.405), rgba(59, 95, 114, 0.3)) repeat-x;
  border-color: color-yig(rgba(59, 95, 114, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(164, 186, 197, 0.5);
}

.module-ocean-30:active, .module-ocean-30.active,
.show > .module-ocean-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(55, 88, 105, 0.3);
  background-image: none;
  border-color: rgba(50, 81, 97, 0.3);
}

.module-ocean-30:active:focus, .module-ocean-30.active:focus,
.show > .module-ocean-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(164, 186, 197, 0.5);
}

.module-ocean-30:disabled, .module-ocean-30.disabled {
  color: #fff;
  background-color: rgba(72, 116, 139, 0.3);
  background-image: none;
  border-color: rgba(72, 116, 139, 0.3);
}

.module-ocean-40 {
  color: #fff;
  background: rgba(72, 116, 139, 0.4) linear-gradient(180deg, rgba(141, 169, 185, 0.49), rgba(72, 116, 139, 0.4)) repeat-x;
  border-color: rgba(72, 116, 139, 0.4);
}

.module-ocean-40 .icon-container {
  background-color: rgba(59, 95, 114, 0.4);
}

.module-ocean-40 .icon-container.light {
  background-color: rgba(108, 155, 179, 0.4);
}

.module-ocean-40:hover {
  color: #fff;
  background: rgba(59, 95, 114, 0.4) linear-gradient(180deg, rgba(133, 157, 170, 0.49), rgba(59, 95, 114, 0.4)) repeat-x;
  border-color: color-yig(rgba(59, 95, 114, 0.4));
}

.module-ocean-40:focus, .module-ocean-40.focus {
  color: #fff;
  background: rgba(59, 95, 114, 0.4) linear-gradient(180deg, rgba(133, 157, 170, 0.49), rgba(59, 95, 114, 0.4)) repeat-x;
  border-color: color-yig(rgba(59, 95, 114, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(148, 174, 187, 0.5);
}

.module-ocean-40:active, .module-ocean-40.active,
.show > .module-ocean-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(55, 88, 105, 0.4);
  background-image: none;
  border-color: rgba(50, 81, 97, 0.4);
}

.module-ocean-40:active:focus, .module-ocean-40.active:focus,
.show > .module-ocean-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(148, 174, 187, 0.5);
}

.module-ocean-40:disabled, .module-ocean-40.disabled {
  color: #fff;
  background-color: rgba(72, 116, 139, 0.4);
  background-image: none;
  border-color: rgba(72, 116, 139, 0.4);
}

.module-ocean-50 {
  color: #fff;
  background: rgba(72, 116, 139, 0.5) linear-gradient(180deg, rgba(129, 161, 177, 0.575), rgba(72, 116, 139, 0.5)) repeat-x;
  border-color: rgba(72, 116, 139, 0.5);
}

.module-ocean-50 .icon-container {
  background-color: rgba(59, 95, 114, 0.5);
}

.module-ocean-50 .icon-container.light {
  background-color: rgba(108, 155, 179, 0.5);
}

.module-ocean-50:hover {
  color: #fff;
  background: rgba(59, 95, 114, 0.5) linear-gradient(180deg, rgba(121, 147, 161, 0.575), rgba(59, 95, 114, 0.5)) repeat-x;
  border-color: color-yig(rgba(59, 95, 114, 0.5));
}

.module-ocean-50:focus, .module-ocean-50.focus {
  color: #fff;
  background: rgba(59, 95, 114, 0.5) linear-gradient(180deg, rgba(121, 147, 161, 0.575), rgba(59, 95, 114, 0.5)) repeat-x;
  border-color: color-yig(rgba(59, 95, 114, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(135, 164, 179, 0.5);
}

.module-ocean-50:active, .module-ocean-50.active,
.show > .module-ocean-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(55, 88, 105, 0.5);
  background-image: none;
  border-color: rgba(50, 81, 97, 0.5);
}

.module-ocean-50:active:focus, .module-ocean-50.active:focus,
.show > .module-ocean-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(135, 164, 179, 0.5);
}

.module-ocean-50:disabled, .module-ocean-50.disabled {
  color: #fff;
  background-color: rgba(72, 116, 139, 0.5);
  background-image: none;
  border-color: rgba(72, 116, 139, 0.5);
}

.module-ocean-60 {
  color: #fff;
  background: rgba(72, 116, 139, 0.6) linear-gradient(180deg, rgba(120, 154, 171, 0.66), rgba(72, 116, 139, 0.6)) repeat-x;
  border-color: rgba(72, 116, 139, 0.6);
}

.module-ocean-60 .icon-container {
  background-color: rgba(59, 95, 114, 0.6);
}

.module-ocean-60 .icon-container.light {
  background-color: rgba(108, 155, 179, 0.6);
}

.module-ocean-60:hover {
  color: #fff;
  background: rgba(59, 95, 114, 0.6) linear-gradient(180deg, rgba(111, 139, 154, 0.66), rgba(59, 95, 114, 0.6)) repeat-x;
  border-color: color-yig(rgba(59, 95, 114, 0.6));
}

.module-ocean-60:focus, .module-ocean-60.focus {
  color: #fff;
  background: rgba(59, 95, 114, 0.6) linear-gradient(180deg, rgba(111, 139, 154, 0.66), rgba(59, 95, 114, 0.6)) repeat-x;
  border-color: color-yig(rgba(59, 95, 114, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(125, 157, 173, 0.5);
}

.module-ocean-60:active, .module-ocean-60.active,
.show > .module-ocean-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(55, 88, 105, 0.6);
  background-image: none;
  border-color: rgba(50, 81, 97, 0.6);
}

.module-ocean-60:active:focus, .module-ocean-60.active:focus,
.show > .module-ocean-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 157, 173, 0.5);
}

.module-ocean-60:disabled, .module-ocean-60.disabled {
  color: #fff;
  background-color: rgba(72, 116, 139, 0.6);
  background-image: none;
  border-color: rgba(72, 116, 139, 0.6);
}

.module-ocean-70 {
  color: #fff;
  background: rgba(72, 116, 139, 0.7) linear-gradient(180deg, rgba(113, 148, 166, 0.745), rgba(72, 116, 139, 0.7)) repeat-x;
  border-color: rgba(72, 116, 139, 0.7);
}

.module-ocean-70 .icon-container {
  background-color: rgba(59, 95, 114, 0.7);
}

.module-ocean-70 .icon-container.light {
  background-color: rgba(108, 155, 179, 0.7);
}

.module-ocean-70:hover {
  color: #fff;
  background: rgba(59, 95, 114, 0.7) linear-gradient(180deg, rgba(103, 132, 147, 0.745), rgba(59, 95, 114, 0.7)) repeat-x;
  border-color: color-yig(rgba(59, 95, 114, 0.7));
}

.module-ocean-70:focus, .module-ocean-70.focus {
  color: #fff;
  background: rgba(59, 95, 114, 0.7) linear-gradient(180deg, rgba(103, 132, 147, 0.745), rgba(59, 95, 114, 0.7)) repeat-x;
  border-color: color-yig(rgba(59, 95, 114, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(117, 150, 168, 0.5);
}

.module-ocean-70:active, .module-ocean-70.active,
.show > .module-ocean-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(55, 88, 105, 0.7);
  background-image: none;
  border-color: rgba(50, 81, 97, 0.7);
}

.module-ocean-70:active:focus, .module-ocean-70.active:focus,
.show > .module-ocean-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(117, 150, 168, 0.5);
}

.module-ocean-70:disabled, .module-ocean-70.disabled {
  color: #fff;
  background-color: rgba(72, 116, 139, 0.7);
  background-image: none;
  border-color: rgba(72, 116, 139, 0.7);
}

.module-ocean-80 {
  color: #fff;
  background: rgba(72, 116, 139, 0.8) linear-gradient(180deg, rgba(107, 143, 162, 0.83), rgba(72, 116, 139, 0.8)) repeat-x;
  border-color: rgba(72, 116, 139, 0.8);
}

.module-ocean-80 .icon-container {
  background-color: rgba(59, 95, 114, 0.8);
}

.module-ocean-80 .icon-container.light {
  background-color: rgba(108, 155, 179, 0.8);
}

.module-ocean-80:hover {
  color: #fff;
  background: rgba(59, 95, 114, 0.8) linear-gradient(180deg, rgba(96, 126, 142, 0.83), rgba(59, 95, 114, 0.8)) repeat-x;
  border-color: color-yig(rgba(59, 95, 114, 0.8));
}

.module-ocean-80:focus, .module-ocean-80.focus {
  color: #fff;
  background: rgba(59, 95, 114, 0.8) linear-gradient(180deg, rgba(96, 126, 142, 0.83), rgba(59, 95, 114, 0.8)) repeat-x;
  border-color: color-yig(rgba(59, 95, 114, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(110, 145, 163, 0.5);
}

.module-ocean-80:active, .module-ocean-80.active,
.show > .module-ocean-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(55, 88, 105, 0.8);
  background-image: none;
  border-color: rgba(50, 81, 97, 0.8);
}

.module-ocean-80:active:focus, .module-ocean-80.active:focus,
.show > .module-ocean-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(110, 145, 163, 0.5);
}

.module-ocean-80:disabled, .module-ocean-80.disabled {
  color: #fff;
  background-color: rgba(72, 116, 139, 0.8);
  background-image: none;
  border-color: rgba(72, 116, 139, 0.8);
}

.module-ocean-90 {
  color: #fff;
  background: rgba(72, 116, 139, 0.9) linear-gradient(180deg, rgba(101, 139, 159, 0.915), rgba(72, 116, 139, 0.9)) repeat-x;
  border-color: rgba(72, 116, 139, 0.9);
}

.module-ocean-90 .icon-container {
  background-color: rgba(59, 95, 114, 0.9);
}

.module-ocean-90 .icon-container.light {
  background-color: rgba(108, 155, 179, 0.9);
}

.module-ocean-90:hover {
  color: #fff;
  background: rgba(59, 95, 114, 0.9) linear-gradient(180deg, rgba(91, 122, 138, 0.915), rgba(59, 95, 114, 0.9)) repeat-x;
  border-color: color-yig(rgba(59, 95, 114, 0.9));
}

.module-ocean-90:focus, .module-ocean-90.focus {
  color: #fff;
  background: rgba(59, 95, 114, 0.9) linear-gradient(180deg, rgba(91, 122, 138, 0.915), rgba(59, 95, 114, 0.9)) repeat-x;
  border-color: color-yig(rgba(59, 95, 114, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(104, 141, 160, 0.5);
}

.module-ocean-90:active, .module-ocean-90.active,
.show > .module-ocean-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(55, 88, 105, 0.9);
  background-image: none;
  border-color: rgba(50, 81, 97, 0.9);
}

.module-ocean-90:active:focus, .module-ocean-90.active:focus,
.show > .module-ocean-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(104, 141, 160, 0.5);
}

.module-ocean-90:disabled, .module-ocean-90.disabled {
  color: #fff;
  background-color: rgba(72, 116, 139, 0.9);
  background-image: none;
  border-color: rgba(72, 116, 139, 0.9);
}

.module-midnight {
  color: #fff;
  background: #354f68 linear-gradient(180deg, #51687e, #354f68) repeat-x;
  border-color: #354f68;
}

.module-midnight .icon-container {
  background-color: #283c4f;
}

.module-midnight .icon-container.light {
  background-color: #4f759b;
}

.module-midnight:hover {
  color: #fff;
  background: #283c4f linear-gradient(180deg, #465868, #283c4f) repeat-x;
  border-color: color-yig(#283c4f);
}

.module-midnight:focus, .module-midnight.focus {
  color: #fff;
  background: #283c4f linear-gradient(180deg, #465868, #283c4f) repeat-x;
  border-color: color-yig(#283c4f);
  box-shadow: 0 0 0 0.2rem rgba(83, 105, 127, 0.5);
}

.module-midnight:active, .module-midnight.active,
.show > .module-midnight.dropdown-toggle {
  color: #fff;
  background-color: #243546;
  background-image: none;
  border-color: #1f2f3e;
}

.module-midnight:active:focus, .module-midnight.active:focus,
.show > .module-midnight.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(83, 105, 127, 0.5);
}

.module-midnight:disabled, .module-midnight.disabled {
  color: #fff;
  background-color: #354f68;
  background-image: none;
  border-color: #354f68;
}

.module-midnight-10 {
  color: #fff;
  background: rgba(53, 79, 104, 0.1) linear-gradient(180deg, rgba(196, 207, 216, 0.235), rgba(53, 79, 104, 0.1)) repeat-x;
  border-color: rgba(53, 79, 104, 0.1);
}

.module-midnight-10 .icon-container {
  background-color: rgba(40, 60, 79, 0.1);
}

.module-midnight-10 .icon-container.light {
  background-color: rgba(79, 117, 155, 0.1);
}

.module-midnight-10:hover {
  color: #fff;
  background: rgba(40, 60, 79, 0.1) linear-gradient(180deg, rgba(193, 202, 211, 0.235), rgba(40, 60, 79, 0.1)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.1));
}

.module-midnight-10:focus, .module-midnight-10.focus {
  color: #fff;
  background: rgba(40, 60, 79, 0.1) linear-gradient(180deg, rgba(193, 202, 211, 0.235), rgba(40, 60, 79, 0.1)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(209, 215, 220, 0.5);
}

.module-midnight-10:active, .module-midnight-10.active,
.show > .module-midnight-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(36, 53, 70, 0.1);
  background-image: none;
  border-color: rgba(31, 47, 62, 0.1);
}

.module-midnight-10:active:focus, .module-midnight-10.active:focus,
.show > .module-midnight-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 215, 220, 0.5);
}

.module-midnight-10:disabled, .module-midnight-10.disabled {
  color: #fff;
  background-color: rgba(53, 79, 104, 0.1);
  background-image: none;
  border-color: rgba(53, 79, 104, 0.1);
}

.module-midnight-20 {
  color: #fff;
  background: rgba(53, 79, 104, 0.2) linear-gradient(180deg, rgba(167, 181, 194, 0.32), rgba(53, 79, 104, 0.2)) repeat-x;
  border-color: rgba(53, 79, 104, 0.2);
}

.module-midnight-20 .icon-container {
  background-color: rgba(40, 60, 79, 0.2);
}

.module-midnight-20 .icon-container.light {
  background-color: rgba(79, 117, 155, 0.2);
}

.module-midnight-20:hover {
  color: #fff;
  background: rgba(40, 60, 79, 0.2) linear-gradient(180deg, rgba(162, 173, 184, 0.32), rgba(40, 60, 79, 0.2)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.2));
}

.module-midnight-20:focus, .module-midnight-20.focus {
  color: #fff;
  background: rgba(40, 60, 79, 0.2) linear-gradient(180deg, rgba(162, 173, 184, 0.32), rgba(40, 60, 79, 0.2)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(177, 187, 197, 0.5);
}

.module-midnight-20:active, .module-midnight-20.active,
.show > .module-midnight-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(36, 53, 70, 0.2);
  background-image: none;
  border-color: rgba(31, 47, 62, 0.2);
}

.module-midnight-20:active:focus, .module-midnight-20.active:focus,
.show > .module-midnight-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(177, 187, 197, 0.5);
}

.module-midnight-20:disabled, .module-midnight-20.disabled {
  color: #fff;
  background-color: rgba(53, 79, 104, 0.2);
  background-image: none;
  border-color: rgba(53, 79, 104, 0.2);
}

.module-midnight-30 {
  color: #fff;
  background: rgba(53, 79, 104, 0.3) linear-gradient(180deg, rgba(146, 162, 177, 0.405), rgba(53, 79, 104, 0.3)) repeat-x;
  border-color: rgba(53, 79, 104, 0.3);
}

.module-midnight-30 .icon-container {
  background-color: rgba(40, 60, 79, 0.3);
}

.module-midnight-30 .icon-container.light {
  background-color: rgba(79, 117, 155, 0.3);
}

.module-midnight-30:hover {
  color: #fff;
  background: rgba(40, 60, 79, 0.3) linear-gradient(180deg, rgba(139, 152, 164, 0.405), rgba(40, 60, 79, 0.3)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.3));
}

.module-midnight-30:focus, .module-midnight-30.focus {
  color: #fff;
  background: rgba(40, 60, 79, 0.3) linear-gradient(180deg, rgba(139, 152, 164, 0.405), rgba(40, 60, 79, 0.3)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(154, 167, 180, 0.5);
}

.module-midnight-30:active, .module-midnight-30.active,
.show > .module-midnight-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(36, 53, 70, 0.3);
  background-image: none;
  border-color: rgba(31, 47, 62, 0.3);
}

.module-midnight-30:active:focus, .module-midnight-30.active:focus,
.show > .module-midnight-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(154, 167, 180, 0.5);
}

.module-midnight-30:disabled, .module-midnight-30.disabled {
  color: #fff;
  background-color: rgba(53, 79, 104, 0.3);
  background-image: none;
  border-color: rgba(53, 79, 104, 0.3);
}

.module-midnight-40 {
  color: #fff;
  background: rgba(53, 79, 104, 0.4) linear-gradient(180deg, rgba(130, 148, 164, 0.49), rgba(53, 79, 104, 0.4)) repeat-x;
  border-color: rgba(53, 79, 104, 0.4);
}

.module-midnight-40 .icon-container {
  background-color: rgba(40, 60, 79, 0.4);
}

.module-midnight-40 .icon-container.light {
  background-color: rgba(79, 117, 155, 0.4);
}

.module-midnight-40:hover {
  color: #fff;
  background: rgba(40, 60, 79, 0.4) linear-gradient(180deg, rgba(122, 136, 150, 0.49), rgba(40, 60, 79, 0.4)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.4));
}

.module-midnight-40:focus, .module-midnight-40.focus {
  color: #fff;
  background: rgba(40, 60, 79, 0.4) linear-gradient(180deg, rgba(122, 136, 150, 0.49), rgba(40, 60, 79, 0.4)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(137, 152, 166, 0.5);
}

.module-midnight-40:active, .module-midnight-40.active,
.show > .module-midnight-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(36, 53, 70, 0.4);
  background-image: none;
  border-color: rgba(31, 47, 62, 0.4);
}

.module-midnight-40:active:focus, .module-midnight-40.active:focus,
.show > .module-midnight-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(137, 152, 166, 0.5);
}

.module-midnight-40:disabled, .module-midnight-40.disabled {
  color: #fff;
  background-color: rgba(53, 79, 104, 0.4);
  background-image: none;
  border-color: rgba(53, 79, 104, 0.4);
}

.module-midnight-50 {
  color: #fff;
  background: rgba(53, 79, 104, 0.5) linear-gradient(180deg, rgba(117, 136, 155, 0.575), rgba(53, 79, 104, 0.5)) repeat-x;
  border-color: rgba(53, 79, 104, 0.5);
}

.module-midnight-50 .icon-container {
  background-color: rgba(40, 60, 79, 0.5);
}

.module-midnight-50 .icon-container.light {
  background-color: rgba(79, 117, 155, 0.5);
}

.module-midnight-50:hover {
  color: #fff;
  background: rgba(40, 60, 79, 0.5) linear-gradient(180deg, rgba(109, 124, 138, 0.575), rgba(40, 60, 79, 0.5)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.5));
}

.module-midnight-50:focus, .module-midnight-50.focus {
  color: #fff;
  background: rgba(40, 60, 79, 0.5) linear-gradient(180deg, rgba(109, 124, 138, 0.575), rgba(40, 60, 79, 0.5)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(123, 140, 156, 0.5);
}

.module-midnight-50:active, .module-midnight-50.active,
.show > .module-midnight-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(36, 53, 70, 0.5);
  background-image: none;
  border-color: rgba(31, 47, 62, 0.5);
}

.module-midnight-50:active:focus, .module-midnight-50.active:focus,
.show > .module-midnight-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(123, 140, 156, 0.5);
}

.module-midnight-50:disabled, .module-midnight-50.disabled {
  color: #fff;
  background-color: rgba(53, 79, 104, 0.5);
  background-image: none;
  border-color: rgba(53, 79, 104, 0.5);
}

.module-midnight-60 {
  color: #fff;
  background: rgba(53, 79, 104, 0.6) linear-gradient(180deg, rgba(107, 127, 147, 0.66), rgba(53, 79, 104, 0.6)) repeat-x;
  border-color: rgba(53, 79, 104, 0.6);
}

.module-midnight-60 .icon-container {
  background-color: rgba(40, 60, 79, 0.6);
}

.module-midnight-60 .icon-container.light {
  background-color: rgba(79, 117, 155, 0.6);
}

.module-midnight-60:hover {
  color: #fff;
  background: rgba(40, 60, 79, 0.6) linear-gradient(180deg, rgba(98, 114, 129, 0.66), rgba(40, 60, 79, 0.6)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.6));
}

.module-midnight-60:focus, .module-midnight-60.focus {
  color: #fff;
  background: rgba(40, 60, 79, 0.6) linear-gradient(180deg, rgba(98, 114, 129, 0.66), rgba(40, 60, 79, 0.6)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(112, 130, 148, 0.5);
}

.module-midnight-60:active, .module-midnight-60.active,
.show > .module-midnight-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(36, 53, 70, 0.6);
  background-image: none;
  border-color: rgba(31, 47, 62, 0.6);
}

.module-midnight-60:active:focus, .module-midnight-60.active:focus,
.show > .module-midnight-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(112, 130, 148, 0.5);
}

.module-midnight-60:disabled, .module-midnight-60.disabled {
  color: #fff;
  background-color: rgba(53, 79, 104, 0.6);
  background-image: none;
  border-color: rgba(53, 79, 104, 0.6);
}

.module-midnight-70 {
  color: #fff;
  background: rgba(53, 79, 104, 0.7) linear-gradient(180deg, rgba(99, 120, 140, 0.745), rgba(53, 79, 104, 0.7)) repeat-x;
  border-color: rgba(53, 79, 104, 0.7);
}

.module-midnight-70 .icon-container {
  background-color: rgba(40, 60, 79, 0.7);
}

.module-midnight-70 .icon-container.light {
  background-color: rgba(79, 117, 155, 0.7);
}

.module-midnight-70:hover {
  color: #fff;
  background: rgba(40, 60, 79, 0.7) linear-gradient(180deg, rgba(89, 105, 121, 0.745), rgba(40, 60, 79, 0.7)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.7));
}

.module-midnight-70:focus, .module-midnight-70.focus {
  color: #fff;
  background: rgba(40, 60, 79, 0.7) linear-gradient(180deg, rgba(89, 105, 121, 0.745), rgba(40, 60, 79, 0.7)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(103, 122, 141, 0.5);
}

.module-midnight-70:active, .module-midnight-70.active,
.show > .module-midnight-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(36, 53, 70, 0.7);
  background-image: none;
  border-color: rgba(31, 47, 62, 0.7);
}

.module-midnight-70:active:focus, .module-midnight-70.active:focus,
.show > .module-midnight-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(103, 122, 141, 0.5);
}

.module-midnight-70:disabled, .module-midnight-70.disabled {
  color: #fff;
  background-color: rgba(53, 79, 104, 0.7);
  background-image: none;
  border-color: rgba(53, 79, 104, 0.7);
}

.module-midnight-80 {
  color: #fff;
  background: rgba(53, 79, 104, 0.8) linear-gradient(180deg, rgba(92, 114, 135, 0.83), rgba(53, 79, 104, 0.8)) repeat-x;
  border-color: rgba(53, 79, 104, 0.8);
}

.module-midnight-80 .icon-container {
  background-color: rgba(40, 60, 79, 0.8);
}

.module-midnight-80 .icon-container.light {
  background-color: rgba(79, 117, 155, 0.8);
}

.module-midnight-80:hover {
  color: #fff;
  background: rgba(40, 60, 79, 0.8) linear-gradient(180deg, rgba(82, 99, 115, 0.83), rgba(40, 60, 79, 0.8)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.8));
}

.module-midnight-80:focus, .module-midnight-80.focus {
  color: #fff;
  background: rgba(40, 60, 79, 0.8) linear-gradient(180deg, rgba(82, 99, 115, 0.83), rgba(40, 60, 79, 0.8)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(95, 116, 136, 0.5);
}

.module-midnight-80:active, .module-midnight-80.active,
.show > .module-midnight-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(36, 53, 70, 0.8);
  background-image: none;
  border-color: rgba(31, 47, 62, 0.8);
}

.module-midnight-80:active:focus, .module-midnight-80.active:focus,
.show > .module-midnight-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 116, 136, 0.5);
}

.module-midnight-80:disabled, .module-midnight-80.disabled {
  color: #fff;
  background-color: rgba(53, 79, 104, 0.8);
  background-image: none;
  border-color: rgba(53, 79, 104, 0.8);
}

.module-midnight-90 {
  color: #fff;
  background: rgba(53, 79, 104, 0.9) linear-gradient(180deg, rgba(86, 108, 130, 0.915), rgba(53, 79, 104, 0.9)) repeat-x;
  border-color: rgba(53, 79, 104, 0.9);
}

.module-midnight-90 .icon-container {
  background-color: rgba(40, 60, 79, 0.9);
}

.module-midnight-90 .icon-container.light {
  background-color: rgba(79, 117, 155, 0.9);
}

.module-midnight-90:hover {
  color: #fff;
  background: rgba(40, 60, 79, 0.9) linear-gradient(180deg, rgba(75, 93, 109, 0.915), rgba(40, 60, 79, 0.9)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.9));
}

.module-midnight-90:focus, .module-midnight-90.focus {
  color: #fff;
  background: rgba(40, 60, 79, 0.9) linear-gradient(180deg, rgba(75, 93, 109, 0.915), rgba(40, 60, 79, 0.9)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(89, 110, 131, 0.5);
}

.module-midnight-90:active, .module-midnight-90.active,
.show > .module-midnight-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(36, 53, 70, 0.9);
  background-image: none;
  border-color: rgba(31, 47, 62, 0.9);
}

.module-midnight-90:active:focus, .module-midnight-90.active:focus,
.show > .module-midnight-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 110, 131, 0.5);
}

.module-midnight-90:disabled, .module-midnight-90.disabled {
  color: #fff;
  background-color: rgba(53, 79, 104, 0.9);
  background-image: none;
  border-color: rgba(53, 79, 104, 0.9);
}

.module-cosmos {
  color: #fff;
  background: #273a4d linear-gradient(180deg, #455667, #273a4d) repeat-x;
  border-color: #273a4d;
}

.module-cosmos .icon-container {
  background-color: #1a2734;
}

.module-cosmos .icon-container.light {
  background-color: #416080;
}

.module-cosmos:hover {
  color: #fff;
  background: #1a2734 linear-gradient(180deg, #3a4651, #1a2734) repeat-x;
  border-color: color-yig(#1a2734);
}

.module-cosmos:focus, .module-cosmos.focus {
  color: #fff;
  background: #1a2734 linear-gradient(180deg, #3a4651, #1a2734) repeat-x;
  border-color: color-yig(#1a2734);
  box-shadow: 0 0 0 0.2rem rgba(71, 88, 104, 0.5);
}

.module-cosmos:active, .module-cosmos.active,
.show > .module-cosmos.dropdown-toggle {
  color: #fff;
  background-color: #16212b;
  background-image: none;
  border-color: #121a23;
}

.module-cosmos:active:focus, .module-cosmos.active:focus,
.show > .module-cosmos.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 88, 104, 0.5);
}

.module-cosmos:disabled, .module-cosmos.disabled {
  color: #fff;
  background-color: #273a4d;
  background-image: none;
  border-color: #273a4d;
}

.module-cosmos-10 {
  color: #fff;
  background: rgba(39, 58, 77, 0.1) linear-gradient(180deg, rgba(192, 202, 210, 0.235), rgba(39, 58, 77, 0.1)) repeat-x;
  border-color: rgba(39, 58, 77, 0.1);
}

.module-cosmos-10 .icon-container {
  background-color: rgba(26, 39, 52, 0.1);
}

.module-cosmos-10 .icon-container.light {
  background-color: rgba(65, 96, 128, 0.1);
}

.module-cosmos-10:hover {
  color: #fff;
  background: rgba(26, 39, 52, 0.1) linear-gradient(180deg, rgba(189, 198, 204, 0.235), rgba(26, 39, 52, 0.1)) repeat-x;
  border-color: color-yig(rgba(26, 39, 52, 0.1));
}

.module-cosmos-10:focus, .module-cosmos-10.focus {
  color: #fff;
  background: rgba(26, 39, 52, 0.1) linear-gradient(180deg, rgba(189, 198, 204, 0.235), rgba(26, 39, 52, 0.1)) repeat-x;
  border-color: color-yig(rgba(26, 39, 52, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(205, 210, 214, 0.5);
}

.module-cosmos-10:active, .module-cosmos-10.active,
.show > .module-cosmos-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(22, 33, 43, 0.1);
  background-image: none;
  border-color: rgba(18, 26, 35, 0.1);
}

.module-cosmos-10:active:focus, .module-cosmos-10.active:focus,
.show > .module-cosmos-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(205, 210, 214, 0.5);
}

.module-cosmos-10:disabled, .module-cosmos-10.disabled {
  color: #fff;
  background-color: rgba(39, 58, 77, 0.1);
  background-image: none;
  border-color: rgba(39, 58, 77, 0.1);
}

.module-cosmos-20 {
  color: #fff;
  background: rgba(39, 58, 77, 0.2) linear-gradient(180deg, rgba(161, 173, 183, 0.32), rgba(39, 58, 77, 0.2)) repeat-x;
  border-color: rgba(39, 58, 77, 0.2);
}

.module-cosmos-20 .icon-container {
  background-color: rgba(26, 39, 52, 0.2);
}

.module-cosmos-20 .icon-container.light {
  background-color: rgba(65, 96, 128, 0.2);
}

.module-cosmos-20:hover {
  color: #fff;
  background: rgba(26, 39, 52, 0.2) linear-gradient(180deg, rgba(156, 165, 173, 0.32), rgba(26, 39, 52, 0.2)) repeat-x;
  border-color: color-yig(rgba(26, 39, 52, 0.2));
}

.module-cosmos-20:focus, .module-cosmos-20.focus {
  color: #fff;
  background: rgba(26, 39, 52, 0.2) linear-gradient(180deg, rgba(156, 165, 173, 0.32), rgba(26, 39, 52, 0.2)) repeat-x;
  border-color: color-yig(rgba(26, 39, 52, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(172, 179, 186, 0.5);
}

.module-cosmos-20:active, .module-cosmos-20.active,
.show > .module-cosmos-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(22, 33, 43, 0.2);
  background-image: none;
  border-color: rgba(18, 26, 35, 0.2);
}

.module-cosmos-20:active:focus, .module-cosmos-20.active:focus,
.show > .module-cosmos-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(172, 179, 186, 0.5);
}

.module-cosmos-20:disabled, .module-cosmos-20.disabled {
  color: #fff;
  background-color: rgba(39, 58, 77, 0.2);
  background-image: none;
  border-color: rgba(39, 58, 77, 0.2);
}

.module-cosmos-30 {
  color: #fff;
  background: rgba(39, 58, 77, 0.3) linear-gradient(180deg, rgba(139, 152, 164, 0.405), rgba(39, 58, 77, 0.3)) repeat-x;
  border-color: rgba(39, 58, 77, 0.3);
}

.module-cosmos-30 .icon-container {
  background-color: rgba(26, 39, 52, 0.3);
}

.module-cosmos-30 .icon-container.light {
  background-color: rgba(65, 96, 128, 0.3);
}

.module-cosmos-30:hover {
  color: #fff;
  background: rgba(26, 39, 52, 0.3) linear-gradient(180deg, rgba(132, 142, 151, 0.405), rgba(26, 39, 52, 0.3)) repeat-x;
  border-color: color-yig(rgba(26, 39, 52, 0.3));
}

.module-cosmos-30:focus, .module-cosmos-30.focus {
  color: #fff;
  background: rgba(26, 39, 52, 0.3) linear-gradient(180deg, rgba(132, 142, 151, 0.405), rgba(26, 39, 52, 0.3)) repeat-x;
  border-color: color-yig(rgba(26, 39, 52, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(147, 157, 166, 0.5);
}

.module-cosmos-30:active, .module-cosmos-30.active,
.show > .module-cosmos-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(22, 33, 43, 0.3);
  background-image: none;
  border-color: rgba(18, 26, 35, 0.3);
}

.module-cosmos-30:active:focus, .module-cosmos-30.active:focus,
.show > .module-cosmos-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 157, 166, 0.5);
}

.module-cosmos-30:disabled, .module-cosmos-30.disabled {
  color: #fff;
  background-color: rgba(39, 58, 77, 0.3);
  background-image: none;
  border-color: rgba(39, 58, 77, 0.3);
}

.module-cosmos-40 {
  color: #fff;
  background: rgba(39, 58, 77, 0.4) linear-gradient(180deg, rgba(121, 135, 149, 0.49), rgba(39, 58, 77, 0.4)) repeat-x;
  border-color: rgba(39, 58, 77, 0.4);
}

.module-cosmos-40 .icon-container {
  background-color: rgba(26, 39, 52, 0.4);
}

.module-cosmos-40 .icon-container.light {
  background-color: rgba(65, 96, 128, 0.4);
}

.module-cosmos-40:hover {
  color: #fff;
  background: rgba(26, 39, 52, 0.4) linear-gradient(180deg, rgba(114, 124, 134, 0.49), rgba(26, 39, 52, 0.4)) repeat-x;
  border-color: color-yig(rgba(26, 39, 52, 0.4));
}

.module-cosmos-40:focus, .module-cosmos-40.focus {
  color: #fff;
  background: rgba(26, 39, 52, 0.4) linear-gradient(180deg, rgba(114, 124, 134, 0.49), rgba(26, 39, 52, 0.4)) repeat-x;
  border-color: color-yig(rgba(26, 39, 52, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(128, 140, 151, 0.5);
}

.module-cosmos-40:active, .module-cosmos-40.active,
.show > .module-cosmos-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(22, 33, 43, 0.4);
  background-image: none;
  border-color: rgba(18, 26, 35, 0.4);
}

.module-cosmos-40:active:focus, .module-cosmos-40.active:focus,
.show > .module-cosmos-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 140, 151, 0.5);
}

.module-cosmos-40:disabled, .module-cosmos-40.disabled {
  color: #fff;
  background-color: rgba(39, 58, 77, 0.4);
  background-image: none;
  border-color: rgba(39, 58, 77, 0.4);
}

.module-cosmos-50 {
  color: #fff;
  background: rgba(39, 58, 77, 0.5) linear-gradient(180deg, rgba(108, 123, 137, 0.575), rgba(39, 58, 77, 0.5)) repeat-x;
  border-color: rgba(39, 58, 77, 0.5);
}

.module-cosmos-50 .icon-container {
  background-color: rgba(26, 39, 52, 0.5);
}

.module-cosmos-50 .icon-container.light {
  background-color: rgba(65, 96, 128, 0.5);
}

.module-cosmos-50:hover {
  color: #fff;
  background: rgba(26, 39, 52, 0.5) linear-gradient(180deg, rgba(99, 110, 120, 0.575), rgba(26, 39, 52, 0.5)) repeat-x;
  border-color: color-yig(rgba(26, 39, 52, 0.5));
}

.module-cosmos-50:focus, .module-cosmos-50.focus {
  color: #fff;
  background: rgba(26, 39, 52, 0.5) linear-gradient(180deg, rgba(99, 110, 120, 0.575), rgba(26, 39, 52, 0.5)) repeat-x;
  border-color: color-yig(rgba(26, 39, 52, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(114, 126, 139, 0.5);
}

.module-cosmos-50:active, .module-cosmos-50.active,
.show > .module-cosmos-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(22, 33, 43, 0.5);
  background-image: none;
  border-color: rgba(18, 26, 35, 0.5);
}

.module-cosmos-50:active:focus, .module-cosmos-50.active:focus,
.show > .module-cosmos-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(114, 126, 139, 0.5);
}

.module-cosmos-50:disabled, .module-cosmos-50.disabled {
  color: #fff;
  background-color: rgba(39, 58, 77, 0.5);
  background-image: none;
  border-color: rgba(39, 58, 77, 0.5);
}

.module-cosmos-60 {
  color: #fff;
  background: rgba(39, 58, 77, 0.6) linear-gradient(180deg, rgba(97, 113, 127, 0.66), rgba(39, 58, 77, 0.6)) repeat-x;
  border-color: rgba(39, 58, 77, 0.6);
}

.module-cosmos-60 .icon-container {
  background-color: rgba(26, 39, 52, 0.6);
}

.module-cosmos-60 .icon-container.light {
  background-color: rgba(65, 96, 128, 0.6);
}

.module-cosmos-60:hover {
  color: #fff;
  background: rgba(26, 39, 52, 0.6) linear-gradient(180deg, rgba(88, 99, 109, 0.66), rgba(26, 39, 52, 0.6)) repeat-x;
  border-color: color-yig(rgba(26, 39, 52, 0.6));
}

.module-cosmos-60:focus, .module-cosmos-60.focus {
  color: #fff;
  background: rgba(26, 39, 52, 0.6) linear-gradient(180deg, rgba(88, 99, 109, 0.66), rgba(26, 39, 52, 0.6)) repeat-x;
  border-color: color-yig(rgba(26, 39, 52, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(102, 115, 129, 0.5);
}

.module-cosmos-60:active, .module-cosmos-60.active,
.show > .module-cosmos-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(22, 33, 43, 0.6);
  background-image: none;
  border-color: rgba(18, 26, 35, 0.6);
}

.module-cosmos-60:active:focus, .module-cosmos-60.active:focus,
.show > .module-cosmos-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 115, 129, 0.5);
}

.module-cosmos-60:disabled, .module-cosmos-60.disabled {
  color: #fff;
  background-color: rgba(39, 58, 77, 0.6);
  background-image: none;
  border-color: rgba(39, 58, 77, 0.6);
}

.module-cosmos-70 {
  color: #fff;
  background: rgba(39, 58, 77, 0.7) linear-gradient(180deg, rgba(88, 104, 120, 0.745), rgba(39, 58, 77, 0.7)) repeat-x;
  border-color: rgba(39, 58, 77, 0.7);
}

.module-cosmos-70 .icon-container {
  background-color: rgba(26, 39, 52, 0.7);
}

.module-cosmos-70 .icon-container.light {
  background-color: rgba(65, 96, 128, 0.7);
}

.module-cosmos-70:hover {
  color: #fff;
  background: rgba(26, 39, 52, 0.7) linear-gradient(180deg, rgba(78, 90, 101, 0.745), rgba(26, 39, 52, 0.7)) repeat-x;
  border-color: color-yig(rgba(26, 39, 52, 0.7));
}

.module-cosmos-70:focus, .module-cosmos-70.focus {
  color: #fff;
  background: rgba(26, 39, 52, 0.7) linear-gradient(180deg, rgba(78, 90, 101, 0.745), rgba(26, 39, 52, 0.7)) repeat-x;
  border-color: color-yig(rgba(26, 39, 52, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(92, 107, 121, 0.5);
}

.module-cosmos-70:active, .module-cosmos-70.active,
.show > .module-cosmos-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(22, 33, 43, 0.7);
  background-image: none;
  border-color: rgba(18, 26, 35, 0.7);
}

.module-cosmos-70:active:focus, .module-cosmos-70.active:focus,
.show > .module-cosmos-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 107, 121, 0.5);
}

.module-cosmos-70:disabled, .module-cosmos-70.disabled {
  color: #fff;
  background-color: rgba(39, 58, 77, 0.7);
  background-image: none;
  border-color: rgba(39, 58, 77, 0.7);
}

.module-cosmos-80 {
  color: #fff;
  background: rgba(39, 58, 77, 0.8) linear-gradient(180deg, rgba(81, 97, 113, 0.83), rgba(39, 58, 77, 0.8)) repeat-x;
  border-color: rgba(39, 58, 77, 0.8);
}

.module-cosmos-80 .icon-container {
  background-color: rgba(26, 39, 52, 0.8);
}

.module-cosmos-80 .icon-container.light {
  background-color: rgba(65, 96, 128, 0.8);
}

.module-cosmos-80:hover {
  color: #fff;
  background: rgba(26, 39, 52, 0.8) linear-gradient(180deg, rgba(70, 82, 93, 0.83), rgba(26, 39, 52, 0.8)) repeat-x;
  border-color: color-yig(rgba(26, 39, 52, 0.8));
}

.module-cosmos-80:focus, .module-cosmos-80.focus {
  color: #fff;
  background: rgba(26, 39, 52, 0.8) linear-gradient(180deg, rgba(70, 82, 93, 0.83), rgba(26, 39, 52, 0.8)) repeat-x;
  border-color: color-yig(rgba(26, 39, 52, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(84, 99, 114, 0.5);
}

.module-cosmos-80:active, .module-cosmos-80.active,
.show > .module-cosmos-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(22, 33, 43, 0.8);
  background-image: none;
  border-color: rgba(18, 26, 35, 0.8);
}

.module-cosmos-80:active:focus, .module-cosmos-80.active:focus,
.show > .module-cosmos-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(84, 99, 114, 0.5);
}

.module-cosmos-80:disabled, .module-cosmos-80.disabled {
  color: #fff;
  background-color: rgba(39, 58, 77, 0.8);
  background-image: none;
  border-color: rgba(39, 58, 77, 0.8);
}

.module-cosmos-90 {
  color: #fff;
  background: rgba(39, 58, 77, 0.9) linear-gradient(180deg, rgba(74, 91, 108, 0.915), rgba(39, 58, 77, 0.9)) repeat-x;
  border-color: rgba(39, 58, 77, 0.9);
}

.module-cosmos-90 .icon-container {
  background-color: rgba(26, 39, 52, 0.9);
}

.module-cosmos-90 .icon-container.light {
  background-color: rgba(65, 96, 128, 0.9);
}

.module-cosmos-90:hover {
  color: #fff;
  background: rgba(26, 39, 52, 0.9) linear-gradient(180deg, rgba(64, 75, 87, 0.915), rgba(26, 39, 52, 0.9)) repeat-x;
  border-color: color-yig(rgba(26, 39, 52, 0.9));
}

.module-cosmos-90:focus, .module-cosmos-90.focus {
  color: #fff;
  background: rgba(26, 39, 52, 0.9) linear-gradient(180deg, rgba(64, 75, 87, 0.915), rgba(26, 39, 52, 0.9)) repeat-x;
  border-color: color-yig(rgba(26, 39, 52, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(77, 93, 109, 0.5);
}

.module-cosmos-90:active, .module-cosmos-90.active,
.show > .module-cosmos-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(22, 33, 43, 0.9);
  background-image: none;
  border-color: rgba(18, 26, 35, 0.9);
}

.module-cosmos-90:active:focus, .module-cosmos-90.active:focus,
.show > .module-cosmos-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 93, 109, 0.5);
}

.module-cosmos-90:disabled, .module-cosmos-90.disabled {
  color: #fff;
  background-color: rgba(39, 58, 77, 0.9);
  background-image: none;
  border-color: rgba(39, 58, 77, 0.9);
}

.module-deepdark {
  color: #fff;
  background: #0b1b2c linear-gradient(180deg, #2d3c4b, #0b1b2c) repeat-x;
  border-color: #0b1b2c;
}

.module-deepdark .icon-container {
  background-color: #03080d;
}

.module-deepdark .icon-container.light {
  background-color: #1a4169;
}

.module-deepdark:hover {
  color: #fff;
  background: #03080d linear-gradient(180deg, #272c31, #03080d) repeat-x;
  border-color: color-yig(#03080d);
}

.module-deepdark:focus, .module-deepdark.focus {
  color: #fff;
  background: #03080d linear-gradient(180deg, #272c31, #03080d) repeat-x;
  border-color: color-yig(#03080d);
  box-shadow: 0 0 0 0.2rem rgba(48, 61, 76, 0.5);
}

.module-deepdark:active, .module-deepdark.active,
.show > .module-deepdark.dropdown-toggle {
  color: #fff;
  background-color: #010203;
  background-image: none;
  border-color: black;
}

.module-deepdark:active:focus, .module-deepdark.active:focus,
.show > .module-deepdark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(48, 61, 76, 0.5);
}

.module-deepdark:disabled, .module-deepdark.disabled {
  color: #fff;
  background-color: #0b1b2c;
  background-image: none;
  border-color: #0b1b2c;
}

.module-deepdark-10 {
  color: #fff;
  background: rgba(11, 27, 44, 0.1) linear-gradient(180deg, rgba(186, 195, 203, 0.235), rgba(11, 27, 44, 0.1)) repeat-x;
  border-color: rgba(11, 27, 44, 0.1);
}

.module-deepdark-10 .icon-container {
  background-color: rgba(3, 8, 13, 0.1);
}

.module-deepdark-10 .icon-container.light {
  background-color: rgba(26, 65, 105, 0.1);
}

.module-deepdark-10:hover {
  color: #fff;
  background: rgba(3, 8, 13, 0.1) linear-gradient(180deg, rgba(184, 191, 196, 0.235), rgba(3, 8, 13, 0.1)) repeat-x;
  border-color: color-yig(rgba(3, 8, 13, 0.1));
}

.module-deepdark-10:focus, .module-deepdark-10.focus {
  color: #fff;
  background: rgba(3, 8, 13, 0.1) linear-gradient(180deg, rgba(184, 191, 196, 0.235), rgba(3, 8, 13, 0.1)) repeat-x;
  border-color: color-yig(rgba(3, 8, 13, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(199, 203, 207, 0.5);
}

.module-deepdark-10:active, .module-deepdark-10.active,
.show > .module-deepdark-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(1, 2, 3, 0.1);
  background-image: none;
  border-color: rgba(0, 0, 0, 0.1);
}

.module-deepdark-10:active:focus, .module-deepdark-10.active:focus,
.show > .module-deepdark-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(199, 203, 207, 0.5);
}

.module-deepdark-10:disabled, .module-deepdark-10.disabled {
  color: #fff;
  background-color: rgba(11, 27, 44, 0.1);
  background-image: none;
  border-color: rgba(11, 27, 44, 0.1);
}

.module-deepdark-20 {
  color: #fff;
  background: rgba(11, 27, 44, 0.2) linear-gradient(180deg, rgba(150, 161, 170, 0.32), rgba(11, 27, 44, 0.2)) repeat-x;
  border-color: rgba(11, 27, 44, 0.2);
}

.module-deepdark-20 .icon-container {
  background-color: rgba(3, 8, 13, 0.2);
}

.module-deepdark-20 .icon-container.light {
  background-color: rgba(26, 65, 105, 0.2);
}

.module-deepdark-20:hover {
  color: #fff;
  background: rgba(3, 8, 13, 0.2) linear-gradient(180deg, rgba(147, 154, 159, 0.32), rgba(3, 8, 13, 0.2)) repeat-x;
  border-color: color-yig(rgba(3, 8, 13, 0.2));
}

.module-deepdark-20:focus, .module-deepdark-20.focus {
  color: #fff;
  background: rgba(3, 8, 13, 0.2) linear-gradient(180deg, rgba(147, 154, 159, 0.32), rgba(3, 8, 13, 0.2)) repeat-x;
  border-color: color-yig(rgba(3, 8, 13, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(161, 167, 173, 0.5);
}

.module-deepdark-20:active, .module-deepdark-20.active,
.show > .module-deepdark-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(1, 2, 3, 0.2);
  background-image: none;
  border-color: rgba(0, 0, 0, 0.2);
}

.module-deepdark-20:active:focus, .module-deepdark-20.active:focus,
.show > .module-deepdark-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(161, 167, 173, 0.5);
}

.module-deepdark-20:disabled, .module-deepdark-20.disabled {
  color: #fff;
  background-color: rgba(11, 27, 44, 0.2);
  background-image: none;
  border-color: rgba(11, 27, 44, 0.2);
}

.module-deepdark-30 {
  color: #fff;
  background: rgba(11, 27, 44, 0.3) linear-gradient(180deg, rgba(125, 136, 147, 0.405), rgba(11, 27, 44, 0.3)) repeat-x;
  border-color: rgba(11, 27, 44, 0.3);
}

.module-deepdark-30 .icon-container {
  background-color: rgba(3, 8, 13, 0.3);
}

.module-deepdark-30 .icon-container.light {
  background-color: rgba(26, 65, 105, 0.3);
}

.module-deepdark-30:hover {
  color: #fff;
  background: rgba(3, 8, 13, 0.3) linear-gradient(180deg, rgba(121, 127, 132, 0.405), rgba(3, 8, 13, 0.3)) repeat-x;
  border-color: color-yig(rgba(3, 8, 13, 0.3));
}

.module-deepdark-30:focus, .module-deepdark-30.focus {
  color: #fff;
  background: rgba(3, 8, 13, 0.3) linear-gradient(180deg, rgba(121, 127, 132, 0.405), rgba(3, 8, 13, 0.3)) repeat-x;
  border-color: color-yig(rgba(3, 8, 13, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(133, 141, 150, 0.5);
}

.module-deepdark-30:active, .module-deepdark-30.active,
.show > .module-deepdark-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(1, 2, 3, 0.3);
  background-image: none;
  border-color: rgba(0, 0, 0, 0.3);
}

.module-deepdark-30:active:focus, .module-deepdark-30.active:focus,
.show > .module-deepdark-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 141, 150, 0.5);
}

.module-deepdark-30:disabled, .module-deepdark-30.disabled {
  color: #fff;
  background-color: rgba(11, 27, 44, 0.3);
  background-image: none;
  border-color: rgba(11, 27, 44, 0.3);
}

.module-deepdark-40 {
  color: #fff;
  background: rgba(11, 27, 44, 0.4) linear-gradient(180deg, rgba(105, 117, 129, 0.49), rgba(11, 27, 44, 0.4)) repeat-x;
  border-color: rgba(11, 27, 44, 0.4);
}

.module-deepdark-40 .icon-container {
  background-color: rgba(3, 8, 13, 0.4);
}

.module-deepdark-40 .icon-container.light {
  background-color: rgba(26, 65, 105, 0.4);
}

.module-deepdark-40:hover {
  color: #fff;
  background: rgba(3, 8, 13, 0.4) linear-gradient(180deg, rgba(100, 106, 111, 0.49), rgba(3, 8, 13, 0.4)) repeat-x;
  border-color: color-yig(rgba(3, 8, 13, 0.4));
}

.module-deepdark-40:focus, .module-deepdark-40.focus {
  color: #fff;
  background: rgba(3, 8, 13, 0.4) linear-gradient(180deg, rgba(100, 106, 111, 0.49), rgba(3, 8, 13, 0.4)) repeat-x;
  border-color: color-yig(rgba(3, 8, 13, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(112, 121, 131, 0.5);
}

.module-deepdark-40:active, .module-deepdark-40.active,
.show > .module-deepdark-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(1, 2, 3, 0.4);
  background-image: none;
  border-color: rgba(0, 0, 0, 0.4);
}

.module-deepdark-40:active:focus, .module-deepdark-40.active:focus,
.show > .module-deepdark-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(112, 121, 131, 0.5);
}

.module-deepdark-40:disabled, .module-deepdark-40.disabled {
  color: #fff;
  background-color: rgba(11, 27, 44, 0.4);
  background-image: none;
  border-color: rgba(11, 27, 44, 0.4);
}

.module-deepdark-50 {
  color: #fff;
  background: rgba(11, 27, 44, 0.5) linear-gradient(180deg, rgba(90, 102, 115, 0.575), rgba(11, 27, 44, 0.5)) repeat-x;
  border-color: rgba(11, 27, 44, 0.5);
}

.module-deepdark-50 .icon-container {
  background-color: rgba(3, 8, 13, 0.5);
}

.module-deepdark-50 .icon-container.light {
  background-color: rgba(26, 65, 105, 0.5);
}

.module-deepdark-50:hover {
  color: #fff;
  background: rgba(3, 8, 13, 0.5) linear-gradient(180deg, rgba(85, 90, 95, 0.575), rgba(3, 8, 13, 0.5)) repeat-x;
  border-color: color-yig(rgba(3, 8, 13, 0.5));
}

.module-deepdark-50:focus, .module-deepdark-50.focus {
  color: #fff;
  background: rgba(3, 8, 13, 0.5) linear-gradient(180deg, rgba(85, 90, 95, 0.575), rgba(3, 8, 13, 0.5)) repeat-x;
  border-color: color-yig(rgba(3, 8, 13, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(95, 106, 117, 0.5);
}

.module-deepdark-50:active, .module-deepdark-50.active,
.show > .module-deepdark-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(1, 2, 3, 0.5);
  background-image: none;
  border-color: rgba(0, 0, 0, 0.5);
}

.module-deepdark-50:active:focus, .module-deepdark-50.active:focus,
.show > .module-deepdark-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 106, 117, 0.5);
}

.module-deepdark-50:disabled, .module-deepdark-50.disabled {
  color: #fff;
  background-color: rgba(11, 27, 44, 0.5);
  background-image: none;
  border-color: rgba(11, 27, 44, 0.5);
}

.module-deepdark-60 {
  color: #fff;
  background: rgba(11, 27, 44, 0.6) linear-gradient(180deg, rgba(77, 91, 104, 0.66), rgba(11, 27, 44, 0.6)) repeat-x;
  border-color: rgba(11, 27, 44, 0.6);
}

.module-deepdark-60 .icon-container {
  background-color: rgba(3, 8, 13, 0.6);
}

.module-deepdark-60 .icon-container.light {
  background-color: rgba(26, 65, 105, 0.6);
}

.module-deepdark-60:hover {
  color: #fff;
  background: rgba(3, 8, 13, 0.6) linear-gradient(180deg, rgba(72, 77, 82, 0.66), rgba(3, 8, 13, 0.6)) repeat-x;
  border-color: color-yig(rgba(3, 8, 13, 0.6));
}

.module-deepdark-60:focus, .module-deepdark-60.focus {
  color: #fff;
  background: rgba(3, 8, 13, 0.6) linear-gradient(180deg, rgba(72, 77, 82, 0.66), rgba(3, 8, 13, 0.6)) repeat-x;
  border-color: color-yig(rgba(3, 8, 13, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(82, 94, 106, 0.5);
}

.module-deepdark-60:active, .module-deepdark-60.active,
.show > .module-deepdark-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(1, 2, 3, 0.6);
  background-image: none;
  border-color: rgba(0, 0, 0, 0.6);
}

.module-deepdark-60:active:focus, .module-deepdark-60.active:focus,
.show > .module-deepdark-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 94, 106, 0.5);
}

.module-deepdark-60:disabled, .module-deepdark-60.disabled {
  color: #fff;
  background-color: rgba(11, 27, 44, 0.6);
  background-image: none;
  border-color: rgba(11, 27, 44, 0.6);
}

.module-deepdark-70 {
  color: #fff;
  background: rgba(11, 27, 44, 0.7) linear-gradient(180deg, rgba(67, 81, 95, 0.745), rgba(11, 27, 44, 0.7)) repeat-x;
  border-color: rgba(11, 27, 44, 0.7);
}

.module-deepdark-70 .icon-container {
  background-color: rgba(3, 8, 13, 0.7);
}

.module-deepdark-70 .icon-container.light {
  background-color: rgba(26, 65, 105, 0.7);
}

.module-deepdark-70:hover {
  color: #fff;
  background: rgba(3, 8, 13, 0.7) linear-gradient(180deg, rgba(61, 67, 72, 0.745), rgba(3, 8, 13, 0.7)) repeat-x;
  border-color: color-yig(rgba(3, 8, 13, 0.7));
}

.module-deepdark-70:focus, .module-deepdark-70.focus {
  color: #fff;
  background: rgba(3, 8, 13, 0.7) linear-gradient(180deg, rgba(61, 67, 72, 0.745), rgba(3, 8, 13, 0.7)) repeat-x;
  border-color: color-yig(rgba(3, 8, 13, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(71, 83, 96, 0.5);
}

.module-deepdark-70:active, .module-deepdark-70.active,
.show > .module-deepdark-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(1, 2, 3, 0.7);
  background-image: none;
  border-color: rgba(0, 0, 0, 0.7);
}

.module-deepdark-70:active:focus, .module-deepdark-70.active:focus,
.show > .module-deepdark-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 83, 96, 0.5);
}

.module-deepdark-70:disabled, .module-deepdark-70.disabled {
  color: #fff;
  background-color: rgba(11, 27, 44, 0.7);
  background-image: none;
  border-color: rgba(11, 27, 44, 0.7);
}

.module-deepdark-80 {
  color: #fff;
  background: rgba(11, 27, 44, 0.8) linear-gradient(180deg, rgba(59, 73, 87, 0.83), rgba(11, 27, 44, 0.8)) repeat-x;
  border-color: rgba(11, 27, 44, 0.8);
}

.module-deepdark-80 .icon-container {
  background-color: rgba(3, 8, 13, 0.8);
}

.module-deepdark-80 .icon-container.light {
  background-color: rgba(26, 65, 105, 0.8);
}

.module-deepdark-80:hover {
  color: #fff;
  background: rgba(3, 8, 13, 0.8) linear-gradient(180deg, rgba(52, 58, 63, 0.83), rgba(3, 8, 13, 0.8)) repeat-x;
  border-color: color-yig(rgba(3, 8, 13, 0.8));
}

.module-deepdark-80:focus, .module-deepdark-80.focus {
  color: #fff;
  background: rgba(3, 8, 13, 0.8) linear-gradient(180deg, rgba(52, 58, 63, 0.83), rgba(3, 8, 13, 0.8)) repeat-x;
  border-color: color-yig(rgba(3, 8, 13, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(62, 75, 88, 0.5);
}

.module-deepdark-80:active, .module-deepdark-80.active,
.show > .module-deepdark-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(1, 2, 3, 0.8);
  background-image: none;
  border-color: rgba(0, 0, 0, 0.8);
}

.module-deepdark-80:active:focus, .module-deepdark-80.active:focus,
.show > .module-deepdark-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(62, 75, 88, 0.5);
}

.module-deepdark-80:disabled, .module-deepdark-80.disabled {
  color: #fff;
  background-color: rgba(11, 27, 44, 0.8);
  background-image: none;
  border-color: rgba(11, 27, 44, 0.8);
}

.module-deepdark-90 {
  color: #fff;
  background: rgba(11, 27, 44, 0.9) linear-gradient(180deg, rgba(51, 66, 81, 0.915), rgba(11, 27, 44, 0.9)) repeat-x;
  border-color: rgba(11, 27, 44, 0.9);
}

.module-deepdark-90 .icon-container {
  background-color: rgba(3, 8, 13, 0.9);
}

.module-deepdark-90 .icon-container.light {
  background-color: rgba(26, 65, 105, 0.9);
}

.module-deepdark-90:hover {
  color: #fff;
  background: rgba(3, 8, 13, 0.9) linear-gradient(180deg, rgba(45, 50, 55, 0.915), rgba(3, 8, 13, 0.9)) repeat-x;
  border-color: color-yig(rgba(3, 8, 13, 0.9));
}

.module-deepdark-90:focus, .module-deepdark-90.focus {
  color: #fff;
  background: rgba(3, 8, 13, 0.9) linear-gradient(180deg, rgba(45, 50, 55, 0.915), rgba(3, 8, 13, 0.9)) repeat-x;
  border-color: color-yig(rgba(3, 8, 13, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(54, 67, 81, 0.5);
}

.module-deepdark-90:active, .module-deepdark-90.active,
.show > .module-deepdark-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(1, 2, 3, 0.9);
  background-image: none;
  border-color: rgba(0, 0, 0, 0.9);
}

.module-deepdark-90:active:focus, .module-deepdark-90.active:focus,
.show > .module-deepdark-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(54, 67, 81, 0.5);
}

.module-deepdark-90:disabled, .module-deepdark-90.disabled {
  color: #fff;
  background-color: rgba(11, 27, 44, 0.9);
  background-image: none;
  border-color: rgba(11, 27, 44, 0.9);
}

.module-primary {
  color: #fff;
  background: #354f68 linear-gradient(180deg, #51687e, #354f68) repeat-x;
  border-color: #354f68;
}

.module-primary .icon-container {
  background-color: #283c4f;
}

.module-primary .icon-container.light {
  background-color: #4f759b;
}

.module-primary:hover {
  color: #fff;
  background: #283c4f linear-gradient(180deg, #465868, #283c4f) repeat-x;
  border-color: color-yig(#283c4f);
}

.module-primary:focus, .module-primary.focus {
  color: #fff;
  background: #283c4f linear-gradient(180deg, #465868, #283c4f) repeat-x;
  border-color: color-yig(#283c4f);
  box-shadow: 0 0 0 0.2rem rgba(83, 105, 127, 0.5);
}

.module-primary:active, .module-primary.active,
.show > .module-primary.dropdown-toggle {
  color: #fff;
  background-color: #243546;
  background-image: none;
  border-color: #1f2f3e;
}

.module-primary:active:focus, .module-primary.active:focus,
.show > .module-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(83, 105, 127, 0.5);
}

.module-primary:disabled, .module-primary.disabled {
  color: #fff;
  background-color: #354f68;
  background-image: none;
  border-color: #354f68;
}

.module-primary-10 {
  color: #fff;
  background: rgba(53, 79, 104, 0.1) linear-gradient(180deg, rgba(196, 207, 216, 0.235), rgba(53, 79, 104, 0.1)) repeat-x;
  border-color: rgba(53, 79, 104, 0.1);
}

.module-primary-10 .icon-container {
  background-color: rgba(40, 60, 79, 0.1);
}

.module-primary-10 .icon-container.light {
  background-color: rgba(79, 117, 155, 0.1);
}

.module-primary-10:hover {
  color: #fff;
  background: rgba(40, 60, 79, 0.1) linear-gradient(180deg, rgba(193, 202, 211, 0.235), rgba(40, 60, 79, 0.1)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.1));
}

.module-primary-10:focus, .module-primary-10.focus {
  color: #fff;
  background: rgba(40, 60, 79, 0.1) linear-gradient(180deg, rgba(193, 202, 211, 0.235), rgba(40, 60, 79, 0.1)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(209, 215, 220, 0.5);
}

.module-primary-10:active, .module-primary-10.active,
.show > .module-primary-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(36, 53, 70, 0.1);
  background-image: none;
  border-color: rgba(31, 47, 62, 0.1);
}

.module-primary-10:active:focus, .module-primary-10.active:focus,
.show > .module-primary-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 215, 220, 0.5);
}

.module-primary-10:disabled, .module-primary-10.disabled {
  color: #fff;
  background-color: rgba(53, 79, 104, 0.1);
  background-image: none;
  border-color: rgba(53, 79, 104, 0.1);
}

.module-primary-20 {
  color: #fff;
  background: rgba(53, 79, 104, 0.2) linear-gradient(180deg, rgba(167, 181, 194, 0.32), rgba(53, 79, 104, 0.2)) repeat-x;
  border-color: rgba(53, 79, 104, 0.2);
}

.module-primary-20 .icon-container {
  background-color: rgba(40, 60, 79, 0.2);
}

.module-primary-20 .icon-container.light {
  background-color: rgba(79, 117, 155, 0.2);
}

.module-primary-20:hover {
  color: #fff;
  background: rgba(40, 60, 79, 0.2) linear-gradient(180deg, rgba(162, 173, 184, 0.32), rgba(40, 60, 79, 0.2)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.2));
}

.module-primary-20:focus, .module-primary-20.focus {
  color: #fff;
  background: rgba(40, 60, 79, 0.2) linear-gradient(180deg, rgba(162, 173, 184, 0.32), rgba(40, 60, 79, 0.2)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(177, 187, 197, 0.5);
}

.module-primary-20:active, .module-primary-20.active,
.show > .module-primary-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(36, 53, 70, 0.2);
  background-image: none;
  border-color: rgba(31, 47, 62, 0.2);
}

.module-primary-20:active:focus, .module-primary-20.active:focus,
.show > .module-primary-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(177, 187, 197, 0.5);
}

.module-primary-20:disabled, .module-primary-20.disabled {
  color: #fff;
  background-color: rgba(53, 79, 104, 0.2);
  background-image: none;
  border-color: rgba(53, 79, 104, 0.2);
}

.module-primary-30 {
  color: #fff;
  background: rgba(53, 79, 104, 0.3) linear-gradient(180deg, rgba(146, 162, 177, 0.405), rgba(53, 79, 104, 0.3)) repeat-x;
  border-color: rgba(53, 79, 104, 0.3);
}

.module-primary-30 .icon-container {
  background-color: rgba(40, 60, 79, 0.3);
}

.module-primary-30 .icon-container.light {
  background-color: rgba(79, 117, 155, 0.3);
}

.module-primary-30:hover {
  color: #fff;
  background: rgba(40, 60, 79, 0.3) linear-gradient(180deg, rgba(139, 152, 164, 0.405), rgba(40, 60, 79, 0.3)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.3));
}

.module-primary-30:focus, .module-primary-30.focus {
  color: #fff;
  background: rgba(40, 60, 79, 0.3) linear-gradient(180deg, rgba(139, 152, 164, 0.405), rgba(40, 60, 79, 0.3)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(154, 167, 180, 0.5);
}

.module-primary-30:active, .module-primary-30.active,
.show > .module-primary-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(36, 53, 70, 0.3);
  background-image: none;
  border-color: rgba(31, 47, 62, 0.3);
}

.module-primary-30:active:focus, .module-primary-30.active:focus,
.show > .module-primary-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(154, 167, 180, 0.5);
}

.module-primary-30:disabled, .module-primary-30.disabled {
  color: #fff;
  background-color: rgba(53, 79, 104, 0.3);
  background-image: none;
  border-color: rgba(53, 79, 104, 0.3);
}

.module-primary-40 {
  color: #fff;
  background: rgba(53, 79, 104, 0.4) linear-gradient(180deg, rgba(130, 148, 164, 0.49), rgba(53, 79, 104, 0.4)) repeat-x;
  border-color: rgba(53, 79, 104, 0.4);
}

.module-primary-40 .icon-container {
  background-color: rgba(40, 60, 79, 0.4);
}

.module-primary-40 .icon-container.light {
  background-color: rgba(79, 117, 155, 0.4);
}

.module-primary-40:hover {
  color: #fff;
  background: rgba(40, 60, 79, 0.4) linear-gradient(180deg, rgba(122, 136, 150, 0.49), rgba(40, 60, 79, 0.4)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.4));
}

.module-primary-40:focus, .module-primary-40.focus {
  color: #fff;
  background: rgba(40, 60, 79, 0.4) linear-gradient(180deg, rgba(122, 136, 150, 0.49), rgba(40, 60, 79, 0.4)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(137, 152, 166, 0.5);
}

.module-primary-40:active, .module-primary-40.active,
.show > .module-primary-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(36, 53, 70, 0.4);
  background-image: none;
  border-color: rgba(31, 47, 62, 0.4);
}

.module-primary-40:active:focus, .module-primary-40.active:focus,
.show > .module-primary-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(137, 152, 166, 0.5);
}

.module-primary-40:disabled, .module-primary-40.disabled {
  color: #fff;
  background-color: rgba(53, 79, 104, 0.4);
  background-image: none;
  border-color: rgba(53, 79, 104, 0.4);
}

.module-primary-50 {
  color: #fff;
  background: rgba(53, 79, 104, 0.5) linear-gradient(180deg, rgba(117, 136, 155, 0.575), rgba(53, 79, 104, 0.5)) repeat-x;
  border-color: rgba(53, 79, 104, 0.5);
}

.module-primary-50 .icon-container {
  background-color: rgba(40, 60, 79, 0.5);
}

.module-primary-50 .icon-container.light {
  background-color: rgba(79, 117, 155, 0.5);
}

.module-primary-50:hover {
  color: #fff;
  background: rgba(40, 60, 79, 0.5) linear-gradient(180deg, rgba(109, 124, 138, 0.575), rgba(40, 60, 79, 0.5)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.5));
}

.module-primary-50:focus, .module-primary-50.focus {
  color: #fff;
  background: rgba(40, 60, 79, 0.5) linear-gradient(180deg, rgba(109, 124, 138, 0.575), rgba(40, 60, 79, 0.5)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(123, 140, 156, 0.5);
}

.module-primary-50:active, .module-primary-50.active,
.show > .module-primary-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(36, 53, 70, 0.5);
  background-image: none;
  border-color: rgba(31, 47, 62, 0.5);
}

.module-primary-50:active:focus, .module-primary-50.active:focus,
.show > .module-primary-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(123, 140, 156, 0.5);
}

.module-primary-50:disabled, .module-primary-50.disabled {
  color: #fff;
  background-color: rgba(53, 79, 104, 0.5);
  background-image: none;
  border-color: rgba(53, 79, 104, 0.5);
}

.module-primary-60 {
  color: #fff;
  background: rgba(53, 79, 104, 0.6) linear-gradient(180deg, rgba(107, 127, 147, 0.66), rgba(53, 79, 104, 0.6)) repeat-x;
  border-color: rgba(53, 79, 104, 0.6);
}

.module-primary-60 .icon-container {
  background-color: rgba(40, 60, 79, 0.6);
}

.module-primary-60 .icon-container.light {
  background-color: rgba(79, 117, 155, 0.6);
}

.module-primary-60:hover {
  color: #fff;
  background: rgba(40, 60, 79, 0.6) linear-gradient(180deg, rgba(98, 114, 129, 0.66), rgba(40, 60, 79, 0.6)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.6));
}

.module-primary-60:focus, .module-primary-60.focus {
  color: #fff;
  background: rgba(40, 60, 79, 0.6) linear-gradient(180deg, rgba(98, 114, 129, 0.66), rgba(40, 60, 79, 0.6)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(112, 130, 148, 0.5);
}

.module-primary-60:active, .module-primary-60.active,
.show > .module-primary-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(36, 53, 70, 0.6);
  background-image: none;
  border-color: rgba(31, 47, 62, 0.6);
}

.module-primary-60:active:focus, .module-primary-60.active:focus,
.show > .module-primary-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(112, 130, 148, 0.5);
}

.module-primary-60:disabled, .module-primary-60.disabled {
  color: #fff;
  background-color: rgba(53, 79, 104, 0.6);
  background-image: none;
  border-color: rgba(53, 79, 104, 0.6);
}

.module-primary-70 {
  color: #fff;
  background: rgba(53, 79, 104, 0.7) linear-gradient(180deg, rgba(99, 120, 140, 0.745), rgba(53, 79, 104, 0.7)) repeat-x;
  border-color: rgba(53, 79, 104, 0.7);
}

.module-primary-70 .icon-container {
  background-color: rgba(40, 60, 79, 0.7);
}

.module-primary-70 .icon-container.light {
  background-color: rgba(79, 117, 155, 0.7);
}

.module-primary-70:hover {
  color: #fff;
  background: rgba(40, 60, 79, 0.7) linear-gradient(180deg, rgba(89, 105, 121, 0.745), rgba(40, 60, 79, 0.7)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.7));
}

.module-primary-70:focus, .module-primary-70.focus {
  color: #fff;
  background: rgba(40, 60, 79, 0.7) linear-gradient(180deg, rgba(89, 105, 121, 0.745), rgba(40, 60, 79, 0.7)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(103, 122, 141, 0.5);
}

.module-primary-70:active, .module-primary-70.active,
.show > .module-primary-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(36, 53, 70, 0.7);
  background-image: none;
  border-color: rgba(31, 47, 62, 0.7);
}

.module-primary-70:active:focus, .module-primary-70.active:focus,
.show > .module-primary-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(103, 122, 141, 0.5);
}

.module-primary-70:disabled, .module-primary-70.disabled {
  color: #fff;
  background-color: rgba(53, 79, 104, 0.7);
  background-image: none;
  border-color: rgba(53, 79, 104, 0.7);
}

.module-primary-80 {
  color: #fff;
  background: rgba(53, 79, 104, 0.8) linear-gradient(180deg, rgba(92, 114, 135, 0.83), rgba(53, 79, 104, 0.8)) repeat-x;
  border-color: rgba(53, 79, 104, 0.8);
}

.module-primary-80 .icon-container {
  background-color: rgba(40, 60, 79, 0.8);
}

.module-primary-80 .icon-container.light {
  background-color: rgba(79, 117, 155, 0.8);
}

.module-primary-80:hover {
  color: #fff;
  background: rgba(40, 60, 79, 0.8) linear-gradient(180deg, rgba(82, 99, 115, 0.83), rgba(40, 60, 79, 0.8)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.8));
}

.module-primary-80:focus, .module-primary-80.focus {
  color: #fff;
  background: rgba(40, 60, 79, 0.8) linear-gradient(180deg, rgba(82, 99, 115, 0.83), rgba(40, 60, 79, 0.8)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(95, 116, 136, 0.5);
}

.module-primary-80:active, .module-primary-80.active,
.show > .module-primary-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(36, 53, 70, 0.8);
  background-image: none;
  border-color: rgba(31, 47, 62, 0.8);
}

.module-primary-80:active:focus, .module-primary-80.active:focus,
.show > .module-primary-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 116, 136, 0.5);
}

.module-primary-80:disabled, .module-primary-80.disabled {
  color: #fff;
  background-color: rgba(53, 79, 104, 0.8);
  background-image: none;
  border-color: rgba(53, 79, 104, 0.8);
}

.module-primary-90 {
  color: #fff;
  background: rgba(53, 79, 104, 0.9) linear-gradient(180deg, rgba(86, 108, 130, 0.915), rgba(53, 79, 104, 0.9)) repeat-x;
  border-color: rgba(53, 79, 104, 0.9);
}

.module-primary-90 .icon-container {
  background-color: rgba(40, 60, 79, 0.9);
}

.module-primary-90 .icon-container.light {
  background-color: rgba(79, 117, 155, 0.9);
}

.module-primary-90:hover {
  color: #fff;
  background: rgba(40, 60, 79, 0.9) linear-gradient(180deg, rgba(75, 93, 109, 0.915), rgba(40, 60, 79, 0.9)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.9));
}

.module-primary-90:focus, .module-primary-90.focus {
  color: #fff;
  background: rgba(40, 60, 79, 0.9) linear-gradient(180deg, rgba(75, 93, 109, 0.915), rgba(40, 60, 79, 0.9)) repeat-x;
  border-color: color-yig(rgba(40, 60, 79, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(89, 110, 131, 0.5);
}

.module-primary-90:active, .module-primary-90.active,
.show > .module-primary-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(36, 53, 70, 0.9);
  background-image: none;
  border-color: rgba(31, 47, 62, 0.9);
}

.module-primary-90:active:focus, .module-primary-90.active:focus,
.show > .module-primary-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 110, 131, 0.5);
}

.module-primary-90:disabled, .module-primary-90.disabled {
  color: #fff;
  background-color: rgba(53, 79, 104, 0.9);
  background-image: none;
  border-color: rgba(53, 79, 104, 0.9);
}

.module-success {
  color: #fff;
  background: #5a9956 linear-gradient(180deg, #70a76f, #5a9956) repeat-x;
  border-color: #5a9956;
}

.module-success .icon-container {
  background-color: #4c8148;
}

.module-success .icon-container.light {
  background-color: #86b982;
}

.module-success:hover {
  color: #fff;
  background: #4c8148 linear-gradient(180deg, #649263, #4c8148) repeat-x;
  border-color: color-yig(#4c8148);
}

.module-success:focus, .module-success.focus {
  color: #fff;
  background: #4c8148 linear-gradient(180deg, #649263, #4c8148) repeat-x;
  border-color: color-yig(#4c8148);
  box-shadow: 0 0 0 0.2rem rgba(115, 168, 111, 0.5);
}

.module-success:active, .module-success.active,
.show > .module-success.dropdown-toggle {
  color: #fff;
  background-color: #477844;
  background-image: none;
  border-color: #42703f;
}

.module-success:active:focus, .module-success.active:focus,
.show > .module-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(115, 168, 111, 0.5);
}

.module-success:disabled, .module-success.disabled {
  color: #fff;
  background-color: #5a9956;
  background-image: none;
  border-color: #5a9956;
}

.module-success-10 {
  color: #fff;
  background: rgba(90, 153, 86, 0.1) linear-gradient(180deg, rgba(204, 224, 212, 0.235), rgba(90, 153, 86, 0.1)) repeat-x;
  border-color: rgba(90, 153, 86, 0.1);
}

.module-success-10 .icon-container {
  background-color: rgba(76, 129, 72, 0.1);
}

.module-success-10 .icon-container.light {
  background-color: rgba(134, 185, 130, 0.1);
}

.module-success-10:hover {
  color: #fff;
  background: rgba(76, 129, 72, 0.1) linear-gradient(180deg, rgba(201, 218, 209, 0.235), rgba(76, 129, 72, 0.1)) repeat-x;
  border-color: color-yig(rgba(76, 129, 72, 0.1));
}

.module-success-10:focus, .module-success-10.focus {
  color: #fff;
  background: rgba(76, 129, 72, 0.1) linear-gradient(180deg, rgba(201, 218, 209, 0.235), rgba(76, 129, 72, 0.1)) repeat-x;
  border-color: color-yig(rgba(76, 129, 72, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(217, 232, 216, 0.5);
}

.module-success-10:active, .module-success-10.active,
.show > .module-success-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(71, 120, 68, 0.1);
  background-image: none;
  border-color: rgba(66, 112, 63, 0.1);
}

.module-success-10:active:focus, .module-success-10.active:focus,
.show > .module-success-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 232, 216, 0.5);
}

.module-success-10:disabled, .module-success-10.disabled {
  color: #fff;
  background-color: rgba(90, 153, 86, 0.1);
  background-image: none;
  border-color: rgba(90, 153, 86, 0.1);
}

.module-success-20 {
  color: #fff;
  background: rgba(90, 153, 86, 0.2) linear-gradient(180deg, rgba(181, 209, 187, 0.32), rgba(90, 153, 86, 0.2)) repeat-x;
  border-color: rgba(90, 153, 86, 0.2);
}

.module-success-20 .icon-container {
  background-color: rgba(76, 129, 72, 0.2);
}

.module-success-20 .icon-container.light {
  background-color: rgba(134, 185, 130, 0.2);
}

.module-success-20:hover {
  color: #fff;
  background: rgba(76, 129, 72, 0.2) linear-gradient(180deg, rgba(175, 200, 181, 0.32), rgba(76, 129, 72, 0.2)) repeat-x;
  border-color: color-yig(rgba(76, 129, 72, 0.2));
}

.module-success-20:focus, .module-success-20.focus {
  color: #fff;
  background: rgba(76, 129, 72, 0.2) linear-gradient(180deg, rgba(175, 200, 181, 0.32), rgba(76, 129, 72, 0.2)) repeat-x;
  border-color: color-yig(rgba(76, 129, 72, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(191, 216, 190, 0.5);
}

.module-success-20:active, .module-success-20.active,
.show > .module-success-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(71, 120, 68, 0.2);
  background-image: none;
  border-color: rgba(66, 112, 63, 0.2);
}

.module-success-20:active:focus, .module-success-20.active:focus,
.show > .module-success-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(191, 216, 190, 0.5);
}

.module-success-20:disabled, .module-success-20.disabled {
  color: #fff;
  background-color: rgba(90, 153, 86, 0.2);
  background-image: none;
  border-color: rgba(90, 153, 86, 0.2);
}

.module-success-30 {
  color: #fff;
  background: rgba(90, 153, 86, 0.3) linear-gradient(180deg, rgba(164, 199, 168, 0.405), rgba(90, 153, 86, 0.3)) repeat-x;
  border-color: rgba(90, 153, 86, 0.3);
}

.module-success-30 .icon-container {
  background-color: rgba(76, 129, 72, 0.3);
}

.module-success-30 .icon-container.light {
  background-color: rgba(134, 185, 130, 0.3);
}

.module-success-30:hover {
  color: #fff;
  background: rgba(76, 129, 72, 0.3) linear-gradient(180deg, rgba(157, 187, 161, 0.405), rgba(76, 129, 72, 0.3)) repeat-x;
  border-color: color-yig(rgba(76, 129, 72, 0.3));
}

.module-success-30:focus, .module-success-30.focus {
  color: #fff;
  background: rgba(76, 129, 72, 0.3) linear-gradient(180deg, rgba(157, 187, 161, 0.405), rgba(76, 129, 72, 0.3)) repeat-x;
  border-color: color-yig(rgba(76, 129, 72, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(173, 204, 171, 0.5);
}

.module-success-30:active, .module-success-30.active,
.show > .module-success-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(71, 120, 68, 0.3);
  background-image: none;
  border-color: rgba(66, 112, 63, 0.3);
}

.module-success-30:active:focus, .module-success-30.active:focus,
.show > .module-success-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(173, 204, 171, 0.5);
}

.module-success-30:disabled, .module-success-30.disabled {
  color: #fff;
  background-color: rgba(90, 153, 86, 0.3);
  background-image: none;
  border-color: rgba(90, 153, 86, 0.3);
}

.module-success-40 {
  color: #fff;
  background: rgba(90, 153, 86, 0.4) linear-gradient(180deg, rgba(151, 191, 154, 0.49), rgba(90, 153, 86, 0.4)) repeat-x;
  border-color: rgba(90, 153, 86, 0.4);
}

.module-success-40 .icon-container {
  background-color: rgba(76, 129, 72, 0.4);
}

.module-success-40 .icon-container.light {
  background-color: rgba(134, 185, 130, 0.4);
}

.module-success-40:hover {
  color: #fff;
  background: rgba(76, 129, 72, 0.4) linear-gradient(180deg, rgba(143, 177, 146, 0.49), rgba(76, 129, 72, 0.4)) repeat-x;
  border-color: color-yig(rgba(76, 129, 72, 0.4));
}

.module-success-40:focus, .module-success-40.focus {
  color: #fff;
  background: rgba(76, 129, 72, 0.4) linear-gradient(180deg, rgba(143, 177, 146, 0.49), rgba(76, 129, 72, 0.4)) repeat-x;
  border-color: color-yig(rgba(76, 129, 72, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(158, 195, 156, 0.5);
}

.module-success-40:active, .module-success-40.active,
.show > .module-success-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(71, 120, 68, 0.4);
  background-image: none;
  border-color: rgba(66, 112, 63, 0.4);
}

.module-success-40:active:focus, .module-success-40.active:focus,
.show > .module-success-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(158, 195, 156, 0.5);
}

.module-success-40:disabled, .module-success-40.disabled {
  color: #fff;
  background-color: rgba(90, 153, 86, 0.4);
  background-image: none;
  border-color: rgba(90, 153, 86, 0.4);
}

.module-success-50 {
  color: #fff;
  background: rgba(90, 153, 86, 0.5) linear-gradient(180deg, rgba(141, 185, 143, 0.575), rgba(90, 153, 86, 0.5)) repeat-x;
  border-color: rgba(90, 153, 86, 0.5);
}

.module-success-50 .icon-container {
  background-color: rgba(76, 129, 72, 0.5);
}

.module-success-50 .icon-container.light {
  background-color: rgba(134, 185, 130, 0.5);
}

.module-success-50:hover {
  color: #fff;
  background: rgba(76, 129, 72, 0.5) linear-gradient(180deg, rgba(132, 169, 134, 0.575), rgba(76, 129, 72, 0.5)) repeat-x;
  border-color: color-yig(rgba(76, 129, 72, 0.5));
}

.module-success-50:focus, .module-success-50.focus {
  color: #fff;
  background: rgba(76, 129, 72, 0.5) linear-gradient(180deg, rgba(132, 169, 134, 0.575), rgba(76, 129, 72, 0.5)) repeat-x;
  border-color: color-yig(rgba(76, 129, 72, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(147, 188, 145, 0.5);
}

.module-success-50:active, .module-success-50.active,
.show > .module-success-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(71, 120, 68, 0.5);
  background-image: none;
  border-color: rgba(66, 112, 63, 0.5);
}

.module-success-50:active:focus, .module-success-50.active:focus,
.show > .module-success-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 188, 145, 0.5);
}

.module-success-50:disabled, .module-success-50.disabled {
  color: #fff;
  background-color: rgba(90, 153, 86, 0.5);
  background-image: none;
  border-color: rgba(90, 153, 86, 0.5);
}

.module-success-60 {
  color: #fff;
  background: rgba(90, 153, 86, 0.6) linear-gradient(180deg, rgba(133, 180, 134, 0.66), rgba(90, 153, 86, 0.6)) repeat-x;
  border-color: rgba(90, 153, 86, 0.6);
}

.module-success-60 .icon-container {
  background-color: rgba(76, 129, 72, 0.6);
}

.module-success-60 .icon-container.light {
  background-color: rgba(134, 185, 130, 0.6);
}

.module-success-60:hover {
  color: #fff;
  background: rgba(76, 129, 72, 0.6) linear-gradient(180deg, rgba(123, 162, 124, 0.66), rgba(76, 129, 72, 0.6)) repeat-x;
  border-color: color-yig(rgba(76, 129, 72, 0.6));
}

.module-success-60:focus, .module-success-60.focus {
  color: #fff;
  background: rgba(76, 129, 72, 0.6) linear-gradient(180deg, rgba(123, 162, 124, 0.66), rgba(76, 129, 72, 0.6)) repeat-x;
  border-color: color-yig(rgba(76, 129, 72, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(138, 183, 135, 0.5);
}

.module-success-60:active, .module-success-60.active,
.show > .module-success-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(71, 120, 68, 0.6);
  background-image: none;
  border-color: rgba(66, 112, 63, 0.6);
}

.module-success-60:active:focus, .module-success-60.active:focus,
.show > .module-success-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(138, 183, 135, 0.5);
}

.module-success-60:disabled, .module-success-60.disabled {
  color: #fff;
  background-color: rgba(90, 153, 86, 0.6);
  background-image: none;
  border-color: rgba(90, 153, 86, 0.6);
}

.module-success-70 {
  color: #fff;
  background: rgba(90, 153, 86, 0.7) linear-gradient(180deg, rgba(127, 176, 126, 0.745), rgba(90, 153, 86, 0.7)) repeat-x;
  border-color: rgba(90, 153, 86, 0.7);
}

.module-success-70 .icon-container {
  background-color: rgba(76, 129, 72, 0.7);
}

.module-success-70 .icon-container.light {
  background-color: rgba(134, 185, 130, 0.7);
}

.module-success-70:hover {
  color: #fff;
  background: rgba(76, 129, 72, 0.7) linear-gradient(180deg, rgba(116, 157, 116, 0.745), rgba(76, 129, 72, 0.7)) repeat-x;
  border-color: color-yig(rgba(76, 129, 72, 0.7));
}

.module-success-70:focus, .module-success-70.focus {
  color: #fff;
  background: rgba(76, 129, 72, 0.7) linear-gradient(180deg, rgba(116, 157, 116, 0.745), rgba(76, 129, 72, 0.7)) repeat-x;
  border-color: color-yig(rgba(76, 129, 72, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(131, 178, 128, 0.5);
}

.module-success-70:active, .module-success-70.active,
.show > .module-success-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(71, 120, 68, 0.7);
  background-image: none;
  border-color: rgba(66, 112, 63, 0.7);
}

.module-success-70:active:focus, .module-success-70.active:focus,
.show > .module-success-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(131, 178, 128, 0.5);
}

.module-success-70:disabled, .module-success-70.disabled {
  color: #fff;
  background-color: rgba(90, 153, 86, 0.7);
  background-image: none;
  border-color: rgba(90, 153, 86, 0.7);
}

.module-success-80 {
  color: #fff;
  background: rgba(90, 153, 86, 0.8) linear-gradient(180deg, rgba(121, 172, 120, 0.83), rgba(90, 153, 86, 0.8)) repeat-x;
  border-color: rgba(90, 153, 86, 0.8);
}

.module-success-80 .icon-container {
  background-color: rgba(76, 129, 72, 0.8);
}

.module-success-80 .icon-container.light {
  background-color: rgba(134, 185, 130, 0.8);
}

.module-success-80:hover {
  color: #fff;
  background: rgba(76, 129, 72, 0.8) linear-gradient(180deg, rgba(110, 153, 109, 0.83), rgba(76, 129, 72, 0.8)) repeat-x;
  border-color: color-yig(rgba(76, 129, 72, 0.8));
}

.module-success-80:focus, .module-success-80.focus {
  color: #fff;
  background: rgba(76, 129, 72, 0.8) linear-gradient(180deg, rgba(110, 153, 109, 0.83), rgba(76, 129, 72, 0.8)) repeat-x;
  border-color: color-yig(rgba(76, 129, 72, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(125, 174, 121, 0.5);
}

.module-success-80:active, .module-success-80.active,
.show > .module-success-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(71, 120, 68, 0.8);
  background-image: none;
  border-color: rgba(66, 112, 63, 0.8);
}

.module-success-80:active:focus, .module-success-80.active:focus,
.show > .module-success-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 174, 121, 0.5);
}

.module-success-80:disabled, .module-success-80.disabled {
  color: #fff;
  background-color: rgba(90, 153, 86, 0.8);
  background-image: none;
  border-color: rgba(90, 153, 86, 0.8);
}

.module-success-90 {
  color: #fff;
  background: rgba(90, 153, 86, 0.9) linear-gradient(180deg, rgba(116, 169, 115, 0.915), rgba(90, 153, 86, 0.9)) repeat-x;
  border-color: rgba(90, 153, 86, 0.9);
}

.module-success-90 .icon-container {
  background-color: rgba(76, 129, 72, 0.9);
}

.module-success-90 .icon-container.light {
  background-color: rgba(134, 185, 130, 0.9);
}

.module-success-90:hover {
  color: #fff;
  background: rgba(76, 129, 72, 0.9) linear-gradient(180deg, rgba(104, 149, 104, 0.915), rgba(76, 129, 72, 0.9)) repeat-x;
  border-color: color-yig(rgba(76, 129, 72, 0.9));
}

.module-success-90:focus, .module-success-90.focus {
  color: #fff;
  background: rgba(76, 129, 72, 0.9) linear-gradient(180deg, rgba(104, 149, 104, 0.915), rgba(76, 129, 72, 0.9)) repeat-x;
  border-color: color-yig(rgba(76, 129, 72, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(119, 171, 116, 0.5);
}

.module-success-90:active, .module-success-90.active,
.show > .module-success-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(71, 120, 68, 0.9);
  background-image: none;
  border-color: rgba(66, 112, 63, 0.9);
}

.module-success-90:active:focus, .module-success-90.active:focus,
.show > .module-success-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(119, 171, 116, 0.5);
}

.module-success-90:disabled, .module-success-90.disabled {
  color: #fff;
  background-color: rgba(90, 153, 86, 0.9);
  background-image: none;
  border-color: rgba(90, 153, 86, 0.9);
}

.module-info {
  color: #fff;
  background: #5299e0 linear-gradient(180deg, #69a7e4, #5299e0) repeat-x;
  border-color: #5299e0;
}

.module-info .icon-container {
  background-color: #3286da;
}

.module-info .icon-container.light {
  background-color: #93bfec;
}

.module-info:hover {
  color: #fff;
  background: #3286da linear-gradient(180deg, #4e97df, #3286da) repeat-x;
  border-color: color-yig(#3286da);
}

.module-info:focus, .module-info.focus {
  color: #fff;
  background: #3286da linear-gradient(180deg, #4e97df, #3286da) repeat-x;
  border-color: color-yig(#3286da);
  box-shadow: 0 0 0 0.2rem rgba(108, 168, 229, 0.5);
}

.module-info:active, .module-info.active,
.show > .module-info.dropdown-toggle {
  color: #fff;
  background-color: #2780d8;
  background-image: none;
  border-color: #2579cd;
}

.module-info:active:focus, .module-info.active:focus,
.show > .module-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 168, 229, 0.5);
}

.module-info:disabled, .module-info.disabled {
  color: #fff;
  background-color: #5299e0;
  background-image: none;
  border-color: #5299e0;
}

.module-info-10 {
  color: #fff;
  background: rgba(82, 153, 224, 0.1) linear-gradient(180deg, rgba(202, 224, 244, 0.235), rgba(82, 153, 224, 0.1)) repeat-x;
  border-color: rgba(82, 153, 224, 0.1);
}

.module-info-10 .icon-container {
  background-color: rgba(50, 134, 218, 0.1);
}

.module-info-10 .icon-container.light {
  background-color: rgba(147, 191, 236, 0.1);
}

.module-info-10:hover {
  color: #fff;
  background: rgba(50, 134, 218, 0.1) linear-gradient(180deg, rgba(195, 219, 243, 0.235), rgba(50, 134, 218, 0.1)) repeat-x;
  border-color: color-yig(rgba(50, 134, 218, 0.1));
}

.module-info-10:focus, .module-info-10.focus {
  color: #fff;
  background: rgba(50, 134, 218, 0.1) linear-gradient(180deg, rgba(195, 219, 243, 0.235), rgba(50, 134, 218, 0.1)) repeat-x;
  border-color: color-yig(rgba(50, 134, 218, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(215, 232, 248, 0.5);
}

.module-info-10:active, .module-info-10.active,
.show > .module-info-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(39, 128, 216, 0.1);
  background-image: none;
  border-color: rgba(37, 121, 205, 0.1);
}

.module-info-10:active:focus, .module-info-10.active:focus,
.show > .module-info-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 232, 248, 0.5);
}

.module-info-10:disabled, .module-info-10.disabled {
  color: #fff;
  background-color: rgba(82, 153, 224, 0.1);
  background-image: none;
  border-color: rgba(82, 153, 224, 0.1);
}

.module-info-20 {
  color: #fff;
  background: rgba(82, 153, 224, 0.2) linear-gradient(180deg, rgba(178, 209, 240, 0.32), rgba(82, 153, 224, 0.2)) repeat-x;
  border-color: rgba(82, 153, 224, 0.2);
}

.module-info-20 .icon-container {
  background-color: rgba(50, 134, 218, 0.2);
}

.module-info-20 .icon-container.light {
  background-color: rgba(147, 191, 236, 0.2);
}

.module-info-20:hover {
  color: #fff;
  background: rgba(50, 134, 218, 0.2) linear-gradient(180deg, rgba(165, 202, 238, 0.32), rgba(50, 134, 218, 0.2)) repeat-x;
  border-color: color-yig(rgba(50, 134, 218, 0.2));
}

.module-info-20:focus, .module-info-20.focus {
  color: #fff;
  background: rgba(50, 134, 218, 0.2) linear-gradient(180deg, rgba(165, 202, 238, 0.32), rgba(50, 134, 218, 0.2)) repeat-x;
  border-color: color-yig(rgba(50, 134, 218, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(188, 216, 243, 0.5);
}

.module-info-20:active, .module-info-20.active,
.show > .module-info-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(39, 128, 216, 0.2);
  background-image: none;
  border-color: rgba(37, 121, 205, 0.2);
}

.module-info-20:active:focus, .module-info-20.active:focus,
.show > .module-info-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(188, 216, 243, 0.5);
}

.module-info-20:disabled, .module-info-20.disabled {
  color: #fff;
  background-color: rgba(82, 153, 224, 0.2);
  background-image: none;
  border-color: rgba(82, 153, 224, 0.2);
}

.module-info-30 {
  color: #fff;
  background: rgba(82, 153, 224, 0.3) linear-gradient(180deg, rgba(160, 199, 237, 0.405), rgba(82, 153, 224, 0.3)) repeat-x;
  border-color: rgba(82, 153, 224, 0.3);
}

.module-info-30 .icon-container {
  background-color: rgba(50, 134, 218, 0.3);
}

.module-info-30 .icon-container.light {
  background-color: rgba(147, 191, 236, 0.3);
}

.module-info-30:hover {
  color: #fff;
  background: rgba(50, 134, 218, 0.3) linear-gradient(180deg, rgba(144, 189, 234, 0.405), rgba(50, 134, 218, 0.3)) repeat-x;
  border-color: color-yig(rgba(50, 134, 218, 0.3));
}

.module-info-30:focus, .module-info-30.focus {
  color: #fff;
  background: rgba(50, 134, 218, 0.3) linear-gradient(180deg, rgba(144, 189, 234, 0.405), rgba(50, 134, 218, 0.3)) repeat-x;
  border-color: color-yig(rgba(50, 134, 218, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(169, 204, 240, 0.5);
}

.module-info-30:active, .module-info-30.active,
.show > .module-info-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(39, 128, 216, 0.3);
  background-image: none;
  border-color: rgba(37, 121, 205, 0.3);
}

.module-info-30:active:focus, .module-info-30.active:focus,
.show > .module-info-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(169, 204, 240, 0.5);
}

.module-info-30:disabled, .module-info-30.disabled {
  color: #fff;
  background-color: rgba(82, 153, 224, 0.3);
  background-image: none;
  border-color: rgba(82, 153, 224, 0.3);
}

.module-info-40 {
  color: #fff;
  background: rgba(82, 153, 224, 0.4) linear-gradient(180deg, rgba(147, 191, 235, 0.49), rgba(82, 153, 224, 0.4)) repeat-x;
  border-color: rgba(82, 153, 224, 0.4);
}

.module-info-40 .icon-container {
  background-color: rgba(50, 134, 218, 0.4);
}

.module-info-40 .icon-container.light {
  background-color: rgba(147, 191, 236, 0.4);
}

.module-info-40:hover {
  color: #fff;
  background: rgba(50, 134, 218, 0.4) linear-gradient(180deg, rgba(128, 180, 231, 0.49), rgba(50, 134, 218, 0.4)) repeat-x;
  border-color: color-yig(rgba(50, 134, 218, 0.4));
}

.module-info-40:focus, .module-info-40.focus {
  color: #fff;
  background: rgba(50, 134, 218, 0.4) linear-gradient(180deg, rgba(128, 180, 231, 0.49), rgba(50, 134, 218, 0.4)) repeat-x;
  border-color: color-yig(rgba(50, 134, 218, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(154, 195, 237, 0.5);
}

.module-info-40:active, .module-info-40.active,
.show > .module-info-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(39, 128, 216, 0.4);
  background-image: none;
  border-color: rgba(37, 121, 205, 0.4);
}

.module-info-40:active:focus, .module-info-40.active:focus,
.show > .module-info-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(154, 195, 237, 0.5);
}

.module-info-40:disabled, .module-info-40.disabled {
  color: #fff;
  background-color: rgba(82, 153, 224, 0.4);
  background-image: none;
  border-color: rgba(82, 153, 224, 0.4);
}

.module-info-50 {
  color: #fff;
  background: rgba(82, 153, 224, 0.5) linear-gradient(180deg, rgba(136, 185, 233, 0.575), rgba(82, 153, 224, 0.5)) repeat-x;
  border-color: rgba(82, 153, 224, 0.5);
}

.module-info-50 .icon-container {
  background-color: rgba(50, 134, 218, 0.5);
}

.module-info-50 .icon-container.light {
  background-color: rgba(147, 191, 236, 0.5);
}

.module-info-50:hover {
  color: #fff;
  background: rgba(50, 134, 218, 0.5) linear-gradient(180deg, rgba(115, 172, 229, 0.575), rgba(50, 134, 218, 0.5)) repeat-x;
  border-color: color-yig(rgba(50, 134, 218, 0.5));
}

.module-info-50:focus, .module-info-50.focus {
  color: #fff;
  background: rgba(50, 134, 218, 0.5) linear-gradient(180deg, rgba(115, 172, 229, 0.575), rgba(50, 134, 218, 0.5)) repeat-x;
  border-color: color-yig(rgba(50, 134, 218, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(142, 188, 235, 0.5);
}

.module-info-50:active, .module-info-50.active,
.show > .module-info-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(39, 128, 216, 0.5);
  background-image: none;
  border-color: rgba(37, 121, 205, 0.5);
}

.module-info-50:active:focus, .module-info-50.active:focus,
.show > .module-info-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(142, 188, 235, 0.5);
}

.module-info-50:disabled, .module-info-50.disabled {
  color: #fff;
  background-color: rgba(82, 153, 224, 0.5);
  background-image: none;
  border-color: rgba(82, 153, 224, 0.5);
}

.module-info-60 {
  color: #fff;
  background: rgba(82, 153, 224, 0.6) linear-gradient(180deg, rgba(128, 180, 232, 0.66), rgba(82, 153, 224, 0.6)) repeat-x;
  border-color: rgba(82, 153, 224, 0.6);
}

.module-info-60 .icon-container {
  background-color: rgba(50, 134, 218, 0.6);
}

.module-info-60 .icon-container.light {
  background-color: rgba(147, 191, 236, 0.6);
}

.module-info-60:hover {
  color: #fff;
  background: rgba(50, 134, 218, 0.6) linear-gradient(180deg, rgba(105, 166, 227, 0.66), rgba(50, 134, 218, 0.6)) repeat-x;
  border-color: color-yig(rgba(50, 134, 218, 0.6));
}

.module-info-60:focus, .module-info-60.focus {
  color: #fff;
  background: rgba(50, 134, 218, 0.6) linear-gradient(180deg, rgba(105, 166, 227, 0.66), rgba(50, 134, 218, 0.6)) repeat-x;
  border-color: color-yig(rgba(50, 134, 218, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(132, 183, 233, 0.5);
}

.module-info-60:active, .module-info-60.active,
.show > .module-info-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(39, 128, 216, 0.6);
  background-image: none;
  border-color: rgba(37, 121, 205, 0.6);
}

.module-info-60:active:focus, .module-info-60.active:focus,
.show > .module-info-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(132, 183, 233, 0.5);
}

.module-info-60:disabled, .module-info-60.disabled {
  color: #fff;
  background-color: rgba(82, 153, 224, 0.6);
  background-image: none;
  border-color: rgba(82, 153, 224, 0.6);
}

.module-info-70 {
  color: #fff;
  background: rgba(82, 153, 224, 0.7) linear-gradient(180deg, rgba(121, 176, 230, 0.745), rgba(82, 153, 224, 0.7)) repeat-x;
  border-color: rgba(82, 153, 224, 0.7);
}

.module-info-70 .icon-container {
  background-color: rgba(50, 134, 218, 0.7);
}

.module-info-70 .icon-container.light {
  background-color: rgba(147, 191, 236, 0.7);
}

.module-info-70:hover {
  color: #fff;
  background: rgba(50, 134, 218, 0.7) linear-gradient(180deg, rgba(96, 161, 226, 0.745), rgba(50, 134, 218, 0.7)) repeat-x;
  border-color: color-yig(rgba(50, 134, 218, 0.7));
}

.module-info-70:focus, .module-info-70.focus {
  color: #fff;
  background: rgba(50, 134, 218, 0.7) linear-gradient(180deg, rgba(96, 161, 226, 0.745), rgba(50, 134, 218, 0.7)) repeat-x;
  border-color: color-yig(rgba(50, 134, 218, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(125, 178, 232, 0.5);
}

.module-info-70:active, .module-info-70.active,
.show > .module-info-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(39, 128, 216, 0.7);
  background-image: none;
  border-color: rgba(37, 121, 205, 0.7);
}

.module-info-70:active:focus, .module-info-70.active:focus,
.show > .module-info-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 178, 232, 0.5);
}

.module-info-70:disabled, .module-info-70.disabled {
  color: #fff;
  background-color: rgba(82, 153, 224, 0.7);
  background-image: none;
  border-color: rgba(82, 153, 224, 0.7);
}

.module-info-80 {
  color: #fff;
  background: rgba(82, 153, 224, 0.8) linear-gradient(180deg, rgba(115, 172, 229, 0.83), rgba(82, 153, 224, 0.8)) repeat-x;
  border-color: rgba(82, 153, 224, 0.8);
}

.module-info-80 .icon-container {
  background-color: rgba(50, 134, 218, 0.8);
}

.module-info-80 .icon-container.light {
  background-color: rgba(147, 191, 236, 0.8);
}

.module-info-80:hover {
  color: #fff;
  background: rgba(50, 134, 218, 0.8) linear-gradient(180deg, rgba(89, 157, 225, 0.83), rgba(50, 134, 218, 0.8)) repeat-x;
  border-color: color-yig(rgba(50, 134, 218, 0.8));
}

.module-info-80:focus, .module-info-80.focus {
  color: #fff;
  background: rgba(50, 134, 218, 0.8) linear-gradient(180deg, rgba(89, 157, 225, 0.83), rgba(50, 134, 218, 0.8)) repeat-x;
  border-color: color-yig(rgba(50, 134, 218, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(118, 174, 230, 0.5);
}

.module-info-80:active, .module-info-80.active,
.show > .module-info-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(39, 128, 216, 0.8);
  background-image: none;
  border-color: rgba(37, 121, 205, 0.8);
}

.module-info-80:active:focus, .module-info-80.active:focus,
.show > .module-info-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(118, 174, 230, 0.5);
}

.module-info-80:disabled, .module-info-80.disabled {
  color: #fff;
  background-color: rgba(82, 153, 224, 0.8);
  background-image: none;
  border-color: rgba(82, 153, 224, 0.8);
}

.module-info-90 {
  color: #fff;
  background: rgba(82, 153, 224, 0.9) linear-gradient(180deg, rgba(110, 169, 229, 0.915), rgba(82, 153, 224, 0.9)) repeat-x;
  border-color: rgba(82, 153, 224, 0.9);
}

.module-info-90 .icon-container {
  background-color: rgba(50, 134, 218, 0.9);
}

.module-info-90 .icon-container.light {
  background-color: rgba(147, 191, 236, 0.9);
}

.module-info-90:hover {
  color: #fff;
  background: rgba(50, 134, 218, 0.9) linear-gradient(180deg, rgba(83, 154, 224, 0.915), rgba(50, 134, 218, 0.9)) repeat-x;
  border-color: color-yig(rgba(50, 134, 218, 0.9));
}

.module-info-90:focus, .module-info-90.focus {
  color: #fff;
  background: rgba(50, 134, 218, 0.9) linear-gradient(180deg, rgba(83, 154, 224, 0.915), rgba(50, 134, 218, 0.9)) repeat-x;
  border-color: color-yig(rgba(50, 134, 218, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(113, 171, 230, 0.5);
}

.module-info-90:active, .module-info-90.active,
.show > .module-info-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(39, 128, 216, 0.9);
  background-image: none;
  border-color: rgba(37, 121, 205, 0.9);
}

.module-info-90:active:focus, .module-info-90.active:focus,
.show > .module-info-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(113, 171, 230, 0.5);
}

.module-info-90:disabled, .module-info-90.disabled {
  color: #fff;
  background-color: rgba(82, 153, 224, 0.9);
  background-image: none;
  border-color: rgba(82, 153, 224, 0.9);
}

.module-warning {
  color: #273a4d;
  background: #f2d585 linear-gradient(180deg, #f1da97, #f2d585) repeat-x;
  border-color: #f2d585;
}

.module-warning .icon-container {
  background-color: #eec962;
}

.module-warning .icon-container.light {
  background-color: #f9edca;
}

.module-warning:hover {
  color: #273a4d;
  background: #eec962 linear-gradient(180deg, #eed079, #eec962) repeat-x;
  border-color: color-yig(#eec962);
}

.module-warning:focus, .module-warning.focus {
  color: #273a4d;
  background: #eec962 linear-gradient(180deg, #eed079, #eec962) repeat-x;
  border-color: color-yig(#eec962);
  box-shadow: 0 0 0 0.2rem rgba(212, 190, 125, 0.5);
}

.module-warning:active, .module-warning.active,
.show > .module-warning.dropdown-toggle {
  color: #273a4d;
  background-color: #edc557;
  background-image: none;
  border-color: #ecc14b;
}

.module-warning:active:focus, .module-warning.active:focus,
.show > .module-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 190, 125, 0.5);
}

.module-warning:disabled, .module-warning.disabled {
  color: #273a4d;
  background-color: #f2d585;
  background-image: none;
  border-color: #f2d585;
}

.module-warning-10 {
  color: #273a4d;
  background: rgba(242, 213, 133, 0.1) linear-gradient(180deg, rgba(239, 238, 223, 0.235), rgba(242, 213, 133, 0.1)) repeat-x;
  border-color: rgba(242, 213, 133, 0.1);
}

.module-warning-10 .icon-container {
  background-color: rgba(238, 201, 98, 0.1);
}

.module-warning-10 .icon-container.light {
  background-color: rgba(249, 237, 202, 0.1);
}

.module-warning-10:hover {
  color: #273a4d;
  background: rgba(238, 201, 98, 0.1) linear-gradient(180deg, rgba(238, 235, 215, 0.235), rgba(238, 201, 98, 0.1)) repeat-x;
  border-color: color-yig(rgba(238, 201, 98, 0.1));
}

.module-warning-10:focus, .module-warning-10.focus {
  color: #273a4d;
  background: rgba(238, 201, 98, 0.1) linear-gradient(180deg, rgba(238, 235, 215, 0.235), rgba(238, 201, 98, 0.1)) repeat-x;
  border-color: color-yig(rgba(238, 201, 98, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(86, 94, 90, 0.5);
}

.module-warning-10:active, .module-warning-10.active,
.show > .module-warning-10.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(237, 197, 87, 0.1);
  background-image: none;
  border-color: rgba(236, 193, 75, 0.1);
}

.module-warning-10:active:focus, .module-warning-10.active:focus,
.show > .module-warning-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 94, 90, 0.5);
}

.module-warning-10:disabled, .module-warning-10.disabled {
  color: #273a4d;
  background-color: rgba(242, 213, 133, 0.1);
  background-image: none;
  border-color: rgba(242, 213, 133, 0.1);
}

.module-warning-20 {
  color: #273a4d;
  background: rgba(242, 213, 133, 0.2) linear-gradient(180deg, rgba(240, 233, 205, 0.32), rgba(242, 213, 133, 0.2)) repeat-x;
  border-color: rgba(242, 213, 133, 0.2);
}

.module-warning-20 .icon-container {
  background-color: rgba(238, 201, 98, 0.2);
}

.module-warning-20 .icon-container.light {
  background-color: rgba(249, 237, 202, 0.2);
}

.module-warning-20:hover {
  color: #273a4d;
  background: rgba(238, 201, 98, 0.2) linear-gradient(180deg, rgba(238, 228, 191, 0.32), rgba(238, 201, 98, 0.2)) repeat-x;
  border-color: color-yig(rgba(238, 201, 98, 0.2));
}

.module-warning-20:focus, .module-warning-20.focus {
  color: #273a4d;
  background: rgba(238, 201, 98, 0.2) linear-gradient(180deg, rgba(238, 228, 191, 0.32), rgba(238, 201, 98, 0.2)) repeat-x;
  border-color: color-yig(rgba(238, 201, 98, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(117, 118, 99, 0.5);
}

.module-warning-20:active, .module-warning-20.active,
.show > .module-warning-20.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(237, 197, 87, 0.2);
  background-image: none;
  border-color: rgba(236, 193, 75, 0.2);
}

.module-warning-20:active:focus, .module-warning-20.active:focus,
.show > .module-warning-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(117, 118, 99, 0.5);
}

.module-warning-20:disabled, .module-warning-20.disabled {
  color: #273a4d;
  background-color: rgba(242, 213, 133, 0.2);
  background-image: none;
  border-color: rgba(242, 213, 133, 0.2);
}

.module-warning-30 {
  color: #273a4d;
  background: rgba(242, 213, 133, 0.3) linear-gradient(180deg, rgba(240, 229, 192, 0.405), rgba(242, 213, 133, 0.3)) repeat-x;
  border-color: rgba(242, 213, 133, 0.3);
}

.module-warning-30 .icon-container {
  background-color: rgba(238, 201, 98, 0.3);
}

.module-warning-30 .icon-container.light {
  background-color: rgba(249, 237, 202, 0.3);
}

.module-warning-30:hover {
  color: #273a4d;
  background: rgba(238, 201, 98, 0.3) linear-gradient(180deg, rgba(238, 223, 174, 0.405), rgba(238, 201, 98, 0.3)) repeat-x;
  border-color: color-yig(rgba(238, 201, 98, 0.3));
}

.module-warning-30:focus, .module-warning-30.focus {
  color: #273a4d;
  background: rgba(238, 201, 98, 0.3) linear-gradient(180deg, rgba(238, 223, 174, 0.405), rgba(238, 201, 98, 0.3)) repeat-x;
  border-color: color-yig(rgba(238, 201, 98, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(141, 136, 105, 0.5);
}

.module-warning-30:active, .module-warning-30.active,
.show > .module-warning-30.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(237, 197, 87, 0.3);
  background-image: none;
  border-color: rgba(236, 193, 75, 0.3);
}

.module-warning-30:active:focus, .module-warning-30.active:focus,
.show > .module-warning-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(141, 136, 105, 0.5);
}

.module-warning-30:disabled, .module-warning-30.disabled {
  color: #273a4d;
  background-color: rgba(242, 213, 133, 0.3);
  background-image: none;
  border-color: rgba(242, 213, 133, 0.3);
}

.module-warning-40 {
  color: #273a4d;
  background: rgba(242, 213, 133, 0.4) linear-gradient(180deg, rgba(240, 226, 181, 0.49), rgba(242, 213, 133, 0.4)) repeat-x;
  border-color: rgba(242, 213, 133, 0.4);
}

.module-warning-40 .icon-container {
  background-color: rgba(238, 201, 98, 0.4);
}

.module-warning-40 .icon-container.light {
  background-color: rgba(249, 237, 202, 0.4);
}

.module-warning-40:hover {
  color: #273a4d;
  background: rgba(238, 201, 98, 0.4) linear-gradient(180deg, rgba(238, 219, 161, 0.49), rgba(238, 201, 98, 0.4)) repeat-x;
  border-color: color-yig(rgba(238, 201, 98, 0.4));
}

.module-warning-40:focus, .module-warning-40.focus {
  color: #273a4d;
  background: rgba(238, 201, 98, 0.4) linear-gradient(180deg, rgba(238, 219, 161, 0.49), rgba(238, 201, 98, 0.4)) repeat-x;
  border-color: color-yig(rgba(238, 201, 98, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(158, 149, 110, 0.5);
}

.module-warning-40:active, .module-warning-40.active,
.show > .module-warning-40.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(237, 197, 87, 0.4);
  background-image: none;
  border-color: rgba(236, 193, 75, 0.4);
}

.module-warning-40:active:focus, .module-warning-40.active:focus,
.show > .module-warning-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(158, 149, 110, 0.5);
}

.module-warning-40:disabled, .module-warning-40.disabled {
  color: #273a4d;
  background-color: rgba(242, 213, 133, 0.4);
  background-image: none;
  border-color: rgba(242, 213, 133, 0.4);
}

.module-warning-50 {
  color: #273a4d;
  background: rgba(242, 213, 133, 0.5) linear-gradient(180deg, rgba(241, 224, 174, 0.575), rgba(242, 213, 133, 0.5)) repeat-x;
  border-color: rgba(242, 213, 133, 0.5);
}

.module-warning-50 .icon-container {
  background-color: rgba(238, 201, 98, 0.5);
}

.module-warning-50 .icon-container.light {
  background-color: rgba(249, 237, 202, 0.5);
}

.module-warning-50:hover {
  color: #273a4d;
  background: rgba(238, 201, 98, 0.5) linear-gradient(180deg, rgba(238, 216, 151, 0.575), rgba(238, 201, 98, 0.5)) repeat-x;
  border-color: color-yig(rgba(238, 201, 98, 0.5));
}

.module-warning-50:focus, .module-warning-50.focus {
  color: #273a4d;
  background: rgba(238, 201, 98, 0.5) linear-gradient(180deg, rgba(238, 216, 151, 0.575), rgba(238, 201, 98, 0.5)) repeat-x;
  border-color: color-yig(rgba(238, 201, 98, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(172, 159, 114, 0.5);
}

.module-warning-50:active, .module-warning-50.active,
.show > .module-warning-50.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(237, 197, 87, 0.5);
  background-image: none;
  border-color: rgba(236, 193, 75, 0.5);
}

.module-warning-50:active:focus, .module-warning-50.active:focus,
.show > .module-warning-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(172, 159, 114, 0.5);
}

.module-warning-50:disabled, .module-warning-50.disabled {
  color: #273a4d;
  background-color: rgba(242, 213, 133, 0.5);
  background-image: none;
  border-color: rgba(242, 213, 133, 0.5);
}

.module-warning-60 {
  color: #273a4d;
  background: rgba(242, 213, 133, 0.6) linear-gradient(180deg, rgba(241, 222, 167, 0.66), rgba(242, 213, 133, 0.6)) repeat-x;
  border-color: rgba(242, 213, 133, 0.6);
}

.module-warning-60 .icon-container {
  background-color: rgba(238, 201, 98, 0.6);
}

.module-warning-60 .icon-container.light {
  background-color: rgba(249, 237, 202, 0.6);
}

.module-warning-60:hover {
  color: #273a4d;
  background: rgba(238, 201, 98, 0.6) linear-gradient(180deg, rgba(238, 214, 143, 0.66), rgba(238, 201, 98, 0.6)) repeat-x;
  border-color: color-yig(rgba(238, 201, 98, 0.6));
}

.module-warning-60:focus, .module-warning-60.focus {
  color: #273a4d;
  background: rgba(238, 201, 98, 0.6) linear-gradient(180deg, rgba(238, 214, 143, 0.66), rgba(238, 201, 98, 0.6)) repeat-x;
  border-color: color-yig(rgba(238, 201, 98, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(183, 168, 117, 0.5);
}

.module-warning-60:active, .module-warning-60.active,
.show > .module-warning-60.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(237, 197, 87, 0.6);
  background-image: none;
  border-color: rgba(236, 193, 75, 0.6);
}

.module-warning-60:active:focus, .module-warning-60.active:focus,
.show > .module-warning-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(183, 168, 117, 0.5);
}

.module-warning-60:disabled, .module-warning-60.disabled {
  color: #273a4d;
  background-color: rgba(242, 213, 133, 0.6);
  background-image: none;
  border-color: rgba(242, 213, 133, 0.6);
}

.module-warning-70 {
  color: #273a4d;
  background: rgba(242, 213, 133, 0.7) linear-gradient(180deg, rgba(241, 221, 162, 0.745), rgba(242, 213, 133, 0.7)) repeat-x;
  border-color: rgba(242, 213, 133, 0.7);
}

.module-warning-70 .icon-container {
  background-color: rgba(238, 201, 98, 0.7);
}

.module-warning-70 .icon-container.light {
  background-color: rgba(249, 237, 202, 0.7);
}

.module-warning-70:hover {
  color: #273a4d;
  background: rgba(238, 201, 98, 0.7) linear-gradient(180deg, rgba(238, 212, 136, 0.745), rgba(238, 201, 98, 0.7)) repeat-x;
  border-color: color-yig(rgba(238, 201, 98, 0.7));
}

.module-warning-70:focus, .module-warning-70.focus {
  color: #273a4d;
  background: rgba(238, 201, 98, 0.7) linear-gradient(180deg, rgba(238, 212, 136, 0.745), rgba(238, 201, 98, 0.7)) repeat-x;
  border-color: color-yig(rgba(238, 201, 98, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(192, 175, 119, 0.5);
}

.module-warning-70:active, .module-warning-70.active,
.show > .module-warning-70.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(237, 197, 87, 0.7);
  background-image: none;
  border-color: rgba(236, 193, 75, 0.7);
}

.module-warning-70:active:focus, .module-warning-70.active:focus,
.show > .module-warning-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(192, 175, 119, 0.5);
}

.module-warning-70:disabled, .module-warning-70.disabled {
  color: #273a4d;
  background-color: rgba(242, 213, 133, 0.7);
  background-image: none;
  border-color: rgba(242, 213, 133, 0.7);
}

.module-warning-80 {
  color: #273a4d;
  background: rgba(242, 213, 133, 0.8) linear-gradient(180deg, rgba(241, 220, 157, 0.83), rgba(242, 213, 133, 0.8)) repeat-x;
  border-color: rgba(242, 213, 133, 0.8);
}

.module-warning-80 .icon-container {
  background-color: rgba(238, 201, 98, 0.8);
}

.module-warning-80 .icon-container.light {
  background-color: rgba(249, 237, 202, 0.8);
}

.module-warning-80:hover {
  color: #273a4d;
  background: rgba(238, 201, 98, 0.8) linear-gradient(180deg, rgba(238, 210, 130, 0.83), rgba(238, 201, 98, 0.8)) repeat-x;
  border-color: color-yig(rgba(238, 201, 98, 0.8));
}

.module-warning-80:focus, .module-warning-80.focus {
  color: #273a4d;
  background: rgba(238, 201, 98, 0.8) linear-gradient(180deg, rgba(238, 210, 130, 0.83), rgba(238, 201, 98, 0.8)) repeat-x;
  border-color: color-yig(rgba(238, 201, 98, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(200, 181, 121, 0.5);
}

.module-warning-80:active, .module-warning-80.active,
.show > .module-warning-80.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(237, 197, 87, 0.8);
  background-image: none;
  border-color: rgba(236, 193, 75, 0.8);
}

.module-warning-80:active:focus, .module-warning-80.active:focus,
.show > .module-warning-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(200, 181, 121, 0.5);
}

.module-warning-80:disabled, .module-warning-80.disabled {
  color: #273a4d;
  background-color: rgba(242, 213, 133, 0.8);
  background-image: none;
  border-color: rgba(242, 213, 133, 0.8);
}

.module-warning-90 {
  color: #273a4d;
  background: rgba(242, 213, 133, 0.9) linear-gradient(180deg, rgba(241, 219, 154, 0.915), rgba(242, 213, 133, 0.9)) repeat-x;
  border-color: rgba(242, 213, 133, 0.9);
}

.module-warning-90 .icon-container {
  background-color: rgba(238, 201, 98, 0.9);
}

.module-warning-90 .icon-container.light {
  background-color: rgba(249, 237, 202, 0.9);
}

.module-warning-90:hover {
  color: #273a4d;
  background: rgba(238, 201, 98, 0.9) linear-gradient(180deg, rgba(238, 209, 125, 0.915), rgba(238, 201, 98, 0.9)) repeat-x;
  border-color: color-yig(rgba(238, 201, 98, 0.9));
}

.module-warning-90:focus, .module-warning-90.focus {
  color: #273a4d;
  background: rgba(238, 201, 98, 0.9) linear-gradient(180deg, rgba(238, 209, 125, 0.915), rgba(238, 201, 98, 0.9)) repeat-x;
  border-color: color-yig(rgba(238, 201, 98, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(206, 186, 123, 0.5);
}

.module-warning-90:active, .module-warning-90.active,
.show > .module-warning-90.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(237, 197, 87, 0.9);
  background-image: none;
  border-color: rgba(236, 193, 75, 0.9);
}

.module-warning-90:active:focus, .module-warning-90.active:focus,
.show > .module-warning-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 186, 123, 0.5);
}

.module-warning-90:disabled, .module-warning-90.disabled {
  color: #273a4d;
  background-color: rgba(242, 213, 133, 0.9);
  background-image: none;
  border-color: rgba(242, 213, 133, 0.9);
}

.module-danger {
  color: #fff;
  background: #ff4136 linear-gradient(180deg, #fc5c53, #ff4136) repeat-x;
  border-color: #ff4136;
}

.module-danger .icon-container {
  background-color: #ff1d10;
}

.module-danger .icon-container.light {
  background-color: #ff8983;
}

.module-danger:hover {
  color: #fff;
  background: #ff1d10 linear-gradient(180deg, #fc3d33, #ff1d10) repeat-x;
  border-color: color-yig(#ff1d10);
}

.module-danger:focus, .module-danger.focus {
  color: #fff;
  background: #ff1d10 linear-gradient(180deg, #fc3d33, #ff1d10) repeat-x;
  border-color: color-yig(#ff1d10);
  box-shadow: 0 0 0 0.2rem rgba(255, 94, 84, 0.5);
}

.module-danger:active, .module-danger.active,
.show > .module-danger.dropdown-toggle {
  color: #fff;
  background-color: #ff1103;
  background-image: none;
  border-color: #f50d00;
}

.module-danger:active:focus, .module-danger.active:focus,
.show > .module-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 94, 84, 0.5);
}

.module-danger:disabled, .module-danger.disabled {
  color: #fff;
  background-color: #ff4136;
  background-image: none;
  border-color: #ff4136;
}

.module-danger-10 {
  color: #fff;
  background: rgba(255, 65, 54, 0.1) linear-gradient(180deg, rgba(242, 204, 205, 0.235), rgba(255, 65, 54, 0.1)) repeat-x;
  border-color: rgba(255, 65, 54, 0.1);
}

.module-danger-10 .icon-container {
  background-color: rgba(255, 29, 16, 0.1);
}

.module-danger-10 .icon-container.light {
  background-color: rgba(255, 137, 131, 0.1);
}

.module-danger-10:hover {
  color: #fff;
  background: rgba(255, 29, 16, 0.1) linear-gradient(180deg, rgba(242, 195, 196, 0.235), rgba(255, 29, 16, 0.1)) repeat-x;
  border-color: color-yig(rgba(255, 29, 16, 0.1));
}

.module-danger-10:focus, .module-danger-10.focus {
  color: #fff;
  background: rgba(255, 29, 16, 0.1) linear-gradient(180deg, rgba(242, 195, 196, 0.235), rgba(255, 29, 16, 0.1)) repeat-x;
  border-color: color-yig(rgba(255, 29, 16, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(255, 211, 209, 0.5);
}

.module-danger-10:active, .module-danger-10.active,
.show > .module-danger-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 17, 3, 0.1);
  background-image: none;
  border-color: rgba(245, 13, 0, 0.1);
}

.module-danger-10:active:focus, .module-danger-10.active:focus,
.show > .module-danger-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 211, 209, 0.5);
}

.module-danger-10:disabled, .module-danger-10.disabled {
  color: #fff;
  background-color: rgba(255, 65, 54, 0.1);
  background-image: none;
  border-color: rgba(255, 65, 54, 0.1);
}

.module-danger-20 {
  color: #fff;
  background: rgba(255, 65, 54, 0.2) linear-gradient(180deg, rgba(245, 175, 174, 0.32), rgba(255, 65, 54, 0.2)) repeat-x;
  border-color: rgba(255, 65, 54, 0.2);
}

.module-danger-20 .icon-container {
  background-color: rgba(255, 29, 16, 0.2);
}

.module-danger-20 .icon-container.light {
  background-color: rgba(255, 137, 131, 0.2);
}

.module-danger-20:hover {
  color: #fff;
  background: rgba(255, 29, 16, 0.2) linear-gradient(180deg, rgba(245, 161, 159, 0.32), rgba(255, 29, 16, 0.2)) repeat-x;
  border-color: color-yig(rgba(255, 29, 16, 0.2));
}

.module-danger-20:focus, .module-danger-20.focus {
  color: #fff;
  background: rgba(255, 29, 16, 0.2) linear-gradient(180deg, rgba(245, 161, 159, 0.32), rgba(255, 29, 16, 0.2)) repeat-x;
  border-color: color-yig(rgba(255, 29, 16, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(255, 182, 177, 0.5);
}

.module-danger-20:active, .module-danger-20.active,
.show > .module-danger-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 17, 3, 0.2);
  background-image: none;
  border-color: rgba(245, 13, 0, 0.2);
}

.module-danger-20:active:focus, .module-danger-20.active:focus,
.show > .module-danger-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 182, 177, 0.5);
}

.module-danger-20:disabled, .module-danger-20.disabled {
  color: #fff;
  background-color: rgba(255, 65, 54, 0.2);
  background-image: none;
  border-color: rgba(255, 65, 54, 0.2);
}

.module-danger-30 {
  color: #fff;
  background: rgba(255, 65, 54, 0.3) linear-gradient(180deg, rgba(247, 155, 152, 0.405), rgba(255, 65, 54, 0.3)) repeat-x;
  border-color: rgba(255, 65, 54, 0.3);
}

.module-danger-30 .icon-container {
  background-color: rgba(255, 29, 16, 0.3);
}

.module-danger-30 .icon-container.light {
  background-color: rgba(255, 137, 131, 0.3);
}

.module-danger-30:hover {
  color: #fff;
  background: rgba(255, 29, 16, 0.3) linear-gradient(180deg, rgba(247, 137, 133, 0.405), rgba(255, 29, 16, 0.3)) repeat-x;
  border-color: color-yig(rgba(255, 29, 16, 0.3));
}

.module-danger-30:focus, .module-danger-30.focus {
  color: #fff;
  background: rgba(255, 29, 16, 0.3) linear-gradient(180deg, rgba(247, 137, 133, 0.405), rgba(255, 29, 16, 0.3)) repeat-x;
  border-color: color-yig(rgba(255, 29, 16, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(255, 160, 155, 0.5);
}

.module-danger-30:active, .module-danger-30.active,
.show > .module-danger-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 17, 3, 0.3);
  background-image: none;
  border-color: rgba(245, 13, 0, 0.3);
}

.module-danger-30:active:focus, .module-danger-30.active:focus,
.show > .module-danger-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 160, 155, 0.5);
}

.module-danger-30:disabled, .module-danger-30.disabled {
  color: #fff;
  background-color: rgba(255, 65, 54, 0.3);
  background-image: none;
  border-color: rgba(255, 65, 54, 0.3);
}

.module-danger-40 {
  color: #fff;
  background: rgba(255, 65, 54, 0.4) linear-gradient(180deg, rgba(248, 139, 135, 0.49), rgba(255, 65, 54, 0.4)) repeat-x;
  border-color: rgba(255, 65, 54, 0.4);
}

.module-danger-40 .icon-container {
  background-color: rgba(255, 29, 16, 0.4);
}

.module-danger-40 .icon-container.light {
  background-color: rgba(255, 137, 131, 0.4);
}

.module-danger-40:hover {
  color: #fff;
  background: rgba(255, 29, 16, 0.4) linear-gradient(180deg, rgba(248, 118, 113, 0.49), rgba(255, 29, 16, 0.4)) repeat-x;
  border-color: color-yig(rgba(255, 29, 16, 0.4));
}

.module-danger-40:focus, .module-danger-40.focus {
  color: #fff;
  background: rgba(255, 29, 16, 0.4) linear-gradient(180deg, rgba(248, 118, 113, 0.49), rgba(255, 29, 16, 0.4)) repeat-x;
  border-color: color-yig(rgba(255, 29, 16, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(255, 144, 137, 0.5);
}

.module-danger-40:active, .module-danger-40.active,
.show > .module-danger-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 17, 3, 0.4);
  background-image: none;
  border-color: rgba(245, 13, 0, 0.4);
}

.module-danger-40:active:focus, .module-danger-40.active:focus,
.show > .module-danger-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 144, 137, 0.5);
}

.module-danger-40:disabled, .module-danger-40.disabled {
  color: #fff;
  background-color: rgba(255, 65, 54, 0.4);
  background-image: none;
  border-color: rgba(255, 65, 54, 0.4);
}

.module-danger-50 {
  color: #fff;
  background: rgba(255, 65, 54, 0.5) linear-gradient(180deg, rgba(249, 127, 122, 0.575), rgba(255, 65, 54, 0.5)) repeat-x;
  border-color: rgba(255, 65, 54, 0.5);
}

.module-danger-50 .icon-container {
  background-color: rgba(255, 29, 16, 0.5);
}

.module-danger-50 .icon-container.light {
  background-color: rgba(255, 137, 131, 0.5);
}

.module-danger-50:hover {
  color: #fff;
  background: rgba(255, 29, 16, 0.5) linear-gradient(180deg, rgba(249, 104, 97, 0.575), rgba(255, 29, 16, 0.5)) repeat-x;
  border-color: color-yig(rgba(255, 29, 16, 0.5));
}

.module-danger-50:focus, .module-danger-50.focus {
  color: #fff;
  background: rgba(255, 29, 16, 0.5) linear-gradient(180deg, rgba(249, 104, 97, 0.575), rgba(255, 29, 16, 0.5)) repeat-x;
  border-color: color-yig(rgba(255, 29, 16, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(255, 131, 124, 0.5);
}

.module-danger-50:active, .module-danger-50.active,
.show > .module-danger-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 17, 3, 0.5);
  background-image: none;
  border-color: rgba(245, 13, 0, 0.5);
}

.module-danger-50:active:focus, .module-danger-50.active:focus,
.show > .module-danger-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 131, 124, 0.5);
}

.module-danger-50:disabled, .module-danger-50.disabled {
  color: #fff;
  background-color: rgba(255, 65, 54, 0.5);
  background-image: none;
  border-color: rgba(255, 65, 54, 0.5);
}

.module-danger-60 {
  color: #fff;
  background: rgba(255, 65, 54, 0.6) linear-gradient(180deg, rgba(250, 118, 111, 0.66), rgba(255, 65, 54, 0.6)) repeat-x;
  border-color: rgba(255, 65, 54, 0.6);
}

.module-danger-60 .icon-container {
  background-color: rgba(255, 29, 16, 0.6);
}

.module-danger-60 .icon-container.light {
  background-color: rgba(255, 137, 131, 0.6);
}

.module-danger-60:hover {
  color: #fff;
  background: rgba(255, 29, 16, 0.6) linear-gradient(180deg, rgba(250, 92, 84, 0.66), rgba(255, 29, 16, 0.6)) repeat-x;
  border-color: color-yig(rgba(255, 29, 16, 0.6));
}

.module-danger-60:focus, .module-danger-60.focus {
  color: #fff;
  background: rgba(255, 29, 16, 0.6) linear-gradient(180deg, rgba(250, 92, 84, 0.66), rgba(255, 29, 16, 0.6)) repeat-x;
  border-color: color-yig(rgba(255, 29, 16, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(255, 120, 113, 0.5);
}

.module-danger-60:active, .module-danger-60.active,
.show > .module-danger-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 17, 3, 0.6);
  background-image: none;
  border-color: rgba(245, 13, 0, 0.6);
}

.module-danger-60:active:focus, .module-danger-60.active:focus,
.show > .module-danger-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 120, 113, 0.5);
}

.module-danger-60:disabled, .module-danger-60.disabled {
  color: #fff;
  background-color: rgba(255, 65, 54, 0.6);
  background-image: none;
  border-color: rgba(255, 65, 54, 0.6);
}

.module-danger-70 {
  color: #fff;
  background: rgba(255, 65, 54, 0.7) linear-gradient(180deg, rgba(251, 109, 102, 0.745), rgba(255, 65, 54, 0.7)) repeat-x;
  border-color: rgba(255, 65, 54, 0.7);
}

.module-danger-70 .icon-container {
  background-color: rgba(255, 29, 16, 0.7);
}

.module-danger-70 .icon-container.light {
  background-color: rgba(255, 137, 131, 0.7);
}

.module-danger-70:hover {
  color: #fff;
  background: rgba(255, 29, 16, 0.7) linear-gradient(180deg, rgba(251, 82, 74, 0.745), rgba(255, 29, 16, 0.7)) repeat-x;
  border-color: color-yig(rgba(255, 29, 16, 0.7));
}

.module-danger-70:focus, .module-danger-70.focus {
  color: #fff;
  background: rgba(255, 29, 16, 0.7) linear-gradient(180deg, rgba(251, 82, 74, 0.745), rgba(255, 29, 16, 0.7)) repeat-x;
  border-color: color-yig(rgba(255, 29, 16, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(255, 112, 104, 0.5);
}

.module-danger-70:active, .module-danger-70.active,
.show > .module-danger-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 17, 3, 0.7);
  background-image: none;
  border-color: rgba(245, 13, 0, 0.7);
}

.module-danger-70:active:focus, .module-danger-70.active:focus,
.show > .module-danger-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 112, 104, 0.5);
}

.module-danger-70:disabled, .module-danger-70.disabled {
  color: #fff;
  background-color: rgba(255, 65, 54, 0.7);
  background-image: none;
  border-color: rgba(255, 65, 54, 0.7);
}

.module-danger-80 {
  color: #fff;
  background: rgba(255, 65, 54, 0.8) linear-gradient(180deg, rgba(251, 103, 95, 0.83), rgba(255, 65, 54, 0.8)) repeat-x;
  border-color: rgba(255, 65, 54, 0.8);
}

.module-danger-80 .icon-container {
  background-color: rgba(255, 29, 16, 0.8);
}

.module-danger-80 .icon-container.light {
  background-color: rgba(255, 137, 131, 0.8);
}

.module-danger-80:hover {
  color: #fff;
  background: rgba(255, 29, 16, 0.8) linear-gradient(180deg, rgba(251, 74, 65, 0.83), rgba(255, 29, 16, 0.8)) repeat-x;
  border-color: color-yig(rgba(255, 29, 16, 0.8));
}

.module-danger-80:focus, .module-danger-80.focus {
  color: #fff;
  background: rgba(255, 29, 16, 0.8) linear-gradient(180deg, rgba(251, 74, 65, 0.83), rgba(255, 29, 16, 0.8)) repeat-x;
  border-color: color-yig(rgba(255, 29, 16, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(255, 105, 96, 0.5);
}

.module-danger-80:active, .module-danger-80.active,
.show > .module-danger-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 17, 3, 0.8);
  background-image: none;
  border-color: rgba(245, 13, 0, 0.8);
}

.module-danger-80:active:focus, .module-danger-80.active:focus,
.show > .module-danger-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 105, 96, 0.5);
}

.module-danger-80:disabled, .module-danger-80.disabled {
  color: #fff;
  background-color: rgba(255, 65, 54, 0.8);
  background-image: none;
  border-color: rgba(255, 65, 54, 0.8);
}

.module-danger-90 {
  color: #fff;
  background: rgba(255, 65, 54, 0.9) linear-gradient(180deg, rgba(252, 97, 89, 0.915), rgba(255, 65, 54, 0.9)) repeat-x;
  border-color: rgba(255, 65, 54, 0.9);
}

.module-danger-90 .icon-container {
  background-color: rgba(255, 29, 16, 0.9);
}

.module-danger-90 .icon-container.light {
  background-color: rgba(255, 137, 131, 0.9);
}

.module-danger-90:hover {
  color: #fff;
  background: rgba(255, 29, 16, 0.9) linear-gradient(180deg, rgba(252, 67, 57, 0.915), rgba(255, 29, 16, 0.9)) repeat-x;
  border-color: color-yig(rgba(255, 29, 16, 0.9));
}

.module-danger-90:focus, .module-danger-90.focus {
  color: #fff;
  background: rgba(255, 29, 16, 0.9) linear-gradient(180deg, rgba(252, 67, 57, 0.915), rgba(255, 29, 16, 0.9)) repeat-x;
  border-color: color-yig(rgba(255, 29, 16, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(255, 99, 90, 0.5);
}

.module-danger-90:active, .module-danger-90.active,
.show > .module-danger-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 17, 3, 0.9);
  background-image: none;
  border-color: rgba(245, 13, 0, 0.9);
}

.module-danger-90:active:focus, .module-danger-90.active:focus,
.show > .module-danger-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 99, 90, 0.5);
}

.module-danger-90:disabled, .module-danger-90.disabled {
  color: #fff;
  background-color: rgba(255, 65, 54, 0.9);
  background-image: none;
  border-color: rgba(255, 65, 54, 0.9);
}

.module-dark {
  color: #fff;
  background: #333 linear-gradient(180deg, #4f5051, #333) repeat-x;
  border-color: #333;
}

.module-dark .icon-container {
  background-color: #202020;
}

.module-dark .icon-container.light {
  background-color: #595959;
}

.module-dark:hover {
  color: #fff;
  background: #202020 linear-gradient(180deg, #3f4041, #202020) repeat-x;
  border-color: color-yig(#202020);
}

.module-dark:focus, .module-dark.focus {
  color: #fff;
  background: #202020 linear-gradient(180deg, #3f4041, #202020) repeat-x;
  border-color: color-yig(#202020);
  box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
}

.module-dark:active, .module-dark.active,
.show > .module-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1a1a;
  background-image: none;
  border-color: #131313;
}

.module-dark:active:focus, .module-dark.active:focus,
.show > .module-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
}

.module-dark:disabled, .module-dark.disabled {
  color: #fff;
  background-color: #333;
  background-image: none;
  border-color: #333;
}

.module-dark-10 {
  color: #fff;
  background: rgba(51, 51, 51, 0.1) linear-gradient(180deg, rgba(195, 200, 204, 0.235), rgba(51, 51, 51, 0.1)) repeat-x;
  border-color: rgba(51, 51, 51, 0.1);
}

.module-dark-10 .icon-container {
  background-color: rgba(32, 32, 32, 0.1);
}

.module-dark-10 .icon-container.light {
  background-color: rgba(89, 89, 89, 0.1);
}

.module-dark-10:hover {
  color: #fff;
  background: rgba(32, 32, 32, 0.1) linear-gradient(180deg, rgba(191, 196, 200, 0.235), rgba(32, 32, 32, 0.1)) repeat-x;
  border-color: color-yig(rgba(32, 32, 32, 0.1));
}

.module-dark-10:focus, .module-dark-10.focus {
  color: #fff;
  background: rgba(32, 32, 32, 0.1) linear-gradient(180deg, rgba(191, 196, 200, 0.235), rgba(32, 32, 32, 0.1)) repeat-x;
  border-color: color-yig(rgba(32, 32, 32, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(208, 208, 208, 0.5);
}

.module-dark-10:active, .module-dark-10.active,
.show > .module-dark-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(26, 26, 26, 0.1);
  background-image: none;
  border-color: rgba(19, 19, 19, 0.1);
}

.module-dark-10:active:focus, .module-dark-10.active:focus,
.show > .module-dark-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(208, 208, 208, 0.5);
}

.module-dark-10:disabled, .module-dark-10.disabled {
  color: #fff;
  background-color: rgba(51, 51, 51, 0.1);
  background-image: none;
  border-color: rgba(51, 51, 51, 0.1);
}

.module-dark-20 {
  color: #fff;
  background: rgba(51, 51, 51, 0.2) linear-gradient(180deg, rgba(166, 170, 173, 0.32), rgba(51, 51, 51, 0.2)) repeat-x;
  border-color: rgba(51, 51, 51, 0.2);
}

.module-dark-20 .icon-container {
  background-color: rgba(32, 32, 32, 0.2);
}

.module-dark-20 .icon-container.light {
  background-color: rgba(89, 89, 89, 0.2);
}

.module-dark-20:hover {
  color: #fff;
  background: rgba(32, 32, 32, 0.2) linear-gradient(180deg, rgba(158, 163, 166, 0.32), rgba(32, 32, 32, 0.2)) repeat-x;
  border-color: color-yig(rgba(32, 32, 32, 0.2));
}

.module-dark-20:focus, .module-dark-20.focus {
  color: #fff;
  background: rgba(32, 32, 32, 0.2) linear-gradient(180deg, rgba(158, 163, 166, 0.32), rgba(32, 32, 32, 0.2)) repeat-x;
  border-color: color-yig(rgba(32, 32, 32, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(176, 176, 176, 0.5);
}

.module-dark-20:active, .module-dark-20.active,
.show > .module-dark-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(26, 26, 26, 0.2);
  background-image: none;
  border-color: rgba(19, 19, 19, 0.2);
}

.module-dark-20:active:focus, .module-dark-20.active:focus,
.show > .module-dark-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(176, 176, 176, 0.5);
}

.module-dark-20:disabled, .module-dark-20.disabled {
  color: #fff;
  background-color: rgba(51, 51, 51, 0.2);
  background-image: none;
  border-color: rgba(51, 51, 51, 0.2);
}

.module-dark-30 {
  color: #fff;
  background: rgba(51, 51, 51, 0.3) linear-gradient(180deg, rgba(145, 148, 151, 0.405), rgba(51, 51, 51, 0.3)) repeat-x;
  border-color: rgba(51, 51, 51, 0.3);
}

.module-dark-30 .icon-container {
  background-color: rgba(32, 32, 32, 0.3);
}

.module-dark-30 .icon-container.light {
  background-color: rgba(89, 89, 89, 0.3);
}

.module-dark-30:hover {
  color: #fff;
  background: rgba(32, 32, 32, 0.3) linear-gradient(180deg, rgba(135, 138, 141, 0.405), rgba(32, 32, 32, 0.3)) repeat-x;
  border-color: color-yig(rgba(32, 32, 32, 0.3));
}

.module-dark-30:focus, .module-dark-30.focus {
  color: #fff;
  background: rgba(32, 32, 32, 0.3) linear-gradient(180deg, rgba(135, 138, 141, 0.405), rgba(32, 32, 32, 0.3)) repeat-x;
  border-color: color-yig(rgba(32, 32, 32, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.module-dark-30:active, .module-dark-30.active,
.show > .module-dark-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(26, 26, 26, 0.3);
  background-image: none;
  border-color: rgba(19, 19, 19, 0.3);
}

.module-dark-30:active:focus, .module-dark-30.active:focus,
.show > .module-dark-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.module-dark-30:disabled, .module-dark-30.disabled {
  color: #fff;
  background-color: rgba(51, 51, 51, 0.3);
  background-image: none;
  border-color: rgba(51, 51, 51, 0.3);
}

.module-dark-40 {
  color: #fff;
  background: rgba(51, 51, 51, 0.4) linear-gradient(180deg, rgba(128, 131, 133, 0.49), rgba(51, 51, 51, 0.4)) repeat-x;
  border-color: rgba(51, 51, 51, 0.4);
}

.module-dark-40 .icon-container {
  background-color: rgba(32, 32, 32, 0.4);
}

.module-dark-40 .icon-container.light {
  background-color: rgba(89, 89, 89, 0.4);
}

.module-dark-40:hover {
  color: #fff;
  background: rgba(32, 32, 32, 0.4) linear-gradient(180deg, rgba(117, 120, 122, 0.49), rgba(32, 32, 32, 0.4)) repeat-x;
  border-color: color-yig(rgba(32, 32, 32, 0.4));
}

.module-dark-40:focus, .module-dark-40.focus {
  color: #fff;
  background: rgba(32, 32, 32, 0.4) linear-gradient(180deg, rgba(117, 120, 122, 0.49), rgba(32, 32, 32, 0.4)) repeat-x;
  border-color: color-yig(rgba(32, 32, 32, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(135, 135, 135, 0.5);
}

.module-dark-40:active, .module-dark-40.active,
.show > .module-dark-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(26, 26, 26, 0.4);
  background-image: none;
  border-color: rgba(19, 19, 19, 0.4);
}

.module-dark-40:active:focus, .module-dark-40.active:focus,
.show > .module-dark-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(135, 135, 135, 0.5);
}

.module-dark-40:disabled, .module-dark-40.disabled {
  color: #fff;
  background-color: rgba(51, 51, 51, 0.4);
  background-image: none;
  border-color: rgba(51, 51, 51, 0.4);
}

.module-dark-50 {
  color: #fff;
  background: rgba(51, 51, 51, 0.5) linear-gradient(180deg, rgba(116, 118, 120, 0.575), rgba(51, 51, 51, 0.5)) repeat-x;
  border-color: rgba(51, 51, 51, 0.5);
}

.module-dark-50 .icon-container {
  background-color: rgba(32, 32, 32, 0.5);
}

.module-dark-50 .icon-container.light {
  background-color: rgba(89, 89, 89, 0.5);
}

.module-dark-50:hover {
  color: #fff;
  background: rgba(32, 32, 32, 0.5) linear-gradient(180deg, rgba(103, 106, 107, 0.575), rgba(32, 32, 32, 0.5)) repeat-x;
  border-color: color-yig(rgba(32, 32, 32, 0.5));
}

.module-dark-50:focus, .module-dark-50.focus {
  color: #fff;
  background: rgba(32, 32, 32, 0.5) linear-gradient(180deg, rgba(103, 106, 107, 0.575), rgba(32, 32, 32, 0.5)) repeat-x;
  border-color: color-yig(rgba(32, 32, 32, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(122, 122, 122, 0.5);
}

.module-dark-50:active, .module-dark-50.active,
.show > .module-dark-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(26, 26, 26, 0.5);
  background-image: none;
  border-color: rgba(19, 19, 19, 0.5);
}

.module-dark-50:active:focus, .module-dark-50.active:focus,
.show > .module-dark-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 122, 122, 0.5);
}

.module-dark-50:disabled, .module-dark-50.disabled {
  color: #fff;
  background-color: rgba(51, 51, 51, 0.5);
  background-image: none;
  border-color: rgba(51, 51, 51, 0.5);
}

.module-dark-60 {
  color: #fff;
  background: rgba(51, 51, 51, 0.6) linear-gradient(180deg, rgba(106, 108, 109, 0.66), rgba(51, 51, 51, 0.6)) repeat-x;
  border-color: rgba(51, 51, 51, 0.6);
}

.module-dark-60 .icon-container {
  background-color: rgba(32, 32, 32, 0.6);
}

.module-dark-60 .icon-container.light {
  background-color: rgba(89, 89, 89, 0.6);
}

.module-dark-60:hover {
  color: #fff;
  background: rgba(32, 32, 32, 0.6) linear-gradient(180deg, rgba(92, 94, 95, 0.66), rgba(32, 32, 32, 0.6)) repeat-x;
  border-color: color-yig(rgba(32, 32, 32, 0.6));
}

.module-dark-60:focus, .module-dark-60.focus {
  color: #fff;
  background: rgba(32, 32, 32, 0.6) linear-gradient(180deg, rgba(92, 94, 95, 0.66), rgba(32, 32, 32, 0.6)) repeat-x;
  border-color: color-yig(rgba(32, 32, 32, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(111, 111, 111, 0.5);
}

.module-dark-60:active, .module-dark-60.active,
.show > .module-dark-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(26, 26, 26, 0.6);
  background-image: none;
  border-color: rgba(19, 19, 19, 0.6);
}

.module-dark-60:active:focus, .module-dark-60.active:focus,
.show > .module-dark-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 111, 111, 0.5);
}

.module-dark-60:disabled, .module-dark-60.disabled {
  color: #fff;
  background-color: rgba(51, 51, 51, 0.6);
  background-image: none;
  border-color: rgba(51, 51, 51, 0.6);
}

.module-dark-70 {
  color: #fff;
  background: rgba(51, 51, 51, 0.7) linear-gradient(180deg, rgba(97, 99, 100, 0.745), rgba(51, 51, 51, 0.7)) repeat-x;
  border-color: rgba(51, 51, 51, 0.7);
}

.module-dark-70 .icon-container {
  background-color: rgba(32, 32, 32, 0.7);
}

.module-dark-70 .icon-container.light {
  background-color: rgba(89, 89, 89, 0.7);
}

.module-dark-70:hover {
  color: #fff;
  background: rgba(32, 32, 32, 0.7) linear-gradient(180deg, rgba(83, 84, 86, 0.745), rgba(32, 32, 32, 0.7)) repeat-x;
  border-color: color-yig(rgba(32, 32, 32, 0.7));
}

.module-dark-70:focus, .module-dark-70.focus {
  color: #fff;
  background: rgba(32, 32, 32, 0.7) linear-gradient(180deg, rgba(83, 84, 86, 0.745), rgba(32, 32, 32, 0.7)) repeat-x;
  border-color: color-yig(rgba(32, 32, 32, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(101, 101, 101, 0.5);
}

.module-dark-70:active, .module-dark-70.active,
.show > .module-dark-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(26, 26, 26, 0.7);
  background-image: none;
  border-color: rgba(19, 19, 19, 0.7);
}

.module-dark-70:active:focus, .module-dark-70.active:focus,
.show > .module-dark-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(101, 101, 101, 0.5);
}

.module-dark-70:disabled, .module-dark-70.disabled {
  color: #fff;
  background-color: rgba(51, 51, 51, 0.7);
  background-image: none;
  border-color: rgba(51, 51, 51, 0.7);
}

.module-dark-80 {
  color: #fff;
  background: rgba(51, 51, 51, 0.8) linear-gradient(180deg, rgba(90, 92, 93, 0.83), rgba(51, 51, 51, 0.8)) repeat-x;
  border-color: rgba(51, 51, 51, 0.8);
}

.module-dark-80 .icon-container {
  background-color: rgba(32, 32, 32, 0.8);
}

.module-dark-80 .icon-container.light {
  background-color: rgba(89, 89, 89, 0.8);
}

.module-dark-80:hover {
  color: #fff;
  background: rgba(32, 32, 32, 0.8) linear-gradient(180deg, rgba(75, 76, 78, 0.83), rgba(32, 32, 32, 0.8)) repeat-x;
  border-color: color-yig(rgba(32, 32, 32, 0.8));
}

.module-dark-80:focus, .module-dark-80.focus {
  color: #fff;
  background: rgba(32, 32, 32, 0.8) linear-gradient(180deg, rgba(75, 76, 78, 0.83), rgba(32, 32, 32, 0.8)) repeat-x;
  border-color: color-yig(rgba(32, 32, 32, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(94, 94, 94, 0.5);
}

.module-dark-80:active, .module-dark-80.active,
.show > .module-dark-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(26, 26, 26, 0.8);
  background-image: none;
  border-color: rgba(19, 19, 19, 0.8);
}

.module-dark-80:active:focus, .module-dark-80.active:focus,
.show > .module-dark-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(94, 94, 94, 0.5);
}

.module-dark-80:disabled, .module-dark-80.disabled {
  color: #fff;
  background-color: rgba(51, 51, 51, 0.8);
  background-image: none;
  border-color: rgba(51, 51, 51, 0.8);
}

.module-dark-90 {
  color: #fff;
  background: rgba(51, 51, 51, 0.9) linear-gradient(180deg, rgba(84, 85, 86, 0.915), rgba(51, 51, 51, 0.9)) repeat-x;
  border-color: rgba(51, 51, 51, 0.9);
}

.module-dark-90 .icon-container {
  background-color: rgba(32, 32, 32, 0.9);
}

.module-dark-90 .icon-container.light {
  background-color: rgba(89, 89, 89, 0.9);
}

.module-dark-90:hover {
  color: #fff;
  background: rgba(32, 32, 32, 0.9) linear-gradient(180deg, rgba(68, 70, 71, 0.915), rgba(32, 32, 32, 0.9)) repeat-x;
  border-color: color-yig(rgba(32, 32, 32, 0.9));
}

.module-dark-90:focus, .module-dark-90.focus {
  color: #fff;
  background: rgba(32, 32, 32, 0.9) linear-gradient(180deg, rgba(68, 70, 71, 0.915), rgba(32, 32, 32, 0.9)) repeat-x;
  border-color: color-yig(rgba(32, 32, 32, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(87, 87, 87, 0.5);
}

.module-dark-90:active, .module-dark-90.active,
.show > .module-dark-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(26, 26, 26, 0.9);
  background-image: none;
  border-color: rgba(19, 19, 19, 0.9);
}

.module-dark-90:active:focus, .module-dark-90.active:focus,
.show > .module-dark-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(87, 87, 87, 0.5);
}

.module-dark-90:disabled, .module-dark-90.disabled {
  color: #fff;
  background-color: rgba(51, 51, 51, 0.9);
  background-image: none;
  border-color: rgba(51, 51, 51, 0.9);
}

.module-deep-ocean {
  color: #fff;
  background: #023946 linear-gradient(180deg, #255561, #023946) repeat-x;
  border-color: #023946;
}

.module-deep-ocean .icon-container {
  background-color: #011b21;
}

.module-deep-ocean .icon-container.light {
  background-color: #047690;
}

.module-deep-ocean:hover {
  color: #fff;
  background: #011b21 linear-gradient(180deg, #243b41, #011b21) repeat-x;
  border-color: color-yig(#011b21);
}

.module-deep-ocean:focus, .module-deep-ocean.focus {
  color: #fff;
  background: #011b21 linear-gradient(180deg, #243b41, #011b21) repeat-x;
  border-color: color-yig(#011b21);
  box-shadow: 0 0 0 0.2rem rgba(40, 87, 98, 0.5);
}

.module-deep-ocean:active, .module-deep-ocean.active,
.show > .module-deep-ocean.dropdown-toggle {
  color: #fff;
  background-color: #011114;
  background-image: none;
  border-color: #000708;
}

.module-deep-ocean:active:focus, .module-deep-ocean.active:focus,
.show > .module-deep-ocean.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 87, 98, 0.5);
}

.module-deep-ocean:disabled, .module-deep-ocean.disabled {
  color: #fff;
  background-color: #023946;
  background-image: none;
  border-color: #023946;
}

.module-deep-ocean-10 {
  color: #fff;
  background: rgba(2, 57, 70, 0.1) linear-gradient(180deg, rgba(184, 202, 209, 0.235), rgba(2, 57, 70, 0.1)) repeat-x;
  border-color: rgba(2, 57, 70, 0.1);
}

.module-deep-ocean-10 .icon-container {
  background-color: rgba(1, 27, 33, 0.1);
}

.module-deep-ocean-10 .icon-container.light {
  background-color: rgba(4, 118, 144, 0.1);
}

.module-deep-ocean-10:hover {
  color: #fff;
  background: rgba(1, 27, 33, 0.1) linear-gradient(180deg, rgba(184, 195, 200, 0.235), rgba(1, 27, 33, 0.1)) repeat-x;
  border-color: color-yig(rgba(1, 27, 33, 0.1));
}

.module-deep-ocean-10:focus, .module-deep-ocean-10.focus {
  color: #fff;
  background: rgba(1, 27, 33, 0.1) linear-gradient(180deg, rgba(184, 195, 200, 0.235), rgba(1, 27, 33, 0.1)) repeat-x;
  border-color: color-yig(rgba(1, 27, 33, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(197, 210, 213, 0.5);
}

.module-deep-ocean-10:active, .module-deep-ocean-10.active,
.show > .module-deep-ocean-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(1, 17, 20, 0.1);
  background-image: none;
  border-color: rgba(0, 7, 8, 0.1);
}

.module-deep-ocean-10:active:focus, .module-deep-ocean-10.active:focus,
.show > .module-deep-ocean-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(197, 210, 213, 0.5);
}

.module-deep-ocean-10:disabled, .module-deep-ocean-10.disabled {
  color: #fff;
  background-color: rgba(2, 57, 70, 0.1);
  background-image: none;
  border-color: rgba(2, 57, 70, 0.1);
}

.module-deep-ocean-20 {
  color: #fff;
  background: rgba(2, 57, 70, 0.2) linear-gradient(180deg, rgba(147, 172, 180, 0.32), rgba(2, 57, 70, 0.2)) repeat-x;
  border-color: rgba(2, 57, 70, 0.2);
}

.module-deep-ocean-20 .icon-container {
  background-color: rgba(1, 27, 33, 0.2);
}

.module-deep-ocean-20 .icon-container.light {
  background-color: rgba(4, 118, 144, 0.2);
}

.module-deep-ocean-20:hover {
  color: #fff;
  background: rgba(1, 27, 33, 0.2) linear-gradient(180deg, rgba(146, 161, 166, 0.32), rgba(1, 27, 33, 0.2)) repeat-x;
  border-color: color-yig(rgba(1, 27, 33, 0.2));
}

.module-deep-ocean-20:focus, .module-deep-ocean-20.focus {
  color: #fff;
  background: rgba(1, 27, 33, 0.2) linear-gradient(180deg, rgba(146, 161, 166, 0.32), rgba(1, 27, 33, 0.2)) repeat-x;
  border-color: color-yig(rgba(1, 27, 33, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(157, 179, 184, 0.5);
}

.module-deep-ocean-20:active, .module-deep-ocean-20.active,
.show > .module-deep-ocean-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(1, 17, 20, 0.2);
  background-image: none;
  border-color: rgba(0, 7, 8, 0.2);
}

.module-deep-ocean-20:active:focus, .module-deep-ocean-20.active:focus,
.show > .module-deep-ocean-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(157, 179, 184, 0.5);
}

.module-deep-ocean-20:disabled, .module-deep-ocean-20.disabled {
  color: #fff;
  background-color: rgba(2, 57, 70, 0.2);
  background-image: none;
  border-color: rgba(2, 57, 70, 0.2);
}

.module-deep-ocean-30 {
  color: #fff;
  background: rgba(2, 57, 70, 0.3) linear-gradient(180deg, rgba(120, 151, 160, 0.405), rgba(2, 57, 70, 0.3)) repeat-x;
  border-color: rgba(2, 57, 70, 0.3);
}

.module-deep-ocean-30 .icon-container {
  background-color: rgba(1, 27, 33, 0.3);
}

.module-deep-ocean-30 .icon-container.light {
  background-color: rgba(4, 118, 144, 0.3);
}

.module-deep-ocean-30:hover {
  color: #fff;
  background: rgba(1, 27, 33, 0.3) linear-gradient(180deg, rgba(119, 136, 141, 0.405), rgba(1, 27, 33, 0.3)) repeat-x;
  border-color: color-yig(rgba(1, 27, 33, 0.3));
}

.module-deep-ocean-30:focus, .module-deep-ocean-30.focus {
  color: #fff;
  background: rgba(1, 27, 33, 0.3) linear-gradient(180deg, rgba(119, 136, 141, 0.405), rgba(1, 27, 33, 0.3)) repeat-x;
  border-color: color-yig(rgba(1, 27, 33, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(129, 156, 163, 0.5);
}

.module-deep-ocean-30:active, .module-deep-ocean-30.active,
.show > .module-deep-ocean-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(1, 17, 20, 0.3);
  background-image: none;
  border-color: rgba(0, 7, 8, 0.3);
}

.module-deep-ocean-30:active:focus, .module-deep-ocean-30.active:focus,
.show > .module-deep-ocean-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(129, 156, 163, 0.5);
}

.module-deep-ocean-30:disabled, .module-deep-ocean-30.disabled {
  color: #fff;
  background-color: rgba(2, 57, 70, 0.3);
  background-image: none;
  border-color: rgba(2, 57, 70, 0.3);
}

.module-deep-ocean-40 {
  color: #fff;
  background: rgba(2, 57, 70, 0.4) linear-gradient(180deg, rgba(100, 135, 144, 0.49), rgba(2, 57, 70, 0.4)) repeat-x;
  border-color: rgba(2, 57, 70, 0.4);
}

.module-deep-ocean-40 .icon-container {
  background-color: rgba(1, 27, 33, 0.4);
}

.module-deep-ocean-40 .icon-container.light {
  background-color: rgba(4, 118, 144, 0.4);
}

.module-deep-ocean-40:hover {
  color: #fff;
  background: rgba(1, 27, 33, 0.4) linear-gradient(180deg, rgba(99, 117, 123, 0.49), rgba(1, 27, 33, 0.4)) repeat-x;
  border-color: color-yig(rgba(1, 27, 33, 0.4));
}

.module-deep-ocean-40:focus, .module-deep-ocean-40.focus {
  color: #fff;
  background: rgba(1, 27, 33, 0.4) linear-gradient(180deg, rgba(99, 117, 123, 0.49), rgba(1, 27, 33, 0.4)) repeat-x;
  border-color: color-yig(rgba(1, 27, 33, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(107, 139, 147, 0.5);
}

.module-deep-ocean-40:active, .module-deep-ocean-40.active,
.show > .module-deep-ocean-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(1, 17, 20, 0.4);
  background-image: none;
  border-color: rgba(0, 7, 8, 0.4);
}

.module-deep-ocean-40:active:focus, .module-deep-ocean-40.active:focus,
.show > .module-deep-ocean-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(107, 139, 147, 0.5);
}

.module-deep-ocean-40:disabled, .module-deep-ocean-40.disabled {
  color: #fff;
  background-color: rgba(2, 57, 70, 0.4);
  background-image: none;
  border-color: rgba(2, 57, 70, 0.4);
}

.module-deep-ocean-50 {
  color: #fff;
  background: rgba(2, 57, 70, 0.5) linear-gradient(180deg, rgba(84, 122, 132, 0.575), rgba(2, 57, 70, 0.5)) repeat-x;
  border-color: rgba(2, 57, 70, 0.5);
}

.module-deep-ocean-50 .icon-container {
  background-color: rgba(1, 27, 33, 0.5);
}

.module-deep-ocean-50 .icon-container.light {
  background-color: rgba(4, 118, 144, 0.5);
}

.module-deep-ocean-50:hover {
  color: #fff;
  background: rgba(1, 27, 33, 0.5) linear-gradient(180deg, rgba(83, 102, 108, 0.575), rgba(1, 27, 33, 0.5)) repeat-x;
  border-color: color-yig(rgba(1, 27, 33, 0.5));
}

.module-deep-ocean-50:focus, .module-deep-ocean-50.focus {
  color: #fff;
  background: rgba(1, 27, 33, 0.5) linear-gradient(180deg, rgba(83, 102, 108, 0.575), rgba(1, 27, 33, 0.5)) repeat-x;
  border-color: color-yig(rgba(1, 27, 33, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(90, 126, 134, 0.5);
}

.module-deep-ocean-50:active, .module-deep-ocean-50.active,
.show > .module-deep-ocean-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(1, 17, 20, 0.5);
  background-image: none;
  border-color: rgba(0, 7, 8, 0.5);
}

.module-deep-ocean-50:active:focus, .module-deep-ocean-50.active:focus,
.show > .module-deep-ocean-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(90, 126, 134, 0.5);
}

.module-deep-ocean-50:disabled, .module-deep-ocean-50.disabled {
  color: #fff;
  background-color: rgba(2, 57, 70, 0.5);
  background-image: none;
  border-color: rgba(2, 57, 70, 0.5);
}

.module-deep-ocean-60 {
  color: #fff;
  background: rgba(2, 57, 70, 0.6) linear-gradient(180deg, rgba(71, 112, 123, 0.66), rgba(2, 57, 70, 0.6)) repeat-x;
  border-color: rgba(2, 57, 70, 0.6);
}

.module-deep-ocean-60 .icon-container {
  background-color: rgba(1, 27, 33, 0.6);
}

.module-deep-ocean-60 .icon-container.light {
  background-color: rgba(4, 118, 144, 0.6);
}

.module-deep-ocean-60:hover {
  color: #fff;
  background: rgba(1, 27, 33, 0.6) linear-gradient(180deg, rgba(70, 90, 96, 0.66), rgba(1, 27, 33, 0.6)) repeat-x;
  border-color: color-yig(rgba(1, 27, 33, 0.6));
}

.module-deep-ocean-60:focus, .module-deep-ocean-60.focus {
  color: #fff;
  background: rgba(1, 27, 33, 0.6) linear-gradient(180deg, rgba(70, 90, 96, 0.66), rgba(1, 27, 33, 0.6)) repeat-x;
  border-color: color-yig(rgba(1, 27, 33, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(76, 115, 124, 0.5);
}

.module-deep-ocean-60:active, .module-deep-ocean-60.active,
.show > .module-deep-ocean-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(1, 17, 20, 0.6);
  background-image: none;
  border-color: rgba(0, 7, 8, 0.6);
}

.module-deep-ocean-60:active:focus, .module-deep-ocean-60.active:focus,
.show > .module-deep-ocean-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 115, 124, 0.5);
}

.module-deep-ocean-60:disabled, .module-deep-ocean-60.disabled {
  color: #fff;
  background-color: rgba(2, 57, 70, 0.6);
  background-image: none;
  border-color: rgba(2, 57, 70, 0.6);
}

.module-deep-ocean-70 {
  color: #fff;
  background: rgba(2, 57, 70, 0.7) linear-gradient(180deg, rgba(60, 103, 114, 0.745), rgba(2, 57, 70, 0.7)) repeat-x;
  border-color: rgba(2, 57, 70, 0.7);
}

.module-deep-ocean-70 .icon-container {
  background-color: rgba(1, 27, 33, 0.7);
}

.module-deep-ocean-70 .icon-container.light {
  background-color: rgba(4, 118, 144, 0.7);
}

.module-deep-ocean-70:hover {
  color: #fff;
  background: rgba(1, 27, 33, 0.7) linear-gradient(180deg, rgba(59, 81, 86, 0.745), rgba(1, 27, 33, 0.7)) repeat-x;
  border-color: color-yig(rgba(1, 27, 33, 0.7));
}

.module-deep-ocean-70:focus, .module-deep-ocean-70.focus {
  color: #fff;
  background: rgba(1, 27, 33, 0.7) linear-gradient(180deg, rgba(59, 81, 86, 0.745), rgba(1, 27, 33, 0.7)) repeat-x;
  border-color: color-yig(rgba(1, 27, 33, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(64, 106, 116, 0.5);
}

.module-deep-ocean-70:active, .module-deep-ocean-70.active,
.show > .module-deep-ocean-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(1, 17, 20, 0.7);
  background-image: none;
  border-color: rgba(0, 7, 8, 0.7);
}

.module-deep-ocean-70:active:focus, .module-deep-ocean-70.active:focus,
.show > .module-deep-ocean-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 106, 116, 0.5);
}

.module-deep-ocean-70:disabled, .module-deep-ocean-70.disabled {
  color: #fff;
  background-color: rgba(2, 57, 70, 0.7);
  background-image: none;
  border-color: rgba(2, 57, 70, 0.7);
}

.module-deep-ocean-80 {
  color: #fff;
  background: rgba(2, 57, 70, 0.8) linear-gradient(180deg, rgba(51, 96, 108, 0.83), rgba(2, 57, 70, 0.8)) repeat-x;
  border-color: rgba(2, 57, 70, 0.8);
}

.module-deep-ocean-80 .icon-container {
  background-color: rgba(1, 27, 33, 0.8);
}

.module-deep-ocean-80 .icon-container.light {
  background-color: rgba(4, 118, 144, 0.8);
}

.module-deep-ocean-80:hover {
  color: #fff;
  background: rgba(1, 27, 33, 0.8) linear-gradient(180deg, rgba(51, 72, 78, 0.83), rgba(1, 27, 33, 0.8)) repeat-x;
  border-color: color-yig(rgba(1, 27, 33, 0.8));
}

.module-deep-ocean-80:focus, .module-deep-ocean-80.focus {
  color: #fff;
  background: rgba(1, 27, 33, 0.8) linear-gradient(180deg, rgba(51, 72, 78, 0.83), rgba(1, 27, 33, 0.8)) repeat-x;
  border-color: color-yig(rgba(1, 27, 33, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(55, 98, 109, 0.5);
}

.module-deep-ocean-80:active, .module-deep-ocean-80.active,
.show > .module-deep-ocean-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(1, 17, 20, 0.8);
  background-image: none;
  border-color: rgba(0, 7, 8, 0.8);
}

.module-deep-ocean-80:active:focus, .module-deep-ocean-80.active:focus,
.show > .module-deep-ocean-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 98, 109, 0.5);
}

.module-deep-ocean-80:disabled, .module-deep-ocean-80.disabled {
  color: #fff;
  background-color: rgba(2, 57, 70, 0.8);
  background-image: none;
  border-color: rgba(2, 57, 70, 0.8);
}

.module-deep-ocean-90 {
  color: #fff;
  background: rgba(2, 57, 70, 0.9) linear-gradient(180deg, rgba(44, 90, 102, 0.915), rgba(2, 57, 70, 0.9)) repeat-x;
  border-color: rgba(2, 57, 70, 0.9);
}

.module-deep-ocean-90 .icon-container {
  background-color: rgba(1, 27, 33, 0.9);
}

.module-deep-ocean-90 .icon-container.light {
  background-color: rgba(4, 118, 144, 0.9);
}

.module-deep-ocean-90:hover {
  color: #fff;
  background: rgba(1, 27, 33, 0.9) linear-gradient(180deg, rgba(43, 65, 71, 0.915), rgba(1, 27, 33, 0.9)) repeat-x;
  border-color: color-yig(rgba(1, 27, 33, 0.9));
}

.module-deep-ocean-90:focus, .module-deep-ocean-90.focus {
  color: #fff;
  background: rgba(1, 27, 33, 0.9) linear-gradient(180deg, rgba(43, 65, 71, 0.915), rgba(1, 27, 33, 0.9)) repeat-x;
  border-color: color-yig(rgba(1, 27, 33, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(47, 92, 103, 0.5);
}

.module-deep-ocean-90:active, .module-deep-ocean-90.active,
.show > .module-deep-ocean-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(1, 17, 20, 0.9);
  background-image: none;
  border-color: rgba(0, 7, 8, 0.9);
}

.module-deep-ocean-90:active:focus, .module-deep-ocean-90.active:focus,
.show > .module-deep-ocean-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 92, 103, 0.5);
}

.module-deep-ocean-90:disabled, .module-deep-ocean-90.disabled {
  color: #fff;
  background-color: rgba(2, 57, 70, 0.9);
  background-image: none;
  border-color: rgba(2, 57, 70, 0.9);
}

.module-teal {
  color: #fff;
  background: #035f75 linear-gradient(180deg, #267689, #035f75) repeat-x;
  border-color: #035f75;
}

.module-teal .icon-container {
  background-color: #024150;
}

.module-teal .icon-container.light {
  background-color: #059cc0;
}

.module-teal:hover {
  color: #fff;
  background: #024150 linear-gradient(180deg, #255c69, #024150) repeat-x;
  border-color: color-yig(#024150);
}

.module-teal:focus, .module-teal.focus {
  color: #fff;
  background: #024150 linear-gradient(180deg, #255c69, #024150) repeat-x;
  border-color: color-yig(#024150);
  box-shadow: 0 0 0 0.2rem rgba(41, 119, 138, 0.5);
}

.module-teal:active, .module-teal.active,
.show > .module-teal.dropdown-toggle {
  color: #fff;
  background-color: #023743;
  background-image: none;
  border-color: #012d37;
}

.module-teal:active:focus, .module-teal.active:focus,
.show > .module-teal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(41, 119, 138, 0.5);
}

.module-teal:disabled, .module-teal.disabled {
  color: #fff;
  background-color: #035f75;
  background-image: none;
  border-color: #035f75;
}

.module-teal-10 {
  color: #fff;
  background: rgba(3, 95, 117, 0.1) linear-gradient(180deg, rgba(184, 211, 219, 0.235), rgba(3, 95, 117, 0.1)) repeat-x;
  border-color: rgba(3, 95, 117, 0.1);
}

.module-teal-10 .icon-container {
  background-color: rgba(2, 65, 80, 0.1);
}

.module-teal-10 .icon-container.light {
  background-color: rgba(5, 156, 192, 0.1);
}

.module-teal-10:hover {
  color: #fff;
  background: rgba(2, 65, 80, 0.1) linear-gradient(180deg, rgba(184, 204, 211, 0.235), rgba(2, 65, 80, 0.1)) repeat-x;
  border-color: color-yig(rgba(2, 65, 80, 0.1));
}

.module-teal-10:focus, .module-teal-10.focus {
  color: #fff;
  background: rgba(2, 65, 80, 0.1) linear-gradient(180deg, rgba(184, 204, 211, 0.235), rgba(2, 65, 80, 0.1)) repeat-x;
  border-color: color-yig(rgba(2, 65, 80, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(197, 218, 223, 0.5);
}

.module-teal-10:active, .module-teal-10.active,
.show > .module-teal-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(2, 55, 67, 0.1);
  background-image: none;
  border-color: rgba(1, 45, 55, 0.1);
}

.module-teal-10:active:focus, .module-teal-10.active:focus,
.show > .module-teal-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(197, 218, 223, 0.5);
}

.module-teal-10:disabled, .module-teal-10.disabled {
  color: #fff;
  background-color: rgba(3, 95, 117, 0.1);
  background-image: none;
  border-color: rgba(3, 95, 117, 0.1);
}

.module-teal-20 {
  color: #fff;
  background: rgba(3, 95, 117, 0.2) linear-gradient(180deg, rgba(147, 187, 199, 0.32), rgba(3, 95, 117, 0.2)) repeat-x;
  border-color: rgba(3, 95, 117, 0.2);
}

.module-teal-20 .icon-container {
  background-color: rgba(2, 65, 80, 0.2);
}

.module-teal-20 .icon-container.light {
  background-color: rgba(5, 156, 192, 0.2);
}

.module-teal-20:hover {
  color: #fff;
  background: rgba(2, 65, 80, 0.2) linear-gradient(180deg, rgba(147, 175, 184, 0.32), rgba(2, 65, 80, 0.2)) repeat-x;
  border-color: color-yig(rgba(2, 65, 80, 0.2));
}

.module-teal-20:focus, .module-teal-20.focus {
  color: #fff;
  background: rgba(2, 65, 80, 0.2) linear-gradient(180deg, rgba(147, 175, 184, 0.32), rgba(2, 65, 80, 0.2)) repeat-x;
  border-color: color-yig(rgba(2, 65, 80, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(158, 193, 202, 0.5);
}

.module-teal-20:active, .module-teal-20.active,
.show > .module-teal-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(2, 55, 67, 0.2);
  background-image: none;
  border-color: rgba(1, 45, 55, 0.2);
}

.module-teal-20:active:focus, .module-teal-20.active:focus,
.show > .module-teal-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(158, 193, 202, 0.5);
}

.module-teal-20:disabled, .module-teal-20.disabled {
  color: #fff;
  background-color: rgba(3, 95, 117, 0.2);
  background-image: none;
  border-color: rgba(3, 95, 117, 0.2);
}

.module-teal-30 {
  color: #fff;
  background: rgba(3, 95, 117, 0.3) linear-gradient(180deg, rgba(121, 170, 184, 0.405), rgba(3, 95, 117, 0.3)) repeat-x;
  border-color: rgba(3, 95, 117, 0.3);
}

.module-teal-30 .icon-container {
  background-color: rgba(2, 65, 80, 0.3);
}

.module-teal-30 .icon-container.light {
  background-color: rgba(5, 156, 192, 0.3);
}

.module-teal-30:hover {
  color: #fff;
  background: rgba(2, 65, 80, 0.3) linear-gradient(180deg, rgba(120, 155, 165, 0.405), rgba(2, 65, 80, 0.3)) repeat-x;
  border-color: color-yig(rgba(2, 65, 80, 0.3));
}

.module-teal-30:focus, .module-teal-30.focus {
  color: #fff;
  background: rgba(2, 65, 80, 0.3) linear-gradient(180deg, rgba(120, 155, 165, 0.405), rgba(2, 65, 80, 0.3)) repeat-x;
  border-color: color-yig(rgba(2, 65, 80, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(129, 175, 186, 0.5);
}

.module-teal-30:active, .module-teal-30.active,
.show > .module-teal-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(2, 55, 67, 0.3);
  background-image: none;
  border-color: rgba(1, 45, 55, 0.3);
}

.module-teal-30:active:focus, .module-teal-30.active:focus,
.show > .module-teal-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(129, 175, 186, 0.5);
}

.module-teal-30:disabled, .module-teal-30.disabled {
  color: #fff;
  background-color: rgba(3, 95, 117, 0.3);
  background-image: none;
  border-color: rgba(3, 95, 117, 0.3);
}

.module-teal-40 {
  color: #fff;
  background: rgba(3, 95, 117, 0.4) linear-gradient(180deg, rgba(100, 157, 172, 0.49), rgba(3, 95, 117, 0.4)) repeat-x;
  border-color: rgba(3, 95, 117, 0.4);
}

.module-teal-40 .icon-container {
  background-color: rgba(2, 65, 80, 0.4);
}

.module-teal-40 .icon-container.light {
  background-color: rgba(5, 156, 192, 0.4);
}

.module-teal-40:hover {
  color: #fff;
  background: rgba(2, 65, 80, 0.4) linear-gradient(180deg, rgba(100, 139, 150, 0.49), rgba(2, 65, 80, 0.4)) repeat-x;
  border-color: color-yig(rgba(2, 65, 80, 0.4));
}

.module-teal-40:focus, .module-teal-40.focus {
  color: #fff;
  background: rgba(2, 65, 80, 0.4) linear-gradient(180deg, rgba(100, 139, 150, 0.49), rgba(2, 65, 80, 0.4)) repeat-x;
  border-color: color-yig(rgba(2, 65, 80, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(107, 161, 174, 0.5);
}

.module-teal-40:active, .module-teal-40.active,
.show > .module-teal-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(2, 55, 67, 0.4);
  background-image: none;
  border-color: rgba(1, 45, 55, 0.4);
}

.module-teal-40:active:focus, .module-teal-40.active:focus,
.show > .module-teal-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(107, 161, 174, 0.5);
}

.module-teal-40:disabled, .module-teal-40.disabled {
  color: #fff;
  background-color: rgba(3, 95, 117, 0.4);
  background-image: none;
  border-color: rgba(3, 95, 117, 0.4);
}

.module-teal-50 {
  color: #fff;
  background: rgba(3, 95, 117, 0.5) linear-gradient(180deg, rgba(84, 147, 163, 0.575), rgba(3, 95, 117, 0.5)) repeat-x;
  border-color: rgba(3, 95, 117, 0.5);
}

.module-teal-50 .icon-container {
  background-color: rgba(2, 65, 80, 0.5);
}

.module-teal-50 .icon-container.light {
  background-color: rgba(5, 156, 192, 0.5);
}

.module-teal-50:hover {
  color: #fff;
  background: rgba(2, 65, 80, 0.5) linear-gradient(180deg, rgba(84, 127, 139, 0.575), rgba(2, 65, 80, 0.5)) repeat-x;
  border-color: color-yig(rgba(2, 65, 80, 0.5));
}

.module-teal-50:focus, .module-teal-50.focus {
  color: #fff;
  background: rgba(2, 65, 80, 0.5) linear-gradient(180deg, rgba(84, 127, 139, 0.575), rgba(2, 65, 80, 0.5)) repeat-x;
  border-color: color-yig(rgba(2, 65, 80, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(90, 150, 165, 0.5);
}

.module-teal-50:active, .module-teal-50.active,
.show > .module-teal-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(2, 55, 67, 0.5);
  background-image: none;
  border-color: rgba(1, 45, 55, 0.5);
}

.module-teal-50:active:focus, .module-teal-50.active:focus,
.show > .module-teal-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(90, 150, 165, 0.5);
}

.module-teal-50:disabled, .module-teal-50.disabled {
  color: #fff;
  background-color: rgba(3, 95, 117, 0.5);
  background-image: none;
  border-color: rgba(3, 95, 117, 0.5);
}

.module-teal-60 {
  color: #fff;
  background: rgba(3, 95, 117, 0.6) linear-gradient(180deg, rgba(72, 139, 156, 0.66), rgba(3, 95, 117, 0.6)) repeat-x;
  border-color: rgba(3, 95, 117, 0.6);
}

.module-teal-60 .icon-container {
  background-color: rgba(2, 65, 80, 0.6);
}

.module-teal-60 .icon-container.light {
  background-color: rgba(5, 156, 192, 0.6);
}

.module-teal-60:hover {
  color: #fff;
  background: rgba(2, 65, 80, 0.6) linear-gradient(180deg, rgba(71, 117, 129, 0.66), rgba(2, 65, 80, 0.6)) repeat-x;
  border-color: color-yig(rgba(2, 65, 80, 0.6));
}

.module-teal-60:focus, .module-teal-60.focus {
  color: #fff;
  background: rgba(2, 65, 80, 0.6) linear-gradient(180deg, rgba(71, 117, 129, 0.66), rgba(2, 65, 80, 0.6)) repeat-x;
  border-color: color-yig(rgba(2, 65, 80, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(77, 142, 157, 0.5);
}

.module-teal-60:active, .module-teal-60.active,
.show > .module-teal-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(2, 55, 67, 0.6);
  background-image: none;
  border-color: rgba(1, 45, 55, 0.6);
}

.module-teal-60:active:focus, .module-teal-60.active:focus,
.show > .module-teal-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 142, 157, 0.5);
}

.module-teal-60:disabled, .module-teal-60.disabled {
  color: #fff;
  background-color: rgba(3, 95, 117, 0.6);
  background-image: none;
  border-color: rgba(3, 95, 117, 0.6);
}

.module-teal-70 {
  color: #fff;
  background: rgba(3, 95, 117, 0.7) linear-gradient(180deg, rgba(61, 132, 150, 0.745), rgba(3, 95, 117, 0.7)) repeat-x;
  border-color: rgba(3, 95, 117, 0.7);
}

.module-teal-70 .icon-container {
  background-color: rgba(2, 65, 80, 0.7);
}

.module-teal-70 .icon-container.light {
  background-color: rgba(5, 156, 192, 0.7);
}

.module-teal-70:hover {
  color: #fff;
  background: rgba(2, 65, 80, 0.7) linear-gradient(180deg, rgba(60, 109, 122, 0.745), rgba(2, 65, 80, 0.7)) repeat-x;
  border-color: color-yig(rgba(2, 65, 80, 0.7));
}

.module-teal-70:focus, .module-teal-70.focus {
  color: #fff;
  background: rgba(2, 65, 80, 0.7) linear-gradient(180deg, rgba(60, 109, 122, 0.745), rgba(2, 65, 80, 0.7)) repeat-x;
  border-color: color-yig(rgba(2, 65, 80, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(65, 134, 151, 0.5);
}

.module-teal-70:active, .module-teal-70.active,
.show > .module-teal-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(2, 55, 67, 0.7);
  background-image: none;
  border-color: rgba(1, 45, 55, 0.7);
}

.module-teal-70:active:focus, .module-teal-70.active:focus,
.show > .module-teal-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 134, 151, 0.5);
}

.module-teal-70:disabled, .module-teal-70.disabled {
  color: #fff;
  background-color: rgba(3, 95, 117, 0.7);
  background-image: none;
  border-color: rgba(3, 95, 117, 0.7);
}

.module-teal-80 {
  color: #fff;
  background: rgba(3, 95, 117, 0.8) linear-gradient(180deg, rgba(52, 126, 145, 0.83), rgba(3, 95, 117, 0.8)) repeat-x;
  border-color: rgba(3, 95, 117, 0.8);
}

.module-teal-80 .icon-container {
  background-color: rgba(2, 65, 80, 0.8);
}

.module-teal-80 .icon-container.light {
  background-color: rgba(5, 156, 192, 0.8);
}

.module-teal-80:hover {
  color: #fff;
  background: rgba(2, 65, 80, 0.8) linear-gradient(180deg, rgba(51, 102, 115, 0.83), rgba(2, 65, 80, 0.8)) repeat-x;
  border-color: color-yig(rgba(2, 65, 80, 0.8));
}

.module-teal-80:focus, .module-teal-80.focus {
  color: #fff;
  background: rgba(2, 65, 80, 0.8) linear-gradient(180deg, rgba(51, 102, 115, 0.83), rgba(2, 65, 80, 0.8)) repeat-x;
  border-color: color-yig(rgba(2, 65, 80, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(56, 128, 146, 0.5);
}

.module-teal-80:active, .module-teal-80.active,
.show > .module-teal-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(2, 55, 67, 0.8);
  background-image: none;
  border-color: rgba(1, 45, 55, 0.8);
}

.module-teal-80:active:focus, .module-teal-80.active:focus,
.show > .module-teal-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 128, 146, 0.5);
}

.module-teal-80:disabled, .module-teal-80.disabled {
  color: #fff;
  background-color: rgba(3, 95, 117, 0.8);
  background-image: none;
  border-color: rgba(3, 95, 117, 0.8);
}

.module-teal-90 {
  color: #fff;
  background: rgba(3, 95, 117, 0.9) linear-gradient(180deg, rgba(45, 122, 141, 0.915), rgba(3, 95, 117, 0.9)) repeat-x;
  border-color: rgba(3, 95, 117, 0.9);
}

.module-teal-90 .icon-container {
  background-color: rgba(2, 65, 80, 0.9);
}

.module-teal-90 .icon-container.light {
  background-color: rgba(5, 156, 192, 0.9);
}

.module-teal-90:hover {
  color: #fff;
  background: rgba(2, 65, 80, 0.9) linear-gradient(180deg, rgba(44, 97, 110, 0.915), rgba(2, 65, 80, 0.9)) repeat-x;
  border-color: color-yig(rgba(2, 65, 80, 0.9));
}

.module-teal-90:focus, .module-teal-90.focus {
  color: #fff;
  background: rgba(2, 65, 80, 0.9) linear-gradient(180deg, rgba(44, 97, 110, 0.915), rgba(2, 65, 80, 0.9)) repeat-x;
  border-color: color-yig(rgba(2, 65, 80, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(48, 123, 141, 0.5);
}

.module-teal-90:active, .module-teal-90.active,
.show > .module-teal-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(2, 55, 67, 0.9);
  background-image: none;
  border-color: rgba(1, 45, 55, 0.9);
}

.module-teal-90:active:focus, .module-teal-90.active:focus,
.show > .module-teal-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(48, 123, 141, 0.5);
}

.module-teal-90:disabled, .module-teal-90.disabled {
  color: #fff;
  background-color: rgba(3, 95, 117, 0.9);
  background-image: none;
  border-color: rgba(3, 95, 117, 0.9);
}

.module-petrol {
  color: #fff;
  background: #0f99ba linear-gradient(180deg, #30a7c4, #0f99ba) repeat-x;
  border-color: #0f99ba;
}

.module-petrol .icon-container {
  background-color: #0c7c97;
}

.module-petrol .icon-container.light {
  background-color: #28c7ee;
}

.module-petrol:hover {
  color: #fff;
  background: #0c7c97 linear-gradient(180deg, #2e8ea6, #0c7c97) repeat-x;
  border-color: color-yig(#0c7c97);
}

.module-petrol:focus, .module-petrol.focus {
  color: #fff;
  background: #0c7c97 linear-gradient(180deg, #2e8ea6, #0c7c97) repeat-x;
  border-color: color-yig(#0c7c97);
  box-shadow: 0 0 0 0.2rem rgba(51, 168, 196, 0.5);
}

.module-petrol:active, .module-petrol.active,
.show > .module-petrol.dropdown-toggle {
  color: #fff;
  background-color: #0b728b;
  background-image: none;
  border-color: #0a687f;
}

.module-petrol:active:focus, .module-petrol.active:focus,
.show > .module-petrol.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 168, 196, 0.5);
}

.module-petrol:disabled, .module-petrol.disabled {
  color: #fff;
  background-color: #0f99ba;
  background-image: none;
  border-color: #0f99ba;
}

.module-petrol-10 {
  color: #fff;
  background: rgba(15, 153, 186, 0.1) linear-gradient(180deg, rgba(187, 224, 235, 0.235), rgba(15, 153, 186, 0.1)) repeat-x;
  border-color: rgba(15, 153, 186, 0.1);
}

.module-petrol-10 .icon-container {
  background-color: rgba(12, 124, 151, 0.1);
}

.module-petrol-10 .icon-container.light {
  background-color: rgba(40, 199, 238, 0.1);
}

.module-petrol-10:hover {
  color: #fff;
  background: rgba(12, 124, 151, 0.1) linear-gradient(180deg, rgba(186, 217, 227, 0.235), rgba(12, 124, 151, 0.1)) repeat-x;
  border-color: color-yig(rgba(12, 124, 151, 0.1));
}

.module-petrol-10:focus, .module-petrol-10.focus {
  color: #fff;
  background: rgba(12, 124, 151, 0.1) linear-gradient(180deg, rgba(186, 217, 227, 0.235), rgba(12, 124, 151, 0.1)) repeat-x;
  border-color: color-yig(rgba(12, 124, 151, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(200, 232, 239, 0.5);
}

.module-petrol-10:active, .module-petrol-10.active,
.show > .module-petrol-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(11, 114, 139, 0.1);
  background-image: none;
  border-color: rgba(10, 104, 127, 0.1);
}

.module-petrol-10:active:focus, .module-petrol-10.active:focus,
.show > .module-petrol-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(200, 232, 239, 0.5);
}

.module-petrol-10:disabled, .module-petrol-10.disabled {
  color: #fff;
  background-color: rgba(15, 153, 186, 0.1);
  background-image: none;
  border-color: rgba(15, 153, 186, 0.1);
}

.module-petrol-20 {
  color: #fff;
  background: rgba(15, 153, 186, 0.2) linear-gradient(180deg, rgba(152, 209, 225, 0.32), rgba(15, 153, 186, 0.2)) repeat-x;
  border-color: rgba(15, 153, 186, 0.2);
}

.module-petrol-20 .icon-container {
  background-color: rgba(12, 124, 151, 0.2);
}

.module-petrol-20 .icon-container.light {
  background-color: rgba(40, 199, 238, 0.2);
}

.module-petrol-20:hover {
  color: #fff;
  background: rgba(12, 124, 151, 0.2) linear-gradient(180deg, rgba(151, 198, 212, 0.32), rgba(12, 124, 151, 0.2)) repeat-x;
  border-color: color-yig(rgba(12, 124, 151, 0.2));
}

.module-petrol-20:focus, .module-petrol-20.focus {
  color: #fff;
  background: rgba(12, 124, 151, 0.2) linear-gradient(180deg, rgba(151, 198, 212, 0.32), rgba(12, 124, 151, 0.2)) repeat-x;
  border-color: color-yig(rgba(12, 124, 151, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(162, 216, 228, 0.5);
}

.module-petrol-20:active, .module-petrol-20.active,
.show > .module-petrol-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(11, 114, 139, 0.2);
  background-image: none;
  border-color: rgba(10, 104, 127, 0.2);
}

.module-petrol-20:active:focus, .module-petrol-20.active:focus,
.show > .module-petrol-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(162, 216, 228, 0.5);
}

.module-petrol-20:disabled, .module-petrol-20.disabled {
  color: #fff;
  background-color: rgba(15, 153, 186, 0.2);
  background-image: none;
  border-color: rgba(15, 153, 186, 0.2);
}

.module-petrol-30 {
  color: #fff;
  background: rgba(15, 153, 186, 0.3) linear-gradient(180deg, rgba(127, 199, 218, 0.405), rgba(15, 153, 186, 0.3)) repeat-x;
  border-color: rgba(15, 153, 186, 0.3);
}

.module-petrol-30 .icon-container {
  background-color: rgba(12, 124, 151, 0.3);
}

.module-petrol-30 .icon-container.light {
  background-color: rgba(40, 199, 238, 0.3);
}

.module-petrol-30:hover {
  color: #fff;
  background: rgba(12, 124, 151, 0.3) linear-gradient(180deg, rgba(125, 184, 200, 0.405), rgba(12, 124, 151, 0.3)) repeat-x;
  border-color: color-yig(rgba(12, 124, 151, 0.3));
}

.module-petrol-30:focus, .module-petrol-30.focus {
  color: #fff;
  background: rgba(12, 124, 151, 0.3) linear-gradient(180deg, rgba(125, 184, 200, 0.405), rgba(12, 124, 151, 0.3)) repeat-x;
  border-color: color-yig(rgba(12, 124, 151, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(135, 204, 221, 0.5);
}

.module-petrol-30:active, .module-petrol-30.active,
.show > .module-petrol-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(11, 114, 139, 0.3);
  background-image: none;
  border-color: rgba(10, 104, 127, 0.3);
}

.module-petrol-30:active:focus, .module-petrol-30.active:focus,
.show > .module-petrol-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(135, 204, 221, 0.5);
}

.module-petrol-30:disabled, .module-petrol-30.disabled {
  color: #fff;
  background-color: rgba(15, 153, 186, 0.3);
  background-image: none;
  border-color: rgba(15, 153, 186, 0.3);
}

.module-petrol-40 {
  color: #fff;
  background: rgba(15, 153, 186, 0.4) linear-gradient(180deg, rgba(107, 191, 212, 0.49), rgba(15, 153, 186, 0.4)) repeat-x;
  border-color: rgba(15, 153, 186, 0.4);
}

.module-petrol-40 .icon-container {
  background-color: rgba(12, 124, 151, 0.4);
}

.module-petrol-40 .icon-container.light {
  background-color: rgba(40, 199, 238, 0.4);
}

.module-petrol-40:hover {
  color: #fff;
  background: rgba(12, 124, 151, 0.4) linear-gradient(180deg, rgba(106, 174, 192, 0.49), rgba(12, 124, 151, 0.4)) repeat-x;
  border-color: color-yig(rgba(12, 124, 151, 0.4));
}

.module-petrol-40:focus, .module-petrol-40.focus {
  color: #fff;
  background: rgba(12, 124, 151, 0.4) linear-gradient(180deg, rgba(106, 174, 192, 0.49), rgba(12, 124, 151, 0.4)) repeat-x;
  border-color: color-yig(rgba(12, 124, 151, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(114, 195, 215, 0.5);
}

.module-petrol-40:active, .module-petrol-40.active,
.show > .module-petrol-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(11, 114, 139, 0.4);
  background-image: none;
  border-color: rgba(10, 104, 127, 0.4);
}

.module-petrol-40:active:focus, .module-petrol-40.active:focus,
.show > .module-petrol-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(114, 195, 215, 0.5);
}

.module-petrol-40:disabled, .module-petrol-40.disabled {
  color: #fff;
  background-color: rgba(15, 153, 186, 0.4);
  background-image: none;
  border-color: rgba(15, 153, 186, 0.4);
}

.module-petrol-50 {
  color: #fff;
  background: rgba(15, 153, 186, 0.5) linear-gradient(180deg, rgba(92, 185, 208, 0.575), rgba(15, 153, 186, 0.5)) repeat-x;
  border-color: rgba(15, 153, 186, 0.5);
}

.module-petrol-50 .icon-container {
  background-color: rgba(12, 124, 151, 0.5);
}

.module-petrol-50 .icon-container.light {
  background-color: rgba(40, 199, 238, 0.5);
}

.module-petrol-50:hover {
  color: #fff;
  background: rgba(12, 124, 151, 0.5) linear-gradient(180deg, rgba(90, 166, 185, 0.575), rgba(12, 124, 151, 0.5)) repeat-x;
  border-color: color-yig(rgba(12, 124, 151, 0.5));
}

.module-petrol-50:focus, .module-petrol-50.focus {
  color: #fff;
  background: rgba(12, 124, 151, 0.5) linear-gradient(180deg, rgba(90, 166, 185, 0.575), rgba(12, 124, 151, 0.5)) repeat-x;
  border-color: color-yig(rgba(12, 124, 151, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(98, 188, 210, 0.5);
}

.module-petrol-50:active, .module-petrol-50.active,
.show > .module-petrol-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(11, 114, 139, 0.5);
  background-image: none;
  border-color: rgba(10, 104, 127, 0.5);
}

.module-petrol-50:active:focus, .module-petrol-50.active:focus,
.show > .module-petrol-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(98, 188, 210, 0.5);
}

.module-petrol-50:disabled, .module-petrol-50.disabled {
  color: #fff;
  background-color: rgba(15, 153, 186, 0.5);
  background-image: none;
  border-color: rgba(15, 153, 186, 0.5);
}

.module-petrol-60 {
  color: #fff;
  background: rgba(15, 153, 186, 0.6) linear-gradient(180deg, rgba(80, 180, 205, 0.66), rgba(15, 153, 186, 0.6)) repeat-x;
  border-color: rgba(15, 153, 186, 0.6);
}

.module-petrol-60 .icon-container {
  background-color: rgba(12, 124, 151, 0.6);
}

.module-petrol-60 .icon-container.light {
  background-color: rgba(40, 199, 238, 0.6);
}

.module-petrol-60:hover {
  color: #fff;
  background: rgba(12, 124, 151, 0.6) linear-gradient(180deg, rgba(78, 159, 180, 0.66), rgba(12, 124, 151, 0.6)) repeat-x;
  border-color: color-yig(rgba(12, 124, 151, 0.6));
}

.module-petrol-60:focus, .module-petrol-60.focus {
  color: #fff;
  background: rgba(12, 124, 151, 0.6) linear-gradient(180deg, rgba(78, 159, 180, 0.66), rgba(12, 124, 151, 0.6)) repeat-x;
  border-color: color-yig(rgba(12, 124, 151, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(85, 183, 206, 0.5);
}

.module-petrol-60:active, .module-petrol-60.active,
.show > .module-petrol-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(11, 114, 139, 0.6);
  background-image: none;
  border-color: rgba(10, 104, 127, 0.6);
}

.module-petrol-60:active:focus, .module-petrol-60.active:focus,
.show > .module-petrol-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(85, 183, 206, 0.5);
}

.module-petrol-60:disabled, .module-petrol-60.disabled {
  color: #fff;
  background-color: rgba(15, 153, 186, 0.6);
  background-image: none;
  border-color: rgba(15, 153, 186, 0.6);
}

.module-petrol-70 {
  color: #fff;
  background: rgba(15, 153, 186, 0.7) linear-gradient(180deg, rgba(70, 176, 202, 0.745), rgba(15, 153, 186, 0.7)) repeat-x;
  border-color: rgba(15, 153, 186, 0.7);
}

.module-petrol-70 .icon-container {
  background-color: rgba(12, 124, 151, 0.7);
}

.module-petrol-70 .icon-container.light {
  background-color: rgba(40, 199, 238, 0.7);
}

.module-petrol-70:hover {
  color: #fff;
  background: rgba(12, 124, 151, 0.7) linear-gradient(180deg, rgba(68, 154, 175, 0.745), rgba(12, 124, 151, 0.7)) repeat-x;
  border-color: color-yig(rgba(12, 124, 151, 0.7));
}

.module-petrol-70:focus, .module-petrol-70.focus {
  color: #fff;
  background: rgba(12, 124, 151, 0.7) linear-gradient(180deg, rgba(68, 154, 175, 0.745), rgba(12, 124, 151, 0.7)) repeat-x;
  border-color: color-yig(rgba(12, 124, 151, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(74, 178, 203, 0.5);
}

.module-petrol-70:active, .module-petrol-70.active,
.show > .module-petrol-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(11, 114, 139, 0.7);
  background-image: none;
  border-color: rgba(10, 104, 127, 0.7);
}

.module-petrol-70:active:focus, .module-petrol-70.active:focus,
.show > .module-petrol-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(74, 178, 203, 0.5);
}

.module-petrol-70:disabled, .module-petrol-70.disabled {
  color: #fff;
  background-color: rgba(15, 153, 186, 0.7);
  background-image: none;
  border-color: rgba(15, 153, 186, 0.7);
}

.module-petrol-80 {
  color: #fff;
  background: rgba(15, 153, 186, 0.8) linear-gradient(180deg, rgba(62, 172, 199, 0.83), rgba(15, 153, 186, 0.8)) repeat-x;
  border-color: rgba(15, 153, 186, 0.8);
}

.module-petrol-80 .icon-container {
  background-color: rgba(12, 124, 151, 0.8);
}

.module-petrol-80 .icon-container.light {
  background-color: rgba(40, 199, 238, 0.8);
}

.module-petrol-80:hover {
  color: #fff;
  background: rgba(12, 124, 151, 0.8) linear-gradient(180deg, rgba(59, 149, 171, 0.83), rgba(12, 124, 151, 0.8)) repeat-x;
  border-color: color-yig(rgba(12, 124, 151, 0.8));
}

.module-petrol-80:focus, .module-petrol-80.focus {
  color: #fff;
  background: rgba(12, 124, 151, 0.8) linear-gradient(180deg, rgba(59, 149, 171, 0.83), rgba(12, 124, 151, 0.8)) repeat-x;
  border-color: color-yig(rgba(12, 124, 151, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(65, 174, 200, 0.5);
}

.module-petrol-80:active, .module-petrol-80.active,
.show > .module-petrol-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(11, 114, 139, 0.8);
  background-image: none;
  border-color: rgba(10, 104, 127, 0.8);
}

.module-petrol-80:active:focus, .module-petrol-80.active:focus,
.show > .module-petrol-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 174, 200, 0.5);
}

.module-petrol-80:disabled, .module-petrol-80.disabled {
  color: #fff;
  background-color: rgba(15, 153, 186, 0.8);
  background-image: none;
  border-color: rgba(15, 153, 186, 0.8);
}

.module-petrol-90 {
  color: #fff;
  background: rgba(15, 153, 186, 0.9) linear-gradient(180deg, rgba(55, 169, 197, 0.915), rgba(15, 153, 186, 0.9)) repeat-x;
  border-color: rgba(15, 153, 186, 0.9);
}

.module-petrol-90 .icon-container {
  background-color: rgba(12, 124, 151, 0.9);
}

.module-petrol-90 .icon-container.light {
  background-color: rgba(40, 199, 238, 0.9);
}

.module-petrol-90:hover {
  color: #fff;
  background: rgba(12, 124, 151, 0.9) linear-gradient(180deg, rgba(52, 145, 168, 0.915), rgba(12, 124, 151, 0.9)) repeat-x;
  border-color: color-yig(rgba(12, 124, 151, 0.9));
}

.module-petrol-90:focus, .module-petrol-90.focus {
  color: #fff;
  background: rgba(12, 124, 151, 0.9) linear-gradient(180deg, rgba(52, 145, 168, 0.915), rgba(12, 124, 151, 0.9)) repeat-x;
  border-color: color-yig(rgba(12, 124, 151, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(58, 171, 198, 0.5);
}

.module-petrol-90:active, .module-petrol-90.active,
.show > .module-petrol-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(11, 114, 139, 0.9);
  background-image: none;
  border-color: rgba(10, 104, 127, 0.9);
}

.module-petrol-90:active:focus, .module-petrol-90.active:focus,
.show > .module-petrol-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 171, 198, 0.5);
}

.module-petrol-90:disabled, .module-petrol-90.disabled {
  color: #fff;
  background-color: rgba(15, 153, 186, 0.9);
  background-image: none;
  border-color: rgba(15, 153, 186, 0.9);
}

.module-turquoise {
  color: #fff;
  background: #14b1d7 linear-gradient(180deg, #35bbdc, #14b1d7) repeat-x;
  border-color: #14b1d7;
}

.module-turquoise .icon-container {
  background-color: #1194b4;
}

.module-turquoise .icon-container.light {
  background-color: #49ceee;
}

.module-turquoise:hover {
  color: #fff;
  background: #1194b4 linear-gradient(180deg, #32a3bf, #1194b4) repeat-x;
  border-color: color-yig(#1194b4);
}

.module-turquoise:focus, .module-turquoise.focus {
  color: #fff;
  background: #1194b4 linear-gradient(180deg, #32a3bf, #1194b4) repeat-x;
  border-color: color-yig(#1194b4);
  box-shadow: 0 0 0 0.2rem rgba(55, 189, 221, 0.5);
}

.module-turquoise:active, .module-turquoise.active,
.show > .module-turquoise.dropdown-toggle {
  color: #fff;
  background-color: #108ba8;
  background-image: none;
  border-color: #0f819d;
}

.module-turquoise:active:focus, .module-turquoise.active:focus,
.show > .module-turquoise.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 189, 221, 0.5);
}

.module-turquoise:disabled, .module-turquoise.disabled {
  color: #fff;
  background-color: #14b1d7;
  background-image: none;
  border-color: #14b1d7;
}

.module-turquoise-10 {
  color: #fff;
  background: rgba(20, 177, 215, 0.1) linear-gradient(180deg, rgba(188, 229, 242, 0.235), rgba(20, 177, 215, 0.1)) repeat-x;
  border-color: rgba(20, 177, 215, 0.1);
}

.module-turquoise-10 .icon-container {
  background-color: rgba(17, 148, 180, 0.1);
}

.module-turquoise-10 .icon-container.light {
  background-color: rgba(73, 206, 238, 0.1);
}

.module-turquoise-10:hover {
  color: #fff;
  background: rgba(17, 148, 180, 0.1) linear-gradient(180deg, rgba(187, 223, 234, 0.235), rgba(17, 148, 180, 0.1)) repeat-x;
  border-color: color-yig(rgba(17, 148, 180, 0.1));
}

.module-turquoise-10:focus, .module-turquoise-10.focus {
  color: #fff;
  background: rgba(17, 148, 180, 0.1) linear-gradient(180deg, rgba(187, 223, 234, 0.235), rgba(17, 148, 180, 0.1)) repeat-x;
  border-color: color-yig(rgba(17, 148, 180, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(201, 237, 246, 0.5);
}

.module-turquoise-10:active, .module-turquoise-10.active,
.show > .module-turquoise-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(16, 139, 168, 0.1);
  background-image: none;
  border-color: rgba(15, 129, 157, 0.1);
}

.module-turquoise-10:active:focus, .module-turquoise-10.active:focus,
.show > .module-turquoise-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(201, 237, 246, 0.5);
}

.module-turquoise-10:disabled, .module-turquoise-10.disabled {
  color: #fff;
  background-color: rgba(20, 177, 215, 0.1);
  background-image: none;
  border-color: rgba(20, 177, 215, 0.1);
}

.module-turquoise-20 {
  color: #fff;
  background: rgba(20, 177, 215, 0.2) linear-gradient(180deg, rgba(154, 219, 236, 0.32), rgba(20, 177, 215, 0.2)) repeat-x;
  border-color: rgba(20, 177, 215, 0.2);
}

.module-turquoise-20 .icon-container {
  background-color: rgba(17, 148, 180, 0.2);
}

.module-turquoise-20 .icon-container.light {
  background-color: rgba(73, 206, 238, 0.2);
}

.module-turquoise-20:hover {
  color: #fff;
  background: rgba(17, 148, 180, 0.2) linear-gradient(180deg, rgba(153, 208, 223, 0.32), rgba(17, 148, 180, 0.2)) repeat-x;
  border-color: color-yig(rgba(17, 148, 180, 0.2));
}

.module-turquoise-20:focus, .module-turquoise-20.focus {
  color: #fff;
  background: rgba(17, 148, 180, 0.2) linear-gradient(180deg, rgba(153, 208, 223, 0.32), rgba(17, 148, 180, 0.2)) repeat-x;
  border-color: color-yig(rgba(17, 148, 180, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(164, 225, 240, 0.5);
}

.module-turquoise-20:active, .module-turquoise-20.active,
.show > .module-turquoise-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(16, 139, 168, 0.2);
  background-image: none;
  border-color: rgba(15, 129, 157, 0.2);
}

.module-turquoise-20:active:focus, .module-turquoise-20.active:focus,
.show > .module-turquoise-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(164, 225, 240, 0.5);
}

.module-turquoise-20:disabled, .module-turquoise-20.disabled {
  color: #fff;
  background-color: rgba(20, 177, 215, 0.2);
  background-image: none;
  border-color: rgba(20, 177, 215, 0.2);
}

.module-turquoise-30 {
  color: #fff;
  background: rgba(20, 177, 215, 0.3) linear-gradient(180deg, rgba(129, 211, 233, 0.405), rgba(20, 177, 215, 0.3)) repeat-x;
  border-color: rgba(20, 177, 215, 0.3);
}

.module-turquoise-30 .icon-container {
  background-color: rgba(17, 148, 180, 0.3);
}

.module-turquoise-30 .icon-container.light {
  background-color: rgba(73, 206, 238, 0.3);
}

.module-turquoise-30:hover {
  color: #fff;
  background: rgba(17, 148, 180, 0.3) linear-gradient(180deg, rgba(127, 197, 215, 0.405), rgba(17, 148, 180, 0.3)) repeat-x;
  border-color: color-yig(rgba(17, 148, 180, 0.3));
}

.module-turquoise-30:focus, .module-turquoise-30.focus {
  color: #fff;
  background: rgba(17, 148, 180, 0.3) linear-gradient(180deg, rgba(127, 197, 215, 0.405), rgba(17, 148, 180, 0.3)) repeat-x;
  border-color: color-yig(rgba(17, 148, 180, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(138, 216, 235, 0.5);
}

.module-turquoise-30:active, .module-turquoise-30.active,
.show > .module-turquoise-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(16, 139, 168, 0.3);
  background-image: none;
  border-color: rgba(15, 129, 157, 0.3);
}

.module-turquoise-30:active:focus, .module-turquoise-30.active:focus,
.show > .module-turquoise-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(138, 216, 235, 0.5);
}

.module-turquoise-30:disabled, .module-turquoise-30.disabled {
  color: #fff;
  background-color: rgba(20, 177, 215, 0.3);
  background-image: none;
  border-color: rgba(20, 177, 215, 0.3);
}

.module-turquoise-40 {
  color: #fff;
  background: rgba(20, 177, 215, 0.4) linear-gradient(180deg, rgba(110, 205, 229, 0.49), rgba(20, 177, 215, 0.4)) repeat-x;
  border-color: rgba(20, 177, 215, 0.4);
}

.module-turquoise-40 .icon-container {
  background-color: rgba(17, 148, 180, 0.4);
}

.module-turquoise-40 .icon-container.light {
  background-color: rgba(73, 206, 238, 0.4);
}

.module-turquoise-40:hover {
  color: #fff;
  background: rgba(17, 148, 180, 0.4) linear-gradient(180deg, rgba(108, 188, 209, 0.49), rgba(17, 148, 180, 0.4)) repeat-x;
  border-color: color-yig(rgba(17, 148, 180, 0.4));
}

.module-turquoise-40:focus, .module-turquoise-40.focus {
  color: #fff;
  background: rgba(17, 148, 180, 0.4) linear-gradient(180deg, rgba(108, 188, 209, 0.49), rgba(17, 148, 180, 0.4)) repeat-x;
  border-color: color-yig(rgba(17, 148, 180, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(117, 209, 232, 0.5);
}

.module-turquoise-40:active, .module-turquoise-40.active,
.show > .module-turquoise-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(16, 139, 168, 0.4);
  background-image: none;
  border-color: rgba(15, 129, 157, 0.4);
}

.module-turquoise-40:active:focus, .module-turquoise-40.active:focus,
.show > .module-turquoise-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(117, 209, 232, 0.5);
}

.module-turquoise-40:disabled, .module-turquoise-40.disabled {
  color: #fff;
  background-color: rgba(20, 177, 215, 0.4);
  background-image: none;
  border-color: rgba(20, 177, 215, 0.4);
}

.module-turquoise-50 {
  color: #fff;
  background: rgba(20, 177, 215, 0.5) linear-gradient(180deg, rgba(95, 201, 227, 0.575), rgba(20, 177, 215, 0.5)) repeat-x;
  border-color: rgba(20, 177, 215, 0.5);
}

.module-turquoise-50 .icon-container {
  background-color: rgba(17, 148, 180, 0.5);
}

.module-turquoise-50 .icon-container.light {
  background-color: rgba(73, 206, 238, 0.5);
}

.module-turquoise-50:hover {
  color: #fff;
  background: rgba(17, 148, 180, 0.5) linear-gradient(180deg, rgba(93, 182, 204, 0.575), rgba(17, 148, 180, 0.5)) repeat-x;
  border-color: color-yig(rgba(17, 148, 180, 0.5));
}

.module-turquoise-50:focus, .module-turquoise-50.focus {
  color: #fff;
  background: rgba(17, 148, 180, 0.5) linear-gradient(180deg, rgba(93, 182, 204, 0.575), rgba(17, 148, 180, 0.5)) repeat-x;
  border-color: color-yig(rgba(17, 148, 180, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(101, 204, 229, 0.5);
}

.module-turquoise-50:active, .module-turquoise-50.active,
.show > .module-turquoise-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(16, 139, 168, 0.5);
  background-image: none;
  border-color: rgba(15, 129, 157, 0.5);
}

.module-turquoise-50:active:focus, .module-turquoise-50.active:focus,
.show > .module-turquoise-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(101, 204, 229, 0.5);
}

.module-turquoise-50:disabled, .module-turquoise-50.disabled {
  color: #fff;
  background-color: rgba(20, 177, 215, 0.5);
  background-image: none;
  border-color: rgba(20, 177, 215, 0.5);
}

.module-turquoise-60 {
  color: #fff;
  background: rgba(20, 177, 215, 0.6) linear-gradient(180deg, rgba(84, 197, 225, 0.66), rgba(20, 177, 215, 0.6)) repeat-x;
  border-color: rgba(20, 177, 215, 0.6);
}

.module-turquoise-60 .icon-container {
  background-color: rgba(17, 148, 180, 0.6);
}

.module-turquoise-60 .icon-container.light {
  background-color: rgba(73, 206, 238, 0.6);
}

.module-turquoise-60:hover {
  color: #fff;
  background: rgba(17, 148, 180, 0.6) linear-gradient(180deg, rgba(81, 176, 200, 0.66), rgba(17, 148, 180, 0.6)) repeat-x;
  border-color: color-yig(rgba(17, 148, 180, 0.6));
}

.module-turquoise-60:focus, .module-turquoise-60.focus {
  color: #fff;
  background: rgba(17, 148, 180, 0.6) linear-gradient(180deg, rgba(81, 176, 200, 0.66), rgba(17, 148, 180, 0.6)) repeat-x;
  border-color: color-yig(rgba(17, 148, 180, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(89, 200, 227, 0.5);
}

.module-turquoise-60:active, .module-turquoise-60.active,
.show > .module-turquoise-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(16, 139, 168, 0.6);
  background-image: none;
  border-color: rgba(15, 129, 157, 0.6);
}

.module-turquoise-60:active:focus, .module-turquoise-60.active:focus,
.show > .module-turquoise-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 200, 227, 0.5);
}

.module-turquoise-60:disabled, .module-turquoise-60.disabled {
  color: #fff;
  background-color: rgba(20, 177, 215, 0.6);
  background-image: none;
  border-color: rgba(20, 177, 215, 0.6);
}

.module-turquoise-70 {
  color: #fff;
  background: rgba(20, 177, 215, 0.7) linear-gradient(180deg, rgba(74, 194, 224, 0.745), rgba(20, 177, 215, 0.7)) repeat-x;
  border-color: rgba(20, 177, 215, 0.7);
}

.module-turquoise-70 .icon-container {
  background-color: rgba(17, 148, 180, 0.7);
}

.module-turquoise-70 .icon-container.light {
  background-color: rgba(73, 206, 238, 0.7);
}

.module-turquoise-70:hover {
  color: #fff;
  background: rgba(17, 148, 180, 0.7) linear-gradient(180deg, rgba(71, 172, 197, 0.745), rgba(17, 148, 180, 0.7)) repeat-x;
  border-color: color-yig(rgba(17, 148, 180, 0.7));
}

.module-turquoise-70:focus, .module-turquoise-70.focus {
  color: #fff;
  background: rgba(17, 148, 180, 0.7) linear-gradient(180deg, rgba(71, 172, 197, 0.745), rgba(17, 148, 180, 0.7)) repeat-x;
  border-color: color-yig(rgba(17, 148, 180, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(78, 196, 225, 0.5);
}

.module-turquoise-70:active, .module-turquoise-70.active,
.show > .module-turquoise-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(16, 139, 168, 0.7);
  background-image: none;
  border-color: rgba(15, 129, 157, 0.7);
}

.module-turquoise-70:active:focus, .module-turquoise-70.active:focus,
.show > .module-turquoise-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 196, 225, 0.5);
}

.module-turquoise-70:disabled, .module-turquoise-70.disabled {
  color: #fff;
  background-color: rgba(20, 177, 215, 0.7);
  background-image: none;
  border-color: rgba(20, 177, 215, 0.7);
}

.module-turquoise-80 {
  color: #fff;
  background: rgba(20, 177, 215, 0.8) linear-gradient(180deg, rgba(66, 191, 222, 0.83), rgba(20, 177, 215, 0.8)) repeat-x;
  border-color: rgba(20, 177, 215, 0.8);
}

.module-turquoise-80 .icon-container {
  background-color: rgba(17, 148, 180, 0.8);
}

.module-turquoise-80 .icon-container.light {
  background-color: rgba(73, 206, 238, 0.8);
}

.module-turquoise-80:hover {
  color: #fff;
  background: rgba(17, 148, 180, 0.8) linear-gradient(180deg, rgba(63, 168, 195, 0.83), rgba(17, 148, 180, 0.8)) repeat-x;
  border-color: color-yig(rgba(17, 148, 180, 0.8));
}

.module-turquoise-80:focus, .module-turquoise-80.focus {
  color: #fff;
  background: rgba(17, 148, 180, 0.8) linear-gradient(180deg, rgba(63, 168, 195, 0.83), rgba(17, 148, 180, 0.8)) repeat-x;
  border-color: color-yig(rgba(17, 148, 180, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(69, 193, 223, 0.5);
}

.module-turquoise-80:active, .module-turquoise-80.active,
.show > .module-turquoise-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(16, 139, 168, 0.8);
  background-image: none;
  border-color: rgba(15, 129, 157, 0.8);
}

.module-turquoise-80:active:focus, .module-turquoise-80.active:focus,
.show > .module-turquoise-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 193, 223, 0.5);
}

.module-turquoise-80:disabled, .module-turquoise-80.disabled {
  color: #fff;
  background-color: rgba(20, 177, 215, 0.8);
  background-image: none;
  border-color: rgba(20, 177, 215, 0.8);
}

.module-turquoise-90 {
  color: #fff;
  background: rgba(20, 177, 215, 0.9) linear-gradient(180deg, rgba(59, 189, 221, 0.915), rgba(20, 177, 215, 0.9)) repeat-x;
  border-color: rgba(20, 177, 215, 0.9);
}

.module-turquoise-90 .icon-container {
  background-color: rgba(17, 148, 180, 0.9);
}

.module-turquoise-90 .icon-container.light {
  background-color: rgba(73, 206, 238, 0.9);
}

.module-turquoise-90:hover {
  color: #fff;
  background: rgba(17, 148, 180, 0.9) linear-gradient(180deg, rgba(56, 165, 192, 0.915), rgba(17, 148, 180, 0.9)) repeat-x;
  border-color: color-yig(rgba(17, 148, 180, 0.9));
}

.module-turquoise-90:focus, .module-turquoise-90.focus {
  color: #fff;
  background: rgba(17, 148, 180, 0.9) linear-gradient(180deg, rgba(56, 165, 192, 0.915), rgba(17, 148, 180, 0.9)) repeat-x;
  border-color: color-yig(rgba(17, 148, 180, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(62, 191, 222, 0.5);
}

.module-turquoise-90:active, .module-turquoise-90.active,
.show > .module-turquoise-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(16, 139, 168, 0.9);
  background-image: none;
  border-color: rgba(15, 129, 157, 0.9);
}

.module-turquoise-90:active:focus, .module-turquoise-90.active:focus,
.show > .module-turquoise-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(62, 191, 222, 0.5);
}

.module-turquoise-90:disabled, .module-turquoise-90.disabled {
  color: #fff;
  background-color: rgba(20, 177, 215, 0.9);
  background-image: none;
  border-color: rgba(20, 177, 215, 0.9);
}

.module-sky {
  color: #273a4d;
  background: #8cd8eb linear-gradient(180deg, #9bdced, #8cd8eb) repeat-x;
  border-color: #8cd8eb;
}

.module-sky .icon-container {
  background-color: #6bcde5;
}

.module-sky .icon-container.light {
  background-color: #cdeef6;
}

.module-sky:hover {
  color: #273a4d;
  background: #6bcde5 linear-gradient(180deg, #7fd3e8, #6bcde5) repeat-x;
  border-color: color-yig(#6bcde5);
}

.module-sky:focus, .module-sky.focus {
  color: #273a4d;
  background: #6bcde5 linear-gradient(180deg, #7fd3e8, #6bcde5) repeat-x;
  border-color: color-yig(#6bcde5);
  box-shadow: 0 0 0 0.2rem rgba(125, 192, 211, 0.5);
}

.module-sky:active, .module-sky.active,
.show > .module-sky.dropdown-toggle {
  color: #273a4d;
  background-color: #61c9e3;
  background-image: none;
  border-color: #56c6e2;
}

.module-sky:active:focus, .module-sky.active:focus,
.show > .module-sky.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 192, 211, 0.5);
}

.module-sky:disabled, .module-sky.disabled {
  color: #273a4d;
  background-color: #8cd8eb;
  background-image: none;
  border-color: #8cd8eb;
}

.module-sky-10 {
  color: #273a4d;
  background: rgba(140, 216, 235, 0.1) linear-gradient(180deg, rgba(215, 238, 247, 0.235), rgba(140, 216, 235, 0.1)) repeat-x;
  border-color: rgba(140, 216, 235, 0.1);
}

.module-sky-10 .icon-container {
  background-color: rgba(107, 205, 229, 0.1);
}

.module-sky-10 .icon-container.light {
  background-color: rgba(205, 238, 246, 0.1);
}

.module-sky-10:hover {
  color: #273a4d;
  background: rgba(107, 205, 229, 0.1) linear-gradient(180deg, rgba(208, 236, 245, 0.235), rgba(107, 205, 229, 0.1)) repeat-x;
  border-color: color-yig(rgba(107, 205, 229, 0.1));
}

.module-sky-10:focus, .module-sky-10.focus {
  color: #273a4d;
  background: rgba(107, 205, 229, 0.1) linear-gradient(180deg, rgba(208, 236, 245, 0.235), rgba(107, 205, 229, 0.1)) repeat-x;
  border-color: color-yig(rgba(107, 205, 229, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(62, 94, 113, 0.5);
}

.module-sky-10:active, .module-sky-10.active,
.show > .module-sky-10.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(97, 201, 227, 0.1);
  background-image: none;
  border-color: rgba(86, 198, 226, 0.1);
}

.module-sky-10:active:focus, .module-sky-10.active:focus,
.show > .module-sky-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(62, 94, 113, 0.5);
}

.module-sky-10:disabled, .module-sky-10.disabled {
  color: #273a4d;
  background-color: rgba(140, 216, 235, 0.1);
  background-image: none;
  border-color: rgba(140, 216, 235, 0.1);
}

.module-sky-20 {
  color: #273a4d;
  background: rgba(140, 216, 235, 0.2) linear-gradient(180deg, rgba(200, 234, 244, 0.32), rgba(140, 216, 235, 0.2)) repeat-x;
  border-color: rgba(140, 216, 235, 0.2);
}

.module-sky-20 .icon-container {
  background-color: rgba(107, 205, 229, 0.2);
}

.module-sky-20 .icon-container.light {
  background-color: rgba(205, 238, 246, 0.2);
}

.module-sky-20:hover {
  color: #273a4d;
  background: rgba(107, 205, 229, 0.2) linear-gradient(180deg, rgba(188, 230, 242, 0.32), rgba(107, 205, 229, 0.2)) repeat-x;
  border-color: color-yig(rgba(107, 205, 229, 0.2));
}

.module-sky-20:focus, .module-sky-20.focus {
  color: #273a4d;
  background: rgba(107, 205, 229, 0.2) linear-gradient(180deg, rgba(188, 230, 242, 0.32), rgba(107, 205, 229, 0.2)) repeat-x;
  border-color: color-yig(rgba(107, 205, 229, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(78, 119, 138, 0.5);
}

.module-sky-20:active, .module-sky-20.active,
.show > .module-sky-20.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(97, 201, 227, 0.2);
  background-image: none;
  border-color: rgba(86, 198, 226, 0.2);
}

.module-sky-20:active:focus, .module-sky-20.active:focus,
.show > .module-sky-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 119, 138, 0.5);
}

.module-sky-20:disabled, .module-sky-20.disabled {
  color: #273a4d;
  background-color: rgba(140, 216, 235, 0.2);
  background-image: none;
  border-color: rgba(140, 216, 235, 0.2);
}

.module-sky-30 {
  color: #273a4d;
  background: rgba(140, 216, 235, 0.3) linear-gradient(180deg, rgba(189, 231, 243, 0.405), rgba(140, 216, 235, 0.3)) repeat-x;
  border-color: rgba(140, 216, 235, 0.3);
}

.module-sky-30 .icon-container {
  background-color: rgba(107, 205, 229, 0.3);
}

.module-sky-30 .icon-container.light {
  background-color: rgba(205, 238, 246, 0.3);
}

.module-sky-30:hover {
  color: #273a4d;
  background: rgba(107, 205, 229, 0.3) linear-gradient(180deg, rgba(173, 225, 240, 0.405), rgba(107, 205, 229, 0.3)) repeat-x;
  border-color: color-yig(rgba(107, 205, 229, 0.3));
}

.module-sky-30:focus, .module-sky-30.focus {
  color: #273a4d;
  background: rgba(107, 205, 229, 0.3) linear-gradient(180deg, rgba(173, 225, 240, 0.405), rgba(107, 205, 229, 0.3)) repeat-x;
  border-color: color-yig(rgba(107, 205, 229, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(90, 137, 156, 0.5);
}

.module-sky-30:active, .module-sky-30.active,
.show > .module-sky-30.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(97, 201, 227, 0.3);
  background-image: none;
  border-color: rgba(86, 198, 226, 0.3);
}

.module-sky-30:active:focus, .module-sky-30.active:focus,
.show > .module-sky-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(90, 137, 156, 0.5);
}

.module-sky-30:disabled, .module-sky-30.disabled {
  color: #273a4d;
  background-color: rgba(140, 216, 235, 0.3);
  background-image: none;
  border-color: rgba(140, 216, 235, 0.3);
}

.module-sky-40 {
  color: #273a4d;
  background: rgba(140, 216, 235, 0.4) linear-gradient(180deg, rgba(181, 228, 241, 0.49), rgba(140, 216, 235, 0.4)) repeat-x;
  border-color: rgba(140, 216, 235, 0.4);
}

.module-sky-40 .icon-container {
  background-color: rgba(107, 205, 229, 0.4);
}

.module-sky-40 .icon-container.light {
  background-color: rgba(205, 238, 246, 0.4);
}

.module-sky-40:hover {
  color: #273a4d;
  background: rgba(107, 205, 229, 0.4) linear-gradient(180deg, rgba(161, 222, 238, 0.49), rgba(107, 205, 229, 0.4)) repeat-x;
  border-color: color-yig(rgba(107, 205, 229, 0.4));
}

.module-sky-40:focus, .module-sky-40.focus {
  color: #273a4d;
  background: rgba(107, 205, 229, 0.4) linear-gradient(180deg, rgba(161, 222, 238, 0.49), rgba(107, 205, 229, 0.4)) repeat-x;
  border-color: color-yig(rgba(107, 205, 229, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(98, 151, 170, 0.5);
}

.module-sky-40:active, .module-sky-40.active,
.show > .module-sky-40.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(97, 201, 227, 0.4);
  background-image: none;
  border-color: rgba(86, 198, 226, 0.4);
}

.module-sky-40:active:focus, .module-sky-40.active:focus,
.show > .module-sky-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(98, 151, 170, 0.5);
}

.module-sky-40:disabled, .module-sky-40.disabled {
  color: #273a4d;
  background-color: rgba(140, 216, 235, 0.4);
  background-image: none;
  border-color: rgba(140, 216, 235, 0.4);
}

.module-sky-50 {
  color: #273a4d;
  background: rgba(140, 216, 235, 0.5) linear-gradient(180deg, rgba(174, 226, 240, 0.575), rgba(140, 216, 235, 0.5)) repeat-x;
  border-color: rgba(140, 216, 235, 0.5);
}

.module-sky-50 .icon-container {
  background-color: rgba(107, 205, 229, 0.5);
}

.module-sky-50 .icon-container.light {
  background-color: rgba(205, 238, 246, 0.5);
}

.module-sky-50:hover {
  color: #273a4d;
  background: rgba(107, 205, 229, 0.5) linear-gradient(180deg, rgba(153, 219, 236, 0.575), rgba(107, 205, 229, 0.5)) repeat-x;
  border-color: color-yig(rgba(107, 205, 229, 0.5));
}

.module-sky-50:focus, .module-sky-50.focus {
  color: #273a4d;
  background: rgba(107, 205, 229, 0.5) linear-gradient(180deg, rgba(153, 219, 236, 0.575), rgba(107, 205, 229, 0.5)) repeat-x;
  border-color: color-yig(rgba(107, 205, 229, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(105, 161, 180, 0.5);
}

.module-sky-50:active, .module-sky-50.active,
.show > .module-sky-50.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(97, 201, 227, 0.5);
  background-image: none;
  border-color: rgba(86, 198, 226, 0.5);
}

.module-sky-50:active:focus, .module-sky-50.active:focus,
.show > .module-sky-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 161, 180, 0.5);
}

.module-sky-50:disabled, .module-sky-50.disabled {
  color: #273a4d;
  background-color: rgba(140, 216, 235, 0.5);
  background-image: none;
  border-color: rgba(140, 216, 235, 0.5);
}

.module-sky-60 {
  color: #273a4d;
  background: rgba(140, 216, 235, 0.6) linear-gradient(180deg, rgba(169, 224, 239, 0.66), rgba(140, 216, 235, 0.6)) repeat-x;
  border-color: rgba(140, 216, 235, 0.6);
}

.module-sky-60 .icon-container {
  background-color: rgba(107, 205, 229, 0.6);
}

.module-sky-60 .icon-container.light {
  background-color: rgba(205, 238, 246, 0.6);
}

.module-sky-60:hover {
  color: #273a4d;
  background: rgba(107, 205, 229, 0.6) linear-gradient(180deg, rgba(146, 217, 235, 0.66), rgba(107, 205, 229, 0.6)) repeat-x;
  border-color: color-yig(rgba(107, 205, 229, 0.6));
}

.module-sky-60:focus, .module-sky-60.focus {
  color: #273a4d;
  background: rgba(107, 205, 229, 0.6) linear-gradient(180deg, rgba(146, 217, 235, 0.66), rgba(107, 205, 229, 0.6)) repeat-x;
  border-color: color-yig(rgba(107, 205, 229, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(111, 170, 189, 0.5);
}

.module-sky-60:active, .module-sky-60.active,
.show > .module-sky-60.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(97, 201, 227, 0.6);
  background-image: none;
  border-color: rgba(86, 198, 226, 0.6);
}

.module-sky-60:active:focus, .module-sky-60.active:focus,
.show > .module-sky-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 170, 189, 0.5);
}

.module-sky-60:disabled, .module-sky-60.disabled {
  color: #273a4d;
  background-color: rgba(140, 216, 235, 0.6);
  background-image: none;
  border-color: rgba(140, 216, 235, 0.6);
}

.module-sky-70 {
  color: #273a4d;
  background: rgba(140, 216, 235, 0.7) linear-gradient(180deg, rgba(164, 223, 239, 0.745), rgba(140, 216, 235, 0.7)) repeat-x;
  border-color: rgba(140, 216, 235, 0.7);
}

.module-sky-70 .icon-container {
  background-color: rgba(107, 205, 229, 0.7);
}

.module-sky-70 .icon-container.light {
  background-color: rgba(205, 238, 246, 0.7);
}

.module-sky-70:hover {
  color: #273a4d;
  background: rgba(107, 205, 229, 0.7) linear-gradient(180deg, rgba(140, 215, 234, 0.745), rgba(107, 205, 229, 0.7)) repeat-x;
  border-color: color-yig(rgba(107, 205, 229, 0.7));
}

.module-sky-70:focus, .module-sky-70.focus {
  color: #273a4d;
  background: rgba(107, 205, 229, 0.7) linear-gradient(180deg, rgba(140, 215, 234, 0.745), rgba(107, 205, 229, 0.7)) repeat-x;
  border-color: color-yig(rgba(107, 205, 229, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(115, 177, 196, 0.5);
}

.module-sky-70:active, .module-sky-70.active,
.show > .module-sky-70.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(97, 201, 227, 0.7);
  background-image: none;
  border-color: rgba(86, 198, 226, 0.7);
}

.module-sky-70:active:focus, .module-sky-70.active:focus,
.show > .module-sky-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(115, 177, 196, 0.5);
}

.module-sky-70:disabled, .module-sky-70.disabled {
  color: #273a4d;
  background-color: rgba(140, 216, 235, 0.7);
  background-image: none;
  border-color: rgba(140, 216, 235, 0.7);
}

.module-sky-80 {
  color: #273a4d;
  background: rgba(140, 216, 235, 0.8) linear-gradient(180deg, rgba(161, 222, 238, 0.83), rgba(140, 216, 235, 0.8)) repeat-x;
  border-color: rgba(140, 216, 235, 0.8);
}

.module-sky-80 .icon-container {
  background-color: rgba(107, 205, 229, 0.8);
}

.module-sky-80 .icon-container.light {
  background-color: rgba(205, 238, 246, 0.8);
}

.module-sky-80:hover {
  color: #273a4d;
  background: rgba(107, 205, 229, 0.8) linear-gradient(180deg, rgba(135, 213, 234, 0.83), rgba(107, 205, 229, 0.8)) repeat-x;
  border-color: color-yig(rgba(107, 205, 229, 0.8));
}

.module-sky-80:focus, .module-sky-80.focus {
  color: #273a4d;
  background: rgba(107, 205, 229, 0.8) linear-gradient(180deg, rgba(135, 213, 234, 0.83), rgba(107, 205, 229, 0.8)) repeat-x;
  border-color: color-yig(rgba(107, 205, 229, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(119, 183, 202, 0.5);
}

.module-sky-80:active, .module-sky-80.active,
.show > .module-sky-80.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(97, 201, 227, 0.8);
  background-image: none;
  border-color: rgba(86, 198, 226, 0.8);
}

.module-sky-80:active:focus, .module-sky-80.active:focus,
.show > .module-sky-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(119, 183, 202, 0.5);
}

.module-sky-80:disabled, .module-sky-80.disabled {
  color: #273a4d;
  background-color: rgba(140, 216, 235, 0.8);
  background-image: none;
  border-color: rgba(140, 216, 235, 0.8);
}

.module-sky-90 {
  color: #273a4d;
  background: rgba(140, 216, 235, 0.9) linear-gradient(180deg, rgba(157, 221, 238, 0.915), rgba(140, 216, 235, 0.9)) repeat-x;
  border-color: rgba(140, 216, 235, 0.9);
}

.module-sky-90 .icon-container {
  background-color: rgba(107, 205, 229, 0.9);
}

.module-sky-90 .icon-container.light {
  background-color: rgba(205, 238, 246, 0.9);
}

.module-sky-90:hover {
  color: #273a4d;
  background: rgba(107, 205, 229, 0.9) linear-gradient(180deg, rgba(131, 212, 233, 0.915), rgba(107, 205, 229, 0.9)) repeat-x;
  border-color: color-yig(rgba(107, 205, 229, 0.9));
}

.module-sky-90:focus, .module-sky-90.focus {
  color: #273a4d;
  background: rgba(107, 205, 229, 0.9) linear-gradient(180deg, rgba(131, 212, 233, 0.915), rgba(107, 205, 229, 0.9)) repeat-x;
  border-color: color-yig(rgba(107, 205, 229, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(122, 188, 207, 0.5);
}

.module-sky-90:active, .module-sky-90.active,
.show > .module-sky-90.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(97, 201, 227, 0.9);
  background-image: none;
  border-color: rgba(86, 198, 226, 0.9);
}

.module-sky-90:active:focus, .module-sky-90.active:focus,
.show > .module-sky-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 188, 207, 0.5);
}

.module-sky-90:disabled, .module-sky-90.disabled {
  color: #273a4d;
  background-color: rgba(140, 216, 235, 0.9);
  background-image: none;
  border-color: rgba(140, 216, 235, 0.9);
}

.module-mountain-river {
  color: #273a4d;
  background: #c4eaf3 linear-gradient(180deg, #caecf4, #c4eaf3) repeat-x;
  border-color: #c4eaf3;
}

.module-mountain-river .icon-container {
  background-color: #a4dfed;
}

.module-mountain-river .icon-container.light {
  background-color: white;
}

.module-mountain-river:hover {
  color: #273a4d;
  background: #a4dfed linear-gradient(180deg, #afe2ef, #a4dfed) repeat-x;
  border-color: color-yig(#a4dfed);
}

.module-mountain-river:focus, .module-mountain-river.focus {
  color: #273a4d;
  background: #a4dfed linear-gradient(180deg, #afe2ef, #a4dfed) repeat-x;
  border-color: color-yig(#a4dfed);
  box-shadow: 0 0 0 0.2rem rgba(172, 208, 218, 0.5);
}

.module-mountain-river:active, .module-mountain-river.active,
.show > .module-mountain-river.dropdown-toggle {
  color: #273a4d;
  background-color: #9adbea;
  background-image: none;
  border-color: #8fd7e8;
}

.module-mountain-river:active:focus, .module-mountain-river.active:focus,
.show > .module-mountain-river.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(172, 208, 218, 0.5);
}

.module-mountain-river:disabled, .module-mountain-river.disabled {
  color: #273a4d;
  background-color: #c4eaf3;
  background-image: none;
  border-color: #c4eaf3;
}

.module-mountain-river-10 {
  color: #273a4d;
  background: rgba(196, 234, 243, 0.1) linear-gradient(180deg, rgba(228, 242, 248, 0.235), rgba(196, 234, 243, 0.1)) repeat-x;
  border-color: rgba(196, 234, 243, 0.1);
}

.module-mountain-river-10 .icon-container {
  background-color: rgba(164, 223, 237, 0.1);
}

.module-mountain-river-10 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.1);
}

.module-mountain-river-10:hover {
  color: #273a4d;
  background: rgba(164, 223, 237, 0.1) linear-gradient(180deg, rgba(221, 240, 247, 0.235), rgba(164, 223, 237, 0.1)) repeat-x;
  border-color: color-yig(rgba(164, 223, 237, 0.1));
}

.module-mountain-river-10:focus, .module-mountain-river-10.focus {
  color: #273a4d;
  background: rgba(164, 223, 237, 0.1) linear-gradient(180deg, rgba(221, 240, 247, 0.235), rgba(164, 223, 237, 0.1)) repeat-x;
  border-color: color-yig(rgba(164, 223, 237, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(75, 98, 115, 0.5);
}

.module-mountain-river-10:active, .module-mountain-river-10.active,
.show > .module-mountain-river-10.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(154, 219, 234, 0.1);
  background-image: none;
  border-color: rgba(143, 215, 232, 0.1);
}

.module-mountain-river-10:active:focus, .module-mountain-river-10.active:focus,
.show > .module-mountain-river-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(75, 98, 115, 0.5);
}

.module-mountain-river-10:disabled, .module-mountain-river-10.disabled {
  color: #273a4d;
  background-color: rgba(196, 234, 243, 0.1);
  background-image: none;
  border-color: rgba(196, 234, 243, 0.1);
}

.module-mountain-river-20 {
  color: #273a4d;
  background: rgba(196, 234, 243, 0.2) linear-gradient(180deg, rgba(222, 241, 247, 0.32), rgba(196, 234, 243, 0.2)) repeat-x;
  border-color: rgba(196, 234, 243, 0.2);
}

.module-mountain-river-20 .icon-container {
  background-color: rgba(164, 223, 237, 0.2);
}

.module-mountain-river-20 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.2);
}

.module-mountain-river-20:hover {
  color: #273a4d;
  background: rgba(164, 223, 237, 0.2) linear-gradient(180deg, rgba(209, 236, 245, 0.32), rgba(164, 223, 237, 0.2)) repeat-x;
  border-color: color-yig(rgba(164, 223, 237, 0.2));
}

.module-mountain-river-20:focus, .module-mountain-river-20.focus {
  color: #273a4d;
  background: rgba(164, 223, 237, 0.2) linear-gradient(180deg, rgba(209, 236, 245, 0.32), rgba(164, 223, 237, 0.2)) repeat-x;
  border-color: color-yig(rgba(164, 223, 237, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(100, 126, 141, 0.5);
}

.module-mountain-river-20:active, .module-mountain-river-20.active,
.show > .module-mountain-river-20.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(154, 219, 234, 0.2);
  background-image: none;
  border-color: rgba(143, 215, 232, 0.2);
}

.module-mountain-river-20:active:focus, .module-mountain-river-20.active:focus,
.show > .module-mountain-river-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(100, 126, 141, 0.5);
}

.module-mountain-river-20:disabled, .module-mountain-river-20.disabled {
  color: #273a4d;
  background-color: rgba(196, 234, 243, 0.2);
  background-image: none;
  border-color: rgba(196, 234, 243, 0.2);
}

.module-mountain-river-30 {
  color: #273a4d;
  background: rgba(196, 234, 243, 0.3) linear-gradient(180deg, rgba(217, 240, 247, 0.405), rgba(196, 234, 243, 0.3)) repeat-x;
  border-color: rgba(196, 234, 243, 0.3);
}

.module-mountain-river-30 .icon-container {
  background-color: rgba(164, 223, 237, 0.3);
}

.module-mountain-river-30 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.3);
}

.module-mountain-river-30:hover {
  color: #273a4d;
  background: rgba(164, 223, 237, 0.3) linear-gradient(180deg, rgba(201, 234, 243, 0.405), rgba(164, 223, 237, 0.3)) repeat-x;
  border-color: color-yig(rgba(164, 223, 237, 0.3));
}

.module-mountain-river-30:focus, .module-mountain-river-30.focus {
  color: #273a4d;
  background: rgba(164, 223, 237, 0.3) linear-gradient(180deg, rgba(201, 234, 243, 0.405), rgba(164, 223, 237, 0.3)) repeat-x;
  border-color: color-yig(rgba(164, 223, 237, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(118, 146, 160, 0.5);
}

.module-mountain-river-30:active, .module-mountain-river-30.active,
.show > .module-mountain-river-30.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(154, 219, 234, 0.3);
  background-image: none;
  border-color: rgba(143, 215, 232, 0.3);
}

.module-mountain-river-30:active:focus, .module-mountain-river-30.active:focus,
.show > .module-mountain-river-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(118, 146, 160, 0.5);
}

.module-mountain-river-30:disabled, .module-mountain-river-30.disabled {
  color: #273a4d;
  background-color: rgba(196, 234, 243, 0.3);
  background-image: none;
  border-color: rgba(196, 234, 243, 0.3);
}

.module-mountain-river-40 {
  color: #273a4d;
  background: rgba(196, 234, 243, 0.4) linear-gradient(180deg, rgba(213, 239, 246, 0.49), rgba(196, 234, 243, 0.4)) repeat-x;
  border-color: rgba(196, 234, 243, 0.4);
}

.module-mountain-river-40 .icon-container {
  background-color: rgba(164, 223, 237, 0.4);
}

.module-mountain-river-40 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.4);
}

.module-mountain-river-40:hover {
  color: #273a4d;
  background: rgba(164, 223, 237, 0.4) linear-gradient(180deg, rgba(195, 232, 242, 0.49), rgba(164, 223, 237, 0.4)) repeat-x;
  border-color: color-yig(rgba(164, 223, 237, 0.4));
}

.module-mountain-river-40:focus, .module-mountain-river-40.focus {
  color: #273a4d;
  background: rgba(164, 223, 237, 0.4) linear-gradient(180deg, rgba(195, 232, 242, 0.49), rgba(164, 223, 237, 0.4)) repeat-x;
  border-color: color-yig(rgba(164, 223, 237, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(131, 161, 174, 0.5);
}

.module-mountain-river-40:active, .module-mountain-river-40.active,
.show > .module-mountain-river-40.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(154, 219, 234, 0.4);
  background-image: none;
  border-color: rgba(143, 215, 232, 0.4);
}

.module-mountain-river-40:active:focus, .module-mountain-river-40.active:focus,
.show > .module-mountain-river-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(131, 161, 174, 0.5);
}

.module-mountain-river-40:disabled, .module-mountain-river-40.disabled {
  color: #273a4d;
  background-color: rgba(196, 234, 243, 0.4);
  background-image: none;
  border-color: rgba(196, 234, 243, 0.4);
}

.module-mountain-river-50 {
  color: #273a4d;
  background: rgba(196, 234, 243, 0.5) linear-gradient(180deg, rgba(211, 238, 245, 0.575), rgba(196, 234, 243, 0.5)) repeat-x;
  border-color: rgba(196, 234, 243, 0.5);
}

.module-mountain-river-50 .icon-container {
  background-color: rgba(164, 223, 237, 0.5);
}

.module-mountain-river-50 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.5);
}

.module-mountain-river-50:hover {
  color: #273a4d;
  background: rgba(164, 223, 237, 0.5) linear-gradient(180deg, rgba(190, 230, 241, 0.575), rgba(164, 223, 237, 0.5)) repeat-x;
  border-color: color-yig(rgba(164, 223, 237, 0.5));
}

.module-mountain-river-50:focus, .module-mountain-river-50.focus {
  color: #273a4d;
  background: rgba(164, 223, 237, 0.5) linear-gradient(180deg, rgba(190, 230, 241, 0.575), rgba(164, 223, 237, 0.5)) repeat-x;
  border-color: color-yig(rgba(164, 223, 237, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(142, 173, 186, 0.5);
}

.module-mountain-river-50:active, .module-mountain-river-50.active,
.show > .module-mountain-river-50.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(154, 219, 234, 0.5);
  background-image: none;
  border-color: rgba(143, 215, 232, 0.5);
}

.module-mountain-river-50:active:focus, .module-mountain-river-50.active:focus,
.show > .module-mountain-river-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(142, 173, 186, 0.5);
}

.module-mountain-river-50:disabled, .module-mountain-river-50.disabled {
  color: #273a4d;
  background-color: rgba(196, 234, 243, 0.5);
  background-image: none;
  border-color: rgba(196, 234, 243, 0.5);
}

.module-mountain-river-60 {
  color: #273a4d;
  background: rgba(196, 234, 243, 0.6) linear-gradient(180deg, rgba(208, 237, 245, 0.66), rgba(196, 234, 243, 0.6)) repeat-x;
  border-color: rgba(196, 234, 243, 0.6);
}

.module-mountain-river-60 .icon-container {
  background-color: rgba(164, 223, 237, 0.6);
}

.module-mountain-river-60 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.6);
}

.module-mountain-river-60:hover {
  color: #273a4d;
  background: rgba(164, 223, 237, 0.6) linear-gradient(180deg, rgba(186, 229, 240, 0.66), rgba(164, 223, 237, 0.6)) repeat-x;
  border-color: color-yig(rgba(164, 223, 237, 0.6));
}

.module-mountain-river-60:focus, .module-mountain-river-60.focus {
  color: #273a4d;
  background: rgba(164, 223, 237, 0.6) linear-gradient(180deg, rgba(186, 229, 240, 0.66), rgba(164, 223, 237, 0.6)) repeat-x;
  border-color: color-yig(rgba(164, 223, 237, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(150, 183, 195, 0.5);
}

.module-mountain-river-60:active, .module-mountain-river-60.active,
.show > .module-mountain-river-60.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(154, 219, 234, 0.6);
  background-image: none;
  border-color: rgba(143, 215, 232, 0.6);
}

.module-mountain-river-60:active:focus, .module-mountain-river-60.active:focus,
.show > .module-mountain-river-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(150, 183, 195, 0.5);
}

.module-mountain-river-60:disabled, .module-mountain-river-60.disabled {
  color: #273a4d;
  background-color: rgba(196, 234, 243, 0.6);
  background-image: none;
  border-color: rgba(196, 234, 243, 0.6);
}

.module-mountain-river-70 {
  color: #273a4d;
  background: rgba(196, 234, 243, 0.7) linear-gradient(180deg, rgba(206, 237, 245, 0.745), rgba(196, 234, 243, 0.7)) repeat-x;
  border-color: rgba(196, 234, 243, 0.7);
}

.module-mountain-river-70 .icon-container {
  background-color: rgba(164, 223, 237, 0.7);
}

.module-mountain-river-70 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.7);
}

.module-mountain-river-70:hover {
  color: #273a4d;
  background: rgba(164, 223, 237, 0.7) linear-gradient(180deg, rgba(182, 228, 240, 0.745), rgba(164, 223, 237, 0.7)) repeat-x;
  border-color: color-yig(rgba(164, 223, 237, 0.7));
}

.module-mountain-river-70:focus, .module-mountain-river-70.focus {
  color: #273a4d;
  background: rgba(164, 223, 237, 0.7) linear-gradient(180deg, rgba(182, 228, 240, 0.745), rgba(164, 223, 237, 0.7)) repeat-x;
  border-color: color-yig(rgba(164, 223, 237, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(157, 191, 202, 0.5);
}

.module-mountain-river-70:active, .module-mountain-river-70.active,
.show > .module-mountain-river-70.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(154, 219, 234, 0.7);
  background-image: none;
  border-color: rgba(143, 215, 232, 0.7);
}

.module-mountain-river-70:active:focus, .module-mountain-river-70.active:focus,
.show > .module-mountain-river-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(157, 191, 202, 0.5);
}

.module-mountain-river-70:disabled, .module-mountain-river-70.disabled {
  color: #273a4d;
  background-color: rgba(196, 234, 243, 0.7);
  background-image: none;
  border-color: rgba(196, 234, 243, 0.7);
}

.module-mountain-river-80 {
  color: #273a4d;
  background: rgba(196, 234, 243, 0.8) linear-gradient(180deg, rgba(205, 236, 244, 0.83), rgba(196, 234, 243, 0.8)) repeat-x;
  border-color: rgba(196, 234, 243, 0.8);
}

.module-mountain-river-80 .icon-container {
  background-color: rgba(164, 223, 237, 0.8);
}

.module-mountain-river-80 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.8);
}

.module-mountain-river-80:hover {
  color: #273a4d;
  background: rgba(164, 223, 237, 0.8) linear-gradient(180deg, rgba(180, 227, 239, 0.83), rgba(164, 223, 237, 0.8)) repeat-x;
  border-color: color-yig(rgba(164, 223, 237, 0.8));
}

.module-mountain-river-80:focus, .module-mountain-river-80.focus {
  color: #273a4d;
  background: rgba(164, 223, 237, 0.8) linear-gradient(180deg, rgba(180, 227, 239, 0.83), rgba(164, 223, 237, 0.8)) repeat-x;
  border-color: color-yig(rgba(164, 223, 237, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(163, 197, 208, 0.5);
}

.module-mountain-river-80:active, .module-mountain-river-80.active,
.show > .module-mountain-river-80.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(154, 219, 234, 0.8);
  background-image: none;
  border-color: rgba(143, 215, 232, 0.8);
}

.module-mountain-river-80:active:focus, .module-mountain-river-80.active:focus,
.show > .module-mountain-river-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(163, 197, 208, 0.5);
}

.module-mountain-river-80:disabled, .module-mountain-river-80.disabled {
  color: #273a4d;
  background-color: rgba(196, 234, 243, 0.8);
  background-image: none;
  border-color: rgba(196, 234, 243, 0.8);
}

.module-mountain-river-90 {
  color: #273a4d;
  background: rgba(196, 234, 243, 0.9) linear-gradient(180deg, rgba(203, 236, 244, 0.915), rgba(196, 234, 243, 0.9)) repeat-x;
  border-color: rgba(196, 234, 243, 0.9);
}

.module-mountain-river-90 .icon-container {
  background-color: rgba(164, 223, 237, 0.9);
}

.module-mountain-river-90 .icon-container.light {
  background-color: rgba(255, 255, 255, 0.9);
}

.module-mountain-river-90:hover {
  color: #273a4d;
  background: rgba(164, 223, 237, 0.9) linear-gradient(180deg, rgba(177, 227, 239, 0.915), rgba(164, 223, 237, 0.9)) repeat-x;
  border-color: color-yig(rgba(164, 223, 237, 0.9));
}

.module-mountain-river-90:focus, .module-mountain-river-90.focus {
  color: #273a4d;
  background: rgba(164, 223, 237, 0.9) linear-gradient(180deg, rgba(177, 227, 239, 0.915), rgba(164, 223, 237, 0.9)) repeat-x;
  border-color: color-yig(rgba(164, 223, 237, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(168, 203, 214, 0.5);
}

.module-mountain-river-90:active, .module-mountain-river-90.active,
.show > .module-mountain-river-90.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(154, 219, 234, 0.9);
  background-image: none;
  border-color: rgba(143, 215, 232, 0.9);
}

.module-mountain-river-90:active:focus, .module-mountain-river-90.active:focus,
.show > .module-mountain-river-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(168, 203, 214, 0.5);
}

.module-mountain-river-90:disabled, .module-mountain-river-90.disabled {
  color: #273a4d;
  background-color: rgba(196, 234, 243, 0.9);
  background-image: none;
  border-color: rgba(196, 234, 243, 0.9);
}

.module-dark-forest {
  color: #fff;
  background: #1c401d linear-gradient(180deg, #3c5b3e, #1c401d) repeat-x;
  border-color: #1c401d;
}

.module-dark-forest .icon-container {
  background-color: #102511;
}

.module-dark-forest .icon-container.light {
  background-color: #337535;
}

.module-dark-forest:hover {
  color: #fff;
  background: #102511 linear-gradient(180deg, #324534, #102511) repeat-x;
  border-color: color-yig(#102511);
}

.module-dark-forest:focus, .module-dark-forest.focus {
  color: #fff;
  background: #102511 linear-gradient(180deg, #324534, #102511) repeat-x;
  border-color: color-yig(#102511);
  box-shadow: 0 0 0 0.2rem rgba(62, 93, 63, 0.5);
}

.module-dark-forest:active, .module-dark-forest.active,
.show > .module-dark-forest.dropdown-toggle {
  color: #fff;
  background-color: #0c1d0d;
  background-image: none;
  border-color: #091409;
}

.module-dark-forest:active:focus, .module-dark-forest.active:focus,
.show > .module-dark-forest.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(62, 93, 63, 0.5);
}

.module-dark-forest:disabled, .module-dark-forest.disabled {
  color: #fff;
  background-color: #1c401d;
  background-image: none;
  border-color: #1c401d;
}

.module-dark-forest-10 {
  color: #fff;
  background: rgba(28, 64, 29, 0.1) linear-gradient(180deg, rgba(190, 203, 199, 0.235), rgba(28, 64, 29, 0.1)) repeat-x;
  border-color: rgba(28, 64, 29, 0.1);
}

.module-dark-forest-10 .icon-container {
  background-color: rgba(16, 37, 17, 0.1);
}

.module-dark-forest-10 .icon-container.light {
  background-color: rgba(51, 117, 53, 0.1);
}

.module-dark-forest-10:hover {
  color: #fff;
  background: rgba(16, 37, 17, 0.1) linear-gradient(180deg, rgba(187, 197, 196, 0.235), rgba(16, 37, 17, 0.1)) repeat-x;
  border-color: color-yig(rgba(16, 37, 17, 0.1));
}

.module-dark-forest-10:focus, .module-dark-forest-10.focus {
  color: #fff;
  background: rgba(16, 37, 17, 0.1) linear-gradient(180deg, rgba(187, 197, 196, 0.235), rgba(16, 37, 17, 0.1)) repeat-x;
  border-color: color-yig(rgba(16, 37, 17, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(203, 211, 203, 0.5);
}

.module-dark-forest-10:active, .module-dark-forest-10.active,
.show > .module-dark-forest-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(12, 29, 13, 0.1);
  background-image: none;
  border-color: rgba(9, 20, 9, 0.1);
}

.module-dark-forest-10:active:focus, .module-dark-forest-10.active:focus,
.show > .module-dark-forest-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(203, 211, 203, 0.5);
}

.module-dark-forest-10:disabled, .module-dark-forest-10.disabled {
  color: #fff;
  background-color: rgba(28, 64, 29, 0.1);
  background-image: none;
  border-color: rgba(28, 64, 29, 0.1);
}

.module-dark-forest-20 {
  color: #fff;
  background: rgba(28, 64, 29, 0.2) linear-gradient(180deg, rgba(157, 175, 165, 0.32), rgba(28, 64, 29, 0.2)) repeat-x;
  border-color: rgba(28, 64, 29, 0.2);
}

.module-dark-forest-20 .icon-container {
  background-color: rgba(16, 37, 17, 0.2);
}

.module-dark-forest-20 .icon-container.light {
  background-color: rgba(51, 117, 53, 0.2);
}

.module-dark-forest-20:hover {
  color: #fff;
  background: rgba(16, 37, 17, 0.2) linear-gradient(180deg, rgba(152, 165, 160, 0.32), rgba(16, 37, 17, 0.2)) repeat-x;
  border-color: color-yig(rgba(16, 37, 17, 0.2));
}

.module-dark-forest-20:focus, .module-dark-forest-20.focus {
  color: #fff;
  background: rgba(16, 37, 17, 0.2) linear-gradient(180deg, rgba(152, 165, 160, 0.32), rgba(16, 37, 17, 0.2)) repeat-x;
  border-color: color-yig(rgba(16, 37, 17, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(167, 181, 168, 0.5);
}

.module-dark-forest-20:active, .module-dark-forest-20.active,
.show > .module-dark-forest-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(12, 29, 13, 0.2);
  background-image: none;
  border-color: rgba(9, 20, 9, 0.2);
}

.module-dark-forest-20:active:focus, .module-dark-forest-20.active:focus,
.show > .module-dark-forest-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(167, 181, 168, 0.5);
}

.module-dark-forest-20:disabled, .module-dark-forest-20.disabled {
  color: #fff;
  background-color: rgba(28, 64, 29, 0.2);
  background-image: none;
  border-color: rgba(28, 64, 29, 0.2);
}

.module-dark-forest-30 {
  color: #fff;
  background: rgba(28, 64, 29, 0.3) linear-gradient(180deg, rgba(133, 155, 140, 0.405), rgba(28, 64, 29, 0.3)) repeat-x;
  border-color: rgba(28, 64, 29, 0.3);
}

.module-dark-forest-30 .icon-container {
  background-color: rgba(16, 37, 17, 0.3);
}

.module-dark-forest-30 .icon-container.light {
  background-color: rgba(51, 117, 53, 0.3);
}

.module-dark-forest-30:hover {
  color: #fff;
  background: rgba(16, 37, 17, 0.3) linear-gradient(180deg, rgba(127, 141, 133, 0.405), rgba(16, 37, 17, 0.3)) repeat-x;
  border-color: color-yig(rgba(16, 37, 17, 0.3));
}

.module-dark-forest-30:focus, .module-dark-forest-30.focus {
  color: #fff;
  background: rgba(16, 37, 17, 0.3) linear-gradient(180deg, rgba(127, 141, 133, 0.405), rgba(16, 37, 17, 0.3)) repeat-x;
  border-color: color-yig(rgba(16, 37, 17, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(142, 160, 142, 0.5);
}

.module-dark-forest-30:active, .module-dark-forest-30.active,
.show > .module-dark-forest-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(12, 29, 13, 0.3);
  background-image: none;
  border-color: rgba(9, 20, 9, 0.3);
}

.module-dark-forest-30:active:focus, .module-dark-forest-30.active:focus,
.show > .module-dark-forest-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(142, 160, 142, 0.5);
}

.module-dark-forest-30:disabled, .module-dark-forest-30.disabled {
  color: #fff;
  background-color: rgba(28, 64, 29, 0.3);
  background-image: none;
  border-color: rgba(28, 64, 29, 0.3);
}

.module-dark-forest-40 {
  color: #fff;
  background: rgba(28, 64, 29, 0.4) linear-gradient(180deg, rgba(115, 139, 120, 0.49), rgba(28, 64, 29, 0.4)) repeat-x;
  border-color: rgba(28, 64, 29, 0.4);
}

.module-dark-forest-40 .icon-container {
  background-color: rgba(16, 37, 17, 0.4);
}

.module-dark-forest-40 .icon-container.light {
  background-color: rgba(51, 117, 53, 0.4);
}

.module-dark-forest-40:hover {
  color: #fff;
  background: rgba(16, 37, 17, 0.4) linear-gradient(180deg, rgba(108, 123, 113, 0.49), rgba(16, 37, 17, 0.4)) repeat-x;
  border-color: color-yig(rgba(16, 37, 17, 0.4));
}

.module-dark-forest-40:focus, .module-dark-forest-40.focus {
  color: #fff;
  background: rgba(16, 37, 17, 0.4) linear-gradient(180deg, rgba(108, 123, 113, 0.49), rgba(16, 37, 17, 0.4)) repeat-x;
  border-color: color-yig(rgba(16, 37, 17, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(122, 143, 123, 0.5);
}

.module-dark-forest-40:active, .module-dark-forest-40.active,
.show > .module-dark-forest-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(12, 29, 13, 0.4);
  background-image: none;
  border-color: rgba(9, 20, 9, 0.4);
}

.module-dark-forest-40:active:focus, .module-dark-forest-40.active:focus,
.show > .module-dark-forest-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 143, 123, 0.5);
}

.module-dark-forest-40:disabled, .module-dark-forest-40.disabled {
  color: #fff;
  background-color: rgba(28, 64, 29, 0.4);
  background-image: none;
  border-color: rgba(28, 64, 29, 0.4);
}

.module-dark-forest-50 {
  color: #fff;
  background: rgba(28, 64, 29, 0.5) linear-gradient(180deg, rgba(101, 127, 106, 0.575), rgba(28, 64, 29, 0.5)) repeat-x;
  border-color: rgba(28, 64, 29, 0.5);
}

.module-dark-forest-50 .icon-container {
  background-color: rgba(16, 37, 17, 0.5);
}

.module-dark-forest-50 .icon-container.light {
  background-color: rgba(51, 117, 53, 0.5);
}

.module-dark-forest-50:hover {
  color: #fff;
  background: rgba(16, 37, 17, 0.5) linear-gradient(180deg, rgba(93, 109, 98, 0.575), rgba(16, 37, 17, 0.5)) repeat-x;
  border-color: color-yig(rgba(16, 37, 17, 0.5));
}

.module-dark-forest-50:focus, .module-dark-forest-50.focus {
  color: #fff;
  background: rgba(16, 37, 17, 0.5) linear-gradient(180deg, rgba(93, 109, 98, 0.575), rgba(16, 37, 17, 0.5)) repeat-x;
  border-color: color-yig(rgba(16, 37, 17, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(107, 130, 107, 0.5);
}

.module-dark-forest-50:active, .module-dark-forest-50.active,
.show > .module-dark-forest-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(12, 29, 13, 0.5);
  background-image: none;
  border-color: rgba(9, 20, 9, 0.5);
}

.module-dark-forest-50:active:focus, .module-dark-forest-50.active:focus,
.show > .module-dark-forest-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(107, 130, 107, 0.5);
}

.module-dark-forest-50:disabled, .module-dark-forest-50.disabled {
  color: #fff;
  background-color: rgba(28, 64, 29, 0.5);
  background-image: none;
  border-color: rgba(28, 64, 29, 0.5);
}

.module-dark-forest-60 {
  color: #fff;
  background: rgba(28, 64, 29, 0.6) linear-gradient(180deg, rgba(89, 117, 93, 0.66), rgba(28, 64, 29, 0.6)) repeat-x;
  border-color: rgba(28, 64, 29, 0.6);
}

.module-dark-forest-60 .icon-container {
  background-color: rgba(16, 37, 17, 0.6);
}

.module-dark-forest-60 .icon-container.light {
  background-color: rgba(51, 117, 53, 0.6);
}

.module-dark-forest-60:hover {
  color: #fff;
  background: rgba(16, 37, 17, 0.6) linear-gradient(180deg, rgba(81, 98, 85, 0.66), rgba(16, 37, 17, 0.6)) repeat-x;
  border-color: color-yig(rgba(16, 37, 17, 0.6));
}

.module-dark-forest-60:focus, .module-dark-forest-60.focus {
  color: #fff;
  background: rgba(16, 37, 17, 0.6) linear-gradient(180deg, rgba(81, 98, 85, 0.66), rgba(16, 37, 17, 0.6)) repeat-x;
  border-color: color-yig(rgba(16, 37, 17, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(94, 120, 95, 0.5);
}

.module-dark-forest-60:active, .module-dark-forest-60.active,
.show > .module-dark-forest-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(12, 29, 13, 0.6);
  background-image: none;
  border-color: rgba(9, 20, 9, 0.6);
}

.module-dark-forest-60:active:focus, .module-dark-forest-60.active:focus,
.show > .module-dark-forest-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(94, 120, 95, 0.5);
}

.module-dark-forest-60:disabled, .module-dark-forest-60.disabled {
  color: #fff;
  background-color: rgba(28, 64, 29, 0.6);
  background-image: none;
  border-color: rgba(28, 64, 29, 0.6);
}

.module-dark-forest-70 {
  color: #fff;
  background: rgba(28, 64, 29, 0.7) linear-gradient(180deg, rgba(80, 109, 84, 0.745), rgba(28, 64, 29, 0.7)) repeat-x;
  border-color: rgba(28, 64, 29, 0.7);
}

.module-dark-forest-70 .icon-container {
  background-color: rgba(16, 37, 17, 0.7);
}

.module-dark-forest-70 .icon-container.light {
  background-color: rgba(51, 117, 53, 0.7);
}

.module-dark-forest-70:hover {
  color: #fff;
  background: rgba(16, 37, 17, 0.7) linear-gradient(180deg, rgba(71, 89, 74, 0.745), rgba(16, 37, 17, 0.7)) repeat-x;
  border-color: color-yig(rgba(16, 37, 17, 0.7));
}

.module-dark-forest-70:focus, .module-dark-forest-70.focus {
  color: #fff;
  background: rgba(16, 37, 17, 0.7) linear-gradient(180deg, rgba(71, 89, 74, 0.745), rgba(16, 37, 17, 0.7)) repeat-x;
  border-color: color-yig(rgba(16, 37, 17, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(84, 111, 85, 0.5);
}

.module-dark-forest-70:active, .module-dark-forest-70.active,
.show > .module-dark-forest-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(12, 29, 13, 0.7);
  background-image: none;
  border-color: rgba(9, 20, 9, 0.7);
}

.module-dark-forest-70:active:focus, .module-dark-forest-70.active:focus,
.show > .module-dark-forest-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(84, 111, 85, 0.5);
}

.module-dark-forest-70:disabled, .module-dark-forest-70.disabled {
  color: #fff;
  background-color: rgba(28, 64, 29, 0.7);
  background-image: none;
  border-color: rgba(28, 64, 29, 0.7);
}

.module-dark-forest-80 {
  color: #fff;
  background: rgba(28, 64, 29, 0.8) linear-gradient(180deg, rgba(72, 102, 75, 0.83), rgba(28, 64, 29, 0.8)) repeat-x;
  border-color: rgba(28, 64, 29, 0.8);
}

.module-dark-forest-80 .icon-container {
  background-color: rgba(16, 37, 17, 0.8);
}

.module-dark-forest-80 .icon-container.light {
  background-color: rgba(51, 117, 53, 0.8);
}

.module-dark-forest-80:hover {
  color: #fff;
  background: rgba(16, 37, 17, 0.8) linear-gradient(180deg, rgba(63, 81, 66, 0.83), rgba(16, 37, 17, 0.8)) repeat-x;
  border-color: color-yig(rgba(16, 37, 17, 0.8));
}

.module-dark-forest-80:focus, .module-dark-forest-80.focus {
  color: #fff;
  background: rgba(16, 37, 17, 0.8) linear-gradient(180deg, rgba(63, 81, 66, 0.83), rgba(16, 37, 17, 0.8)) repeat-x;
  border-color: color-yig(rgba(16, 37, 17, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(76, 104, 76, 0.5);
}

.module-dark-forest-80:active, .module-dark-forest-80.active,
.show > .module-dark-forest-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(12, 29, 13, 0.8);
  background-image: none;
  border-color: rgba(9, 20, 9, 0.8);
}

.module-dark-forest-80:active:focus, .module-dark-forest-80.active:focus,
.show > .module-dark-forest-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 104, 76, 0.5);
}

.module-dark-forest-80:disabled, .module-dark-forest-80.disabled {
  color: #fff;
  background-color: rgba(28, 64, 29, 0.8);
  background-image: none;
  border-color: rgba(28, 64, 29, 0.8);
}

.module-dark-forest-90 {
  color: #fff;
  background: rgba(28, 64, 29, 0.9) linear-gradient(180deg, rgba(65, 96, 68, 0.915), rgba(28, 64, 29, 0.9)) repeat-x;
  border-color: rgba(28, 64, 29, 0.9);
}

.module-dark-forest-90 .icon-container {
  background-color: rgba(16, 37, 17, 0.9);
}

.module-dark-forest-90 .icon-container.light {
  background-color: rgba(51, 117, 53, 0.9);
}

.module-dark-forest-90:hover {
  color: #fff;
  background: rgba(16, 37, 17, 0.9) linear-gradient(180deg, rgba(56, 74, 58, 0.915), rgba(16, 37, 17, 0.9)) repeat-x;
  border-color: color-yig(rgba(16, 37, 17, 0.9));
}

.module-dark-forest-90:focus, .module-dark-forest-90.focus {
  color: #fff;
  background: rgba(16, 37, 17, 0.9) linear-gradient(180deg, rgba(56, 74, 58, 0.915), rgba(16, 37, 17, 0.9)) repeat-x;
  border-color: color-yig(rgba(16, 37, 17, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(68, 98, 69, 0.5);
}

.module-dark-forest-90:active, .module-dark-forest-90.active,
.show > .module-dark-forest-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(12, 29, 13, 0.9);
  background-image: none;
  border-color: rgba(9, 20, 9, 0.9);
}

.module-dark-forest-90:active:focus, .module-dark-forest-90.active:focus,
.show > .module-dark-forest-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 98, 69, 0.5);
}

.module-dark-forest-90:disabled, .module-dark-forest-90.disabled {
  color: #fff;
  background-color: rgba(28, 64, 29, 0.9);
  background-image: none;
  border-color: rgba(28, 64, 29, 0.9);
}

.module-fir {
  color: #fff;
  background: #137117 linear-gradient(180deg, #348539, #137117) repeat-x;
  border-color: #137117;
}

.module-fir .icon-container {
  background-color: #0d5010;
}

.module-fir .icon-container.light {
  background-color: #1eb224;
}

.module-fir:hover {
  color: #fff;
  background: #0d5010 linear-gradient(180deg, #2f6933, #0d5010) repeat-x;
  border-color: color-yig(#0d5010);
}

.module-fir:focus, .module-fir.focus {
  color: #fff;
  background: #0d5010 linear-gradient(180deg, #2f6933, #0d5010) repeat-x;
  border-color: color-yig(#0d5010);
  box-shadow: 0 0 0 0.2rem rgba(54, 134, 58, 0.5);
}

.module-fir:active, .module-fir.active,
.show > .module-fir.dropdown-toggle {
  color: #fff;
  background-color: #0c450e;
  background-image: none;
  border-color: #0a3a0c;
}

.module-fir:active:focus, .module-fir.active:focus,
.show > .module-fir.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(54, 134, 58, 0.5);
}

.module-fir:disabled, .module-fir.disabled {
  color: #fff;
  background-color: #137117;
  background-image: none;
  border-color: #137117;
}

.module-fir-10 {
  color: #fff;
  background: rgba(19, 113, 23, 0.1) linear-gradient(180deg, rgba(188, 215, 198, 0.235), rgba(19, 113, 23, 0.1)) repeat-x;
  border-color: rgba(19, 113, 23, 0.1);
}

.module-fir-10 .icon-container {
  background-color: rgba(13, 80, 16, 0.1);
}

.module-fir-10 .icon-container.light {
  background-color: rgba(30, 178, 36, 0.1);
}

.module-fir-10:hover {
  color: #fff;
  background: rgba(13, 80, 16, 0.1) linear-gradient(180deg, rgba(186, 207, 196, 0.235), rgba(13, 80, 16, 0.1)) repeat-x;
  border-color: color-yig(rgba(13, 80, 16, 0.1));
}

.module-fir-10:focus, .module-fir-10.focus {
  color: #fff;
  background: rgba(13, 80, 16, 0.1) linear-gradient(180deg, rgba(186, 207, 196, 0.235), rgba(13, 80, 16, 0.1)) repeat-x;
  border-color: color-yig(rgba(13, 80, 16, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(201, 222, 202, 0.5);
}

.module-fir-10:active, .module-fir-10.active,
.show > .module-fir-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(12, 69, 14, 0.1);
  background-image: none;
  border-color: rgba(10, 58, 12, 0.1);
}

.module-fir-10:active:focus, .module-fir-10.active:focus,
.show > .module-fir-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(201, 222, 202, 0.5);
}

.module-fir-10:disabled, .module-fir-10.disabled {
  color: #fff;
  background-color: rgba(19, 113, 23, 0.1);
  background-image: none;
  border-color: rgba(19, 113, 23, 0.1);
}

.module-fir-20 {
  color: #fff;
  background: rgba(19, 113, 23, 0.2) linear-gradient(180deg, rgba(153, 194, 162, 0.32), rgba(19, 113, 23, 0.2)) repeat-x;
  border-color: rgba(19, 113, 23, 0.2);
}

.module-fir-20 .icon-container {
  background-color: rgba(13, 80, 16, 0.2);
}

.module-fir-20 .icon-container.light {
  background-color: rgba(30, 178, 36, 0.2);
}

.module-fir-20:hover {
  color: #fff;
  background: rgba(13, 80, 16, 0.2) linear-gradient(180deg, rgba(151, 181, 160, 0.32), rgba(13, 80, 16, 0.2)) repeat-x;
  border-color: color-yig(rgba(13, 80, 16, 0.2));
}

.module-fir-20:focus, .module-fir-20.focus {
  color: #fff;
  background: rgba(13, 80, 16, 0.2) linear-gradient(180deg, rgba(151, 181, 160, 0.32), rgba(13, 80, 16, 0.2)) repeat-x;
  border-color: color-yig(rgba(13, 80, 16, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(164, 200, 165, 0.5);
}

.module-fir-20:active, .module-fir-20.active,
.show > .module-fir-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(12, 69, 14, 0.2);
  background-image: none;
  border-color: rgba(10, 58, 12, 0.2);
}

.module-fir-20:active:focus, .module-fir-20.active:focus,
.show > .module-fir-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(164, 200, 165, 0.5);
}

.module-fir-20:disabled, .module-fir-20.disabled {
  color: #fff;
  background-color: rgba(19, 113, 23, 0.2);
  background-image: none;
  border-color: rgba(19, 113, 23, 0.2);
}

.module-fir-30 {
  color: #fff;
  background: rgba(19, 113, 23, 0.3) linear-gradient(180deg, rgba(129, 179, 137, 0.405), rgba(19, 113, 23, 0.3)) repeat-x;
  border-color: rgba(19, 113, 23, 0.3);
}

.module-fir-30 .icon-container {
  background-color: rgba(13, 80, 16, 0.3);
}

.module-fir-30 .icon-container.light {
  background-color: rgba(30, 178, 36, 0.3);
}

.module-fir-30:hover {
  color: #fff;
  background: rgba(13, 80, 16, 0.3) linear-gradient(180deg, rgba(126, 163, 133, 0.405), rgba(13, 80, 16, 0.3)) repeat-x;
  border-color: color-yig(rgba(13, 80, 16, 0.3));
}

.module-fir-30:focus, .module-fir-30.focus {
  color: #fff;
  background: rgba(13, 80, 16, 0.3) linear-gradient(180deg, rgba(126, 163, 133, 0.405), rgba(13, 80, 16, 0.3)) repeat-x;
  border-color: color-yig(rgba(13, 80, 16, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(137, 184, 139, 0.5);
}

.module-fir-30:active, .module-fir-30.active,
.show > .module-fir-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(12, 69, 14, 0.3);
  background-image: none;
  border-color: rgba(10, 58, 12, 0.3);
}

.module-fir-30:active:focus, .module-fir-30.active:focus,
.show > .module-fir-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(137, 184, 139, 0.5);
}

.module-fir-30:disabled, .module-fir-30.disabled {
  color: #fff;
  background-color: rgba(19, 113, 23, 0.3);
  background-image: none;
  border-color: rgba(19, 113, 23, 0.3);
}

.module-fir-40 {
  color: #fff;
  background: rgba(19, 113, 23, 0.4) linear-gradient(180deg, rgba(110, 168, 117, 0.49), rgba(19, 113, 23, 0.4)) repeat-x;
  border-color: rgba(19, 113, 23, 0.4);
}

.module-fir-40 .icon-container {
  background-color: rgba(13, 80, 16, 0.4);
}

.module-fir-40 .icon-container.light {
  background-color: rgba(30, 178, 36, 0.4);
}

.module-fir-40:hover {
  color: #fff;
  background: rgba(13, 80, 16, 0.4) linear-gradient(180deg, rgba(106, 148, 113, 0.49), rgba(13, 80, 16, 0.4)) repeat-x;
  border-color: color-yig(rgba(13, 80, 16, 0.4));
}

.module-fir-40:focus, .module-fir-40.focus {
  color: #fff;
  background: rgba(13, 80, 16, 0.4) linear-gradient(180deg, rgba(106, 148, 113, 0.49), rgba(13, 80, 16, 0.4)) repeat-x;
  border-color: color-yig(rgba(13, 80, 16, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(117, 172, 119, 0.5);
}

.module-fir-40:active, .module-fir-40.active,
.show > .module-fir-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(12, 69, 14, 0.4);
  background-image: none;
  border-color: rgba(10, 58, 12, 0.4);
}

.module-fir-40:active:focus, .module-fir-40.active:focus,
.show > .module-fir-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(117, 172, 119, 0.5);
}

.module-fir-40:disabled, .module-fir-40.disabled {
  color: #fff;
  background-color: rgba(19, 113, 23, 0.4);
  background-image: none;
  border-color: rgba(19, 113, 23, 0.4);
}

.module-fir-50 {
  color: #fff;
  background: rgba(19, 113, 23, 0.5) linear-gradient(180deg, rgba(95, 159, 102, 0.575), rgba(19, 113, 23, 0.5)) repeat-x;
  border-color: rgba(19, 113, 23, 0.5);
}

.module-fir-50 .icon-container {
  background-color: rgba(13, 80, 16, 0.5);
}

.module-fir-50 .icon-container.light {
  background-color: rgba(30, 178, 36, 0.5);
}

.module-fir-50:hover {
  color: #fff;
  background: rgba(13, 80, 16, 0.5) linear-gradient(180deg, rgba(91, 137, 97, 0.575), rgba(13, 80, 16, 0.5)) repeat-x;
  border-color: color-yig(rgba(13, 80, 16, 0.5));
}

.module-fir-50:focus, .module-fir-50.focus {
  color: #fff;
  background: rgba(13, 80, 16, 0.5) linear-gradient(180deg, rgba(91, 137, 97, 0.575), rgba(13, 80, 16, 0.5)) repeat-x;
  border-color: color-yig(rgba(13, 80, 16, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(101, 162, 103, 0.5);
}

.module-fir-50:active, .module-fir-50.active,
.show > .module-fir-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(12, 69, 14, 0.5);
  background-image: none;
  border-color: rgba(10, 58, 12, 0.5);
}

.module-fir-50:active:focus, .module-fir-50.active:focus,
.show > .module-fir-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(101, 162, 103, 0.5);
}

.module-fir-50:disabled, .module-fir-50.disabled {
  color: #fff;
  background-color: rgba(19, 113, 23, 0.5);
  background-image: none;
  border-color: rgba(19, 113, 23, 0.5);
}

.module-fir-60 {
  color: #fff;
  background: rgba(19, 113, 23, 0.6) linear-gradient(180deg, rgba(83, 152, 89, 0.66), rgba(19, 113, 23, 0.6)) repeat-x;
  border-color: rgba(19, 113, 23, 0.6);
}

.module-fir-60 .icon-container {
  background-color: rgba(13, 80, 16, 0.6);
}

.module-fir-60 .icon-container.light {
  background-color: rgba(30, 178, 36, 0.6);
}

.module-fir-60:hover {
  color: #fff;
  background: rgba(13, 80, 16, 0.6) linear-gradient(180deg, rgba(79, 128, 84, 0.66), rgba(13, 80, 16, 0.6)) repeat-x;
  border-color: color-yig(rgba(13, 80, 16, 0.6));
}

.module-fir-60:focus, .module-fir-60.focus {
  color: #fff;
  background: rgba(13, 80, 16, 0.6) linear-gradient(180deg, rgba(79, 128, 84, 0.66), rgba(13, 80, 16, 0.6)) repeat-x;
  border-color: color-yig(rgba(13, 80, 16, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(88, 154, 91, 0.5);
}

.module-fir-60:active, .module-fir-60.active,
.show > .module-fir-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(12, 69, 14, 0.6);
  background-image: none;
  border-color: rgba(10, 58, 12, 0.6);
}

.module-fir-60:active:focus, .module-fir-60.active:focus,
.show > .module-fir-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 154, 91, 0.5);
}

.module-fir-60:disabled, .module-fir-60.disabled {
  color: #fff;
  background-color: rgba(19, 113, 23, 0.6);
  background-image: none;
  border-color: rgba(19, 113, 23, 0.6);
}

.module-fir-70 {
  color: #fff;
  background: rgba(19, 113, 23, 0.7) linear-gradient(180deg, rgba(73, 146, 79, 0.745), rgba(19, 113, 23, 0.7)) repeat-x;
  border-color: rgba(19, 113, 23, 0.7);
}

.module-fir-70 .icon-container {
  background-color: rgba(13, 80, 16, 0.7);
}

.module-fir-70 .icon-container.light {
  background-color: rgba(30, 178, 36, 0.7);
}

.module-fir-70:hover {
  color: #fff;
  background: rgba(13, 80, 16, 0.7) linear-gradient(180deg, rgba(69, 121, 74, 0.745), rgba(13, 80, 16, 0.7)) repeat-x;
  border-color: color-yig(rgba(13, 80, 16, 0.7));
}

.module-fir-70:focus, .module-fir-70.focus {
  color: #fff;
  background: rgba(13, 80, 16, 0.7) linear-gradient(180deg, rgba(69, 121, 74, 0.745), rgba(13, 80, 16, 0.7)) repeat-x;
  border-color: color-yig(rgba(13, 80, 16, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(77, 148, 80, 0.5);
}

.module-fir-70:active, .module-fir-70.active,
.show > .module-fir-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(12, 69, 14, 0.7);
  background-image: none;
  border-color: rgba(10, 58, 12, 0.7);
}

.module-fir-70:active:focus, .module-fir-70.active:focus,
.show > .module-fir-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 148, 80, 0.5);
}

.module-fir-70:disabled, .module-fir-70.disabled {
  color: #fff;
  background-color: rgba(19, 113, 23, 0.7);
  background-image: none;
  border-color: rgba(19, 113, 23, 0.7);
}

.module-fir-80 {
  color: #fff;
  background: rgba(19, 113, 23, 0.8) linear-gradient(180deg, rgba(65, 141, 71, 0.83), rgba(19, 113, 23, 0.8)) repeat-x;
  border-color: rgba(19, 113, 23, 0.8);
}

.module-fir-80 .icon-container {
  background-color: rgba(13, 80, 16, 0.8);
}

.module-fir-80 .icon-container.light {
  background-color: rgba(30, 178, 36, 0.8);
}

.module-fir-80:hover {
  color: #fff;
  background: rgba(13, 80, 16, 0.8) linear-gradient(180deg, rgba(60, 115, 65, 0.83), rgba(13, 80, 16, 0.8)) repeat-x;
  border-color: color-yig(rgba(13, 80, 16, 0.8));
}

.module-fir-80:focus, .module-fir-80.focus {
  color: #fff;
  background: rgba(13, 80, 16, 0.8) linear-gradient(180deg, rgba(60, 115, 65, 0.83), rgba(13, 80, 16, 0.8)) repeat-x;
  border-color: color-yig(rgba(13, 80, 16, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(68, 143, 72, 0.5);
}

.module-fir-80:active, .module-fir-80.active,
.show > .module-fir-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(12, 69, 14, 0.8);
  background-image: none;
  border-color: rgba(10, 58, 12, 0.8);
}

.module-fir-80:active:focus, .module-fir-80.active:focus,
.show > .module-fir-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 143, 72, 0.5);
}

.module-fir-80:disabled, .module-fir-80.disabled {
  color: #fff;
  background-color: rgba(19, 113, 23, 0.8);
  background-image: none;
  border-color: rgba(19, 113, 23, 0.8);
}

.module-fir-90 {
  color: #fff;
  background: rgba(19, 113, 23, 0.9) linear-gradient(180deg, rgba(58, 136, 63, 0.915), rgba(19, 113, 23, 0.9)) repeat-x;
  border-color: rgba(19, 113, 23, 0.9);
}

.module-fir-90 .icon-container {
  background-color: rgba(13, 80, 16, 0.9);
}

.module-fir-90 .icon-container.light {
  background-color: rgba(30, 178, 36, 0.9);
}

.module-fir-90:hover {
  color: #fff;
  background: rgba(13, 80, 16, 0.9) linear-gradient(180deg, rgba(53, 109, 58, 0.915), rgba(13, 80, 16, 0.9)) repeat-x;
  border-color: color-yig(rgba(13, 80, 16, 0.9));
}

.module-fir-90:focus, .module-fir-90.focus {
  color: #fff;
  background: rgba(13, 80, 16, 0.9) linear-gradient(180deg, rgba(53, 109, 58, 0.915), rgba(13, 80, 16, 0.9)) repeat-x;
  border-color: color-yig(rgba(13, 80, 16, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(61, 138, 64, 0.5);
}

.module-fir-90:active, .module-fir-90.active,
.show > .module-fir-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(12, 69, 14, 0.9);
  background-image: none;
  border-color: rgba(10, 58, 12, 0.9);
}

.module-fir-90:active:focus, .module-fir-90.active:focus,
.show > .module-fir-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(61, 138, 64, 0.5);
}

.module-fir-90:disabled, .module-fir-90.disabled {
  color: #fff;
  background-color: rgba(19, 113, 23, 0.9);
  background-image: none;
  border-color: rgba(19, 113, 23, 0.9);
}

.module-larch {
  color: #fff;
  background: #1e9d23 linear-gradient(180deg, #3daa43, #1e9d23) repeat-x;
  border-color: #1e9d23;
}

.module-larch .icon-container {
  background-color: #187d1c;
}

.module-larch .icon-container.light {
  background-color: #30d737;
}

.module-larch:hover {
  color: #fff;
  background: #187d1c linear-gradient(180deg, #388f3d, #187d1c) repeat-x;
  border-color: color-yig(#187d1c);
}

.module-larch:focus, .module-larch.focus {
  color: #fff;
  background: #187d1c linear-gradient(180deg, #388f3d, #187d1c) repeat-x;
  border-color: color-yig(#187d1c);
  box-shadow: 0 0 0 0.2rem rgba(64, 172, 68, 0.5);
}

.module-larch:active, .module-larch.active,
.show > .module-larch.dropdown-toggle {
  color: #fff;
  background-color: #167219;
  background-image: none;
  border-color: #146717;
}

.module-larch:active:focus, .module-larch.active:focus,
.show > .module-larch.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 172, 68, 0.5);
}

.module-larch:disabled, .module-larch.disabled {
  color: #fff;
  background-color: #1e9d23;
  background-image: none;
  border-color: #1e9d23;
}

.module-larch-10 {
  color: #fff;
  background: rgba(30, 157, 35, 0.1) linear-gradient(180deg, rgba(190, 225, 201, 0.235), rgba(30, 157, 35, 0.1)) repeat-x;
  border-color: rgba(30, 157, 35, 0.1);
}

.module-larch-10 .icon-container {
  background-color: rgba(24, 125, 28, 0.1);
}

.module-larch-10 .icon-container.light {
  background-color: rgba(48, 215, 55, 0.1);
}

.module-larch-10:hover {
  color: #fff;
  background: rgba(24, 125, 28, 0.1) linear-gradient(180deg, rgba(189, 217, 199, 0.235), rgba(24, 125, 28, 0.1)) repeat-x;
  border-color: color-yig(rgba(24, 125, 28, 0.1));
}

.module-larch-10:focus, .module-larch-10.focus {
  color: #fff;
  background: rgba(24, 125, 28, 0.1) linear-gradient(180deg, rgba(189, 217, 199, 0.235), rgba(24, 125, 28, 0.1)) repeat-x;
  border-color: color-yig(rgba(24, 125, 28, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(203, 232, 204, 0.5);
}

.module-larch-10:active, .module-larch-10.active,
.show > .module-larch-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(22, 114, 25, 0.1);
  background-image: none;
  border-color: rgba(20, 103, 23, 0.1);
}

.module-larch-10:active:focus, .module-larch-10.active:focus,
.show > .module-larch-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(203, 232, 204, 0.5);
}

.module-larch-10:disabled, .module-larch-10.disabled {
  color: #fff;
  background-color: rgba(30, 157, 35, 0.1);
  background-image: none;
  border-color: rgba(30, 157, 35, 0.1);
}

.module-larch-20 {
  color: #fff;
  background: rgba(30, 157, 35, 0.2) linear-gradient(180deg, rgba(158, 211, 167, 0.32), rgba(30, 157, 35, 0.2)) repeat-x;
  border-color: rgba(30, 157, 35, 0.2);
}

.module-larch-20 .icon-container {
  background-color: rgba(24, 125, 28, 0.2);
}

.module-larch-20 .icon-container.light {
  background-color: rgba(48, 215, 55, 0.2);
}

.module-larch-20:hover {
  color: #fff;
  background: rgba(24, 125, 28, 0.2) linear-gradient(180deg, rgba(155, 199, 164, 0.32), rgba(24, 125, 28, 0.2)) repeat-x;
  border-color: color-yig(rgba(24, 125, 28, 0.2));
}

.module-larch-20:focus, .module-larch-20.focus {
  color: #fff;
  background: rgba(24, 125, 28, 0.2) linear-gradient(180deg, rgba(155, 199, 164, 0.32), rgba(24, 125, 28, 0.2)) repeat-x;
  border-color: color-yig(rgba(24, 125, 28, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(168, 217, 170, 0.5);
}

.module-larch-20:active, .module-larch-20.active,
.show > .module-larch-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(22, 114, 25, 0.2);
  background-image: none;
  border-color: rgba(20, 103, 23, 0.2);
}

.module-larch-20:active:focus, .module-larch-20.active:focus,
.show > .module-larch-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(168, 217, 170, 0.5);
}

.module-larch-20:disabled, .module-larch-20.disabled {
  color: #fff;
  background-color: rgba(30, 157, 35, 0.2);
  background-image: none;
  border-color: rgba(30, 157, 35, 0.2);
}

.module-larch-30 {
  color: #fff;
  background: rgba(30, 157, 35, 0.3) linear-gradient(180deg, rgba(134, 201, 143, 0.405), rgba(30, 157, 35, 0.3)) repeat-x;
  border-color: rgba(30, 157, 35, 0.3);
}

.module-larch-30 .icon-container {
  background-color: rgba(24, 125, 28, 0.3);
}

.module-larch-30 .icon-container.light {
  background-color: rgba(48, 215, 55, 0.3);
}

.module-larch-30:hover {
  color: #fff;
  background: rgba(24, 125, 28, 0.3) linear-gradient(180deg, rgba(131, 185, 139, 0.405), rgba(24, 125, 28, 0.3)) repeat-x;
  border-color: color-yig(rgba(24, 125, 28, 0.3));
}

.module-larch-30:focus, .module-larch-30.focus {
  color: #fff;
  background: rgba(24, 125, 28, 0.3) linear-gradient(180deg, rgba(131, 185, 139, 0.405), rgba(24, 125, 28, 0.3)) repeat-x;
  border-color: color-yig(rgba(24, 125, 28, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(143, 206, 145, 0.5);
}

.module-larch-30:active, .module-larch-30.active,
.show > .module-larch-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(22, 114, 25, 0.3);
  background-image: none;
  border-color: rgba(20, 103, 23, 0.3);
}

.module-larch-30:active:focus, .module-larch-30.active:focus,
.show > .module-larch-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(143, 206, 145, 0.5);
}

.module-larch-30:disabled, .module-larch-30.disabled {
  color: #fff;
  background-color: rgba(30, 157, 35, 0.3);
  background-image: none;
  border-color: rgba(30, 157, 35, 0.3);
}

.module-larch-40 {
  color: #fff;
  background: rgba(30, 157, 35, 0.4) linear-gradient(180deg, rgba(116, 193, 124, 0.49), rgba(30, 157, 35, 0.4)) repeat-x;
  border-color: rgba(30, 157, 35, 0.4);
}

.module-larch-40 .icon-container {
  background-color: rgba(24, 125, 28, 0.4);
}

.module-larch-40 .icon-container.light {
  background-color: rgba(48, 215, 55, 0.4);
}

.module-larch-40:hover {
  color: #fff;
  background: rgba(24, 125, 28, 0.4) linear-gradient(180deg, rgba(112, 175, 120, 0.49), rgba(24, 125, 28, 0.4)) repeat-x;
  border-color: color-yig(rgba(24, 125, 28, 0.4));
}

.module-larch-40:focus, .module-larch-40.focus {
  color: #fff;
  background: rgba(24, 125, 28, 0.4) linear-gradient(180deg, rgba(112, 175, 120, 0.49), rgba(24, 125, 28, 0.4)) repeat-x;
  border-color: color-yig(rgba(24, 125, 28, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(123, 198, 126, 0.5);
}

.module-larch-40:active, .module-larch-40.active,
.show > .module-larch-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(22, 114, 25, 0.4);
  background-image: none;
  border-color: rgba(20, 103, 23, 0.4);
}

.module-larch-40:active:focus, .module-larch-40.active:focus,
.show > .module-larch-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(123, 198, 126, 0.5);
}

.module-larch-40:disabled, .module-larch-40.disabled {
  color: #fff;
  background-color: rgba(30, 157, 35, 0.4);
  background-image: none;
  border-color: rgba(30, 157, 35, 0.4);
}

.module-larch-50 {
  color: #fff;
  background: rgba(30, 157, 35, 0.5) linear-gradient(180deg, rgba(102, 187, 109, 0.575), rgba(30, 157, 35, 0.5)) repeat-x;
  border-color: rgba(30, 157, 35, 0.5);
}

.module-larch-50 .icon-container {
  background-color: rgba(24, 125, 28, 0.5);
}

.module-larch-50 .icon-container.light {
  background-color: rgba(48, 215, 55, 0.5);
}

.module-larch-50:hover {
  color: #fff;
  background: rgba(24, 125, 28, 0.5) linear-gradient(180deg, rgba(98, 166, 105, 0.575), rgba(24, 125, 28, 0.5)) repeat-x;
  border-color: color-yig(rgba(24, 125, 28, 0.5));
}

.module-larch-50:focus, .module-larch-50.focus {
  color: #fff;
  background: rgba(24, 125, 28, 0.5) linear-gradient(180deg, rgba(98, 166, 105, 0.575), rgba(24, 125, 28, 0.5)) repeat-x;
  border-color: color-yig(rgba(24, 125, 28, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(108, 191, 111, 0.5);
}

.module-larch-50:active, .module-larch-50.active,
.show > .module-larch-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(22, 114, 25, 0.5);
  background-image: none;
  border-color: rgba(20, 103, 23, 0.5);
}

.module-larch-50:active:focus, .module-larch-50.active:focus,
.show > .module-larch-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 191, 111, 0.5);
}

.module-larch-50:disabled, .module-larch-50.disabled {
  color: #fff;
  background-color: rgba(30, 157, 35, 0.5);
  background-image: none;
  border-color: rgba(30, 157, 35, 0.5);
}

.module-larch-60 {
  color: #fff;
  background: rgba(30, 157, 35, 0.6) linear-gradient(180deg, rgba(91, 183, 98, 0.66), rgba(30, 157, 35, 0.6)) repeat-x;
  border-color: rgba(30, 157, 35, 0.6);
}

.module-larch-60 .icon-container {
  background-color: rgba(24, 125, 28, 0.6);
}

.module-larch-60 .icon-container.light {
  background-color: rgba(48, 215, 55, 0.6);
}

.module-larch-60:hover {
  color: #fff;
  background: rgba(24, 125, 28, 0.6) linear-gradient(180deg, rgba(86, 160, 93, 0.66), rgba(24, 125, 28, 0.6)) repeat-x;
  border-color: color-yig(rgba(24, 125, 28, 0.6));
}

.module-larch-60:focus, .module-larch-60.focus {
  color: #fff;
  background: rgba(24, 125, 28, 0.6) linear-gradient(180deg, rgba(86, 160, 93, 0.66), rgba(24, 125, 28, 0.6)) repeat-x;
  border-color: color-yig(rgba(24, 125, 28, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(96, 186, 99, 0.5);
}

.module-larch-60:active, .module-larch-60.active,
.show > .module-larch-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(22, 114, 25, 0.6);
  background-image: none;
  border-color: rgba(20, 103, 23, 0.6);
}

.module-larch-60:active:focus, .module-larch-60.active:focus,
.show > .module-larch-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(96, 186, 99, 0.5);
}

.module-larch-60:disabled, .module-larch-60.disabled {
  color: #fff;
  background-color: rgba(30, 157, 35, 0.6);
  background-image: none;
  border-color: rgba(30, 157, 35, 0.6);
}

.module-larch-70 {
  color: #fff;
  background: rgba(30, 157, 35, 0.7) linear-gradient(180deg, rgba(81, 179, 88, 0.745), rgba(30, 157, 35, 0.7)) repeat-x;
  border-color: rgba(30, 157, 35, 0.7);
}

.module-larch-70 .icon-container {
  background-color: rgba(24, 125, 28, 0.7);
}

.module-larch-70 .icon-container.light {
  background-color: rgba(48, 215, 55, 0.7);
}

.module-larch-70:hover {
  color: #fff;
  background: rgba(24, 125, 28, 0.7) linear-gradient(180deg, rgba(77, 155, 83, 0.745), rgba(24, 125, 28, 0.7)) repeat-x;
  border-color: color-yig(rgba(24, 125, 28, 0.7));
}

.module-larch-70:focus, .module-larch-70.focus {
  color: #fff;
  background: rgba(24, 125, 28, 0.7) linear-gradient(180deg, rgba(77, 155, 83, 0.745), rgba(24, 125, 28, 0.7)) repeat-x;
  border-color: color-yig(rgba(24, 125, 28, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(86, 181, 89, 0.5);
}

.module-larch-70:active, .module-larch-70.active,
.show > .module-larch-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(22, 114, 25, 0.7);
  background-image: none;
  border-color: rgba(20, 103, 23, 0.7);
}

.module-larch-70:active:focus, .module-larch-70.active:focus,
.show > .module-larch-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 181, 89, 0.5);
}

.module-larch-70:disabled, .module-larch-70.disabled {
  color: #fff;
  background-color: rgba(30, 157, 35, 0.7);
  background-image: none;
  border-color: rgba(30, 157, 35, 0.7);
}

.module-larch-80 {
  color: #fff;
  background: rgba(30, 157, 35, 0.8) linear-gradient(180deg, rgba(74, 175, 80, 0.83), rgba(30, 157, 35, 0.8)) repeat-x;
  border-color: rgba(30, 157, 35, 0.8);
}

.module-larch-80 .icon-container {
  background-color: rgba(24, 125, 28, 0.8);
}

.module-larch-80 .icon-container.light {
  background-color: rgba(48, 215, 55, 0.8);
}

.module-larch-80:hover {
  color: #fff;
  background: rgba(24, 125, 28, 0.8) linear-gradient(180deg, rgba(69, 150, 74, 0.83), rgba(24, 125, 28, 0.8)) repeat-x;
  border-color: color-yig(rgba(24, 125, 28, 0.8));
}

.module-larch-80:focus, .module-larch-80.focus {
  color: #fff;
  background: rgba(24, 125, 28, 0.8) linear-gradient(180deg, rgba(69, 150, 74, 0.83), rgba(24, 125, 28, 0.8)) repeat-x;
  border-color: color-yig(rgba(24, 125, 28, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(77, 178, 81, 0.5);
}

.module-larch-80:active, .module-larch-80.active,
.show > .module-larch-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(22, 114, 25, 0.8);
  background-image: none;
  border-color: rgba(20, 103, 23, 0.8);
}

.module-larch-80:active:focus, .module-larch-80.active:focus,
.show > .module-larch-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 178, 81, 0.5);
}

.module-larch-80:disabled, .module-larch-80.disabled {
  color: #fff;
  background-color: rgba(30, 157, 35, 0.8);
  background-image: none;
  border-color: rgba(30, 157, 35, 0.8);
}

.module-larch-90 {
  color: #fff;
  background: rgba(30, 157, 35, 0.9) linear-gradient(180deg, rgba(67, 173, 73, 0.915), rgba(30, 157, 35, 0.9)) repeat-x;
  border-color: rgba(30, 157, 35, 0.9);
}

.module-larch-90 .icon-container {
  background-color: rgba(24, 125, 28, 0.9);
}

.module-larch-90 .icon-container.light {
  background-color: rgba(48, 215, 55, 0.9);
}

.module-larch-90:hover {
  color: #fff;
  background: rgba(24, 125, 28, 0.9) linear-gradient(180deg, rgba(62, 146, 67, 0.915), rgba(24, 125, 28, 0.9)) repeat-x;
  border-color: color-yig(rgba(24, 125, 28, 0.9));
}

.module-larch-90:focus, .module-larch-90.focus {
  color: #fff;
  background: rgba(24, 125, 28, 0.9) linear-gradient(180deg, rgba(62, 146, 67, 0.915), rgba(24, 125, 28, 0.9)) repeat-x;
  border-color: color-yig(rgba(24, 125, 28, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(70, 174, 74, 0.5);
}

.module-larch-90:active, .module-larch-90.active,
.show > .module-larch-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(22, 114, 25, 0.9);
  background-image: none;
  border-color: rgba(20, 103, 23, 0.9);
}

.module-larch-90:active:focus, .module-larch-90.active:focus,
.show > .module-larch-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 174, 74, 0.5);
}

.module-larch-90:disabled, .module-larch-90.disabled {
  color: #fff;
  background-color: rgba(30, 157, 35, 0.9);
  background-image: none;
  border-color: rgba(30, 157, 35, 0.9);
}

.module-gra {
  color: #fff;
  background: #47ca4b linear-gradient(180deg, #60d065, #47ca4b) repeat-x;
  border-color: #47ca4b;
}

.module-gra .icon-container {
  background-color: #34b638;
}

.module-gra .icon-container.light {
  background-color: #82db85;
}

.module-gra:hover {
  color: #fff;
  background: #34b638 linear-gradient(180deg, #50c055, #34b638) repeat-x;
  border-color: color-yig(#34b638);
}

.module-gra:focus, .module-gra.focus {
  color: #fff;
  background: #34b638 linear-gradient(180deg, #50c055, #34b638) repeat-x;
  border-color: color-yig(#34b638);
  box-shadow: 0 0 0 0.2rem rgba(99, 210, 102, 0.5);
}

.module-gra:active, .module-gra.active,
.show > .module-gra.dropdown-toggle {
  color: #fff;
  background-color: #32ac35;
  background-image: none;
  border-color: #2fa232;
}

.module-gra:active:focus, .module-gra.active:focus,
.show > .module-gra.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 210, 102, 0.5);
}

.module-gra:disabled, .module-gra.disabled {
  color: #fff;
  background-color: #47ca4b;
  background-image: none;
  border-color: #47ca4b;
}

.module-gra-10 {
  color: #fff;
  background: rgba(71, 202, 75, 0.1) linear-gradient(180deg, rgba(200, 235, 210, 0.235), rgba(71, 202, 75, 0.1)) repeat-x;
  border-color: rgba(71, 202, 75, 0.1);
}

.module-gra-10 .icon-container {
  background-color: rgba(52, 182, 56, 0.1);
}

.module-gra-10 .icon-container.light {
  background-color: rgba(130, 219, 133, 0.1);
}

.module-gra-10:hover {
  color: #fff;
  background: rgba(52, 182, 56, 0.1) linear-gradient(180deg, rgba(195, 231, 206, 0.235), rgba(52, 182, 56, 0.1)) repeat-x;
  border-color: color-yig(rgba(52, 182, 56, 0.1));
}

.module-gra-10:focus, .module-gra-10.focus {
  color: #fff;
  background: rgba(52, 182, 56, 0.1) linear-gradient(180deg, rgba(195, 231, 206, 0.235), rgba(52, 182, 56, 0.1)) repeat-x;
  border-color: color-yig(rgba(52, 182, 56, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(213, 243, 214, 0.5);
}

.module-gra-10:active, .module-gra-10.active,
.show > .module-gra-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(50, 172, 53, 0.1);
  background-image: none;
  border-color: rgba(47, 162, 50, 0.1);
}

.module-gra-10:active:focus, .module-gra-10.active:focus,
.show > .module-gra-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 243, 214, 0.5);
}

.module-gra-10:disabled, .module-gra-10.disabled {
  color: #fff;
  background-color: rgba(71, 202, 75, 0.1);
  background-image: none;
  border-color: rgba(71, 202, 75, 0.1);
}

.module-gra-20 {
  color: #fff;
  background: rgba(71, 202, 75, 0.2) linear-gradient(180deg, rgba(173, 228, 182, 0.32), rgba(71, 202, 75, 0.2)) repeat-x;
  border-color: rgba(71, 202, 75, 0.2);
}

.module-gra-20 .icon-container {
  background-color: rgba(52, 182, 56, 0.2);
}

.module-gra-20 .icon-container.light {
  background-color: rgba(130, 219, 133, 0.2);
}

.module-gra-20:hover {
  color: #fff;
  background: rgba(52, 182, 56, 0.2) linear-gradient(180deg, rgba(166, 221, 175, 0.32), rgba(52, 182, 56, 0.2)) repeat-x;
  border-color: color-yig(rgba(52, 182, 56, 0.2));
}

.module-gra-20:focus, .module-gra-20.focus {
  color: #fff;
  background: rgba(52, 182, 56, 0.2) linear-gradient(180deg, rgba(166, 221, 175, 0.32), rgba(52, 182, 56, 0.2)) repeat-x;
  border-color: color-yig(rgba(52, 182, 56, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(184, 235, 185, 0.5);
}

.module-gra-20:active, .module-gra-20.active,
.show > .module-gra-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(50, 172, 53, 0.2);
  background-image: none;
  border-color: rgba(47, 162, 50, 0.2);
}

.module-gra-20:active:focus, .module-gra-20.active:focus,
.show > .module-gra-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 235, 185, 0.5);
}

.module-gra-20:disabled, .module-gra-20.disabled {
  color: #fff;
  background-color: rgba(71, 202, 75, 0.2);
  background-image: none;
  border-color: rgba(71, 202, 75, 0.2);
}

.module-gra-30 {
  color: #fff;
  background: rgba(71, 202, 75, 0.3) linear-gradient(180deg, rgba(155, 224, 163, 0.405), rgba(71, 202, 75, 0.3)) repeat-x;
  border-color: rgba(71, 202, 75, 0.3);
}

.module-gra-30 .icon-container {
  background-color: rgba(52, 182, 56, 0.3);
}

.module-gra-30 .icon-container.light {
  background-color: rgba(130, 219, 133, 0.3);
}

.module-gra-30:hover {
  color: #fff;
  background: rgba(52, 182, 56, 0.3) linear-gradient(180deg, rgba(145, 214, 153, 0.405), rgba(52, 182, 56, 0.3)) repeat-x;
  border-color: color-yig(rgba(52, 182, 56, 0.3));
}

.module-gra-30:focus, .module-gra-30.focus {
  color: #fff;
  background: rgba(52, 182, 56, 0.3) linear-gradient(180deg, rgba(145, 214, 153, 0.405), rgba(52, 182, 56, 0.3)) repeat-x;
  border-color: color-yig(rgba(52, 182, 56, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(163, 229, 165, 0.5);
}

.module-gra-30:active, .module-gra-30.active,
.show > .module-gra-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(50, 172, 53, 0.3);
  background-image: none;
  border-color: rgba(47, 162, 50, 0.3);
}

.module-gra-30:active:focus, .module-gra-30.active:focus,
.show > .module-gra-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(163, 229, 165, 0.5);
}

.module-gra-30:disabled, .module-gra-30.disabled {
  color: #fff;
  background-color: rgba(71, 202, 75, 0.3);
  background-image: none;
  border-color: rgba(71, 202, 75, 0.3);
}

.module-gra-40 {
  color: #fff;
  background: rgba(71, 202, 75, 0.4) linear-gradient(180deg, rgba(140, 220, 147, 0.49), rgba(71, 202, 75, 0.4)) repeat-x;
  border-color: rgba(71, 202, 75, 0.4);
}

.module-gra-40 .icon-container {
  background-color: rgba(52, 182, 56, 0.4);
}

.module-gra-40 .icon-container.light {
  background-color: rgba(130, 219, 133, 0.4);
}

.module-gra-40:hover {
  color: #fff;
  background: rgba(52, 182, 56, 0.4) linear-gradient(180deg, rgba(129, 208, 137, 0.49), rgba(52, 182, 56, 0.4)) repeat-x;
  border-color: color-yig(rgba(52, 182, 56, 0.4));
}

.module-gra-40:focus, .module-gra-40.focus {
  color: #fff;
  background: rgba(52, 182, 56, 0.4) linear-gradient(180deg, rgba(129, 208, 137, 0.49), rgba(52, 182, 56, 0.4)) repeat-x;
  border-color: color-yig(rgba(52, 182, 56, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(147, 224, 149, 0.5);
}

.module-gra-40:active, .module-gra-40.active,
.show > .module-gra-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(50, 172, 53, 0.4);
  background-image: none;
  border-color: rgba(47, 162, 50, 0.4);
}

.module-gra-40:active:focus, .module-gra-40.active:focus,
.show > .module-gra-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 224, 149, 0.5);
}

.module-gra-40:disabled, .module-gra-40.disabled {
  color: #fff;
  background-color: rgba(71, 202, 75, 0.4);
  background-image: none;
  border-color: rgba(71, 202, 75, 0.4);
}

.module-gra-50 {
  color: #fff;
  background: rgba(71, 202, 75, 0.5) linear-gradient(180deg, rgba(129, 217, 136, 0.575), rgba(71, 202, 75, 0.5)) repeat-x;
  border-color: rgba(71, 202, 75, 0.5);
}

.module-gra-50 .icon-container {
  background-color: rgba(52, 182, 56, 0.5);
}

.module-gra-50 .icon-container.light {
  background-color: rgba(130, 219, 133, 0.5);
}

.module-gra-50:hover {
  color: #fff;
  background: rgba(52, 182, 56, 0.5) linear-gradient(180deg, rgba(117, 204, 123, 0.575), rgba(52, 182, 56, 0.5)) repeat-x;
  border-color: color-yig(rgba(52, 182, 56, 0.5));
}

.module-gra-50:focus, .module-gra-50.focus {
  color: #fff;
  background: rgba(52, 182, 56, 0.5) linear-gradient(180deg, rgba(117, 204, 123, 0.575), rgba(52, 182, 56, 0.5)) repeat-x;
  border-color: color-yig(rgba(52, 182, 56, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(135, 220, 137, 0.5);
}

.module-gra-50:active, .module-gra-50.active,
.show > .module-gra-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(50, 172, 53, 0.5);
  background-image: none;
  border-color: rgba(47, 162, 50, 0.5);
}

.module-gra-50:active:focus, .module-gra-50.active:focus,
.show > .module-gra-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(135, 220, 137, 0.5);
}

.module-gra-50:disabled, .module-gra-50.disabled {
  color: #fff;
  background-color: rgba(71, 202, 75, 0.5);
  background-image: none;
  border-color: rgba(71, 202, 75, 0.5);
}

.module-gra-60 {
  color: #fff;
  background: rgba(71, 202, 75, 0.6) linear-gradient(180deg, rgba(120, 215, 126, 0.66), rgba(71, 202, 75, 0.6)) repeat-x;
  border-color: rgba(71, 202, 75, 0.6);
}

.module-gra-60 .icon-container {
  background-color: rgba(52, 182, 56, 0.6);
}

.module-gra-60 .icon-container.light {
  background-color: rgba(130, 219, 133, 0.6);
}

.module-gra-60:hover {
  color: #fff;
  background: rgba(52, 182, 56, 0.6) linear-gradient(180deg, rgba(107, 201, 113, 0.66), rgba(52, 182, 56, 0.6)) repeat-x;
  border-color: color-yig(rgba(52, 182, 56, 0.6));
}

.module-gra-60:focus, .module-gra-60.focus {
  color: #fff;
  background: rgba(52, 182, 56, 0.6) linear-gradient(180deg, rgba(107, 201, 113, 0.66), rgba(52, 182, 56, 0.6)) repeat-x;
  border-color: color-yig(rgba(52, 182, 56, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(125, 217, 128, 0.5);
}

.module-gra-60:active, .module-gra-60.active,
.show > .module-gra-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(50, 172, 53, 0.6);
  background-image: none;
  border-color: rgba(47, 162, 50, 0.6);
}

.module-gra-60:active:focus, .module-gra-60.active:focus,
.show > .module-gra-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 217, 128, 0.5);
}

.module-gra-60:disabled, .module-gra-60.disabled {
  color: #fff;
  background-color: rgba(71, 202, 75, 0.6);
  background-image: none;
  border-color: rgba(71, 202, 75, 0.6);
}

.module-gra-70 {
  color: #fff;
  background: rgba(71, 202, 75, 0.7) linear-gradient(180deg, rgba(112, 213, 118, 0.745), rgba(71, 202, 75, 0.7)) repeat-x;
  border-color: rgba(71, 202, 75, 0.7);
}

.module-gra-70 .icon-container {
  background-color: rgba(52, 182, 56, 0.7);
}

.module-gra-70 .icon-container.light {
  background-color: rgba(130, 219, 133, 0.7);
}

.module-gra-70:hover {
  color: #fff;
  background: rgba(52, 182, 56, 0.7) linear-gradient(180deg, rgba(98, 198, 104, 0.745), rgba(52, 182, 56, 0.7)) repeat-x;
  border-color: color-yig(rgba(52, 182, 56, 0.7));
}

.module-gra-70:focus, .module-gra-70.focus {
  color: #fff;
  background: rgba(52, 182, 56, 0.7) linear-gradient(180deg, rgba(98, 198, 104, 0.745), rgba(52, 182, 56, 0.7)) repeat-x;
  border-color: color-yig(rgba(52, 182, 56, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(116, 215, 119, 0.5);
}

.module-gra-70:active, .module-gra-70.active,
.show > .module-gra-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(50, 172, 53, 0.7);
  background-image: none;
  border-color: rgba(47, 162, 50, 0.7);
}

.module-gra-70:active:focus, .module-gra-70.active:focus,
.show > .module-gra-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(116, 215, 119, 0.5);
}

.module-gra-70:disabled, .module-gra-70.disabled {
  color: #fff;
  background-color: rgba(71, 202, 75, 0.7);
  background-image: none;
  border-color: rgba(71, 202, 75, 0.7);
}

.module-gra-80 {
  color: #fff;
  background: rgba(71, 202, 75, 0.8) linear-gradient(180deg, rgba(106, 211, 112, 0.83), rgba(71, 202, 75, 0.8)) repeat-x;
  border-color: rgba(71, 202, 75, 0.8);
}

.module-gra-80 .icon-container {
  background-color: rgba(52, 182, 56, 0.8);
}

.module-gra-80 .icon-container.light {
  background-color: rgba(130, 219, 133, 0.8);
}

.module-gra-80:hover {
  color: #fff;
  background: rgba(52, 182, 56, 0.8) linear-gradient(180deg, rgba(91, 195, 97, 0.83), rgba(52, 182, 56, 0.8)) repeat-x;
  border-color: color-yig(rgba(52, 182, 56, 0.8));
}

.module-gra-80:focus, .module-gra-80.focus {
  color: #fff;
  background: rgba(52, 182, 56, 0.8) linear-gradient(180deg, rgba(91, 195, 97, 0.83), rgba(52, 182, 56, 0.8)) repeat-x;
  border-color: color-yig(rgba(52, 182, 56, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(110, 213, 113, 0.5);
}

.module-gra-80:active, .module-gra-80.active,
.show > .module-gra-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(50, 172, 53, 0.8);
  background-image: none;
  border-color: rgba(47, 162, 50, 0.8);
}

.module-gra-80:active:focus, .module-gra-80.active:focus,
.show > .module-gra-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(110, 213, 113, 0.5);
}

.module-gra-80:disabled, .module-gra-80.disabled {
  color: #fff;
  background-color: rgba(71, 202, 75, 0.8);
  background-image: none;
  border-color: rgba(71, 202, 75, 0.8);
}

.module-gra-90 {
  color: #fff;
  background: rgba(71, 202, 75, 0.9) linear-gradient(180deg, rgba(101, 210, 106, 0.915), rgba(71, 202, 75, 0.9)) repeat-x;
  border-color: rgba(71, 202, 75, 0.9);
}

.module-gra-90 .icon-container {
  background-color: rgba(52, 182, 56, 0.9);
}

.module-gra-90 .icon-container.light {
  background-color: rgba(130, 219, 133, 0.9);
}

.module-gra-90:hover {
  color: #fff;
  background: rgba(52, 182, 56, 0.9) linear-gradient(180deg, rgba(85, 193, 91, 0.915), rgba(52, 182, 56, 0.9)) repeat-x;
  border-color: color-yig(rgba(52, 182, 56, 0.9));
}

.module-gra-90:focus, .module-gra-90.focus {
  color: #fff;
  background: rgba(52, 182, 56, 0.9) linear-gradient(180deg, rgba(85, 193, 91, 0.915), rgba(52, 182, 56, 0.9)) repeat-x;
  border-color: color-yig(rgba(52, 182, 56, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(104, 211, 107, 0.5);
}

.module-gra-90:active, .module-gra-90.active,
.show > .module-gra-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(50, 172, 53, 0.9);
  background-image: none;
  border-color: rgba(47, 162, 50, 0.9);
}

.module-gra-90:active:focus, .module-gra-90.active:focus,
.show > .module-gra-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(104, 211, 107, 0.5);
}

.module-gra-90:disabled, .module-gra-90.disabled {
  color: #fff;
  background-color: rgba(71, 202, 75, 0.9);
  background-image: none;
  border-color: rgba(71, 202, 75, 0.9);
}

.module-fern {
  color: #273a4d;
  background: #7adf7e linear-gradient(180deg, #8be291, #7adf7e) repeat-x;
  border-color: #7adf7e;
}

.module-fern .icon-container {
  background-color: #5bd860;
}

.module-fern .icon-container.light {
  background-color: #b8eeba;
}

.module-fern:hover {
  color: #273a4d;
  background: #5bd860 linear-gradient(180deg, #71dc77, #5bd860) repeat-x;
  border-color: color-yig(#5bd860);
}

.module-fern:focus, .module-fern.focus {
  color: #273a4d;
  background: #5bd860 linear-gradient(180deg, #71dc77, #5bd860) repeat-x;
  border-color: color-yig(#5bd860);
  box-shadow: 0 0 0 0.2rem rgba(110, 198, 119, 0.5);
}

.module-fern:active, .module-fern.active,
.show > .module-fern.dropdown-toggle {
  color: #273a4d;
  background-color: #51d556;
  background-image: none;
  border-color: #47d34c;
}

.module-fern:active:focus, .module-fern.active:focus,
.show > .module-fern.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(110, 198, 119, 0.5);
}

.module-fern:disabled, .module-fern.disabled {
  color: #273a4d;
  background-color: #7adf7e;
  background-image: none;
  border-color: #7adf7e;
}

.module-fern-10 {
  color: #273a4d;
  background: rgba(122, 223, 126, 0.1) linear-gradient(180deg, rgba(211, 240, 222, 0.235), rgba(122, 223, 126, 0.1)) repeat-x;
  border-color: rgba(122, 223, 126, 0.1);
}

.module-fern-10 .icon-container {
  background-color: rgba(91, 216, 96, 0.1);
}

.module-fern-10 .icon-container.light {
  background-color: rgba(184, 238, 186, 0.1);
}

.module-fern-10:hover {
  color: #273a4d;
  background: rgba(91, 216, 96, 0.1) linear-gradient(180deg, rgba(204, 238, 215, 0.235), rgba(91, 216, 96, 0.1)) repeat-x;
  border-color: color-yig(rgba(91, 216, 96, 0.1));
}

.module-fern-10:focus, .module-fern-10.focus {
  color: #273a4d;
  background: rgba(91, 216, 96, 0.1) linear-gradient(180deg, rgba(204, 238, 215, 0.235), rgba(91, 216, 96, 0.1)) repeat-x;
  border-color: color-yig(rgba(91, 216, 96, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(58, 96, 88, 0.5);
}

.module-fern-10:active, .module-fern-10.active,
.show > .module-fern-10.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(81, 213, 86, 0.1);
  background-image: none;
  border-color: rgba(71, 211, 76, 0.1);
}

.module-fern-10:active:focus, .module-fern-10.active:focus,
.show > .module-fern-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 96, 88, 0.5);
}

.module-fern-10:disabled, .module-fern-10.disabled {
  color: #273a4d;
  background-color: rgba(122, 223, 126, 0.1);
  background-image: none;
  border-color: rgba(122, 223, 126, 0.1);
}

.module-fern-20 {
  color: #273a4d;
  background: rgba(122, 223, 126, 0.2) linear-gradient(180deg, rgba(193, 237, 202, 0.32), rgba(122, 223, 126, 0.2)) repeat-x;
  border-color: rgba(122, 223, 126, 0.2);
}

.module-fern-20 .icon-container {
  background-color: rgba(91, 216, 96, 0.2);
}

.module-fern-20 .icon-container.light {
  background-color: rgba(184, 238, 186, 0.2);
}

.module-fern-20:hover {
  color: #273a4d;
  background: rgba(91, 216, 96, 0.2) linear-gradient(180deg, rgba(181, 234, 191, 0.32), rgba(91, 216, 96, 0.2)) repeat-x;
  border-color: color-yig(rgba(91, 216, 96, 0.2));
}

.module-fern-20:focus, .module-fern-20.focus {
  color: #273a4d;
  background: rgba(91, 216, 96, 0.2) linear-gradient(180deg, rgba(181, 234, 191, 0.32), rgba(91, 216, 96, 0.2)) repeat-x;
  border-color: color-yig(rgba(91, 216, 96, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(71, 122, 96, 0.5);
}

.module-fern-20:active, .module-fern-20.active,
.show > .module-fern-20.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(81, 213, 86, 0.2);
  background-image: none;
  border-color: rgba(71, 211, 76, 0.2);
}

.module-fern-20:active:focus, .module-fern-20.active:focus,
.show > .module-fern-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 122, 96, 0.5);
}

.module-fern-20:disabled, .module-fern-20.disabled {
  color: #273a4d;
  background-color: rgba(122, 223, 126, 0.2);
  background-image: none;
  border-color: rgba(122, 223, 126, 0.2);
}

.module-fern-30 {
  color: #273a4d;
  background: rgba(122, 223, 126, 0.3) linear-gradient(180deg, rgba(180, 234, 188, 0.405), rgba(122, 223, 126, 0.3)) repeat-x;
  border-color: rgba(122, 223, 126, 0.3);
}

.module-fern-30 .icon-container {
  background-color: rgba(91, 216, 96, 0.3);
}

.module-fern-30 .icon-container.light {
  background-color: rgba(184, 238, 186, 0.3);
}

.module-fern-30:hover {
  color: #273a4d;
  background: rgba(91, 216, 96, 0.3) linear-gradient(180deg, rgba(165, 230, 173, 0.405), rgba(91, 216, 96, 0.3)) repeat-x;
  border-color: color-yig(rgba(91, 216, 96, 0.3));
}

.module-fern-30:focus, .module-fern-30.focus {
  color: #273a4d;
  background: rgba(91, 216, 96, 0.3) linear-gradient(180deg, rgba(165, 230, 173, 0.405), rgba(91, 216, 96, 0.3)) repeat-x;
  border-color: color-yig(rgba(91, 216, 96, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(81, 141, 102, 0.5);
}

.module-fern-30:active, .module-fern-30.active,
.show > .module-fern-30.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(81, 213, 86, 0.3);
  background-image: none;
  border-color: rgba(71, 211, 76, 0.3);
}

.module-fern-30:active:focus, .module-fern-30.active:focus,
.show > .module-fern-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(81, 141, 102, 0.5);
}

.module-fern-30:disabled, .module-fern-30.disabled {
  color: #273a4d;
  background-color: rgba(122, 223, 126, 0.3);
  background-image: none;
  border-color: rgba(122, 223, 126, 0.3);
}

.module-fern-40 {
  color: #273a4d;
  background: rgba(122, 223, 126, 0.4) linear-gradient(180deg, rgba(170, 232, 177, 0.49), rgba(122, 223, 126, 0.4)) repeat-x;
  border-color: rgba(122, 223, 126, 0.4);
}

.module-fern-40 .icon-container {
  background-color: rgba(91, 216, 96, 0.4);
}

.module-fern-40 .icon-container.light {
  background-color: rgba(184, 238, 186, 0.4);
}

.module-fern-40:hover {
  color: #273a4d;
  background: rgba(91, 216, 96, 0.4) linear-gradient(180deg, rgba(152, 228, 160, 0.49), rgba(91, 216, 96, 0.4)) repeat-x;
  border-color: color-yig(rgba(91, 216, 96, 0.4));
}

.module-fern-40:focus, .module-fern-40.focus {
  color: #273a4d;
  background: rgba(91, 216, 96, 0.4) linear-gradient(180deg, rgba(152, 228, 160, 0.49), rgba(91, 216, 96, 0.4)) repeat-x;
  border-color: color-yig(rgba(91, 216, 96, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(88, 155, 106, 0.5);
}

.module-fern-40:active, .module-fern-40.active,
.show > .module-fern-40.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(81, 213, 86, 0.4);
  background-image: none;
  border-color: rgba(71, 211, 76, 0.4);
}

.module-fern-40:active:focus, .module-fern-40.active:focus,
.show > .module-fern-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 155, 106, 0.5);
}

.module-fern-40:disabled, .module-fern-40.disabled {
  color: #273a4d;
  background-color: rgba(122, 223, 126, 0.4);
  background-image: none;
  border-color: rgba(122, 223, 126, 0.4);
}

.module-fern-50 {
  color: #273a4d;
  background: rgba(122, 223, 126, 0.5) linear-gradient(180deg, rgba(162, 231, 169, 0.575), rgba(122, 223, 126, 0.5)) repeat-x;
  border-color: rgba(122, 223, 126, 0.5);
}

.module-fern-50 .icon-container {
  background-color: rgba(91, 216, 96, 0.5);
}

.module-fern-50 .icon-container.light {
  background-color: rgba(184, 238, 186, 0.5);
}

.module-fern-50:hover {
  color: #273a4d;
  background: rgba(91, 216, 96, 0.5) linear-gradient(180deg, rgba(142, 226, 149, 0.575), rgba(91, 216, 96, 0.5)) repeat-x;
  border-color: color-yig(rgba(91, 216, 96, 0.5));
}

.module-fern-50:focus, .module-fern-50.focus {
  color: #273a4d;
  background: rgba(91, 216, 96, 0.5) linear-gradient(180deg, rgba(142, 226, 149, 0.575), rgba(91, 216, 96, 0.5)) repeat-x;
  border-color: color-yig(rgba(91, 216, 96, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(93, 166, 109, 0.5);
}

.module-fern-50:active, .module-fern-50.active,
.show > .module-fern-50.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(81, 213, 86, 0.5);
  background-image: none;
  border-color: rgba(71, 211, 76, 0.5);
}

.module-fern-50:active:focus, .module-fern-50.active:focus,
.show > .module-fern-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(93, 166, 109, 0.5);
}

.module-fern-50:disabled, .module-fern-50.disabled {
  color: #273a4d;
  background-color: rgba(122, 223, 126, 0.5);
  background-image: none;
  border-color: rgba(122, 223, 126, 0.5);
}

.module-fern-60 {
  color: #273a4d;
  background: rgba(122, 223, 126, 0.6) linear-gradient(180deg, rgba(156, 229, 162, 0.66), rgba(122, 223, 126, 0.6)) repeat-x;
  border-color: rgba(122, 223, 126, 0.6);
}

.module-fern-60 .icon-container {
  background-color: rgba(91, 216, 96, 0.6);
}

.module-fern-60 .icon-container.light {
  background-color: rgba(184, 238, 186, 0.6);
}

.module-fern-60:hover {
  color: #273a4d;
  background: rgba(91, 216, 96, 0.6) linear-gradient(180deg, rgba(134, 224, 141, 0.66), rgba(91, 216, 96, 0.6)) repeat-x;
  border-color: color-yig(rgba(91, 216, 96, 0.6));
}

.module-fern-60:focus, .module-fern-60.focus {
  color: #273a4d;
  background: rgba(91, 216, 96, 0.6) linear-gradient(180deg, rgba(134, 224, 141, 0.66), rgba(91, 216, 96, 0.6)) repeat-x;
  border-color: color-yig(rgba(91, 216, 96, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(98, 175, 112, 0.5);
}

.module-fern-60:active, .module-fern-60.active,
.show > .module-fern-60.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(81, 213, 86, 0.6);
  background-image: none;
  border-color: rgba(71, 211, 76, 0.6);
}

.module-fern-60:active:focus, .module-fern-60.active:focus,
.show > .module-fern-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(98, 175, 112, 0.5);
}

.module-fern-60:disabled, .module-fern-60.disabled {
  color: #273a4d;
  background-color: rgba(122, 223, 126, 0.6);
  background-image: none;
  border-color: rgba(122, 223, 126, 0.6);
}

.module-fern-70 {
  color: #273a4d;
  background: rgba(122, 223, 126, 0.7) linear-gradient(180deg, rgba(151, 228, 157, 0.745), rgba(122, 223, 126, 0.7)) repeat-x;
  border-color: rgba(122, 223, 126, 0.7);
}

.module-fern-70 .icon-container {
  background-color: rgba(91, 216, 96, 0.7);
}

.module-fern-70 .icon-container.light {
  background-color: rgba(184, 238, 186, 0.7);
}

.module-fern-70:hover {
  color: #273a4d;
  background: rgba(91, 216, 96, 0.7) linear-gradient(180deg, rgba(127, 223, 134, 0.745), rgba(91, 216, 96, 0.7)) repeat-x;
  border-color: color-yig(rgba(91, 216, 96, 0.7));
}

.module-fern-70:focus, .module-fern-70.focus {
  color: #273a4d;
  background: rgba(91, 216, 96, 0.7) linear-gradient(180deg, rgba(127, 223, 134, 0.745), rgba(91, 216, 96, 0.7)) repeat-x;
  border-color: color-yig(rgba(91, 216, 96, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(102, 182, 114, 0.5);
}

.module-fern-70:active, .module-fern-70.active,
.show > .module-fern-70.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(81, 213, 86, 0.7);
  background-image: none;
  border-color: rgba(71, 211, 76, 0.7);
}

.module-fern-70:active:focus, .module-fern-70.active:focus,
.show > .module-fern-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 182, 114, 0.5);
}

.module-fern-70:disabled, .module-fern-70.disabled {
  color: #273a4d;
  background-color: rgba(122, 223, 126, 0.7);
  background-image: none;
  border-color: rgba(122, 223, 126, 0.7);
}

.module-fern-80 {
  color: #273a4d;
  background: rgba(122, 223, 126, 0.8) linear-gradient(180deg, rgba(146, 228, 152, 0.83), rgba(122, 223, 126, 0.8)) repeat-x;
  border-color: rgba(122, 223, 126, 0.8);
}

.module-fern-80 .icon-container {
  background-color: rgba(91, 216, 96, 0.8);
}

.module-fern-80 .icon-container.light {
  background-color: rgba(184, 238, 186, 0.8);
}

.module-fern-80:hover {
  color: #273a4d;
  background: rgba(91, 216, 96, 0.8) linear-gradient(180deg, rgba(122, 222, 128, 0.83), rgba(91, 216, 96, 0.8)) repeat-x;
  border-color: color-yig(rgba(91, 216, 96, 0.8));
}

.module-fern-80:focus, .module-fern-80.focus {
  color: #273a4d;
  background: rgba(91, 216, 96, 0.8) linear-gradient(180deg, rgba(122, 222, 128, 0.83), rgba(91, 216, 96, 0.8)) repeat-x;
  border-color: color-yig(rgba(91, 216, 96, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(105, 188, 116, 0.5);
}

.module-fern-80:active, .module-fern-80.active,
.show > .module-fern-80.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(81, 213, 86, 0.8);
  background-image: none;
  border-color: rgba(71, 211, 76, 0.8);
}

.module-fern-80:active:focus, .module-fern-80.active:focus,
.show > .module-fern-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 188, 116, 0.5);
}

.module-fern-80:disabled, .module-fern-80.disabled {
  color: #273a4d;
  background-color: rgba(122, 223, 126, 0.8);
  background-image: none;
  border-color: rgba(122, 223, 126, 0.8);
}

.module-fern-90 {
  color: #273a4d;
  background: rgba(122, 223, 126, 0.9) linear-gradient(180deg, rgba(143, 227, 148, 0.915), rgba(122, 223, 126, 0.9)) repeat-x;
  border-color: rgba(122, 223, 126, 0.9);
}

.module-fern-90 .icon-container {
  background-color: rgba(91, 216, 96, 0.9);
}

.module-fern-90 .icon-container.light {
  background-color: rgba(184, 238, 186, 0.9);
}

.module-fern-90:hover {
  color: #273a4d;
  background: rgba(91, 216, 96, 0.9) linear-gradient(180deg, rgba(117, 221, 123, 0.915), rgba(91, 216, 96, 0.9)) repeat-x;
  border-color: color-yig(rgba(91, 216, 96, 0.9));
}

.module-fern-90:focus, .module-fern-90.focus {
  color: #273a4d;
  background: rgba(91, 216, 96, 0.9) linear-gradient(180deg, rgba(117, 221, 123, 0.915), rgba(91, 216, 96, 0.9)) repeat-x;
  border-color: color-yig(rgba(91, 216, 96, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(107, 194, 117, 0.5);
}

.module-fern-90:active, .module-fern-90.active,
.show > .module-fern-90.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(81, 213, 86, 0.9);
  background-image: none;
  border-color: rgba(71, 211, 76, 0.9);
}

.module-fern-90:active:focus, .module-fern-90.active:focus,
.show > .module-fern-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(107, 194, 117, 0.5);
}

.module-fern-90:disabled, .module-fern-90.disabled {
  color: #273a4d;
  background-color: rgba(122, 223, 126, 0.9);
  background-image: none;
  border-color: rgba(122, 223, 126, 0.9);
}

.module-min {
  color: #273a4d;
  background: #beefbf linear-gradient(180deg, #c5f0c8, #beefbf) repeat-x;
  border-color: #beefbf;
}

.module-min .icon-container {
  background-color: #9fe7a1;
}

.module-min .icon-container.light {
  background-color: #fbfefb;
}

.module-min:hover {
  color: #273a4d;
  background: #9fe7a1 linear-gradient(180deg, #abe9ae, #9fe7a1) repeat-x;
  border-color: color-yig(#9fe7a1);
}

.module-min:focus, .module-min.focus {
  color: #273a4d;
  background: #9fe7a1 linear-gradient(180deg, #abe9ae, #9fe7a1) repeat-x;
  border-color: color-yig(#9fe7a1);
  box-shadow: 0 0 0 0.2rem rgba(167, 212, 174, 0.5);
}

.module-min:active, .module-min.active,
.show > .module-min.dropdown-toggle {
  color: #273a4d;
  background-color: #95e597;
  background-image: none;
  border-color: #8be28d;
}

.module-min:active:focus, .module-min.active:focus,
.show > .module-min.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(167, 212, 174, 0.5);
}

.module-min:disabled, .module-min.disabled {
  color: #273a4d;
  background-color: #beefbf;
  background-image: none;
  border-color: #beefbf;
}

.module-min-10 {
  color: #273a4d;
  background: rgba(190, 239, 191, 0.1) linear-gradient(180deg, rgba(227, 244, 236, 0.235), rgba(190, 239, 191, 0.1)) repeat-x;
  border-color: rgba(190, 239, 191, 0.1);
}

.module-min-10 .icon-container {
  background-color: rgba(159, 231, 161, 0.1);
}

.module-min-10 .icon-container.light {
  background-color: rgba(251, 254, 251, 0.1);
}

.module-min-10:hover {
  color: #273a4d;
  background: rgba(159, 231, 161, 0.1) linear-gradient(180deg, rgba(220, 242, 230, 0.235), rgba(159, 231, 161, 0.1)) repeat-x;
  border-color: color-yig(rgba(159, 231, 161, 0.1));
}

.module-min-10:focus, .module-min-10.focus {
  color: #273a4d;
  background: rgba(159, 231, 161, 0.1) linear-gradient(180deg, rgba(220, 242, 230, 0.235), rgba(159, 231, 161, 0.1)) repeat-x;
  border-color: color-yig(rgba(159, 231, 161, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(74, 100, 103, 0.5);
}

.module-min-10:active, .module-min-10.active,
.show > .module-min-10.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(149, 229, 151, 0.1);
  background-image: none;
  border-color: rgba(139, 226, 141, 0.1);
}

.module-min-10:active:focus, .module-min-10.active:focus,
.show > .module-min-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(74, 100, 103, 0.5);
}

.module-min-10:disabled, .module-min-10.disabled {
  color: #273a4d;
  background-color: rgba(190, 239, 191, 0.1);
  background-image: none;
  border-color: rgba(190, 239, 191, 0.1);
}

.module-min-20 {
  color: #273a4d;
  background: rgba(190, 239, 191, 0.2) linear-gradient(180deg, rgba(219, 243, 227, 0.32), rgba(190, 239, 191, 0.2)) repeat-x;
  border-color: rgba(190, 239, 191, 0.2);
}

.module-min-20 .icon-container {
  background-color: rgba(159, 231, 161, 0.2);
}

.module-min-20 .icon-container.light {
  background-color: rgba(251, 254, 251, 0.2);
}

.module-min-20:hover {
  color: #273a4d;
  background: rgba(159, 231, 161, 0.2) linear-gradient(180deg, rgba(208, 240, 216, 0.32), rgba(159, 231, 161, 0.2)) repeat-x;
  border-color: color-yig(rgba(159, 231, 161, 0.2));
}

.module-min-20:focus, .module-min-20.focus {
  color: #273a4d;
  background: rgba(159, 231, 161, 0.2) linear-gradient(180deg, rgba(208, 240, 216, 0.32), rgba(159, 231, 161, 0.2)) repeat-x;
  border-color: color-yig(rgba(159, 231, 161, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(97, 128, 121, 0.5);
}

.module-min-20:active, .module-min-20.active,
.show > .module-min-20.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(149, 229, 151, 0.2);
  background-image: none;
  border-color: rgba(139, 226, 141, 0.2);
}

.module-min-20:active:focus, .module-min-20.active:focus,
.show > .module-min-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(97, 128, 121, 0.5);
}

.module-min-20:disabled, .module-min-20.disabled {
  color: #273a4d;
  background-color: rgba(190, 239, 191, 0.2);
  background-image: none;
  border-color: rgba(190, 239, 191, 0.2);
}

.module-min-30 {
  color: #273a4d;
  background: rgba(190, 239, 191, 0.3) linear-gradient(180deg, rgba(214, 242, 221, 0.405), rgba(190, 239, 191, 0.3)) repeat-x;
  border-color: rgba(190, 239, 191, 0.3);
}

.module-min-30 .icon-container {
  background-color: rgba(159, 231, 161, 0.3);
}

.module-min-30 .icon-container.light {
  background-color: rgba(251, 254, 251, 0.3);
}

.module-min-30:hover {
  color: #273a4d;
  background: rgba(159, 231, 161, 0.3) linear-gradient(180deg, rgba(199, 238, 205, 0.405), rgba(159, 231, 161, 0.3)) repeat-x;
  border-color: color-yig(rgba(159, 231, 161, 0.3));
}

.module-min-30:focus, .module-min-30.focus {
  color: #273a4d;
  background: rgba(159, 231, 161, 0.3) linear-gradient(180deg, rgba(199, 238, 205, 0.405), rgba(159, 231, 161, 0.3)) repeat-x;
  border-color: color-yig(rgba(159, 231, 161, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(115, 149, 134, 0.5);
}

.module-min-30:active, .module-min-30.active,
.show > .module-min-30.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(149, 229, 151, 0.3);
  background-image: none;
  border-color: rgba(139, 226, 141, 0.3);
}

.module-min-30:active:focus, .module-min-30.active:focus,
.show > .module-min-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(115, 149, 134, 0.5);
}

.module-min-30:disabled, .module-min-30.disabled {
  color: #273a4d;
  background-color: rgba(190, 239, 191, 0.3);
  background-image: none;
  border-color: rgba(190, 239, 191, 0.3);
}

.module-min-40 {
  color: #273a4d;
  background: rgba(190, 239, 191, 0.4) linear-gradient(180deg, rgba(210, 241, 215, 0.49), rgba(190, 239, 191, 0.4)) repeat-x;
  border-color: rgba(190, 239, 191, 0.4);
}

.module-min-40 .icon-container {
  background-color: rgba(159, 231, 161, 0.4);
}

.module-min-40 .icon-container.light {
  background-color: rgba(251, 254, 251, 0.4);
}

.module-min-40:hover {
  color: #273a4d;
  background: rgba(159, 231, 161, 0.4) linear-gradient(180deg, rgba(192, 237, 198, 0.49), rgba(159, 231, 161, 0.4)) repeat-x;
  border-color: color-yig(rgba(159, 231, 161, 0.4));
}

.module-min-40:focus, .module-min-40.focus {
  color: #273a4d;
  background: rgba(159, 231, 161, 0.4) linear-gradient(180deg, rgba(192, 237, 198, 0.49), rgba(159, 231, 161, 0.4)) repeat-x;
  border-color: color-yig(rgba(159, 231, 161, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(128, 164, 144, 0.5);
}

.module-min-40:active, .module-min-40.active,
.show > .module-min-40.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(149, 229, 151, 0.4);
  background-image: none;
  border-color: rgba(139, 226, 141, 0.4);
}

.module-min-40:active:focus, .module-min-40.active:focus,
.show > .module-min-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 164, 144, 0.5);
}

.module-min-40:disabled, .module-min-40.disabled {
  color: #273a4d;
  background-color: rgba(190, 239, 191, 0.4);
  background-image: none;
  border-color: rgba(190, 239, 191, 0.4);
}

.module-min-50 {
  color: #273a4d;
  background: rgba(190, 239, 191, 0.5) linear-gradient(180deg, rgba(207, 241, 211, 0.575), rgba(190, 239, 191, 0.5)) repeat-x;
  border-color: rgba(190, 239, 191, 0.5);
}

.module-min-50 .icon-container {
  background-color: rgba(159, 231, 161, 0.5);
}

.module-min-50 .icon-container.light {
  background-color: rgba(251, 254, 251, 0.5);
}

.module-min-50:hover {
  color: #273a4d;
  background: rgba(159, 231, 161, 0.5) linear-gradient(180deg, rgba(187, 236, 192, 0.575), rgba(159, 231, 161, 0.5)) repeat-x;
  border-color: color-yig(rgba(159, 231, 161, 0.5));
}

.module-min-50:focus, .module-min-50.focus {
  color: #273a4d;
  background: rgba(159, 231, 161, 0.5) linear-gradient(180deg, rgba(187, 236, 192, 0.575), rgba(159, 231, 161, 0.5)) repeat-x;
  border-color: color-yig(rgba(159, 231, 161, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(138, 176, 152, 0.5);
}

.module-min-50:active, .module-min-50.active,
.show > .module-min-50.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(149, 229, 151, 0.5);
  background-image: none;
  border-color: rgba(139, 226, 141, 0.5);
}

.module-min-50:active:focus, .module-min-50.active:focus,
.show > .module-min-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(138, 176, 152, 0.5);
}

.module-min-50:disabled, .module-min-50.disabled {
  color: #273a4d;
  background-color: rgba(190, 239, 191, 0.5);
  background-image: none;
  border-color: rgba(190, 239, 191, 0.5);
}

.module-min-60 {
  color: #273a4d;
  background: rgba(190, 239, 191, 0.6) linear-gradient(180deg, rgba(204, 241, 208, 0.66), rgba(190, 239, 191, 0.6)) repeat-x;
  border-color: rgba(190, 239, 191, 0.6);
}

.module-min-60 .icon-container {
  background-color: rgba(159, 231, 161, 0.6);
}

.module-min-60 .icon-container.light {
  background-color: rgba(251, 254, 251, 0.6);
}

.module-min-60:hover {
  color: #273a4d;
  background: rgba(159, 231, 161, 0.6) linear-gradient(180deg, rgba(182, 235, 187, 0.66), rgba(159, 231, 161, 0.6)) repeat-x;
  border-color: color-yig(rgba(159, 231, 161, 0.6));
}

.module-min-60:focus, .module-min-60.focus {
  color: #273a4d;
  background: rgba(159, 231, 161, 0.6) linear-gradient(180deg, rgba(182, 235, 187, 0.66), rgba(159, 231, 161, 0.6)) repeat-x;
  border-color: color-yig(rgba(159, 231, 161, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(146, 186, 158, 0.5);
}

.module-min-60:active, .module-min-60.active,
.show > .module-min-60.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(149, 229, 151, 0.6);
  background-image: none;
  border-color: rgba(139, 226, 141, 0.6);
}

.module-min-60:active:focus, .module-min-60.active:focus,
.show > .module-min-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(146, 186, 158, 0.5);
}

.module-min-60:disabled, .module-min-60.disabled {
  color: #273a4d;
  background-color: rgba(190, 239, 191, 0.6);
  background-image: none;
  border-color: rgba(190, 239, 191, 0.6);
}

.module-min-70 {
  color: #273a4d;
  background: rgba(190, 239, 191, 0.7) linear-gradient(180deg, rgba(202, 240, 206, 0.745), rgba(190, 239, 191, 0.7)) repeat-x;
  border-color: rgba(190, 239, 191, 0.7);
}

.module-min-70 .icon-container {
  background-color: rgba(159, 231, 161, 0.7);
}

.module-min-70 .icon-container.light {
  background-color: rgba(251, 254, 251, 0.7);
}

.module-min-70:hover {
  color: #273a4d;
  background: rgba(159, 231, 161, 0.7) linear-gradient(180deg, rgba(179, 235, 183, 0.745), rgba(159, 231, 161, 0.7)) repeat-x;
  border-color: color-yig(rgba(159, 231, 161, 0.7));
}

.module-min-70:focus, .module-min-70.focus {
  color: #273a4d;
  background: rgba(159, 231, 161, 0.7) linear-gradient(180deg, rgba(179, 235, 183, 0.745), rgba(159, 231, 161, 0.7)) repeat-x;
  border-color: color-yig(rgba(159, 231, 161, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(153, 194, 163, 0.5);
}

.module-min-70:active, .module-min-70.active,
.show > .module-min-70.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(149, 229, 151, 0.7);
  background-image: none;
  border-color: rgba(139, 226, 141, 0.7);
}

.module-min-70:active:focus, .module-min-70.active:focus,
.show > .module-min-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(153, 194, 163, 0.5);
}

.module-min-70:disabled, .module-min-70.disabled {
  color: #273a4d;
  background-color: rgba(190, 239, 191, 0.7);
  background-image: none;
  border-color: rgba(190, 239, 191, 0.7);
}

.module-min-80 {
  color: #273a4d;
  background: rgba(190, 239, 191, 0.8) linear-gradient(180deg, rgba(200, 240, 203, 0.83), rgba(190, 239, 191, 0.8)) repeat-x;
  border-color: rgba(190, 239, 191, 0.8);
}

.module-min-80 .icon-container {
  background-color: rgba(159, 231, 161, 0.8);
}

.module-min-80 .icon-container.light {
  background-color: rgba(251, 254, 251, 0.8);
}

.module-min-80:hover {
  color: #273a4d;
  background: rgba(159, 231, 161, 0.8) linear-gradient(180deg, rgba(176, 234, 179, 0.83), rgba(159, 231, 161, 0.8)) repeat-x;
  border-color: color-yig(rgba(159, 231, 161, 0.8));
}

.module-min-80:focus, .module-min-80.focus {
  color: #273a4d;
  background: rgba(159, 231, 161, 0.8) linear-gradient(180deg, rgba(176, 234, 179, 0.83), rgba(159, 231, 161, 0.8)) repeat-x;
  border-color: color-yig(rgba(159, 231, 161, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(158, 201, 167, 0.5);
}

.module-min-80:active, .module-min-80.active,
.show > .module-min-80.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(149, 229, 151, 0.8);
  background-image: none;
  border-color: rgba(139, 226, 141, 0.8);
}

.module-min-80:active:focus, .module-min-80.active:focus,
.show > .module-min-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(158, 201, 167, 0.5);
}

.module-min-80:disabled, .module-min-80.disabled {
  color: #273a4d;
  background-color: rgba(190, 239, 191, 0.8);
  background-image: none;
  border-color: rgba(190, 239, 191, 0.8);
}

.module-min-90 {
  color: #273a4d;
  background: rgba(190, 239, 191, 0.9) linear-gradient(180deg, rgba(199, 240, 201, 0.915), rgba(190, 239, 191, 0.9)) repeat-x;
  border-color: rgba(190, 239, 191, 0.9);
}

.module-min-90 .icon-container {
  background-color: rgba(159, 231, 161, 0.9);
}

.module-min-90 .icon-container.light {
  background-color: rgba(251, 254, 251, 0.9);
}

.module-min-90:hover {
  color: #273a4d;
  background: rgba(159, 231, 161, 0.9) linear-gradient(180deg, rgba(173, 234, 177, 0.915), rgba(159, 231, 161, 0.9)) repeat-x;
  border-color: color-yig(rgba(159, 231, 161, 0.9));
}

.module-min-90:focus, .module-min-90.focus {
  color: #273a4d;
  background: rgba(159, 231, 161, 0.9) linear-gradient(180deg, rgba(173, 234, 177, 0.915), rgba(159, 231, 161, 0.9)) repeat-x;
  border-color: color-yig(rgba(159, 231, 161, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(163, 207, 171, 0.5);
}

.module-min-90:active, .module-min-90.active,
.show > .module-min-90.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(149, 229, 151, 0.9);
  background-image: none;
  border-color: rgba(139, 226, 141, 0.9);
}

.module-min-90:active:focus, .module-min-90.active:focus,
.show > .module-min-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(163, 207, 171, 0.5);
}

.module-min-90:disabled, .module-min-90.disabled {
  color: #273a4d;
  background-color: rgba(190, 239, 191, 0.9);
  background-image: none;
  border-color: rgba(190, 239, 191, 0.9);
}

.module-seaweed {
  color: #fff;
  background: #3a401c linear-gradient(180deg, #555b3d, #3a401c) repeat-x;
  border-color: #3a401c;
}

.module-seaweed .icon-container {
  background-color: #222510;
}

.module-seaweed .icon-container.light {
  background-color: #6a7533;
}

.module-seaweed:hover {
  color: #fff;
  background: #222510 linear-gradient(180deg, #414533, #222510) repeat-x;
  border-color: color-yig(#222510);
}

.module-seaweed:focus, .module-seaweed.focus {
  color: #fff;
  background: #222510 linear-gradient(180deg, #414533, #222510) repeat-x;
  border-color: color-yig(#222510);
  box-shadow: 0 0 0 0.2rem rgba(88, 93, 62, 0.5);
}

.module-seaweed:active, .module-seaweed.active,
.show > .module-seaweed.dropdown-toggle {
  color: #fff;
  background-color: #1a1d0c;
  background-image: none;
  border-color: #121409;
}

.module-seaweed:active:focus, .module-seaweed.active:focus,
.show > .module-seaweed.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 93, 62, 0.5);
}

.module-seaweed:disabled, .module-seaweed.disabled {
  color: #fff;
  background-color: #3a401c;
  background-image: none;
  border-color: #3a401c;
}

.module-seaweed-10 {
  color: #fff;
  background: rgba(58, 64, 28, 0.1) linear-gradient(180deg, rgba(197, 203, 199, 0.235), rgba(58, 64, 28, 0.1)) repeat-x;
  border-color: rgba(58, 64, 28, 0.1);
}

.module-seaweed-10 .icon-container {
  background-color: rgba(34, 37, 16, 0.1);
}

.module-seaweed-10 .icon-container.light {
  background-color: rgba(106, 117, 51, 0.1);
}

.module-seaweed-10:hover {
  color: #fff;
  background: rgba(34, 37, 16, 0.1) linear-gradient(180deg, rgba(191, 197, 196, 0.235), rgba(34, 37, 16, 0.1)) repeat-x;
  border-color: color-yig(rgba(34, 37, 16, 0.1));
}

.module-seaweed-10:focus, .module-seaweed-10.focus {
  color: #fff;
  background: rgba(34, 37, 16, 0.1) linear-gradient(180deg, rgba(191, 197, 196, 0.235), rgba(34, 37, 16, 0.1)) repeat-x;
  border-color: color-yig(rgba(34, 37, 16, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(210, 211, 203, 0.5);
}

.module-seaweed-10:active, .module-seaweed-10.active,
.show > .module-seaweed-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(26, 29, 12, 0.1);
  background-image: none;
  border-color: rgba(18, 20, 9, 0.1);
}

.module-seaweed-10:active:focus, .module-seaweed-10.active:focus,
.show > .module-seaweed-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 211, 203, 0.5);
}

.module-seaweed-10:disabled, .module-seaweed-10.disabled {
  color: #fff;
  background-color: rgba(58, 64, 28, 0.1);
  background-image: none;
  border-color: rgba(58, 64, 28, 0.1);
}

.module-seaweed-20 {
  color: #fff;
  background: rgba(58, 64, 28, 0.2) linear-gradient(180deg, rgba(168, 175, 164, 0.32), rgba(58, 64, 28, 0.2)) repeat-x;
  border-color: rgba(58, 64, 28, 0.2);
}

.module-seaweed-20 .icon-container {
  background-color: rgba(34, 37, 16, 0.2);
}

.module-seaweed-20 .icon-container.light {
  background-color: rgba(106, 117, 51, 0.2);
}

.module-seaweed-20:hover {
  color: #fff;
  background: rgba(34, 37, 16, 0.2) linear-gradient(180deg, rgba(159, 165, 160, 0.32), rgba(34, 37, 16, 0.2)) repeat-x;
  border-color: color-yig(rgba(34, 37, 16, 0.2));
}

.module-seaweed-20:focus, .module-seaweed-20.focus {
  color: #fff;
  background: rgba(34, 37, 16, 0.2) linear-gradient(180deg, rgba(159, 165, 160, 0.32), rgba(34, 37, 16, 0.2)) repeat-x;
  border-color: color-yig(rgba(34, 37, 16, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(179, 181, 167, 0.5);
}

.module-seaweed-20:active, .module-seaweed-20.active,
.show > .module-seaweed-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(26, 29, 12, 0.2);
  background-image: none;
  border-color: rgba(18, 20, 9, 0.2);
}

.module-seaweed-20:active:focus, .module-seaweed-20.active:focus,
.show > .module-seaweed-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(179, 181, 167, 0.5);
}

.module-seaweed-20:disabled, .module-seaweed-20.disabled {
  color: #fff;
  background-color: rgba(58, 64, 28, 0.2);
  background-image: none;
  border-color: rgba(58, 64, 28, 0.2);
}

.module-seaweed-30 {
  color: #fff;
  background: rgba(58, 64, 28, 0.3) linear-gradient(180deg, rgba(148, 155, 139, 0.405), rgba(58, 64, 28, 0.3)) repeat-x;
  border-color: rgba(58, 64, 28, 0.3);
}

.module-seaweed-30 .icon-container {
  background-color: rgba(34, 37, 16, 0.3);
}

.module-seaweed-30 .icon-container.light {
  background-color: rgba(106, 117, 51, 0.3);
}

.module-seaweed-30:hover {
  color: #fff;
  background: rgba(34, 37, 16, 0.3) linear-gradient(180deg, rgba(136, 141, 133, 0.405), rgba(34, 37, 16, 0.3)) repeat-x;
  border-color: color-yig(rgba(34, 37, 16, 0.3));
}

.module-seaweed-30:focus, .module-seaweed-30.focus {
  color: #fff;
  background: rgba(34, 37, 16, 0.3) linear-gradient(180deg, rgba(136, 141, 133, 0.405), rgba(34, 37, 16, 0.3)) repeat-x;
  border-color: color-yig(rgba(34, 37, 16, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(157, 160, 142, 0.5);
}

.module-seaweed-30:active, .module-seaweed-30.active,
.show > .module-seaweed-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(26, 29, 12, 0.3);
  background-image: none;
  border-color: rgba(18, 20, 9, 0.3);
}

.module-seaweed-30:active:focus, .module-seaweed-30.active:focus,
.show > .module-seaweed-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(157, 160, 142, 0.5);
}

.module-seaweed-30:disabled, .module-seaweed-30.disabled {
  color: #fff;
  background-color: rgba(58, 64, 28, 0.3);
  background-image: none;
  border-color: rgba(58, 64, 28, 0.3);
}

.module-seaweed-40 {
  color: #fff;
  background: rgba(58, 64, 28, 0.4) linear-gradient(180deg, rgba(132, 139, 120, 0.49), rgba(58, 64, 28, 0.4)) repeat-x;
  border-color: rgba(58, 64, 28, 0.4);
}

.module-seaweed-40 .icon-container {
  background-color: rgba(34, 37, 16, 0.4);
}

.module-seaweed-40 .icon-container.light {
  background-color: rgba(106, 117, 51, 0.4);
}

.module-seaweed-40:hover {
  color: #fff;
  background: rgba(34, 37, 16, 0.4) linear-gradient(180deg, rgba(118, 123, 113, 0.49), rgba(34, 37, 16, 0.4)) repeat-x;
  border-color: color-yig(rgba(34, 37, 16, 0.4));
}

.module-seaweed-40:focus, .module-seaweed-40.focus {
  color: #fff;
  background: rgba(34, 37, 16, 0.4) linear-gradient(180deg, rgba(118, 123, 113, 0.49), rgba(34, 37, 16, 0.4)) repeat-x;
  border-color: color-yig(rgba(34, 37, 16, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(140, 143, 122, 0.5);
}

.module-seaweed-40:active, .module-seaweed-40.active,
.show > .module-seaweed-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(26, 29, 12, 0.4);
  background-image: none;
  border-color: rgba(18, 20, 9, 0.4);
}

.module-seaweed-40:active:focus, .module-seaweed-40.active:focus,
.show > .module-seaweed-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(140, 143, 122, 0.5);
}

.module-seaweed-40:disabled, .module-seaweed-40.disabled {
  color: #fff;
  background-color: rgba(58, 64, 28, 0.4);
  background-image: none;
  border-color: rgba(58, 64, 28, 0.4);
}

.module-seaweed-50 {
  color: #fff;
  background: rgba(58, 64, 28, 0.5) linear-gradient(180deg, rgba(120, 127, 105, 0.575), rgba(58, 64, 28, 0.5)) repeat-x;
  border-color: rgba(58, 64, 28, 0.5);
}

.module-seaweed-50 .icon-container {
  background-color: rgba(34, 37, 16, 0.5);
}

.module-seaweed-50 .icon-container.light {
  background-color: rgba(106, 117, 51, 0.5);
}

.module-seaweed-50:hover {
  color: #fff;
  background: rgba(34, 37, 16, 0.5) linear-gradient(180deg, rgba(105, 109, 97, 0.575), rgba(34, 37, 16, 0.5)) repeat-x;
  border-color: color-yig(rgba(34, 37, 16, 0.5));
}

.module-seaweed-50:focus, .module-seaweed-50.focus {
  color: #fff;
  background: rgba(34, 37, 16, 0.5) linear-gradient(180deg, rgba(105, 109, 97, 0.575), rgba(34, 37, 16, 0.5)) repeat-x;
  border-color: color-yig(rgba(34, 37, 16, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(126, 130, 107, 0.5);
}

.module-seaweed-50:active, .module-seaweed-50.active,
.show > .module-seaweed-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(26, 29, 12, 0.5);
  background-image: none;
  border-color: rgba(18, 20, 9, 0.5);
}

.module-seaweed-50:active:focus, .module-seaweed-50.active:focus,
.show > .module-seaweed-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(126, 130, 107, 0.5);
}

.module-seaweed-50:disabled, .module-seaweed-50.disabled {
  color: #fff;
  background-color: rgba(58, 64, 28, 0.5);
  background-image: none;
  border-color: rgba(58, 64, 28, 0.5);
}

.module-seaweed-60 {
  color: #fff;
  background: rgba(58, 64, 28, 0.6) linear-gradient(180deg, rgba(111, 117, 93, 0.66), rgba(58, 64, 28, 0.6)) repeat-x;
  border-color: rgba(58, 64, 28, 0.6);
}

.module-seaweed-60 .icon-container {
  background-color: rgba(34, 37, 16, 0.6);
}

.module-seaweed-60 .icon-container.light {
  background-color: rgba(106, 117, 51, 0.6);
}

.module-seaweed-60:hover {
  color: #fff;
  background: rgba(34, 37, 16, 0.6) linear-gradient(180deg, rgba(93, 98, 85, 0.66), rgba(34, 37, 16, 0.6)) repeat-x;
  border-color: color-yig(rgba(34, 37, 16, 0.6));
}

.module-seaweed-60:focus, .module-seaweed-60.focus {
  color: #fff;
  background: rgba(34, 37, 16, 0.6) linear-gradient(180deg, rgba(93, 98, 85, 0.66), rgba(34, 37, 16, 0.6)) repeat-x;
  border-color: color-yig(rgba(34, 37, 16, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(115, 120, 94, 0.5);
}

.module-seaweed-60:active, .module-seaweed-60.active,
.show > .module-seaweed-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(26, 29, 12, 0.6);
  background-image: none;
  border-color: rgba(18, 20, 9, 0.6);
}

.module-seaweed-60:active:focus, .module-seaweed-60.active:focus,
.show > .module-seaweed-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(115, 120, 94, 0.5);
}

.module-seaweed-60:disabled, .module-seaweed-60.disabled {
  color: #fff;
  background-color: rgba(58, 64, 28, 0.6);
  background-image: none;
  border-color: rgba(58, 64, 28, 0.6);
}

.module-seaweed-70 {
  color: #fff;
  background: rgba(58, 64, 28, 0.7) linear-gradient(180deg, rgba(102, 109, 83, 0.745), rgba(58, 64, 28, 0.7)) repeat-x;
  border-color: rgba(58, 64, 28, 0.7);
}

.module-seaweed-70 .icon-container {
  background-color: rgba(34, 37, 16, 0.7);
}

.module-seaweed-70 .icon-container.light {
  background-color: rgba(106, 117, 51, 0.7);
}

.module-seaweed-70:hover {
  color: #fff;
  background: rgba(34, 37, 16, 0.7) linear-gradient(180deg, rgba(84, 89, 74, 0.745), rgba(34, 37, 16, 0.7)) repeat-x;
  border-color: color-yig(rgba(34, 37, 16, 0.7));
}

.module-seaweed-70:focus, .module-seaweed-70.focus {
  color: #fff;
  background: rgba(34, 37, 16, 0.7) linear-gradient(180deg, rgba(84, 89, 74, 0.745), rgba(34, 37, 16, 0.7)) repeat-x;
  border-color: color-yig(rgba(34, 37, 16, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(107, 111, 84, 0.5);
}

.module-seaweed-70:active, .module-seaweed-70.active,
.show > .module-seaweed-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(26, 29, 12, 0.7);
  background-image: none;
  border-color: rgba(18, 20, 9, 0.7);
}

.module-seaweed-70:active:focus, .module-seaweed-70.active:focus,
.show > .module-seaweed-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(107, 111, 84, 0.5);
}

.module-seaweed-70:disabled, .module-seaweed-70.disabled {
  color: #fff;
  background-color: rgba(58, 64, 28, 0.7);
  background-image: none;
  border-color: rgba(58, 64, 28, 0.7);
}

.module-seaweed-80 {
  color: #fff;
  background: rgba(58, 64, 28, 0.8) linear-gradient(180deg, rgba(96, 102, 74, 0.83), rgba(58, 64, 28, 0.8)) repeat-x;
  border-color: rgba(58, 64, 28, 0.8);
}

.module-seaweed-80 .icon-container {
  background-color: rgba(34, 37, 16, 0.8);
}

.module-seaweed-80 .icon-container.light {
  background-color: rgba(106, 117, 51, 0.8);
}

.module-seaweed-80:hover {
  color: #fff;
  background: rgba(34, 37, 16, 0.8) linear-gradient(180deg, rgba(77, 81, 65, 0.83), rgba(34, 37, 16, 0.8)) repeat-x;
  border-color: color-yig(rgba(34, 37, 16, 0.8));
}

.module-seaweed-80:focus, .module-seaweed-80.focus {
  color: #fff;
  background: rgba(34, 37, 16, 0.8) linear-gradient(180deg, rgba(77, 81, 65, 0.83), rgba(34, 37, 16, 0.8)) repeat-x;
  border-color: color-yig(rgba(34, 37, 16, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(99, 104, 76, 0.5);
}

.module-seaweed-80:active, .module-seaweed-80.active,
.show > .module-seaweed-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(26, 29, 12, 0.8);
  background-image: none;
  border-color: rgba(18, 20, 9, 0.8);
}

.module-seaweed-80:active:focus, .module-seaweed-80.active:focus,
.show > .module-seaweed-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 104, 76, 0.5);
}

.module-seaweed-80:disabled, .module-seaweed-80.disabled {
  color: #fff;
  background-color: rgba(58, 64, 28, 0.8);
  background-image: none;
  border-color: rgba(58, 64, 28, 0.8);
}

.module-seaweed-90 {
  color: #fff;
  background: rgba(58, 64, 28, 0.9) linear-gradient(180deg, rgba(90, 96, 67, 0.915), rgba(58, 64, 28, 0.9)) repeat-x;
  border-color: rgba(58, 64, 28, 0.9);
}

.module-seaweed-90 .icon-container {
  background-color: rgba(34, 37, 16, 0.9);
}

.module-seaweed-90 .icon-container.light {
  background-color: rgba(106, 117, 51, 0.9);
}

.module-seaweed-90:hover {
  color: #fff;
  background: rgba(34, 37, 16, 0.9) linear-gradient(180deg, rgba(70, 74, 58, 0.915), rgba(34, 37, 16, 0.9)) repeat-x;
  border-color: color-yig(rgba(34, 37, 16, 0.9));
}

.module-seaweed-90:focus, .module-seaweed-90.focus {
  color: #fff;
  background: rgba(34, 37, 16, 0.9) linear-gradient(180deg, rgba(70, 74, 58, 0.915), rgba(34, 37, 16, 0.9)) repeat-x;
  border-color: color-yig(rgba(34, 37, 16, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(93, 98, 68, 0.5);
}

.module-seaweed-90:active, .module-seaweed-90.active,
.show > .module-seaweed-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(26, 29, 12, 0.9);
  background-image: none;
  border-color: rgba(18, 20, 9, 0.9);
}

.module-seaweed-90:active:focus, .module-seaweed-90.active:focus,
.show > .module-seaweed-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(93, 98, 68, 0.5);
}

.module-seaweed-90:disabled, .module-seaweed-90.disabled {
  color: #fff;
  background-color: rgba(58, 64, 28, 0.9);
  background-image: none;
  border-color: rgba(58, 64, 28, 0.9);
}

.module-army {
  color: #fff;
  background: #515c1d linear-gradient(180deg, #69733e, #515c1d) repeat-x;
  border-color: #515c1d;
}

.module-army .icon-container {
  background-color: #373f14;
}

.module-army .icon-container.light {
  background-color: #84962f;
}

.module-army:hover {
  color: #fff;
  background: #373f14 linear-gradient(180deg, #535a36, #373f14) repeat-x;
  border-color: color-yig(#373f14);
}

.module-army:focus, .module-army.focus {
  color: #fff;
  background: #373f14 linear-gradient(180deg, #535a36, #373f14) repeat-x;
  border-color: color-yig(#373f14);
  box-shadow: 0 0 0 0.2rem rgba(107, 116, 63, 0.5);
}

.module-army:active, .module-army.active,
.show > .module-army.dropdown-toggle {
  color: #fff;
  background-color: #2f3511;
  background-image: none;
  border-color: #262c0e;
}

.module-army:active:focus, .module-army.active:focus,
.show > .module-army.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(107, 116, 63, 0.5);
}

.module-army:disabled, .module-army.disabled {
  color: #fff;
  background-color: #515c1d;
  background-image: none;
  border-color: #515c1d;
}

.module-army-10 {
  color: #fff;
  background: rgba(81, 92, 29, 0.1) linear-gradient(180deg, rgba(202, 210, 199, 0.235), rgba(81, 92, 29, 0.1)) repeat-x;
  border-color: rgba(81, 92, 29, 0.1);
}

.module-army-10 .icon-container {
  background-color: rgba(55, 63, 20, 0.1);
}

.module-army-10 .icon-container.light {
  background-color: rgba(132, 150, 47, 0.1);
}

.module-army-10:hover {
  color: #fff;
  background: rgba(55, 63, 20, 0.1) linear-gradient(180deg, rgba(196, 203, 197, 0.235), rgba(55, 63, 20, 0.1)) repeat-x;
  border-color: color-yig(rgba(55, 63, 20, 0.1));
}

.module-army-10:focus, .module-army-10.focus {
  color: #fff;
  background: rgba(55, 63, 20, 0.1) linear-gradient(180deg, rgba(196, 203, 197, 0.235), rgba(55, 63, 20, 0.1)) repeat-x;
  border-color: color-yig(rgba(55, 63, 20, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(215, 218, 203, 0.5);
}

.module-army-10:active, .module-army-10.active,
.show > .module-army-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(47, 53, 17, 0.1);
  background-image: none;
  border-color: rgba(38, 44, 14, 0.1);
}

.module-army-10:active:focus, .module-army-10.active:focus,
.show > .module-army-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 218, 203, 0.5);
}

.module-army-10:disabled, .module-army-10.disabled {
  color: #fff;
  background-color: rgba(81, 92, 29, 0.1);
  background-image: none;
  border-color: rgba(81, 92, 29, 0.1);
}

.module-army-20 {
  color: #fff;
  background: rgba(81, 92, 29, 0.2) linear-gradient(180deg, rgba(177, 186, 165, 0.32), rgba(81, 92, 29, 0.2)) repeat-x;
  border-color: rgba(81, 92, 29, 0.2);
}

.module-army-20 .icon-container {
  background-color: rgba(55, 63, 20, 0.2);
}

.module-army-20 .icon-container.light {
  background-color: rgba(132, 150, 47, 0.2);
}

.module-army-20:hover {
  color: #fff;
  background: rgba(55, 63, 20, 0.2) linear-gradient(180deg, rgba(167, 175, 161, 0.32), rgba(55, 63, 20, 0.2)) repeat-x;
  border-color: color-yig(rgba(55, 63, 20, 0.2));
}

.module-army-20:focus, .module-army-20.focus {
  color: #fff;
  background: rgba(55, 63, 20, 0.2) linear-gradient(180deg, rgba(167, 175, 161, 0.32), rgba(55, 63, 20, 0.2)) repeat-x;
  border-color: color-yig(rgba(55, 63, 20, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(188, 192, 168, 0.5);
}

.module-army-20:active, .module-army-20.active,
.show > .module-army-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(47, 53, 17, 0.2);
  background-image: none;
  border-color: rgba(38, 44, 14, 0.2);
}

.module-army-20:active:focus, .module-army-20.active:focus,
.show > .module-army-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(188, 192, 168, 0.5);
}

.module-army-20:disabled, .module-army-20.disabled {
  color: #fff;
  background-color: rgba(81, 92, 29, 0.2);
  background-image: none;
  border-color: rgba(81, 92, 29, 0.2);
}

.module-army-30 {
  color: #fff;
  background: rgba(81, 92, 29, 0.3) linear-gradient(180deg, rgba(160, 169, 140, 0.405), rgba(81, 92, 29, 0.3)) repeat-x;
  border-color: rgba(81, 92, 29, 0.3);
}

.module-army-30 .icon-container {
  background-color: rgba(55, 63, 20, 0.3);
}

.module-army-30 .icon-container.light {
  background-color: rgba(132, 150, 47, 0.3);
}

.module-army-30:hover {
  color: #fff;
  background: rgba(55, 63, 20, 0.3) linear-gradient(180deg, rgba(147, 154, 135, 0.405), rgba(55, 63, 20, 0.3)) repeat-x;
  border-color: color-yig(rgba(55, 63, 20, 0.3));
}

.module-army-30:focus, .module-army-30.focus {
  color: #fff;
  background: rgba(55, 63, 20, 0.3) linear-gradient(180deg, rgba(147, 154, 135, 0.405), rgba(55, 63, 20, 0.3)) repeat-x;
  border-color: color-yig(rgba(55, 63, 20, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(168, 174, 142, 0.5);
}

.module-army-30:active, .module-army-30.active,
.show > .module-army-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(47, 53, 17, 0.3);
  background-image: none;
  border-color: rgba(38, 44, 14, 0.3);
}

.module-army-30:active:focus, .module-army-30.active:focus,
.show > .module-army-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(168, 174, 142, 0.5);
}

.module-army-30:disabled, .module-army-30.disabled {
  color: #fff;
  background-color: rgba(81, 92, 29, 0.3);
  background-image: none;
  border-color: rgba(81, 92, 29, 0.3);
}

.module-army-40 {
  color: #fff;
  background: rgba(81, 92, 29, 0.4) linear-gradient(180deg, rgba(146, 155, 120, 0.49), rgba(81, 92, 29, 0.4)) repeat-x;
  border-color: rgba(81, 92, 29, 0.4);
}

.module-army-40 .icon-container {
  background-color: rgba(55, 63, 20, 0.4);
}

.module-army-40 .icon-container.light {
  background-color: rgba(132, 150, 47, 0.4);
}

.module-army-40:hover {
  color: #fff;
  background: rgba(55, 63, 20, 0.4) linear-gradient(180deg, rgba(131, 138, 115, 0.49), rgba(55, 63, 20, 0.4)) repeat-x;
  border-color: color-yig(rgba(55, 63, 20, 0.4));
}

.module-army-40:focus, .module-army-40.focus {
  color: #fff;
  background: rgba(55, 63, 20, 0.4) linear-gradient(180deg, rgba(131, 138, 115, 0.49), rgba(55, 63, 20, 0.4)) repeat-x;
  border-color: color-yig(rgba(55, 63, 20, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(153, 159, 123, 0.5);
}

.module-army-40:active, .module-army-40.active,
.show > .module-army-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(47, 53, 17, 0.4);
  background-image: none;
  border-color: rgba(38, 44, 14, 0.4);
}

.module-army-40:active:focus, .module-army-40.active:focus,
.show > .module-army-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(153, 159, 123, 0.5);
}

.module-army-40:disabled, .module-army-40.disabled {
  color: #fff;
  background-color: rgba(81, 92, 29, 0.4);
  background-image: none;
  border-color: rgba(81, 92, 29, 0.4);
}

.module-army-50 {
  color: #fff;
  background: rgba(81, 92, 29, 0.5) linear-gradient(180deg, rgba(135, 145, 106, 0.575), rgba(81, 92, 29, 0.5)) repeat-x;
  border-color: rgba(81, 92, 29, 0.5);
}

.module-army-50 .icon-container {
  background-color: rgba(55, 63, 20, 0.5);
}

.module-army-50 .icon-container.light {
  background-color: rgba(132, 150, 47, 0.5);
}

.module-army-50:hover {
  color: #fff;
  background: rgba(55, 63, 20, 0.5) linear-gradient(180deg, rgba(119, 126, 100, 0.575), rgba(55, 63, 20, 0.5)) repeat-x;
  border-color: color-yig(rgba(55, 63, 20, 0.5));
}

.module-army-50:focus, .module-army-50.focus {
  color: #fff;
  background: rgba(55, 63, 20, 0.5) linear-gradient(180deg, rgba(119, 126, 100, 0.575), rgba(55, 63, 20, 0.5)) repeat-x;
  border-color: color-yig(rgba(55, 63, 20, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(141, 148, 107, 0.5);
}

.module-army-50:active, .module-army-50.active,
.show > .module-army-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(47, 53, 17, 0.5);
  background-image: none;
  border-color: rgba(38, 44, 14, 0.5);
}

.module-army-50:active:focus, .module-army-50.active:focus,
.show > .module-army-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(141, 148, 107, 0.5);
}

.module-army-50:disabled, .module-army-50.disabled {
  color: #fff;
  background-color: rgba(81, 92, 29, 0.5);
  background-image: none;
  border-color: rgba(81, 92, 29, 0.5);
}

.module-army-60 {
  color: #fff;
  background: rgba(81, 92, 29, 0.6) linear-gradient(180deg, rgba(127, 137, 93, 0.66), rgba(81, 92, 29, 0.6)) repeat-x;
  border-color: rgba(81, 92, 29, 0.6);
}

.module-army-60 .icon-container {
  background-color: rgba(55, 63, 20, 0.6);
}

.module-army-60 .icon-container.light {
  background-color: rgba(132, 150, 47, 0.6);
}

.module-army-60:hover {
  color: #fff;
  background: rgba(55, 63, 20, 0.6) linear-gradient(180deg, rgba(109, 116, 87, 0.66), rgba(55, 63, 20, 0.6)) repeat-x;
  border-color: color-yig(rgba(55, 63, 20, 0.6));
}

.module-army-60:focus, .module-army-60.focus {
  color: #fff;
  background: rgba(55, 63, 20, 0.6) linear-gradient(180deg, rgba(109, 116, 87, 0.66), rgba(55, 63, 20, 0.6)) repeat-x;
  border-color: color-yig(rgba(55, 63, 20, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(132, 140, 95, 0.5);
}

.module-army-60:active, .module-army-60.active,
.show > .module-army-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(47, 53, 17, 0.6);
  background-image: none;
  border-color: rgba(38, 44, 14, 0.6);
}

.module-army-60:active:focus, .module-army-60.active:focus,
.show > .module-army-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(132, 140, 95, 0.5);
}

.module-army-60:disabled, .module-army-60.disabled {
  color: #fff;
  background-color: rgba(81, 92, 29, 0.6);
  background-image: none;
  border-color: rgba(81, 92, 29, 0.6);
}

.module-army-70 {
  color: #fff;
  background: rgba(81, 92, 29, 0.7) linear-gradient(180deg, rgba(120, 130, 84, 0.745), rgba(81, 92, 29, 0.7)) repeat-x;
  border-color: rgba(81, 92, 29, 0.7);
}

.module-army-70 .icon-container {
  background-color: rgba(55, 63, 20, 0.7);
}

.module-army-70 .icon-container.light {
  background-color: rgba(132, 150, 47, 0.7);
}

.module-army-70:hover {
  color: #fff;
  background: rgba(55, 63, 20, 0.7) linear-gradient(180deg, rgba(100, 108, 77, 0.745), rgba(55, 63, 20, 0.7)) repeat-x;
  border-color: color-yig(rgba(55, 63, 20, 0.7));
}

.module-army-70:focus, .module-army-70.focus {
  color: #fff;
  background: rgba(55, 63, 20, 0.7) linear-gradient(180deg, rgba(100, 108, 77, 0.745), rgba(55, 63, 20, 0.7)) repeat-x;
  border-color: color-yig(rgba(55, 63, 20, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(124, 132, 85, 0.5);
}

.module-army-70:active, .module-army-70.active,
.show > .module-army-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(47, 53, 17, 0.7);
  background-image: none;
  border-color: rgba(38, 44, 14, 0.7);
}

.module-army-70:active:focus, .module-army-70.active:focus,
.show > .module-army-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(124, 132, 85, 0.5);
}

.module-army-70:disabled, .module-army-70.disabled {
  color: #fff;
  background-color: rgba(81, 92, 29, 0.7);
  background-image: none;
  border-color: rgba(81, 92, 29, 0.7);
}

.module-army-80 {
  color: #fff;
  background: rgba(81, 92, 29, 0.8) linear-gradient(180deg, rgba(114, 124, 75, 0.83), rgba(81, 92, 29, 0.8)) repeat-x;
  border-color: rgba(81, 92, 29, 0.8);
}

.module-army-80 .icon-container {
  background-color: rgba(55, 63, 20, 0.8);
}

.module-army-80 .icon-container.light {
  background-color: rgba(132, 150, 47, 0.8);
}

.module-army-80:hover {
  color: #fff;
  background: rgba(55, 63, 20, 0.8) linear-gradient(180deg, rgba(94, 101, 68, 0.83), rgba(55, 63, 20, 0.8)) repeat-x;
  border-color: color-yig(rgba(55, 63, 20, 0.8));
}

.module-army-80:focus, .module-army-80.focus {
  color: #fff;
  background: rgba(55, 63, 20, 0.8) linear-gradient(180deg, rgba(94, 101, 68, 0.83), rgba(55, 63, 20, 0.8)) repeat-x;
  border-color: color-yig(rgba(55, 63, 20, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(117, 126, 76, 0.5);
}

.module-army-80:active, .module-army-80.active,
.show > .module-army-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(47, 53, 17, 0.8);
  background-image: none;
  border-color: rgba(38, 44, 14, 0.8);
}

.module-army-80:active:focus, .module-army-80.active:focus,
.show > .module-army-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(117, 126, 76, 0.5);
}

.module-army-80:disabled, .module-army-80.disabled {
  color: #fff;
  background-color: rgba(81, 92, 29, 0.8);
  background-image: none;
  border-color: rgba(81, 92, 29, 0.8);
}

.module-army-90 {
  color: #fff;
  background: rgba(81, 92, 29, 0.9) linear-gradient(180deg, rgba(109, 119, 68, 0.915), rgba(81, 92, 29, 0.9)) repeat-x;
  border-color: rgba(81, 92, 29, 0.9);
}

.module-army-90 .icon-container {
  background-color: rgba(55, 63, 20, 0.9);
}

.module-army-90 .icon-container.light {
  background-color: rgba(132, 150, 47, 0.9);
}

.module-army-90:hover {
  color: #fff;
  background: rgba(55, 63, 20, 0.9) linear-gradient(180deg, rgba(88, 95, 61, 0.915), rgba(55, 63, 20, 0.9)) repeat-x;
  border-color: color-yig(rgba(55, 63, 20, 0.9));
}

.module-army-90:focus, .module-army-90.focus {
  color: #fff;
  background: rgba(55, 63, 20, 0.9) linear-gradient(180deg, rgba(88, 95, 61, 0.915), rgba(55, 63, 20, 0.9)) repeat-x;
  border-color: color-yig(rgba(55, 63, 20, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(112, 121, 69, 0.5);
}

.module-army-90:active, .module-army-90.active,
.show > .module-army-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(47, 53, 17, 0.9);
  background-image: none;
  border-color: rgba(38, 44, 14, 0.9);
}

.module-army-90:active:focus, .module-army-90.active:focus,
.show > .module-army-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(112, 121, 69, 0.5);
}

.module-army-90:disabled, .module-army-90.disabled {
  color: #fff;
  background-color: rgba(81, 92, 29, 0.9);
  background-image: none;
  border-color: rgba(81, 92, 29, 0.9);
}

.module-green-olive {
  color: #fff;
  background: #74861f linear-gradient(180deg, #869740, #74861f) repeat-x;
  border-color: #74861f;
}

.module-green-olive .icon-container {
  background-color: #596718;
}

.module-green-olive .icon-container.light {
  background-color: #aac42d;
}

.module-green-olive:hover {
  color: #fff;
  background: #596718 linear-gradient(180deg, #6f7c3a, #596718) repeat-x;
  border-color: color-yig(#596718);
}

.module-green-olive:focus, .module-green-olive.focus {
  color: #fff;
  background: #596718 linear-gradient(180deg, #6f7c3a, #596718) repeat-x;
  border-color: color-yig(#596718);
  box-shadow: 0 0 0 0.2rem rgba(137, 152, 65, 0.5);
}

.module-green-olive:active, .module-green-olive.active,
.show > .module-green-olive.dropdown-toggle {
  color: #fff;
  background-color: #505d15;
  background-image: none;
  border-color: #475213;
}

.module-green-olive:active:focus, .module-green-olive.active:focus,
.show > .module-green-olive.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(137, 152, 65, 0.5);
}

.module-green-olive:disabled, .module-green-olive.disabled {
  color: #fff;
  background-color: #74861f;
  background-image: none;
  border-color: #74861f;
}

.module-green-olive-10 {
  color: #fff;
  background: rgba(116, 134, 31, 0.1) linear-gradient(180deg, rgba(210, 220, 200, 0.235), rgba(116, 134, 31, 0.1)) repeat-x;
  border-color: rgba(116, 134, 31, 0.1);
}

.module-green-olive-10 .icon-container {
  background-color: rgba(89, 103, 24, 0.1);
}

.module-green-olive-10 .icon-container.light {
  background-color: rgba(170, 196, 45, 0.1);
}

.module-green-olive-10:hover {
  color: #fff;
  background: rgba(89, 103, 24, 0.1) linear-gradient(180deg, rgba(204, 212, 198, 0.235), rgba(89, 103, 24, 0.1)) repeat-x;
  border-color: color-yig(rgba(89, 103, 24, 0.1));
}

.module-green-olive-10:focus, .module-green-olive-10.focus {
  color: #fff;
  background: rgba(89, 103, 24, 0.1) linear-gradient(180deg, rgba(204, 212, 198, 0.235), rgba(89, 103, 24, 0.1)) repeat-x;
  border-color: color-yig(rgba(89, 103, 24, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(223, 227, 204, 0.5);
}

.module-green-olive-10:active, .module-green-olive-10.active,
.show > .module-green-olive-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(80, 93, 21, 0.1);
  background-image: none;
  border-color: rgba(71, 82, 19, 0.1);
}

.module-green-olive-10:active:focus, .module-green-olive-10.active:focus,
.show > .module-green-olive-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 227, 204, 0.5);
}

.module-green-olive-10:disabled, .module-green-olive-10.disabled {
  color: #fff;
  background-color: rgba(116, 134, 31, 0.1);
  background-image: none;
  border-color: rgba(116, 134, 31, 0.1);
}

.module-green-olive-20 {
  color: #fff;
  background: rgba(116, 134, 31, 0.2) linear-gradient(180deg, rgba(191, 202, 165, 0.32), rgba(116, 134, 31, 0.2)) repeat-x;
  border-color: rgba(116, 134, 31, 0.2);
}

.module-green-olive-20 .icon-container {
  background-color: rgba(89, 103, 24, 0.2);
}

.module-green-olive-20 .icon-container.light {
  background-color: rgba(170, 196, 45, 0.2);
}

.module-green-olive-20:hover {
  color: #fff;
  background: rgba(89, 103, 24, 0.2) linear-gradient(180deg, rgba(180, 190, 163, 0.32), rgba(89, 103, 24, 0.2)) repeat-x;
  border-color: color-yig(rgba(89, 103, 24, 0.2));
}

.module-green-olive-20:focus, .module-green-olive-20.focus {
  color: #fff;
  background: rgba(89, 103, 24, 0.2) linear-gradient(180deg, rgba(180, 190, 163, 0.32), rgba(89, 103, 24, 0.2)) repeat-x;
  border-color: color-yig(rgba(89, 103, 24, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(201, 208, 168, 0.5);
}

.module-green-olive-20:active, .module-green-olive-20.active,
.show > .module-green-olive-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(80, 93, 21, 0.2);
  background-image: none;
  border-color: rgba(71, 82, 19, 0.2);
}

.module-green-olive-20:active:focus, .module-green-olive-20.active:focus,
.show > .module-green-olive-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(201, 208, 168, 0.5);
}

.module-green-olive-20:disabled, .module-green-olive-20.disabled {
  color: #fff;
  background-color: rgba(116, 134, 31, 0.2);
  background-image: none;
  border-color: rgba(116, 134, 31, 0.2);
}

.module-green-olive-30 {
  color: #fff;
  background: rgba(116, 134, 31, 0.3) linear-gradient(180deg, rgba(177, 190, 141, 0.405), rgba(116, 134, 31, 0.3)) repeat-x;
  border-color: rgba(116, 134, 31, 0.3);
}

.module-green-olive-30 .icon-container {
  background-color: rgba(89, 103, 24, 0.3);
}

.module-green-olive-30 .icon-container.light {
  background-color: rgba(170, 196, 45, 0.3);
}

.module-green-olive-30:hover {
  color: #fff;
  background: rgba(89, 103, 24, 0.3) linear-gradient(180deg, rgba(164, 174, 137, 0.405), rgba(89, 103, 24, 0.3)) repeat-x;
  border-color: color-yig(rgba(89, 103, 24, 0.3));
}

.module-green-olive-30:focus, .module-green-olive-30.focus {
  color: #fff;
  background: rgba(89, 103, 24, 0.3) linear-gradient(180deg, rgba(164, 174, 137, 0.405), rgba(89, 103, 24, 0.3)) repeat-x;
  border-color: color-yig(rgba(89, 103, 24, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(186, 195, 143, 0.5);
}

.module-green-olive-30:active, .module-green-olive-30.active,
.show > .module-green-olive-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(80, 93, 21, 0.3);
  background-image: none;
  border-color: rgba(71, 82, 19, 0.3);
}

.module-green-olive-30:active:focus, .module-green-olive-30.active:focus,
.show > .module-green-olive-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(186, 195, 143, 0.5);
}

.module-green-olive-30:disabled, .module-green-olive-30.disabled {
  color: #fff;
  background-color: rgba(116, 134, 31, 0.3);
  background-image: none;
  border-color: rgba(116, 134, 31, 0.3);
}

.module-green-olive-40 {
  color: #fff;
  background: rgba(116, 134, 31, 0.4) linear-gradient(180deg, rgba(166, 180, 122, 0.49), rgba(116, 134, 31, 0.4)) repeat-x;
  border-color: rgba(116, 134, 31, 0.4);
}

.module-green-olive-40 .icon-container {
  background-color: rgba(89, 103, 24, 0.4);
}

.module-green-olive-40 .icon-container.light {
  background-color: rgba(170, 196, 45, 0.4);
}

.module-green-olive-40:hover {
  color: #fff;
  background: rgba(89, 103, 24, 0.4) linear-gradient(180deg, rgba(151, 162, 117, 0.49), rgba(89, 103, 24, 0.4)) repeat-x;
  border-color: color-yig(rgba(89, 103, 24, 0.4));
}

.module-green-olive-40:focus, .module-green-olive-40.focus {
  color: #fff;
  background: rgba(89, 103, 24, 0.4) linear-gradient(180deg, rgba(151, 162, 117, 0.49), rgba(89, 103, 24, 0.4)) repeat-x;
  border-color: color-yig(rgba(89, 103, 24, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(174, 184, 124, 0.5);
}

.module-green-olive-40:active, .module-green-olive-40.active,
.show > .module-green-olive-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(80, 93, 21, 0.4);
  background-image: none;
  border-color: rgba(71, 82, 19, 0.4);
}

.module-green-olive-40:active:focus, .module-green-olive-40.active:focus,
.show > .module-green-olive-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(174, 184, 124, 0.5);
}

.module-green-olive-40:disabled, .module-green-olive-40.disabled {
  color: #fff;
  background-color: rgba(116, 134, 31, 0.4);
  background-image: none;
  border-color: rgba(116, 134, 31, 0.4);
}

.module-green-olive-50 {
  color: #fff;
  background: rgba(116, 134, 31, 0.5) linear-gradient(180deg, rgba(158, 172, 107, 0.575), rgba(116, 134, 31, 0.5)) repeat-x;
  border-color: rgba(116, 134, 31, 0.5);
}

.module-green-olive-50 .icon-container {
  background-color: rgba(89, 103, 24, 0.5);
}

.module-green-olive-50 .icon-container.light {
  background-color: rgba(170, 196, 45, 0.5);
}

.module-green-olive-50:hover {
  color: #fff;
  background: rgba(89, 103, 24, 0.5) linear-gradient(180deg, rgba(141, 152, 102, 0.575), rgba(89, 103, 24, 0.5)) repeat-x;
  border-color: color-yig(rgba(89, 103, 24, 0.5));
}

.module-green-olive-50:focus, .module-green-olive-50.focus {
  color: #fff;
  background: rgba(89, 103, 24, 0.5) linear-gradient(180deg, rgba(141, 152, 102, 0.575), rgba(89, 103, 24, 0.5)) repeat-x;
  border-color: color-yig(rgba(89, 103, 24, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(164, 176, 109, 0.5);
}

.module-green-olive-50:active, .module-green-olive-50.active,
.show > .module-green-olive-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(80, 93, 21, 0.5);
  background-image: none;
  border-color: rgba(71, 82, 19, 0.5);
}

.module-green-olive-50:active:focus, .module-green-olive-50.active:focus,
.show > .module-green-olive-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(164, 176, 109, 0.5);
}

.module-green-olive-50:disabled, .module-green-olive-50.disabled {
  color: #fff;
  background-color: rgba(116, 134, 31, 0.5);
  background-image: none;
  border-color: rgba(116, 134, 31, 0.5);
}

.module-green-olive-60 {
  color: #fff;
  background: rgba(116, 134, 31, 0.6) linear-gradient(180deg, rgba(152, 166, 95, 0.66), rgba(116, 134, 31, 0.6)) repeat-x;
  border-color: rgba(116, 134, 31, 0.6);
}

.module-green-olive-60 .icon-container {
  background-color: rgba(89, 103, 24, 0.6);
}

.module-green-olive-60 .icon-container.light {
  background-color: rgba(170, 196, 45, 0.6);
}

.module-green-olive-60:hover {
  color: #fff;
  background: rgba(89, 103, 24, 0.6) linear-gradient(180deg, rgba(133, 144, 90, 0.66), rgba(89, 103, 24, 0.6)) repeat-x;
  border-color: color-yig(rgba(89, 103, 24, 0.6));
}

.module-green-olive-60:focus, .module-green-olive-60.focus {
  color: #fff;
  background: rgba(89, 103, 24, 0.6) linear-gradient(180deg, rgba(133, 144, 90, 0.66), rgba(89, 103, 24, 0.6)) repeat-x;
  border-color: color-yig(rgba(89, 103, 24, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(157, 169, 96, 0.5);
}

.module-green-olive-60:active, .module-green-olive-60.active,
.show > .module-green-olive-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(80, 93, 21, 0.6);
  background-image: none;
  border-color: rgba(71, 82, 19, 0.6);
}

.module-green-olive-60:active:focus, .module-green-olive-60.active:focus,
.show > .module-green-olive-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(157, 169, 96, 0.5);
}

.module-green-olive-60:disabled, .module-green-olive-60.disabled {
  color: #fff;
  background-color: rgba(116, 134, 31, 0.6);
  background-image: none;
  border-color: rgba(116, 134, 31, 0.6);
}

.module-green-olive-70 {
  color: #fff;
  background: rgba(116, 134, 31, 0.7) linear-gradient(180deg, rgba(146, 161, 85, 0.745), rgba(116, 134, 31, 0.7)) repeat-x;
  border-color: rgba(116, 134, 31, 0.7);
}

.module-green-olive-70 .icon-container {
  background-color: rgba(89, 103, 24, 0.7);
}

.module-green-olive-70 .icon-container.light {
  background-color: rgba(170, 196, 45, 0.7);
}

.module-green-olive-70:hover {
  color: #fff;
  background: rgba(89, 103, 24, 0.7) linear-gradient(180deg, rgba(126, 138, 80, 0.745), rgba(89, 103, 24, 0.7)) repeat-x;
  border-color: color-yig(rgba(89, 103, 24, 0.7));
}

.module-green-olive-70:focus, .module-green-olive-70.focus {
  color: #fff;
  background: rgba(89, 103, 24, 0.7) linear-gradient(180deg, rgba(126, 138, 80, 0.745), rgba(89, 103, 24, 0.7)) repeat-x;
  border-color: color-yig(rgba(89, 103, 24, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(150, 164, 86, 0.5);
}

.module-green-olive-70:active, .module-green-olive-70.active,
.show > .module-green-olive-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(80, 93, 21, 0.7);
  background-image: none;
  border-color: rgba(71, 82, 19, 0.7);
}

.module-green-olive-70:active:focus, .module-green-olive-70.active:focus,
.show > .module-green-olive-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(150, 164, 86, 0.5);
}

.module-green-olive-70:disabled, .module-green-olive-70.disabled {
  color: #fff;
  background-color: rgba(116, 134, 31, 0.7);
  background-image: none;
  border-color: rgba(116, 134, 31, 0.7);
}

.module-green-olive-80 {
  color: #fff;
  background: rgba(116, 134, 31, 0.8) linear-gradient(180deg, rgba(142, 157, 77, 0.83), rgba(116, 134, 31, 0.8)) repeat-x;
  border-color: rgba(116, 134, 31, 0.8);
}

.module-green-olive-80 .icon-container {
  background-color: rgba(89, 103, 24, 0.8);
}

.module-green-olive-80 .icon-container.light {
  background-color: rgba(170, 196, 45, 0.8);
}

.module-green-olive-80:hover {
  color: #fff;
  background: rgba(89, 103, 24, 0.8) linear-gradient(180deg, rgba(120, 133, 71, 0.83), rgba(89, 103, 24, 0.8)) repeat-x;
  border-color: color-yig(rgba(89, 103, 24, 0.8));
}

.module-green-olive-80:focus, .module-green-olive-80.focus {
  color: #fff;
  background: rgba(89, 103, 24, 0.8) linear-gradient(180deg, rgba(120, 133, 71, 0.83), rgba(89, 103, 24, 0.8)) repeat-x;
  border-color: color-yig(rgba(89, 103, 24, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(145, 159, 78, 0.5);
}

.module-green-olive-80:active, .module-green-olive-80.active,
.show > .module-green-olive-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(80, 93, 21, 0.8);
  background-image: none;
  border-color: rgba(71, 82, 19, 0.8);
}

.module-green-olive-80:active:focus, .module-green-olive-80.active:focus,
.show > .module-green-olive-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(145, 159, 78, 0.5);
}

.module-green-olive-80:disabled, .module-green-olive-80.disabled {
  color: #fff;
  background-color: rgba(116, 134, 31, 0.8);
  background-image: none;
  border-color: rgba(116, 134, 31, 0.8);
}

.module-green-olive-90 {
  color: #fff;
  background: rgba(116, 134, 31, 0.9) linear-gradient(180deg, rgba(138, 154, 70, 0.915), rgba(116, 134, 31, 0.9)) repeat-x;
  border-color: rgba(116, 134, 31, 0.9);
}

.module-green-olive-90 .icon-container {
  background-color: rgba(89, 103, 24, 0.9);
}

.module-green-olive-90 .icon-container.light {
  background-color: rgba(170, 196, 45, 0.9);
}

.module-green-olive-90:hover {
  color: #fff;
  background: rgba(89, 103, 24, 0.9) linear-gradient(180deg, rgba(116, 128, 64, 0.915), rgba(89, 103, 24, 0.9)) repeat-x;
  border-color: color-yig(rgba(89, 103, 24, 0.9));
}

.module-green-olive-90:focus, .module-green-olive-90.focus {
  color: #fff;
  background: rgba(89, 103, 24, 0.9) linear-gradient(180deg, rgba(116, 128, 64, 0.915), rgba(89, 103, 24, 0.9)) repeat-x;
  border-color: color-yig(rgba(89, 103, 24, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(141, 155, 71, 0.5);
}

.module-green-olive-90:active, .module-green-olive-90.active,
.show > .module-green-olive-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(80, 93, 21, 0.9);
  background-image: none;
  border-color: rgba(71, 82, 19, 0.9);
}

.module-green-olive-90:active:focus, .module-green-olive-90.active:focus,
.show > .module-green-olive-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(141, 155, 71, 0.5);
}

.module-green-olive-90:disabled, .module-green-olive-90.disabled {
  color: #fff;
  background-color: rgba(116, 134, 31, 0.9);
  background-image: none;
  border-color: rgba(116, 134, 31, 0.9);
}

.module-apple {
  color: #273a4d;
  background: #a7c80d linear-gradient(180deg, #b2cf31, #a7c80d) repeat-x;
  border-color: #a7c80d;
}

.module-apple .icon-container {
  background-color: #89a40b;
}

.module-apple .icon-container.light {
  background-color: #cff230;
}

.module-apple:hover {
  color: #fff;
  background: #89a40b linear-gradient(180deg, #98b02f, #89a40b) repeat-x;
  border-color: color-yig(#89a40b);
}

.module-apple:focus, .module-apple.focus {
  color: #fff;
  background: #89a40b linear-gradient(180deg, #98b02f, #89a40b) repeat-x;
  border-color: color-yig(#89a40b);
  box-shadow: 0 0 0 0.2rem rgba(148, 179, 23, 0.5);
}

.module-apple:active, .module-apple.active,
.show > .module-apple.dropdown-toggle {
  color: #fff;
  background-color: #7f980a;
  background-image: none;
  border-color: #758c09;
}

.module-apple:active:focus, .module-apple.active:focus,
.show > .module-apple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(148, 179, 23, 0.5);
}

.module-apple:disabled, .module-apple.disabled {
  color: #273a4d;
  background-color: #a7c80d;
  background-image: none;
  border-color: #a7c80d;
}

.module-apple-10 {
  color: #273a4d;
  background: rgba(167, 200, 13, 0.1) linear-gradient(180deg, rgba(222, 235, 196, 0.235), rgba(167, 200, 13, 0.1)) repeat-x;
  border-color: rgba(167, 200, 13, 0.1);
}

.module-apple-10 .icon-container {
  background-color: rgba(137, 164, 11, 0.1);
}

.module-apple-10 .icon-container.light {
  background-color: rgba(207, 242, 48, 0.1);
}

.module-apple-10:hover {
  color: #fff;
  background: rgba(137, 164, 11, 0.1) linear-gradient(180deg, rgba(215, 226, 195, 0.235), rgba(137, 164, 11, 0.1)) repeat-x;
  border-color: color-yig(rgba(137, 164, 11, 0.1));
}

.module-apple-10:focus, .module-apple-10.focus {
  color: #fff;
  background: rgba(137, 164, 11, 0.1) linear-gradient(180deg, rgba(215, 226, 195, 0.235), rgba(137, 164, 11, 0.1)) repeat-x;
  border-color: color-yig(rgba(137, 164, 11, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(68, 91, 62, 0.5);
}

.module-apple-10:active, .module-apple-10.active,
.show > .module-apple-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(127, 152, 10, 0.1);
  background-image: none;
  border-color: rgba(117, 140, 9, 0.1);
}

.module-apple-10:active:focus, .module-apple-10.active:focus,
.show > .module-apple-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 91, 62, 0.5);
}

.module-apple-10:disabled, .module-apple-10.disabled {
  color: #273a4d;
  background-color: rgba(167, 200, 13, 0.1);
  background-image: none;
  border-color: rgba(167, 200, 13, 0.1);
}

.module-apple-20 {
  color: #273a4d;
  background: rgba(167, 200, 13, 0.2) linear-gradient(180deg, rgba(211, 228, 158, 0.32), rgba(167, 200, 13, 0.2)) repeat-x;
  border-color: rgba(167, 200, 13, 0.2);
}

.module-apple-20 .icon-container {
  background-color: rgba(137, 164, 11, 0.2);
}

.module-apple-20 .icon-container.light {
  background-color: rgba(207, 242, 48, 0.2);
}

.module-apple-20:hover {
  color: #fff;
  background: rgba(137, 164, 11, 0.2) linear-gradient(180deg, rgba(199, 214, 158, 0.32), rgba(137, 164, 11, 0.2)) repeat-x;
  border-color: color-yig(rgba(137, 164, 11, 0.2));
}

.module-apple-20:focus, .module-apple-20.focus {
  color: #fff;
  background: rgba(137, 164, 11, 0.2) linear-gradient(180deg, rgba(199, 214, 158, 0.32), rgba(137, 164, 11, 0.2)) repeat-x;
  border-color: color-yig(rgba(137, 164, 11, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(88, 113, 52, 0.5);
}

.module-apple-20:active, .module-apple-20.active,
.show > .module-apple-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(127, 152, 10, 0.2);
  background-image: none;
  border-color: rgba(117, 140, 9, 0.2);
}

.module-apple-20:active:focus, .module-apple-20.active:focus,
.show > .module-apple-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 113, 52, 0.5);
}

.module-apple-20:disabled, .module-apple-20.disabled {
  color: #273a4d;
  background-color: rgba(167, 200, 13, 0.2);
  background-image: none;
  border-color: rgba(167, 200, 13, 0.2);
}

.module-apple-30 {
  color: #273a4d;
  background: rgba(167, 200, 13, 0.3) linear-gradient(180deg, rgba(203, 223, 132, 0.405), rgba(167, 200, 13, 0.3)) repeat-x;
  border-color: rgba(167, 200, 13, 0.3);
}

.module-apple-30 .icon-container {
  background-color: rgba(137, 164, 11, 0.3);
}

.module-apple-30 .icon-container.light {
  background-color: rgba(207, 242, 48, 0.3);
}

.module-apple-30:hover {
  color: #fff;
  background: rgba(137, 164, 11, 0.3) linear-gradient(180deg, rgba(188, 205, 130, 0.405), rgba(137, 164, 11, 0.3)) repeat-x;
  border-color: color-yig(rgba(137, 164, 11, 0.3));
}

.module-apple-30:focus, .module-apple-30.focus {
  color: #fff;
  background: rgba(137, 164, 11, 0.3) linear-gradient(180deg, rgba(188, 205, 130, 0.405), rgba(137, 164, 11, 0.3)) repeat-x;
  border-color: color-yig(rgba(137, 164, 11, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(103, 129, 45, 0.5);
}

.module-apple-30:active, .module-apple-30.active,
.show > .module-apple-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(127, 152, 10, 0.3);
  background-image: none;
  border-color: rgba(117, 140, 9, 0.3);
}

.module-apple-30:active:focus, .module-apple-30.active:focus,
.show > .module-apple-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(103, 129, 45, 0.5);
}

.module-apple-30:disabled, .module-apple-30.disabled {
  color: #273a4d;
  background-color: rgba(167, 200, 13, 0.3);
  background-image: none;
  border-color: rgba(167, 200, 13, 0.3);
}

.module-apple-40 {
  color: #273a4d;
  background: rgba(167, 200, 13, 0.4) linear-gradient(180deg, rgba(196, 219, 111, 0.49), rgba(167, 200, 13, 0.4)) repeat-x;
  border-color: rgba(167, 200, 13, 0.4);
}

.module-apple-40 .icon-container {
  background-color: rgba(137, 164, 11, 0.4);
}

.module-apple-40 .icon-container.light {
  background-color: rgba(207, 242, 48, 0.4);
}

.module-apple-40:hover {
  color: #fff;
  background: rgba(137, 164, 11, 0.4) linear-gradient(180deg, rgba(179, 198, 110, 0.49), rgba(137, 164, 11, 0.4)) repeat-x;
  border-color: color-yig(rgba(137, 164, 11, 0.4));
}

.module-apple-40:focus, .module-apple-40.focus {
  color: #fff;
  background: rgba(137, 164, 11, 0.4) linear-gradient(180deg, rgba(179, 198, 110, 0.49), rgba(137, 164, 11, 0.4)) repeat-x;
  border-color: color-yig(rgba(137, 164, 11, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(114, 141, 39, 0.5);
}

.module-apple-40:active, .module-apple-40.active,
.show > .module-apple-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(127, 152, 10, 0.4);
  background-image: none;
  border-color: rgba(117, 140, 9, 0.4);
}

.module-apple-40:active:focus, .module-apple-40.active:focus,
.show > .module-apple-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(114, 141, 39, 0.5);
}

.module-apple-40:disabled, .module-apple-40.disabled {
  color: #273a4d;
  background-color: rgba(167, 200, 13, 0.4);
  background-image: none;
  border-color: rgba(167, 200, 13, 0.4);
}

.module-apple-50 {
  color: #273a4d;
  background: rgba(167, 200, 13, 0.5) linear-gradient(180deg, rgba(192, 216, 95, 0.575), rgba(167, 200, 13, 0.5)) repeat-x;
  border-color: rgba(167, 200, 13, 0.5);
}

.module-apple-50 .icon-container {
  background-color: rgba(137, 164, 11, 0.5);
}

.module-apple-50 .icon-container.light {
  background-color: rgba(207, 242, 48, 0.5);
}

.module-apple-50:hover {
  color: #fff;
  background: rgba(137, 164, 11, 0.5) linear-gradient(180deg, rgba(172, 192, 94, 0.575), rgba(137, 164, 11, 0.5)) repeat-x;
  border-color: color-yig(rgba(137, 164, 11, 0.5));
}

.module-apple-50:focus, .module-apple-50.focus {
  color: #fff;
  background: rgba(137, 164, 11, 0.5) linear-gradient(180deg, rgba(172, 192, 94, 0.575), rgba(137, 164, 11, 0.5)) repeat-x;
  border-color: color-yig(rgba(137, 164, 11, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(123, 151, 35, 0.5);
}

.module-apple-50:active, .module-apple-50.active,
.show > .module-apple-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(127, 152, 10, 0.5);
  background-image: none;
  border-color: rgba(117, 140, 9, 0.5);
}

.module-apple-50:active:focus, .module-apple-50.active:focus,
.show > .module-apple-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(123, 151, 35, 0.5);
}

.module-apple-50:disabled, .module-apple-50.disabled {
  color: #273a4d;
  background-color: rgba(167, 200, 13, 0.5);
  background-image: none;
  border-color: rgba(167, 200, 13, 0.5);
}

.module-apple-60 {
  color: #273a4d;
  background: rgba(167, 200, 13, 0.6) linear-gradient(180deg, rgba(188, 213, 82, 0.66), rgba(167, 200, 13, 0.6)) repeat-x;
  border-color: rgba(167, 200, 13, 0.6);
}

.module-apple-60 .icon-container {
  background-color: rgba(137, 164, 11, 0.6);
}

.module-apple-60 .icon-container.light {
  background-color: rgba(207, 242, 48, 0.6);
}

.module-apple-60:hover {
  color: #fff;
  background: rgba(137, 164, 11, 0.6) linear-gradient(180deg, rgba(166, 188, 80, 0.66), rgba(137, 164, 11, 0.6)) repeat-x;
  border-color: color-yig(rgba(137, 164, 11, 0.6));
}

.module-apple-60:focus, .module-apple-60.focus {
  color: #fff;
  background: rgba(137, 164, 11, 0.6) linear-gradient(180deg, rgba(166, 188, 80, 0.66), rgba(137, 164, 11, 0.6)) repeat-x;
  border-color: color-yig(rgba(137, 164, 11, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(130, 159, 32, 0.5);
}

.module-apple-60:active, .module-apple-60.active,
.show > .module-apple-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(127, 152, 10, 0.6);
  background-image: none;
  border-color: rgba(117, 140, 9, 0.6);
}

.module-apple-60:active:focus, .module-apple-60.active:focus,
.show > .module-apple-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 159, 32, 0.5);
}

.module-apple-60:disabled, .module-apple-60.disabled {
  color: #273a4d;
  background-color: rgba(167, 200, 13, 0.6);
  background-image: none;
  border-color: rgba(167, 200, 13, 0.6);
}

.module-apple-70 {
  color: #273a4d;
  background: rgba(167, 200, 13, 0.7) linear-gradient(180deg, rgba(185, 211, 72, 0.745), rgba(167, 200, 13, 0.7)) repeat-x;
  border-color: rgba(167, 200, 13, 0.7);
}

.module-apple-70 .icon-container {
  background-color: rgba(137, 164, 11, 0.7);
}

.module-apple-70 .icon-container.light {
  background-color: rgba(207, 242, 48, 0.7);
}

.module-apple-70:hover {
  color: #fff;
  background: rgba(137, 164, 11, 0.7) linear-gradient(180deg, rgba(162, 184, 70, 0.745), rgba(137, 164, 11, 0.7)) repeat-x;
  border-color: color-yig(rgba(137, 164, 11, 0.7));
}

.module-apple-70:focus, .module-apple-70.focus {
  color: #fff;
  background: rgba(137, 164, 11, 0.7) linear-gradient(180deg, rgba(162, 184, 70, 0.745), rgba(137, 164, 11, 0.7)) repeat-x;
  border-color: color-yig(rgba(137, 164, 11, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(135, 165, 29, 0.5);
}

.module-apple-70:active, .module-apple-70.active,
.show > .module-apple-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(127, 152, 10, 0.7);
  background-image: none;
  border-color: rgba(117, 140, 9, 0.7);
}

.module-apple-70:active:focus, .module-apple-70.active:focus,
.show > .module-apple-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(135, 165, 29, 0.5);
}

.module-apple-70:disabled, .module-apple-70.disabled {
  color: #273a4d;
  background-color: rgba(167, 200, 13, 0.7);
  background-image: none;
  border-color: rgba(167, 200, 13, 0.7);
}

.module-apple-80 {
  color: #273a4d;
  background: rgba(167, 200, 13, 0.8) linear-gradient(180deg, rgba(182, 209, 63, 0.83), rgba(167, 200, 13, 0.8)) repeat-x;
  border-color: rgba(167, 200, 13, 0.8);
}

.module-apple-80 .icon-container {
  background-color: rgba(137, 164, 11, 0.8);
}

.module-apple-80 .icon-container.light {
  background-color: rgba(207, 242, 48, 0.8);
}

.module-apple-80:hover {
  color: #fff;
  background: rgba(137, 164, 11, 0.8) linear-gradient(180deg, rgba(158, 181, 61, 0.83), rgba(137, 164, 11, 0.8)) repeat-x;
  border-color: color-yig(rgba(137, 164, 11, 0.8));
}

.module-apple-80:focus, .module-apple-80.focus {
  color: #fff;
  background: rgba(137, 164, 11, 0.8) linear-gradient(180deg, rgba(158, 181, 61, 0.83), rgba(137, 164, 11, 0.8)) repeat-x;
  border-color: color-yig(rgba(137, 164, 11, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(140, 170, 26, 0.5);
}

.module-apple-80:active, .module-apple-80.active,
.show > .module-apple-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(127, 152, 10, 0.8);
  background-image: none;
  border-color: rgba(117, 140, 9, 0.8);
}

.module-apple-80:active:focus, .module-apple-80.active:focus,
.show > .module-apple-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(140, 170, 26, 0.5);
}

.module-apple-80:disabled, .module-apple-80.disabled {
  color: #273a4d;
  background-color: rgba(167, 200, 13, 0.8);
  background-image: none;
  border-color: rgba(167, 200, 13, 0.8);
}

.module-apple-90 {
  color: #273a4d;
  background: rgba(167, 200, 13, 0.9) linear-gradient(180deg, rgba(180, 208, 55, 0.915), rgba(167, 200, 13, 0.9)) repeat-x;
  border-color: rgba(167, 200, 13, 0.9);
}

.module-apple-90 .icon-container {
  background-color: rgba(137, 164, 11, 0.9);
}

.module-apple-90 .icon-container.light {
  background-color: rgba(207, 242, 48, 0.9);
}

.module-apple-90:hover {
  color: #fff;
  background: rgba(137, 164, 11, 0.9) linear-gradient(180deg, rgba(155, 178, 53, 0.915), rgba(137, 164, 11, 0.9)) repeat-x;
  border-color: color-yig(rgba(137, 164, 11, 0.9));
}

.module-apple-90:focus, .module-apple-90.focus {
  color: #fff;
  background: rgba(137, 164, 11, 0.9) linear-gradient(180deg, rgba(155, 178, 53, 0.915), rgba(137, 164, 11, 0.9)) repeat-x;
  border-color: color-yig(rgba(137, 164, 11, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(144, 175, 24, 0.5);
}

.module-apple-90:active, .module-apple-90.active,
.show > .module-apple-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(127, 152, 10, 0.9);
  background-image: none;
  border-color: rgba(117, 140, 9, 0.9);
}

.module-apple-90:active:focus, .module-apple-90.active:focus,
.show > .module-apple-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(144, 175, 24, 0.5);
}

.module-apple-90:disabled, .module-apple-90.disabled {
  color: #273a4d;
  background-color: rgba(167, 200, 13, 0.9);
  background-image: none;
  border-color: rgba(167, 200, 13, 0.9);
}

.module-lime {
  color: #273a4d;
  background: #c7e82e linear-gradient(180deg, #cdea4d, #c7e82e) repeat-x;
  border-color: #c7e82e;
}

.module-lime .icon-container {
  background-color: #b6d818;
}

.module-lime .icon-container.light {
  background-color: #d9f073;
}

.module-lime:hover {
  color: #273a4d;
  background: #b6d818 linear-gradient(180deg, #bedc3a, #b6d818) repeat-x;
  border-color: color-yig(#b6d818);
}

.module-lime:focus, .module-lime.focus {
  color: #273a4d;
  background: #b6d818 linear-gradient(180deg, #bedc3a, #b6d818) repeat-x;
  border-color: color-yig(#b6d818);
  box-shadow: 0 0 0 0.2rem rgba(175, 206, 51, 0.5);
}

.module-lime:active, .module-lime.active,
.show > .module-lime.dropdown-toggle {
  color: #273a4d;
  background-color: #accc17;
  background-image: none;
  border-color: #a3c115;
}

.module-lime:active:focus, .module-lime.active:focus,
.show > .module-lime.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(175, 206, 51, 0.5);
}

.module-lime:disabled, .module-lime.disabled {
  color: #273a4d;
  background-color: #c7e82e;
  background-image: none;
  border-color: #c7e82e;
}

.module-lime-10 {
  color: #273a4d;
  background: rgba(199, 232, 46, 0.1) linear-gradient(180deg, rgba(229, 242, 203, 0.235), rgba(199, 232, 46, 0.1)) repeat-x;
  border-color: rgba(199, 232, 46, 0.1);
}

.module-lime-10 .icon-container {
  background-color: rgba(182, 216, 24, 0.1);
}

.module-lime-10 .icon-container.light {
  background-color: rgba(217, 240, 115, 0.1);
}

.module-lime-10:hover {
  color: #273a4d;
  background: rgba(182, 216, 24, 0.1) linear-gradient(180deg, rgba(225, 238, 198, 0.235), rgba(182, 216, 24, 0.1)) repeat-x;
  border-color: color-yig(rgba(182, 216, 24, 0.1));
}

.module-lime-10:focus, .module-lime-10.focus {
  color: #273a4d;
  background: rgba(182, 216, 24, 0.1) linear-gradient(180deg, rgba(225, 238, 198, 0.235), rgba(182, 216, 24, 0.1)) repeat-x;
  border-color: color-yig(rgba(182, 216, 24, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(76, 98, 70, 0.5);
}

.module-lime-10:active, .module-lime-10.active,
.show > .module-lime-10.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(172, 204, 23, 0.1);
  background-image: none;
  border-color: rgba(163, 193, 21, 0.1);
}

.module-lime-10:active:focus, .module-lime-10.active:focus,
.show > .module-lime-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 98, 70, 0.5);
}

.module-lime-10:disabled, .module-lime-10.disabled {
  color: #273a4d;
  background-color: rgba(199, 232, 46, 0.1);
  background-image: none;
  border-color: rgba(199, 232, 46, 0.1);
}

.module-lime-20 {
  color: #273a4d;
  background: rgba(199, 232, 46, 0.2) linear-gradient(180deg, rgba(223, 240, 171, 0.32), rgba(199, 232, 46, 0.2)) repeat-x;
  border-color: rgba(199, 232, 46, 0.2);
}

.module-lime-20 .icon-container {
  background-color: rgba(182, 216, 24, 0.2);
}

.module-lime-20 .icon-container.light {
  background-color: rgba(217, 240, 115, 0.2);
}

.module-lime-20:hover {
  color: #273a4d;
  background: rgba(182, 216, 24, 0.2) linear-gradient(180deg, rgba(216, 234, 163, 0.32), rgba(182, 216, 24, 0.2)) repeat-x;
  border-color: color-yig(rgba(182, 216, 24, 0.2));
}

.module-lime-20:focus, .module-lime-20.focus {
  color: #273a4d;
  background: rgba(182, 216, 24, 0.2) linear-gradient(180deg, rgba(216, 234, 163, 0.32), rgba(182, 216, 24, 0.2)) repeat-x;
  border-color: color-yig(rgba(182, 216, 24, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(101, 125, 65, 0.5);
}

.module-lime-20:active, .module-lime-20.active,
.show > .module-lime-20.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(172, 204, 23, 0.2);
  background-image: none;
  border-color: rgba(163, 193, 21, 0.2);
}

.module-lime-20:active:focus, .module-lime-20.active:focus,
.show > .module-lime-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(101, 125, 65, 0.5);
}

.module-lime-20:disabled, .module-lime-20.disabled {
  color: #273a4d;
  background-color: rgba(199, 232, 46, 0.2);
  background-image: none;
  border-color: rgba(199, 232, 46, 0.2);
}

.module-lime-30 {
  color: #273a4d;
  background: rgba(199, 232, 46, 0.3) linear-gradient(180deg, rgba(219, 239, 148, 0.405), rgba(199, 232, 46, 0.3)) repeat-x;
  border-color: rgba(199, 232, 46, 0.3);
}

.module-lime-30 .icon-container {
  background-color: rgba(182, 216, 24, 0.3);
}

.module-lime-30 .icon-container.light {
  background-color: rgba(217, 240, 115, 0.3);
}

.module-lime-30:hover {
  color: #273a4d;
  background: rgba(182, 216, 24, 0.3) linear-gradient(180deg, rgba(210, 230, 137, 0.405), rgba(182, 216, 24, 0.3)) repeat-x;
  border-color: color-yig(rgba(182, 216, 24, 0.3));
}

.module-lime-30:focus, .module-lime-30.focus {
  color: #273a4d;
  background: rgba(182, 216, 24, 0.3) linear-gradient(180deg, rgba(210, 230, 137, 0.405), rgba(182, 216, 24, 0.3)) repeat-x;
  border-color: color-yig(rgba(182, 216, 24, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(119, 145, 62, 0.5);
}

.module-lime-30:active, .module-lime-30.active,
.show > .module-lime-30.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(172, 204, 23, 0.3);
  background-image: none;
  border-color: rgba(163, 193, 21, 0.3);
}

.module-lime-30:active:focus, .module-lime-30.active:focus,
.show > .module-lime-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(119, 145, 62, 0.5);
}

.module-lime-30:disabled, .module-lime-30.disabled {
  color: #273a4d;
  background-color: rgba(199, 232, 46, 0.3);
  background-image: none;
  border-color: rgba(199, 232, 46, 0.3);
}

.module-lime-40 {
  color: #273a4d;
  background: rgba(199, 232, 46, 0.4) linear-gradient(180deg, rgba(215, 237, 130, 0.49), rgba(199, 232, 46, 0.4)) repeat-x;
  border-color: rgba(199, 232, 46, 0.4);
}

.module-lime-40 .icon-container {
  background-color: rgba(182, 216, 24, 0.4);
}

.module-lime-40 .icon-container.light {
  background-color: rgba(217, 240, 115, 0.4);
}

.module-lime-40:hover {
  color: #273a4d;
  background: rgba(182, 216, 24, 0.4) linear-gradient(180deg, rgba(205, 228, 117, 0.49), rgba(182, 216, 24, 0.4)) repeat-x;
  border-color: color-yig(rgba(182, 216, 24, 0.4));
}

.module-lime-40:focus, .module-lime-40.focus {
  color: #273a4d;
  background: rgba(182, 216, 24, 0.4) linear-gradient(180deg, rgba(205, 228, 117, 0.49), rgba(182, 216, 24, 0.4)) repeat-x;
  border-color: color-yig(rgba(182, 216, 24, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(133, 160, 59, 0.5);
}

.module-lime-40:active, .module-lime-40.active,
.show > .module-lime-40.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(172, 204, 23, 0.4);
  background-image: none;
  border-color: rgba(163, 193, 21, 0.4);
}

.module-lime-40:active:focus, .module-lime-40.active:focus,
.show > .module-lime-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 160, 59, 0.5);
}

.module-lime-40:disabled, .module-lime-40.disabled {
  color: #273a4d;
  background-color: rgba(199, 232, 46, 0.4);
  background-image: none;
  border-color: rgba(199, 232, 46, 0.4);
}

.module-lime-50 {
  color: #273a4d;
  background: rgba(199, 232, 46, 0.5) linear-gradient(180deg, rgba(213, 237, 117, 0.575), rgba(199, 232, 46, 0.5)) repeat-x;
  border-color: rgba(199, 232, 46, 0.5);
}

.module-lime-50 .icon-container {
  background-color: rgba(182, 216, 24, 0.5);
}

.module-lime-50 .icon-container.light {
  background-color: rgba(217, 240, 115, 0.5);
}

.module-lime-50:hover {
  color: #273a4d;
  background: rgba(182, 216, 24, 0.5) linear-gradient(180deg, rgba(201, 226, 102, 0.575), rgba(182, 216, 24, 0.5)) repeat-x;
  border-color: color-yig(rgba(182, 216, 24, 0.5));
}

.module-lime-50:focus, .module-lime-50.focus {
  color: #273a4d;
  background: rgba(182, 216, 24, 0.5) linear-gradient(180deg, rgba(201, 226, 102, 0.575), rgba(182, 216, 24, 0.5)) repeat-x;
  border-color: color-yig(rgba(182, 216, 24, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(144, 172, 57, 0.5);
}

.module-lime-50:active, .module-lime-50.active,
.show > .module-lime-50.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(172, 204, 23, 0.5);
  background-image: none;
  border-color: rgba(163, 193, 21, 0.5);
}

.module-lime-50:active:focus, .module-lime-50.active:focus,
.show > .module-lime-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(144, 172, 57, 0.5);
}

.module-lime-50:disabled, .module-lime-50.disabled {
  color: #273a4d;
  background-color: rgba(199, 232, 46, 0.5);
  background-image: none;
  border-color: rgba(199, 232, 46, 0.5);
}

.module-lime-60 {
  color: #273a4d;
  background: rgba(199, 232, 46, 0.6) linear-gradient(180deg, rgba(210, 236, 106, 0.66), rgba(199, 232, 46, 0.6)) repeat-x;
  border-color: rgba(199, 232, 46, 0.6);
}

.module-lime-60 .icon-container {
  background-color: rgba(182, 216, 24, 0.6);
}

.module-lime-60 .icon-container.light {
  background-color: rgba(217, 240, 115, 0.6);
}

.module-lime-60:hover {
  color: #273a4d;
  background: rgba(182, 216, 24, 0.6) linear-gradient(180deg, rgba(198, 224, 90, 0.66), rgba(182, 216, 24, 0.6)) repeat-x;
  border-color: color-yig(rgba(182, 216, 24, 0.6));
}

.module-lime-60:focus, .module-lime-60.focus {
  color: #273a4d;
  background: rgba(182, 216, 24, 0.6) linear-gradient(180deg, rgba(198, 224, 90, 0.66), rgba(182, 216, 24, 0.6)) repeat-x;
  border-color: color-yig(rgba(182, 216, 24, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(152, 181, 55, 0.5);
}

.module-lime-60:active, .module-lime-60.active,
.show > .module-lime-60.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(172, 204, 23, 0.6);
  background-image: none;
  border-color: rgba(163, 193, 21, 0.6);
}

.module-lime-60:active:focus, .module-lime-60.active:focus,
.show > .module-lime-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(152, 181, 55, 0.5);
}

.module-lime-60:disabled, .module-lime-60.disabled {
  color: #273a4d;
  background-color: rgba(199, 232, 46, 0.6);
  background-image: none;
  border-color: rgba(199, 232, 46, 0.6);
}

.module-lime-70 {
  color: #273a4d;
  background: rgba(199, 232, 46, 0.7) linear-gradient(180deg, rgba(209, 235, 96, 0.745), rgba(199, 232, 46, 0.7)) repeat-x;
  border-color: rgba(199, 232, 46, 0.7);
}

.module-lime-70 .icon-container {
  background-color: rgba(182, 216, 24, 0.7);
}

.module-lime-70 .icon-container.light {
  background-color: rgba(217, 240, 115, 0.7);
}

.module-lime-70:hover {
  color: #273a4d;
  background: rgba(182, 216, 24, 0.7) linear-gradient(180deg, rgba(196, 223, 80, 0.745), rgba(182, 216, 24, 0.7)) repeat-x;
  border-color: color-yig(rgba(182, 216, 24, 0.7));
}

.module-lime-70:focus, .module-lime-70.focus {
  color: #273a4d;
  background: rgba(182, 216, 24, 0.7) linear-gradient(180deg, rgba(196, 223, 80, 0.745), rgba(182, 216, 24, 0.7)) repeat-x;
  border-color: color-yig(rgba(182, 216, 24, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(160, 189, 54, 0.5);
}

.module-lime-70:active, .module-lime-70.active,
.show > .module-lime-70.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(172, 204, 23, 0.7);
  background-image: none;
  border-color: rgba(163, 193, 21, 0.7);
}

.module-lime-70:active:focus, .module-lime-70.active:focus,
.show > .module-lime-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(160, 189, 54, 0.5);
}

.module-lime-70:disabled, .module-lime-70.disabled {
  color: #273a4d;
  background-color: rgba(199, 232, 46, 0.7);
  background-image: none;
  border-color: rgba(199, 232, 46, 0.7);
}

.module-lime-80 {
  color: #273a4d;
  background: rgba(199, 232, 46, 0.8) linear-gradient(180deg, rgba(207, 235, 89, 0.83), rgba(199, 232, 46, 0.8)) repeat-x;
  border-color: rgba(199, 232, 46, 0.8);
}

.module-lime-80 .icon-container {
  background-color: rgba(182, 216, 24, 0.8);
}

.module-lime-80 .icon-container.light {
  background-color: rgba(217, 240, 115, 0.8);
}

.module-lime-80:hover {
  color: #273a4d;
  background: rgba(182, 216, 24, 0.8) linear-gradient(180deg, rgba(194, 222, 71, 0.83), rgba(182, 216, 24, 0.8)) repeat-x;
  border-color: color-yig(rgba(182, 216, 24, 0.8));
}

.module-lime-80:focus, .module-lime-80.focus {
  color: #273a4d;
  background: rgba(182, 216, 24, 0.8) linear-gradient(180deg, rgba(194, 222, 71, 0.83), rgba(182, 216, 24, 0.8)) repeat-x;
  border-color: color-yig(rgba(182, 216, 24, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(166, 196, 52, 0.5);
}

.module-lime-80:active, .module-lime-80.active,
.show > .module-lime-80.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(172, 204, 23, 0.8);
  background-image: none;
  border-color: rgba(163, 193, 21, 0.8);
}

.module-lime-80:active:focus, .module-lime-80.active:focus,
.show > .module-lime-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 196, 52, 0.5);
}

.module-lime-80:disabled, .module-lime-80.disabled {
  color: #273a4d;
  background-color: rgba(199, 232, 46, 0.8);
  background-image: none;
  border-color: rgba(199, 232, 46, 0.8);
}

.module-lime-90 {
  color: #273a4d;
  background: rgba(199, 232, 46, 0.9) linear-gradient(180deg, rgba(206, 234, 82, 0.915), rgba(199, 232, 46, 0.9)) repeat-x;
  border-color: rgba(199, 232, 46, 0.9);
}

.module-lime-90 .icon-container {
  background-color: rgba(182, 216, 24, 0.9);
}

.module-lime-90 .icon-container.light {
  background-color: rgba(217, 240, 115, 0.9);
}

.module-lime-90:hover {
  color: #273a4d;
  background: rgba(182, 216, 24, 0.9) linear-gradient(180deg, rgba(192, 221, 64, 0.915), rgba(182, 216, 24, 0.9)) repeat-x;
  border-color: color-yig(rgba(182, 216, 24, 0.9));
}

.module-lime-90:focus, .module-lime-90.focus {
  color: #273a4d;
  background: rgba(182, 216, 24, 0.9) linear-gradient(180deg, rgba(192, 221, 64, 0.915), rgba(182, 216, 24, 0.9)) repeat-x;
  border-color: color-yig(rgba(182, 216, 24, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(171, 201, 52, 0.5);
}

.module-lime-90:active, .module-lime-90.active,
.show > .module-lime-90.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(172, 204, 23, 0.9);
  background-image: none;
  border-color: rgba(163, 193, 21, 0.9);
}

.module-lime-90:active:focus, .module-lime-90.active:focus,
.show > .module-lime-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(171, 201, 52, 0.5);
}

.module-lime-90:disabled, .module-lime-90.disabled {
  color: #273a4d;
  background-color: rgba(199, 232, 46, 0.9);
  background-image: none;
  border-color: rgba(199, 232, 46, 0.9);
}

.module-tea {
  color: #273a4d;
  background: #e6f797 linear-gradient(180deg, #e7f7a6, #e6f797) repeat-x;
  border-color: #e6f797;
}

.module-tea .icon-container {
  background-color: #ddf473;
}

.module-tea .icon-container.light {
  background-color: #f7fcde;
}

.module-tea:hover {
  color: #273a4d;
  background: #ddf473 linear-gradient(180deg, #e0f488, #ddf473) repeat-x;
  border-color: color-yig(#ddf473);
}

.module-tea:focus, .module-tea.focus {
  color: #273a4d;
  background: #ddf473 linear-gradient(180deg, #e0f488, #ddf473) repeat-x;
  border-color: color-yig(#ddf473);
  box-shadow: 0 0 0 0.2rem rgba(201, 219, 140, 0.5);
}

.module-tea:active, .module-tea.active,
.show > .module-tea.dropdown-toggle {
  color: #273a4d;
  background-color: #dbf368;
  background-image: none;
  border-color: #d8f25c;
}

.module-tea:active:focus, .module-tea.active:focus,
.show > .module-tea.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(201, 219, 140, 0.5);
}

.module-tea:disabled, .module-tea.disabled {
  color: #273a4d;
  background-color: #e6f797;
  background-image: none;
  border-color: #e6f797;
}

.module-tea-10 {
  color: #273a4d;
  background: rgba(230, 247, 151, 0.1) linear-gradient(180deg, rgba(236, 245, 227, 0.235), rgba(230, 247, 151, 0.1)) repeat-x;
  border-color: rgba(230, 247, 151, 0.1);
}

.module-tea-10 .icon-container {
  background-color: rgba(221, 244, 115, 0.1);
}

.module-tea-10 .icon-container.light {
  background-color: rgba(247, 252, 222, 0.1);
}

.module-tea-10:hover {
  color: #273a4d;
  background: rgba(221, 244, 115, 0.1) linear-gradient(180deg, rgba(234, 245, 219, 0.235), rgba(221, 244, 115, 0.1)) repeat-x;
  border-color: color-yig(rgba(221, 244, 115, 0.1));
}

.module-tea-10:focus, .module-tea-10.focus {
  color: #273a4d;
  background: rgba(221, 244, 115, 0.1) linear-gradient(180deg, rgba(234, 245, 219, 0.235), rgba(221, 244, 115, 0.1)) repeat-x;
  border-color: color-yig(rgba(221, 244, 115, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(83, 101, 94, 0.5);
}

.module-tea-10:active, .module-tea-10.active,
.show > .module-tea-10.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(219, 243, 104, 0.1);
  background-image: none;
  border-color: rgba(216, 242, 92, 0.1);
}

.module-tea-10:active:focus, .module-tea-10.active:focus,
.show > .module-tea-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(83, 101, 94, 0.5);
}

.module-tea-10:disabled, .module-tea-10.disabled {
  color: #273a4d;
  background-color: rgba(230, 247, 151, 0.1);
  background-image: none;
  border-color: rgba(230, 247, 151, 0.1);
}

.module-tea-20 {
  color: #273a4d;
  background: rgba(230, 247, 151, 0.2) linear-gradient(180deg, rgba(235, 246, 212, 0.32), rgba(230, 247, 151, 0.2)) repeat-x;
  border-color: rgba(230, 247, 151, 0.2);
}

.module-tea-20 .icon-container {
  background-color: rgba(221, 244, 115, 0.2);
}

.module-tea-20 .icon-container.light {
  background-color: rgba(247, 252, 222, 0.2);
}

.module-tea-20:hover {
  color: #273a4d;
  background: rgba(221, 244, 115, 0.2) linear-gradient(180deg, rgba(232, 245, 198, 0.32), rgba(221, 244, 115, 0.2)) repeat-x;
  border-color: color-yig(rgba(221, 244, 115, 0.2));
}

.module-tea-20:focus, .module-tea-20.focus {
  color: #273a4d;
  background: rgba(221, 244, 115, 0.2) linear-gradient(180deg, rgba(232, 245, 198, 0.32), rgba(221, 244, 115, 0.2)) repeat-x;
  border-color: color-yig(rgba(221, 244, 115, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(113, 131, 106, 0.5);
}

.module-tea-20:active, .module-tea-20.active,
.show > .module-tea-20.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(219, 243, 104, 0.2);
  background-image: none;
  border-color: rgba(216, 242, 92, 0.2);
}

.module-tea-20:active:focus, .module-tea-20.active:focus,
.show > .module-tea-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(113, 131, 106, 0.5);
}

.module-tea-20:disabled, .module-tea-20.disabled {
  color: #273a4d;
  background-color: rgba(230, 247, 151, 0.2);
  background-image: none;
  border-color: rgba(230, 247, 151, 0.2);
}

.module-tea-30 {
  color: #273a4d;
  background: rgba(230, 247, 151, 0.3) linear-gradient(180deg, rgba(234, 246, 201, 0.405), rgba(230, 247, 151, 0.3)) repeat-x;
  border-color: rgba(230, 247, 151, 0.3);
}

.module-tea-30 .icon-container {
  background-color: rgba(221, 244, 115, 0.3);
}

.module-tea-30 .icon-container.light {
  background-color: rgba(247, 252, 222, 0.3);
}

.module-tea-30:hover {
  color: #273a4d;
  background: rgba(221, 244, 115, 0.3) linear-gradient(180deg, rgba(230, 245, 183, 0.405), rgba(221, 244, 115, 0.3)) repeat-x;
  border-color: color-yig(rgba(221, 244, 115, 0.3));
}

.module-tea-30:focus, .module-tea-30.focus {
  color: #273a4d;
  background: rgba(221, 244, 115, 0.3) linear-gradient(180deg, rgba(230, 245, 183, 0.405), rgba(221, 244, 115, 0.3)) repeat-x;
  border-color: color-yig(rgba(221, 244, 115, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(135, 153, 114, 0.5);
}

.module-tea-30:active, .module-tea-30.active,
.show > .module-tea-30.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(219, 243, 104, 0.3);
  background-image: none;
  border-color: rgba(216, 242, 92, 0.3);
}

.module-tea-30:active:focus, .module-tea-30.active:focus,
.show > .module-tea-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(135, 153, 114, 0.5);
}

.module-tea-30:disabled, .module-tea-30.disabled {
  color: #273a4d;
  background-color: rgba(230, 247, 151, 0.3);
  background-image: none;
  border-color: rgba(230, 247, 151, 0.3);
}

.module-tea-40 {
  color: #273a4d;
  background: rgba(230, 247, 151, 0.4) linear-gradient(180deg, rgba(233, 246, 192, 0.49), rgba(230, 247, 151, 0.4)) repeat-x;
  border-color: rgba(230, 247, 151, 0.4);
}

.module-tea-40 .icon-container {
  background-color: rgba(221, 244, 115, 0.4);
}

.module-tea-40 .icon-container.light {
  background-color: rgba(247, 252, 222, 0.4);
}

.module-tea-40:hover {
  color: #273a4d;
  background: rgba(221, 244, 115, 0.4) linear-gradient(180deg, rgba(228, 245, 171, 0.49), rgba(221, 244, 115, 0.4)) repeat-x;
  border-color: color-yig(rgba(221, 244, 115, 0.4));
}

.module-tea-40:focus, .module-tea-40.focus {
  color: #273a4d;
  background: rgba(221, 244, 115, 0.4) linear-gradient(180deg, rgba(228, 245, 171, 0.49), rgba(221, 244, 115, 0.4)) repeat-x;
  border-color: color-yig(rgba(221, 244, 115, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(151, 169, 120, 0.5);
}

.module-tea-40:active, .module-tea-40.active,
.show > .module-tea-40.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(219, 243, 104, 0.4);
  background-image: none;
  border-color: rgba(216, 242, 92, 0.4);
}

.module-tea-40:active:focus, .module-tea-40.active:focus,
.show > .module-tea-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(151, 169, 120, 0.5);
}

.module-tea-40:disabled, .module-tea-40.disabled {
  color: #273a4d;
  background-color: rgba(230, 247, 151, 0.4);
  background-image: none;
  border-color: rgba(230, 247, 151, 0.4);
}

.module-tea-50 {
  color: #273a4d;
  background: rgba(230, 247, 151, 0.5) linear-gradient(180deg, rgba(233, 246, 185, 0.575), rgba(230, 247, 151, 0.5)) repeat-x;
  border-color: rgba(230, 247, 151, 0.5);
}

.module-tea-50 .icon-container {
  background-color: rgba(221, 244, 115, 0.5);
}

.module-tea-50 .icon-container.light {
  background-color: rgba(247, 252, 222, 0.5);
}

.module-tea-50:hover {
  color: #273a4d;
  background: rgba(221, 244, 115, 0.5) linear-gradient(180deg, rgba(227, 245, 162, 0.575), rgba(221, 244, 115, 0.5)) repeat-x;
  border-color: color-yig(rgba(221, 244, 115, 0.5));
}

.module-tea-50:focus, .module-tea-50.focus {
  color: #273a4d;
  background: rgba(221, 244, 115, 0.5) linear-gradient(180deg, rgba(227, 245, 162, 0.575), rgba(221, 244, 115, 0.5)) repeat-x;
  border-color: color-yig(rgba(221, 244, 115, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(164, 182, 125, 0.5);
}

.module-tea-50:active, .module-tea-50.active,
.show > .module-tea-50.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(219, 243, 104, 0.5);
  background-image: none;
  border-color: rgba(216, 242, 92, 0.5);
}

.module-tea-50:active:focus, .module-tea-50.active:focus,
.show > .module-tea-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(164, 182, 125, 0.5);
}

.module-tea-50:disabled, .module-tea-50.disabled {
  color: #273a4d;
  background-color: rgba(230, 247, 151, 0.5);
  background-image: none;
  border-color: rgba(230, 247, 151, 0.5);
}

.module-tea-60 {
  color: #273a4d;
  background: rgba(230, 247, 151, 0.6) linear-gradient(180deg, rgba(232, 246, 180, 0.66), rgba(230, 247, 151, 0.6)) repeat-x;
  border-color: rgba(230, 247, 151, 0.6);
}

.module-tea-60 .icon-container {
  background-color: rgba(221, 244, 115, 0.6);
}

.module-tea-60 .icon-container.light {
  background-color: rgba(247, 252, 222, 0.6);
}

.module-tea-60:hover {
  color: #273a4d;
  background: rgba(221, 244, 115, 0.6) linear-gradient(180deg, rgba(226, 244, 155, 0.66), rgba(221, 244, 115, 0.6)) repeat-x;
  border-color: color-yig(rgba(221, 244, 115, 0.6));
}

.module-tea-60:focus, .module-tea-60.focus {
  color: #273a4d;
  background: rgba(221, 244, 115, 0.6) linear-gradient(180deg, rgba(226, 244, 155, 0.66), rgba(221, 244, 115, 0.6)) repeat-x;
  border-color: color-yig(rgba(221, 244, 115, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(174, 192, 129, 0.5);
}

.module-tea-60:active, .module-tea-60.active,
.show > .module-tea-60.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(219, 243, 104, 0.6);
  background-image: none;
  border-color: rgba(216, 242, 92, 0.6);
}

.module-tea-60:active:focus, .module-tea-60.active:focus,
.show > .module-tea-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(174, 192, 129, 0.5);
}

.module-tea-60:disabled, .module-tea-60.disabled {
  color: #273a4d;
  background-color: rgba(230, 247, 151, 0.6);
  background-image: none;
  border-color: rgba(230, 247, 151, 0.6);
}

.module-tea-70 {
  color: #273a4d;
  background: rgba(230, 247, 151, 0.7) linear-gradient(180deg, rgba(232, 247, 175, 0.745), rgba(230, 247, 151, 0.7)) repeat-x;
  border-color: rgba(230, 247, 151, 0.7);
}

.module-tea-70 .icon-container {
  background-color: rgba(221, 244, 115, 0.7);
}

.module-tea-70 .icon-container.light {
  background-color: rgba(247, 252, 222, 0.7);
}

.module-tea-70:hover {
  color: #273a4d;
  background: rgba(221, 244, 115, 0.7) linear-gradient(180deg, rgba(226, 244, 149, 0.745), rgba(221, 244, 115, 0.7)) repeat-x;
  border-color: color-yig(rgba(221, 244, 115, 0.7));
}

.module-tea-70:focus, .module-tea-70.focus {
  color: #273a4d;
  background: rgba(221, 244, 115, 0.7) linear-gradient(180deg, rgba(226, 244, 149, 0.745), rgba(221, 244, 115, 0.7)) repeat-x;
  border-color: color-yig(rgba(221, 244, 115, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(183, 200, 133, 0.5);
}

.module-tea-70:active, .module-tea-70.active,
.show > .module-tea-70.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(219, 243, 104, 0.7);
  background-image: none;
  border-color: rgba(216, 242, 92, 0.7);
}

.module-tea-70:active:focus, .module-tea-70.active:focus,
.show > .module-tea-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(183, 200, 133, 0.5);
}

.module-tea-70:disabled, .module-tea-70.disabled {
  color: #273a4d;
  background-color: rgba(230, 247, 151, 0.7);
  background-image: none;
  border-color: rgba(230, 247, 151, 0.7);
}

.module-tea-80 {
  color: #273a4d;
  background: rgba(230, 247, 151, 0.8) linear-gradient(180deg, rgba(232, 247, 172, 0.83), rgba(230, 247, 151, 0.8)) repeat-x;
  border-color: rgba(230, 247, 151, 0.8);
}

.module-tea-80 .icon-container {
  background-color: rgba(221, 244, 115, 0.8);
}

.module-tea-80 .icon-container.light {
  background-color: rgba(247, 252, 222, 0.8);
}

.module-tea-80:hover {
  color: #273a4d;
  background: rgba(221, 244, 115, 0.8) linear-gradient(180deg, rgba(225, 244, 144, 0.83), rgba(221, 244, 115, 0.8)) repeat-x;
  border-color: color-yig(rgba(221, 244, 115, 0.8));
}

.module-tea-80:focus, .module-tea-80.focus {
  color: #273a4d;
  background: rgba(221, 244, 115, 0.8) linear-gradient(180deg, rgba(225, 244, 144, 0.83), rgba(221, 244, 115, 0.8)) repeat-x;
  border-color: color-yig(rgba(221, 244, 115, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(190, 207, 136, 0.5);
}

.module-tea-80:active, .module-tea-80.active,
.show > .module-tea-80.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(219, 243, 104, 0.8);
  background-image: none;
  border-color: rgba(216, 242, 92, 0.8);
}

.module-tea-80:active:focus, .module-tea-80.active:focus,
.show > .module-tea-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(190, 207, 136, 0.5);
}

.module-tea-80:disabled, .module-tea-80.disabled {
  color: #273a4d;
  background-color: rgba(230, 247, 151, 0.8);
  background-image: none;
  border-color: rgba(230, 247, 151, 0.8);
}

.module-tea-90 {
  color: #273a4d;
  background: rgba(230, 247, 151, 0.9) linear-gradient(180deg, rgba(231, 247, 169, 0.915), rgba(230, 247, 151, 0.9)) repeat-x;
  border-color: rgba(230, 247, 151, 0.9);
}

.module-tea-90 .icon-container {
  background-color: rgba(221, 244, 115, 0.9);
}

.module-tea-90 .icon-container.light {
  background-color: rgba(247, 252, 222, 0.9);
}

.module-tea-90:hover {
  color: #273a4d;
  background: rgba(221, 244, 115, 0.9) linear-gradient(180deg, rgba(224, 244, 139, 0.915), rgba(221, 244, 115, 0.9)) repeat-x;
  border-color: color-yig(rgba(221, 244, 115, 0.9));
}

.module-tea-90:focus, .module-tea-90.focus {
  color: #273a4d;
  background: rgba(221, 244, 115, 0.9) linear-gradient(180deg, rgba(224, 244, 139, 0.915), rgba(221, 244, 115, 0.9)) repeat-x;
  border-color: color-yig(rgba(221, 244, 115, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(196, 213, 138, 0.5);
}

.module-tea-90:active, .module-tea-90.active,
.show > .module-tea-90.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(219, 243, 104, 0.9);
  background-image: none;
  border-color: rgba(216, 242, 92, 0.9);
}

.module-tea-90:active:focus, .module-tea-90.active:focus,
.show > .module-tea-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 213, 138, 0.5);
}

.module-tea-90:disabled, .module-tea-90.disabled {
  color: #273a4d;
  background-color: rgba(230, 247, 151, 0.9);
  background-image: none;
  border-color: rgba(230, 247, 151, 0.9);
}

.module-weather-oak {
  color: #fff;
  background: #403b1c linear-gradient(180deg, #5a573d, #403b1c) repeat-x;
  border-color: #403b1c;
}

.module-weather-oak .icon-container {
  background-color: #252210;
}

.module-weather-oak .icon-container.light {
  background-color: #756c33;
}

.module-weather-oak:hover {
  color: #fff;
  background: #252210 linear-gradient(180deg, #434233, #252210) repeat-x;
  border-color: color-yig(#252210);
}

.module-weather-oak:focus, .module-weather-oak.focus {
  color: #fff;
  background: #252210 linear-gradient(180deg, #434233, #252210) repeat-x;
  border-color: color-yig(#252210);
  box-shadow: 0 0 0 0.2rem rgba(93, 88, 62, 0.5);
}

.module-weather-oak:active, .module-weather-oak.active,
.show > .module-weather-oak.dropdown-toggle {
  color: #fff;
  background-color: #1d1a0c;
  background-image: none;
  border-color: #141209;
}

.module-weather-oak:active:focus, .module-weather-oak.active:focus,
.show > .module-weather-oak.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(93, 88, 62, 0.5);
}

.module-weather-oak:disabled, .module-weather-oak.disabled {
  color: #fff;
  background-color: #403b1c;
  background-image: none;
  border-color: #403b1c;
}

.module-weather-oak-10 {
  color: #fff;
  background: rgba(64, 59, 28, 0.1) linear-gradient(180deg, rgba(198, 202, 199, 0.235), rgba(64, 59, 28, 0.1)) repeat-x;
  border-color: rgba(64, 59, 28, 0.1);
}

.module-weather-oak-10 .icon-container {
  background-color: rgba(37, 34, 16, 0.1);
}

.module-weather-oak-10 .icon-container.light {
  background-color: rgba(117, 108, 51, 0.1);
}

.module-weather-oak-10:hover {
  color: #fff;
  background: rgba(37, 34, 16, 0.1) linear-gradient(180deg, rgba(192, 197, 196, 0.235), rgba(37, 34, 16, 0.1)) repeat-x;
  border-color: color-yig(rgba(37, 34, 16, 0.1));
}

.module-weather-oak-10:focus, .module-weather-oak-10.focus {
  color: #fff;
  background: rgba(37, 34, 16, 0.1) linear-gradient(180deg, rgba(192, 197, 196, 0.235), rgba(37, 34, 16, 0.1)) repeat-x;
  border-color: color-yig(rgba(37, 34, 16, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(211, 210, 203, 0.5);
}

.module-weather-oak-10:active, .module-weather-oak-10.active,
.show > .module-weather-oak-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 26, 12, 0.1);
  background-image: none;
  border-color: rgba(20, 18, 9, 0.1);
}

.module-weather-oak-10:active:focus, .module-weather-oak-10.active:focus,
.show > .module-weather-oak-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 210, 203, 0.5);
}

.module-weather-oak-10:disabled, .module-weather-oak-10.disabled {
  color: #fff;
  background-color: rgba(64, 59, 28, 0.1);
  background-image: none;
  border-color: rgba(64, 59, 28, 0.1);
}

.module-weather-oak-20 {
  color: #fff;
  background: rgba(64, 59, 28, 0.2) linear-gradient(180deg, rgba(171, 173, 164, 0.32), rgba(64, 59, 28, 0.2)) repeat-x;
  border-color: rgba(64, 59, 28, 0.2);
}

.module-weather-oak-20 .icon-container {
  background-color: rgba(37, 34, 16, 0.2);
}

.module-weather-oak-20 .icon-container.light {
  background-color: rgba(117, 108, 51, 0.2);
}

.module-weather-oak-20:hover {
  color: #fff;
  background: rgba(37, 34, 16, 0.2) linear-gradient(180deg, rgba(160, 164, 160, 0.32), rgba(37, 34, 16, 0.2)) repeat-x;
  border-color: color-yig(rgba(37, 34, 16, 0.2));
}

.module-weather-oak-20:focus, .module-weather-oak-20.focus {
  color: #fff;
  background: rgba(37, 34, 16, 0.2) linear-gradient(180deg, rgba(160, 164, 160, 0.32), rgba(37, 34, 16, 0.2)) repeat-x;
  border-color: color-yig(rgba(37, 34, 16, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(181, 179, 167, 0.5);
}

.module-weather-oak-20:active, .module-weather-oak-20.active,
.show > .module-weather-oak-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 26, 12, 0.2);
  background-image: none;
  border-color: rgba(20, 18, 9, 0.2);
}

.module-weather-oak-20:active:focus, .module-weather-oak-20.active:focus,
.show > .module-weather-oak-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(181, 179, 167, 0.5);
}

.module-weather-oak-20:disabled, .module-weather-oak-20.disabled {
  color: #fff;
  background-color: rgba(64, 59, 28, 0.2);
  background-image: none;
  border-color: rgba(64, 59, 28, 0.2);
}

.module-weather-oak-30 {
  color: #fff;
  background: rgba(64, 59, 28, 0.3) linear-gradient(180deg, rgba(151, 152, 139, 0.405), rgba(64, 59, 28, 0.3)) repeat-x;
  border-color: rgba(64, 59, 28, 0.3);
}

.module-weather-oak-30 .icon-container {
  background-color: rgba(37, 34, 16, 0.3);
}

.module-weather-oak-30 .icon-container.light {
  background-color: rgba(117, 108, 51, 0.3);
}

.module-weather-oak-30:hover {
  color: #fff;
  background: rgba(37, 34, 16, 0.3) linear-gradient(180deg, rgba(138, 140, 133, 0.405), rgba(37, 34, 16, 0.3)) repeat-x;
  border-color: color-yig(rgba(37, 34, 16, 0.3));
}

.module-weather-oak-30:focus, .module-weather-oak-30.focus {
  color: #fff;
  background: rgba(37, 34, 16, 0.3) linear-gradient(180deg, rgba(138, 140, 133, 0.405), rgba(37, 34, 16, 0.3)) repeat-x;
  border-color: color-yig(rgba(37, 34, 16, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(160, 157, 142, 0.5);
}

.module-weather-oak-30:active, .module-weather-oak-30.active,
.show > .module-weather-oak-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 26, 12, 0.3);
  background-image: none;
  border-color: rgba(20, 18, 9, 0.3);
}

.module-weather-oak-30:active:focus, .module-weather-oak-30.active:focus,
.show > .module-weather-oak-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(160, 157, 142, 0.5);
}

.module-weather-oak-30:disabled, .module-weather-oak-30.disabled {
  color: #fff;
  background-color: rgba(64, 59, 28, 0.3);
  background-image: none;
  border-color: rgba(64, 59, 28, 0.3);
}

.module-weather-oak-40 {
  color: #fff;
  background: rgba(64, 59, 28, 0.4) linear-gradient(180deg, rgba(136, 136, 120, 0.49), rgba(64, 59, 28, 0.4)) repeat-x;
  border-color: rgba(64, 59, 28, 0.4);
}

.module-weather-oak-40 .icon-container {
  background-color: rgba(37, 34, 16, 0.4);
}

.module-weather-oak-40 .icon-container.light {
  background-color: rgba(117, 108, 51, 0.4);
}

.module-weather-oak-40:hover {
  color: #fff;
  background: rgba(37, 34, 16, 0.4) linear-gradient(180deg, rgba(120, 122, 113, 0.49), rgba(37, 34, 16, 0.4)) repeat-x;
  border-color: color-yig(rgba(37, 34, 16, 0.4));
}

.module-weather-oak-40:focus, .module-weather-oak-40.focus {
  color: #fff;
  background: rgba(37, 34, 16, 0.4) linear-gradient(180deg, rgba(120, 122, 113, 0.49), rgba(37, 34, 16, 0.4)) repeat-x;
  border-color: color-yig(rgba(37, 34, 16, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(143, 140, 122, 0.5);
}

.module-weather-oak-40:active, .module-weather-oak-40.active,
.show > .module-weather-oak-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 26, 12, 0.4);
  background-image: none;
  border-color: rgba(20, 18, 9, 0.4);
}

.module-weather-oak-40:active:focus, .module-weather-oak-40.active:focus,
.show > .module-weather-oak-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(143, 140, 122, 0.5);
}

.module-weather-oak-40:disabled, .module-weather-oak-40.disabled {
  color: #fff;
  background-color: rgba(64, 59, 28, 0.4);
  background-image: none;
  border-color: rgba(64, 59, 28, 0.4);
}

.module-weather-oak-50 {
  color: #fff;
  background: rgba(64, 59, 28, 0.5) linear-gradient(180deg, rgba(124, 123, 105, 0.575), rgba(64, 59, 28, 0.5)) repeat-x;
  border-color: rgba(64, 59, 28, 0.5);
}

.module-weather-oak-50 .icon-container {
  background-color: rgba(37, 34, 16, 0.5);
}

.module-weather-oak-50 .icon-container.light {
  background-color: rgba(117, 108, 51, 0.5);
}

.module-weather-oak-50:hover {
  color: #fff;
  background: rgba(37, 34, 16, 0.5) linear-gradient(180deg, rgba(107, 107, 97, 0.575), rgba(37, 34, 16, 0.5)) repeat-x;
  border-color: color-yig(rgba(37, 34, 16, 0.5));
}

.module-weather-oak-50:focus, .module-weather-oak-50.focus {
  color: #fff;
  background: rgba(37, 34, 16, 0.5) linear-gradient(180deg, rgba(107, 107, 97, 0.575), rgba(37, 34, 16, 0.5)) repeat-x;
  border-color: color-yig(rgba(37, 34, 16, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(130, 127, 107, 0.5);
}

.module-weather-oak-50:active, .module-weather-oak-50.active,
.show > .module-weather-oak-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 26, 12, 0.5);
  background-image: none;
  border-color: rgba(20, 18, 9, 0.5);
}

.module-weather-oak-50:active:focus, .module-weather-oak-50.active:focus,
.show > .module-weather-oak-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 127, 107, 0.5);
}

.module-weather-oak-50:disabled, .module-weather-oak-50.disabled {
  color: #fff;
  background-color: rgba(64, 59, 28, 0.5);
  background-image: none;
  border-color: rgba(64, 59, 28, 0.5);
}

.module-weather-oak-60 {
  color: #fff;
  background: rgba(64, 59, 28, 0.6) linear-gradient(180deg, rgba(115, 113, 93, 0.66), rgba(64, 59, 28, 0.6)) repeat-x;
  border-color: rgba(64, 59, 28, 0.6);
}

.module-weather-oak-60 .icon-container {
  background-color: rgba(37, 34, 16, 0.6);
}

.module-weather-oak-60 .icon-container.light {
  background-color: rgba(117, 108, 51, 0.6);
}

.module-weather-oak-60:hover {
  color: #fff;
  background: rgba(37, 34, 16, 0.6) linear-gradient(180deg, rgba(96, 96, 85, 0.66), rgba(37, 34, 16, 0.6)) repeat-x;
  border-color: color-yig(rgba(37, 34, 16, 0.6));
}

.module-weather-oak-60:focus, .module-weather-oak-60.focus {
  color: #fff;
  background: rgba(37, 34, 16, 0.6) linear-gradient(180deg, rgba(96, 96, 85, 0.66), rgba(37, 34, 16, 0.6)) repeat-x;
  border-color: color-yig(rgba(37, 34, 16, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(120, 116, 94, 0.5);
}

.module-weather-oak-60:active, .module-weather-oak-60.active,
.show > .module-weather-oak-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 26, 12, 0.6);
  background-image: none;
  border-color: rgba(20, 18, 9, 0.6);
}

.module-weather-oak-60:active:focus, .module-weather-oak-60.active:focus,
.show > .module-weather-oak-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 116, 94, 0.5);
}

.module-weather-oak-60:disabled, .module-weather-oak-60.disabled {
  color: #fff;
  background-color: rgba(64, 59, 28, 0.6);
  background-image: none;
  border-color: rgba(64, 59, 28, 0.6);
}

.module-weather-oak-70 {
  color: #fff;
  background: rgba(64, 59, 28, 0.7) linear-gradient(180deg, rgba(107, 105, 83, 0.745), rgba(64, 59, 28, 0.7)) repeat-x;
  border-color: rgba(64, 59, 28, 0.7);
}

.module-weather-oak-70 .icon-container {
  background-color: rgba(37, 34, 16, 0.7);
}

.module-weather-oak-70 .icon-container.light {
  background-color: rgba(117, 108, 51, 0.7);
}

.module-weather-oak-70:hover {
  color: #fff;
  background: rgba(37, 34, 16, 0.7) linear-gradient(180deg, rgba(87, 86, 74, 0.745), rgba(37, 34, 16, 0.7)) repeat-x;
  border-color: color-yig(rgba(37, 34, 16, 0.7));
}

.module-weather-oak-70:focus, .module-weather-oak-70.focus {
  color: #fff;
  background: rgba(37, 34, 16, 0.7) linear-gradient(180deg, rgba(87, 86, 74, 0.745), rgba(37, 34, 16, 0.7)) repeat-x;
  border-color: color-yig(rgba(37, 34, 16, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(111, 107, 84, 0.5);
}

.module-weather-oak-70:active, .module-weather-oak-70.active,
.show > .module-weather-oak-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 26, 12, 0.7);
  background-image: none;
  border-color: rgba(20, 18, 9, 0.7);
}

.module-weather-oak-70:active:focus, .module-weather-oak-70.active:focus,
.show > .module-weather-oak-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 107, 84, 0.5);
}

.module-weather-oak-70:disabled, .module-weather-oak-70.disabled {
  color: #fff;
  background-color: rgba(64, 59, 28, 0.7);
  background-image: none;
  border-color: rgba(64, 59, 28, 0.7);
}

.module-weather-oak-80 {
  color: #fff;
  background: rgba(64, 59, 28, 0.8) linear-gradient(180deg, rgba(100, 98, 74, 0.83), rgba(64, 59, 28, 0.8)) repeat-x;
  border-color: rgba(64, 59, 28, 0.8);
}

.module-weather-oak-80 .icon-container {
  background-color: rgba(37, 34, 16, 0.8);
}

.module-weather-oak-80 .icon-container.light {
  background-color: rgba(117, 108, 51, 0.8);
}

.module-weather-oak-80:hover {
  color: #fff;
  background: rgba(37, 34, 16, 0.8) linear-gradient(180deg, rgba(79, 79, 65, 0.83), rgba(37, 34, 16, 0.8)) repeat-x;
  border-color: color-yig(rgba(37, 34, 16, 0.8));
}

.module-weather-oak-80:focus, .module-weather-oak-80.focus {
  color: #fff;
  background: rgba(37, 34, 16, 0.8) linear-gradient(180deg, rgba(79, 79, 65, 0.83), rgba(37, 34, 16, 0.8)) repeat-x;
  border-color: color-yig(rgba(37, 34, 16, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(104, 100, 76, 0.5);
}

.module-weather-oak-80:active, .module-weather-oak-80.active,
.show > .module-weather-oak-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 26, 12, 0.8);
  background-image: none;
  border-color: rgba(20, 18, 9, 0.8);
}

.module-weather-oak-80:active:focus, .module-weather-oak-80.active:focus,
.show > .module-weather-oak-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(104, 100, 76, 0.5);
}

.module-weather-oak-80:disabled, .module-weather-oak-80.disabled {
  color: #fff;
  background-color: rgba(64, 59, 28, 0.8);
  background-image: none;
  border-color: rgba(64, 59, 28, 0.8);
}

.module-weather-oak-90 {
  color: #fff;
  background: rgba(64, 59, 28, 0.9) linear-gradient(180deg, rgba(95, 92, 67, 0.915), rgba(64, 59, 28, 0.9)) repeat-x;
  border-color: rgba(64, 59, 28, 0.9);
}

.module-weather-oak-90 .icon-container {
  background-color: rgba(37, 34, 16, 0.9);
}

.module-weather-oak-90 .icon-container.light {
  background-color: rgba(117, 108, 51, 0.9);
}

.module-weather-oak-90:hover {
  color: #fff;
  background: rgba(37, 34, 16, 0.9) linear-gradient(180deg, rgba(73, 72, 58, 0.915), rgba(37, 34, 16, 0.9)) repeat-x;
  border-color: color-yig(rgba(37, 34, 16, 0.9));
}

.module-weather-oak-90:focus, .module-weather-oak-90.focus {
  color: #fff;
  background: rgba(37, 34, 16, 0.9) linear-gradient(180deg, rgba(73, 72, 58, 0.915), rgba(37, 34, 16, 0.9)) repeat-x;
  border-color: color-yig(rgba(37, 34, 16, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(98, 94, 68, 0.5);
}

.module-weather-oak-90:active, .module-weather-oak-90.active,
.show > .module-weather-oak-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 26, 12, 0.9);
  background-image: none;
  border-color: rgba(20, 18, 9, 0.9);
}

.module-weather-oak-90:active:focus, .module-weather-oak-90.active:focus,
.show > .module-weather-oak-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(98, 94, 68, 0.5);
}

.module-weather-oak-90:disabled, .module-weather-oak-90.disabled {
  color: #fff;
  background-color: rgba(64, 59, 28, 0.9);
  background-image: none;
  border-color: rgba(64, 59, 28, 0.9);
}

.module-avocado {
  color: #fff;
  background: #5c541d linear-gradient(180deg, #726c3e, #5c541d) repeat-x;
  border-color: #5c541d;
}

.module-avocado .icon-container {
  background-color: #3f3914;
}

.module-avocado .icon-container.light {
  background-color: #96892f;
}

.module-avocado:hover {
  color: #fff;
  background: #3f3914 linear-gradient(180deg, #595636, #3f3914) repeat-x;
  border-color: color-yig(#3f3914);
}

.module-avocado:focus, .module-avocado.focus {
  color: #fff;
  background: #3f3914 linear-gradient(180deg, #595636, #3f3914) repeat-x;
  border-color: color-yig(#3f3914);
  box-shadow: 0 0 0 0.2rem rgba(116, 110, 63, 0.5);
}

.module-avocado:active, .module-avocado.active,
.show > .module-avocado.dropdown-toggle {
  color: #fff;
  background-color: #353111;
  background-image: none;
  border-color: #2c280e;
}

.module-avocado:active:focus, .module-avocado.active:focus,
.show > .module-avocado.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(116, 110, 63, 0.5);
}

.module-avocado:disabled, .module-avocado.disabled {
  color: #fff;
  background-color: #5c541d;
  background-image: none;
  border-color: #5c541d;
}

.module-avocado-10 {
  color: #fff;
  background: rgba(92, 84, 29, 0.1) linear-gradient(180deg, rgba(204, 208, 199, 0.235), rgba(92, 84, 29, 0.1)) repeat-x;
  border-color: rgba(92, 84, 29, 0.1);
}

.module-avocado-10 .icon-container {
  background-color: rgba(63, 57, 20, 0.1);
}

.module-avocado-10 .icon-container.light {
  background-color: rgba(150, 137, 47, 0.1);
}

.module-avocado-10:hover {
  color: #fff;
  background: rgba(63, 57, 20, 0.1) linear-gradient(180deg, rgba(198, 202, 197, 0.235), rgba(63, 57, 20, 0.1)) repeat-x;
  border-color: color-yig(rgba(63, 57, 20, 0.1));
}

.module-avocado-10:focus, .module-avocado-10.focus {
  color: #fff;
  background: rgba(63, 57, 20, 0.1) linear-gradient(180deg, rgba(198, 202, 197, 0.235), rgba(63, 57, 20, 0.1)) repeat-x;
  border-color: color-yig(rgba(63, 57, 20, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(218, 216, 203, 0.5);
}

.module-avocado-10:active, .module-avocado-10.active,
.show > .module-avocado-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(53, 49, 17, 0.1);
  background-image: none;
  border-color: rgba(44, 40, 14, 0.1);
}

.module-avocado-10:active:focus, .module-avocado-10.active:focus,
.show > .module-avocado-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 216, 203, 0.5);
}

.module-avocado-10:disabled, .module-avocado-10.disabled {
  color: #fff;
  background-color: rgba(92, 84, 29, 0.1);
  background-image: none;
  border-color: rgba(92, 84, 29, 0.1);
}

.module-avocado-20 {
  color: #fff;
  background: rgba(92, 84, 29, 0.2) linear-gradient(180deg, rgba(182, 183, 165, 0.32), rgba(92, 84, 29, 0.2)) repeat-x;
  border-color: rgba(92, 84, 29, 0.2);
}

.module-avocado-20 .icon-container {
  background-color: rgba(63, 57, 20, 0.2);
}

.module-avocado-20 .icon-container.light {
  background-color: rgba(150, 137, 47, 0.2);
}

.module-avocado-20:hover {
  color: #fff;
  background: rgba(63, 57, 20, 0.2) linear-gradient(180deg, rgba(170, 173, 161, 0.32), rgba(63, 57, 20, 0.2)) repeat-x;
  border-color: color-yig(rgba(63, 57, 20, 0.2));
}

.module-avocado-20:focus, .module-avocado-20.focus {
  color: #fff;
  background: rgba(63, 57, 20, 0.2) linear-gradient(180deg, rgba(170, 173, 161, 0.32), rgba(63, 57, 20, 0.2)) repeat-x;
  border-color: color-yig(rgba(63, 57, 20, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(192, 189, 168, 0.5);
}

.module-avocado-20:active, .module-avocado-20.active,
.show > .module-avocado-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(53, 49, 17, 0.2);
  background-image: none;
  border-color: rgba(44, 40, 14, 0.2);
}

.module-avocado-20:active:focus, .module-avocado-20.active:focus,
.show > .module-avocado-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(192, 189, 168, 0.5);
}

.module-avocado-20:disabled, .module-avocado-20.disabled {
  color: #fff;
  background-color: rgba(92, 84, 29, 0.2);
  background-image: none;
  border-color: rgba(92, 84, 29, 0.2);
}

.module-avocado-30 {
  color: #fff;
  background: rgba(92, 84, 29, 0.3) linear-gradient(180deg, rgba(165, 165, 140, 0.405), rgba(92, 84, 29, 0.3)) repeat-x;
  border-color: rgba(92, 84, 29, 0.3);
}

.module-avocado-30 .icon-container {
  background-color: rgba(63, 57, 20, 0.3);
}

.module-avocado-30 .icon-container.light {
  background-color: rgba(150, 137, 47, 0.3);
}

.module-avocado-30:hover {
  color: #fff;
  background: rgba(63, 57, 20, 0.3) linear-gradient(180deg, rgba(150, 151, 135, 0.405), rgba(63, 57, 20, 0.3)) repeat-x;
  border-color: color-yig(rgba(63, 57, 20, 0.3));
}

.module-avocado-30:focus, .module-avocado-30.focus {
  color: #fff;
  background: rgba(63, 57, 20, 0.3) linear-gradient(180deg, rgba(150, 151, 135, 0.405), rgba(63, 57, 20, 0.3)) repeat-x;
  border-color: color-yig(rgba(63, 57, 20, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(174, 170, 142, 0.5);
}

.module-avocado-30:active, .module-avocado-30.active,
.show > .module-avocado-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(53, 49, 17, 0.3);
  background-image: none;
  border-color: rgba(44, 40, 14, 0.3);
}

.module-avocado-30:active:focus, .module-avocado-30.active:focus,
.show > .module-avocado-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(174, 170, 142, 0.5);
}

.module-avocado-30:disabled, .module-avocado-30.disabled {
  color: #fff;
  background-color: rgba(92, 84, 29, 0.3);
  background-image: none;
  border-color: rgba(92, 84, 29, 0.3);
}

.module-avocado-40 {
  color: #fff;
  background: rgba(92, 84, 29, 0.4) linear-gradient(180deg, rgba(152, 151, 120, 0.49), rgba(92, 84, 29, 0.4)) repeat-x;
  border-color: rgba(92, 84, 29, 0.4);
}

.module-avocado-40 .icon-container {
  background-color: rgba(63, 57, 20, 0.4);
}

.module-avocado-40 .icon-container.light {
  background-color: rgba(150, 137, 47, 0.4);
}

.module-avocado-40:hover {
  color: #fff;
  background: rgba(63, 57, 20, 0.4) linear-gradient(180deg, rgba(135, 135, 115, 0.49), rgba(63, 57, 20, 0.4)) repeat-x;
  border-color: color-yig(rgba(63, 57, 20, 0.4));
}

.module-avocado-40:focus, .module-avocado-40.focus {
  color: #fff;
  background: rgba(63, 57, 20, 0.4) linear-gradient(180deg, rgba(135, 135, 115, 0.49), rgba(63, 57, 20, 0.4)) repeat-x;
  border-color: color-yig(rgba(63, 57, 20, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(159, 155, 123, 0.5);
}

.module-avocado-40:active, .module-avocado-40.active,
.show > .module-avocado-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(53, 49, 17, 0.4);
  background-image: none;
  border-color: rgba(44, 40, 14, 0.4);
}

.module-avocado-40:active:focus, .module-avocado-40.active:focus,
.show > .module-avocado-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(159, 155, 123, 0.5);
}

.module-avocado-40:disabled, .module-avocado-40.disabled {
  color: #fff;
  background-color: rgba(92, 84, 29, 0.4);
  background-image: none;
  border-color: rgba(92, 84, 29, 0.4);
}

.module-avocado-50 {
  color: #fff;
  background: rgba(92, 84, 29, 0.5) linear-gradient(180deg, rgba(143, 140, 106, 0.575), rgba(92, 84, 29, 0.5)) repeat-x;
  border-color: rgba(92, 84, 29, 0.5);
}

.module-avocado-50 .icon-container {
  background-color: rgba(63, 57, 20, 0.5);
}

.module-avocado-50 .icon-container.light {
  background-color: rgba(150, 137, 47, 0.5);
}

.module-avocado-50:hover {
  color: #fff;
  background: rgba(63, 57, 20, 0.5) linear-gradient(180deg, rgba(124, 122, 100, 0.575), rgba(63, 57, 20, 0.5)) repeat-x;
  border-color: color-yig(rgba(63, 57, 20, 0.5));
}

.module-avocado-50:focus, .module-avocado-50.focus {
  color: #fff;
  background: rgba(63, 57, 20, 0.5) linear-gradient(180deg, rgba(124, 122, 100, 0.575), rgba(63, 57, 20, 0.5)) repeat-x;
  border-color: color-yig(rgba(63, 57, 20, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(148, 143, 107, 0.5);
}

.module-avocado-50:active, .module-avocado-50.active,
.show > .module-avocado-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(53, 49, 17, 0.5);
  background-image: none;
  border-color: rgba(44, 40, 14, 0.5);
}

.module-avocado-50:active:focus, .module-avocado-50.active:focus,
.show > .module-avocado-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(148, 143, 107, 0.5);
}

.module-avocado-50:disabled, .module-avocado-50.disabled {
  color: #fff;
  background-color: rgba(92, 84, 29, 0.5);
  background-image: none;
  border-color: rgba(92, 84, 29, 0.5);
}

.module-avocado-60 {
  color: #fff;
  background: rgba(92, 84, 29, 0.6) linear-gradient(180deg, rgba(135, 131, 93, 0.66), rgba(92, 84, 29, 0.6)) repeat-x;
  border-color: rgba(92, 84, 29, 0.6);
}

.module-avocado-60 .icon-container {
  background-color: rgba(63, 57, 20, 0.6);
}

.module-avocado-60 .icon-container.light {
  background-color: rgba(150, 137, 47, 0.6);
}

.module-avocado-60:hover {
  color: #fff;
  background: rgba(63, 57, 20, 0.6) linear-gradient(180deg, rgba(114, 112, 87, 0.66), rgba(63, 57, 20, 0.6)) repeat-x;
  border-color: color-yig(rgba(63, 57, 20, 0.6));
}

.module-avocado-60:focus, .module-avocado-60.focus {
  color: #fff;
  background: rgba(63, 57, 20, 0.6) linear-gradient(180deg, rgba(114, 112, 87, 0.66), rgba(63, 57, 20, 0.6)) repeat-x;
  border-color: color-yig(rgba(63, 57, 20, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(140, 134, 95, 0.5);
}

.module-avocado-60:active, .module-avocado-60.active,
.show > .module-avocado-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(53, 49, 17, 0.6);
  background-image: none;
  border-color: rgba(44, 40, 14, 0.6);
}

.module-avocado-60:active:focus, .module-avocado-60.active:focus,
.show > .module-avocado-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(140, 134, 95, 0.5);
}

.module-avocado-60:disabled, .module-avocado-60.disabled {
  color: #fff;
  background-color: rgba(92, 84, 29, 0.6);
  background-image: none;
  border-color: rgba(92, 84, 29, 0.6);
}

.module-avocado-70 {
  color: #fff;
  background: rgba(92, 84, 29, 0.7) linear-gradient(180deg, rgba(128, 124, 84, 0.745), rgba(92, 84, 29, 0.7)) repeat-x;
  border-color: rgba(92, 84, 29, 0.7);
}

.module-avocado-70 .icon-container {
  background-color: rgba(63, 57, 20, 0.7);
}

.module-avocado-70 .icon-container.light {
  background-color: rgba(150, 137, 47, 0.7);
}

.module-avocado-70:hover {
  color: #fff;
  background: rgba(63, 57, 20, 0.7) linear-gradient(180deg, rgba(106, 104, 77, 0.745), rgba(63, 57, 20, 0.7)) repeat-x;
  border-color: color-yig(rgba(63, 57, 20, 0.7));
}

.module-avocado-70:focus, .module-avocado-70.focus {
  color: #fff;
  background: rgba(63, 57, 20, 0.7) linear-gradient(180deg, rgba(106, 104, 77, 0.745), rgba(63, 57, 20, 0.7)) repeat-x;
  border-color: color-yig(rgba(63, 57, 20, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(132, 126, 85, 0.5);
}

.module-avocado-70:active, .module-avocado-70.active,
.show > .module-avocado-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(53, 49, 17, 0.7);
  background-image: none;
  border-color: rgba(44, 40, 14, 0.7);
}

.module-avocado-70:active:focus, .module-avocado-70.active:focus,
.show > .module-avocado-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(132, 126, 85, 0.5);
}

.module-avocado-70:disabled, .module-avocado-70.disabled {
  color: #fff;
  background-color: rgba(92, 84, 29, 0.7);
  background-image: none;
  border-color: rgba(92, 84, 29, 0.7);
}

.module-avocado-80 {
  color: #fff;
  background: rgba(92, 84, 29, 0.8) linear-gradient(180deg, rgba(123, 118, 75, 0.83), rgba(92, 84, 29, 0.8)) repeat-x;
  border-color: rgba(92, 84, 29, 0.8);
}

.module-avocado-80 .icon-container {
  background-color: rgba(63, 57, 20, 0.8);
}

.module-avocado-80 .icon-container.light {
  background-color: rgba(150, 137, 47, 0.8);
}

.module-avocado-80:hover {
  color: #fff;
  background: rgba(63, 57, 20, 0.8) linear-gradient(180deg, rgba(100, 97, 68, 0.83), rgba(63, 57, 20, 0.8)) repeat-x;
  border-color: color-yig(rgba(63, 57, 20, 0.8));
}

.module-avocado-80:focus, .module-avocado-80.focus {
  color: #fff;
  background: rgba(63, 57, 20, 0.8) linear-gradient(180deg, rgba(100, 97, 68, 0.83), rgba(63, 57, 20, 0.8)) repeat-x;
  border-color: color-yig(rgba(63, 57, 20, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(126, 120, 76, 0.5);
}

.module-avocado-80:active, .module-avocado-80.active,
.show > .module-avocado-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(53, 49, 17, 0.8);
  background-image: none;
  border-color: rgba(44, 40, 14, 0.8);
}

.module-avocado-80:active:focus, .module-avocado-80.active:focus,
.show > .module-avocado-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(126, 120, 76, 0.5);
}

.module-avocado-80:disabled, .module-avocado-80.disabled {
  color: #fff;
  background-color: rgba(92, 84, 29, 0.8);
  background-image: none;
  border-color: rgba(92, 84, 29, 0.8);
}

.module-avocado-90 {
  color: #fff;
  background: rgba(92, 84, 29, 0.9) linear-gradient(180deg, rgba(118, 113, 68, 0.915), rgba(92, 84, 29, 0.9)) repeat-x;
  border-color: rgba(92, 84, 29, 0.9);
}

.module-avocado-90 .icon-container {
  background-color: rgba(63, 57, 20, 0.9);
}

.module-avocado-90 .icon-container.light {
  background-color: rgba(150, 137, 47, 0.9);
}

.module-avocado-90:hover {
  color: #fff;
  background: rgba(63, 57, 20, 0.9) linear-gradient(180deg, rgba(94, 91, 61, 0.915), rgba(63, 57, 20, 0.9)) repeat-x;
  border-color: color-yig(rgba(63, 57, 20, 0.9));
}

.module-avocado-90:focus, .module-avocado-90.focus {
  color: #fff;
  background: rgba(63, 57, 20, 0.9) linear-gradient(180deg, rgba(94, 91, 61, 0.915), rgba(63, 57, 20, 0.9)) repeat-x;
  border-color: color-yig(rgba(63, 57, 20, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(121, 114, 69, 0.5);
}

.module-avocado-90:active, .module-avocado-90.active,
.show > .module-avocado-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(53, 49, 17, 0.9);
  background-image: none;
  border-color: rgba(44, 40, 14, 0.9);
}

.module-avocado-90:active:focus, .module-avocado-90.active:focus,
.show > .module-avocado-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(121, 114, 69, 0.5);
}

.module-avocado-90:disabled, .module-avocado-90.disabled {
  color: #fff;
  background-color: rgba(92, 84, 29, 0.9);
  background-image: none;
  border-color: rgba(92, 84, 29, 0.9);
}

.module-olive {
  color: #fff;
  background: #a08c0d linear-gradient(180deg, #ac9c31, #a08c0d) repeat-x;
  border-color: #a08c0d;
}

.module-olive .icon-container {
  background-color: #7d6d0a;
}

.module-olive .icon-container.light {
  background-color: #e7ca13;
}

.module-olive:hover {
  color: #fff;
  background: #7d6d0a linear-gradient(180deg, #8e812e, #7d6d0a) repeat-x;
  border-color: color-yig(#7d6d0a);
}

.module-olive:focus, .module-olive.focus {
  color: #fff;
  background: #7d6d0a linear-gradient(180deg, #8e812e, #7d6d0a) repeat-x;
  border-color: color-yig(#7d6d0a);
  box-shadow: 0 0 0 0.2rem rgba(174, 157, 49, 0.5);
}

.module-olive:active, .module-olive.active,
.show > .module-olive.dropdown-toggle {
  color: #fff;
  background-color: #716309;
  background-image: none;
  border-color: #655808;
}

.module-olive:active:focus, .module-olive.active:focus,
.show > .module-olive.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(174, 157, 49, 0.5);
}

.module-olive:disabled, .module-olive.disabled {
  color: #fff;
  background-color: #a08c0d;
  background-image: none;
  border-color: #a08c0d;
}

.module-olive-10 {
  color: #fff;
  background: rgba(160, 140, 13, 0.1) linear-gradient(180deg, rgba(220, 221, 196, 0.235), rgba(160, 140, 13, 0.1)) repeat-x;
  border-color: rgba(160, 140, 13, 0.1);
}

.module-olive-10 .icon-container {
  background-color: rgba(125, 109, 10, 0.1);
}

.module-olive-10 .icon-container.light {
  background-color: rgba(231, 202, 19, 0.1);
}

.module-olive-10:hover {
  color: #fff;
  background: rgba(125, 109, 10, 0.1) linear-gradient(180deg, rgba(212, 214, 195, 0.235), rgba(125, 109, 10, 0.1)) repeat-x;
  border-color: color-yig(rgba(125, 109, 10, 0.1));
}

.module-olive-10:focus, .module-olive-10.focus {
  color: #fff;
  background: rgba(125, 109, 10, 0.1) linear-gradient(180deg, rgba(212, 214, 195, 0.235), rgba(125, 109, 10, 0.1)) repeat-x;
  border-color: color-yig(rgba(125, 109, 10, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(233, 229, 199, 0.5);
}

.module-olive-10:active, .module-olive-10.active,
.show > .module-olive-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(113, 99, 9, 0.1);
  background-image: none;
  border-color: rgba(101, 88, 8, 0.1);
}

.module-olive-10:active:focus, .module-olive-10.active:focus,
.show > .module-olive-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 229, 199, 0.5);
}

.module-olive-10:disabled, .module-olive-10.disabled {
  color: #fff;
  background-color: rgba(160, 140, 13, 0.1);
  background-image: none;
  border-color: rgba(160, 140, 13, 0.1);
}

.module-olive-20 {
  color: #fff;
  background: rgba(160, 140, 13, 0.2) linear-gradient(180deg, rgba(208, 204, 158, 0.32), rgba(160, 140, 13, 0.2)) repeat-x;
  border-color: rgba(160, 140, 13, 0.2);
}

.module-olive-20 .icon-container {
  background-color: rgba(125, 109, 10, 0.2);
}

.module-olive-20 .icon-container.light {
  background-color: rgba(231, 202, 19, 0.2);
}

.module-olive-20:hover {
  color: #fff;
  background: rgba(125, 109, 10, 0.2) linear-gradient(180deg, rgba(194, 192, 157, 0.32), rgba(125, 109, 10, 0.2)) repeat-x;
  border-color: color-yig(rgba(125, 109, 10, 0.2));
}

.module-olive-20:focus, .module-olive-20.focus {
  color: #fff;
  background: rgba(125, 109, 10, 0.2) linear-gradient(180deg, rgba(194, 192, 157, 0.32), rgba(125, 109, 10, 0.2)) repeat-x;
  border-color: color-yig(rgba(125, 109, 10, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(218, 211, 162, 0.5);
}

.module-olive-20:active, .module-olive-20.active,
.show > .module-olive-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(113, 99, 9, 0.2);
  background-image: none;
  border-color: rgba(101, 88, 8, 0.2);
}

.module-olive-20:active:focus, .module-olive-20.active:focus,
.show > .module-olive-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 211, 162, 0.5);
}

.module-olive-20:disabled, .module-olive-20.disabled {
  color: #fff;
  background-color: rgba(160, 140, 13, 0.2);
  background-image: none;
  border-color: rgba(160, 140, 13, 0.2);
}

.module-olive-30 {
  color: #fff;
  background: rgba(160, 140, 13, 0.3) linear-gradient(180deg, rgba(199, 193, 132, 0.405), rgba(160, 140, 13, 0.3)) repeat-x;
  border-color: rgba(160, 140, 13, 0.3);
}

.module-olive-30 .icon-container {
  background-color: rgba(125, 109, 10, 0.3);
}

.module-olive-30 .icon-container.light {
  background-color: rgba(231, 202, 19, 0.3);
}

.module-olive-30:hover {
  color: #fff;
  background: rgba(125, 109, 10, 0.3) linear-gradient(180deg, rgba(181, 177, 130, 0.405), rgba(125, 109, 10, 0.3)) repeat-x;
  border-color: color-yig(rgba(125, 109, 10, 0.3));
}

.module-olive-30:focus, .module-olive-30.focus {
  color: #fff;
  background: rgba(125, 109, 10, 0.3) linear-gradient(180deg, rgba(181, 177, 130, 0.405), rgba(125, 109, 10, 0.3)) repeat-x;
  border-color: color-yig(rgba(125, 109, 10, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(208, 198, 134, 0.5);
}

.module-olive-30:active, .module-olive-30.active,
.show > .module-olive-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(113, 99, 9, 0.3);
  background-image: none;
  border-color: rgba(101, 88, 8, 0.3);
}

.module-olive-30:active:focus, .module-olive-30.active:focus,
.show > .module-olive-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(208, 198, 134, 0.5);
}

.module-olive-30:disabled, .module-olive-30.disabled {
  color: #fff;
  background-color: rgba(160, 140, 13, 0.3);
  background-image: none;
  border-color: rgba(160, 140, 13, 0.3);
}

.module-olive-40 {
  color: #fff;
  background: rgba(160, 140, 13, 0.4) linear-gradient(180deg, rgba(192, 183, 111, 0.49), rgba(160, 140, 13, 0.4)) repeat-x;
  border-color: rgba(160, 140, 13, 0.4);
}

.module-olive-40 .icon-container {
  background-color: rgba(125, 109, 10, 0.4);
}

.module-olive-40 .icon-container.light {
  background-color: rgba(231, 202, 19, 0.4);
}

.module-olive-40:hover {
  color: #fff;
  background: rgba(125, 109, 10, 0.4) linear-gradient(180deg, rgba(172, 165, 109, 0.49), rgba(125, 109, 10, 0.4)) repeat-x;
  border-color: color-yig(rgba(125, 109, 10, 0.4));
}

.module-olive-40:focus, .module-olive-40.focus {
  color: #fff;
  background: rgba(125, 109, 10, 0.4) linear-gradient(180deg, rgba(172, 165, 109, 0.49), rgba(125, 109, 10, 0.4)) repeat-x;
  border-color: color-yig(rgba(125, 109, 10, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(199, 188, 113, 0.5);
}

.module-olive-40:active, .module-olive-40.active,
.show > .module-olive-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(113, 99, 9, 0.4);
  background-image: none;
  border-color: rgba(101, 88, 8, 0.4);
}

.module-olive-40:active:focus, .module-olive-40.active:focus,
.show > .module-olive-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(199, 188, 113, 0.5);
}

.module-olive-40:disabled, .module-olive-40.disabled {
  color: #fff;
  background-color: rgba(160, 140, 13, 0.4);
  background-image: none;
  border-color: rgba(160, 140, 13, 0.4);
}

.module-olive-50 {
  color: #fff;
  background: rgba(160, 140, 13, 0.5) linear-gradient(180deg, rgba(187, 176, 95, 0.575), rgba(160, 140, 13, 0.5)) repeat-x;
  border-color: rgba(160, 140, 13, 0.5);
}

.module-olive-50 .icon-container {
  background-color: rgba(125, 109, 10, 0.5);
}

.module-olive-50 .icon-container.light {
  background-color: rgba(231, 202, 19, 0.5);
}

.module-olive-50:hover {
  color: #fff;
  background: rgba(125, 109, 10, 0.5) linear-gradient(180deg, rgba(164, 156, 93, 0.575), rgba(125, 109, 10, 0.5)) repeat-x;
  border-color: color-yig(rgba(125, 109, 10, 0.5));
}

.module-olive-50:focus, .module-olive-50.focus {
  color: #fff;
  background: rgba(125, 109, 10, 0.5) linear-gradient(180deg, rgba(164, 156, 93, 0.575), rgba(125, 109, 10, 0.5)) repeat-x;
  border-color: color-yig(rgba(125, 109, 10, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(193, 180, 97, 0.5);
}

.module-olive-50:active, .module-olive-50.active,
.show > .module-olive-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(113, 99, 9, 0.5);
  background-image: none;
  border-color: rgba(101, 88, 8, 0.5);
}

.module-olive-50:active:focus, .module-olive-50.active:focus,
.show > .module-olive-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(193, 180, 97, 0.5);
}

.module-olive-50:disabled, .module-olive-50.disabled {
  color: #fff;
  background-color: rgba(160, 140, 13, 0.5);
  background-image: none;
  border-color: rgba(160, 140, 13, 0.5);
}

.module-olive-60 {
  color: #fff;
  background: rgba(160, 140, 13, 0.6) linear-gradient(180deg, rgba(183, 171, 82, 0.66), rgba(160, 140, 13, 0.6)) repeat-x;
  border-color: rgba(160, 140, 13, 0.6);
}

.module-olive-60 .icon-container {
  background-color: rgba(125, 109, 10, 0.6);
}

.module-olive-60 .icon-container.light {
  background-color: rgba(231, 202, 19, 0.6);
}

.module-olive-60:hover {
  color: #fff;
  background: rgba(125, 109, 10, 0.6) linear-gradient(180deg, rgba(158, 149, 80, 0.66), rgba(125, 109, 10, 0.6)) repeat-x;
  border-color: color-yig(rgba(125, 109, 10, 0.6));
}

.module-olive-60:focus, .module-olive-60.focus {
  color: #fff;
  background: rgba(125, 109, 10, 0.6) linear-gradient(180deg, rgba(158, 149, 80, 0.66), rgba(125, 109, 10, 0.6)) repeat-x;
  border-color: color-yig(rgba(125, 109, 10, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(188, 174, 84, 0.5);
}

.module-olive-60:active, .module-olive-60.active,
.show > .module-olive-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(113, 99, 9, 0.6);
  background-image: none;
  border-color: rgba(101, 88, 8, 0.6);
}

.module-olive-60:active:focus, .module-olive-60.active:focus,
.show > .module-olive-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(188, 174, 84, 0.5);
}

.module-olive-60:disabled, .module-olive-60.disabled {
  color: #fff;
  background-color: rgba(160, 140, 13, 0.6);
  background-image: none;
  border-color: rgba(160, 140, 13, 0.6);
}

.module-olive-70 {
  color: #fff;
  background: rgba(160, 140, 13, 0.7) linear-gradient(180deg, rgba(179, 166, 72, 0.745), rgba(160, 140, 13, 0.7)) repeat-x;
  border-color: rgba(160, 140, 13, 0.7);
}

.module-olive-70 .icon-container {
  background-color: rgba(125, 109, 10, 0.7);
}

.module-olive-70 .icon-container.light {
  background-color: rgba(231, 202, 19, 0.7);
}

.module-olive-70:hover {
  color: #fff;
  background: rgba(125, 109, 10, 0.7) linear-gradient(180deg, rgba(153, 143, 69, 0.745), rgba(125, 109, 10, 0.7)) repeat-x;
  border-color: color-yig(rgba(125, 109, 10, 0.7));
}

.module-olive-70:focus, .module-olive-70.focus {
  color: #fff;
  background: rgba(125, 109, 10, 0.7) linear-gradient(180deg, rgba(153, 143, 69, 0.745), rgba(125, 109, 10, 0.7)) repeat-x;
  border-color: color-yig(rgba(125, 109, 10, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(183, 168, 73, 0.5);
}

.module-olive-70:active, .module-olive-70.active,
.show > .module-olive-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(113, 99, 9, 0.7);
  background-image: none;
  border-color: rgba(101, 88, 8, 0.7);
}

.module-olive-70:active:focus, .module-olive-70.active:focus,
.show > .module-olive-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(183, 168, 73, 0.5);
}

.module-olive-70:disabled, .module-olive-70.disabled {
  color: #fff;
  background-color: rgba(160, 140, 13, 0.7);
  background-image: none;
  border-color: rgba(160, 140, 13, 0.7);
}

.module-olive-80 {
  color: #fff;
  background: rgba(160, 140, 13, 0.8) linear-gradient(180deg, rgba(176, 162, 63, 0.83), rgba(160, 140, 13, 0.8)) repeat-x;
  border-color: rgba(160, 140, 13, 0.8);
}

.module-olive-80 .icon-container {
  background-color: rgba(125, 109, 10, 0.8);
}

.module-olive-80 .icon-container.light {
  background-color: rgba(231, 202, 19, 0.8);
}

.module-olive-80:hover {
  color: #fff;
  background: rgba(125, 109, 10, 0.8) linear-gradient(180deg, rgba(148, 138, 60, 0.83), rgba(125, 109, 10, 0.8)) repeat-x;
  border-color: color-yig(rgba(125, 109, 10, 0.8));
}

.module-olive-80:focus, .module-olive-80.focus {
  color: #fff;
  background: rgba(125, 109, 10, 0.8) linear-gradient(180deg, rgba(148, 138, 60, 0.83), rgba(125, 109, 10, 0.8)) repeat-x;
  border-color: color-yig(rgba(125, 109, 10, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(180, 164, 64, 0.5);
}

.module-olive-80:active, .module-olive-80.active,
.show > .module-olive-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(113, 99, 9, 0.8);
  background-image: none;
  border-color: rgba(101, 88, 8, 0.8);
}

.module-olive-80:active:focus, .module-olive-80.active:focus,
.show > .module-olive-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(180, 164, 64, 0.5);
}

.module-olive-80:disabled, .module-olive-80.disabled {
  color: #fff;
  background-color: rgba(160, 140, 13, 0.8);
  background-image: none;
  border-color: rgba(160, 140, 13, 0.8);
}

.module-olive-90 {
  color: #fff;
  background: rgba(160, 140, 13, 0.9) linear-gradient(180deg, rgba(174, 159, 55, 0.915), rgba(160, 140, 13, 0.9)) repeat-x;
  border-color: rgba(160, 140, 13, 0.9);
}

.module-olive-90 .icon-container {
  background-color: rgba(125, 109, 10, 0.9);
}

.module-olive-90 .icon-container.light {
  background-color: rgba(231, 202, 19, 0.9);
}

.module-olive-90:hover {
  color: #fff;
  background: rgba(125, 109, 10, 0.9) linear-gradient(180deg, rgba(145, 133, 53, 0.915), rgba(125, 109, 10, 0.9)) repeat-x;
  border-color: color-yig(rgba(125, 109, 10, 0.9));
}

.module-olive-90:focus, .module-olive-90.focus {
  color: #fff;
  background: rgba(125, 109, 10, 0.9) linear-gradient(180deg, rgba(145, 133, 53, 0.915), rgba(125, 109, 10, 0.9)) repeat-x;
  border-color: color-yig(rgba(125, 109, 10, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(177, 160, 56, 0.5);
}

.module-olive-90:active, .module-olive-90.active,
.show > .module-olive-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(113, 99, 9, 0.9);
  background-image: none;
  border-color: rgba(101, 88, 8, 0.9);
}

.module-olive-90:active:focus, .module-olive-90.active:focus,
.show > .module-olive-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(177, 160, 56, 0.5);
}

.module-olive-90:disabled, .module-olive-90.disabled {
  color: #fff;
  background-color: rgba(160, 140, 13, 0.9);
  background-image: none;
  border-color: rgba(160, 140, 13, 0.9);
}

.module-gold {
  color: #273a4d;
  background: #ddbf00 linear-gradient(180deg, #e0c726, #ddbf00) repeat-x;
  border-color: #ddbf00;
}

.module-gold .icon-container {
  background-color: #b79e00;
}

.module-gold .icon-container.light {
  background-color: #ffe22b;
}

.module-gold:hover {
  color: #fff;
  background: #b79e00 linear-gradient(180deg, #bfab26, #b79e00) repeat-x;
  border-color: color-yig(#b79e00);
}

.module-gold:focus, .module-gold.focus {
  color: #fff;
  background: #b79e00 linear-gradient(180deg, #bfab26, #b79e00) repeat-x;
  border-color: color-yig(#b79e00);
  box-shadow: 0 0 0 0.2rem rgba(194, 171, 12, 0.5);
}

.module-gold:active, .module-gold.active,
.show > .module-gold.dropdown-toggle {
  color: #fff;
  background-color: #aa9300;
  background-image: none;
  border-color: #9d8800;
}

.module-gold:active:focus, .module-gold.active:focus,
.show > .module-gold.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(194, 171, 12, 0.5);
}

.module-gold:disabled, .module-gold.disabled {
  color: #273a4d;
  background-color: #ddbf00;
  background-image: none;
  border-color: #ddbf00;
}

.module-gold-10 {
  color: #273a4d;
  background: rgba(221, 191, 0, 0.1) linear-gradient(180deg, rgba(234, 233, 193, 0.235), rgba(221, 191, 0, 0.1)) repeat-x;
  border-color: rgba(221, 191, 0, 0.1);
}

.module-gold-10 .icon-container {
  background-color: rgba(183, 158, 0, 0.1);
}

.module-gold-10 .icon-container.light {
  background-color: rgba(255, 226, 43, 0.1);
}

.module-gold-10:hover {
  color: #fff;
  background: rgba(183, 158, 0, 0.1) linear-gradient(180deg, rgba(225, 225, 193, 0.235), rgba(183, 158, 0, 0.1)) repeat-x;
  border-color: color-yig(rgba(183, 158, 0, 0.1));
}

.module-gold-10:focus, .module-gold-10.focus {
  color: #fff;
  background: rgba(183, 158, 0, 0.1) linear-gradient(180deg, rgba(225, 225, 193, 0.235), rgba(183, 158, 0, 0.1)) repeat-x;
  border-color: color-yig(rgba(183, 158, 0, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(81, 89, 59, 0.5);
}

.module-gold-10:active, .module-gold-10.active,
.show > .module-gold-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(170, 147, 0, 0.1);
  background-image: none;
  border-color: rgba(157, 136, 0, 0.1);
}

.module-gold-10:active:focus, .module-gold-10.active:focus,
.show > .module-gold-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(81, 89, 59, 0.5);
}

.module-gold-10:disabled, .module-gold-10.disabled {
  color: #273a4d;
  background-color: rgba(221, 191, 0, 0.1);
  background-image: none;
  border-color: rgba(221, 191, 0, 0.1);
}

.module-gold-20 {
  color: #273a4d;
  background: rgba(221, 191, 0, 0.2) linear-gradient(180deg, rgba(231, 224, 153, 0.32), rgba(221, 191, 0, 0.2)) repeat-x;
  border-color: rgba(221, 191, 0, 0.2);
}

.module-gold-20 .icon-container {
  background-color: rgba(183, 158, 0, 0.2);
}

.module-gold-20 .icon-container.light {
  background-color: rgba(255, 226, 43, 0.2);
}

.module-gold-20:hover {
  color: #fff;
  background: rgba(183, 158, 0, 0.2) linear-gradient(180deg, rgba(217, 211, 153, 0.32), rgba(183, 158, 0, 0.2)) repeat-x;
  border-color: color-yig(rgba(183, 158, 0, 0.2));
}

.module-gold-20:focus, .module-gold-20.focus {
  color: #fff;
  background: rgba(183, 158, 0, 0.2) linear-gradient(180deg, rgba(217, 211, 153, 0.32), rgba(183, 158, 0, 0.2)) repeat-x;
  border-color: color-yig(rgba(183, 158, 0, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(109, 109, 47, 0.5);
}

.module-gold-20:active, .module-gold-20.active,
.show > .module-gold-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(170, 147, 0, 0.2);
  background-image: none;
  border-color: rgba(157, 136, 0, 0.2);
}

.module-gold-20:active:focus, .module-gold-20.active:focus,
.show > .module-gold-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(109, 109, 47, 0.5);
}

.module-gold-20:disabled, .module-gold-20.disabled {
  color: #273a4d;
  background-color: rgba(221, 191, 0, 0.2);
  background-image: none;
  border-color: rgba(221, 191, 0, 0.2);
}

.module-gold-30 {
  color: #273a4d;
  background: rgba(221, 191, 0, 0.3) linear-gradient(180deg, rgba(230, 218, 125, 0.405), rgba(221, 191, 0, 0.3)) repeat-x;
  border-color: rgba(221, 191, 0, 0.3);
}

.module-gold-30 .icon-container {
  background-color: rgba(183, 158, 0, 0.3);
}

.module-gold-30 .icon-container.light {
  background-color: rgba(255, 226, 43, 0.3);
}

.module-gold-30:hover {
  color: #fff;
  background: rgba(183, 158, 0, 0.3) linear-gradient(180deg, rgba(210, 201, 125, 0.405), rgba(183, 158, 0, 0.3)) repeat-x;
  border-color: color-yig(rgba(183, 158, 0, 0.3));
}

.module-gold-30:focus, .module-gold-30.focus {
  color: #fff;
  background: rgba(183, 158, 0, 0.3) linear-gradient(180deg, rgba(210, 201, 125, 0.405), rgba(183, 158, 0, 0.3)) repeat-x;
  border-color: color-yig(rgba(183, 158, 0, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(130, 125, 39, 0.5);
}

.module-gold-30:active, .module-gold-30.active,
.show > .module-gold-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(170, 147, 0, 0.3);
  background-image: none;
  border-color: rgba(157, 136, 0, 0.3);
}

.module-gold-30:active:focus, .module-gold-30.active:focus,
.show > .module-gold-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 125, 39, 0.5);
}

.module-gold-30:disabled, .module-gold-30.disabled {
  color: #273a4d;
  background-color: rgba(221, 191, 0, 0.3);
  background-image: none;
  border-color: rgba(221, 191, 0, 0.3);
}

.module-gold-40 {
  color: #273a4d;
  background: rgba(221, 191, 0, 0.4) linear-gradient(180deg, rgba(228, 213, 103, 0.49), rgba(221, 191, 0, 0.4)) repeat-x;
  border-color: rgba(221, 191, 0, 0.4);
}

.module-gold-40 .icon-container {
  background-color: rgba(183, 158, 0, 0.4);
}

.module-gold-40 .icon-container.light {
  background-color: rgba(255, 226, 43, 0.4);
}

.module-gold-40:hover {
  color: #fff;
  background: rgba(183, 158, 0, 0.4) linear-gradient(180deg, rgba(206, 194, 103, 0.49), rgba(183, 158, 0, 0.4)) repeat-x;
  border-color: color-yig(rgba(183, 158, 0, 0.4));
}

.module-gold-40:focus, .module-gold-40.focus {
  color: #fff;
  background: rgba(183, 158, 0, 0.4) linear-gradient(180deg, rgba(206, 194, 103, 0.49), rgba(183, 158, 0, 0.4)) repeat-x;
  border-color: color-yig(rgba(183, 158, 0, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(146, 136, 32, 0.5);
}

.module-gold-40:active, .module-gold-40.active,
.show > .module-gold-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(170, 147, 0, 0.4);
  background-image: none;
  border-color: rgba(157, 136, 0, 0.4);
}

.module-gold-40:active:focus, .module-gold-40.active:focus,
.show > .module-gold-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(146, 136, 32, 0.5);
}

.module-gold-40:disabled, .module-gold-40.disabled {
  color: #273a4d;
  background-color: rgba(221, 191, 0, 0.4);
  background-image: none;
  border-color: rgba(221, 191, 0, 0.4);
}

.module-gold-50 {
  color: #273a4d;
  background: rgba(221, 191, 0, 0.5) linear-gradient(180deg, rgba(227, 210, 87, 0.575), rgba(221, 191, 0, 0.5)) repeat-x;
  border-color: rgba(221, 191, 0, 0.5);
}

.module-gold-50 .icon-container {
  background-color: rgba(183, 158, 0, 0.5);
}

.module-gold-50 .icon-container.light {
  background-color: rgba(255, 226, 43, 0.5);
}

.module-gold-50:hover {
  color: #fff;
  background: rgba(183, 158, 0, 0.5) linear-gradient(180deg, rgba(202, 188, 87, 0.575), rgba(183, 158, 0, 0.5)) repeat-x;
  border-color: color-yig(rgba(183, 158, 0, 0.5));
}

.module-gold-50:focus, .module-gold-50.focus {
  color: #fff;
  background: rgba(183, 158, 0, 0.5) linear-gradient(180deg, rgba(202, 188, 87, 0.575), rgba(183, 158, 0, 0.5)) repeat-x;
  border-color: color-yig(rgba(183, 158, 0, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(158, 145, 27, 0.5);
}

.module-gold-50:active, .module-gold-50.active,
.show > .module-gold-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(170, 147, 0, 0.5);
  background-image: none;
  border-color: rgba(157, 136, 0, 0.5);
}

.module-gold-50:active:focus, .module-gold-50.active:focus,
.show > .module-gold-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(158, 145, 27, 0.5);
}

.module-gold-50:disabled, .module-gold-50.disabled {
  color: #273a4d;
  background-color: rgba(221, 191, 0, 0.5);
  background-image: none;
  border-color: rgba(221, 191, 0, 0.5);
}

.module-gold-60 {
  color: #273a4d;
  background: rgba(221, 191, 0, 0.6) linear-gradient(180deg, rgba(226, 207, 73, 0.66), rgba(221, 191, 0, 0.6)) repeat-x;
  border-color: rgba(221, 191, 0, 0.6);
}

.module-gold-60 .icon-container {
  background-color: rgba(183, 158, 0, 0.6);
}

.module-gold-60 .icon-container.light {
  background-color: rgba(255, 226, 43, 0.6);
}

.module-gold-60:hover {
  color: #fff;
  background: rgba(183, 158, 0, 0.6) linear-gradient(180deg, rgba(199, 183, 73, 0.66), rgba(183, 158, 0, 0.6)) repeat-x;
  border-color: color-yig(rgba(183, 158, 0, 0.6));
}

.module-gold-60:focus, .module-gold-60.focus {
  color: #fff;
  background: rgba(183, 158, 0, 0.6) linear-gradient(180deg, rgba(199, 183, 73, 0.66), rgba(183, 158, 0, 0.6)) repeat-x;
  border-color: color-yig(rgba(183, 158, 0, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(168, 152, 22, 0.5);
}

.module-gold-60:active, .module-gold-60.active,
.show > .module-gold-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(170, 147, 0, 0.6);
  background-image: none;
  border-color: rgba(157, 136, 0, 0.6);
}

.module-gold-60:active:focus, .module-gold-60.active:focus,
.show > .module-gold-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(168, 152, 22, 0.5);
}

.module-gold-60:disabled, .module-gold-60.disabled {
  color: #273a4d;
  background-color: rgba(221, 191, 0, 0.6);
  background-image: none;
  border-color: rgba(221, 191, 0, 0.6);
}

.module-gold-70 {
  color: #273a4d;
  background: rgba(221, 191, 0, 0.7) linear-gradient(180deg, rgba(225, 204, 62, 0.745), rgba(221, 191, 0, 0.7)) repeat-x;
  border-color: rgba(221, 191, 0, 0.7);
}

.module-gold-70 .icon-container {
  background-color: rgba(183, 158, 0, 0.7);
}

.module-gold-70 .icon-container.light {
  background-color: rgba(255, 226, 43, 0.7);
}

.module-gold-70:hover {
  color: #fff;
  background: rgba(183, 158, 0, 0.7) linear-gradient(180deg, rgba(196, 179, 62, 0.745), rgba(183, 158, 0, 0.7)) repeat-x;
  border-color: color-yig(rgba(183, 158, 0, 0.7));
}

.module-gold-70:focus, .module-gold-70.focus {
  color: #fff;
  background: rgba(183, 158, 0, 0.7) linear-gradient(180deg, rgba(196, 179, 62, 0.745), rgba(183, 158, 0, 0.7)) repeat-x;
  border-color: color-yig(rgba(183, 158, 0, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(176, 158, 19, 0.5);
}

.module-gold-70:active, .module-gold-70.active,
.show > .module-gold-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(170, 147, 0, 0.7);
  background-image: none;
  border-color: rgba(157, 136, 0, 0.7);
}

.module-gold-70:active:focus, .module-gold-70.active:focus,
.show > .module-gold-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(176, 158, 19, 0.5);
}

.module-gold-70:disabled, .module-gold-70.disabled {
  color: #273a4d;
  background-color: rgba(221, 191, 0, 0.7);
  background-image: none;
  border-color: rgba(221, 191, 0, 0.7);
}

.module-gold-80 {
  color: #273a4d;
  background: rgba(221, 191, 0, 0.8) linear-gradient(180deg, rgba(225, 202, 52, 0.83), rgba(221, 191, 0, 0.8)) repeat-x;
  border-color: rgba(221, 191, 0, 0.8);
}

.module-gold-80 .icon-container {
  background-color: rgba(183, 158, 0, 0.8);
}

.module-gold-80 .icon-container.light {
  background-color: rgba(255, 226, 43, 0.8);
}

.module-gold-80:hover {
  color: #fff;
  background: rgba(183, 158, 0, 0.8) linear-gradient(180deg, rgba(194, 176, 52, 0.83), rgba(183, 158, 0, 0.8)) repeat-x;
  border-color: color-yig(rgba(183, 158, 0, 0.8));
}

.module-gold-80:focus, .module-gold-80.focus {
  color: #fff;
  background: rgba(183, 158, 0, 0.8) linear-gradient(180deg, rgba(194, 176, 52, 0.83), rgba(183, 158, 0, 0.8)) repeat-x;
  border-color: color-yig(rgba(183, 158, 0, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(183, 163, 16, 0.5);
}

.module-gold-80:active, .module-gold-80.active,
.show > .module-gold-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(170, 147, 0, 0.8);
  background-image: none;
  border-color: rgba(157, 136, 0, 0.8);
}

.module-gold-80:active:focus, .module-gold-80.active:focus,
.show > .module-gold-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(183, 163, 16, 0.5);
}

.module-gold-80:disabled, .module-gold-80.disabled {
  color: #273a4d;
  background-color: rgba(221, 191, 0, 0.8);
  background-image: none;
  border-color: rgba(221, 191, 0, 0.8);
}

.module-gold-90 {
  color: #273a4d;
  background: rgba(221, 191, 0, 0.9) linear-gradient(180deg, rgba(224, 201, 44, 0.915), rgba(221, 191, 0, 0.9)) repeat-x;
  border-color: rgba(221, 191, 0, 0.9);
}

.module-gold-90 .icon-container {
  background-color: rgba(183, 158, 0, 0.9);
}

.module-gold-90 .icon-container.light {
  background-color: rgba(255, 226, 43, 0.9);
}

.module-gold-90:hover {
  color: #fff;
  background: rgba(183, 158, 0, 0.9) linear-gradient(180deg, rgba(193, 173, 44, 0.915), rgba(183, 158, 0, 0.9)) repeat-x;
  border-color: color-yig(rgba(183, 158, 0, 0.9));
}

.module-gold-90:focus, .module-gold-90.focus {
  color: #fff;
  background: rgba(183, 158, 0, 0.9) linear-gradient(180deg, rgba(193, 173, 44, 0.915), rgba(183, 158, 0, 0.9)) repeat-x;
  border-color: color-yig(rgba(183, 158, 0, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(189, 167, 14, 0.5);
}

.module-gold-90:active, .module-gold-90.active,
.show > .module-gold-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(170, 147, 0, 0.9);
  background-image: none;
  border-color: rgba(157, 136, 0, 0.9);
}

.module-gold-90:active:focus, .module-gold-90.active:focus,
.show > .module-gold-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(189, 167, 14, 0.5);
}

.module-gold-90:disabled, .module-gold-90.disabled {
  color: #273a4d;
  background-color: rgba(221, 191, 0, 0.9);
  background-image: none;
  border-color: rgba(221, 191, 0, 0.9);
}

.module-sun {
  color: #273a4d;
  background: #ffe854 linear-gradient(180deg, #fcea6d, #ffe854) repeat-x;
  border-color: #ffe854;
}

.module-sun .icon-container {
  background-color: #ffe32e;
}

.module-sun .icon-container.light {
  background-color: #fff2a1;
}

.module-sun:hover {
  color: #273a4d;
  background: #ffe32e linear-gradient(180deg, #fce64c, #ffe32e) repeat-x;
  border-color: color-yig(#ffe32e);
}

.module-sun:focus, .module-sun.focus {
  color: #273a4d;
  background: #ffe32e linear-gradient(180deg, #fce64c, #ffe32e) repeat-x;
  border-color: color-yig(#ffe32e);
  box-shadow: 0 0 0 0.2rem rgba(223, 206, 83, 0.5);
}

.module-sun:active, .module-sun.active,
.show > .module-sun.dropdown-toggle {
  color: #273a4d;
  background-color: #ffe121;
  background-image: none;
  border-color: #ffdf14;
}

.module-sun:active:focus, .module-sun.active:focus,
.show > .module-sun.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 206, 83, 0.5);
}

.module-sun:disabled, .module-sun.disabled {
  color: #273a4d;
  background-color: #ffe854;
  background-image: none;
  border-color: #ffe854;
}

.module-sun-10 {
  color: #273a4d;
  background: rgba(255, 232, 84, 0.1) linear-gradient(180deg, rgba(242, 242, 212, 0.235), rgba(255, 232, 84, 0.1)) repeat-x;
  border-color: rgba(255, 232, 84, 0.1);
}

.module-sun-10 .icon-container {
  background-color: rgba(255, 227, 46, 0.1);
}

.module-sun-10 .icon-container.light {
  background-color: rgba(255, 242, 161, 0.1);
}

.module-sun-10:hover {
  color: #273a4d;
  background: rgba(255, 227, 46, 0.1) linear-gradient(180deg, rgba(242, 241, 203, 0.235), rgba(255, 227, 46, 0.1)) repeat-x;
  border-color: color-yig(rgba(255, 227, 46, 0.1));
}

.module-sun-10:focus, .module-sun-10.focus {
  color: #273a4d;
  background: rgba(255, 227, 46, 0.1) linear-gradient(180deg, rgba(242, 241, 203, 0.235), rgba(255, 227, 46, 0.1)) repeat-x;
  border-color: color-yig(rgba(255, 227, 46, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(89, 98, 79, 0.5);
}

.module-sun-10:active, .module-sun-10.active,
.show > .module-sun-10.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 225, 33, 0.1);
  background-image: none;
  border-color: rgba(255, 223, 20, 0.1);
}

.module-sun-10:active:focus, .module-sun-10.active:focus,
.show > .module-sun-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 98, 79, 0.5);
}

.module-sun-10:disabled, .module-sun-10.disabled {
  color: #273a4d;
  background-color: rgba(255, 232, 84, 0.1);
  background-image: none;
  border-color: rgba(255, 232, 84, 0.1);
}

.module-sun-20 {
  color: #273a4d;
  background: rgba(255, 232, 84, 0.2) linear-gradient(180deg, rgba(245, 240, 186, 0.32), rgba(255, 232, 84, 0.2)) repeat-x;
  border-color: rgba(255, 232, 84, 0.2);
}

.module-sun-20 .icon-container {
  background-color: rgba(255, 227, 46, 0.2);
}

.module-sun-20 .icon-container.light {
  background-color: rgba(255, 242, 161, 0.2);
}

.module-sun-20:hover {
  color: #273a4d;
  background: rgba(255, 227, 46, 0.2) linear-gradient(180deg, rgba(245, 238, 171, 0.32), rgba(255, 227, 46, 0.2)) repeat-x;
  border-color: color-yig(rgba(255, 227, 46, 0.2));
}

.module-sun-20:focus, .module-sun-20.focus {
  color: #273a4d;
  background: rgba(255, 227, 46, 0.2) linear-gradient(180deg, rgba(245, 238, 171, 0.32), rgba(255, 227, 46, 0.2)) repeat-x;
  border-color: color-yig(rgba(255, 227, 46, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(122, 125, 80, 0.5);
}

.module-sun-20:active, .module-sun-20.active,
.show > .module-sun-20.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 225, 33, 0.2);
  background-image: none;
  border-color: rgba(255, 223, 20, 0.2);
}

.module-sun-20:active:focus, .module-sun-20.active:focus,
.show > .module-sun-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 125, 80, 0.5);
}

.module-sun-20:disabled, .module-sun-20.disabled {
  color: #273a4d;
  background-color: rgba(255, 232, 84, 0.2);
  background-image: none;
  border-color: rgba(255, 232, 84, 0.2);
}

.module-sun-30 {
  color: #273a4d;
  background: rgba(255, 232, 84, 0.3) linear-gradient(180deg, rgba(247, 239, 167, 0.405), rgba(255, 232, 84, 0.3)) repeat-x;
  border-color: rgba(255, 232, 84, 0.3);
}

.module-sun-30 .icon-container {
  background-color: rgba(255, 227, 46, 0.3);
}

.module-sun-30 .icon-container.light {
  background-color: rgba(255, 242, 161, 0.3);
}

.module-sun-30:hover {
  color: #273a4d;
  background: rgba(255, 227, 46, 0.3) linear-gradient(180deg, rgba(247, 236, 148, 0.405), rgba(255, 227, 46, 0.3)) repeat-x;
  border-color: color-yig(rgba(255, 227, 46, 0.3));
}

.module-sun-30:focus, .module-sun-30.focus {
  color: #273a4d;
  background: rgba(255, 227, 46, 0.3) linear-gradient(180deg, rgba(247, 236, 148, 0.405), rgba(255, 227, 46, 0.3)) repeat-x;
  border-color: color-yig(rgba(255, 227, 46, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(147, 145, 81, 0.5);
}

.module-sun-30:active, .module-sun-30.active,
.show > .module-sun-30.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 225, 33, 0.3);
  background-image: none;
  border-color: rgba(255, 223, 20, 0.3);
}

.module-sun-30:active:focus, .module-sun-30.active:focus,
.show > .module-sun-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 145, 81, 0.5);
}

.module-sun-30:disabled, .module-sun-30.disabled {
  color: #273a4d;
  background-color: rgba(255, 232, 84, 0.3);
  background-image: none;
  border-color: rgba(255, 232, 84, 0.3);
}

.module-sun-40 {
  color: #273a4d;
  background: rgba(255, 232, 84, 0.4) linear-gradient(180deg, rgba(248, 237, 153, 0.49), rgba(255, 232, 84, 0.4)) repeat-x;
  border-color: rgba(255, 232, 84, 0.4);
}

.module-sun-40 .icon-container {
  background-color: rgba(255, 227, 46, 0.4);
}

.module-sun-40 .icon-container.light {
  background-color: rgba(255, 242, 161, 0.4);
}

.module-sun-40:hover {
  color: #273a4d;
  background: rgba(255, 227, 46, 0.4) linear-gradient(180deg, rgba(248, 234, 130, 0.49), rgba(255, 227, 46, 0.4)) repeat-x;
  border-color: color-yig(rgba(255, 227, 46, 0.4));
}

.module-sun-40:focus, .module-sun-40.focus {
  color: #273a4d;
  background: rgba(255, 227, 46, 0.4) linear-gradient(180deg, rgba(248, 234, 130, 0.49), rgba(255, 227, 46, 0.4)) repeat-x;
  border-color: color-yig(rgba(255, 227, 46, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(166, 160, 81, 0.5);
}

.module-sun-40:active, .module-sun-40.active,
.show > .module-sun-40.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 225, 33, 0.4);
  background-image: none;
  border-color: rgba(255, 223, 20, 0.4);
}

.module-sun-40:active:focus, .module-sun-40.active:focus,
.show > .module-sun-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 160, 81, 0.5);
}

.module-sun-40:disabled, .module-sun-40.disabled {
  color: #273a4d;
  background-color: rgba(255, 232, 84, 0.4);
  background-image: none;
  border-color: rgba(255, 232, 84, 0.4);
}

.module-sun-50 {
  color: #273a4d;
  background: rgba(255, 232, 84, 0.5) linear-gradient(180deg, rgba(249, 237, 141, 0.575), rgba(255, 232, 84, 0.5)) repeat-x;
  border-color: rgba(255, 232, 84, 0.5);
}

.module-sun-50 .icon-container {
  background-color: rgba(255, 227, 46, 0.5);
}

.module-sun-50 .icon-container.light {
  background-color: rgba(255, 242, 161, 0.5);
}

.module-sun-50:hover {
  color: #273a4d;
  background: rgba(255, 227, 46, 0.5) linear-gradient(180deg, rgba(249, 233, 116, 0.575), rgba(255, 227, 46, 0.5)) repeat-x;
  border-color: color-yig(rgba(255, 227, 46, 0.5));
}

.module-sun-50:focus, .module-sun-50.focus {
  color: #273a4d;
  background: rgba(255, 227, 46, 0.5) linear-gradient(180deg, rgba(249, 233, 116, 0.575), rgba(255, 227, 46, 0.5)) repeat-x;
  border-color: color-yig(rgba(255, 227, 46, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(180, 172, 82, 0.5);
}

.module-sun-50:active, .module-sun-50.active,
.show > .module-sun-50.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 225, 33, 0.5);
  background-image: none;
  border-color: rgba(255, 223, 20, 0.5);
}

.module-sun-50:active:focus, .module-sun-50.active:focus,
.show > .module-sun-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(180, 172, 82, 0.5);
}

.module-sun-50:disabled, .module-sun-50.disabled {
  color: #273a4d;
  background-color: rgba(255, 232, 84, 0.5);
  background-image: none;
  border-color: rgba(255, 232, 84, 0.5);
}

.module-sun-60 {
  color: #273a4d;
  background: rgba(255, 232, 84, 0.6) linear-gradient(180deg, rgba(250, 236, 132, 0.66), rgba(255, 232, 84, 0.6)) repeat-x;
  border-color: rgba(255, 232, 84, 0.6);
}

.module-sun-60 .icon-container {
  background-color: rgba(255, 227, 46, 0.6);
}

.module-sun-60 .icon-container.light {
  background-color: rgba(255, 242, 161, 0.6);
}

.module-sun-60:hover {
  color: #273a4d;
  background: rgba(255, 227, 46, 0.6) linear-gradient(180deg, rgba(250, 232, 105, 0.66), rgba(255, 227, 46, 0.6)) repeat-x;
  border-color: color-yig(rgba(255, 227, 46, 0.6));
}

.module-sun-60:focus, .module-sun-60.focus {
  color: #273a4d;
  background: rgba(255, 227, 46, 0.6) linear-gradient(180deg, rgba(250, 232, 105, 0.66), rgba(255, 227, 46, 0.6)) repeat-x;
  border-color: color-yig(rgba(255, 227, 46, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(192, 181, 82, 0.5);
}

.module-sun-60:active, .module-sun-60.active,
.show > .module-sun-60.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 225, 33, 0.6);
  background-image: none;
  border-color: rgba(255, 223, 20, 0.6);
}

.module-sun-60:active:focus, .module-sun-60.active:focus,
.show > .module-sun-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(192, 181, 82, 0.5);
}

.module-sun-60:disabled, .module-sun-60.disabled {
  color: #273a4d;
  background-color: rgba(255, 232, 84, 0.6);
  background-image: none;
  border-color: rgba(255, 232, 84, 0.6);
}

.module-sun-70 {
  color: #273a4d;
  background: rgba(255, 232, 84, 0.7) linear-gradient(180deg, rgba(251, 235, 125, 0.745), rgba(255, 232, 84, 0.7)) repeat-x;
  border-color: rgba(255, 232, 84, 0.7);
}

.module-sun-70 .icon-container {
  background-color: rgba(255, 227, 46, 0.7);
}

.module-sun-70 .icon-container.light {
  background-color: rgba(255, 242, 161, 0.7);
}

.module-sun-70:hover {
  color: #273a4d;
  background: rgba(255, 227, 46, 0.7) linear-gradient(180deg, rgba(251, 231, 96, 0.745), rgba(255, 227, 46, 0.7)) repeat-x;
  border-color: color-yig(rgba(255, 227, 46, 0.7));
}

.module-sun-70:focus, .module-sun-70.focus {
  color: #273a4d;
  background: rgba(255, 227, 46, 0.7) linear-gradient(180deg, rgba(251, 231, 96, 0.745), rgba(255, 227, 46, 0.7)) repeat-x;
  border-color: color-yig(rgba(255, 227, 46, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(202, 189, 82, 0.5);
}

.module-sun-70:active, .module-sun-70.active,
.show > .module-sun-70.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 225, 33, 0.7);
  background-image: none;
  border-color: rgba(255, 223, 20, 0.7);
}

.module-sun-70:active:focus, .module-sun-70.active:focus,
.show > .module-sun-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(202, 189, 82, 0.5);
}

.module-sun-70:disabled, .module-sun-70.disabled {
  color: #273a4d;
  background-color: rgba(255, 232, 84, 0.7);
  background-image: none;
  border-color: rgba(255, 232, 84, 0.7);
}

.module-sun-80 {
  color: #273a4d;
  background: rgba(255, 232, 84, 0.8) linear-gradient(180deg, rgba(251, 235, 119, 0.83), rgba(255, 232, 84, 0.8)) repeat-x;
  border-color: rgba(255, 232, 84, 0.8);
}

.module-sun-80 .icon-container {
  background-color: rgba(255, 227, 46, 0.8);
}

.module-sun-80 .icon-container.light {
  background-color: rgba(255, 242, 161, 0.8);
}

.module-sun-80:hover {
  color: #273a4d;
  background: rgba(255, 227, 46, 0.8) linear-gradient(180deg, rgba(251, 231, 89, 0.83), rgba(255, 227, 46, 0.8)) repeat-x;
  border-color: color-yig(rgba(255, 227, 46, 0.8));
}

.module-sun-80:focus, .module-sun-80.focus {
  color: #273a4d;
  background: rgba(255, 227, 46, 0.8) linear-gradient(180deg, rgba(251, 231, 89, 0.83), rgba(255, 227, 46, 0.8)) repeat-x;
  border-color: color-yig(rgba(255, 227, 46, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(210, 196, 83, 0.5);
}

.module-sun-80:active, .module-sun-80.active,
.show > .module-sun-80.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 225, 33, 0.8);
  background-image: none;
  border-color: rgba(255, 223, 20, 0.8);
}

.module-sun-80:active:focus, .module-sun-80.active:focus,
.show > .module-sun-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 196, 83, 0.5);
}

.module-sun-80:disabled, .module-sun-80.disabled {
  color: #273a4d;
  background-color: rgba(255, 232, 84, 0.8);
  background-image: none;
  border-color: rgba(255, 232, 84, 0.8);
}

.module-sun-90 {
  color: #273a4d;
  background: rgba(255, 232, 84, 0.9) linear-gradient(180deg, rgba(252, 234, 113, 0.915), rgba(255, 232, 84, 0.9)) repeat-x;
  border-color: rgba(255, 232, 84, 0.9);
}

.module-sun-90 .icon-container {
  background-color: rgba(255, 227, 46, 0.9);
}

.module-sun-90 .icon-container.light {
  background-color: rgba(255, 242, 161, 0.9);
}

.module-sun-90:hover {
  color: #273a4d;
  background: rgba(255, 227, 46, 0.9) linear-gradient(180deg, rgba(252, 230, 82, 0.915), rgba(255, 227, 46, 0.9)) repeat-x;
  border-color: color-yig(rgba(255, 227, 46, 0.9));
}

.module-sun-90:focus, .module-sun-90.focus {
  color: #273a4d;
  background: rgba(255, 227, 46, 0.9) linear-gradient(180deg, rgba(252, 230, 82, 0.915), rgba(255, 227, 46, 0.9)) repeat-x;
  border-color: color-yig(rgba(255, 227, 46, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(217, 201, 83, 0.5);
}

.module-sun-90:active, .module-sun-90.active,
.show > .module-sun-90.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 225, 33, 0.9);
  background-image: none;
  border-color: rgba(255, 223, 20, 0.9);
}

.module-sun-90:active:focus, .module-sun-90.active:focus,
.show > .module-sun-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 201, 83, 0.5);
}

.module-sun-90:disabled, .module-sun-90.disabled {
  color: #273a4d;
  background-color: rgba(255, 232, 84, 0.9);
  background-image: none;
  border-color: rgba(255, 232, 84, 0.9);
}

.module-banana {
  color: #273a4d;
  background: #fff3a7 linear-gradient(180deg, #fcf3b3, #fff3a7) repeat-x;
  border-color: #fff3a7;
}

.module-banana .icon-container {
  background-color: #ffee81;
}

.module-banana .icon-container.light {
  background-color: #fffdf4;
}

.module-banana:hover {
  color: #273a4d;
  background: #ffee81 linear-gradient(180deg, #fcef93, #ffee81) repeat-x;
  border-color: color-yig(#ffee81);
}

.module-banana:focus, .module-banana.focus {
  color: #273a4d;
  background: #ffee81 linear-gradient(180deg, #fcef93, #ffee81) repeat-x;
  border-color: color-yig(#ffee81);
  box-shadow: 0 0 0 0.2rem rgba(223, 215, 154, 0.5);
}

.module-banana:active, .module-banana.active,
.show > .module-banana.dropdown-toggle {
  color: #273a4d;
  background-color: #ffec74;
  background-image: none;
  border-color: #ffea67;
}

.module-banana:active:focus, .module-banana.active:focus,
.show > .module-banana.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 215, 154, 0.5);
}

.module-banana:disabled, .module-banana.disabled {
  color: #273a4d;
  background-color: #fff3a7;
  background-image: none;
  border-color: #fff3a7;
}

.module-banana-10 {
  color: #273a4d;
  background: rgba(255, 243, 167, 0.1) linear-gradient(180deg, rgba(242, 245, 231, 0.235), rgba(255, 243, 167, 0.1)) repeat-x;
  border-color: rgba(255, 243, 167, 0.1);
}

.module-banana-10 .icon-container {
  background-color: rgba(255, 238, 129, 0.1);
}

.module-banana-10 .icon-container.light {
  background-color: rgba(255, 253, 244, 0.1);
}

.module-banana-10:hover {
  color: #273a4d;
  background: rgba(255, 238, 129, 0.1) linear-gradient(180deg, rgba(242, 243, 222, 0.235), rgba(255, 238, 129, 0.1)) repeat-x;
  border-color: color-yig(rgba(255, 238, 129, 0.1));
}

.module-banana-10:focus, .module-banana-10.focus {
  color: #273a4d;
  background: rgba(255, 238, 129, 0.1) linear-gradient(180deg, rgba(242, 243, 222, 0.235), rgba(255, 238, 129, 0.1)) repeat-x;
  border-color: color-yig(rgba(255, 238, 129, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(89, 101, 98, 0.5);
}

.module-banana-10:active, .module-banana-10.active,
.show > .module-banana-10.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 236, 116, 0.1);
  background-image: none;
  border-color: rgba(255, 234, 103, 0.1);
}

.module-banana-10:active:focus, .module-banana-10.active:focus,
.show > .module-banana-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 101, 98, 0.5);
}

.module-banana-10:disabled, .module-banana-10.disabled {
  color: #273a4d;
  background-color: rgba(255, 243, 167, 0.1);
  background-image: none;
  border-color: rgba(255, 243, 167, 0.1);
}

.module-banana-20 {
  color: #273a4d;
  background: rgba(255, 243, 167, 0.2) linear-gradient(180deg, rgba(245, 244, 218, 0.32), rgba(255, 243, 167, 0.2)) repeat-x;
  border-color: rgba(255, 243, 167, 0.2);
}

.module-banana-20 .icon-container {
  background-color: rgba(255, 238, 129, 0.2);
}

.module-banana-20 .icon-container.light {
  background-color: rgba(255, 253, 244, 0.2);
}

.module-banana-20:hover {
  color: #273a4d;
  background: rgba(255, 238, 129, 0.2) linear-gradient(180deg, rgba(245, 242, 203, 0.32), rgba(255, 238, 129, 0.2)) repeat-x;
  border-color: color-yig(rgba(255, 238, 129, 0.2));
}

.module-banana-20:focus, .module-banana-20.focus {
  color: #273a4d;
  background: rgba(255, 238, 129, 0.2) linear-gradient(180deg, rgba(245, 242, 203, 0.32), rgba(255, 238, 129, 0.2)) repeat-x;
  border-color: color-yig(rgba(255, 238, 129, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(122, 129, 112, 0.5);
}

.module-banana-20:active, .module-banana-20.active,
.show > .module-banana-20.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 236, 116, 0.2);
  background-image: none;
  border-color: rgba(255, 234, 103, 0.2);
}

.module-banana-20:active:focus, .module-banana-20.active:focus,
.show > .module-banana-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 129, 112, 0.5);
}

.module-banana-20:disabled, .module-banana-20.disabled {
  color: #273a4d;
  background-color: rgba(255, 243, 167, 0.2);
  background-image: none;
  border-color: rgba(255, 243, 167, 0.2);
}

.module-banana-30 {
  color: #273a4d;
  background: rgba(255, 243, 167, 0.3) linear-gradient(180deg, rgba(247, 244, 209, 0.405), rgba(255, 243, 167, 0.3)) repeat-x;
  border-color: rgba(255, 243, 167, 0.3);
}

.module-banana-30 .icon-container {
  background-color: rgba(255, 238, 129, 0.3);
}

.module-banana-30 .icon-container.light {
  background-color: rgba(255, 253, 244, 0.3);
}

.module-banana-30:hover {
  color: #273a4d;
  background: rgba(255, 238, 129, 0.3) linear-gradient(180deg, rgba(247, 241, 189, 0.405), rgba(255, 238, 129, 0.3)) repeat-x;
  border-color: color-yig(rgba(255, 238, 129, 0.3));
}

.module-banana-30:focus, .module-banana-30.focus {
  color: #273a4d;
  background: rgba(255, 238, 129, 0.3) linear-gradient(180deg, rgba(247, 241, 189, 0.405), rgba(255, 238, 129, 0.3)) repeat-x;
  border-color: color-yig(rgba(255, 238, 129, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(147, 151, 122, 0.5);
}

.module-banana-30:active, .module-banana-30.active,
.show > .module-banana-30.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 236, 116, 0.3);
  background-image: none;
  border-color: rgba(255, 234, 103, 0.3);
}

.module-banana-30:active:focus, .module-banana-30.active:focus,
.show > .module-banana-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 151, 122, 0.5);
}

.module-banana-30:disabled, .module-banana-30.disabled {
  color: #273a4d;
  background-color: rgba(255, 243, 167, 0.3);
  background-image: none;
  border-color: rgba(255, 243, 167, 0.3);
}

.module-banana-40 {
  color: #273a4d;
  background: rgba(255, 243, 167, 0.4) linear-gradient(180deg, rgba(248, 244, 201, 0.49), rgba(255, 243, 167, 0.4)) repeat-x;
  border-color: rgba(255, 243, 167, 0.4);
}

.module-banana-40 .icon-container {
  background-color: rgba(255, 238, 129, 0.4);
}

.module-banana-40 .icon-container.light {
  background-color: rgba(255, 253, 244, 0.4);
}

.module-banana-40:hover {
  color: #273a4d;
  background: rgba(255, 238, 129, 0.4) linear-gradient(180deg, rgba(248, 241, 179, 0.49), rgba(255, 238, 129, 0.4)) repeat-x;
  border-color: color-yig(rgba(255, 238, 129, 0.4));
}

.module-banana-40:focus, .module-banana-40.focus {
  color: #273a4d;
  background: rgba(255, 238, 129, 0.4) linear-gradient(180deg, rgba(248, 241, 179, 0.49), rgba(255, 238, 129, 0.4)) repeat-x;
  border-color: color-yig(rgba(255, 238, 129, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(166, 166, 130, 0.5);
}

.module-banana-40:active, .module-banana-40.active,
.show > .module-banana-40.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 236, 116, 0.4);
  background-image: none;
  border-color: rgba(255, 234, 103, 0.4);
}

.module-banana-40:active:focus, .module-banana-40.active:focus,
.show > .module-banana-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 166, 130, 0.5);
}

.module-banana-40:disabled, .module-banana-40.disabled {
  color: #273a4d;
  background-color: rgba(255, 243, 167, 0.4);
  background-image: none;
  border-color: rgba(255, 243, 167, 0.4);
}

.module-banana-50 {
  color: #273a4d;
  background: rgba(255, 243, 167, 0.5) linear-gradient(180deg, rgba(249, 244, 196, 0.575), rgba(255, 243, 167, 0.5)) repeat-x;
  border-color: rgba(255, 243, 167, 0.5);
}

.module-banana-50 .icon-container {
  background-color: rgba(255, 238, 129, 0.5);
}

.module-banana-50 .icon-container.light {
  background-color: rgba(255, 253, 244, 0.5);
}

.module-banana-50:hover {
  color: #273a4d;
  background: rgba(255, 238, 129, 0.5) linear-gradient(180deg, rgba(249, 240, 171, 0.575), rgba(255, 238, 129, 0.5)) repeat-x;
  border-color: color-yig(rgba(255, 238, 129, 0.5));
}

.module-banana-50:focus, .module-banana-50.focus {
  color: #273a4d;
  background: rgba(255, 238, 129, 0.5) linear-gradient(180deg, rgba(249, 240, 171, 0.575), rgba(255, 238, 129, 0.5)) repeat-x;
  border-color: color-yig(rgba(255, 238, 129, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(180, 179, 136, 0.5);
}

.module-banana-50:active, .module-banana-50.active,
.show > .module-banana-50.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 236, 116, 0.5);
  background-image: none;
  border-color: rgba(255, 234, 103, 0.5);
}

.module-banana-50:active:focus, .module-banana-50.active:focus,
.show > .module-banana-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(180, 179, 136, 0.5);
}

.module-banana-50:disabled, .module-banana-50.disabled {
  color: #273a4d;
  background-color: rgba(255, 243, 167, 0.5);
  background-image: none;
  border-color: rgba(255, 243, 167, 0.5);
}

.module-banana-60 {
  color: #273a4d;
  background: rgba(255, 243, 167, 0.6) linear-gradient(180deg, rgba(250, 244, 191, 0.66), rgba(255, 243, 167, 0.6)) repeat-x;
  border-color: rgba(255, 243, 167, 0.6);
}

.module-banana-60 .icon-container {
  background-color: rgba(255, 238, 129, 0.6);
}

.module-banana-60 .icon-container.light {
  background-color: rgba(255, 253, 244, 0.6);
}

.module-banana-60:hover {
  color: #273a4d;
  background: rgba(255, 238, 129, 0.6) linear-gradient(180deg, rgba(250, 240, 164, 0.66), rgba(255, 238, 129, 0.6)) repeat-x;
  border-color: color-yig(rgba(255, 238, 129, 0.6));
}

.module-banana-60:focus, .module-banana-60.focus {
  color: #273a4d;
  background: rgba(255, 238, 129, 0.6) linear-gradient(180deg, rgba(250, 240, 164, 0.66), rgba(255, 238, 129, 0.6)) repeat-x;
  border-color: color-yig(rgba(255, 238, 129, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(192, 189, 141, 0.5);
}

.module-banana-60:active, .module-banana-60.active,
.show > .module-banana-60.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 236, 116, 0.6);
  background-image: none;
  border-color: rgba(255, 234, 103, 0.6);
}

.module-banana-60:active:focus, .module-banana-60.active:focus,
.show > .module-banana-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(192, 189, 141, 0.5);
}

.module-banana-60:disabled, .module-banana-60.disabled {
  color: #273a4d;
  background-color: rgba(255, 243, 167, 0.6);
  background-image: none;
  border-color: rgba(255, 243, 167, 0.6);
}

.module-banana-70 {
  color: #273a4d;
  background: rgba(255, 243, 167, 0.7) linear-gradient(180deg, rgba(251, 243, 187, 0.745), rgba(255, 243, 167, 0.7)) repeat-x;
  border-color: rgba(255, 243, 167, 0.7);
}

.module-banana-70 .icon-container {
  background-color: rgba(255, 238, 129, 0.7);
}

.module-banana-70 .icon-container.light {
  background-color: rgba(255, 253, 244, 0.7);
}

.module-banana-70:hover {
  color: #273a4d;
  background: rgba(255, 238, 129, 0.7) linear-gradient(180deg, rgba(251, 240, 159, 0.745), rgba(255, 238, 129, 0.7)) repeat-x;
  border-color: color-yig(rgba(255, 238, 129, 0.7));
}

.module-banana-70:focus, .module-banana-70.focus {
  color: #273a4d;
  background: rgba(255, 238, 129, 0.7) linear-gradient(180deg, rgba(251, 240, 159, 0.745), rgba(255, 238, 129, 0.7)) repeat-x;
  border-color: color-yig(rgba(255, 238, 129, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(202, 197, 145, 0.5);
}

.module-banana-70:active, .module-banana-70.active,
.show > .module-banana-70.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 236, 116, 0.7);
  background-image: none;
  border-color: rgba(255, 234, 103, 0.7);
}

.module-banana-70:active:focus, .module-banana-70.active:focus,
.show > .module-banana-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(202, 197, 145, 0.5);
}

.module-banana-70:disabled, .module-banana-70.disabled {
  color: #273a4d;
  background-color: rgba(255, 243, 167, 0.7);
  background-image: none;
  border-color: rgba(255, 243, 167, 0.7);
}

.module-banana-80 {
  color: #273a4d;
  background: rgba(255, 243, 167, 0.8) linear-gradient(180deg, rgba(251, 243, 184, 0.83), rgba(255, 243, 167, 0.8)) repeat-x;
  border-color: rgba(255, 243, 167, 0.8);
}

.module-banana-80 .icon-container {
  background-color: rgba(255, 238, 129, 0.8);
}

.module-banana-80 .icon-container.light {
  background-color: rgba(255, 253, 244, 0.8);
}

.module-banana-80:hover {
  color: #273a4d;
  background: rgba(255, 238, 129, 0.8) linear-gradient(180deg, rgba(251, 239, 154, 0.83), rgba(255, 238, 129, 0.8)) repeat-x;
  border-color: color-yig(rgba(255, 238, 129, 0.8));
}

.module-banana-80:focus, .module-banana-80.focus {
  color: #273a4d;
  background: rgba(255, 238, 129, 0.8) linear-gradient(180deg, rgba(251, 239, 154, 0.83), rgba(255, 238, 129, 0.8)) repeat-x;
  border-color: color-yig(rgba(255, 238, 129, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(210, 204, 148, 0.5);
}

.module-banana-80:active, .module-banana-80.active,
.show > .module-banana-80.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 236, 116, 0.8);
  background-image: none;
  border-color: rgba(255, 234, 103, 0.8);
}

.module-banana-80:active:focus, .module-banana-80.active:focus,
.show > .module-banana-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 204, 148, 0.5);
}

.module-banana-80:disabled, .module-banana-80.disabled {
  color: #273a4d;
  background-color: rgba(255, 243, 167, 0.8);
  background-image: none;
  border-color: rgba(255, 243, 167, 0.8);
}

.module-banana-90 {
  color: #273a4d;
  background: rgba(255, 243, 167, 0.9) linear-gradient(180deg, rgba(252, 243, 182, 0.915), rgba(255, 243, 167, 0.9)) repeat-x;
  border-color: rgba(255, 243, 167, 0.9);
}

.module-banana-90 .icon-container {
  background-color: rgba(255, 238, 129, 0.9);
}

.module-banana-90 .icon-container.light {
  background-color: rgba(255, 253, 244, 0.9);
}

.module-banana-90:hover {
  color: #273a4d;
  background: rgba(255, 238, 129, 0.9) linear-gradient(180deg, rgba(252, 239, 150, 0.915), rgba(255, 238, 129, 0.9)) repeat-x;
  border-color: color-yig(rgba(255, 238, 129, 0.9));
}

.module-banana-90:focus, .module-banana-90.focus {
  color: #273a4d;
  background: rgba(255, 238, 129, 0.9) linear-gradient(180deg, rgba(252, 239, 150, 0.915), rgba(255, 238, 129, 0.9)) repeat-x;
  border-color: color-yig(rgba(255, 238, 129, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(217, 210, 151, 0.5);
}

.module-banana-90:active, .module-banana-90.active,
.show > .module-banana-90.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 236, 116, 0.9);
  background-image: none;
  border-color: rgba(255, 234, 103, 0.9);
}

.module-banana-90:active:focus, .module-banana-90.active:focus,
.show > .module-banana-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 210, 151, 0.5);
}

.module-banana-90:disabled, .module-banana-90.disabled {
  color: #273a4d;
  background-color: rgba(255, 243, 167, 0.9);
  background-image: none;
  border-color: rgba(255, 243, 167, 0.9);
}

.module-walnut {
  color: #fff;
  background: #40331c linear-gradient(180deg, #5a503d, #40331c) repeat-x;
  border-color: #40331c;
}

.module-walnut .icon-container {
  background-color: #251e10;
}

.module-walnut .icon-container.light {
  background-color: #755d33;
}

.module-walnut:hover {
  color: #fff;
  background: #251e10 linear-gradient(180deg, #433e33, #251e10) repeat-x;
  border-color: color-yig(#251e10);
}

.module-walnut:focus, .module-walnut.focus {
  color: #fff;
  background: #251e10 linear-gradient(180deg, #433e33, #251e10) repeat-x;
  border-color: color-yig(#251e10);
  box-shadow: 0 0 0 0.2rem rgba(93, 82, 62, 0.5);
}

.module-walnut:active, .module-walnut.active,
.show > .module-walnut.dropdown-toggle {
  color: #fff;
  background-color: #1d170c;
  background-image: none;
  border-color: #141009;
}

.module-walnut:active:focus, .module-walnut.active:focus,
.show > .module-walnut.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(93, 82, 62, 0.5);
}

.module-walnut:disabled, .module-walnut.disabled {
  color: #fff;
  background-color: #40331c;
  background-image: none;
  border-color: #40331c;
}

.module-walnut-10 {
  color: #fff;
  background: rgba(64, 51, 28, 0.1) linear-gradient(180deg, rgba(198, 200, 199, 0.235), rgba(64, 51, 28, 0.1)) repeat-x;
  border-color: rgba(64, 51, 28, 0.1);
}

.module-walnut-10 .icon-container {
  background-color: rgba(37, 30, 16, 0.1);
}

.module-walnut-10 .icon-container.light {
  background-color: rgba(117, 93, 51, 0.1);
}

.module-walnut-10:hover {
  color: #fff;
  background: rgba(37, 30, 16, 0.1) linear-gradient(180deg, rgba(192, 196, 196, 0.235), rgba(37, 30, 16, 0.1)) repeat-x;
  border-color: color-yig(rgba(37, 30, 16, 0.1));
}

.module-walnut-10:focus, .module-walnut-10.focus {
  color: #fff;
  background: rgba(37, 30, 16, 0.1) linear-gradient(180deg, rgba(192, 196, 196, 0.235), rgba(37, 30, 16, 0.1)) repeat-x;
  border-color: color-yig(rgba(37, 30, 16, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(211, 208, 203, 0.5);
}

.module-walnut-10:active, .module-walnut-10.active,
.show > .module-walnut-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 23, 12, 0.1);
  background-image: none;
  border-color: rgba(20, 16, 9, 0.1);
}

.module-walnut-10:active:focus, .module-walnut-10.active:focus,
.show > .module-walnut-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 208, 203, 0.5);
}

.module-walnut-10:disabled, .module-walnut-10.disabled {
  color: #fff;
  background-color: rgba(64, 51, 28, 0.1);
  background-image: none;
  border-color: rgba(64, 51, 28, 0.1);
}

.module-walnut-20 {
  color: #fff;
  background: rgba(64, 51, 28, 0.2) linear-gradient(180deg, rgba(171, 170, 164, 0.32), rgba(64, 51, 28, 0.2)) repeat-x;
  border-color: rgba(64, 51, 28, 0.2);
}

.module-walnut-20 .icon-container {
  background-color: rgba(37, 30, 16, 0.2);
}

.module-walnut-20 .icon-container.light {
  background-color: rgba(117, 93, 51, 0.2);
}

.module-walnut-20:hover {
  color: #fff;
  background: rgba(37, 30, 16, 0.2) linear-gradient(180deg, rgba(160, 162, 160, 0.32), rgba(37, 30, 16, 0.2)) repeat-x;
  border-color: color-yig(rgba(37, 30, 16, 0.2));
}

.module-walnut-20:focus, .module-walnut-20.focus {
  color: #fff;
  background: rgba(37, 30, 16, 0.2) linear-gradient(180deg, rgba(160, 162, 160, 0.32), rgba(37, 30, 16, 0.2)) repeat-x;
  border-color: color-yig(rgba(37, 30, 16, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(181, 176, 167, 0.5);
}

.module-walnut-20:active, .module-walnut-20.active,
.show > .module-walnut-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 23, 12, 0.2);
  background-image: none;
  border-color: rgba(20, 16, 9, 0.2);
}

.module-walnut-20:active:focus, .module-walnut-20.active:focus,
.show > .module-walnut-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(181, 176, 167, 0.5);
}

.module-walnut-20:disabled, .module-walnut-20.disabled {
  color: #fff;
  background-color: rgba(64, 51, 28, 0.2);
  background-image: none;
  border-color: rgba(64, 51, 28, 0.2);
}

.module-walnut-30 {
  color: #fff;
  background: rgba(64, 51, 28, 0.3) linear-gradient(180deg, rgba(151, 148, 139, 0.405), rgba(64, 51, 28, 0.3)) repeat-x;
  border-color: rgba(64, 51, 28, 0.3);
}

.module-walnut-30 .icon-container {
  background-color: rgba(37, 30, 16, 0.3);
}

.module-walnut-30 .icon-container.light {
  background-color: rgba(117, 93, 51, 0.3);
}

.module-walnut-30:hover {
  color: #fff;
  background: rgba(37, 30, 16, 0.3) linear-gradient(180deg, rgba(138, 137, 133, 0.405), rgba(37, 30, 16, 0.3)) repeat-x;
  border-color: color-yig(rgba(37, 30, 16, 0.3));
}

.module-walnut-30:focus, .module-walnut-30.focus {
  color: #fff;
  background: rgba(37, 30, 16, 0.3) linear-gradient(180deg, rgba(138, 137, 133, 0.405), rgba(37, 30, 16, 0.3)) repeat-x;
  border-color: color-yig(rgba(37, 30, 16, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(160, 153, 142, 0.5);
}

.module-walnut-30:active, .module-walnut-30.active,
.show > .module-walnut-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 23, 12, 0.3);
  background-image: none;
  border-color: rgba(20, 16, 9, 0.3);
}

.module-walnut-30:active:focus, .module-walnut-30.active:focus,
.show > .module-walnut-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(160, 153, 142, 0.5);
}

.module-walnut-30:disabled, .module-walnut-30.disabled {
  color: #fff;
  background-color: rgba(64, 51, 28, 0.3);
  background-image: none;
  border-color: rgba(64, 51, 28, 0.3);
}

.module-walnut-40 {
  color: #fff;
  background: rgba(64, 51, 28, 0.4) linear-gradient(180deg, rgba(136, 131, 120, 0.49), rgba(64, 51, 28, 0.4)) repeat-x;
  border-color: rgba(64, 51, 28, 0.4);
}

.module-walnut-40 .icon-container {
  background-color: rgba(37, 30, 16, 0.4);
}

.module-walnut-40 .icon-container.light {
  background-color: rgba(117, 93, 51, 0.4);
}

.module-walnut-40:hover {
  color: #fff;
  background: rgba(37, 30, 16, 0.4) linear-gradient(180deg, rgba(120, 119, 113, 0.49), rgba(37, 30, 16, 0.4)) repeat-x;
  border-color: color-yig(rgba(37, 30, 16, 0.4));
}

.module-walnut-40:focus, .module-walnut-40.focus {
  color: #fff;
  background: rgba(37, 30, 16, 0.4) linear-gradient(180deg, rgba(120, 119, 113, 0.49), rgba(37, 30, 16, 0.4)) repeat-x;
  border-color: color-yig(rgba(37, 30, 16, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(143, 135, 122, 0.5);
}

.module-walnut-40:active, .module-walnut-40.active,
.show > .module-walnut-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 23, 12, 0.4);
  background-image: none;
  border-color: rgba(20, 16, 9, 0.4);
}

.module-walnut-40:active:focus, .module-walnut-40.active:focus,
.show > .module-walnut-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(143, 135, 122, 0.5);
}

.module-walnut-40:disabled, .module-walnut-40.disabled {
  color: #fff;
  background-color: rgba(64, 51, 28, 0.4);
  background-image: none;
  border-color: rgba(64, 51, 28, 0.4);
}

.module-walnut-50 {
  color: #fff;
  background: rgba(64, 51, 28, 0.5) linear-gradient(180deg, rgba(124, 118, 105, 0.575), rgba(64, 51, 28, 0.5)) repeat-x;
  border-color: rgba(64, 51, 28, 0.5);
}

.module-walnut-50 .icon-container {
  background-color: rgba(37, 30, 16, 0.5);
}

.module-walnut-50 .icon-container.light {
  background-color: rgba(117, 93, 51, 0.5);
}

.module-walnut-50:hover {
  color: #fff;
  background: rgba(37, 30, 16, 0.5) linear-gradient(180deg, rgba(107, 104, 97, 0.575), rgba(37, 30, 16, 0.5)) repeat-x;
  border-color: color-yig(rgba(37, 30, 16, 0.5));
}

.module-walnut-50:focus, .module-walnut-50.focus {
  color: #fff;
  background: rgba(37, 30, 16, 0.5) linear-gradient(180deg, rgba(107, 104, 97, 0.575), rgba(37, 30, 16, 0.5)) repeat-x;
  border-color: color-yig(rgba(37, 30, 16, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(130, 122, 107, 0.5);
}

.module-walnut-50:active, .module-walnut-50.active,
.show > .module-walnut-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 23, 12, 0.5);
  background-image: none;
  border-color: rgba(20, 16, 9, 0.5);
}

.module-walnut-50:active:focus, .module-walnut-50.active:focus,
.show > .module-walnut-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 122, 107, 0.5);
}

.module-walnut-50:disabled, .module-walnut-50.disabled {
  color: #fff;
  background-color: rgba(64, 51, 28, 0.5);
  background-image: none;
  border-color: rgba(64, 51, 28, 0.5);
}

.module-walnut-60 {
  color: #fff;
  background: rgba(64, 51, 28, 0.6) linear-gradient(180deg, rgba(115, 108, 93, 0.66), rgba(64, 51, 28, 0.6)) repeat-x;
  border-color: rgba(64, 51, 28, 0.6);
}

.module-walnut-60 .icon-container {
  background-color: rgba(37, 30, 16, 0.6);
}

.module-walnut-60 .icon-container.light {
  background-color: rgba(117, 93, 51, 0.6);
}

.module-walnut-60:hover {
  color: #fff;
  background: rgba(37, 30, 16, 0.6) linear-gradient(180deg, rgba(96, 93, 85, 0.66), rgba(37, 30, 16, 0.6)) repeat-x;
  border-color: color-yig(rgba(37, 30, 16, 0.6));
}

.module-walnut-60:focus, .module-walnut-60.focus {
  color: #fff;
  background: rgba(37, 30, 16, 0.6) linear-gradient(180deg, rgba(96, 93, 85, 0.66), rgba(37, 30, 16, 0.6)) repeat-x;
  border-color: color-yig(rgba(37, 30, 16, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(120, 111, 94, 0.5);
}

.module-walnut-60:active, .module-walnut-60.active,
.show > .module-walnut-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 23, 12, 0.6);
  background-image: none;
  border-color: rgba(20, 16, 9, 0.6);
}

.module-walnut-60:active:focus, .module-walnut-60.active:focus,
.show > .module-walnut-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 111, 94, 0.5);
}

.module-walnut-60:disabled, .module-walnut-60.disabled {
  color: #fff;
  background-color: rgba(64, 51, 28, 0.6);
  background-image: none;
  border-color: rgba(64, 51, 28, 0.6);
}

.module-walnut-70 {
  color: #fff;
  background: rgba(64, 51, 28, 0.7) linear-gradient(180deg, rgba(107, 99, 83, 0.745), rgba(64, 51, 28, 0.7)) repeat-x;
  border-color: rgba(64, 51, 28, 0.7);
}

.module-walnut-70 .icon-container {
  background-color: rgba(37, 30, 16, 0.7);
}

.module-walnut-70 .icon-container.light {
  background-color: rgba(117, 93, 51, 0.7);
}

.module-walnut-70:hover {
  color: #fff;
  background: rgba(37, 30, 16, 0.7) linear-gradient(180deg, rgba(87, 83, 74, 0.745), rgba(37, 30, 16, 0.7)) repeat-x;
  border-color: color-yig(rgba(37, 30, 16, 0.7));
}

.module-walnut-70:focus, .module-walnut-70.focus {
  color: #fff;
  background: rgba(37, 30, 16, 0.7) linear-gradient(180deg, rgba(87, 83, 74, 0.745), rgba(37, 30, 16, 0.7)) repeat-x;
  border-color: color-yig(rgba(37, 30, 16, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(111, 101, 84, 0.5);
}

.module-walnut-70:active, .module-walnut-70.active,
.show > .module-walnut-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 23, 12, 0.7);
  background-image: none;
  border-color: rgba(20, 16, 9, 0.7);
}

.module-walnut-70:active:focus, .module-walnut-70.active:focus,
.show > .module-walnut-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 101, 84, 0.5);
}

.module-walnut-70:disabled, .module-walnut-70.disabled {
  color: #fff;
  background-color: rgba(64, 51, 28, 0.7);
  background-image: none;
  border-color: rgba(64, 51, 28, 0.7);
}

.module-walnut-80 {
  color: #fff;
  background: rgba(64, 51, 28, 0.8) linear-gradient(180deg, rgba(100, 92, 74, 0.83), rgba(64, 51, 28, 0.8)) repeat-x;
  border-color: rgba(64, 51, 28, 0.8);
}

.module-walnut-80 .icon-container {
  background-color: rgba(37, 30, 16, 0.8);
}

.module-walnut-80 .icon-container.light {
  background-color: rgba(117, 93, 51, 0.8);
}

.module-walnut-80:hover {
  color: #fff;
  background: rgba(37, 30, 16, 0.8) linear-gradient(180deg, rgba(79, 75, 65, 0.83), rgba(37, 30, 16, 0.8)) repeat-x;
  border-color: color-yig(rgba(37, 30, 16, 0.8));
}

.module-walnut-80:focus, .module-walnut-80.focus {
  color: #fff;
  background: rgba(37, 30, 16, 0.8) linear-gradient(180deg, rgba(79, 75, 65, 0.83), rgba(37, 30, 16, 0.8)) repeat-x;
  border-color: color-yig(rgba(37, 30, 16, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(104, 94, 76, 0.5);
}

.module-walnut-80:active, .module-walnut-80.active,
.show > .module-walnut-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 23, 12, 0.8);
  background-image: none;
  border-color: rgba(20, 16, 9, 0.8);
}

.module-walnut-80:active:focus, .module-walnut-80.active:focus,
.show > .module-walnut-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(104, 94, 76, 0.5);
}

.module-walnut-80:disabled, .module-walnut-80.disabled {
  color: #fff;
  background-color: rgba(64, 51, 28, 0.8);
  background-image: none;
  border-color: rgba(64, 51, 28, 0.8);
}

.module-walnut-90 {
  color: #fff;
  background: rgba(64, 51, 28, 0.9) linear-gradient(180deg, rgba(95, 85, 67, 0.915), rgba(64, 51, 28, 0.9)) repeat-x;
  border-color: rgba(64, 51, 28, 0.9);
}

.module-walnut-90 .icon-container {
  background-color: rgba(37, 30, 16, 0.9);
}

.module-walnut-90 .icon-container.light {
  background-color: rgba(117, 93, 51, 0.9);
}

.module-walnut-90:hover {
  color: #fff;
  background: rgba(37, 30, 16, 0.9) linear-gradient(180deg, rgba(73, 68, 58, 0.915), rgba(37, 30, 16, 0.9)) repeat-x;
  border-color: color-yig(rgba(37, 30, 16, 0.9));
}

.module-walnut-90:focus, .module-walnut-90.focus {
  color: #fff;
  background: rgba(37, 30, 16, 0.9) linear-gradient(180deg, rgba(73, 68, 58, 0.915), rgba(37, 30, 16, 0.9)) repeat-x;
  border-color: color-yig(rgba(37, 30, 16, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(98, 87, 68, 0.5);
}

.module-walnut-90:active, .module-walnut-90.active,
.show > .module-walnut-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 23, 12, 0.9);
  background-image: none;
  border-color: rgba(20, 16, 9, 0.9);
}

.module-walnut-90:active:focus, .module-walnut-90.active:focus,
.show > .module-walnut-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(98, 87, 68, 0.5);
}

.module-walnut-90:disabled, .module-walnut-90.disabled {
  color: #fff;
  background-color: rgba(64, 51, 28, 0.9);
  background-image: none;
  border-color: rgba(64, 51, 28, 0.9);
}

.module-flaxen {
  color: #fff;
  background: #6a4a11 linear-gradient(180deg, #7e6434, #6a4a11) repeat-x;
  border-color: #6a4a11;
}

.module-flaxen .icon-container {
  background-color: #49330c;
}

.module-flaxen .icon-container.light {
  background-color: #ac781c;
}

.module-flaxen:hover {
  color: #fff;
  background: #49330c linear-gradient(180deg, #62502f, #49330c) repeat-x;
  border-color: color-yig(#49330c);
}

.module-flaxen:focus, .module-flaxen.focus {
  color: #fff;
  background: #49330c linear-gradient(180deg, #62502f, #49330c) repeat-x;
  border-color: color-yig(#49330c);
  box-shadow: 0 0 0 0.2rem rgba(128, 101, 53, 0.5);
}

.module-flaxen:active, .module-flaxen.active,
.show > .module-flaxen.dropdown-toggle {
  color: #fff;
  background-color: #3e2b0a;
  background-image: none;
  border-color: #332408;
}

.module-flaxen:active:focus, .module-flaxen.active:focus,
.show > .module-flaxen.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 101, 53, 0.5);
}

.module-flaxen:disabled, .module-flaxen.disabled {
  color: #fff;
  background-color: #6a4a11;
  background-image: none;
  border-color: #6a4a11;
}

.module-flaxen-10 {
  color: #fff;
  background: rgba(106, 74, 17, 0.1) linear-gradient(180deg, rgba(208, 206, 196, 0.235), rgba(106, 74, 17, 0.1)) repeat-x;
  border-color: rgba(106, 74, 17, 0.1);
}

.module-flaxen-10 .icon-container {
  background-color: rgba(73, 51, 12, 0.1);
}

.module-flaxen-10 .icon-container.light {
  background-color: rgba(172, 120, 28, 0.1);
}

.module-flaxen-10:hover {
  color: #fff;
  background: rgba(73, 51, 12, 0.1) linear-gradient(180deg, rgba(200, 200, 195, 0.235), rgba(73, 51, 12, 0.1)) repeat-x;
  border-color: color-yig(rgba(73, 51, 12, 0.1));
}

.module-flaxen-10:focus, .module-flaxen-10.focus {
  color: #fff;
  background: rgba(73, 51, 12, 0.1) linear-gradient(180deg, rgba(200, 200, 195, 0.235), rgba(73, 51, 12, 0.1)) repeat-x;
  border-color: color-yig(rgba(73, 51, 12, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(221, 213, 200, 0.5);
}

.module-flaxen-10:active, .module-flaxen-10.active,
.show > .module-flaxen-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 43, 10, 0.1);
  background-image: none;
  border-color: rgba(51, 36, 8, 0.1);
}

.module-flaxen-10:active:focus, .module-flaxen-10.active:focus,
.show > .module-flaxen-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 213, 200, 0.5);
}

.module-flaxen-10:disabled, .module-flaxen-10.disabled {
  color: #fff;
  background-color: rgba(106, 74, 17, 0.1);
  background-image: none;
  border-color: rgba(106, 74, 17, 0.1);
}

.module-flaxen-20 {
  color: #fff;
  background: rgba(106, 74, 17, 0.2) linear-gradient(180deg, rgba(187, 179, 160, 0.32), rgba(106, 74, 17, 0.2)) repeat-x;
  border-color: rgba(106, 74, 17, 0.2);
}

.module-flaxen-20 .icon-container {
  background-color: rgba(73, 51, 12, 0.2);
}

.module-flaxen-20 .icon-container.light {
  background-color: rgba(172, 120, 28, 0.2);
}

.module-flaxen-20:hover {
  color: #fff;
  background: rgba(73, 51, 12, 0.2) linear-gradient(180deg, rgba(174, 170, 158, 0.32), rgba(73, 51, 12, 0.2)) repeat-x;
  border-color: color-yig(rgba(73, 51, 12, 0.2));
}

.module-flaxen-20:focus, .module-flaxen-20.focus {
  color: #fff;
  background: rgba(73, 51, 12, 0.2) linear-gradient(180deg, rgba(174, 170, 158, 0.32), rgba(73, 51, 12, 0.2)) repeat-x;
  border-color: color-yig(rgba(73, 51, 12, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(197, 185, 163, 0.5);
}

.module-flaxen-20:active, .module-flaxen-20.active,
.show > .module-flaxen-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 43, 10, 0.2);
  background-image: none;
  border-color: rgba(51, 36, 8, 0.2);
}

.module-flaxen-20:active:focus, .module-flaxen-20.active:focus,
.show > .module-flaxen-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(197, 185, 163, 0.5);
}

.module-flaxen-20:disabled, .module-flaxen-20.disabled {
  color: #fff;
  background-color: rgba(106, 74, 17, 0.2);
  background-image: none;
  border-color: rgba(106, 74, 17, 0.2);
}

.module-flaxen-30 {
  color: #fff;
  background: rgba(106, 74, 17, 0.3) linear-gradient(180deg, rgba(172, 160, 134, 0.405), rgba(106, 74, 17, 0.3)) repeat-x;
  border-color: rgba(106, 74, 17, 0.3);
}

.module-flaxen-30 .icon-container {
  background-color: rgba(73, 51, 12, 0.3);
}

.module-flaxen-30 .icon-container.light {
  background-color: rgba(172, 120, 28, 0.3);
}

.module-flaxen-30:hover {
  color: #fff;
  background: rgba(73, 51, 12, 0.3) linear-gradient(180deg, rgba(156, 148, 131, 0.405), rgba(73, 51, 12, 0.3)) repeat-x;
  border-color: color-yig(rgba(73, 51, 12, 0.3));
}

.module-flaxen-30:focus, .module-flaxen-30.focus {
  color: #fff;
  background: rgba(73, 51, 12, 0.3) linear-gradient(180deg, rgba(156, 148, 131, 0.405), rgba(73, 51, 12, 0.3)) repeat-x;
  border-color: color-yig(rgba(73, 51, 12, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(181, 165, 136, 0.5);
}

.module-flaxen-30:active, .module-flaxen-30.active,
.show > .module-flaxen-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 43, 10, 0.3);
  background-image: none;
  border-color: rgba(51, 36, 8, 0.3);
}

.module-flaxen-30:active:focus, .module-flaxen-30.active:focus,
.show > .module-flaxen-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(181, 165, 136, 0.5);
}

.module-flaxen-30:disabled, .module-flaxen-30.disabled {
  color: #fff;
  background-color: rgba(106, 74, 17, 0.3);
  background-image: none;
  border-color: rgba(106, 74, 17, 0.3);
}

.module-flaxen-40 {
  color: #fff;
  background: rgba(106, 74, 17, 0.4) linear-gradient(180deg, rgba(161, 145, 113, 0.49), rgba(106, 74, 17, 0.4)) repeat-x;
  border-color: rgba(106, 74, 17, 0.4);
}

.module-flaxen-40 .icon-container {
  background-color: rgba(73, 51, 12, 0.4);
}

.module-flaxen-40 .icon-container.light {
  background-color: rgba(172, 120, 28, 0.4);
}

.module-flaxen-40:hover {
  color: #fff;
  background: rgba(73, 51, 12, 0.4) linear-gradient(180deg, rgba(141, 131, 110, 0.49), rgba(73, 51, 12, 0.4)) repeat-x;
  border-color: color-yig(rgba(73, 51, 12, 0.4));
}

.module-flaxen-40:focus, .module-flaxen-40.focus {
  color: #fff;
  background: rgba(73, 51, 12, 0.4) linear-gradient(180deg, rgba(141, 131, 110, 0.49), rgba(73, 51, 12, 0.4)) repeat-x;
  border-color: color-yig(rgba(73, 51, 12, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(168, 149, 115, 0.5);
}

.module-flaxen-40:active, .module-flaxen-40.active,
.show > .module-flaxen-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 43, 10, 0.4);
  background-image: none;
  border-color: rgba(51, 36, 8, 0.4);
}

.module-flaxen-40:active:focus, .module-flaxen-40.active:focus,
.show > .module-flaxen-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(168, 149, 115, 0.5);
}

.module-flaxen-40:disabled, .module-flaxen-40.disabled {
  color: #fff;
  background-color: rgba(106, 74, 17, 0.4);
  background-image: none;
  border-color: rgba(106, 74, 17, 0.4);
}

.module-flaxen-50 {
  color: #fff;
  background: rgba(106, 74, 17, 0.5) linear-gradient(180deg, rgba(152, 133, 98, 0.575), rgba(106, 74, 17, 0.5)) repeat-x;
  border-color: rgba(106, 74, 17, 0.5);
}

.module-flaxen-50 .icon-container {
  background-color: rgba(73, 51, 12, 0.5);
}

.module-flaxen-50 .icon-container.light {
  background-color: rgba(172, 120, 28, 0.5);
}

.module-flaxen-50:hover {
  color: #fff;
  background: rgba(73, 51, 12, 0.5) linear-gradient(180deg, rgba(130, 118, 94, 0.575), rgba(73, 51, 12, 0.5)) repeat-x;
  border-color: color-yig(rgba(73, 51, 12, 0.5));
}

.module-flaxen-50:focus, .module-flaxen-50.focus {
  color: #fff;
  background: rgba(73, 51, 12, 0.5) linear-gradient(180deg, rgba(130, 118, 94, 0.575), rgba(73, 51, 12, 0.5)) repeat-x;
  border-color: color-yig(rgba(73, 51, 12, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(158, 137, 99, 0.5);
}

.module-flaxen-50:active, .module-flaxen-50.active,
.show > .module-flaxen-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 43, 10, 0.5);
  background-image: none;
  border-color: rgba(51, 36, 8, 0.5);
}

.module-flaxen-50:active:focus, .module-flaxen-50.active:focus,
.show > .module-flaxen-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(158, 137, 99, 0.5);
}

.module-flaxen-50:disabled, .module-flaxen-50.disabled {
  color: #fff;
  background-color: rgba(106, 74, 17, 0.5);
  background-image: none;
  border-color: rgba(106, 74, 17, 0.5);
}

.module-flaxen-60 {
  color: #fff;
  background: rgba(106, 74, 17, 0.6) linear-gradient(180deg, rgba(145, 124, 85, 0.66), rgba(106, 74, 17, 0.6)) repeat-x;
  border-color: rgba(106, 74, 17, 0.6);
}

.module-flaxen-60 .icon-container {
  background-color: rgba(73, 51, 12, 0.6);
}

.module-flaxen-60 .icon-container.light {
  background-color: rgba(172, 120, 28, 0.6);
}

.module-flaxen-60:hover {
  color: #fff;
  background: rgba(73, 51, 12, 0.6) linear-gradient(180deg, rgba(121, 108, 81, 0.66), rgba(73, 51, 12, 0.6)) repeat-x;
  border-color: color-yig(rgba(73, 51, 12, 0.6));
}

.module-flaxen-60:focus, .module-flaxen-60.focus {
  color: #fff;
  background: rgba(73, 51, 12, 0.6) linear-gradient(180deg, rgba(121, 108, 81, 0.66), rgba(73, 51, 12, 0.6)) repeat-x;
  border-color: color-yig(rgba(73, 51, 12, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(149, 127, 86, 0.5);
}

.module-flaxen-60:active, .module-flaxen-60.active,
.show > .module-flaxen-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 43, 10, 0.6);
  background-image: none;
  border-color: rgba(51, 36, 8, 0.6);
}

.module-flaxen-60:active:focus, .module-flaxen-60.active:focus,
.show > .module-flaxen-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 127, 86, 0.5);
}

.module-flaxen-60:disabled, .module-flaxen-60.disabled {
  color: #fff;
  background-color: rgba(106, 74, 17, 0.6);
  background-image: none;
  border-color: rgba(106, 74, 17, 0.6);
}

.module-flaxen-70 {
  color: #fff;
  background: rgba(106, 74, 17, 0.7) linear-gradient(180deg, rgba(139, 116, 75, 0.745), rgba(106, 74, 17, 0.7)) repeat-x;
  border-color: rgba(106, 74, 17, 0.7);
}

.module-flaxen-70 .icon-container {
  background-color: rgba(73, 51, 12, 0.7);
}

.module-flaxen-70 .icon-container.light {
  background-color: rgba(172, 120, 28, 0.7);
}

.module-flaxen-70:hover {
  color: #fff;
  background: rgba(73, 51, 12, 0.7) linear-gradient(180deg, rgba(114, 99, 71, 0.745), rgba(73, 51, 12, 0.7)) repeat-x;
  border-color: color-yig(rgba(73, 51, 12, 0.7));
}

.module-flaxen-70:focus, .module-flaxen-70.focus {
  color: #fff;
  background: rgba(73, 51, 12, 0.7) linear-gradient(180deg, rgba(114, 99, 71, 0.745), rgba(73, 51, 12, 0.7)) repeat-x;
  border-color: color-yig(rgba(73, 51, 12, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(143, 119, 76, 0.5);
}

.module-flaxen-70:active, .module-flaxen-70.active,
.show > .module-flaxen-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 43, 10, 0.7);
  background-image: none;
  border-color: rgba(51, 36, 8, 0.7);
}

.module-flaxen-70:active:focus, .module-flaxen-70.active:focus,
.show > .module-flaxen-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(143, 119, 76, 0.5);
}

.module-flaxen-70:disabled, .module-flaxen-70.disabled {
  color: #fff;
  background-color: rgba(106, 74, 17, 0.7);
  background-image: none;
  border-color: rgba(106, 74, 17, 0.7);
}

.module-flaxen-80 {
  color: #fff;
  background: rgba(106, 74, 17, 0.8) linear-gradient(180deg, rgba(134, 110, 66, 0.83), rgba(106, 74, 17, 0.8)) repeat-x;
  border-color: rgba(106, 74, 17, 0.8);
}

.module-flaxen-80 .icon-container {
  background-color: rgba(73, 51, 12, 0.8);
}

.module-flaxen-80 .icon-container.light {
  background-color: rgba(172, 120, 28, 0.8);
}

.module-flaxen-80:hover {
  color: #fff;
  background: rgba(73, 51, 12, 0.8) linear-gradient(180deg, rgba(108, 92, 62, 0.83), rgba(73, 51, 12, 0.8)) repeat-x;
  border-color: color-yig(rgba(73, 51, 12, 0.8));
}

.module-flaxen-80:focus, .module-flaxen-80.focus {
  color: #fff;
  background: rgba(73, 51, 12, 0.8) linear-gradient(180deg, rgba(108, 92, 62, 0.83), rgba(73, 51, 12, 0.8)) repeat-x;
  border-color: color-yig(rgba(73, 51, 12, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(137, 112, 67, 0.5);
}

.module-flaxen-80:active, .module-flaxen-80.active,
.show > .module-flaxen-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 43, 10, 0.8);
  background-image: none;
  border-color: rgba(51, 36, 8, 0.8);
}

.module-flaxen-80:active:focus, .module-flaxen-80.active:focus,
.show > .module-flaxen-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(137, 112, 67, 0.5);
}

.module-flaxen-80:disabled, .module-flaxen-80.disabled {
  color: #fff;
  background-color: rgba(106, 74, 17, 0.8);
  background-image: none;
  border-color: rgba(106, 74, 17, 0.8);
}

.module-flaxen-90 {
  color: #fff;
  background: rgba(106, 74, 17, 0.9) linear-gradient(180deg, rgba(129, 104, 58, 0.915), rgba(106, 74, 17, 0.9)) repeat-x;
  border-color: rgba(106, 74, 17, 0.9);
}

.module-flaxen-90 .icon-container {
  background-color: rgba(73, 51, 12, 0.9);
}

.module-flaxen-90 .icon-container.light {
  background-color: rgba(172, 120, 28, 0.9);
}

.module-flaxen-90:hover {
  color: #fff;
  background: rgba(73, 51, 12, 0.9) linear-gradient(180deg, rgba(102, 85, 54, 0.915), rgba(73, 51, 12, 0.9)) repeat-x;
  border-color: color-yig(rgba(73, 51, 12, 0.9));
}

.module-flaxen-90:focus, .module-flaxen-90.focus {
  color: #fff;
  background: rgba(73, 51, 12, 0.9) linear-gradient(180deg, rgba(102, 85, 54, 0.915), rgba(73, 51, 12, 0.9)) repeat-x;
  border-color: color-yig(rgba(73, 51, 12, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(132, 106, 59, 0.5);
}

.module-flaxen-90:active, .module-flaxen-90.active,
.show > .module-flaxen-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 43, 10, 0.9);
  background-image: none;
  border-color: rgba(51, 36, 8, 0.9);
}

.module-flaxen-90:active:focus, .module-flaxen-90.active:focus,
.show > .module-flaxen-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(132, 106, 59, 0.5);
}

.module-flaxen-90:disabled, .module-flaxen-90.disabled {
  color: #fff;
  background-color: rgba(106, 74, 17, 0.9);
  background-image: none;
  border-color: rgba(106, 74, 17, 0.9);
}

.module-sand-stone {
  color: #fff;
  background: #9b670e linear-gradient(180deg, #a77c31, #9b670e) repeat-x;
  border-color: #9b670e;
}

.module-sand-stone .icon-container {
  background-color: #78500b;
}

.module-sand-stone .icon-container.light {
  background-color: #e19614;
}

.module-sand-stone:hover {
  color: #fff;
  background: #78500b linear-gradient(180deg, #8a682f, #78500b) repeat-x;
  border-color: color-yig(#78500b);
}

.module-sand-stone:focus, .module-sand-stone.focus {
  color: #fff;
  background: #78500b linear-gradient(180deg, #8a682f, #78500b) repeat-x;
  border-color: color-yig(#78500b);
  box-shadow: 0 0 0 0.2rem rgba(170, 126, 50, 0.5);
}

.module-sand-stone:active, .module-sand-stone.active,
.show > .module-sand-stone.dropdown-toggle {
  color: #fff;
  background-color: #6c480a;
  background-image: none;
  border-color: #614009;
}

.module-sand-stone:active:focus, .module-sand-stone.active:focus,
.show > .module-sand-stone.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(170, 126, 50, 0.5);
}

.module-sand-stone:disabled, .module-sand-stone.disabled {
  color: #fff;
  background-color: #9b670e;
  background-image: none;
  border-color: #9b670e;
}

.module-sand-stone-10 {
  color: #fff;
  background: rgba(155, 103, 14, 0.1) linear-gradient(180deg, rgba(219, 212, 196, 0.235), rgba(155, 103, 14, 0.1)) repeat-x;
  border-color: rgba(155, 103, 14, 0.1);
}

.module-sand-stone-10 .icon-container {
  background-color: rgba(120, 80, 11, 0.1);
}

.module-sand-stone-10 .icon-container.light {
  background-color: rgba(225, 150, 20, 0.1);
}

.module-sand-stone-10:hover {
  color: #fff;
  background: rgba(120, 80, 11, 0.1) linear-gradient(180deg, rgba(211, 207, 195, 0.235), rgba(120, 80, 11, 0.1)) repeat-x;
  border-color: color-yig(rgba(120, 80, 11, 0.1));
}

.module-sand-stone-10:focus, .module-sand-stone-10.focus {
  color: #fff;
  background: rgba(120, 80, 11, 0.1) linear-gradient(180deg, rgba(211, 207, 195, 0.235), rgba(120, 80, 11, 0.1)) repeat-x;
  border-color: color-yig(rgba(120, 80, 11, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(232, 220, 200, 0.5);
}

.module-sand-stone-10:active, .module-sand-stone-10.active,
.show > .module-sand-stone-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 72, 10, 0.1);
  background-image: none;
  border-color: rgba(97, 64, 9, 0.1);
}

.module-sand-stone-10:active:focus, .module-sand-stone-10.active:focus,
.show > .module-sand-stone-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 220, 200, 0.5);
}

.module-sand-stone-10:disabled, .module-sand-stone-10.disabled {
  color: #fff;
  background-color: rgba(155, 103, 14, 0.1);
  background-image: none;
  border-color: rgba(155, 103, 14, 0.1);
}

.module-sand-stone-20 {
  color: #fff;
  background: rgba(155, 103, 14, 0.2) linear-gradient(180deg, rgba(206, 190, 159, 0.32), rgba(155, 103, 14, 0.2)) repeat-x;
  border-color: rgba(155, 103, 14, 0.2);
}

.module-sand-stone-20 .icon-container {
  background-color: rgba(120, 80, 11, 0.2);
}

.module-sand-stone-20 .icon-container.light {
  background-color: rgba(225, 150, 20, 0.2);
}

.module-sand-stone-20:hover {
  color: #fff;
  background: rgba(120, 80, 11, 0.2) linear-gradient(180deg, rgba(192, 181, 158, 0.32), rgba(120, 80, 11, 0.2)) repeat-x;
  border-color: color-yig(rgba(120, 80, 11, 0.2));
}

.module-sand-stone-20:focus, .module-sand-stone-20.focus {
  color: #fff;
  background: rgba(120, 80, 11, 0.2) linear-gradient(180deg, rgba(192, 181, 158, 0.32), rgba(120, 80, 11, 0.2)) repeat-x;
  border-color: color-yig(rgba(120, 80, 11, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(216, 196, 162, 0.5);
}

.module-sand-stone-20:active, .module-sand-stone-20.active,
.show > .module-sand-stone-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 72, 10, 0.2);
  background-image: none;
  border-color: rgba(97, 64, 9, 0.2);
}

.module-sand-stone-20:active:focus, .module-sand-stone-20.active:focus,
.show > .module-sand-stone-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 196, 162, 0.5);
}

.module-sand-stone-20:disabled, .module-sand-stone-20.disabled {
  color: #fff;
  background-color: rgba(155, 103, 14, 0.2);
  background-image: none;
  border-color: rgba(155, 103, 14, 0.2);
}

.module-sand-stone-30 {
  color: #fff;
  background: rgba(155, 103, 14, 0.3) linear-gradient(180deg, rgba(197, 174, 132, 0.405), rgba(155, 103, 14, 0.3)) repeat-x;
  border-color: rgba(155, 103, 14, 0.3);
}

.module-sand-stone-30 .icon-container {
  background-color: rgba(120, 80, 11, 0.3);
}

.module-sand-stone-30 .icon-container.light {
  background-color: rgba(225, 150, 20, 0.3);
}

.module-sand-stone-30:hover {
  color: #fff;
  background: rgba(120, 80, 11, 0.3) linear-gradient(180deg, rgba(179, 162, 130, 0.405), rgba(120, 80, 11, 0.3)) repeat-x;
  border-color: color-yig(rgba(120, 80, 11, 0.3));
}

.module-sand-stone-30:focus, .module-sand-stone-30.focus {
  color: #fff;
  background: rgba(120, 80, 11, 0.3) linear-gradient(180deg, rgba(179, 162, 130, 0.405), rgba(120, 80, 11, 0.3)) repeat-x;
  border-color: color-yig(rgba(120, 80, 11, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(205, 179, 135, 0.5);
}

.module-sand-stone-30:active, .module-sand-stone-30.active,
.show > .module-sand-stone-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 72, 10, 0.3);
  background-image: none;
  border-color: rgba(97, 64, 9, 0.3);
}

.module-sand-stone-30:active:focus, .module-sand-stone-30.active:focus,
.show > .module-sand-stone-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(205, 179, 135, 0.5);
}

.module-sand-stone-30:disabled, .module-sand-stone-30.disabled {
  color: #fff;
  background-color: rgba(155, 103, 14, 0.3);
  background-image: none;
  border-color: rgba(155, 103, 14, 0.3);
}

.module-sand-stone-40 {
  color: #fff;
  background: rgba(155, 103, 14, 0.4) linear-gradient(180deg, rgba(189, 162, 112, 0.49), rgba(155, 103, 14, 0.4)) repeat-x;
  border-color: rgba(155, 103, 14, 0.4);
}

.module-sand-stone-40 .icon-container {
  background-color: rgba(120, 80, 11, 0.4);
}

.module-sand-stone-40 .icon-container.light {
  background-color: rgba(225, 150, 20, 0.4);
}

.module-sand-stone-40:hover {
  color: #fff;
  background: rgba(120, 80, 11, 0.4) linear-gradient(180deg, rgba(169, 148, 110, 0.49), rgba(120, 80, 11, 0.4)) repeat-x;
  border-color: color-yig(rgba(120, 80, 11, 0.4));
}

.module-sand-stone-40:focus, .module-sand-stone-40.focus {
  color: #fff;
  background: rgba(120, 80, 11, 0.4) linear-gradient(180deg, rgba(169, 148, 110, 0.49), rgba(120, 80, 11, 0.4)) repeat-x;
  border-color: color-yig(rgba(120, 80, 11, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(196, 166, 114, 0.5);
}

.module-sand-stone-40:active, .module-sand-stone-40.active,
.show > .module-sand-stone-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 72, 10, 0.4);
  background-image: none;
  border-color: rgba(97, 64, 9, 0.4);
}

.module-sand-stone-40:active:focus, .module-sand-stone-40.active:focus,
.show > .module-sand-stone-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 166, 114, 0.5);
}

.module-sand-stone-40:disabled, .module-sand-stone-40.disabled {
  color: #fff;
  background-color: rgba(155, 103, 14, 0.4);
  background-image: none;
  border-color: rgba(155, 103, 14, 0.4);
}

.module-sand-stone-50 {
  color: #fff;
  background: rgba(155, 103, 14, 0.5) linear-gradient(180deg, rgba(184, 152, 96, 0.575), rgba(155, 103, 14, 0.5)) repeat-x;
  border-color: rgba(155, 103, 14, 0.5);
}

.module-sand-stone-50 .icon-container {
  background-color: rgba(120, 80, 11, 0.5);
}

.module-sand-stone-50 .icon-container.light {
  background-color: rgba(225, 150, 20, 0.5);
}

.module-sand-stone-50:hover {
  color: #fff;
  background: rgba(120, 80, 11, 0.5) linear-gradient(180deg, rgba(161, 137, 94, 0.575), rgba(120, 80, 11, 0.5)) repeat-x;
  border-color: color-yig(rgba(120, 80, 11, 0.5));
}

.module-sand-stone-50:focus, .module-sand-stone-50.focus {
  color: #fff;
  background: rgba(120, 80, 11, 0.5) linear-gradient(180deg, rgba(161, 137, 94, 0.575), rgba(120, 80, 11, 0.5)) repeat-x;
  border-color: color-yig(rgba(120, 80, 11, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(190, 156, 97, 0.5);
}

.module-sand-stone-50:active, .module-sand-stone-50.active,
.show > .module-sand-stone-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 72, 10, 0.5);
  background-image: none;
  border-color: rgba(97, 64, 9, 0.5);
}

.module-sand-stone-50:active:focus, .module-sand-stone-50.active:focus,
.show > .module-sand-stone-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(190, 156, 97, 0.5);
}

.module-sand-stone-50:disabled, .module-sand-stone-50.disabled {
  color: #fff;
  background-color: rgba(155, 103, 14, 0.5);
  background-image: none;
  border-color: rgba(155, 103, 14, 0.5);
}

.module-sand-stone-60 {
  color: #fff;
  background: rgba(155, 103, 14, 0.6) linear-gradient(180deg, rgba(179, 144, 83, 0.66), rgba(155, 103, 14, 0.6)) repeat-x;
  border-color: rgba(155, 103, 14, 0.6);
}

.module-sand-stone-60 .icon-container {
  background-color: rgba(120, 80, 11, 0.6);
}

.module-sand-stone-60 .icon-container.light {
  background-color: rgba(225, 150, 20, 0.6);
}

.module-sand-stone-60:hover {
  color: #fff;
  background: rgba(120, 80, 11, 0.6) linear-gradient(180deg, rgba(154, 128, 81, 0.66), rgba(120, 80, 11, 0.6)) repeat-x;
  border-color: color-yig(rgba(120, 80, 11, 0.6));
}

.module-sand-stone-60:focus, .module-sand-stone-60.focus {
  color: #fff;
  background: rgba(120, 80, 11, 0.6) linear-gradient(180deg, rgba(154, 128, 81, 0.66), rgba(120, 80, 11, 0.6)) repeat-x;
  border-color: color-yig(rgba(120, 80, 11, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(184, 147, 84, 0.5);
}

.module-sand-stone-60:active, .module-sand-stone-60.active,
.show > .module-sand-stone-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 72, 10, 0.6);
  background-image: none;
  border-color: rgba(97, 64, 9, 0.6);
}

.module-sand-stone-60:active:focus, .module-sand-stone-60.active:focus,
.show > .module-sand-stone-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 147, 84, 0.5);
}

.module-sand-stone-60:disabled, .module-sand-stone-60.disabled {
  color: #fff;
  background-color: rgba(155, 103, 14, 0.6);
  background-image: none;
  border-color: rgba(155, 103, 14, 0.6);
}

.module-sand-stone-70 {
  color: #fff;
  background: rgba(155, 103, 14, 0.7) linear-gradient(180deg, rgba(175, 138, 72, 0.745), rgba(155, 103, 14, 0.7)) repeat-x;
  border-color: rgba(155, 103, 14, 0.7);
}

.module-sand-stone-70 .icon-container {
  background-color: rgba(120, 80, 11, 0.7);
}

.module-sand-stone-70 .icon-container.light {
  background-color: rgba(225, 150, 20, 0.7);
}

.module-sand-stone-70:hover {
  color: #fff;
  background: rgba(120, 80, 11, 0.7) linear-gradient(180deg, rgba(149, 120, 70, 0.745), rgba(120, 80, 11, 0.7)) repeat-x;
  border-color: color-yig(rgba(120, 80, 11, 0.7));
}

.module-sand-stone-70:focus, .module-sand-stone-70.focus {
  color: #fff;
  background: rgba(120, 80, 11, 0.7) linear-gradient(180deg, rgba(149, 120, 70, 0.745), rgba(120, 80, 11, 0.7)) repeat-x;
  border-color: color-yig(rgba(120, 80, 11, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(180, 141, 73, 0.5);
}

.module-sand-stone-70:active, .module-sand-stone-70.active,
.show > .module-sand-stone-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 72, 10, 0.7);
  background-image: none;
  border-color: rgba(97, 64, 9, 0.7);
}

.module-sand-stone-70:active:focus, .module-sand-stone-70.active:focus,
.show > .module-sand-stone-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(180, 141, 73, 0.5);
}

.module-sand-stone-70:disabled, .module-sand-stone-70.disabled {
  color: #fff;
  background-color: rgba(155, 103, 14, 0.7);
  background-image: none;
  border-color: rgba(155, 103, 14, 0.7);
}

.module-sand-stone-80 {
  color: #fff;
  background: rgba(155, 103, 14, 0.8) linear-gradient(180deg, rgba(172, 133, 63, 0.83), rgba(155, 103, 14, 0.8)) repeat-x;
  border-color: rgba(155, 103, 14, 0.8);
}

.module-sand-stone-80 .icon-container {
  background-color: rgba(120, 80, 11, 0.8);
}

.module-sand-stone-80 .icon-container.light {
  background-color: rgba(225, 150, 20, 0.8);
}

.module-sand-stone-80:hover {
  color: #fff;
  background: rgba(120, 80, 11, 0.8) linear-gradient(180deg, rgba(145, 114, 61, 0.83), rgba(120, 80, 11, 0.8)) repeat-x;
  border-color: color-yig(rgba(120, 80, 11, 0.8));
}

.module-sand-stone-80:focus, .module-sand-stone-80.focus {
  color: #fff;
  background: rgba(120, 80, 11, 0.8) linear-gradient(180deg, rgba(145, 114, 61, 0.83), rgba(120, 80, 11, 0.8)) repeat-x;
  border-color: color-yig(rgba(120, 80, 11, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(176, 135, 64, 0.5);
}

.module-sand-stone-80:active, .module-sand-stone-80.active,
.show > .module-sand-stone-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 72, 10, 0.8);
  background-image: none;
  border-color: rgba(97, 64, 9, 0.8);
}

.module-sand-stone-80:active:focus, .module-sand-stone-80.active:focus,
.show > .module-sand-stone-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(176, 135, 64, 0.5);
}

.module-sand-stone-80:disabled, .module-sand-stone-80.disabled {
  color: #fff;
  background-color: rgba(155, 103, 14, 0.8);
  background-image: none;
  border-color: rgba(155, 103, 14, 0.8);
}

.module-sand-stone-90 {
  color: #fff;
  background: rgba(155, 103, 14, 0.9) linear-gradient(180deg, rgba(170, 128, 56, 0.915), rgba(155, 103, 14, 0.9)) repeat-x;
  border-color: rgba(155, 103, 14, 0.9);
}

.module-sand-stone-90 .icon-container {
  background-color: rgba(120, 80, 11, 0.9);
}

.module-sand-stone-90 .icon-container.light {
  background-color: rgba(225, 150, 20, 0.9);
}

.module-sand-stone-90:hover {
  color: #fff;
  background: rgba(120, 80, 11, 0.9) linear-gradient(180deg, rgba(141, 109, 53, 0.915), rgba(120, 80, 11, 0.9)) repeat-x;
  border-color: color-yig(rgba(120, 80, 11, 0.9));
}

.module-sand-stone-90:focus, .module-sand-stone-90.focus {
  color: #fff;
  background: rgba(120, 80, 11, 0.9) linear-gradient(180deg, rgba(141, 109, 53, 0.915), rgba(120, 80, 11, 0.9)) repeat-x;
  border-color: color-yig(rgba(120, 80, 11, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(173, 130, 57, 0.5);
}

.module-sand-stone-90:active, .module-sand-stone-90.active,
.show > .module-sand-stone-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 72, 10, 0.9);
  background-image: none;
  border-color: rgba(97, 64, 9, 0.9);
}

.module-sand-stone-90:active:focus, .module-sand-stone-90.active:focus,
.show > .module-sand-stone-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(173, 130, 57, 0.5);
}

.module-sand-stone-90:disabled, .module-sand-stone-90.disabled {
  color: #fff;
  background-color: rgba(155, 103, 14, 0.9);
  background-image: none;
  border-color: rgba(155, 103, 14, 0.9);
}

.module-carrot {
  color: #273a4d;
  background: #f09800 linear-gradient(180deg, #f0a626, #f09800) repeat-x;
  border-color: #f09800;
}

.module-carrot .icon-container {
  background-color: #ca8000;
}

.module-carrot .icon-container.light {
  background-color: #ffb83e;
}

.module-carrot:hover {
  color: #fff;
  background: #ca8000 linear-gradient(180deg, #cf9126, #ca8000) repeat-x;
  border-color: color-yig(#ca8000);
}

.module-carrot:focus, .module-carrot.focus {
  color: #fff;
  background: #ca8000 linear-gradient(180deg, #cf9126, #ca8000) repeat-x;
  border-color: color-yig(#ca8000);
  box-shadow: 0 0 0 0.2rem rgba(210, 138, 12, 0.5);
}

.module-carrot:active, .module-carrot.active,
.show > .module-carrot.dropdown-toggle {
  color: #fff;
  background-color: #bd7800;
  background-image: none;
  border-color: #b07000;
}

.module-carrot:active:focus, .module-carrot.active:focus,
.show > .module-carrot.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 138, 12, 0.5);
}

.module-carrot:disabled, .module-carrot.disabled {
  color: #273a4d;
  background-color: #f09800;
  background-image: none;
  border-color: #f09800;
}

.module-carrot-10 {
  color: #273a4d;
  background: rgba(240, 152, 0, 0.1) linear-gradient(180deg, rgba(238, 224, 193, 0.235), rgba(240, 152, 0, 0.1)) repeat-x;
  border-color: rgba(240, 152, 0, 0.1);
}

.module-carrot-10 .icon-container {
  background-color: rgba(202, 128, 0, 0.1);
}

.module-carrot-10 .icon-container.light {
  background-color: rgba(255, 184, 62, 0.1);
}

.module-carrot-10:hover {
  color: #fff;
  background: rgba(202, 128, 0, 0.1) linear-gradient(180deg, rgba(230, 218, 193, 0.235), rgba(202, 128, 0, 0.1)) repeat-x;
  border-color: color-yig(rgba(202, 128, 0, 0.1));
}

.module-carrot-10:focus, .module-carrot-10.focus {
  color: #fff;
  background: rgba(202, 128, 0, 0.1) linear-gradient(180deg, rgba(230, 218, 193, 0.235), rgba(202, 128, 0, 0.1)) repeat-x;
  border-color: color-yig(rgba(202, 128, 0, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(85, 80, 59, 0.5);
}

.module-carrot-10:active, .module-carrot-10.active,
.show > .module-carrot-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(189, 120, 0, 0.1);
  background-image: none;
  border-color: rgba(176, 112, 0, 0.1);
}

.module-carrot-10:active:focus, .module-carrot-10.active:focus,
.show > .module-carrot-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(85, 80, 59, 0.5);
}

.module-carrot-10:disabled, .module-carrot-10.disabled {
  color: #273a4d;
  background-color: rgba(240, 152, 0, 0.1);
  background-image: none;
  border-color: rgba(240, 152, 0, 0.1);
}

.module-carrot-20 {
  color: #273a4d;
  background: rgba(240, 152, 0, 0.2) linear-gradient(180deg, rgba(239, 209, 153, 0.32), rgba(240, 152, 0, 0.2)) repeat-x;
  border-color: rgba(240, 152, 0, 0.2);
}

.module-carrot-20 .icon-container {
  background-color: rgba(202, 128, 0, 0.2);
}

.module-carrot-20 .icon-container.light {
  background-color: rgba(255, 184, 62, 0.2);
}

.module-carrot-20:hover {
  color: #fff;
  background: rgba(202, 128, 0, 0.2) linear-gradient(180deg, rgba(224, 200, 153, 0.32), rgba(202, 128, 0, 0.2)) repeat-x;
  border-color: color-yig(rgba(202, 128, 0, 0.2));
}

.module-carrot-20:focus, .module-carrot-20.focus {
  color: #fff;
  background: rgba(202, 128, 0, 0.2) linear-gradient(180deg, rgba(224, 200, 153, 0.32), rgba(202, 128, 0, 0.2)) repeat-x;
  border-color: color-yig(rgba(202, 128, 0, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(117, 94, 47, 0.5);
}

.module-carrot-20:active, .module-carrot-20.active,
.show > .module-carrot-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(189, 120, 0, 0.2);
  background-image: none;
  border-color: rgba(176, 112, 0, 0.2);
}

.module-carrot-20:active:focus, .module-carrot-20.active:focus,
.show > .module-carrot-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(117, 94, 47, 0.5);
}

.module-carrot-20:disabled, .module-carrot-20.disabled {
  color: #273a4d;
  background-color: rgba(240, 152, 0, 0.2);
  background-image: none;
  border-color: rgba(240, 152, 0, 0.2);
}

.module-carrot-30 {
  color: #273a4d;
  background: rgba(240, 152, 0, 0.3) linear-gradient(180deg, rgba(239, 199, 125, 0.405), rgba(240, 152, 0, 0.3)) repeat-x;
  border-color: rgba(240, 152, 0, 0.3);
}

.module-carrot-30 .icon-container {
  background-color: rgba(202, 128, 0, 0.3);
}

.module-carrot-30 .icon-container.light {
  background-color: rgba(255, 184, 62, 0.3);
}

.module-carrot-30:hover {
  color: #fff;
  background: rgba(202, 128, 0, 0.3) linear-gradient(180deg, rgba(220, 186, 125, 0.405), rgba(202, 128, 0, 0.3)) repeat-x;
  border-color: color-yig(rgba(202, 128, 0, 0.3));
}

.module-carrot-30:focus, .module-carrot-30.focus {
  color: #fff;
  background: rgba(202, 128, 0, 0.3) linear-gradient(180deg, rgba(220, 186, 125, 0.405), rgba(202, 128, 0, 0.3)) repeat-x;
  border-color: color-yig(rgba(202, 128, 0, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(140, 105, 39, 0.5);
}

.module-carrot-30:active, .module-carrot-30.active,
.show > .module-carrot-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(189, 120, 0, 0.3);
  background-image: none;
  border-color: rgba(176, 112, 0, 0.3);
}

.module-carrot-30:active:focus, .module-carrot-30.active:focus,
.show > .module-carrot-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(140, 105, 39, 0.5);
}

.module-carrot-30:disabled, .module-carrot-30.disabled {
  color: #273a4d;
  background-color: rgba(240, 152, 0, 0.3);
  background-image: none;
  border-color: rgba(240, 152, 0, 0.3);
}

.module-carrot-40 {
  color: #273a4d;
  background: rgba(240, 152, 0, 0.4) linear-gradient(180deg, rgba(239, 190, 103, 0.49), rgba(240, 152, 0, 0.4)) repeat-x;
  border-color: rgba(240, 152, 0, 0.4);
}

.module-carrot-40 .icon-container {
  background-color: rgba(202, 128, 0, 0.4);
}

.module-carrot-40 .icon-container.light {
  background-color: rgba(255, 184, 62, 0.4);
}

.module-carrot-40:hover {
  color: #fff;
  background: rgba(202, 128, 0, 0.4) linear-gradient(180deg, rgba(217, 176, 103, 0.49), rgba(202, 128, 0, 0.4)) repeat-x;
  border-color: color-yig(rgba(202, 128, 0, 0.4));
}

.module-carrot-40:focus, .module-carrot-40.focus {
  color: #fff;
  background: rgba(202, 128, 0, 0.4) linear-gradient(180deg, rgba(217, 176, 103, 0.49), rgba(202, 128, 0, 0.4)) repeat-x;
  border-color: color-yig(rgba(202, 128, 0, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(157, 113, 32, 0.5);
}

.module-carrot-40:active, .module-carrot-40.active,
.show > .module-carrot-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(189, 120, 0, 0.4);
  background-image: none;
  border-color: rgba(176, 112, 0, 0.4);
}

.module-carrot-40:active:focus, .module-carrot-40.active:focus,
.show > .module-carrot-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(157, 113, 32, 0.5);
}

.module-carrot-40:disabled, .module-carrot-40.disabled {
  color: #273a4d;
  background-color: rgba(240, 152, 0, 0.4);
  background-image: none;
  border-color: rgba(240, 152, 0, 0.4);
}

.module-carrot-50 {
  color: #273a4d;
  background: rgba(240, 152, 0, 0.5) linear-gradient(180deg, rgba(239, 184, 87, 0.575), rgba(240, 152, 0, 0.5)) repeat-x;
  border-color: rgba(240, 152, 0, 0.5);
}

.module-carrot-50 .icon-container {
  background-color: rgba(202, 128, 0, 0.5);
}

.module-carrot-50 .icon-container.light {
  background-color: rgba(255, 184, 62, 0.5);
}

.module-carrot-50:hover {
  color: #fff;
  background: rgba(202, 128, 0, 0.5) linear-gradient(180deg, rgba(214, 168, 87, 0.575), rgba(202, 128, 0, 0.5)) repeat-x;
  border-color: color-yig(rgba(202, 128, 0, 0.5));
}

.module-carrot-50:focus, .module-carrot-50.focus {
  color: #fff;
  background: rgba(202, 128, 0, 0.5) linear-gradient(180deg, rgba(214, 168, 87, 0.575), rgba(202, 128, 0, 0.5)) repeat-x;
  border-color: color-yig(rgba(202, 128, 0, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(170, 119, 27, 0.5);
}

.module-carrot-50:active, .module-carrot-50.active,
.show > .module-carrot-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(189, 120, 0, 0.5);
  background-image: none;
  border-color: rgba(176, 112, 0, 0.5);
}

.module-carrot-50:active:focus, .module-carrot-50.active:focus,
.show > .module-carrot-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(170, 119, 27, 0.5);
}

.module-carrot-50:disabled, .module-carrot-50.disabled {
  color: #273a4d;
  background-color: rgba(240, 152, 0, 0.5);
  background-image: none;
  border-color: rgba(240, 152, 0, 0.5);
}

.module-carrot-60 {
  color: #273a4d;
  background: rgba(240, 152, 0, 0.6) linear-gradient(180deg, rgba(239, 179, 73, 0.66), rgba(240, 152, 0, 0.6)) repeat-x;
  border-color: rgba(240, 152, 0, 0.6);
}

.module-carrot-60 .icon-container {
  background-color: rgba(202, 128, 0, 0.6);
}

.module-carrot-60 .icon-container.light {
  background-color: rgba(255, 184, 62, 0.6);
}

.module-carrot-60:hover {
  color: #fff;
  background: rgba(202, 128, 0, 0.6) linear-gradient(180deg, rgba(212, 162, 73, 0.66), rgba(202, 128, 0, 0.6)) repeat-x;
  border-color: color-yig(rgba(202, 128, 0, 0.6));
}

.module-carrot-60:focus, .module-carrot-60.focus {
  color: #fff;
  background: rgba(202, 128, 0, 0.6) linear-gradient(180deg, rgba(212, 162, 73, 0.66), rgba(202, 128, 0, 0.6)) repeat-x;
  border-color: color-yig(rgba(202, 128, 0, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(181, 125, 22, 0.5);
}

.module-carrot-60:active, .module-carrot-60.active,
.show > .module-carrot-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(189, 120, 0, 0.6);
  background-image: none;
  border-color: rgba(176, 112, 0, 0.6);
}

.module-carrot-60:active:focus, .module-carrot-60.active:focus,
.show > .module-carrot-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(181, 125, 22, 0.5);
}

.module-carrot-60:disabled, .module-carrot-60.disabled {
  color: #273a4d;
  background-color: rgba(240, 152, 0, 0.6);
  background-image: none;
  border-color: rgba(240, 152, 0, 0.6);
}

.module-carrot-70 {
  color: #273a4d;
  background: rgba(240, 152, 0, 0.7) linear-gradient(180deg, rgba(240, 175, 62, 0.745), rgba(240, 152, 0, 0.7)) repeat-x;
  border-color: rgba(240, 152, 0, 0.7);
}

.module-carrot-70 .icon-container {
  background-color: rgba(202, 128, 0, 0.7);
}

.module-carrot-70 .icon-container.light {
  background-color: rgba(255, 184, 62, 0.7);
}

.module-carrot-70:hover {
  color: #fff;
  background: rgba(202, 128, 0, 0.7) linear-gradient(180deg, rgba(211, 157, 62, 0.745), rgba(202, 128, 0, 0.7)) repeat-x;
  border-color: color-yig(rgba(202, 128, 0, 0.7));
}

.module-carrot-70:focus, .module-carrot-70.focus {
  color: #fff;
  background: rgba(202, 128, 0, 0.7) linear-gradient(180deg, rgba(211, 157, 62, 0.745), rgba(202, 128, 0, 0.7)) repeat-x;
  border-color: color-yig(rgba(202, 128, 0, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(190, 129, 19, 0.5);
}

.module-carrot-70:active, .module-carrot-70.active,
.show > .module-carrot-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(189, 120, 0, 0.7);
  background-image: none;
  border-color: rgba(176, 112, 0, 0.7);
}

.module-carrot-70:active:focus, .module-carrot-70.active:focus,
.show > .module-carrot-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(190, 129, 19, 0.5);
}

.module-carrot-70:disabled, .module-carrot-70.disabled {
  color: #273a4d;
  background-color: rgba(240, 152, 0, 0.7);
  background-image: none;
  border-color: rgba(240, 152, 0, 0.7);
}

.module-carrot-80 {
  color: #273a4d;
  background: rgba(240, 152, 0, 0.8) linear-gradient(180deg, rgba(240, 171, 52, 0.83), rgba(240, 152, 0, 0.8)) repeat-x;
  border-color: rgba(240, 152, 0, 0.8);
}

.module-carrot-80 .icon-container {
  background-color: rgba(202, 128, 0, 0.8);
}

.module-carrot-80 .icon-container.light {
  background-color: rgba(255, 184, 62, 0.8);
}

.module-carrot-80:hover {
  color: #fff;
  background: rgba(202, 128, 0, 0.8) linear-gradient(180deg, rgba(209, 152, 52, 0.83), rgba(202, 128, 0, 0.8)) repeat-x;
  border-color: color-yig(rgba(202, 128, 0, 0.8));
}

.module-carrot-80:focus, .module-carrot-80.focus {
  color: #fff;
  background: rgba(202, 128, 0, 0.8) linear-gradient(180deg, rgba(209, 152, 52, 0.83), rgba(202, 128, 0, 0.8)) repeat-x;
  border-color: color-yig(rgba(202, 128, 0, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(198, 132, 16, 0.5);
}

.module-carrot-80:active, .module-carrot-80.active,
.show > .module-carrot-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(189, 120, 0, 0.8);
  background-image: none;
  border-color: rgba(176, 112, 0, 0.8);
}

.module-carrot-80:active:focus, .module-carrot-80.active:focus,
.show > .module-carrot-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(198, 132, 16, 0.5);
}

.module-carrot-80:disabled, .module-carrot-80.disabled {
  color: #273a4d;
  background-color: rgba(240, 152, 0, 0.8);
  background-image: none;
  border-color: rgba(240, 152, 0, 0.8);
}

.module-carrot-90 {
  color: #273a4d;
  background: rgba(240, 152, 0, 0.9) linear-gradient(180deg, rgba(240, 169, 44, 0.915), rgba(240, 152, 0, 0.9)) repeat-x;
  border-color: rgba(240, 152, 0, 0.9);
}

.module-carrot-90 .icon-container {
  background-color: rgba(202, 128, 0, 0.9);
}

.module-carrot-90 .icon-container.light {
  background-color: rgba(255, 184, 62, 0.9);
}

.module-carrot-90:hover {
  color: #fff;
  background: rgba(202, 128, 0, 0.9) linear-gradient(180deg, rgba(208, 149, 44, 0.915), rgba(202, 128, 0, 0.9)) repeat-x;
  border-color: color-yig(rgba(202, 128, 0, 0.9));
}

.module-carrot-90:focus, .module-carrot-90.focus {
  color: #fff;
  background: rgba(202, 128, 0, 0.9) linear-gradient(180deg, rgba(208, 149, 44, 0.915), rgba(202, 128, 0, 0.9)) repeat-x;
  border-color: color-yig(rgba(202, 128, 0, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(204, 135, 14, 0.5);
}

.module-carrot-90:active, .module-carrot-90.active,
.show > .module-carrot-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(189, 120, 0, 0.9);
  background-image: none;
  border-color: rgba(176, 112, 0, 0.9);
}

.module-carrot-90:active:focus, .module-carrot-90.active:focus,
.show > .module-carrot-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(204, 135, 14, 0.5);
}

.module-carrot-90:disabled, .module-carrot-90.disabled {
  color: #273a4d;
  background-color: rgba(240, 152, 0, 0.9);
  background-image: none;
  border-color: rgba(240, 152, 0, 0.9);
}

.module-apricot {
  color: #273a4d;
  background: #ffc155 linear-gradient(180deg, #fcc96e, #ffc155) repeat-x;
  border-color: #ffc155;
}

.module-apricot .icon-container {
  background-color: #ffb32f;
}

.module-apricot .icon-container.light {
  background-color: #ffdda2;
}

.module-apricot:hover {
  color: #273a4d;
  background: #ffb32f linear-gradient(180deg, #fcbd4d, #ffb32f) repeat-x;
  border-color: color-yig(#ffb32f);
}

.module-apricot:focus, .module-apricot.focus {
  color: #273a4d;
  background: #ffb32f linear-gradient(180deg, #fcbd4d, #ffb32f) repeat-x;
  border-color: color-yig(#ffb32f);
  box-shadow: 0 0 0 0.2rem rgba(223, 173, 84, 0.5);
}

.module-apricot:active, .module-apricot.active,
.show > .module-apricot.dropdown-toggle {
  color: #273a4d;
  background-color: #ffae22;
  background-image: none;
  border-color: #ffaa15;
}

.module-apricot:active:focus, .module-apricot.active:focus,
.show > .module-apricot.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 173, 84, 0.5);
}

.module-apricot:disabled, .module-apricot.disabled {
  color: #273a4d;
  background-color: #ffc155;
  background-image: none;
  border-color: #ffc155;
}

.module-apricot-10 {
  color: #273a4d;
  background: rgba(255, 193, 85, 0.1) linear-gradient(180deg, rgba(242, 233, 212, 0.235), rgba(255, 193, 85, 0.1)) repeat-x;
  border-color: rgba(255, 193, 85, 0.1);
}

.module-apricot-10 .icon-container {
  background-color: rgba(255, 179, 47, 0.1);
}

.module-apricot-10 .icon-container.light {
  background-color: rgba(255, 221, 162, 0.1);
}

.module-apricot-10:hover {
  color: #273a4d;
  background: rgba(255, 179, 47, 0.1) linear-gradient(180deg, rgba(242, 230, 203, 0.235), rgba(255, 179, 47, 0.1)) repeat-x;
  border-color: color-yig(rgba(255, 179, 47, 0.1));
}

.module-apricot-10:focus, .module-apricot-10.focus {
  color: #273a4d;
  background: rgba(255, 179, 47, 0.1) linear-gradient(180deg, rgba(242, 230, 203, 0.235), rgba(255, 179, 47, 0.1)) repeat-x;
  border-color: color-yig(rgba(255, 179, 47, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(89, 89, 79, 0.5);
}

.module-apricot-10:active, .module-apricot-10.active,
.show > .module-apricot-10.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 174, 34, 0.1);
  background-image: none;
  border-color: rgba(255, 170, 21, 0.1);
}

.module-apricot-10:active:focus, .module-apricot-10.active:focus,
.show > .module-apricot-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 89, 79, 0.5);
}

.module-apricot-10:disabled, .module-apricot-10.disabled {
  color: #273a4d;
  background-color: rgba(255, 193, 85, 0.1);
  background-image: none;
  border-color: rgba(255, 193, 85, 0.1);
}

.module-apricot-20 {
  color: #273a4d;
  background: rgba(255, 193, 85, 0.2) linear-gradient(180deg, rgba(245, 225, 186, 0.32), rgba(255, 193, 85, 0.2)) repeat-x;
  border-color: rgba(255, 193, 85, 0.2);
}

.module-apricot-20 .icon-container {
  background-color: rgba(255, 179, 47, 0.2);
}

.module-apricot-20 .icon-container.light {
  background-color: rgba(255, 221, 162, 0.2);
}

.module-apricot-20:hover {
  color: #273a4d;
  background: rgba(255, 179, 47, 0.2) linear-gradient(180deg, rgba(245, 220, 171, 0.32), rgba(255, 179, 47, 0.2)) repeat-x;
  border-color: color-yig(rgba(255, 179, 47, 0.2));
}

.module-apricot-20:focus, .module-apricot-20.focus {
  color: #273a4d;
  background: rgba(255, 179, 47, 0.2) linear-gradient(180deg, rgba(245, 220, 171, 0.32), rgba(255, 179, 47, 0.2)) repeat-x;
  border-color: color-yig(rgba(255, 179, 47, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(122, 110, 80, 0.5);
}

.module-apricot-20:active, .module-apricot-20.active,
.show > .module-apricot-20.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 174, 34, 0.2);
  background-image: none;
  border-color: rgba(255, 170, 21, 0.2);
}

.module-apricot-20:active:focus, .module-apricot-20.active:focus,
.show > .module-apricot-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 110, 80, 0.5);
}

.module-apricot-20:disabled, .module-apricot-20.disabled {
  color: #273a4d;
  background-color: rgba(255, 193, 85, 0.2);
  background-image: none;
  border-color: rgba(255, 193, 85, 0.2);
}

.module-apricot-30 {
  color: #273a4d;
  background: rgba(255, 193, 85, 0.3) linear-gradient(180deg, rgba(247, 219, 168, 0.405), rgba(255, 193, 85, 0.3)) repeat-x;
  border-color: rgba(255, 193, 85, 0.3);
}

.module-apricot-30 .icon-container {
  background-color: rgba(255, 179, 47, 0.3);
}

.module-apricot-30 .icon-container.light {
  background-color: rgba(255, 221, 162, 0.3);
}

.module-apricot-30:hover {
  color: #273a4d;
  background: rgba(255, 179, 47, 0.3) linear-gradient(180deg, rgba(247, 212, 148, 0.405), rgba(255, 179, 47, 0.3)) repeat-x;
  border-color: color-yig(rgba(255, 179, 47, 0.3));
}

.module-apricot-30:focus, .module-apricot-30.focus {
  color: #273a4d;
  background: rgba(255, 179, 47, 0.3) linear-gradient(180deg, rgba(247, 212, 148, 0.405), rgba(255, 179, 47, 0.3)) repeat-x;
  border-color: color-yig(rgba(255, 179, 47, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(147, 126, 81, 0.5);
}

.module-apricot-30:active, .module-apricot-30.active,
.show > .module-apricot-30.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 174, 34, 0.3);
  background-image: none;
  border-color: rgba(255, 170, 21, 0.3);
}

.module-apricot-30:active:focus, .module-apricot-30.active:focus,
.show > .module-apricot-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 126, 81, 0.5);
}

.module-apricot-30:disabled, .module-apricot-30.disabled {
  color: #273a4d;
  background-color: rgba(255, 193, 85, 0.3);
  background-image: none;
  border-color: rgba(255, 193, 85, 0.3);
}

.module-apricot-40 {
  color: #273a4d;
  background: rgba(255, 193, 85, 0.4) linear-gradient(180deg, rgba(248, 215, 153, 0.49), rgba(255, 193, 85, 0.4)) repeat-x;
  border-color: rgba(255, 193, 85, 0.4);
}

.module-apricot-40 .icon-container {
  background-color: rgba(255, 179, 47, 0.4);
}

.module-apricot-40 .icon-container.light {
  background-color: rgba(255, 221, 162, 0.4);
}

.module-apricot-40:hover {
  color: #273a4d;
  background: rgba(255, 179, 47, 0.4) linear-gradient(180deg, rgba(248, 206, 131, 0.49), rgba(255, 179, 47, 0.4)) repeat-x;
  border-color: color-yig(rgba(255, 179, 47, 0.4));
}

.module-apricot-40:focus, .module-apricot-40.focus {
  color: #273a4d;
  background: rgba(255, 179, 47, 0.4) linear-gradient(180deg, rgba(248, 206, 131, 0.49), rgba(255, 179, 47, 0.4)) repeat-x;
  border-color: color-yig(rgba(255, 179, 47, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(166, 137, 82, 0.5);
}

.module-apricot-40:active, .module-apricot-40.active,
.show > .module-apricot-40.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 174, 34, 0.4);
  background-image: none;
  border-color: rgba(255, 170, 21, 0.4);
}

.module-apricot-40:active:focus, .module-apricot-40.active:focus,
.show > .module-apricot-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 137, 82, 0.5);
}

.module-apricot-40:disabled, .module-apricot-40.disabled {
  color: #273a4d;
  background-color: rgba(255, 193, 85, 0.4);
  background-image: none;
  border-color: rgba(255, 193, 85, 0.4);
}

.module-apricot-50 {
  color: #273a4d;
  background: rgba(255, 193, 85, 0.5) linear-gradient(180deg, rgba(249, 211, 142, 0.575), rgba(255, 193, 85, 0.5)) repeat-x;
  border-color: rgba(255, 193, 85, 0.5);
}

.module-apricot-50 .icon-container {
  background-color: rgba(255, 179, 47, 0.5);
}

.module-apricot-50 .icon-container.light {
  background-color: rgba(255, 221, 162, 0.5);
}

.module-apricot-50:hover {
  color: #273a4d;
  background: rgba(255, 179, 47, 0.5) linear-gradient(180deg, rgba(249, 202, 117, 0.575), rgba(255, 179, 47, 0.5)) repeat-x;
  border-color: color-yig(rgba(255, 179, 47, 0.5));
}

.module-apricot-50:focus, .module-apricot-50.focus {
  color: #273a4d;
  background: rgba(255, 179, 47, 0.5) linear-gradient(180deg, rgba(249, 202, 117, 0.575), rgba(255, 179, 47, 0.5)) repeat-x;
  border-color: color-yig(rgba(255, 179, 47, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(180, 146, 82, 0.5);
}

.module-apricot-50:active, .module-apricot-50.active,
.show > .module-apricot-50.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 174, 34, 0.5);
  background-image: none;
  border-color: rgba(255, 170, 21, 0.5);
}

.module-apricot-50:active:focus, .module-apricot-50.active:focus,
.show > .module-apricot-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(180, 146, 82, 0.5);
}

.module-apricot-50:disabled, .module-apricot-50.disabled {
  color: #273a4d;
  background-color: rgba(255, 193, 85, 0.5);
  background-image: none;
  border-color: rgba(255, 193, 85, 0.5);
}

.module-apricot-60 {
  color: #273a4d;
  background: rgba(255, 193, 85, 0.6) linear-gradient(180deg, rgba(250, 208, 133, 0.66), rgba(255, 193, 85, 0.6)) repeat-x;
  border-color: rgba(255, 193, 85, 0.6);
}

.module-apricot-60 .icon-container {
  background-color: rgba(255, 179, 47, 0.6);
}

.module-apricot-60 .icon-container.light {
  background-color: rgba(255, 221, 162, 0.6);
}

.module-apricot-60:hover {
  color: #273a4d;
  background: rgba(255, 179, 47, 0.6) linear-gradient(180deg, rgba(250, 198, 106, 0.66), rgba(255, 179, 47, 0.6)) repeat-x;
  border-color: color-yig(rgba(255, 179, 47, 0.6));
}

.module-apricot-60:focus, .module-apricot-60.focus {
  color: #273a4d;
  background: rgba(255, 179, 47, 0.6) linear-gradient(180deg, rgba(250, 198, 106, 0.66), rgba(255, 179, 47, 0.6)) repeat-x;
  border-color: color-yig(rgba(255, 179, 47, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(192, 154, 83, 0.5);
}

.module-apricot-60:active, .module-apricot-60.active,
.show > .module-apricot-60.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 174, 34, 0.6);
  background-image: none;
  border-color: rgba(255, 170, 21, 0.6);
}

.module-apricot-60:active:focus, .module-apricot-60.active:focus,
.show > .module-apricot-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(192, 154, 83, 0.5);
}

.module-apricot-60:disabled, .module-apricot-60.disabled {
  color: #273a4d;
  background-color: rgba(255, 193, 85, 0.6);
  background-image: none;
  border-color: rgba(255, 193, 85, 0.6);
}

.module-apricot-70 {
  color: #273a4d;
  background: rgba(255, 193, 85, 0.7) linear-gradient(180deg, rgba(251, 206, 126, 0.745), rgba(255, 193, 85, 0.7)) repeat-x;
  border-color: rgba(255, 193, 85, 0.7);
}

.module-apricot-70 .icon-container {
  background-color: rgba(255, 179, 47, 0.7);
}

.module-apricot-70 .icon-container.light {
  background-color: rgba(255, 221, 162, 0.7);
}

.module-apricot-70:hover {
  color: #273a4d;
  background: rgba(255, 179, 47, 0.7) linear-gradient(180deg, rgba(251, 195, 97, 0.745), rgba(255, 179, 47, 0.7)) repeat-x;
  border-color: color-yig(rgba(255, 179, 47, 0.7));
}

.module-apricot-70:focus, .module-apricot-70.focus {
  color: #273a4d;
  background: rgba(255, 179, 47, 0.7) linear-gradient(180deg, rgba(251, 195, 97, 0.745), rgba(255, 179, 47, 0.7)) repeat-x;
  border-color: color-yig(rgba(255, 179, 47, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(202, 160, 83, 0.5);
}

.module-apricot-70:active, .module-apricot-70.active,
.show > .module-apricot-70.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 174, 34, 0.7);
  background-image: none;
  border-color: rgba(255, 170, 21, 0.7);
}

.module-apricot-70:active:focus, .module-apricot-70.active:focus,
.show > .module-apricot-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(202, 160, 83, 0.5);
}

.module-apricot-70:disabled, .module-apricot-70.disabled {
  color: #273a4d;
  background-color: rgba(255, 193, 85, 0.7);
  background-image: none;
  border-color: rgba(255, 193, 85, 0.7);
}

.module-apricot-80 {
  color: #273a4d;
  background: rgba(255, 193, 85, 0.8) linear-gradient(180deg, rgba(251, 204, 120, 0.83), rgba(255, 193, 85, 0.8)) repeat-x;
  border-color: rgba(255, 193, 85, 0.8);
}

.module-apricot-80 .icon-container {
  background-color: rgba(255, 179, 47, 0.8);
}

.module-apricot-80 .icon-container.light {
  background-color: rgba(255, 221, 162, 0.8);
}

.module-apricot-80:hover {
  color: #273a4d;
  background: rgba(255, 179, 47, 0.8) linear-gradient(180deg, rgba(251, 193, 89, 0.83), rgba(255, 179, 47, 0.8)) repeat-x;
  border-color: color-yig(rgba(255, 179, 47, 0.8));
}

.module-apricot-80:focus, .module-apricot-80.focus {
  color: #273a4d;
  background: rgba(255, 179, 47, 0.8) linear-gradient(180deg, rgba(251, 193, 89, 0.83), rgba(255, 179, 47, 0.8)) repeat-x;
  border-color: color-yig(rgba(255, 179, 47, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(210, 165, 83, 0.5);
}

.module-apricot-80:active, .module-apricot-80.active,
.show > .module-apricot-80.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 174, 34, 0.8);
  background-image: none;
  border-color: rgba(255, 170, 21, 0.8);
}

.module-apricot-80:active:focus, .module-apricot-80.active:focus,
.show > .module-apricot-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 165, 83, 0.5);
}

.module-apricot-80:disabled, .module-apricot-80.disabled {
  color: #273a4d;
  background-color: rgba(255, 193, 85, 0.8);
  background-image: none;
  border-color: rgba(255, 193, 85, 0.8);
}

.module-apricot-90 {
  color: #273a4d;
  background: rgba(255, 193, 85, 0.9) linear-gradient(180deg, rgba(252, 202, 114, 0.915), rgba(255, 193, 85, 0.9)) repeat-x;
  border-color: rgba(255, 193, 85, 0.9);
}

.module-apricot-90 .icon-container {
  background-color: rgba(255, 179, 47, 0.9);
}

.module-apricot-90 .icon-container.light {
  background-color: rgba(255, 221, 162, 0.9);
}

.module-apricot-90:hover {
  color: #273a4d;
  background: rgba(255, 179, 47, 0.9) linear-gradient(180deg, rgba(252, 191, 83, 0.915), rgba(255, 179, 47, 0.9)) repeat-x;
  border-color: color-yig(rgba(255, 179, 47, 0.9));
}

.module-apricot-90:focus, .module-apricot-90.focus {
  color: #273a4d;
  background: rgba(255, 179, 47, 0.9) linear-gradient(180deg, rgba(252, 191, 83, 0.915), rgba(255, 179, 47, 0.9)) repeat-x;
  border-color: color-yig(rgba(255, 179, 47, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(217, 169, 84, 0.5);
}

.module-apricot-90:active, .module-apricot-90.active,
.show > .module-apricot-90.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 174, 34, 0.9);
  background-image: none;
  border-color: rgba(255, 170, 21, 0.9);
}

.module-apricot-90:active:focus, .module-apricot-90.active:focus,
.show > .module-apricot-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 169, 84, 0.5);
}

.module-apricot-90:disabled, .module-apricot-90.disabled {
  color: #273a4d;
  background-color: rgba(255, 193, 85, 0.9);
  background-image: none;
  border-color: rgba(255, 193, 85, 0.9);
}

.module-sugar-melon {
  color: #273a4d;
  background: #ffdda1 linear-gradient(180deg, #fce1ae, #ffdda1) repeat-x;
  border-color: #ffdda1;
}

.module-sugar-melon .icon-container {
  background-color: #ffcf7b;
}

.module-sugar-melon .icon-container.light {
  background-color: #fff9ee;
}

.module-sugar-melon:hover {
  color: #273a4d;
  background: #ffcf7b linear-gradient(180deg, #fcd58e, #ffcf7b) repeat-x;
  border-color: color-yig(#ffcf7b);
}

.module-sugar-melon:focus, .module-sugar-melon.focus {
  color: #273a4d;
  background: #ffcf7b linear-gradient(180deg, #fcd58e, #ffcf7b) repeat-x;
  border-color: color-yig(#ffcf7b);
  box-shadow: 0 0 0 0.2rem rgba(223, 197, 148, 0.5);
}

.module-sugar-melon:active, .module-sugar-melon.active,
.show > .module-sugar-melon.dropdown-toggle {
  color: #273a4d;
  background-color: #ffcb6e;
  background-image: none;
  border-color: #ffc661;
}

.module-sugar-melon:active:focus, .module-sugar-melon.active:focus,
.show > .module-sugar-melon.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 197, 148, 0.5);
}

.module-sugar-melon:disabled, .module-sugar-melon.disabled {
  color: #273a4d;
  background-color: #ffdda1;
  background-image: none;
  border-color: #ffdda1;
}

.module-sugar-melon-10 {
  color: #273a4d;
  background: rgba(255, 221, 161, 0.1) linear-gradient(180deg, rgba(242, 239, 230, 0.235), rgba(255, 221, 161, 0.1)) repeat-x;
  border-color: rgba(255, 221, 161, 0.1);
}

.module-sugar-melon-10 .icon-container {
  background-color: rgba(255, 207, 123, 0.1);
}

.module-sugar-melon-10 .icon-container.light {
  background-color: rgba(255, 249, 238, 0.1);
}

.module-sugar-melon-10:hover {
  color: #273a4d;
  background: rgba(255, 207, 123, 0.1) linear-gradient(180deg, rgba(242, 236, 221, 0.235), rgba(255, 207, 123, 0.1)) repeat-x;
  border-color: color-yig(rgba(255, 207, 123, 0.1));
}

.module-sugar-melon-10:focus, .module-sugar-melon-10.focus {
  color: #273a4d;
  background: rgba(255, 207, 123, 0.1) linear-gradient(180deg, rgba(242, 236, 221, 0.235), rgba(255, 207, 123, 0.1)) repeat-x;
  border-color: color-yig(rgba(255, 207, 123, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(89, 95, 96, 0.5);
}

.module-sugar-melon-10:active, .module-sugar-melon-10.active,
.show > .module-sugar-melon-10.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 203, 110, 0.1);
  background-image: none;
  border-color: rgba(255, 198, 97, 0.1);
}

.module-sugar-melon-10:active:focus, .module-sugar-melon-10.active:focus,
.show > .module-sugar-melon-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 95, 96, 0.5);
}

.module-sugar-melon-10:disabled, .module-sugar-melon-10.disabled {
  color: #273a4d;
  background-color: rgba(255, 221, 161, 0.1);
  background-image: none;
  border-color: rgba(255, 221, 161, 0.1);
}

.module-sugar-melon-20 {
  color: #273a4d;
  background: rgba(255, 221, 161, 0.2) linear-gradient(180deg, rgba(245, 236, 216, 0.32), rgba(255, 221, 161, 0.2)) repeat-x;
  border-color: rgba(255, 221, 161, 0.2);
}

.module-sugar-melon-20 .icon-container {
  background-color: rgba(255, 207, 123, 0.2);
}

.module-sugar-melon-20 .icon-container.light {
  background-color: rgba(255, 249, 238, 0.2);
}

.module-sugar-melon-20:hover {
  color: #273a4d;
  background: rgba(255, 207, 123, 0.2) linear-gradient(180deg, rgba(245, 230, 201, 0.32), rgba(255, 207, 123, 0.2)) repeat-x;
  border-color: color-yig(rgba(255, 207, 123, 0.2));
}

.module-sugar-melon-20:focus, .module-sugar-melon-20.focus {
  color: #273a4d;
  background: rgba(255, 207, 123, 0.2) linear-gradient(180deg, rgba(245, 230, 201, 0.32), rgba(255, 207, 123, 0.2)) repeat-x;
  border-color: color-yig(rgba(255, 207, 123, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(122, 121, 109, 0.5);
}

.module-sugar-melon-20:active, .module-sugar-melon-20.active,
.show > .module-sugar-melon-20.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 203, 110, 0.2);
  background-image: none;
  border-color: rgba(255, 198, 97, 0.2);
}

.module-sugar-melon-20:active:focus, .module-sugar-melon-20.active:focus,
.show > .module-sugar-melon-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 121, 109, 0.5);
}

.module-sugar-melon-20:disabled, .module-sugar-melon-20.disabled {
  color: #273a4d;
  background-color: rgba(255, 221, 161, 0.2);
  background-image: none;
  border-color: rgba(255, 221, 161, 0.2);
}

.module-sugar-melon-30 {
  color: #273a4d;
  background: rgba(255, 221, 161, 0.3) linear-gradient(180deg, rgba(247, 233, 206, 0.405), rgba(255, 221, 161, 0.3)) repeat-x;
  border-color: rgba(255, 221, 161, 0.3);
}

.module-sugar-melon-30 .icon-container {
  background-color: rgba(255, 207, 123, 0.3);
}

.module-sugar-melon-30 .icon-container.light {
  background-color: rgba(255, 249, 238, 0.3);
}

.module-sugar-melon-30:hover {
  color: #273a4d;
  background: rgba(255, 207, 123, 0.3) linear-gradient(180deg, rgba(247, 226, 186, 0.405), rgba(255, 207, 123, 0.3)) repeat-x;
  border-color: color-yig(rgba(255, 207, 123, 0.3));
}

.module-sugar-melon-30:focus, .module-sugar-melon-30.focus {
  color: #273a4d;
  background: rgba(255, 207, 123, 0.3) linear-gradient(180deg, rgba(247, 226, 186, 0.405), rgba(255, 207, 123, 0.3)) repeat-x;
  border-color: color-yig(rgba(255, 207, 123, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(147, 140, 119, 0.5);
}

.module-sugar-melon-30:active, .module-sugar-melon-30.active,
.show > .module-sugar-melon-30.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 203, 110, 0.3);
  background-image: none;
  border-color: rgba(255, 198, 97, 0.3);
}

.module-sugar-melon-30:active:focus, .module-sugar-melon-30.active:focus,
.show > .module-sugar-melon-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 140, 119, 0.5);
}

.module-sugar-melon-30:disabled, .module-sugar-melon-30.disabled {
  color: #273a4d;
  background-color: rgba(255, 221, 161, 0.3);
  background-image: none;
  border-color: rgba(255, 221, 161, 0.3);
}

.module-sugar-melon-40 {
  color: #273a4d;
  background: rgba(255, 221, 161, 0.4) linear-gradient(180deg, rgba(248, 231, 198, 0.49), rgba(255, 221, 161, 0.4)) repeat-x;
  border-color: rgba(255, 221, 161, 0.4);
}

.module-sugar-melon-40 .icon-container {
  background-color: rgba(255, 207, 123, 0.4);
}

.module-sugar-melon-40 .icon-container.light {
  background-color: rgba(255, 249, 238, 0.4);
}

.module-sugar-melon-40:hover {
  color: #273a4d;
  background: rgba(255, 207, 123, 0.4) linear-gradient(180deg, rgba(248, 223, 175, 0.49), rgba(255, 207, 123, 0.4)) repeat-x;
  border-color: color-yig(rgba(255, 207, 123, 0.4));
}

.module-sugar-melon-40:focus, .module-sugar-melon-40.focus {
  color: #273a4d;
  background: rgba(255, 207, 123, 0.4) linear-gradient(180deg, rgba(248, 223, 175, 0.49), rgba(255, 207, 123, 0.4)) repeat-x;
  border-color: color-yig(rgba(255, 207, 123, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(166, 154, 126, 0.5);
}

.module-sugar-melon-40:active, .module-sugar-melon-40.active,
.show > .module-sugar-melon-40.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 203, 110, 0.4);
  background-image: none;
  border-color: rgba(255, 198, 97, 0.4);
}

.module-sugar-melon-40:active:focus, .module-sugar-melon-40.active:focus,
.show > .module-sugar-melon-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 154, 126, 0.5);
}

.module-sugar-melon-40:disabled, .module-sugar-melon-40.disabled {
  color: #273a4d;
  background-color: rgba(255, 221, 161, 0.4);
  background-image: none;
  border-color: rgba(255, 221, 161, 0.4);
}

.module-sugar-melon-50 {
  color: #273a4d;
  background: rgba(255, 221, 161, 0.5) linear-gradient(180deg, rgba(249, 229, 192, 0.575), rgba(255, 221, 161, 0.5)) repeat-x;
  border-color: rgba(255, 221, 161, 0.5);
}

.module-sugar-melon-50 .icon-container {
  background-color: rgba(255, 207, 123, 0.5);
}

.module-sugar-melon-50 .icon-container.light {
  background-color: rgba(255, 249, 238, 0.5);
}

.module-sugar-melon-50:hover {
  color: #273a4d;
  background: rgba(255, 207, 123, 0.5) linear-gradient(180deg, rgba(249, 220, 167, 0.575), rgba(255, 207, 123, 0.5)) repeat-x;
  border-color: color-yig(rgba(255, 207, 123, 0.5));
}

.module-sugar-melon-50:focus, .module-sugar-melon-50.focus {
  color: #273a4d;
  background: rgba(255, 207, 123, 0.5) linear-gradient(180deg, rgba(249, 220, 167, 0.575), rgba(255, 207, 123, 0.5)) repeat-x;
  border-color: color-yig(rgba(255, 207, 123, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(180, 165, 132, 0.5);
}

.module-sugar-melon-50:active, .module-sugar-melon-50.active,
.show > .module-sugar-melon-50.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 203, 110, 0.5);
  background-image: none;
  border-color: rgba(255, 198, 97, 0.5);
}

.module-sugar-melon-50:active:focus, .module-sugar-melon-50.active:focus,
.show > .module-sugar-melon-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(180, 165, 132, 0.5);
}

.module-sugar-melon-50:disabled, .module-sugar-melon-50.disabled {
  color: #273a4d;
  background-color: rgba(255, 221, 161, 0.5);
  background-image: none;
  border-color: rgba(255, 221, 161, 0.5);
}

.module-sugar-melon-60 {
  color: #273a4d;
  background: rgba(255, 221, 161, 0.6) linear-gradient(180deg, rgba(250, 228, 187, 0.66), rgba(255, 221, 161, 0.6)) repeat-x;
  border-color: rgba(255, 221, 161, 0.6);
}

.module-sugar-melon-60 .icon-container {
  background-color: rgba(255, 207, 123, 0.6);
}

.module-sugar-melon-60 .icon-container.light {
  background-color: rgba(255, 249, 238, 0.6);
}

.module-sugar-melon-60:hover {
  color: #273a4d;
  background: rgba(255, 207, 123, 0.6) linear-gradient(180deg, rgba(250, 218, 160, 0.66), rgba(255, 207, 123, 0.6)) repeat-x;
  border-color: color-yig(rgba(255, 207, 123, 0.6));
}

.module-sugar-melon-60:focus, .module-sugar-melon-60.focus {
  color: #273a4d;
  background: rgba(255, 207, 123, 0.6) linear-gradient(180deg, rgba(250, 218, 160, 0.66), rgba(255, 207, 123, 0.6)) repeat-x;
  border-color: color-yig(rgba(255, 207, 123, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(192, 173, 137, 0.5);
}

.module-sugar-melon-60:active, .module-sugar-melon-60.active,
.show > .module-sugar-melon-60.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 203, 110, 0.6);
  background-image: none;
  border-color: rgba(255, 198, 97, 0.6);
}

.module-sugar-melon-60:active:focus, .module-sugar-melon-60.active:focus,
.show > .module-sugar-melon-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(192, 173, 137, 0.5);
}

.module-sugar-melon-60:disabled, .module-sugar-melon-60.disabled {
  color: #273a4d;
  background-color: rgba(255, 221, 161, 0.6);
  background-image: none;
  border-color: rgba(255, 221, 161, 0.6);
}

.module-sugar-melon-70 {
  color: #273a4d;
  background: rgba(255, 221, 161, 0.7) linear-gradient(180deg, rgba(251, 227, 183, 0.745), rgba(255, 221, 161, 0.7)) repeat-x;
  border-color: rgba(255, 221, 161, 0.7);
}

.module-sugar-melon-70 .icon-container {
  background-color: rgba(255, 207, 123, 0.7);
}

.module-sugar-melon-70 .icon-container.light {
  background-color: rgba(255, 249, 238, 0.7);
}

.module-sugar-melon-70:hover {
  color: #273a4d;
  background: rgba(255, 207, 123, 0.7) linear-gradient(180deg, rgba(251, 217, 154, 0.745), rgba(255, 207, 123, 0.7)) repeat-x;
  border-color: color-yig(rgba(255, 207, 123, 0.7));
}

.module-sugar-melon-70:focus, .module-sugar-melon-70.focus {
  color: #273a4d;
  background: rgba(255, 207, 123, 0.7) linear-gradient(180deg, rgba(251, 217, 154, 0.745), rgba(255, 207, 123, 0.7)) repeat-x;
  border-color: color-yig(rgba(255, 207, 123, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(202, 181, 140, 0.5);
}

.module-sugar-melon-70:active, .module-sugar-melon-70.active,
.show > .module-sugar-melon-70.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 203, 110, 0.7);
  background-image: none;
  border-color: rgba(255, 198, 97, 0.7);
}

.module-sugar-melon-70:active:focus, .module-sugar-melon-70.active:focus,
.show > .module-sugar-melon-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(202, 181, 140, 0.5);
}

.module-sugar-melon-70:disabled, .module-sugar-melon-70.disabled {
  color: #273a4d;
  background-color: rgba(255, 221, 161, 0.7);
  background-image: none;
  border-color: rgba(255, 221, 161, 0.7);
}

.module-sugar-melon-80 {
  color: #273a4d;
  background: rgba(255, 221, 161, 0.8) linear-gradient(180deg, rgba(251, 226, 180, 0.83), rgba(255, 221, 161, 0.8)) repeat-x;
  border-color: rgba(255, 221, 161, 0.8);
}

.module-sugar-melon-80 .icon-container {
  background-color: rgba(255, 207, 123, 0.8);
}

.module-sugar-melon-80 .icon-container.light {
  background-color: rgba(255, 249, 238, 0.8);
}

.module-sugar-melon-80:hover {
  color: #273a4d;
  background: rgba(255, 207, 123, 0.8) linear-gradient(180deg, rgba(251, 215, 149, 0.83), rgba(255, 207, 123, 0.8)) repeat-x;
  border-color: color-yig(rgba(255, 207, 123, 0.8));
}

.module-sugar-melon-80:focus, .module-sugar-melon-80.focus {
  color: #273a4d;
  background: rgba(255, 207, 123, 0.8) linear-gradient(180deg, rgba(251, 215, 149, 0.83), rgba(255, 207, 123, 0.8)) repeat-x;
  border-color: color-yig(rgba(255, 207, 123, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(210, 187, 143, 0.5);
}

.module-sugar-melon-80:active, .module-sugar-melon-80.active,
.show > .module-sugar-melon-80.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 203, 110, 0.8);
  background-image: none;
  border-color: rgba(255, 198, 97, 0.8);
}

.module-sugar-melon-80:active:focus, .module-sugar-melon-80.active:focus,
.show > .module-sugar-melon-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 187, 143, 0.5);
}

.module-sugar-melon-80:disabled, .module-sugar-melon-80.disabled {
  color: #273a4d;
  background-color: rgba(255, 221, 161, 0.8);
  background-image: none;
  border-color: rgba(255, 221, 161, 0.8);
}

.module-sugar-melon-90 {
  color: #273a4d;
  background: rgba(255, 221, 161, 0.9) linear-gradient(180deg, rgba(252, 225, 177, 0.915), rgba(255, 221, 161, 0.9)) repeat-x;
  border-color: rgba(255, 221, 161, 0.9);
}

.module-sugar-melon-90 .icon-container {
  background-color: rgba(255, 207, 123, 0.9);
}

.module-sugar-melon-90 .icon-container.light {
  background-color: rgba(255, 249, 238, 0.9);
}

.module-sugar-melon-90:hover {
  color: #273a4d;
  background: rgba(255, 207, 123, 0.9) linear-gradient(180deg, rgba(252, 214, 145, 0.915), rgba(255, 207, 123, 0.9)) repeat-x;
  border-color: color-yig(rgba(255, 207, 123, 0.9));
}

.module-sugar-melon-90:focus, .module-sugar-melon-90.focus {
  color: #273a4d;
  background: rgba(255, 207, 123, 0.9) linear-gradient(180deg, rgba(252, 214, 145, 0.915), rgba(255, 207, 123, 0.9)) repeat-x;
  border-color: color-yig(rgba(255, 207, 123, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(217, 192, 146, 0.5);
}

.module-sugar-melon-90:active, .module-sugar-melon-90.active,
.show > .module-sugar-melon-90.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 203, 110, 0.9);
  background-image: none;
  border-color: rgba(255, 198, 97, 0.9);
}

.module-sugar-melon-90:active:focus, .module-sugar-melon-90.active:focus,
.show > .module-sugar-melon-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 192, 146, 0.5);
}

.module-sugar-melon-90:disabled, .module-sugar-melon-90.disabled {
  color: #273a4d;
  background-color: rgba(255, 221, 161, 0.9);
  background-image: none;
  border-color: rgba(255, 221, 161, 0.9);
}

.module-chocolate {
  color: #fff;
  background: #40241c linear-gradient(180deg, #5a433d, #40241c) repeat-x;
  border-color: #40241c;
}

.module-chocolate .icon-container {
  background-color: #251510;
}

.module-chocolate .icon-container.light {
  background-color: #754233;
}

.module-chocolate:hover {
  color: #fff;
  background: #251510 linear-gradient(180deg, #433733, #251510) repeat-x;
  border-color: color-yig(#251510);
}

.module-chocolate:focus, .module-chocolate.focus {
  color: #fff;
  background: #251510 linear-gradient(180deg, #433733, #251510) repeat-x;
  border-color: color-yig(#251510);
  box-shadow: 0 0 0 0.2rem rgba(93, 69, 62, 0.5);
}

.module-chocolate:active, .module-chocolate.active,
.show > .module-chocolate.dropdown-toggle {
  color: #fff;
  background-color: #1d100c;
  background-image: none;
  border-color: #140b09;
}

.module-chocolate:active:focus, .module-chocolate.active:focus,
.show > .module-chocolate.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(93, 69, 62, 0.5);
}

.module-chocolate:disabled, .module-chocolate.disabled {
  color: #fff;
  background-color: #40241c;
  background-image: none;
  border-color: #40241c;
}

.module-chocolate-10 {
  color: #fff;
  background: rgba(64, 36, 28, 0.1) linear-gradient(180deg, rgba(198, 197, 199, 0.235), rgba(64, 36, 28, 0.1)) repeat-x;
  border-color: rgba(64, 36, 28, 0.1);
}

.module-chocolate-10 .icon-container {
  background-color: rgba(37, 21, 16, 0.1);
}

.module-chocolate-10 .icon-container.light {
  background-color: rgba(117, 66, 51, 0.1);
}

.module-chocolate-10:hover {
  color: #fff;
  background: rgba(37, 21, 16, 0.1) linear-gradient(180deg, rgba(192, 194, 196, 0.235), rgba(37, 21, 16, 0.1)) repeat-x;
  border-color: color-yig(rgba(37, 21, 16, 0.1));
}

.module-chocolate-10:focus, .module-chocolate-10.focus {
  color: #fff;
  background: rgba(37, 21, 16, 0.1) linear-gradient(180deg, rgba(192, 194, 196, 0.235), rgba(37, 21, 16, 0.1)) repeat-x;
  border-color: color-yig(rgba(37, 21, 16, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(211, 205, 203, 0.5);
}

.module-chocolate-10:active, .module-chocolate-10.active,
.show > .module-chocolate-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 16, 12, 0.1);
  background-image: none;
  border-color: rgba(20, 11, 9, 0.1);
}

.module-chocolate-10:active:focus, .module-chocolate-10.active:focus,
.show > .module-chocolate-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 205, 203, 0.5);
}

.module-chocolate-10:disabled, .module-chocolate-10.disabled {
  color: #fff;
  background-color: rgba(64, 36, 28, 0.1);
  background-image: none;
  border-color: rgba(64, 36, 28, 0.1);
}

.module-chocolate-20 {
  color: #fff;
  background: rgba(64, 36, 28, 0.2) linear-gradient(180deg, rgba(171, 164, 164, 0.32), rgba(64, 36, 28, 0.2)) repeat-x;
  border-color: rgba(64, 36, 28, 0.2);
}

.module-chocolate-20 .icon-container {
  background-color: rgba(37, 21, 16, 0.2);
}

.module-chocolate-20 .icon-container.light {
  background-color: rgba(117, 66, 51, 0.2);
}

.module-chocolate-20:hover {
  color: #fff;
  background: rgba(37, 21, 16, 0.2) linear-gradient(180deg, rgba(160, 158, 160, 0.32), rgba(37, 21, 16, 0.2)) repeat-x;
  border-color: color-yig(rgba(37, 21, 16, 0.2));
}

.module-chocolate-20:focus, .module-chocolate-20.focus {
  color: #fff;
  background: rgba(37, 21, 16, 0.2) linear-gradient(180deg, rgba(160, 158, 160, 0.32), rgba(37, 21, 16, 0.2)) repeat-x;
  border-color: color-yig(rgba(37, 21, 16, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(181, 170, 167, 0.5);
}

.module-chocolate-20:active, .module-chocolate-20.active,
.show > .module-chocolate-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 16, 12, 0.2);
  background-image: none;
  border-color: rgba(20, 11, 9, 0.2);
}

.module-chocolate-20:active:focus, .module-chocolate-20.active:focus,
.show > .module-chocolate-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(181, 170, 167, 0.5);
}

.module-chocolate-20:disabled, .module-chocolate-20.disabled {
  color: #fff;
  background-color: rgba(64, 36, 28, 0.2);
  background-image: none;
  border-color: rgba(64, 36, 28, 0.2);
}

.module-chocolate-30 {
  color: #fff;
  background: rgba(64, 36, 28, 0.3) linear-gradient(180deg, rgba(151, 141, 139, 0.405), rgba(64, 36, 28, 0.3)) repeat-x;
  border-color: rgba(64, 36, 28, 0.3);
}

.module-chocolate-30 .icon-container {
  background-color: rgba(37, 21, 16, 0.3);
}

.module-chocolate-30 .icon-container.light {
  background-color: rgba(117, 66, 51, 0.3);
}

.module-chocolate-30:hover {
  color: #fff;
  background: rgba(37, 21, 16, 0.3) linear-gradient(180deg, rgba(138, 133, 133, 0.405), rgba(37, 21, 16, 0.3)) repeat-x;
  border-color: color-yig(rgba(37, 21, 16, 0.3));
}

.module-chocolate-30:focus, .module-chocolate-30.focus {
  color: #fff;
  background: rgba(37, 21, 16, 0.3) linear-gradient(180deg, rgba(138, 133, 133, 0.405), rgba(37, 21, 16, 0.3)) repeat-x;
  border-color: color-yig(rgba(37, 21, 16, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(160, 146, 142, 0.5);
}

.module-chocolate-30:active, .module-chocolate-30.active,
.show > .module-chocolate-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 16, 12, 0.3);
  background-image: none;
  border-color: rgba(20, 11, 9, 0.3);
}

.module-chocolate-30:active:focus, .module-chocolate-30.active:focus,
.show > .module-chocolate-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(160, 146, 142, 0.5);
}

.module-chocolate-30:disabled, .module-chocolate-30.disabled {
  color: #fff;
  background-color: rgba(64, 36, 28, 0.3);
  background-image: none;
  border-color: rgba(64, 36, 28, 0.3);
}

.module-chocolate-40 {
  color: #fff;
  background: rgba(64, 36, 28, 0.4) linear-gradient(180deg, rgba(136, 122, 120, 0.49), rgba(64, 36, 28, 0.4)) repeat-x;
  border-color: rgba(64, 36, 28, 0.4);
}

.module-chocolate-40 .icon-container {
  background-color: rgba(37, 21, 16, 0.4);
}

.module-chocolate-40 .icon-container.light {
  background-color: rgba(117, 66, 51, 0.4);
}

.module-chocolate-40:hover {
  color: #fff;
  background: rgba(37, 21, 16, 0.4) linear-gradient(180deg, rgba(120, 114, 113, 0.49), rgba(37, 21, 16, 0.4)) repeat-x;
  border-color: color-yig(rgba(37, 21, 16, 0.4));
}

.module-chocolate-40:focus, .module-chocolate-40.focus {
  color: #fff;
  background: rgba(37, 21, 16, 0.4) linear-gradient(180deg, rgba(120, 114, 113, 0.49), rgba(37, 21, 16, 0.4)) repeat-x;
  border-color: color-yig(rgba(37, 21, 16, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(143, 127, 122, 0.5);
}

.module-chocolate-40:active, .module-chocolate-40.active,
.show > .module-chocolate-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 16, 12, 0.4);
  background-image: none;
  border-color: rgba(20, 11, 9, 0.4);
}

.module-chocolate-40:active:focus, .module-chocolate-40.active:focus,
.show > .module-chocolate-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(143, 127, 122, 0.5);
}

.module-chocolate-40:disabled, .module-chocolate-40.disabled {
  color: #fff;
  background-color: rgba(64, 36, 28, 0.4);
  background-image: none;
  border-color: rgba(64, 36, 28, 0.4);
}

.module-chocolate-50 {
  color: #fff;
  background: rgba(64, 36, 28, 0.5) linear-gradient(180deg, rgba(124, 108, 105, 0.575), rgba(64, 36, 28, 0.5)) repeat-x;
  border-color: rgba(64, 36, 28, 0.5);
}

.module-chocolate-50 .icon-container {
  background-color: rgba(37, 21, 16, 0.5);
}

.module-chocolate-50 .icon-container.light {
  background-color: rgba(117, 66, 51, 0.5);
}

.module-chocolate-50:hover {
  color: #fff;
  background: rgba(37, 21, 16, 0.5) linear-gradient(180deg, rgba(107, 99, 97, 0.575), rgba(37, 21, 16, 0.5)) repeat-x;
  border-color: color-yig(rgba(37, 21, 16, 0.5));
}

.module-chocolate-50:focus, .module-chocolate-50.focus {
  color: #fff;
  background: rgba(37, 21, 16, 0.5) linear-gradient(180deg, rgba(107, 99, 97, 0.575), rgba(37, 21, 16, 0.5)) repeat-x;
  border-color: color-yig(rgba(37, 21, 16, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(130, 112, 107, 0.5);
}

.module-chocolate-50:active, .module-chocolate-50.active,
.show > .module-chocolate-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 16, 12, 0.5);
  background-image: none;
  border-color: rgba(20, 11, 9, 0.5);
}

.module-chocolate-50:active:focus, .module-chocolate-50.active:focus,
.show > .module-chocolate-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 112, 107, 0.5);
}

.module-chocolate-50:disabled, .module-chocolate-50.disabled {
  color: #fff;
  background-color: rgba(64, 36, 28, 0.5);
  background-image: none;
  border-color: rgba(64, 36, 28, 0.5);
}

.module-chocolate-60 {
  color: #fff;
  background: rgba(64, 36, 28, 0.6) linear-gradient(180deg, rgba(115, 97, 93, 0.66), rgba(64, 36, 28, 0.6)) repeat-x;
  border-color: rgba(64, 36, 28, 0.6);
}

.module-chocolate-60 .icon-container {
  background-color: rgba(37, 21, 16, 0.6);
}

.module-chocolate-60 .icon-container.light {
  background-color: rgba(117, 66, 51, 0.6);
}

.module-chocolate-60:hover {
  color: #fff;
  background: rgba(37, 21, 16, 0.6) linear-gradient(180deg, rgba(96, 86, 85, 0.66), rgba(37, 21, 16, 0.6)) repeat-x;
  border-color: color-yig(rgba(37, 21, 16, 0.6));
}

.module-chocolate-60:focus, .module-chocolate-60.focus {
  color: #fff;
  background: rgba(37, 21, 16, 0.6) linear-gradient(180deg, rgba(96, 86, 85, 0.66), rgba(37, 21, 16, 0.6)) repeat-x;
  border-color: color-yig(rgba(37, 21, 16, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(120, 100, 94, 0.5);
}

.module-chocolate-60:active, .module-chocolate-60.active,
.show > .module-chocolate-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 16, 12, 0.6);
  background-image: none;
  border-color: rgba(20, 11, 9, 0.6);
}

.module-chocolate-60:active:focus, .module-chocolate-60.active:focus,
.show > .module-chocolate-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 100, 94, 0.5);
}

.module-chocolate-60:disabled, .module-chocolate-60.disabled {
  color: #fff;
  background-color: rgba(64, 36, 28, 0.6);
  background-image: none;
  border-color: rgba(64, 36, 28, 0.6);
}

.module-chocolate-70 {
  color: #fff;
  background: rgba(64, 36, 28, 0.7) linear-gradient(180deg, rgba(107, 88, 83, 0.745), rgba(64, 36, 28, 0.7)) repeat-x;
  border-color: rgba(64, 36, 28, 0.7);
}

.module-chocolate-70 .icon-container {
  background-color: rgba(37, 21, 16, 0.7);
}

.module-chocolate-70 .icon-container.light {
  background-color: rgba(117, 66, 51, 0.7);
}

.module-chocolate-70:hover {
  color: #fff;
  background: rgba(37, 21, 16, 0.7) linear-gradient(180deg, rgba(87, 76, 74, 0.745), rgba(37, 21, 16, 0.7)) repeat-x;
  border-color: color-yig(rgba(37, 21, 16, 0.7));
}

.module-chocolate-70:focus, .module-chocolate-70.focus {
  color: #fff;
  background: rgba(37, 21, 16, 0.7) linear-gradient(180deg, rgba(87, 76, 74, 0.745), rgba(37, 21, 16, 0.7)) repeat-x;
  border-color: color-yig(rgba(37, 21, 16, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(111, 90, 84, 0.5);
}

.module-chocolate-70:active, .module-chocolate-70.active,
.show > .module-chocolate-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 16, 12, 0.7);
  background-image: none;
  border-color: rgba(20, 11, 9, 0.7);
}

.module-chocolate-70:active:focus, .module-chocolate-70.active:focus,
.show > .module-chocolate-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 90, 84, 0.5);
}

.module-chocolate-70:disabled, .module-chocolate-70.disabled {
  color: #fff;
  background-color: rgba(64, 36, 28, 0.7);
  background-image: none;
  border-color: rgba(64, 36, 28, 0.7);
}

.module-chocolate-80 {
  color: #fff;
  background: rgba(64, 36, 28, 0.8) linear-gradient(180deg, rgba(100, 80, 74, 0.83), rgba(64, 36, 28, 0.8)) repeat-x;
  border-color: rgba(64, 36, 28, 0.8);
}

.module-chocolate-80 .icon-container {
  background-color: rgba(37, 21, 16, 0.8);
}

.module-chocolate-80 .icon-container.light {
  background-color: rgba(117, 66, 51, 0.8);
}

.module-chocolate-80:hover {
  color: #fff;
  background: rgba(37, 21, 16, 0.8) linear-gradient(180deg, rgba(79, 68, 65, 0.83), rgba(37, 21, 16, 0.8)) repeat-x;
  border-color: color-yig(rgba(37, 21, 16, 0.8));
}

.module-chocolate-80:focus, .module-chocolate-80.focus {
  color: #fff;
  background: rgba(37, 21, 16, 0.8) linear-gradient(180deg, rgba(79, 68, 65, 0.83), rgba(37, 21, 16, 0.8)) repeat-x;
  border-color: color-yig(rgba(37, 21, 16, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(104, 82, 76, 0.5);
}

.module-chocolate-80:active, .module-chocolate-80.active,
.show > .module-chocolate-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 16, 12, 0.8);
  background-image: none;
  border-color: rgba(20, 11, 9, 0.8);
}

.module-chocolate-80:active:focus, .module-chocolate-80.active:focus,
.show > .module-chocolate-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(104, 82, 76, 0.5);
}

.module-chocolate-80:disabled, .module-chocolate-80.disabled {
  color: #fff;
  background-color: rgba(64, 36, 28, 0.8);
  background-image: none;
  border-color: rgba(64, 36, 28, 0.8);
}

.module-chocolate-90 {
  color: #fff;
  background: rgba(64, 36, 28, 0.9) linear-gradient(180deg, rgba(95, 73, 67, 0.915), rgba(64, 36, 28, 0.9)) repeat-x;
  border-color: rgba(64, 36, 28, 0.9);
}

.module-chocolate-90 .icon-container {
  background-color: rgba(37, 21, 16, 0.9);
}

.module-chocolate-90 .icon-container.light {
  background-color: rgba(117, 66, 51, 0.9);
}

.module-chocolate-90:hover {
  color: #fff;
  background: rgba(37, 21, 16, 0.9) linear-gradient(180deg, rgba(73, 61, 58, 0.915), rgba(37, 21, 16, 0.9)) repeat-x;
  border-color: color-yig(rgba(37, 21, 16, 0.9));
}

.module-chocolate-90:focus, .module-chocolate-90.focus {
  color: #fff;
  background: rgba(37, 21, 16, 0.9) linear-gradient(180deg, rgba(73, 61, 58, 0.915), rgba(37, 21, 16, 0.9)) repeat-x;
  border-color: color-yig(rgba(37, 21, 16, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(98, 75, 68, 0.5);
}

.module-chocolate-90:active, .module-chocolate-90.active,
.show > .module-chocolate-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 16, 12, 0.9);
  background-image: none;
  border-color: rgba(20, 11, 9, 0.9);
}

.module-chocolate-90:active:focus, .module-chocolate-90.active:focus,
.show > .module-chocolate-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(98, 75, 68, 0.5);
}

.module-chocolate-90:disabled, .module-chocolate-90.disabled {
  color: #fff;
  background-color: rgba(64, 36, 28, 0.9);
  background-image: none;
  border-color: rgba(64, 36, 28, 0.9);
}

.module-blood {
  color: #fff;
  background: #6a2511 linear-gradient(180deg, #7e4434, #6a2511) repeat-x;
  border-color: #6a2511;
}

.module-blood .icon-container {
  background-color: #49190c;
}

.module-blood .icon-container.light {
  background-color: #ac3c1c;
}

.module-blood:hover {
  color: #fff;
  background: #49190c linear-gradient(180deg, #623a2f, #49190c) repeat-x;
  border-color: color-yig(#49190c);
}

.module-blood:focus, .module-blood.focus {
  color: #fff;
  background: #49190c linear-gradient(180deg, #623a2f, #49190c) repeat-x;
  border-color: color-yig(#49190c);
  box-shadow: 0 0 0 0.2rem rgba(128, 70, 53, 0.5);
}

.module-blood:active, .module-blood.active,
.show > .module-blood.dropdown-toggle {
  color: #fff;
  background-color: #3e160a;
  background-image: none;
  border-color: #331208;
}

.module-blood:active:focus, .module-blood.active:focus,
.show > .module-blood.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 70, 53, 0.5);
}

.module-blood:disabled, .module-blood.disabled {
  color: #fff;
  background-color: #6a2511;
  background-image: none;
  border-color: #6a2511;
}

.module-blood-10 {
  color: #fff;
  background: rgba(106, 37, 17, 0.1) linear-gradient(180deg, rgba(208, 197, 196, 0.235), rgba(106, 37, 17, 0.1)) repeat-x;
  border-color: rgba(106, 37, 17, 0.1);
}

.module-blood-10 .icon-container {
  background-color: rgba(73, 25, 12, 0.1);
}

.module-blood-10 .icon-container.light {
  background-color: rgba(172, 60, 28, 0.1);
}

.module-blood-10:hover {
  color: #fff;
  background: rgba(73, 25, 12, 0.1) linear-gradient(180deg, rgba(200, 195, 195, 0.235), rgba(73, 25, 12, 0.1)) repeat-x;
  border-color: color-yig(rgba(73, 25, 12, 0.1));
}

.module-blood-10:focus, .module-blood-10.focus {
  color: #fff;
  background: rgba(73, 25, 12, 0.1) linear-gradient(180deg, rgba(200, 195, 195, 0.235), rgba(73, 25, 12, 0.1)) repeat-x;
  border-color: color-yig(rgba(73, 25, 12, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(221, 205, 200, 0.5);
}

.module-blood-10:active, .module-blood-10.active,
.show > .module-blood-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 22, 10, 0.1);
  background-image: none;
  border-color: rgba(51, 18, 8, 0.1);
}

.module-blood-10:active:focus, .module-blood-10.active:focus,
.show > .module-blood-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 205, 200, 0.5);
}

.module-blood-10:disabled, .module-blood-10.disabled {
  color: #fff;
  background-color: rgba(106, 37, 17, 0.1);
  background-image: none;
  border-color: rgba(106, 37, 17, 0.1);
}

.module-blood-20 {
  color: #fff;
  background: rgba(106, 37, 17, 0.2) linear-gradient(180deg, rgba(187, 165, 160, 0.32), rgba(106, 37, 17, 0.2)) repeat-x;
  border-color: rgba(106, 37, 17, 0.2);
}

.module-blood-20 .icon-container {
  background-color: rgba(73, 25, 12, 0.2);
}

.module-blood-20 .icon-container.light {
  background-color: rgba(172, 60, 28, 0.2);
}

.module-blood-20:hover {
  color: #fff;
  background: rgba(73, 25, 12, 0.2) linear-gradient(180deg, rgba(174, 160, 158, 0.32), rgba(73, 25, 12, 0.2)) repeat-x;
  border-color: color-yig(rgba(73, 25, 12, 0.2));
}

.module-blood-20:focus, .module-blood-20.focus {
  color: #fff;
  background: rgba(73, 25, 12, 0.2) linear-gradient(180deg, rgba(174, 160, 158, 0.32), rgba(73, 25, 12, 0.2)) repeat-x;
  border-color: color-yig(rgba(73, 25, 12, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(197, 171, 163, 0.5);
}

.module-blood-20:active, .module-blood-20.active,
.show > .module-blood-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 22, 10, 0.2);
  background-image: none;
  border-color: rgba(51, 18, 8, 0.2);
}

.module-blood-20:active:focus, .module-blood-20.active:focus,
.show > .module-blood-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(197, 171, 163, 0.5);
}

.module-blood-20:disabled, .module-blood-20.disabled {
  color: #fff;
  background-color: rgba(106, 37, 17, 0.2);
  background-image: none;
  border-color: rgba(106, 37, 17, 0.2);
}

.module-blood-30 {
  color: #fff;
  background: rgba(106, 37, 17, 0.3) linear-gradient(180deg, rgba(172, 141, 134, 0.405), rgba(106, 37, 17, 0.3)) repeat-x;
  border-color: rgba(106, 37, 17, 0.3);
}

.module-blood-30 .icon-container {
  background-color: rgba(73, 25, 12, 0.3);
}

.module-blood-30 .icon-container.light {
  background-color: rgba(172, 60, 28, 0.3);
}

.module-blood-30:hover {
  color: #fff;
  background: rgba(73, 25, 12, 0.3) linear-gradient(180deg, rgba(156, 135, 131, 0.405), rgba(73, 25, 12, 0.3)) repeat-x;
  border-color: color-yig(rgba(73, 25, 12, 0.3));
}

.module-blood-30:focus, .module-blood-30.focus {
  color: #fff;
  background: rgba(73, 25, 12, 0.3) linear-gradient(180deg, rgba(156, 135, 131, 0.405), rgba(73, 25, 12, 0.3)) repeat-x;
  border-color: color-yig(rgba(73, 25, 12, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(181, 146, 136, 0.5);
}

.module-blood-30:active, .module-blood-30.active,
.show > .module-blood-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 22, 10, 0.3);
  background-image: none;
  border-color: rgba(51, 18, 8, 0.3);
}

.module-blood-30:active:focus, .module-blood-30.active:focus,
.show > .module-blood-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(181, 146, 136, 0.5);
}

.module-blood-30:disabled, .module-blood-30.disabled {
  color: #fff;
  background-color: rgba(106, 37, 17, 0.3);
  background-image: none;
  border-color: rgba(106, 37, 17, 0.3);
}

.module-blood-40 {
  color: #fff;
  background: rgba(106, 37, 17, 0.4) linear-gradient(180deg, rgba(161, 123, 113, 0.49), rgba(106, 37, 17, 0.4)) repeat-x;
  border-color: rgba(106, 37, 17, 0.4);
}

.module-blood-40 .icon-container {
  background-color: rgba(73, 25, 12, 0.4);
}

.module-blood-40 .icon-container.light {
  background-color: rgba(172, 60, 28, 0.4);
}

.module-blood-40:hover {
  color: #fff;
  background: rgba(73, 25, 12, 0.4) linear-gradient(180deg, rgba(141, 116, 110, 0.49), rgba(73, 25, 12, 0.4)) repeat-x;
  border-color: color-yig(rgba(73, 25, 12, 0.4));
}

.module-blood-40:focus, .module-blood-40.focus {
  color: #fff;
  background: rgba(73, 25, 12, 0.4) linear-gradient(180deg, rgba(141, 116, 110, 0.49), rgba(73, 25, 12, 0.4)) repeat-x;
  border-color: color-yig(rgba(73, 25, 12, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(168, 127, 115, 0.5);
}

.module-blood-40:active, .module-blood-40.active,
.show > .module-blood-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 22, 10, 0.4);
  background-image: none;
  border-color: rgba(51, 18, 8, 0.4);
}

.module-blood-40:active:focus, .module-blood-40.active:focus,
.show > .module-blood-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(168, 127, 115, 0.5);
}

.module-blood-40:disabled, .module-blood-40.disabled {
  color: #fff;
  background-color: rgba(106, 37, 17, 0.4);
  background-image: none;
  border-color: rgba(106, 37, 17, 0.4);
}

.module-blood-50 {
  color: #fff;
  background: rgba(106, 37, 17, 0.5) linear-gradient(180deg, rgba(152, 109, 98, 0.575), rgba(106, 37, 17, 0.5)) repeat-x;
  border-color: rgba(106, 37, 17, 0.5);
}

.module-blood-50 .icon-container {
  background-color: rgba(73, 25, 12, 0.5);
}

.module-blood-50 .icon-container.light {
  background-color: rgba(172, 60, 28, 0.5);
}

.module-blood-50:hover {
  color: #fff;
  background: rgba(73, 25, 12, 0.5) linear-gradient(180deg, rgba(130, 101, 94, 0.575), rgba(73, 25, 12, 0.5)) repeat-x;
  border-color: color-yig(rgba(73, 25, 12, 0.5));
}

.module-blood-50:focus, .module-blood-50.focus {
  color: #fff;
  background: rgba(73, 25, 12, 0.5) linear-gradient(180deg, rgba(130, 101, 94, 0.575), rgba(73, 25, 12, 0.5)) repeat-x;
  border-color: color-yig(rgba(73, 25, 12, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(158, 112, 99, 0.5);
}

.module-blood-50:active, .module-blood-50.active,
.show > .module-blood-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 22, 10, 0.5);
  background-image: none;
  border-color: rgba(51, 18, 8, 0.5);
}

.module-blood-50:active:focus, .module-blood-50.active:focus,
.show > .module-blood-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(158, 112, 99, 0.5);
}

.module-blood-50:disabled, .module-blood-50.disabled {
  color: #fff;
  background-color: rgba(106, 37, 17, 0.5);
  background-image: none;
  border-color: rgba(106, 37, 17, 0.5);
}

.module-blood-60 {
  color: #fff;
  background: rgba(106, 37, 17, 0.6) linear-gradient(180deg, rgba(145, 98, 85, 0.66), rgba(106, 37, 17, 0.6)) repeat-x;
  border-color: rgba(106, 37, 17, 0.6);
}

.module-blood-60 .icon-container {
  background-color: rgba(73, 25, 12, 0.6);
}

.module-blood-60 .icon-container.light {
  background-color: rgba(172, 60, 28, 0.6);
}

.module-blood-60:hover {
  color: #fff;
  background: rgba(73, 25, 12, 0.6) linear-gradient(180deg, rgba(121, 90, 81, 0.66), rgba(73, 25, 12, 0.6)) repeat-x;
  border-color: color-yig(rgba(73, 25, 12, 0.6));
}

.module-blood-60:focus, .module-blood-60.focus {
  color: #fff;
  background: rgba(73, 25, 12, 0.6) linear-gradient(180deg, rgba(121, 90, 81, 0.66), rgba(73, 25, 12, 0.6)) repeat-x;
  border-color: color-yig(rgba(73, 25, 12, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(149, 101, 86, 0.5);
}

.module-blood-60:active, .module-blood-60.active,
.show > .module-blood-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 22, 10, 0.6);
  background-image: none;
  border-color: rgba(51, 18, 8, 0.6);
}

.module-blood-60:active:focus, .module-blood-60.active:focus,
.show > .module-blood-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 101, 86, 0.5);
}

.module-blood-60:disabled, .module-blood-60.disabled {
  color: #fff;
  background-color: rgba(106, 37, 17, 0.6);
  background-image: none;
  border-color: rgba(106, 37, 17, 0.6);
}

.module-blood-70 {
  color: #fff;
  background: rgba(106, 37, 17, 0.7) linear-gradient(180deg, rgba(139, 88, 75, 0.745), rgba(106, 37, 17, 0.7)) repeat-x;
  border-color: rgba(106, 37, 17, 0.7);
}

.module-blood-70 .icon-container {
  background-color: rgba(73, 25, 12, 0.7);
}

.module-blood-70 .icon-container.light {
  background-color: rgba(172, 60, 28, 0.7);
}

.module-blood-70:hover {
  color: #fff;
  background: rgba(73, 25, 12, 0.7) linear-gradient(180deg, rgba(114, 80, 71, 0.745), rgba(73, 25, 12, 0.7)) repeat-x;
  border-color: color-yig(rgba(73, 25, 12, 0.7));
}

.module-blood-70:focus, .module-blood-70.focus {
  color: #fff;
  background: rgba(73, 25, 12, 0.7) linear-gradient(180deg, rgba(114, 80, 71, 0.745), rgba(73, 25, 12, 0.7)) repeat-x;
  border-color: color-yig(rgba(73, 25, 12, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(143, 91, 76, 0.5);
}

.module-blood-70:active, .module-blood-70.active,
.show > .module-blood-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 22, 10, 0.7);
  background-image: none;
  border-color: rgba(51, 18, 8, 0.7);
}

.module-blood-70:active:focus, .module-blood-70.active:focus,
.show > .module-blood-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(143, 91, 76, 0.5);
}

.module-blood-70:disabled, .module-blood-70.disabled {
  color: #fff;
  background-color: rgba(106, 37, 17, 0.7);
  background-image: none;
  border-color: rgba(106, 37, 17, 0.7);
}

.module-blood-80 {
  color: #fff;
  background: rgba(106, 37, 17, 0.8) linear-gradient(180deg, rgba(134, 81, 66, 0.83), rgba(106, 37, 17, 0.8)) repeat-x;
  border-color: rgba(106, 37, 17, 0.8);
}

.module-blood-80 .icon-container {
  background-color: rgba(73, 25, 12, 0.8);
}

.module-blood-80 .icon-container.light {
  background-color: rgba(172, 60, 28, 0.8);
}

.module-blood-80:hover {
  color: #fff;
  background: rgba(73, 25, 12, 0.8) linear-gradient(180deg, rgba(108, 71, 62, 0.83), rgba(73, 25, 12, 0.8)) repeat-x;
  border-color: color-yig(rgba(73, 25, 12, 0.8));
}

.module-blood-80:focus, .module-blood-80.focus {
  color: #fff;
  background: rgba(73, 25, 12, 0.8) linear-gradient(180deg, rgba(108, 71, 62, 0.83), rgba(73, 25, 12, 0.8)) repeat-x;
  border-color: color-yig(rgba(73, 25, 12, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(137, 83, 67, 0.5);
}

.module-blood-80:active, .module-blood-80.active,
.show > .module-blood-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 22, 10, 0.8);
  background-image: none;
  border-color: rgba(51, 18, 8, 0.8);
}

.module-blood-80:active:focus, .module-blood-80.active:focus,
.show > .module-blood-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(137, 83, 67, 0.5);
}

.module-blood-80:disabled, .module-blood-80.disabled {
  color: #fff;
  background-color: rgba(106, 37, 17, 0.8);
  background-image: none;
  border-color: rgba(106, 37, 17, 0.8);
}

.module-blood-90 {
  color: #fff;
  background: rgba(106, 37, 17, 0.9) linear-gradient(180deg, rgba(129, 74, 58, 0.915), rgba(106, 37, 17, 0.9)) repeat-x;
  border-color: rgba(106, 37, 17, 0.9);
}

.module-blood-90 .icon-container {
  background-color: rgba(73, 25, 12, 0.9);
}

.module-blood-90 .icon-container.light {
  background-color: rgba(172, 60, 28, 0.9);
}

.module-blood-90:hover {
  color: #fff;
  background: rgba(73, 25, 12, 0.9) linear-gradient(180deg, rgba(102, 64, 54, 0.915), rgba(73, 25, 12, 0.9)) repeat-x;
  border-color: color-yig(rgba(73, 25, 12, 0.9));
}

.module-blood-90:focus, .module-blood-90.focus {
  color: #fff;
  background: rgba(73, 25, 12, 0.9) linear-gradient(180deg, rgba(102, 64, 54, 0.915), rgba(73, 25, 12, 0.9)) repeat-x;
  border-color: color-yig(rgba(73, 25, 12, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(132, 76, 59, 0.5);
}

.module-blood-90:active, .module-blood-90.active,
.show > .module-blood-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 22, 10, 0.9);
  background-image: none;
  border-color: rgba(51, 18, 8, 0.9);
}

.module-blood-90:active:focus, .module-blood-90.active:focus,
.show > .module-blood-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(132, 76, 59, 0.5);
}

.module-blood-90:disabled, .module-blood-90.disabled {
  color: #fff;
  background-color: rgba(106, 37, 17, 0.9);
  background-image: none;
  border-color: rgba(106, 37, 17, 0.9);
}

.module-brick {
  color: #fff;
  background: #9b2d0e linear-gradient(180deg, #a74b31, #9b2d0e) repeat-x;
  border-color: #9b2d0e;
}

.module-brick .icon-container {
  background-color: #78230b;
}

.module-brick .icon-container.light {
  background-color: #e14114;
}

.module-brick:hover {
  color: #fff;
  background: #78230b linear-gradient(180deg, #8a422f, #78230b) repeat-x;
  border-color: color-yig(#78230b);
}

.module-brick:focus, .module-brick.focus {
  color: #fff;
  background: #78230b linear-gradient(180deg, #8a422f, #78230b) repeat-x;
  border-color: color-yig(#78230b);
  box-shadow: 0 0 0 0.2rem rgba(170, 77, 50, 0.5);
}

.module-brick:active, .module-brick.active,
.show > .module-brick.dropdown-toggle {
  color: #fff;
  background-color: #6c1f0a;
  background-image: none;
  border-color: #611c09;
}

.module-brick:active:focus, .module-brick.active:focus,
.show > .module-brick.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(170, 77, 50, 0.5);
}

.module-brick:disabled, .module-brick.disabled {
  color: #fff;
  background-color: #9b2d0e;
  background-image: none;
  border-color: #9b2d0e;
}

.module-brick-10 {
  color: #fff;
  background: rgba(155, 45, 14, 0.1) linear-gradient(180deg, rgba(219, 199, 196, 0.235), rgba(155, 45, 14, 0.1)) repeat-x;
  border-color: rgba(155, 45, 14, 0.1);
}

.module-brick-10 .icon-container {
  background-color: rgba(120, 35, 11, 0.1);
}

.module-brick-10 .icon-container.light {
  background-color: rgba(225, 65, 20, 0.1);
}

.module-brick-10:hover {
  color: #fff;
  background: rgba(120, 35, 11, 0.1) linear-gradient(180deg, rgba(211, 197, 195, 0.235), rgba(120, 35, 11, 0.1)) repeat-x;
  border-color: color-yig(rgba(120, 35, 11, 0.1));
}

.module-brick-10:focus, .module-brick-10.focus {
  color: #fff;
  background: rgba(120, 35, 11, 0.1) linear-gradient(180deg, rgba(211, 197, 195, 0.235), rgba(120, 35, 11, 0.1)) repeat-x;
  border-color: color-yig(rgba(120, 35, 11, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(232, 207, 200, 0.5);
}

.module-brick-10:active, .module-brick-10.active,
.show > .module-brick-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 31, 10, 0.1);
  background-image: none;
  border-color: rgba(97, 28, 9, 0.1);
}

.module-brick-10:active:focus, .module-brick-10.active:focus,
.show > .module-brick-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 207, 200, 0.5);
}

.module-brick-10:disabled, .module-brick-10.disabled {
  color: #fff;
  background-color: rgba(155, 45, 14, 0.1);
  background-image: none;
  border-color: rgba(155, 45, 14, 0.1);
}

.module-brick-20 {
  color: #fff;
  background: rgba(155, 45, 14, 0.2) linear-gradient(180deg, rgba(206, 168, 159, 0.32), rgba(155, 45, 14, 0.2)) repeat-x;
  border-color: rgba(155, 45, 14, 0.2);
}

.module-brick-20 .icon-container {
  background-color: rgba(120, 35, 11, 0.2);
}

.module-brick-20 .icon-container.light {
  background-color: rgba(225, 65, 20, 0.2);
}

.module-brick-20:hover {
  color: #fff;
  background: rgba(120, 35, 11, 0.2) linear-gradient(180deg, rgba(192, 164, 158, 0.32), rgba(120, 35, 11, 0.2)) repeat-x;
  border-color: color-yig(rgba(120, 35, 11, 0.2));
}

.module-brick-20:focus, .module-brick-20.focus {
  color: #fff;
  background: rgba(120, 35, 11, 0.2) linear-gradient(180deg, rgba(192, 164, 158, 0.32), rgba(120, 35, 11, 0.2)) repeat-x;
  border-color: color-yig(rgba(120, 35, 11, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(216, 174, 162, 0.5);
}

.module-brick-20:active, .module-brick-20.active,
.show > .module-brick-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 31, 10, 0.2);
  background-image: none;
  border-color: rgba(97, 28, 9, 0.2);
}

.module-brick-20:active:focus, .module-brick-20.active:focus,
.show > .module-brick-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 174, 162, 0.5);
}

.module-brick-20:disabled, .module-brick-20.disabled {
  color: #fff;
  background-color: rgba(155, 45, 14, 0.2);
  background-image: none;
  border-color: rgba(155, 45, 14, 0.2);
}

.module-brick-30 {
  color: #fff;
  background: rgba(155, 45, 14, 0.3) linear-gradient(180deg, rgba(197, 145, 132, 0.405), rgba(155, 45, 14, 0.3)) repeat-x;
  border-color: rgba(155, 45, 14, 0.3);
}

.module-brick-30 .icon-container {
  background-color: rgba(120, 35, 11, 0.3);
}

.module-brick-30 .icon-container.light {
  background-color: rgba(225, 65, 20, 0.3);
}

.module-brick-30:hover {
  color: #fff;
  background: rgba(120, 35, 11, 0.3) linear-gradient(180deg, rgba(179, 140, 130, 0.405), rgba(120, 35, 11, 0.3)) repeat-x;
  border-color: color-yig(rgba(120, 35, 11, 0.3));
}

.module-brick-30:focus, .module-brick-30.focus {
  color: #fff;
  background: rgba(120, 35, 11, 0.3) linear-gradient(180deg, rgba(179, 140, 130, 0.405), rgba(120, 35, 11, 0.3)) repeat-x;
  border-color: color-yig(rgba(120, 35, 11, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(205, 150, 135, 0.5);
}

.module-brick-30:active, .module-brick-30.active,
.show > .module-brick-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 31, 10, 0.3);
  background-image: none;
  border-color: rgba(97, 28, 9, 0.3);
}

.module-brick-30:active:focus, .module-brick-30.active:focus,
.show > .module-brick-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(205, 150, 135, 0.5);
}

.module-brick-30:disabled, .module-brick-30.disabled {
  color: #fff;
  background-color: rgba(155, 45, 14, 0.3);
  background-image: none;
  border-color: rgba(155, 45, 14, 0.3);
}

.module-brick-40 {
  color: #fff;
  background: rgba(155, 45, 14, 0.4) linear-gradient(180deg, rgba(189, 128, 112, 0.49), rgba(155, 45, 14, 0.4)) repeat-x;
  border-color: rgba(155, 45, 14, 0.4);
}

.module-brick-40 .icon-container {
  background-color: rgba(120, 35, 11, 0.4);
}

.module-brick-40 .icon-container.light {
  background-color: rgba(225, 65, 20, 0.4);
}

.module-brick-40:hover {
  color: #fff;
  background: rgba(120, 35, 11, 0.4) linear-gradient(180deg, rgba(169, 122, 110, 0.49), rgba(120, 35, 11, 0.4)) repeat-x;
  border-color: color-yig(rgba(120, 35, 11, 0.4));
}

.module-brick-40:focus, .module-brick-40.focus {
  color: #fff;
  background: rgba(120, 35, 11, 0.4) linear-gradient(180deg, rgba(169, 122, 110, 0.49), rgba(120, 35, 11, 0.4)) repeat-x;
  border-color: color-yig(rgba(120, 35, 11, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(196, 132, 114, 0.5);
}

.module-brick-40:active, .module-brick-40.active,
.show > .module-brick-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 31, 10, 0.4);
  background-image: none;
  border-color: rgba(97, 28, 9, 0.4);
}

.module-brick-40:active:focus, .module-brick-40.active:focus,
.show > .module-brick-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 132, 114, 0.5);
}

.module-brick-40:disabled, .module-brick-40.disabled {
  color: #fff;
  background-color: rgba(155, 45, 14, 0.4);
  background-image: none;
  border-color: rgba(155, 45, 14, 0.4);
}

.module-brick-50 {
  color: #fff;
  background: rgba(155, 45, 14, 0.5) linear-gradient(180deg, rgba(184, 114, 96, 0.575), rgba(155, 45, 14, 0.5)) repeat-x;
  border-color: rgba(155, 45, 14, 0.5);
}

.module-brick-50 .icon-container {
  background-color: rgba(120, 35, 11, 0.5);
}

.module-brick-50 .icon-container.light {
  background-color: rgba(225, 65, 20, 0.5);
}

.module-brick-50:hover {
  color: #fff;
  background: rgba(120, 35, 11, 0.5) linear-gradient(180deg, rgba(161, 108, 94, 0.575), rgba(120, 35, 11, 0.5)) repeat-x;
  border-color: color-yig(rgba(120, 35, 11, 0.5));
}

.module-brick-50:focus, .module-brick-50.focus {
  color: #fff;
  background: rgba(120, 35, 11, 0.5) linear-gradient(180deg, rgba(161, 108, 94, 0.575), rgba(120, 35, 11, 0.5)) repeat-x;
  border-color: color-yig(rgba(120, 35, 11, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(190, 118, 97, 0.5);
}

.module-brick-50:active, .module-brick-50.active,
.show > .module-brick-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 31, 10, 0.5);
  background-image: none;
  border-color: rgba(97, 28, 9, 0.5);
}

.module-brick-50:active:focus, .module-brick-50.active:focus,
.show > .module-brick-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(190, 118, 97, 0.5);
}

.module-brick-50:disabled, .module-brick-50.disabled {
  color: #fff;
  background-color: rgba(155, 45, 14, 0.5);
  background-image: none;
  border-color: rgba(155, 45, 14, 0.5);
}

.module-brick-60 {
  color: #fff;
  background: rgba(155, 45, 14, 0.6) linear-gradient(180deg, rgba(179, 103, 83, 0.66), rgba(155, 45, 14, 0.6)) repeat-x;
  border-color: rgba(155, 45, 14, 0.6);
}

.module-brick-60 .icon-container {
  background-color: rgba(120, 35, 11, 0.6);
}

.module-brick-60 .icon-container.light {
  background-color: rgba(225, 65, 20, 0.6);
}

.module-brick-60:hover {
  color: #fff;
  background: rgba(120, 35, 11, 0.6) linear-gradient(180deg, rgba(154, 96, 81, 0.66), rgba(120, 35, 11, 0.6)) repeat-x;
  border-color: color-yig(rgba(120, 35, 11, 0.6));
}

.module-brick-60:focus, .module-brick-60.focus {
  color: #fff;
  background: rgba(120, 35, 11, 0.6) linear-gradient(180deg, rgba(154, 96, 81, 0.66), rgba(120, 35, 11, 0.6)) repeat-x;
  border-color: color-yig(rgba(120, 35, 11, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(184, 106, 84, 0.5);
}

.module-brick-60:active, .module-brick-60.active,
.show > .module-brick-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 31, 10, 0.6);
  background-image: none;
  border-color: rgba(97, 28, 9, 0.6);
}

.module-brick-60:active:focus, .module-brick-60.active:focus,
.show > .module-brick-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 106, 84, 0.5);
}

.module-brick-60:disabled, .module-brick-60.disabled {
  color: #fff;
  background-color: rgba(155, 45, 14, 0.6);
  background-image: none;
  border-color: rgba(155, 45, 14, 0.6);
}

.module-brick-70 {
  color: #fff;
  background: rgba(155, 45, 14, 0.7) linear-gradient(180deg, rgba(175, 94, 72, 0.745), rgba(155, 45, 14, 0.7)) repeat-x;
  border-color: rgba(155, 45, 14, 0.7);
}

.module-brick-70 .icon-container {
  background-color: rgba(120, 35, 11, 0.7);
}

.module-brick-70 .icon-container.light {
  background-color: rgba(225, 65, 20, 0.7);
}

.module-brick-70:hover {
  color: #fff;
  background: rgba(120, 35, 11, 0.7) linear-gradient(180deg, rgba(149, 87, 70, 0.745), rgba(120, 35, 11, 0.7)) repeat-x;
  border-color: color-yig(rgba(120, 35, 11, 0.7));
}

.module-brick-70:focus, .module-brick-70.focus {
  color: #fff;
  background: rgba(120, 35, 11, 0.7) linear-gradient(180deg, rgba(149, 87, 70, 0.745), rgba(120, 35, 11, 0.7)) repeat-x;
  border-color: color-yig(rgba(120, 35, 11, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(180, 97, 73, 0.5);
}

.module-brick-70:active, .module-brick-70.active,
.show > .module-brick-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 31, 10, 0.7);
  background-image: none;
  border-color: rgba(97, 28, 9, 0.7);
}

.module-brick-70:active:focus, .module-brick-70.active:focus,
.show > .module-brick-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(180, 97, 73, 0.5);
}

.module-brick-70:disabled, .module-brick-70.disabled {
  color: #fff;
  background-color: rgba(155, 45, 14, 0.7);
  background-image: none;
  border-color: rgba(155, 45, 14, 0.7);
}

.module-brick-80 {
  color: #fff;
  background: rgba(155, 45, 14, 0.8) linear-gradient(180deg, rgba(172, 87, 63, 0.83), rgba(155, 45, 14, 0.8)) repeat-x;
  border-color: rgba(155, 45, 14, 0.8);
}

.module-brick-80 .icon-container {
  background-color: rgba(120, 35, 11, 0.8);
}

.module-brick-80 .icon-container.light {
  background-color: rgba(225, 65, 20, 0.8);
}

.module-brick-80:hover {
  color: #fff;
  background: rgba(120, 35, 11, 0.8) linear-gradient(180deg, rgba(145, 79, 61, 0.83), rgba(120, 35, 11, 0.8)) repeat-x;
  border-color: color-yig(rgba(120, 35, 11, 0.8));
}

.module-brick-80:focus, .module-brick-80.focus {
  color: #fff;
  background: rgba(120, 35, 11, 0.8) linear-gradient(180deg, rgba(145, 79, 61, 0.83), rgba(120, 35, 11, 0.8)) repeat-x;
  border-color: color-yig(rgba(120, 35, 11, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(176, 89, 64, 0.5);
}

.module-brick-80:active, .module-brick-80.active,
.show > .module-brick-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 31, 10, 0.8);
  background-image: none;
  border-color: rgba(97, 28, 9, 0.8);
}

.module-brick-80:active:focus, .module-brick-80.active:focus,
.show > .module-brick-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(176, 89, 64, 0.5);
}

.module-brick-80:disabled, .module-brick-80.disabled {
  color: #fff;
  background-color: rgba(155, 45, 14, 0.8);
  background-image: none;
  border-color: rgba(155, 45, 14, 0.8);
}

.module-brick-90 {
  color: #fff;
  background: rgba(155, 45, 14, 0.9) linear-gradient(180deg, rgba(170, 80, 56, 0.915), rgba(155, 45, 14, 0.9)) repeat-x;
  border-color: rgba(155, 45, 14, 0.9);
}

.module-brick-90 .icon-container {
  background-color: rgba(120, 35, 11, 0.9);
}

.module-brick-90 .icon-container.light {
  background-color: rgba(225, 65, 20, 0.9);
}

.module-brick-90:hover {
  color: #fff;
  background: rgba(120, 35, 11, 0.9) linear-gradient(180deg, rgba(141, 72, 53, 0.915), rgba(120, 35, 11, 0.9)) repeat-x;
  border-color: color-yig(rgba(120, 35, 11, 0.9));
}

.module-brick-90:focus, .module-brick-90.focus {
  color: #fff;
  background: rgba(120, 35, 11, 0.9) linear-gradient(180deg, rgba(141, 72, 53, 0.915), rgba(120, 35, 11, 0.9)) repeat-x;
  border-color: color-yig(rgba(120, 35, 11, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(173, 82, 57, 0.5);
}

.module-brick-90:active, .module-brick-90.active,
.show > .module-brick-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 31, 10, 0.9);
  background-image: none;
  border-color: rgba(97, 28, 9, 0.9);
}

.module-brick-90:active:focus, .module-brick-90.active:focus,
.show > .module-brick-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(173, 82, 57, 0.5);
}

.module-brick-90:disabled, .module-brick-90.disabled {
  color: #fff;
  background-color: rgba(155, 45, 14, 0.9);
  background-image: none;
  border-color: rgba(155, 45, 14, 0.9);
}

.module-chili {
  color: #fff;
  background: #e83200 linear-gradient(180deg, #e94f26, #e83200) repeat-x;
  border-color: #e83200;
}

.module-chili .icon-container {
  background-color: #c22a00;
}

.module-chili .icon-container.light {
  background-color: #ff6136;
}

.module-chili:hover {
  color: #fff;
  background: #c22a00 linear-gradient(180deg, #c84826, #c22a00) repeat-x;
  border-color: color-yig(#c22a00);
}

.module-chili:focus, .module-chili.focus {
  color: #fff;
  background: #c22a00 linear-gradient(180deg, #c84826, #c22a00) repeat-x;
  border-color: color-yig(#c22a00);
  box-shadow: 0 0 0 0.2rem rgba(235, 81, 38, 0.5);
}

.module-chili:active, .module-chili.active,
.show > .module-chili.dropdown-toggle {
  color: #fff;
  background-color: #b52700;
  background-image: none;
  border-color: #a82400;
}

.module-chili:active:focus, .module-chili.active:focus,
.show > .module-chili.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 81, 38, 0.5);
}

.module-chili:disabled, .module-chili.disabled {
  color: #fff;
  background-color: #e83200;
  background-image: none;
  border-color: #e83200;
}

.module-chili-10 {
  color: #fff;
  background: rgba(232, 50, 0, 0.1) linear-gradient(180deg, rgba(237, 200, 193, 0.235), rgba(232, 50, 0, 0.1)) repeat-x;
  border-color: rgba(232, 50, 0, 0.1);
}

.module-chili-10 .icon-container {
  background-color: rgba(194, 42, 0, 0.1);
}

.module-chili-10 .icon-container.light {
  background-color: rgba(255, 97, 54, 0.1);
}

.module-chili-10:hover {
  color: #fff;
  background: rgba(194, 42, 0, 0.1) linear-gradient(180deg, rgba(228, 198, 193, 0.235), rgba(194, 42, 0, 0.1)) repeat-x;
  border-color: color-yig(rgba(194, 42, 0, 0.1));
}

.module-chili-10:focus, .module-chili-10.focus {
  color: #fff;
  background: rgba(194, 42, 0, 0.1) linear-gradient(180deg, rgba(228, 198, 193, 0.235), rgba(194, 42, 0, 0.1)) repeat-x;
  border-color: color-yig(rgba(194, 42, 0, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(250, 208, 196, 0.5);
}

.module-chili-10:active, .module-chili-10.active,
.show > .module-chili-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(181, 39, 0, 0.1);
  background-image: none;
  border-color: rgba(168, 36, 0, 0.1);
}

.module-chili-10:active:focus, .module-chili-10.active:focus,
.show > .module-chili-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 208, 196, 0.5);
}

.module-chili-10:disabled, .module-chili-10.disabled {
  color: #fff;
  background-color: rgba(232, 50, 0, 0.1);
  background-image: none;
  border-color: rgba(232, 50, 0, 0.1);
}

.module-chili-20 {
  color: #fff;
  background: rgba(232, 50, 0, 0.2) linear-gradient(180deg, rgba(236, 170, 153, 0.32), rgba(232, 50, 0, 0.2)) repeat-x;
  border-color: rgba(232, 50, 0, 0.2);
}

.module-chili-20 .icon-container {
  background-color: rgba(194, 42, 0, 0.2);
}

.module-chili-20 .icon-container.light {
  background-color: rgba(255, 97, 54, 0.2);
}

.module-chili-20:hover {
  color: #fff;
  background: rgba(194, 42, 0, 0.2) linear-gradient(180deg, rgba(221, 166, 153, 0.32), rgba(194, 42, 0, 0.2)) repeat-x;
  border-color: color-yig(rgba(194, 42, 0, 0.2));
}

.module-chili-20:focus, .module-chili-20.focus {
  color: #fff;
  background: rgba(194, 42, 0, 0.2) linear-gradient(180deg, rgba(221, 166, 153, 0.32), rgba(194, 42, 0, 0.2)) repeat-x;
  border-color: color-yig(rgba(194, 42, 0, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(246, 176, 156, 0.5);
}

.module-chili-20:active, .module-chili-20.active,
.show > .module-chili-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(181, 39, 0, 0.2);
  background-image: none;
  border-color: rgba(168, 36, 0, 0.2);
}

.module-chili-20:active:focus, .module-chili-20.active:focus,
.show > .module-chili-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(246, 176, 156, 0.5);
}

.module-chili-20:disabled, .module-chili-20.disabled {
  color: #fff;
  background-color: rgba(232, 50, 0, 0.2);
  background-image: none;
  border-color: rgba(232, 50, 0, 0.2);
}

.module-chili-30 {
  color: #fff;
  background: rgba(232, 50, 0, 0.3) linear-gradient(180deg, rgba(235, 148, 125, 0.405), rgba(232, 50, 0, 0.3)) repeat-x;
  border-color: rgba(232, 50, 0, 0.3);
}

.module-chili-30 .icon-container {
  background-color: rgba(194, 42, 0, 0.3);
}

.module-chili-30 .icon-container.light {
  background-color: rgba(255, 97, 54, 0.3);
}

.module-chili-30:hover {
  color: #fff;
  background: rgba(194, 42, 0, 0.3) linear-gradient(180deg, rgba(216, 143, 125, 0.405), rgba(194, 42, 0, 0.3)) repeat-x;
  border-color: color-yig(rgba(194, 42, 0, 0.3));
}

.module-chili-30:focus, .module-chili-30.focus {
  color: #fff;
  background: rgba(194, 42, 0, 0.3) linear-gradient(180deg, rgba(216, 143, 125, 0.405), rgba(194, 42, 0, 0.3)) repeat-x;
  border-color: color-yig(rgba(194, 42, 0, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(244, 153, 128, 0.5);
}

.module-chili-30:active, .module-chili-30.active,
.show > .module-chili-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(181, 39, 0, 0.3);
  background-image: none;
  border-color: rgba(168, 36, 0, 0.3);
}

.module-chili-30:active:focus, .module-chili-30.active:focus,
.show > .module-chili-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 153, 128, 0.5);
}

.module-chili-30:disabled, .module-chili-30.disabled {
  color: #fff;
  background-color: rgba(232, 50, 0, 0.3);
  background-image: none;
  border-color: rgba(232, 50, 0, 0.3);
}

.module-chili-40 {
  color: #fff;
  background: rgba(232, 50, 0, 0.4) linear-gradient(180deg, rgba(234, 131, 103, 0.49), rgba(232, 50, 0, 0.4)) repeat-x;
  border-color: rgba(232, 50, 0, 0.4);
}

.module-chili-40 .icon-container {
  background-color: rgba(194, 42, 0, 0.4);
}

.module-chili-40 .icon-container.light {
  background-color: rgba(255, 97, 54, 0.4);
}

.module-chili-40:hover {
  color: #fff;
  background: rgba(194, 42, 0, 0.4) linear-gradient(180deg, rgba(212, 126, 103, 0.49), rgba(194, 42, 0, 0.4)) repeat-x;
  border-color: color-yig(rgba(194, 42, 0, 0.4));
}

.module-chili-40:focus, .module-chili-40.focus {
  color: #fff;
  background: rgba(194, 42, 0, 0.4) linear-gradient(180deg, rgba(212, 126, 103, 0.49), rgba(194, 42, 0, 0.4)) repeat-x;
  border-color: color-yig(rgba(194, 42, 0, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(242, 135, 106, 0.5);
}

.module-chili-40:active, .module-chili-40.active,
.show > .module-chili-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(181, 39, 0, 0.4);
  background-image: none;
  border-color: rgba(168, 36, 0, 0.4);
}

.module-chili-40:active:focus, .module-chili-40.active:focus,
.show > .module-chili-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 135, 106, 0.5);
}

.module-chili-40:disabled, .module-chili-40.disabled {
  color: #fff;
  background-color: rgba(232, 50, 0, 0.4);
  background-image: none;
  border-color: rgba(232, 50, 0, 0.4);
}

.module-chili-50 {
  color: #fff;
  background: rgba(232, 50, 0, 0.5) linear-gradient(180deg, rgba(234, 118, 87, 0.575), rgba(232, 50, 0, 0.5)) repeat-x;
  border-color: rgba(232, 50, 0, 0.5);
}

.module-chili-50 .icon-container {
  background-color: rgba(194, 42, 0, 0.5);
}

.module-chili-50 .icon-container.light {
  background-color: rgba(255, 97, 54, 0.5);
}

.module-chili-50:hover {
  color: #fff;
  background: rgba(194, 42, 0, 0.5) linear-gradient(180deg, rgba(209, 112, 87, 0.575), rgba(194, 42, 0, 0.5)) repeat-x;
  border-color: color-yig(rgba(194, 42, 0, 0.5));
}

.module-chili-50:focus, .module-chili-50.focus {
  color: #fff;
  background: rgba(194, 42, 0, 0.5) linear-gradient(180deg, rgba(209, 112, 87, 0.575), rgba(194, 42, 0, 0.5)) repeat-x;
  border-color: color-yig(rgba(194, 42, 0, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(240, 121, 88, 0.5);
}

.module-chili-50:active, .module-chili-50.active,
.show > .module-chili-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(181, 39, 0, 0.5);
  background-image: none;
  border-color: rgba(168, 36, 0, 0.5);
}

.module-chili-50:active:focus, .module-chili-50.active:focus,
.show > .module-chili-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 121, 88, 0.5);
}

.module-chili-50:disabled, .module-chili-50.disabled {
  color: #fff;
  background-color: rgba(232, 50, 0, 0.5);
  background-image: none;
  border-color: rgba(232, 50, 0, 0.5);
}

.module-chili-60 {
  color: #fff;
  background: rgba(232, 50, 0, 0.6) linear-gradient(180deg, rgba(234, 107, 73, 0.66), rgba(232, 50, 0, 0.6)) repeat-x;
  border-color: rgba(232, 50, 0, 0.6);
}

.module-chili-60 .icon-container {
  background-color: rgba(194, 42, 0, 0.6);
}

.module-chili-60 .icon-container.light {
  background-color: rgba(255, 97, 54, 0.6);
}

.module-chili-60:hover {
  color: #fff;
  background: rgba(194, 42, 0, 0.6) linear-gradient(180deg, rgba(207, 101, 73, 0.66), rgba(194, 42, 0, 0.6)) repeat-x;
  border-color: color-yig(rgba(194, 42, 0, 0.6));
}

.module-chili-60:focus, .module-chili-60.focus {
  color: #fff;
  background: rgba(194, 42, 0, 0.6) linear-gradient(180deg, rgba(207, 101, 73, 0.66), rgba(194, 42, 0, 0.6)) repeat-x;
  border-color: color-yig(rgba(194, 42, 0, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(239, 110, 74, 0.5);
}

.module-chili-60:active, .module-chili-60.active,
.show > .module-chili-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(181, 39, 0, 0.6);
  background-image: none;
  border-color: rgba(168, 36, 0, 0.6);
}

.module-chili-60:active:focus, .module-chili-60.active:focus,
.show > .module-chili-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 110, 74, 0.5);
}

.module-chili-60:disabled, .module-chili-60.disabled {
  color: #fff;
  background-color: rgba(232, 50, 0, 0.6);
  background-image: none;
  border-color: rgba(232, 50, 0, 0.6);
}

.module-chili-70 {
  color: #fff;
  background: rgba(232, 50, 0, 0.7) linear-gradient(180deg, rgba(233, 98, 62, 0.745), rgba(232, 50, 0, 0.7)) repeat-x;
  border-color: rgba(232, 50, 0, 0.7);
}

.module-chili-70 .icon-container {
  background-color: rgba(194, 42, 0, 0.7);
}

.module-chili-70 .icon-container.light {
  background-color: rgba(255, 97, 54, 0.7);
}

.module-chili-70:hover {
  color: #fff;
  background: rgba(194, 42, 0, 0.7) linear-gradient(180deg, rgba(205, 92, 62, 0.745), rgba(194, 42, 0, 0.7)) repeat-x;
  border-color: color-yig(rgba(194, 42, 0, 0.7));
}

.module-chili-70:focus, .module-chili-70.focus {
  color: #fff;
  background: rgba(194, 42, 0, 0.7) linear-gradient(180deg, rgba(205, 92, 62, 0.745), rgba(194, 42, 0, 0.7)) repeat-x;
  border-color: color-yig(rgba(194, 42, 0, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(238, 101, 63, 0.5);
}

.module-chili-70:active, .module-chili-70.active,
.show > .module-chili-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(181, 39, 0, 0.7);
  background-image: none;
  border-color: rgba(168, 36, 0, 0.7);
}

.module-chili-70:active:focus, .module-chili-70.active:focus,
.show > .module-chili-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 101, 63, 0.5);
}

.module-chili-70:disabled, .module-chili-70.disabled {
  color: #fff;
  background-color: rgba(232, 50, 0, 0.7);
  background-image: none;
  border-color: rgba(232, 50, 0, 0.7);
}

.module-chili-80 {
  color: #fff;
  background: rgba(232, 50, 0, 0.8) linear-gradient(180deg, rgba(233, 91, 52, 0.83), rgba(232, 50, 0, 0.8)) repeat-x;
  border-color: rgba(232, 50, 0, 0.8);
}

.module-chili-80 .icon-container {
  background-color: rgba(194, 42, 0, 0.8);
}

.module-chili-80 .icon-container.light {
  background-color: rgba(255, 97, 54, 0.8);
}

.module-chili-80:hover {
  color: #fff;
  background: rgba(194, 42, 0, 0.8) linear-gradient(180deg, rgba(203, 84, 52, 0.83), rgba(194, 42, 0, 0.8)) repeat-x;
  border-color: color-yig(rgba(194, 42, 0, 0.8));
}

.module-chili-80:focus, .module-chili-80.focus {
  color: #fff;
  background: rgba(194, 42, 0, 0.8) linear-gradient(180deg, rgba(203, 84, 52, 0.83), rgba(194, 42, 0, 0.8)) repeat-x;
  border-color: color-yig(rgba(194, 42, 0, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(237, 93, 53, 0.5);
}

.module-chili-80:active, .module-chili-80.active,
.show > .module-chili-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(181, 39, 0, 0.8);
  background-image: none;
  border-color: rgba(168, 36, 0, 0.8);
}

.module-chili-80:active:focus, .module-chili-80.active:focus,
.show > .module-chili-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 93, 53, 0.5);
}

.module-chili-80:disabled, .module-chili-80.disabled {
  color: #fff;
  background-color: rgba(232, 50, 0, 0.8);
  background-image: none;
  border-color: rgba(232, 50, 0, 0.8);
}

.module-chili-90 {
  color: #fff;
  background: rgba(232, 50, 0, 0.9) linear-gradient(180deg, rgba(233, 85, 44, 0.915), rgba(232, 50, 0, 0.9)) repeat-x;
  border-color: rgba(232, 50, 0, 0.9);
}

.module-chili-90 .icon-container {
  background-color: rgba(194, 42, 0, 0.9);
}

.module-chili-90 .icon-container.light {
  background-color: rgba(255, 97, 54, 0.9);
}

.module-chili-90:hover {
  color: #fff;
  background: rgba(194, 42, 0, 0.9) linear-gradient(180deg, rgba(202, 78, 44, 0.915), rgba(194, 42, 0, 0.9)) repeat-x;
  border-color: color-yig(rgba(194, 42, 0, 0.9));
}

.module-chili-90:focus, .module-chili-90.focus {
  color: #fff;
  background: rgba(194, 42, 0, 0.9) linear-gradient(180deg, rgba(202, 78, 44, 0.915), rgba(194, 42, 0, 0.9)) repeat-x;
  border-color: color-yig(rgba(194, 42, 0, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(236, 86, 45, 0.5);
}

.module-chili-90:active, .module-chili-90.active,
.show > .module-chili-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(181, 39, 0, 0.9);
  background-image: none;
  border-color: rgba(168, 36, 0, 0.9);
}

.module-chili-90:active:focus, .module-chili-90.active:focus,
.show > .module-chili-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(236, 86, 45, 0.5);
}

.module-chili-90:disabled, .module-chili-90.disabled {
  color: #fff;
  background-color: rgba(232, 50, 0, 0.9);
  background-image: none;
  border-color: rgba(232, 50, 0, 0.9);
}

.module-pumpkin {
  color: #273a4d;
  background: #ff7a55 linear-gradient(180deg, #fc8c6e, #ff7a55) repeat-x;
  border-color: #ff7a55;
}

.module-pumpkin .icon-container {
  background-color: #ff5c2f;
}

.module-pumpkin .icon-container.light {
  background-color: #ffb6a2;
}

.module-pumpkin:hover {
  color: #fff;
  background: #ff5c2f linear-gradient(180deg, #fc734d, #ff5c2f) repeat-x;
  border-color: color-yig(#ff5c2f);
}

.module-pumpkin:focus, .module-pumpkin.focus {
  color: #fff;
  background: #ff5c2f linear-gradient(180deg, #fc734d, #ff5c2f) repeat-x;
  border-color: color-yig(#ff5c2f);
  box-shadow: 0 0 0 0.2rem rgba(223, 112, 84, 0.5);
}

.module-pumpkin:active, .module-pumpkin.active,
.show > .module-pumpkin.dropdown-toggle {
  color: #fff;
  background-color: #ff5222;
  background-image: none;
  border-color: #ff4815;
}

.module-pumpkin:active:focus, .module-pumpkin.active:focus,
.show > .module-pumpkin.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 112, 84, 0.5);
}

.module-pumpkin:disabled, .module-pumpkin.disabled {
  color: #273a4d;
  background-color: #ff7a55;
  background-image: none;
  border-color: #ff7a55;
}

.module-pumpkin-10 {
  color: #273a4d;
  background: rgba(255, 122, 85, 0.1) linear-gradient(180deg, rgba(242, 217, 212, 0.235), rgba(255, 122, 85, 0.1)) repeat-x;
  border-color: rgba(255, 122, 85, 0.1);
}

.module-pumpkin-10 .icon-container {
  background-color: rgba(255, 92, 47, 0.1);
}

.module-pumpkin-10 .icon-container.light {
  background-color: rgba(255, 182, 162, 0.1);
}

.module-pumpkin-10:hover {
  color: #fff;
  background: rgba(255, 92, 47, 0.1) linear-gradient(180deg, rgba(242, 210, 203, 0.235), rgba(255, 92, 47, 0.1)) repeat-x;
  border-color: color-yig(rgba(255, 92, 47, 0.1));
}

.module-pumpkin-10:focus, .module-pumpkin-10.focus {
  color: #fff;
  background: rgba(255, 92, 47, 0.1) linear-gradient(180deg, rgba(242, 210, 203, 0.235), rgba(255, 92, 47, 0.1)) repeat-x;
  border-color: color-yig(rgba(255, 92, 47, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(89, 73, 79, 0.5);
}

.module-pumpkin-10:active, .module-pumpkin-10.active,
.show > .module-pumpkin-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 82, 34, 0.1);
  background-image: none;
  border-color: rgba(255, 72, 21, 0.1);
}

.module-pumpkin-10:active:focus, .module-pumpkin-10.active:focus,
.show > .module-pumpkin-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 73, 79, 0.5);
}

.module-pumpkin-10:disabled, .module-pumpkin-10.disabled {
  color: #273a4d;
  background-color: rgba(255, 122, 85, 0.1);
  background-image: none;
  border-color: rgba(255, 122, 85, 0.1);
}

.module-pumpkin-20 {
  color: #273a4d;
  background: rgba(255, 122, 85, 0.2) linear-gradient(180deg, rgba(245, 197, 186, 0.32), rgba(255, 122, 85, 0.2)) repeat-x;
  border-color: rgba(255, 122, 85, 0.2);
}

.module-pumpkin-20 .icon-container {
  background-color: rgba(255, 92, 47, 0.2);
}

.module-pumpkin-20 .icon-container.light {
  background-color: rgba(255, 182, 162, 0.2);
}

.module-pumpkin-20:hover {
  color: #fff;
  background: rgba(255, 92, 47, 0.2) linear-gradient(180deg, rgba(245, 186, 171, 0.32), rgba(255, 92, 47, 0.2)) repeat-x;
  border-color: color-yig(rgba(255, 92, 47, 0.2));
}

.module-pumpkin-20:focus, .module-pumpkin-20.focus {
  color: #fff;
  background: rgba(255, 92, 47, 0.2) linear-gradient(180deg, rgba(245, 186, 171, 0.32), rgba(255, 92, 47, 0.2)) repeat-x;
  border-color: color-yig(rgba(255, 92, 47, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(122, 83, 80, 0.5);
}

.module-pumpkin-20:active, .module-pumpkin-20.active,
.show > .module-pumpkin-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 82, 34, 0.2);
  background-image: none;
  border-color: rgba(255, 72, 21, 0.2);
}

.module-pumpkin-20:active:focus, .module-pumpkin-20.active:focus,
.show > .module-pumpkin-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 83, 80, 0.5);
}

.module-pumpkin-20:disabled, .module-pumpkin-20.disabled {
  color: #273a4d;
  background-color: rgba(255, 122, 85, 0.2);
  background-image: none;
  border-color: rgba(255, 122, 85, 0.2);
}

.module-pumpkin-30 {
  color: #273a4d;
  background: rgba(255, 122, 85, 0.3) linear-gradient(180deg, rgba(247, 184, 168, 0.405), rgba(255, 122, 85, 0.3)) repeat-x;
  border-color: rgba(255, 122, 85, 0.3);
}

.module-pumpkin-30 .icon-container {
  background-color: rgba(255, 92, 47, 0.3);
}

.module-pumpkin-30 .icon-container.light {
  background-color: rgba(255, 182, 162, 0.3);
}

.module-pumpkin-30:hover {
  color: #fff;
  background: rgba(255, 92, 47, 0.3) linear-gradient(180deg, rgba(247, 169, 148, 0.405), rgba(255, 92, 47, 0.3)) repeat-x;
  border-color: color-yig(rgba(255, 92, 47, 0.3));
}

.module-pumpkin-30:focus, .module-pumpkin-30.focus {
  color: #fff;
  background: rgba(255, 92, 47, 0.3) linear-gradient(180deg, rgba(247, 169, 148, 0.405), rgba(255, 92, 47, 0.3)) repeat-x;
  border-color: color-yig(rgba(255, 92, 47, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(147, 90, 81, 0.5);
}

.module-pumpkin-30:active, .module-pumpkin-30.active,
.show > .module-pumpkin-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 82, 34, 0.3);
  background-image: none;
  border-color: rgba(255, 72, 21, 0.3);
}

.module-pumpkin-30:active:focus, .module-pumpkin-30.active:focus,
.show > .module-pumpkin-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 90, 81, 0.5);
}

.module-pumpkin-30:disabled, .module-pumpkin-30.disabled {
  color: #273a4d;
  background-color: rgba(255, 122, 85, 0.3);
  background-image: none;
  border-color: rgba(255, 122, 85, 0.3);
}

.module-pumpkin-40 {
  color: #273a4d;
  background: rgba(255, 122, 85, 0.4) linear-gradient(180deg, rgba(248, 173, 153, 0.49), rgba(255, 122, 85, 0.4)) repeat-x;
  border-color: rgba(255, 122, 85, 0.4);
}

.module-pumpkin-40 .icon-container {
  background-color: rgba(255, 92, 47, 0.4);
}

.module-pumpkin-40 .icon-container.light {
  background-color: rgba(255, 182, 162, 0.4);
}

.module-pumpkin-40:hover {
  color: #fff;
  background: rgba(255, 92, 47, 0.4) linear-gradient(180deg, rgba(248, 155, 131, 0.49), rgba(255, 92, 47, 0.4)) repeat-x;
  border-color: color-yig(rgba(255, 92, 47, 0.4));
}

.module-pumpkin-40:focus, .module-pumpkin-40.focus {
  color: #fff;
  background: rgba(255, 92, 47, 0.4) linear-gradient(180deg, rgba(248, 155, 131, 0.49), rgba(255, 92, 47, 0.4)) repeat-x;
  border-color: color-yig(rgba(255, 92, 47, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(166, 96, 82, 0.5);
}

.module-pumpkin-40:active, .module-pumpkin-40.active,
.show > .module-pumpkin-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 82, 34, 0.4);
  background-image: none;
  border-color: rgba(255, 72, 21, 0.4);
}

.module-pumpkin-40:active:focus, .module-pumpkin-40.active:focus,
.show > .module-pumpkin-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 96, 82, 0.5);
}

.module-pumpkin-40:disabled, .module-pumpkin-40.disabled {
  color: #273a4d;
  background-color: rgba(255, 122, 85, 0.4);
  background-image: none;
  border-color: rgba(255, 122, 85, 0.4);
}

.module-pumpkin-50 {
  color: #273a4d;
  background: rgba(255, 122, 85, 0.5) linear-gradient(180deg, rgba(249, 165, 142, 0.575), rgba(255, 122, 85, 0.5)) repeat-x;
  border-color: rgba(255, 122, 85, 0.5);
}

.module-pumpkin-50 .icon-container {
  background-color: rgba(255, 92, 47, 0.5);
}

.module-pumpkin-50 .icon-container.light {
  background-color: rgba(255, 182, 162, 0.5);
}

.module-pumpkin-50:hover {
  color: #fff;
  background: rgba(255, 92, 47, 0.5) linear-gradient(180deg, rgba(249, 145, 117, 0.575), rgba(255, 92, 47, 0.5)) repeat-x;
  border-color: color-yig(rgba(255, 92, 47, 0.5));
}

.module-pumpkin-50:focus, .module-pumpkin-50.focus {
  color: #fff;
  background: rgba(255, 92, 47, 0.5) linear-gradient(180deg, rgba(249, 145, 117, 0.575), rgba(255, 92, 47, 0.5)) repeat-x;
  border-color: color-yig(rgba(255, 92, 47, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(180, 100, 82, 0.5);
}

.module-pumpkin-50:active, .module-pumpkin-50.active,
.show > .module-pumpkin-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 82, 34, 0.5);
  background-image: none;
  border-color: rgba(255, 72, 21, 0.5);
}

.module-pumpkin-50:active:focus, .module-pumpkin-50.active:focus,
.show > .module-pumpkin-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(180, 100, 82, 0.5);
}

.module-pumpkin-50:disabled, .module-pumpkin-50.disabled {
  color: #273a4d;
  background-color: rgba(255, 122, 85, 0.5);
  background-image: none;
  border-color: rgba(255, 122, 85, 0.5);
}

.module-pumpkin-60 {
  color: #273a4d;
  background: rgba(255, 122, 85, 0.6) linear-gradient(180deg, rgba(250, 158, 133, 0.66), rgba(255, 122, 85, 0.6)) repeat-x;
  border-color: rgba(255, 122, 85, 0.6);
}

.module-pumpkin-60 .icon-container {
  background-color: rgba(255, 92, 47, 0.6);
}

.module-pumpkin-60 .icon-container.light {
  background-color: rgba(255, 182, 162, 0.6);
}

.module-pumpkin-60:hover {
  color: #fff;
  background: rgba(255, 92, 47, 0.6) linear-gradient(180deg, rgba(250, 137, 106, 0.66), rgba(255, 92, 47, 0.6)) repeat-x;
  border-color: color-yig(rgba(255, 92, 47, 0.6));
}

.module-pumpkin-60:focus, .module-pumpkin-60.focus {
  color: #fff;
  background: rgba(255, 92, 47, 0.6) linear-gradient(180deg, rgba(250, 137, 106, 0.66), rgba(255, 92, 47, 0.6)) repeat-x;
  border-color: color-yig(rgba(255, 92, 47, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(192, 103, 83, 0.5);
}

.module-pumpkin-60:active, .module-pumpkin-60.active,
.show > .module-pumpkin-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 82, 34, 0.6);
  background-image: none;
  border-color: rgba(255, 72, 21, 0.6);
}

.module-pumpkin-60:active:focus, .module-pumpkin-60.active:focus,
.show > .module-pumpkin-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(192, 103, 83, 0.5);
}

.module-pumpkin-60:disabled, .module-pumpkin-60.disabled {
  color: #273a4d;
  background-color: rgba(255, 122, 85, 0.6);
  background-image: none;
  border-color: rgba(255, 122, 85, 0.6);
}

.module-pumpkin-70 {
  color: #273a4d;
  background: rgba(255, 122, 85, 0.7) linear-gradient(180deg, rgba(251, 152, 126, 0.745), rgba(255, 122, 85, 0.7)) repeat-x;
  border-color: rgba(255, 122, 85, 0.7);
}

.module-pumpkin-70 .icon-container {
  background-color: rgba(255, 92, 47, 0.7);
}

.module-pumpkin-70 .icon-container.light {
  background-color: rgba(255, 182, 162, 0.7);
}

.module-pumpkin-70:hover {
  color: #fff;
  background: rgba(255, 92, 47, 0.7) linear-gradient(180deg, rgba(251, 130, 97, 0.745), rgba(255, 92, 47, 0.7)) repeat-x;
  border-color: color-yig(rgba(255, 92, 47, 0.7));
}

.module-pumpkin-70:focus, .module-pumpkin-70.focus {
  color: #fff;
  background: rgba(255, 92, 47, 0.7) linear-gradient(180deg, rgba(251, 130, 97, 0.745), rgba(255, 92, 47, 0.7)) repeat-x;
  border-color: color-yig(rgba(255, 92, 47, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(202, 106, 83, 0.5);
}

.module-pumpkin-70:active, .module-pumpkin-70.active,
.show > .module-pumpkin-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 82, 34, 0.7);
  background-image: none;
  border-color: rgba(255, 72, 21, 0.7);
}

.module-pumpkin-70:active:focus, .module-pumpkin-70.active:focus,
.show > .module-pumpkin-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(202, 106, 83, 0.5);
}

.module-pumpkin-70:disabled, .module-pumpkin-70.disabled {
  color: #273a4d;
  background-color: rgba(255, 122, 85, 0.7);
  background-image: none;
  border-color: rgba(255, 122, 85, 0.7);
}

.module-pumpkin-80 {
  color: #273a4d;
  background: rgba(255, 122, 85, 0.8) linear-gradient(180deg, rgba(251, 148, 120, 0.83), rgba(255, 122, 85, 0.8)) repeat-x;
  border-color: rgba(255, 122, 85, 0.8);
}

.module-pumpkin-80 .icon-container {
  background-color: rgba(255, 92, 47, 0.8);
}

.module-pumpkin-80 .icon-container.light {
  background-color: rgba(255, 182, 162, 0.8);
}

.module-pumpkin-80:hover {
  color: #fff;
  background: rgba(255, 92, 47, 0.8) linear-gradient(180deg, rgba(251, 124, 89, 0.83), rgba(255, 92, 47, 0.8)) repeat-x;
  border-color: color-yig(rgba(255, 92, 47, 0.8));
}

.module-pumpkin-80:focus, .module-pumpkin-80.focus {
  color: #fff;
  background: rgba(255, 92, 47, 0.8) linear-gradient(180deg, rgba(251, 124, 89, 0.83), rgba(255, 92, 47, 0.8)) repeat-x;
  border-color: color-yig(rgba(255, 92, 47, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(210, 109, 83, 0.5);
}

.module-pumpkin-80:active, .module-pumpkin-80.active,
.show > .module-pumpkin-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 82, 34, 0.8);
  background-image: none;
  border-color: rgba(255, 72, 21, 0.8);
}

.module-pumpkin-80:active:focus, .module-pumpkin-80.active:focus,
.show > .module-pumpkin-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 109, 83, 0.5);
}

.module-pumpkin-80:disabled, .module-pumpkin-80.disabled {
  color: #273a4d;
  background-color: rgba(255, 122, 85, 0.8);
  background-image: none;
  border-color: rgba(255, 122, 85, 0.8);
}

.module-pumpkin-90 {
  color: #273a4d;
  background: rgba(255, 122, 85, 0.9) linear-gradient(180deg, rgba(252, 144, 114, 0.915), rgba(255, 122, 85, 0.9)) repeat-x;
  border-color: rgba(255, 122, 85, 0.9);
}

.module-pumpkin-90 .icon-container {
  background-color: rgba(255, 92, 47, 0.9);
}

.module-pumpkin-90 .icon-container.light {
  background-color: rgba(255, 182, 162, 0.9);
}

.module-pumpkin-90:hover {
  color: #fff;
  background: rgba(255, 92, 47, 0.9) linear-gradient(180deg, rgba(252, 119, 83, 0.915), rgba(255, 92, 47, 0.9)) repeat-x;
  border-color: color-yig(rgba(255, 92, 47, 0.9));
}

.module-pumpkin-90:focus, .module-pumpkin-90.focus {
  color: #fff;
  background: rgba(255, 92, 47, 0.9) linear-gradient(180deg, rgba(252, 119, 83, 0.915), rgba(255, 92, 47, 0.9)) repeat-x;
  border-color: color-yig(rgba(255, 92, 47, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(217, 111, 84, 0.5);
}

.module-pumpkin-90:active, .module-pumpkin-90.active,
.show > .module-pumpkin-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 82, 34, 0.9);
  background-image: none;
  border-color: rgba(255, 72, 21, 0.9);
}

.module-pumpkin-90:active:focus, .module-pumpkin-90.active:focus,
.show > .module-pumpkin-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 111, 84, 0.5);
}

.module-pumpkin-90:disabled, .module-pumpkin-90.disabled {
  color: #273a4d;
  background-color: rgba(255, 122, 85, 0.9);
  background-image: none;
  border-color: rgba(255, 122, 85, 0.9);
}

.module-salmon {
  color: #273a4d;
  background: #ffb5a1 linear-gradient(180deg, #fcbfae, #ffb5a1) repeat-x;
  border-color: #ffb5a1;
}

.module-salmon .icon-container {
  background-color: #ff977b;
}

.module-salmon .icon-container.light {
  background-color: #fff1ee;
}

.module-salmon:hover {
  color: #273a4d;
  background: #ff977b linear-gradient(180deg, #fca58e, #ff977b) repeat-x;
  border-color: color-yig(#ff977b);
}

.module-salmon:focus, .module-salmon.focus {
  color: #273a4d;
  background: #ff977b linear-gradient(180deg, #fca58e, #ff977b) repeat-x;
  border-color: color-yig(#ff977b);
  box-shadow: 0 0 0 0.2rem rgba(223, 163, 148, 0.5);
}

.module-salmon:active, .module-salmon.active,
.show > .module-salmon.dropdown-toggle {
  color: #273a4d;
  background-color: #ff8d6e;
  background-image: none;
  border-color: #ff8361;
}

.module-salmon:active:focus, .module-salmon.active:focus,
.show > .module-salmon.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 163, 148, 0.5);
}

.module-salmon:disabled, .module-salmon.disabled {
  color: #273a4d;
  background-color: #ffb5a1;
  background-image: none;
  border-color: #ffb5a1;
}

.module-salmon-10 {
  color: #273a4d;
  background: rgba(255, 181, 161, 0.1) linear-gradient(180deg, rgba(242, 230, 230, 0.235), rgba(255, 181, 161, 0.1)) repeat-x;
  border-color: rgba(255, 181, 161, 0.1);
}

.module-salmon-10 .icon-container {
  background-color: rgba(255, 151, 123, 0.1);
}

.module-salmon-10 .icon-container.light {
  background-color: rgba(255, 241, 238, 0.1);
}

.module-salmon-10:hover {
  color: #273a4d;
  background: rgba(255, 151, 123, 0.1) linear-gradient(180deg, rgba(242, 223, 221, 0.235), rgba(255, 151, 123, 0.1)) repeat-x;
  border-color: color-yig(rgba(255, 151, 123, 0.1));
}

.module-salmon-10:focus, .module-salmon-10.focus {
  color: #273a4d;
  background: rgba(255, 151, 123, 0.1) linear-gradient(180deg, rgba(242, 223, 221, 0.235), rgba(255, 151, 123, 0.1)) repeat-x;
  border-color: color-yig(rgba(255, 151, 123, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(89, 86, 96, 0.5);
}

.module-salmon-10:active, .module-salmon-10.active,
.show > .module-salmon-10.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 141, 110, 0.1);
  background-image: none;
  border-color: rgba(255, 131, 97, 0.1);
}

.module-salmon-10:active:focus, .module-salmon-10.active:focus,
.show > .module-salmon-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 86, 96, 0.5);
}

.module-salmon-10:disabled, .module-salmon-10.disabled {
  color: #273a4d;
  background-color: rgba(255, 181, 161, 0.1);
  background-image: none;
  border-color: rgba(255, 181, 161, 0.1);
}

.module-salmon-20 {
  color: #273a4d;
  background: rgba(255, 181, 161, 0.2) linear-gradient(180deg, rgba(245, 220, 216, 0.32), rgba(255, 181, 161, 0.2)) repeat-x;
  border-color: rgba(255, 181, 161, 0.2);
}

.module-salmon-20 .icon-container {
  background-color: rgba(255, 151, 123, 0.2);
}

.module-salmon-20 .icon-container.light {
  background-color: rgba(255, 241, 238, 0.2);
}

.module-salmon-20:hover {
  color: #273a4d;
  background: rgba(255, 151, 123, 0.2) linear-gradient(180deg, rgba(245, 209, 201, 0.32), rgba(255, 151, 123, 0.2)) repeat-x;
  border-color: color-yig(rgba(255, 151, 123, 0.2));
}

.module-salmon-20:focus, .module-salmon-20.focus {
  color: #273a4d;
  background: rgba(255, 151, 123, 0.2) linear-gradient(180deg, rgba(245, 209, 201, 0.32), rgba(255, 151, 123, 0.2)) repeat-x;
  border-color: color-yig(rgba(255, 151, 123, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(122, 106, 109, 0.5);
}

.module-salmon-20:active, .module-salmon-20.active,
.show > .module-salmon-20.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 141, 110, 0.2);
  background-image: none;
  border-color: rgba(255, 131, 97, 0.2);
}

.module-salmon-20:active:focus, .module-salmon-20.active:focus,
.show > .module-salmon-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 106, 109, 0.5);
}

.module-salmon-20:disabled, .module-salmon-20.disabled {
  color: #273a4d;
  background-color: rgba(255, 181, 161, 0.2);
  background-image: none;
  border-color: rgba(255, 181, 161, 0.2);
}

.module-salmon-30 {
  color: #273a4d;
  background: rgba(255, 181, 161, 0.3) linear-gradient(180deg, rgba(247, 213, 206, 0.405), rgba(255, 181, 161, 0.3)) repeat-x;
  border-color: rgba(255, 181, 161, 0.3);
}

.module-salmon-30 .icon-container {
  background-color: rgba(255, 151, 123, 0.3);
}

.module-salmon-30 .icon-container.light {
  background-color: rgba(255, 241, 238, 0.3);
}

.module-salmon-30:hover {
  color: #273a4d;
  background: rgba(255, 151, 123, 0.3) linear-gradient(180deg, rgba(247, 198, 186, 0.405), rgba(255, 151, 123, 0.3)) repeat-x;
  border-color: color-yig(rgba(255, 151, 123, 0.3));
}

.module-salmon-30:focus, .module-salmon-30.focus {
  color: #273a4d;
  background: rgba(255, 151, 123, 0.3) linear-gradient(180deg, rgba(247, 198, 186, 0.405), rgba(255, 151, 123, 0.3)) repeat-x;
  border-color: color-yig(rgba(255, 151, 123, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(147, 120, 119, 0.5);
}

.module-salmon-30:active, .module-salmon-30.active,
.show > .module-salmon-30.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 141, 110, 0.3);
  background-image: none;
  border-color: rgba(255, 131, 97, 0.3);
}

.module-salmon-30:active:focus, .module-salmon-30.active:focus,
.show > .module-salmon-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 120, 119, 0.5);
}

.module-salmon-30:disabled, .module-salmon-30.disabled {
  color: #273a4d;
  background-color: rgba(255, 181, 161, 0.3);
  background-image: none;
  border-color: rgba(255, 181, 161, 0.3);
}

.module-salmon-40 {
  color: #273a4d;
  background: rgba(255, 181, 161, 0.4) linear-gradient(180deg, rgba(248, 207, 198, 0.49), rgba(255, 181, 161, 0.4)) repeat-x;
  border-color: rgba(255, 181, 161, 0.4);
}

.module-salmon-40 .icon-container {
  background-color: rgba(255, 151, 123, 0.4);
}

.module-salmon-40 .icon-container.light {
  background-color: rgba(255, 241, 238, 0.4);
}

.module-salmon-40:hover {
  color: #273a4d;
  background: rgba(255, 151, 123, 0.4) linear-gradient(180deg, rgba(248, 190, 175, 0.49), rgba(255, 151, 123, 0.4)) repeat-x;
  border-color: color-yig(rgba(255, 151, 123, 0.4));
}

.module-salmon-40:focus, .module-salmon-40.focus {
  color: #273a4d;
  background: rgba(255, 151, 123, 0.4) linear-gradient(180deg, rgba(248, 190, 175, 0.49), rgba(255, 151, 123, 0.4)) repeat-x;
  border-color: color-yig(rgba(255, 151, 123, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(166, 130, 126, 0.5);
}

.module-salmon-40:active, .module-salmon-40.active,
.show > .module-salmon-40.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 141, 110, 0.4);
  background-image: none;
  border-color: rgba(255, 131, 97, 0.4);
}

.module-salmon-40:active:focus, .module-salmon-40.active:focus,
.show > .module-salmon-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 130, 126, 0.5);
}

.module-salmon-40:disabled, .module-salmon-40.disabled {
  color: #273a4d;
  background-color: rgba(255, 181, 161, 0.4);
  background-image: none;
  border-color: rgba(255, 181, 161, 0.4);
}

.module-salmon-50 {
  color: #273a4d;
  background: rgba(255, 181, 161, 0.5) linear-gradient(180deg, rgba(249, 203, 192, 0.575), rgba(255, 181, 161, 0.5)) repeat-x;
  border-color: rgba(255, 181, 161, 0.5);
}

.module-salmon-50 .icon-container {
  background-color: rgba(255, 151, 123, 0.5);
}

.module-salmon-50 .icon-container.light {
  background-color: rgba(255, 241, 238, 0.5);
}

.module-salmon-50:hover {
  color: #273a4d;
  background: rgba(255, 151, 123, 0.5) linear-gradient(180deg, rgba(249, 183, 167, 0.575), rgba(255, 151, 123, 0.5)) repeat-x;
  border-color: color-yig(rgba(255, 151, 123, 0.5));
}

.module-salmon-50:focus, .module-salmon-50.focus {
  color: #273a4d;
  background: rgba(255, 151, 123, 0.5) linear-gradient(180deg, rgba(249, 183, 167, 0.575), rgba(255, 151, 123, 0.5)) repeat-x;
  border-color: color-yig(rgba(255, 151, 123, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(180, 138, 132, 0.5);
}

.module-salmon-50:active, .module-salmon-50.active,
.show > .module-salmon-50.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 141, 110, 0.5);
  background-image: none;
  border-color: rgba(255, 131, 97, 0.5);
}

.module-salmon-50:active:focus, .module-salmon-50.active:focus,
.show > .module-salmon-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(180, 138, 132, 0.5);
}

.module-salmon-50:disabled, .module-salmon-50.disabled {
  color: #273a4d;
  background-color: rgba(255, 181, 161, 0.5);
  background-image: none;
  border-color: rgba(255, 181, 161, 0.5);
}

.module-salmon-60 {
  color: #273a4d;
  background: rgba(255, 181, 161, 0.6) linear-gradient(180deg, rgba(250, 200, 187, 0.66), rgba(255, 181, 161, 0.6)) repeat-x;
  border-color: rgba(255, 181, 161, 0.6);
}

.module-salmon-60 .icon-container {
  background-color: rgba(255, 151, 123, 0.6);
}

.module-salmon-60 .icon-container.light {
  background-color: rgba(255, 241, 238, 0.6);
}

.module-salmon-60:hover {
  color: #273a4d;
  background: rgba(255, 151, 123, 0.6) linear-gradient(180deg, rgba(250, 178, 160, 0.66), rgba(255, 151, 123, 0.6)) repeat-x;
  border-color: color-yig(rgba(255, 151, 123, 0.6));
}

.module-salmon-60:focus, .module-salmon-60.focus {
  color: #273a4d;
  background: rgba(255, 151, 123, 0.6) linear-gradient(180deg, rgba(250, 178, 160, 0.66), rgba(255, 151, 123, 0.6)) repeat-x;
  border-color: color-yig(rgba(255, 151, 123, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(192, 145, 137, 0.5);
}

.module-salmon-60:active, .module-salmon-60.active,
.show > .module-salmon-60.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 141, 110, 0.6);
  background-image: none;
  border-color: rgba(255, 131, 97, 0.6);
}

.module-salmon-60:active:focus, .module-salmon-60.active:focus,
.show > .module-salmon-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(192, 145, 137, 0.5);
}

.module-salmon-60:disabled, .module-salmon-60.disabled {
  color: #273a4d;
  background-color: rgba(255, 181, 161, 0.6);
  background-image: none;
  border-color: rgba(255, 181, 161, 0.6);
}

.module-salmon-70 {
  color: #273a4d;
  background: rgba(255, 181, 161, 0.7) linear-gradient(180deg, rgba(251, 197, 183, 0.745), rgba(255, 181, 161, 0.7)) repeat-x;
  border-color: rgba(255, 181, 161, 0.7);
}

.module-salmon-70 .icon-container {
  background-color: rgba(255, 151, 123, 0.7);
}

.module-salmon-70 .icon-container.light {
  background-color: rgba(255, 241, 238, 0.7);
}

.module-salmon-70:hover {
  color: #273a4d;
  background: rgba(255, 151, 123, 0.7) linear-gradient(180deg, rgba(251, 174, 154, 0.745), rgba(255, 151, 123, 0.7)) repeat-x;
  border-color: color-yig(rgba(255, 151, 123, 0.7));
}

.module-salmon-70:focus, .module-salmon-70.focus {
  color: #273a4d;
  background: rgba(255, 151, 123, 0.7) linear-gradient(180deg, rgba(251, 174, 154, 0.745), rgba(255, 151, 123, 0.7)) repeat-x;
  border-color: color-yig(rgba(255, 151, 123, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(202, 151, 140, 0.5);
}

.module-salmon-70:active, .module-salmon-70.active,
.show > .module-salmon-70.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 141, 110, 0.7);
  background-image: none;
  border-color: rgba(255, 131, 97, 0.7);
}

.module-salmon-70:active:focus, .module-salmon-70.active:focus,
.show > .module-salmon-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(202, 151, 140, 0.5);
}

.module-salmon-70:disabled, .module-salmon-70.disabled {
  color: #273a4d;
  background-color: rgba(255, 181, 161, 0.7);
  background-image: none;
  border-color: rgba(255, 181, 161, 0.7);
}

.module-salmon-80 {
  color: #273a4d;
  background: rgba(255, 181, 161, 0.8) linear-gradient(180deg, rgba(251, 194, 180, 0.83), rgba(255, 181, 161, 0.8)) repeat-x;
  border-color: rgba(255, 181, 161, 0.8);
}

.module-salmon-80 .icon-container {
  background-color: rgba(255, 151, 123, 0.8);
}

.module-salmon-80 .icon-container.light {
  background-color: rgba(255, 241, 238, 0.8);
}

.module-salmon-80:hover {
  color: #273a4d;
  background: rgba(255, 151, 123, 0.8) linear-gradient(180deg, rgba(251, 171, 149, 0.83), rgba(255, 151, 123, 0.8)) repeat-x;
  border-color: color-yig(rgba(255, 151, 123, 0.8));
}

.module-salmon-80:focus, .module-salmon-80.focus {
  color: #273a4d;
  background: rgba(255, 151, 123, 0.8) linear-gradient(180deg, rgba(251, 171, 149, 0.83), rgba(255, 151, 123, 0.8)) repeat-x;
  border-color: color-yig(rgba(255, 151, 123, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(210, 155, 143, 0.5);
}

.module-salmon-80:active, .module-salmon-80.active,
.show > .module-salmon-80.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 141, 110, 0.8);
  background-image: none;
  border-color: rgba(255, 131, 97, 0.8);
}

.module-salmon-80:active:focus, .module-salmon-80.active:focus,
.show > .module-salmon-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 155, 143, 0.5);
}

.module-salmon-80:disabled, .module-salmon-80.disabled {
  color: #273a4d;
  background-color: rgba(255, 181, 161, 0.8);
  background-image: none;
  border-color: rgba(255, 181, 161, 0.8);
}

.module-salmon-90 {
  color: #273a4d;
  background: rgba(255, 181, 161, 0.9) linear-gradient(180deg, rgba(252, 192, 177, 0.915), rgba(255, 181, 161, 0.9)) repeat-x;
  border-color: rgba(255, 181, 161, 0.9);
}

.module-salmon-90 .icon-container {
  background-color: rgba(255, 151, 123, 0.9);
}

.module-salmon-90 .icon-container.light {
  background-color: rgba(255, 241, 238, 0.9);
}

.module-salmon-90:hover {
  color: #273a4d;
  background: rgba(255, 151, 123, 0.9) linear-gradient(180deg, rgba(252, 168, 145, 0.915), rgba(255, 151, 123, 0.9)) repeat-x;
  border-color: color-yig(rgba(255, 151, 123, 0.9));
}

.module-salmon-90:focus, .module-salmon-90.focus {
  color: #273a4d;
  background: rgba(255, 151, 123, 0.9) linear-gradient(180deg, rgba(252, 168, 145, 0.915), rgba(255, 151, 123, 0.9)) repeat-x;
  border-color: color-yig(rgba(255, 151, 123, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(217, 159, 146, 0.5);
}

.module-salmon-90:active, .module-salmon-90.active,
.show > .module-salmon-90.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 141, 110, 0.9);
  background-image: none;
  border-color: rgba(255, 131, 97, 0.9);
}

.module-salmon-90:active:focus, .module-salmon-90.active:focus,
.show > .module-salmon-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 159, 146, 0.5);
}

.module-salmon-90:disabled, .module-salmon-90.disabled {
  color: #273a4d;
  background-color: rgba(255, 181, 161, 0.9);
  background-image: none;
  border-color: rgba(255, 181, 161, 0.9);
}

.module-eggplant {
  color: #fff;
  background: #401c34 linear-gradient(180deg, #5a3d52, #401c34) repeat-x;
  border-color: #401c34;
}

.module-eggplant .icon-container {
  background-color: #25101e;
}

.module-eggplant .icon-container.light {
  background-color: #75335f;
}

.module-eggplant:hover {
  color: #fff;
  background: #25101e linear-gradient(180deg, #43333f, #25101e) repeat-x;
  border-color: color-yig(#25101e);
}

.module-eggplant:focus, .module-eggplant.focus {
  color: #fff;
  background: #25101e linear-gradient(180deg, #43333f, #25101e) repeat-x;
  border-color: color-yig(#25101e);
  box-shadow: 0 0 0 0.2rem rgba(93, 62, 82, 0.5);
}

.module-eggplant:active, .module-eggplant.active,
.show > .module-eggplant.dropdown-toggle {
  color: #fff;
  background-color: #1d0c17;
  background-image: none;
  border-color: #140910;
}

.module-eggplant:active:focus, .module-eggplant.active:focus,
.show > .module-eggplant.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(93, 62, 82, 0.5);
}

.module-eggplant:disabled, .module-eggplant.disabled {
  color: #fff;
  background-color: #401c34;
  background-image: none;
  border-color: #401c34;
}

.module-eggplant-10 {
  color: #fff;
  background: rgba(64, 28, 52, 0.1) linear-gradient(180deg, rgba(198, 195, 205, 0.235), rgba(64, 28, 52, 0.1)) repeat-x;
  border-color: rgba(64, 28, 52, 0.1);
}

.module-eggplant-10 .icon-container {
  background-color: rgba(37, 16, 30, 0.1);
}

.module-eggplant-10 .icon-container.light {
  background-color: rgba(117, 51, 95, 0.1);
}

.module-eggplant-10:hover {
  color: #fff;
  background: rgba(37, 16, 30, 0.1) linear-gradient(180deg, rgba(192, 192, 200, 0.235), rgba(37, 16, 30, 0.1)) repeat-x;
  border-color: color-yig(rgba(37, 16, 30, 0.1));
}

.module-eggplant-10:focus, .module-eggplant-10.focus {
  color: #fff;
  background: rgba(37, 16, 30, 0.1) linear-gradient(180deg, rgba(192, 192, 200, 0.235), rgba(37, 16, 30, 0.1)) repeat-x;
  border-color: color-yig(rgba(37, 16, 30, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(211, 203, 208, 0.5);
}

.module-eggplant-10:active, .module-eggplant-10.active,
.show > .module-eggplant-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 12, 23, 0.1);
  background-image: none;
  border-color: rgba(20, 9, 16, 0.1);
}

.module-eggplant-10:active:focus, .module-eggplant-10.active:focus,
.show > .module-eggplant-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 203, 208, 0.5);
}

.module-eggplant-10:disabled, .module-eggplant-10.disabled {
  color: #fff;
  background-color: rgba(64, 28, 52, 0.1);
  background-image: none;
  border-color: rgba(64, 28, 52, 0.1);
}

.module-eggplant-20 {
  color: #fff;
  background: rgba(64, 28, 52, 0.2) linear-gradient(180deg, rgba(171, 161, 174, 0.32), rgba(64, 28, 52, 0.2)) repeat-x;
  border-color: rgba(64, 28, 52, 0.2);
}

.module-eggplant-20 .icon-container {
  background-color: rgba(37, 16, 30, 0.2);
}

.module-eggplant-20 .icon-container.light {
  background-color: rgba(117, 51, 95, 0.2);
}

.module-eggplant-20:hover {
  color: #fff;
  background: rgba(37, 16, 30, 0.2) linear-gradient(180deg, rgba(160, 157, 165, 0.32), rgba(37, 16, 30, 0.2)) repeat-x;
  border-color: color-yig(rgba(37, 16, 30, 0.2));
}

.module-eggplant-20:focus, .module-eggplant-20.focus {
  color: #fff;
  background: rgba(37, 16, 30, 0.2) linear-gradient(180deg, rgba(160, 157, 165, 0.32), rgba(37, 16, 30, 0.2)) repeat-x;
  border-color: color-yig(rgba(37, 16, 30, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(181, 167, 177, 0.5);
}

.module-eggplant-20:active, .module-eggplant-20.active,
.show > .module-eggplant-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 12, 23, 0.2);
  background-image: none;
  border-color: rgba(20, 9, 16, 0.2);
}

.module-eggplant-20:active:focus, .module-eggplant-20.active:focus,
.show > .module-eggplant-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(181, 167, 177, 0.5);
}

.module-eggplant-20:disabled, .module-eggplant-20.disabled {
  color: #fff;
  background-color: rgba(64, 28, 52, 0.2);
  background-image: none;
  border-color: rgba(64, 28, 52, 0.2);
}

.module-eggplant-30 {
  color: #fff;
  background: rgba(64, 28, 52, 0.3) linear-gradient(180deg, rgba(151, 137, 151, 0.405), rgba(64, 28, 52, 0.3)) repeat-x;
  border-color: rgba(64, 28, 52, 0.3);
}

.module-eggplant-30 .icon-container {
  background-color: rgba(37, 16, 30, 0.3);
}

.module-eggplant-30 .icon-container.light {
  background-color: rgba(117, 51, 95, 0.3);
}

.module-eggplant-30:hover {
  color: #fff;
  background: rgba(37, 16, 30, 0.3) linear-gradient(180deg, rgba(138, 131, 140, 0.405), rgba(37, 16, 30, 0.3)) repeat-x;
  border-color: color-yig(rgba(37, 16, 30, 0.3));
}

.module-eggplant-30:focus, .module-eggplant-30.focus {
  color: #fff;
  background: rgba(37, 16, 30, 0.3) linear-gradient(180deg, rgba(138, 131, 140, 0.405), rgba(37, 16, 30, 0.3)) repeat-x;
  border-color: color-yig(rgba(37, 16, 30, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(160, 142, 154, 0.5);
}

.module-eggplant-30:active, .module-eggplant-30.active,
.show > .module-eggplant-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 12, 23, 0.3);
  background-image: none;
  border-color: rgba(20, 9, 16, 0.3);
}

.module-eggplant-30:active:focus, .module-eggplant-30.active:focus,
.show > .module-eggplant-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(160, 142, 154, 0.5);
}

.module-eggplant-30:disabled, .module-eggplant-30.disabled {
  color: #fff;
  background-color: rgba(64, 28, 52, 0.3);
  background-image: none;
  border-color: rgba(64, 28, 52, 0.3);
}

.module-eggplant-40 {
  color: #fff;
  background: rgba(64, 28, 52, 0.4) linear-gradient(180deg, rgba(136, 118, 134, 0.49), rgba(64, 28, 52, 0.4)) repeat-x;
  border-color: rgba(64, 28, 52, 0.4);
}

.module-eggplant-40 .icon-container {
  background-color: rgba(37, 16, 30, 0.4);
}

.module-eggplant-40 .icon-container.light {
  background-color: rgba(117, 51, 95, 0.4);
}

.module-eggplant-40:hover {
  color: #fff;
  background: rgba(37, 16, 30, 0.4) linear-gradient(180deg, rgba(120, 111, 121, 0.49), rgba(37, 16, 30, 0.4)) repeat-x;
  border-color: color-yig(rgba(37, 16, 30, 0.4));
}

.module-eggplant-40:focus, .module-eggplant-40.focus {
  color: #fff;
  background: rgba(37, 16, 30, 0.4) linear-gradient(180deg, rgba(120, 111, 121, 0.49), rgba(37, 16, 30, 0.4)) repeat-x;
  border-color: color-yig(rgba(37, 16, 30, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(143, 122, 136, 0.5);
}

.module-eggplant-40:active, .module-eggplant-40.active,
.show > .module-eggplant-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 12, 23, 0.4);
  background-image: none;
  border-color: rgba(20, 9, 16, 0.4);
}

.module-eggplant-40:active:focus, .module-eggplant-40.active:focus,
.show > .module-eggplant-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(143, 122, 136, 0.5);
}

.module-eggplant-40:disabled, .module-eggplant-40.disabled {
  color: #fff;
  background-color: rgba(64, 28, 52, 0.4);
  background-image: none;
  border-color: rgba(64, 28, 52, 0.4);
}

.module-eggplant-50 {
  color: #fff;
  background: rgba(64, 28, 52, 0.5) linear-gradient(180deg, rgba(124, 103, 121, 0.575), rgba(64, 28, 52, 0.5)) repeat-x;
  border-color: rgba(64, 28, 52, 0.5);
}

.module-eggplant-50 .icon-container {
  background-color: rgba(37, 16, 30, 0.5);
}

.module-eggplant-50 .icon-container.light {
  background-color: rgba(117, 51, 95, 0.5);
}

.module-eggplant-50:hover {
  color: #fff;
  background: rgba(37, 16, 30, 0.5) linear-gradient(180deg, rgba(107, 96, 106, 0.575), rgba(37, 16, 30, 0.5)) repeat-x;
  border-color: color-yig(rgba(37, 16, 30, 0.5));
}

.module-eggplant-50:focus, .module-eggplant-50.focus {
  color: #fff;
  background: rgba(37, 16, 30, 0.5) linear-gradient(180deg, rgba(107, 96, 106, 0.575), rgba(37, 16, 30, 0.5)) repeat-x;
  border-color: color-yig(rgba(37, 16, 30, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(130, 107, 122, 0.5);
}

.module-eggplant-50:active, .module-eggplant-50.active,
.show > .module-eggplant-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 12, 23, 0.5);
  background-image: none;
  border-color: rgba(20, 9, 16, 0.5);
}

.module-eggplant-50:active:focus, .module-eggplant-50.active:focus,
.show > .module-eggplant-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 107, 122, 0.5);
}

.module-eggplant-50:disabled, .module-eggplant-50.disabled {
  color: #fff;
  background-color: rgba(64, 28, 52, 0.5);
  background-image: none;
  border-color: rgba(64, 28, 52, 0.5);
}

.module-eggplant-60 {
  color: #fff;
  background: rgba(64, 28, 52, 0.6) linear-gradient(180deg, rgba(115, 91, 110, 0.66), rgba(64, 28, 52, 0.6)) repeat-x;
  border-color: rgba(64, 28, 52, 0.6);
}

.module-eggplant-60 .icon-container {
  background-color: rgba(37, 16, 30, 0.6);
}

.module-eggplant-60 .icon-container.light {
  background-color: rgba(117, 51, 95, 0.6);
}

.module-eggplant-60:hover {
  color: #fff;
  background: rgba(37, 16, 30, 0.6) linear-gradient(180deg, rgba(96, 83, 94, 0.66), rgba(37, 16, 30, 0.6)) repeat-x;
  border-color: color-yig(rgba(37, 16, 30, 0.6));
}

.module-eggplant-60:focus, .module-eggplant-60.focus {
  color: #fff;
  background: rgba(37, 16, 30, 0.6) linear-gradient(180deg, rgba(96, 83, 94, 0.66), rgba(37, 16, 30, 0.6)) repeat-x;
  border-color: color-yig(rgba(37, 16, 30, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(120, 94, 111, 0.5);
}

.module-eggplant-60:active, .module-eggplant-60.active,
.show > .module-eggplant-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 12, 23, 0.6);
  background-image: none;
  border-color: rgba(20, 9, 16, 0.6);
}

.module-eggplant-60:active:focus, .module-eggplant-60.active:focus,
.show > .module-eggplant-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 94, 111, 0.5);
}

.module-eggplant-60:disabled, .module-eggplant-60.disabled {
  color: #fff;
  background-color: rgba(64, 28, 52, 0.6);
  background-image: none;
  border-color: rgba(64, 28, 52, 0.6);
}

.module-eggplant-70 {
  color: #fff;
  background: rgba(64, 28, 52, 0.7) linear-gradient(180deg, rgba(107, 82, 101, 0.745), rgba(64, 28, 52, 0.7)) repeat-x;
  border-color: rgba(64, 28, 52, 0.7);
}

.module-eggplant-70 .icon-container {
  background-color: rgba(37, 16, 30, 0.7);
}

.module-eggplant-70 .icon-container.light {
  background-color: rgba(117, 51, 95, 0.7);
}

.module-eggplant-70:hover {
  color: #fff;
  background: rgba(37, 16, 30, 0.7) linear-gradient(180deg, rgba(87, 73, 85, 0.745), rgba(37, 16, 30, 0.7)) repeat-x;
  border-color: color-yig(rgba(37, 16, 30, 0.7));
}

.module-eggplant-70:focus, .module-eggplant-70.focus {
  color: #fff;
  background: rgba(37, 16, 30, 0.7) linear-gradient(180deg, rgba(87, 73, 85, 0.745), rgba(37, 16, 30, 0.7)) repeat-x;
  border-color: color-yig(rgba(37, 16, 30, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(111, 84, 102, 0.5);
}

.module-eggplant-70:active, .module-eggplant-70.active,
.show > .module-eggplant-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 12, 23, 0.7);
  background-image: none;
  border-color: rgba(20, 9, 16, 0.7);
}

.module-eggplant-70:active:focus, .module-eggplant-70.active:focus,
.show > .module-eggplant-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 84, 102, 0.5);
}

.module-eggplant-70:disabled, .module-eggplant-70.disabled {
  color: #fff;
  background-color: rgba(64, 28, 52, 0.7);
  background-image: none;
  border-color: rgba(64, 28, 52, 0.7);
}

.module-eggplant-80 {
  color: #fff;
  background: rgba(64, 28, 52, 0.8) linear-gradient(180deg, rgba(100, 73, 93, 0.83), rgba(64, 28, 52, 0.8)) repeat-x;
  border-color: rgba(64, 28, 52, 0.8);
}

.module-eggplant-80 .icon-container {
  background-color: rgba(37, 16, 30, 0.8);
}

.module-eggplant-80 .icon-container.light {
  background-color: rgba(117, 51, 95, 0.8);
}

.module-eggplant-80:hover {
  color: #fff;
  background: rgba(37, 16, 30, 0.8) linear-gradient(180deg, rgba(79, 64, 76, 0.83), rgba(37, 16, 30, 0.8)) repeat-x;
  border-color: color-yig(rgba(37, 16, 30, 0.8));
}

.module-eggplant-80:focus, .module-eggplant-80.focus {
  color: #fff;
  background: rgba(37, 16, 30, 0.8) linear-gradient(180deg, rgba(79, 64, 76, 0.83), rgba(37, 16, 30, 0.8)) repeat-x;
  border-color: color-yig(rgba(37, 16, 30, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(104, 76, 94, 0.5);
}

.module-eggplant-80:active, .module-eggplant-80.active,
.show > .module-eggplant-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 12, 23, 0.8);
  background-image: none;
  border-color: rgba(20, 9, 16, 0.8);
}

.module-eggplant-80:active:focus, .module-eggplant-80.active:focus,
.show > .module-eggplant-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(104, 76, 94, 0.5);
}

.module-eggplant-80:disabled, .module-eggplant-80.disabled {
  color: #fff;
  background-color: rgba(64, 28, 52, 0.8);
  background-image: none;
  border-color: rgba(64, 28, 52, 0.8);
}

.module-eggplant-90 {
  color: #fff;
  background: rgba(64, 28, 52, 0.9) linear-gradient(180deg, rgba(95, 67, 87, 0.915), rgba(64, 28, 52, 0.9)) repeat-x;
  border-color: rgba(64, 28, 52, 0.9);
}

.module-eggplant-90 .icon-container {
  background-color: rgba(37, 16, 30, 0.9);
}

.module-eggplant-90 .icon-container.light {
  background-color: rgba(117, 51, 95, 0.9);
}

.module-eggplant-90:hover {
  color: #fff;
  background: rgba(37, 16, 30, 0.9) linear-gradient(180deg, rgba(73, 57, 69, 0.915), rgba(37, 16, 30, 0.9)) repeat-x;
  border-color: color-yig(rgba(37, 16, 30, 0.9));
}

.module-eggplant-90:focus, .module-eggplant-90.focus {
  color: #fff;
  background: rgba(37, 16, 30, 0.9) linear-gradient(180deg, rgba(73, 57, 69, 0.915), rgba(37, 16, 30, 0.9)) repeat-x;
  border-color: color-yig(rgba(37, 16, 30, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(98, 68, 88, 0.5);
}

.module-eggplant-90:active, .module-eggplant-90.active,
.show > .module-eggplant-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 12, 23, 0.9);
  background-image: none;
  border-color: rgba(20, 9, 16, 0.9);
}

.module-eggplant-90:active:focus, .module-eggplant-90.active:focus,
.show > .module-eggplant-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(98, 68, 88, 0.5);
}

.module-eggplant-90:disabled, .module-eggplant-90.disabled {
  color: #fff;
  background-color: rgba(64, 28, 52, 0.9);
  background-image: none;
  border-color: rgba(64, 28, 52, 0.9);
}

.module-plum {
  color: #fff;
  background: #6a114d linear-gradient(180deg, #7e3367, #6a114d) repeat-x;
  border-color: #6a114d;
}

.module-plum .icon-container {
  background-color: #490c35;
}

.module-plum .icon-container.light {
  background-color: #ac1c7d;
}

.module-plum:hover {
  color: #fff;
  background: #490c35 linear-gradient(180deg, #622f53, #490c35) repeat-x;
  border-color: color-yig(#490c35);
}

.module-plum:focus, .module-plum.focus {
  color: #fff;
  background: #490c35 linear-gradient(180deg, #622f53, #490c35) repeat-x;
  border-color: color-yig(#490c35);
  box-shadow: 0 0 0 0.2rem rgba(128, 53, 104, 0.5);
}

.module-plum:active, .module-plum.active,
.show > .module-plum.dropdown-toggle {
  color: #fff;
  background-color: #3e0a2d;
  background-image: none;
  border-color: #330825;
}

.module-plum:active:focus, .module-plum.active:focus,
.show > .module-plum.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 53, 104, 0.5);
}

.module-plum:disabled, .module-plum.disabled {
  color: #fff;
  background-color: #6a114d;
  background-image: none;
  border-color: #6a114d;
}

.module-plum-10 {
  color: #fff;
  background: rgba(106, 17, 77, 0.1) linear-gradient(180deg, rgba(208, 193, 210, 0.235), rgba(106, 17, 77, 0.1)) repeat-x;
  border-color: rgba(106, 17, 77, 0.1);
}

.module-plum-10 .icon-container {
  background-color: rgba(73, 12, 53, 0.1);
}

.module-plum-10 .icon-container.light {
  background-color: rgba(172, 28, 125, 0.1);
}

.module-plum-10:hover {
  color: #fff;
  background: rgba(73, 12, 53, 0.1) linear-gradient(180deg, rgba(200, 191, 205, 0.235), rgba(73, 12, 53, 0.1)) repeat-x;
  border-color: color-yig(rgba(73, 12, 53, 0.1));
}

.module-plum-10:focus, .module-plum-10.focus {
  color: #fff;
  background: rgba(73, 12, 53, 0.1) linear-gradient(180deg, rgba(200, 191, 205, 0.235), rgba(73, 12, 53, 0.1)) repeat-x;
  border-color: color-yig(rgba(73, 12, 53, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(221, 200, 214, 0.5);
}

.module-plum-10:active, .module-plum-10.active,
.show > .module-plum-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 10, 45, 0.1);
  background-image: none;
  border-color: rgba(51, 8, 37, 0.1);
}

.module-plum-10:active:focus, .module-plum-10.active:focus,
.show > .module-plum-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 200, 214, 0.5);
}

.module-plum-10:disabled, .module-plum-10.disabled {
  color: #fff;
  background-color: rgba(106, 17, 77, 0.1);
  background-image: none;
  border-color: rgba(106, 17, 77, 0.1);
}

.module-plum-20 {
  color: #fff;
  background: rgba(106, 17, 77, 0.2) linear-gradient(180deg, rgba(187, 157, 183, 0.32), rgba(106, 17, 77, 0.2)) repeat-x;
  border-color: rgba(106, 17, 77, 0.2);
}

.module-plum-20 .icon-container {
  background-color: rgba(73, 12, 53, 0.2);
}

.module-plum-20 .icon-container.light {
  background-color: rgba(172, 28, 125, 0.2);
}

.module-plum-20:hover {
  color: #fff;
  background: rgba(73, 12, 53, 0.2) linear-gradient(180deg, rgba(174, 155, 174, 0.32), rgba(73, 12, 53, 0.2)) repeat-x;
  border-color: color-yig(rgba(73, 12, 53, 0.2));
}

.module-plum-20:focus, .module-plum-20.focus {
  color: #fff;
  background: rgba(73, 12, 53, 0.2) linear-gradient(180deg, rgba(174, 155, 174, 0.32), rgba(73, 12, 53, 0.2)) repeat-x;
  border-color: color-yig(rgba(73, 12, 53, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(197, 163, 186, 0.5);
}

.module-plum-20:active, .module-plum-20.active,
.show > .module-plum-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 10, 45, 0.2);
  background-image: none;
  border-color: rgba(51, 8, 37, 0.2);
}

.module-plum-20:active:focus, .module-plum-20.active:focus,
.show > .module-plum-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(197, 163, 186, 0.5);
}

.module-plum-20:disabled, .module-plum-20.disabled {
  color: #fff;
  background-color: rgba(106, 17, 77, 0.2);
  background-image: none;
  border-color: rgba(106, 17, 77, 0.2);
}

.module-plum-30 {
  color: #fff;
  background: rgba(106, 17, 77, 0.3) linear-gradient(180deg, rgba(172, 131, 164, 0.405), rgba(106, 17, 77, 0.3)) repeat-x;
  border-color: rgba(106, 17, 77, 0.3);
}

.module-plum-30 .icon-container {
  background-color: rgba(73, 12, 53, 0.3);
}

.module-plum-30 .icon-container.light {
  background-color: rgba(172, 28, 125, 0.3);
}

.module-plum-30:hover {
  color: #fff;
  background: rgba(73, 12, 53, 0.3) linear-gradient(180deg, rgba(156, 128, 152, 0.405), rgba(73, 12, 53, 0.3)) repeat-x;
  border-color: color-yig(rgba(73, 12, 53, 0.3));
}

.module-plum-30:focus, .module-plum-30.focus {
  color: #fff;
  background: rgba(73, 12, 53, 0.3) linear-gradient(180deg, rgba(156, 128, 152, 0.405), rgba(73, 12, 53, 0.3)) repeat-x;
  border-color: color-yig(rgba(73, 12, 53, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(181, 136, 166, 0.5);
}

.module-plum-30:active, .module-plum-30.active,
.show > .module-plum-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 10, 45, 0.3);
  background-image: none;
  border-color: rgba(51, 8, 37, 0.3);
}

.module-plum-30:active:focus, .module-plum-30.active:focus,
.show > .module-plum-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(181, 136, 166, 0.5);
}

.module-plum-30:disabled, .module-plum-30.disabled {
  color: #fff;
  background-color: rgba(106, 17, 77, 0.3);
  background-image: none;
  border-color: rgba(106, 17, 77, 0.3);
}

.module-plum-40 {
  color: #fff;
  background: rgba(106, 17, 77, 0.4) linear-gradient(180deg, rgba(161, 111, 149, 0.49), rgba(106, 17, 77, 0.4)) repeat-x;
  border-color: rgba(106, 17, 77, 0.4);
}

.module-plum-40 .icon-container {
  background-color: rgba(73, 12, 53, 0.4);
}

.module-plum-40 .icon-container.light {
  background-color: rgba(172, 28, 125, 0.4);
}

.module-plum-40:hover {
  color: #fff;
  background: rgba(73, 12, 53, 0.4) linear-gradient(180deg, rgba(141, 108, 135, 0.49), rgba(73, 12, 53, 0.4)) repeat-x;
  border-color: color-yig(rgba(73, 12, 53, 0.4));
}

.module-plum-40:focus, .module-plum-40.focus {
  color: #fff;
  background: rgba(73, 12, 53, 0.4) linear-gradient(180deg, rgba(141, 108, 135, 0.49), rgba(73, 12, 53, 0.4)) repeat-x;
  border-color: color-yig(rgba(73, 12, 53, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(168, 115, 151, 0.5);
}

.module-plum-40:active, .module-plum-40.active,
.show > .module-plum-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 10, 45, 0.4);
  background-image: none;
  border-color: rgba(51, 8, 37, 0.4);
}

.module-plum-40:active:focus, .module-plum-40.active:focus,
.show > .module-plum-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(168, 115, 151, 0.5);
}

.module-plum-40:disabled, .module-plum-40.disabled {
  color: #fff;
  background-color: rgba(106, 17, 77, 0.4);
  background-image: none;
  border-color: rgba(106, 17, 77, 0.4);
}

.module-plum-50 {
  color: #fff;
  background: rgba(106, 17, 77, 0.5) linear-gradient(180deg, rgba(152, 96, 137, 0.575), rgba(106, 17, 77, 0.5)) repeat-x;
  border-color: rgba(106, 17, 77, 0.5);
}

.module-plum-50 .icon-container {
  background-color: rgba(73, 12, 53, 0.5);
}

.module-plum-50 .icon-container.light {
  background-color: rgba(172, 28, 125, 0.5);
}

.module-plum-50:hover {
  color: #fff;
  background: rgba(73, 12, 53, 0.5) linear-gradient(180deg, rgba(130, 92, 121, 0.575), rgba(73, 12, 53, 0.5)) repeat-x;
  border-color: color-yig(rgba(73, 12, 53, 0.5));
}

.module-plum-50:focus, .module-plum-50.focus {
  color: #fff;
  background: rgba(73, 12, 53, 0.5) linear-gradient(180deg, rgba(130, 92, 121, 0.575), rgba(73, 12, 53, 0.5)) repeat-x;
  border-color: color-yig(rgba(73, 12, 53, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(158, 99, 139, 0.5);
}

.module-plum-50:active, .module-plum-50.active,
.show > .module-plum-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 10, 45, 0.5);
  background-image: none;
  border-color: rgba(51, 8, 37, 0.5);
}

.module-plum-50:active:focus, .module-plum-50.active:focus,
.show > .module-plum-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(158, 99, 139, 0.5);
}

.module-plum-50:disabled, .module-plum-50.disabled {
  color: #fff;
  background-color: rgba(106, 17, 77, 0.5);
  background-image: none;
  border-color: rgba(106, 17, 77, 0.5);
}

.module-plum-60 {
  color: #fff;
  background: rgba(106, 17, 77, 0.6) linear-gradient(180deg, rgba(145, 84, 127, 0.66), rgba(106, 17, 77, 0.6)) repeat-x;
  border-color: rgba(106, 17, 77, 0.6);
}

.module-plum-60 .icon-container {
  background-color: rgba(73, 12, 53, 0.6);
}

.module-plum-60 .icon-container.light {
  background-color: rgba(172, 28, 125, 0.6);
}

.module-plum-60:hover {
  color: #fff;
  background: rgba(73, 12, 53, 0.6) linear-gradient(180deg, rgba(121, 80, 110, 0.66), rgba(73, 12, 53, 0.6)) repeat-x;
  border-color: color-yig(rgba(73, 12, 53, 0.6));
}

.module-plum-60:focus, .module-plum-60.focus {
  color: #fff;
  background: rgba(73, 12, 53, 0.6) linear-gradient(180deg, rgba(121, 80, 110, 0.66), rgba(73, 12, 53, 0.6)) repeat-x;
  border-color: color-yig(rgba(73, 12, 53, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(149, 86, 129, 0.5);
}

.module-plum-60:active, .module-plum-60.active,
.show > .module-plum-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 10, 45, 0.6);
  background-image: none;
  border-color: rgba(51, 8, 37, 0.6);
}

.module-plum-60:active:focus, .module-plum-60.active:focus,
.show > .module-plum-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 86, 129, 0.5);
}

.module-plum-60:disabled, .module-plum-60.disabled {
  color: #fff;
  background-color: rgba(106, 17, 77, 0.6);
  background-image: none;
  border-color: rgba(106, 17, 77, 0.6);
}

.module-plum-70 {
  color: #fff;
  background: rgba(106, 17, 77, 0.7) linear-gradient(180deg, rgba(139, 73, 120, 0.745), rgba(106, 17, 77, 0.7)) repeat-x;
  border-color: rgba(106, 17, 77, 0.7);
}

.module-plum-70 .icon-container {
  background-color: rgba(73, 12, 53, 0.7);
}

.module-plum-70 .icon-container.light {
  background-color: rgba(172, 28, 125, 0.7);
}

.module-plum-70:hover {
  color: #fff;
  background: rgba(73, 12, 53, 0.7) linear-gradient(180deg, rgba(114, 69, 102, 0.745), rgba(73, 12, 53, 0.7)) repeat-x;
  border-color: color-yig(rgba(73, 12, 53, 0.7));
}

.module-plum-70:focus, .module-plum-70.focus {
  color: #fff;
  background: rgba(73, 12, 53, 0.7) linear-gradient(180deg, rgba(114, 69, 102, 0.745), rgba(73, 12, 53, 0.7)) repeat-x;
  border-color: color-yig(rgba(73, 12, 53, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(143, 76, 121, 0.5);
}

.module-plum-70:active, .module-plum-70.active,
.show > .module-plum-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 10, 45, 0.7);
  background-image: none;
  border-color: rgba(51, 8, 37, 0.7);
}

.module-plum-70:active:focus, .module-plum-70.active:focus,
.show > .module-plum-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(143, 76, 121, 0.5);
}

.module-plum-70:disabled, .module-plum-70.disabled {
  color: #fff;
  background-color: rgba(106, 17, 77, 0.7);
  background-image: none;
  border-color: rgba(106, 17, 77, 0.7);
}

.module-plum-80 {
  color: #fff;
  background: rgba(106, 17, 77, 0.8) linear-gradient(180deg, rgba(134, 65, 113, 0.83), rgba(106, 17, 77, 0.8)) repeat-x;
  border-color: rgba(106, 17, 77, 0.8);
}

.module-plum-80 .icon-container {
  background-color: rgba(73, 12, 53, 0.8);
}

.module-plum-80 .icon-container.light {
  background-color: rgba(172, 28, 125, 0.8);
}

.module-plum-80:hover {
  color: #fff;
  background: rgba(73, 12, 53, 0.8) linear-gradient(180deg, rgba(108, 61, 94, 0.83), rgba(73, 12, 53, 0.8)) repeat-x;
  border-color: color-yig(rgba(73, 12, 53, 0.8));
}

.module-plum-80:focus, .module-plum-80.focus {
  color: #fff;
  background: rgba(73, 12, 53, 0.8) linear-gradient(180deg, rgba(108, 61, 94, 0.83), rgba(73, 12, 53, 0.8)) repeat-x;
  border-color: color-yig(rgba(73, 12, 53, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(137, 67, 114, 0.5);
}

.module-plum-80:active, .module-plum-80.active,
.show > .module-plum-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 10, 45, 0.8);
  background-image: none;
  border-color: rgba(51, 8, 37, 0.8);
}

.module-plum-80:active:focus, .module-plum-80.active:focus,
.show > .module-plum-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(137, 67, 114, 0.5);
}

.module-plum-80:disabled, .module-plum-80.disabled {
  color: #fff;
  background-color: rgba(106, 17, 77, 0.8);
  background-image: none;
  border-color: rgba(106, 17, 77, 0.8);
}

.module-plum-90 {
  color: #fff;
  background: rgba(106, 17, 77, 0.9) linear-gradient(180deg, rgba(129, 57, 108, 0.915), rgba(106, 17, 77, 0.9)) repeat-x;
  border-color: rgba(106, 17, 77, 0.9);
}

.module-plum-90 .icon-container {
  background-color: rgba(73, 12, 53, 0.9);
}

.module-plum-90 .icon-container.light {
  background-color: rgba(172, 28, 125, 0.9);
}

.module-plum-90:hover {
  color: #fff;
  background: rgba(73, 12, 53, 0.9) linear-gradient(180deg, rgba(102, 53, 88, 0.915), rgba(73, 12, 53, 0.9)) repeat-x;
  border-color: color-yig(rgba(73, 12, 53, 0.9));
}

.module-plum-90:focus, .module-plum-90.focus {
  color: #fff;
  background: rgba(73, 12, 53, 0.9) linear-gradient(180deg, rgba(102, 53, 88, 0.915), rgba(73, 12, 53, 0.9)) repeat-x;
  border-color: color-yig(rgba(73, 12, 53, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(132, 59, 109, 0.5);
}

.module-plum-90:active, .module-plum-90.active,
.show > .module-plum-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(62, 10, 45, 0.9);
  background-image: none;
  border-color: rgba(51, 8, 37, 0.9);
}

.module-plum-90:active:focus, .module-plum-90.active:focus,
.show > .module-plum-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(132, 59, 109, 0.5);
}

.module-plum-90:disabled, .module-plum-90.disabled {
  color: #fff;
  background-color: rgba(106, 17, 77, 0.9);
  background-image: none;
  border-color: rgba(106, 17, 77, 0.9);
}

.module-ruby {
  color: #fff;
  background: #9b0e6c linear-gradient(180deg, #a73181, #9b0e6c) repeat-x;
  border-color: #9b0e6c;
}

.module-ruby .icon-container {
  background-color: #780b54;
}

.module-ruby .icon-container.light {
  background-color: #e1149d;
}

.module-ruby:hover {
  color: #fff;
  background: #780b54 linear-gradient(180deg, #8a2e6d, #780b54) repeat-x;
  border-color: color-yig(#780b54);
}

.module-ruby:focus, .module-ruby.focus {
  color: #fff;
  background: #780b54 linear-gradient(180deg, #8a2e6d, #780b54) repeat-x;
  border-color: color-yig(#780b54);
  box-shadow: 0 0 0 0.2rem rgba(170, 50, 130, 0.5);
}

.module-ruby:active, .module-ruby.active,
.show > .module-ruby.dropdown-toggle {
  color: #fff;
  background-color: #6c0a4b;
  background-image: none;
  border-color: #610943;
}

.module-ruby:active:focus, .module-ruby.active:focus,
.show > .module-ruby.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(170, 50, 130, 0.5);
}

.module-ruby:disabled, .module-ruby.disabled {
  color: #fff;
  background-color: #9b0e6c;
  background-image: none;
  border-color: #9b0e6c;
}

.module-ruby-10 {
  color: #fff;
  background: rgba(155, 14, 108, 0.1) linear-gradient(180deg, rgba(219, 192, 217, 0.235), rgba(155, 14, 108, 0.1)) repeat-x;
  border-color: rgba(155, 14, 108, 0.1);
}

.module-ruby-10 .icon-container {
  background-color: rgba(120, 11, 84, 0.1);
}

.module-ruby-10 .icon-container.light {
  background-color: rgba(225, 20, 157, 0.1);
}

.module-ruby-10:hover {
  color: #fff;
  background: rgba(120, 11, 84, 0.1) linear-gradient(180deg, rgba(211, 191, 212, 0.235), rgba(120, 11, 84, 0.1)) repeat-x;
  border-color: color-yig(rgba(120, 11, 84, 0.1));
}

.module-ruby-10:focus, .module-ruby-10.focus {
  color: #fff;
  background: rgba(120, 11, 84, 0.1) linear-gradient(180deg, rgba(211, 191, 212, 0.235), rgba(120, 11, 84, 0.1)) repeat-x;
  border-color: color-yig(rgba(120, 11, 84, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(232, 200, 221, 0.5);
}

.module-ruby-10:active, .module-ruby-10.active,
.show > .module-ruby-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 10, 75, 0.1);
  background-image: none;
  border-color: rgba(97, 9, 67, 0.1);
}

.module-ruby-10:active:focus, .module-ruby-10.active:focus,
.show > .module-ruby-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 200, 221, 0.5);
}

.module-ruby-10:disabled, .module-ruby-10.disabled {
  color: #fff;
  background-color: rgba(155, 14, 108, 0.1);
  background-image: none;
  border-color: rgba(155, 14, 108, 0.1);
}

.module-ruby-20 {
  color: #fff;
  background: rgba(155, 14, 108, 0.2) linear-gradient(180deg, rgba(206, 156, 195, 0.32), rgba(155, 14, 108, 0.2)) repeat-x;
  border-color: rgba(155, 14, 108, 0.2);
}

.module-ruby-20 .icon-container {
  background-color: rgba(120, 11, 84, 0.2);
}

.module-ruby-20 .icon-container.light {
  background-color: rgba(225, 20, 157, 0.2);
}

.module-ruby-20:hover {
  color: #fff;
  background: rgba(120, 11, 84, 0.2) linear-gradient(180deg, rgba(192, 155, 186, 0.32), rgba(120, 11, 84, 0.2)) repeat-x;
  border-color: color-yig(rgba(120, 11, 84, 0.2));
}

.module-ruby-20:focus, .module-ruby-20.focus {
  color: #fff;
  background: rgba(120, 11, 84, 0.2) linear-gradient(180deg, rgba(192, 155, 186, 0.32), rgba(120, 11, 84, 0.2)) repeat-x;
  border-color: color-yig(rgba(120, 11, 84, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(216, 162, 198, 0.5);
}

.module-ruby-20:active, .module-ruby-20.active,
.show > .module-ruby-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 10, 75, 0.2);
  background-image: none;
  border-color: rgba(97, 9, 67, 0.2);
}

.module-ruby-20:active:focus, .module-ruby-20.active:focus,
.show > .module-ruby-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 162, 198, 0.5);
}

.module-ruby-20:disabled, .module-ruby-20.disabled {
  color: #fff;
  background-color: rgba(155, 14, 108, 0.2);
  background-image: none;
  border-color: rgba(155, 14, 108, 0.2);
}

.module-ruby-30 {
  color: #fff;
  background: rgba(155, 14, 108, 0.3) linear-gradient(180deg, rgba(197, 130, 179, 0.405), rgba(155, 14, 108, 0.3)) repeat-x;
  border-color: rgba(155, 14, 108, 0.3);
}

.module-ruby-30 .icon-container {
  background-color: rgba(120, 11, 84, 0.3);
}

.module-ruby-30 .icon-container.light {
  background-color: rgba(225, 20, 157, 0.3);
}

.module-ruby-30:hover {
  color: #fff;
  background: rgba(120, 11, 84, 0.3) linear-gradient(180deg, rgba(179, 128, 167, 0.405), rgba(120, 11, 84, 0.3)) repeat-x;
  border-color: color-yig(rgba(120, 11, 84, 0.3));
}

.module-ruby-30:focus, .module-ruby-30.focus {
  color: #fff;
  background: rgba(120, 11, 84, 0.3) linear-gradient(180deg, rgba(179, 128, 167, 0.405), rgba(120, 11, 84, 0.3)) repeat-x;
  border-color: color-yig(rgba(120, 11, 84, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(205, 135, 182, 0.5);
}

.module-ruby-30:active, .module-ruby-30.active,
.show > .module-ruby-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 10, 75, 0.3);
  background-image: none;
  border-color: rgba(97, 9, 67, 0.3);
}

.module-ruby-30:active:focus, .module-ruby-30.active:focus,
.show > .module-ruby-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(205, 135, 182, 0.5);
}

.module-ruby-30:disabled, .module-ruby-30.disabled {
  color: #fff;
  background-color: rgba(155, 14, 108, 0.3);
  background-image: none;
  border-color: rgba(155, 14, 108, 0.3);
}

.module-ruby-40 {
  color: #fff;
  background: rgba(155, 14, 108, 0.4) linear-gradient(180deg, rgba(189, 110, 167, 0.49), rgba(155, 14, 108, 0.4)) repeat-x;
  border-color: rgba(155, 14, 108, 0.4);
}

.module-ruby-40 .icon-container {
  background-color: rgba(120, 11, 84, 0.4);
}

.module-ruby-40 .icon-container.light {
  background-color: rgba(225, 20, 157, 0.4);
}

.module-ruby-40:hover {
  color: #fff;
  background: rgba(120, 11, 84, 0.4) linear-gradient(180deg, rgba(169, 108, 152, 0.49), rgba(120, 11, 84, 0.4)) repeat-x;
  border-color: color-yig(rgba(120, 11, 84, 0.4));
}

.module-ruby-40:focus, .module-ruby-40.focus {
  color: #fff;
  background: rgba(120, 11, 84, 0.4) linear-gradient(180deg, rgba(169, 108, 152, 0.49), rgba(120, 11, 84, 0.4)) repeat-x;
  border-color: color-yig(rgba(120, 11, 84, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(196, 114, 169, 0.5);
}

.module-ruby-40:active, .module-ruby-40.active,
.show > .module-ruby-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 10, 75, 0.4);
  background-image: none;
  border-color: rgba(97, 9, 67, 0.4);
}

.module-ruby-40:active:focus, .module-ruby-40.active:focus,
.show > .module-ruby-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 114, 169, 0.5);
}

.module-ruby-40:disabled, .module-ruby-40.disabled {
  color: #fff;
  background-color: rgba(155, 14, 108, 0.4);
  background-image: none;
  border-color: rgba(155, 14, 108, 0.4);
}

.module-ruby-50 {
  color: #fff;
  background: rgba(155, 14, 108, 0.5) linear-gradient(180deg, rgba(184, 94, 157, 0.575), rgba(155, 14, 108, 0.5)) repeat-x;
  border-color: rgba(155, 14, 108, 0.5);
}

.module-ruby-50 .icon-container {
  background-color: rgba(120, 11, 84, 0.5);
}

.module-ruby-50 .icon-container.light {
  background-color: rgba(225, 20, 157, 0.5);
}

.module-ruby-50:hover {
  color: #fff;
  background: rgba(120, 11, 84, 0.5) linear-gradient(180deg, rgba(161, 92, 141, 0.575), rgba(120, 11, 84, 0.5)) repeat-x;
  border-color: color-yig(rgba(120, 11, 84, 0.5));
}

.module-ruby-50:focus, .module-ruby-50.focus {
  color: #fff;
  background: rgba(120, 11, 84, 0.5) linear-gradient(180deg, rgba(161, 92, 141, 0.575), rgba(120, 11, 84, 0.5)) repeat-x;
  border-color: color-yig(rgba(120, 11, 84, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(190, 97, 159, 0.5);
}

.module-ruby-50:active, .module-ruby-50.active,
.show > .module-ruby-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 10, 75, 0.5);
  background-image: none;
  border-color: rgba(97, 9, 67, 0.5);
}

.module-ruby-50:active:focus, .module-ruby-50.active:focus,
.show > .module-ruby-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(190, 97, 159, 0.5);
}

.module-ruby-50:disabled, .module-ruby-50.disabled {
  color: #fff;
  background-color: rgba(155, 14, 108, 0.5);
  background-image: none;
  border-color: rgba(155, 14, 108, 0.5);
}

.module-ruby-60 {
  color: #fff;
  background: rgba(155, 14, 108, 0.6) linear-gradient(180deg, rgba(179, 81, 149, 0.66), rgba(155, 14, 108, 0.6)) repeat-x;
  border-color: rgba(155, 14, 108, 0.6);
}

.module-ruby-60 .icon-container {
  background-color: rgba(120, 11, 84, 0.6);
}

.module-ruby-60 .icon-container.light {
  background-color: rgba(225, 20, 157, 0.6);
}

.module-ruby-60:hover {
  color: #fff;
  background: rgba(120, 11, 84, 0.6) linear-gradient(180deg, rgba(154, 79, 132, 0.66), rgba(120, 11, 84, 0.6)) repeat-x;
  border-color: color-yig(rgba(120, 11, 84, 0.6));
}

.module-ruby-60:focus, .module-ruby-60.focus {
  color: #fff;
  background: rgba(120, 11, 84, 0.6) linear-gradient(180deg, rgba(154, 79, 132, 0.66), rgba(120, 11, 84, 0.6)) repeat-x;
  border-color: color-yig(rgba(120, 11, 84, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(184, 84, 151, 0.5);
}

.module-ruby-60:active, .module-ruby-60.active,
.show > .module-ruby-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 10, 75, 0.6);
  background-image: none;
  border-color: rgba(97, 9, 67, 0.6);
}

.module-ruby-60:active:focus, .module-ruby-60.active:focus,
.show > .module-ruby-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 84, 151, 0.5);
}

.module-ruby-60:disabled, .module-ruby-60.disabled {
  color: #fff;
  background-color: rgba(155, 14, 108, 0.6);
  background-image: none;
  border-color: rgba(155, 14, 108, 0.6);
}

.module-ruby-70 {
  color: #fff;
  background: rgba(155, 14, 108, 0.7) linear-gradient(180deg, rgba(175, 71, 143, 0.745), rgba(155, 14, 108, 0.7)) repeat-x;
  border-color: rgba(155, 14, 108, 0.7);
}

.module-ruby-70 .icon-container {
  background-color: rgba(120, 11, 84, 0.7);
}

.module-ruby-70 .icon-container.light {
  background-color: rgba(225, 20, 157, 0.7);
}

.module-ruby-70:hover {
  color: #fff;
  background: rgba(120, 11, 84, 0.7) linear-gradient(180deg, rgba(149, 69, 125, 0.745), rgba(120, 11, 84, 0.7)) repeat-x;
  border-color: color-yig(rgba(120, 11, 84, 0.7));
}

.module-ruby-70:focus, .module-ruby-70.focus {
  color: #fff;
  background: rgba(120, 11, 84, 0.7) linear-gradient(180deg, rgba(149, 69, 125, 0.745), rgba(120, 11, 84, 0.7)) repeat-x;
  border-color: color-yig(rgba(120, 11, 84, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(180, 73, 144, 0.5);
}

.module-ruby-70:active, .module-ruby-70.active,
.show > .module-ruby-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 10, 75, 0.7);
  background-image: none;
  border-color: rgba(97, 9, 67, 0.7);
}

.module-ruby-70:active:focus, .module-ruby-70.active:focus,
.show > .module-ruby-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(180, 73, 144, 0.5);
}

.module-ruby-70:disabled, .module-ruby-70.disabled {
  color: #fff;
  background-color: rgba(155, 14, 108, 0.7);
  background-image: none;
  border-color: rgba(155, 14, 108, 0.7);
}

.module-ruby-80 {
  color: #fff;
  background: rgba(155, 14, 108, 0.8) linear-gradient(180deg, rgba(172, 62, 138, 0.83), rgba(155, 14, 108, 0.8)) repeat-x;
  border-color: rgba(155, 14, 108, 0.8);
}

.module-ruby-80 .icon-container {
  background-color: rgba(120, 11, 84, 0.8);
}

.module-ruby-80 .icon-container.light {
  background-color: rgba(225, 20, 157, 0.8);
}

.module-ruby-80:hover {
  color: #fff;
  background: rgba(120, 11, 84, 0.8) linear-gradient(180deg, rgba(145, 60, 118, 0.83), rgba(120, 11, 84, 0.8)) repeat-x;
  border-color: color-yig(rgba(120, 11, 84, 0.8));
}

.module-ruby-80:focus, .module-ruby-80.focus {
  color: #fff;
  background: rgba(120, 11, 84, 0.8) linear-gradient(180deg, rgba(145, 60, 118, 0.83), rgba(120, 11, 84, 0.8)) repeat-x;
  border-color: color-yig(rgba(120, 11, 84, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(176, 64, 139, 0.5);
}

.module-ruby-80:active, .module-ruby-80.active,
.show > .module-ruby-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 10, 75, 0.8);
  background-image: none;
  border-color: rgba(97, 9, 67, 0.8);
}

.module-ruby-80:active:focus, .module-ruby-80.active:focus,
.show > .module-ruby-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(176, 64, 139, 0.5);
}

.module-ruby-80:disabled, .module-ruby-80.disabled {
  color: #fff;
  background-color: rgba(155, 14, 108, 0.8);
  background-image: none;
  border-color: rgba(155, 14, 108, 0.8);
}

.module-ruby-90 {
  color: #fff;
  background: rgba(155, 14, 108, 0.9) linear-gradient(180deg, rgba(170, 55, 133, 0.915), rgba(155, 14, 108, 0.9)) repeat-x;
  border-color: rgba(155, 14, 108, 0.9);
}

.module-ruby-90 .icon-container {
  background-color: rgba(120, 11, 84, 0.9);
}

.module-ruby-90 .icon-container.light {
  background-color: rgba(225, 20, 157, 0.9);
}

.module-ruby-90:hover {
  color: #fff;
  background: rgba(120, 11, 84, 0.9) linear-gradient(180deg, rgba(141, 52, 113, 0.915), rgba(120, 11, 84, 0.9)) repeat-x;
  border-color: color-yig(rgba(120, 11, 84, 0.9));
}

.module-ruby-90:focus, .module-ruby-90.focus {
  color: #fff;
  background: rgba(120, 11, 84, 0.9) linear-gradient(180deg, rgba(141, 52, 113, 0.915), rgba(120, 11, 84, 0.9)) repeat-x;
  border-color: color-yig(rgba(120, 11, 84, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(173, 57, 134, 0.5);
}

.module-ruby-90:active, .module-ruby-90.active,
.show > .module-ruby-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(108, 10, 75, 0.9);
  background-image: none;
  border-color: rgba(97, 9, 67, 0.9);
}

.module-ruby-90:active:focus, .module-ruby-90.active:focus,
.show > .module-ruby-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(173, 57, 134, 0.5);
}

.module-ruby-90:disabled, .module-ruby-90.disabled {
  color: #fff;
  background-color: rgba(155, 14, 108, 0.9);
  background-image: none;
  border-color: rgba(155, 14, 108, 0.9);
}

.module-berry {
  color: #fff;
  background: #d60590 linear-gradient(180deg, #da29a0, #d60590) repeat-x;
  border-color: #d60590;
}

.module-berry .icon-container {
  background-color: #b10477;
}

.module-berry .icon-container.light {
  background-color: #fa2db6;
}

.module-berry:hover {
  color: #fff;
  background: #b10477 linear-gradient(180deg, #ba288b, #b10477) repeat-x;
  border-color: color-yig(#b10477);
}

.module-berry:focus, .module-berry.focus {
  color: #fff;
  background: #b10477 linear-gradient(180deg, #ba288b, #b10477) repeat-x;
  border-color: color-yig(#b10477);
  box-shadow: 0 0 0 0.2rem rgba(220, 43, 161, 0.5);
}

.module-berry:active, .module-berry.active,
.show > .module-berry.dropdown-toggle {
  color: #fff;
  background-color: #a4046e;
  background-image: none;
  border-color: #980466;
}

.module-berry:active:focus, .module-berry.active:focus,
.show > .module-berry.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 43, 161, 0.5);
}

.module-berry:disabled, .module-berry.disabled {
  color: #fff;
  background-color: #d60590;
  background-image: none;
  border-color: #d60590;
}

.module-berry-10 {
  color: #fff;
  background: rgba(214, 5, 144, 0.1) linear-gradient(180deg, rgba(232, 190, 226, 0.235), rgba(214, 5, 144, 0.1)) repeat-x;
  border-color: rgba(214, 5, 144, 0.1);
}

.module-berry-10 .icon-container {
  background-color: rgba(177, 4, 119, 0.1);
}

.module-berry-10 .icon-container.light {
  background-color: rgba(250, 45, 182, 0.1);
}

.module-berry-10:hover {
  color: #fff;
  background: rgba(177, 4, 119, 0.1) linear-gradient(180deg, rgba(224, 190, 220, 0.235), rgba(177, 4, 119, 0.1)) repeat-x;
  border-color: color-yig(rgba(177, 4, 119, 0.1));
}

.module-berry-10:focus, .module-berry-10.focus {
  color: #fff;
  background: rgba(177, 4, 119, 0.1) linear-gradient(180deg, rgba(224, 190, 220, 0.235), rgba(177, 4, 119, 0.1)) repeat-x;
  border-color: color-yig(rgba(177, 4, 119, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(246, 198, 230, 0.5);
}

.module-berry-10:active, .module-berry-10.active,
.show > .module-berry-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(164, 4, 110, 0.1);
  background-image: none;
  border-color: rgba(152, 4, 102, 0.1);
}

.module-berry-10:active:focus, .module-berry-10.active:focus,
.show > .module-berry-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(246, 198, 230, 0.5);
}

.module-berry-10:disabled, .module-berry-10.disabled {
  color: #fff;
  background-color: rgba(214, 5, 144, 0.1);
  background-image: none;
  border-color: rgba(214, 5, 144, 0.1);
}

.module-berry-20 {
  color: #fff;
  background: rgba(214, 5, 144, 0.2) linear-gradient(180deg, rgba(229, 152, 209, 0.32), rgba(214, 5, 144, 0.2)) repeat-x;
  border-color: rgba(214, 5, 144, 0.2);
}

.module-berry-20 .icon-container {
  background-color: rgba(177, 4, 119, 0.2);
}

.module-berry-20 .icon-container.light {
  background-color: rgba(250, 45, 182, 0.2);
}

.module-berry-20:hover {
  color: #fff;
  background: rgba(177, 4, 119, 0.2) linear-gradient(180deg, rgba(214, 152, 199, 0.32), rgba(177, 4, 119, 0.2)) repeat-x;
  border-color: color-yig(rgba(177, 4, 119, 0.2));
}

.module-berry-20:focus, .module-berry-20.focus {
  color: #fff;
  background: rgba(177, 4, 119, 0.2) linear-gradient(180deg, rgba(214, 152, 199, 0.32), rgba(177, 4, 119, 0.2)) repeat-x;
  border-color: color-yig(rgba(177, 4, 119, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(239, 158, 212, 0.5);
}

.module-berry-20:active, .module-berry-20.active,
.show > .module-berry-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(164, 4, 110, 0.2);
  background-image: none;
  border-color: rgba(152, 4, 102, 0.2);
}

.module-berry-20:active:focus, .module-berry-20.active:focus,
.show > .module-berry-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 158, 212, 0.5);
}

.module-berry-20:disabled, .module-berry-20.disabled {
  color: #fff;
  background-color: rgba(214, 5, 144, 0.2);
  background-image: none;
  border-color: rgba(214, 5, 144, 0.2);
}

.module-berry-30 {
  color: #fff;
  background: rgba(214, 5, 144, 0.3) linear-gradient(180deg, rgba(226, 125, 197, 0.405), rgba(214, 5, 144, 0.3)) repeat-x;
  border-color: rgba(214, 5, 144, 0.3);
}

.module-berry-30 .icon-container {
  background-color: rgba(177, 4, 119, 0.3);
}

.module-berry-30 .icon-container.light {
  background-color: rgba(250, 45, 182, 0.3);
}

.module-berry-30:hover {
  color: #fff;
  background: rgba(177, 4, 119, 0.3) linear-gradient(180deg, rgba(207, 125, 184, 0.405), rgba(177, 4, 119, 0.3)) repeat-x;
  border-color: color-yig(rgba(177, 4, 119, 0.3));
}

.module-berry-30:focus, .module-berry-30.focus {
  color: #fff;
  background: rgba(177, 4, 119, 0.3) linear-gradient(180deg, rgba(207, 125, 184, 0.405), rgba(177, 4, 119, 0.3)) repeat-x;
  border-color: color-yig(rgba(177, 4, 119, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(235, 130, 200, 0.5);
}

.module-berry-30:active, .module-berry-30.active,
.show > .module-berry-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(164, 4, 110, 0.3);
  background-image: none;
  border-color: rgba(152, 4, 102, 0.3);
}

.module-berry-30:active:focus, .module-berry-30.active:focus,
.show > .module-berry-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 130, 200, 0.5);
}

.module-berry-30:disabled, .module-berry-30.disabled {
  color: #fff;
  background-color: rgba(214, 5, 144, 0.3);
  background-image: none;
  border-color: rgba(214, 5, 144, 0.3);
}

.module-berry-40 {
  color: #fff;
  background: rgba(214, 5, 144, 0.4) linear-gradient(180deg, rgba(224, 104, 188, 0.49), rgba(214, 5, 144, 0.4)) repeat-x;
  border-color: rgba(214, 5, 144, 0.4);
}

.module-berry-40 .icon-container {
  background-color: rgba(177, 4, 119, 0.4);
}

.module-berry-40 .icon-container.light {
  background-color: rgba(250, 45, 182, 0.4);
}

.module-berry-40:hover {
  color: #fff;
  background: rgba(177, 4, 119, 0.4) linear-gradient(180deg, rgba(202, 104, 173, 0.49), rgba(177, 4, 119, 0.4)) repeat-x;
  border-color: color-yig(rgba(177, 4, 119, 0.4));
}

.module-berry-40:focus, .module-berry-40.focus {
  color: #fff;
  background: rgba(177, 4, 119, 0.4) linear-gradient(180deg, rgba(202, 104, 173, 0.49), rgba(177, 4, 119, 0.4)) repeat-x;
  border-color: color-yig(rgba(177, 4, 119, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(231, 108, 190, 0.5);
}

.module-berry-40:active, .module-berry-40.active,
.show > .module-berry-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(164, 4, 110, 0.4);
  background-image: none;
  border-color: rgba(152, 4, 102, 0.4);
}

.module-berry-40:active:focus, .module-berry-40.active:focus,
.show > .module-berry-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 108, 190, 0.5);
}

.module-berry-40:disabled, .module-berry-40.disabled {
  color: #fff;
  background-color: rgba(214, 5, 144, 0.4);
  background-image: none;
  border-color: rgba(214, 5, 144, 0.4);
}

.module-berry-50 {
  color: #fff;
  background: rgba(214, 5, 144, 0.5) linear-gradient(180deg, rgba(222, 88, 181, 0.575), rgba(214, 5, 144, 0.5)) repeat-x;
  border-color: rgba(214, 5, 144, 0.5);
}

.module-berry-50 .icon-container {
  background-color: rgba(177, 4, 119, 0.5);
}

.module-berry-50 .icon-container.light {
  background-color: rgba(250, 45, 182, 0.5);
}

.module-berry-50:hover {
  color: #fff;
  background: rgba(177, 4, 119, 0.5) linear-gradient(180deg, rgba(198, 88, 164, 0.575), rgba(177, 4, 119, 0.5)) repeat-x;
  border-color: color-yig(rgba(177, 4, 119, 0.5));
}

.module-berry-50:focus, .module-berry-50.focus {
  color: #fff;
  background: rgba(177, 4, 119, 0.5) linear-gradient(180deg, rgba(198, 88, 164, 0.575), rgba(177, 4, 119, 0.5)) repeat-x;
  border-color: color-yig(rgba(177, 4, 119, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(228, 92, 182, 0.5);
}

.module-berry-50:active, .module-berry-50.active,
.show > .module-berry-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(164, 4, 110, 0.5);
  background-image: none;
  border-color: rgba(152, 4, 102, 0.5);
}

.module-berry-50:active:focus, .module-berry-50.active:focus,
.show > .module-berry-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 92, 182, 0.5);
}

.module-berry-50:disabled, .module-berry-50.disabled {
  color: #fff;
  background-color: rgba(214, 5, 144, 0.5);
  background-image: none;
  border-color: rgba(214, 5, 144, 0.5);
}

.module-berry-60 {
  color: #fff;
  background: rgba(214, 5, 144, 0.6) linear-gradient(180deg, rgba(221, 75, 175, 0.66), rgba(214, 5, 144, 0.6)) repeat-x;
  border-color: rgba(214, 5, 144, 0.6);
}

.module-berry-60 .icon-container {
  background-color: rgba(177, 4, 119, 0.6);
}

.module-berry-60 .icon-container.light {
  background-color: rgba(250, 45, 182, 0.6);
}

.module-berry-60:hover {
  color: #fff;
  background: rgba(177, 4, 119, 0.6) linear-gradient(180deg, rgba(195, 74, 157, 0.66), rgba(177, 4, 119, 0.6)) repeat-x;
  border-color: color-yig(rgba(177, 4, 119, 0.6));
}

.module-berry-60:focus, .module-berry-60.focus {
  color: #fff;
  background: rgba(177, 4, 119, 0.6) linear-gradient(180deg, rgba(195, 74, 157, 0.66), rgba(177, 4, 119, 0.6)) repeat-x;
  border-color: color-yig(rgba(177, 4, 119, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(226, 78, 176, 0.5);
}

.module-berry-60:active, .module-berry-60.active,
.show > .module-berry-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(164, 4, 110, 0.6);
  background-image: none;
  border-color: rgba(152, 4, 102, 0.6);
}

.module-berry-60:active:focus, .module-berry-60.active:focus,
.show > .module-berry-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(226, 78, 176, 0.5);
}

.module-berry-60:disabled, .module-berry-60.disabled {
  color: #fff;
  background-color: rgba(214, 5, 144, 0.6);
  background-image: none;
  border-color: rgba(214, 5, 144, 0.6);
}

.module-berry-70 {
  color: #fff;
  background: rgba(214, 5, 144, 0.7) linear-gradient(180deg, rgba(220, 64, 170, 0.745), rgba(214, 5, 144, 0.7)) repeat-x;
  border-color: rgba(214, 5, 144, 0.7);
}

.module-berry-70 .icon-container {
  background-color: rgba(177, 4, 119, 0.7);
}

.module-berry-70 .icon-container.light {
  background-color: rgba(250, 45, 182, 0.7);
}

.module-berry-70:hover {
  color: #fff;
  background: rgba(177, 4, 119, 0.7) linear-gradient(180deg, rgba(192, 64, 151, 0.745), rgba(177, 4, 119, 0.7)) repeat-x;
  border-color: color-yig(rgba(177, 4, 119, 0.7));
}

.module-berry-70:focus, .module-berry-70.focus {
  color: #fff;
  background: rgba(177, 4, 119, 0.7) linear-gradient(180deg, rgba(192, 64, 151, 0.745), rgba(177, 4, 119, 0.7)) repeat-x;
  border-color: color-yig(rgba(177, 4, 119, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(224, 67, 171, 0.5);
}

.module-berry-70:active, .module-berry-70.active,
.show > .module-berry-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(164, 4, 110, 0.7);
  background-image: none;
  border-color: rgba(152, 4, 102, 0.7);
}

.module-berry-70:active:focus, .module-berry-70.active:focus,
.show > .module-berry-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 67, 171, 0.5);
}

.module-berry-70:disabled, .module-berry-70.disabled {
  color: #fff;
  background-color: rgba(214, 5, 144, 0.7);
  background-image: none;
  border-color: rgba(214, 5, 144, 0.7);
}

.module-berry-80 {
  color: #fff;
  background: rgba(214, 5, 144, 0.8) linear-gradient(180deg, rgba(219, 55, 166, 0.83), rgba(214, 5, 144, 0.8)) repeat-x;
  border-color: rgba(214, 5, 144, 0.8);
}

.module-berry-80 .icon-container {
  background-color: rgba(177, 4, 119, 0.8);
}

.module-berry-80 .icon-container.light {
  background-color: rgba(250, 45, 182, 0.8);
}

.module-berry-80:hover {
  color: #fff;
  background: rgba(177, 4, 119, 0.8) linear-gradient(180deg, rgba(189, 55, 146, 0.83), rgba(177, 4, 119, 0.8)) repeat-x;
  border-color: color-yig(rgba(177, 4, 119, 0.8));
}

.module-berry-80:focus, .module-berry-80.focus {
  color: #fff;
  background: rgba(177, 4, 119, 0.8) linear-gradient(180deg, rgba(189, 55, 146, 0.83), rgba(177, 4, 119, 0.8)) repeat-x;
  border-color: color-yig(rgba(177, 4, 119, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(223, 57, 167, 0.5);
}

.module-berry-80:active, .module-berry-80.active,
.show > .module-berry-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(164, 4, 110, 0.8);
  background-image: none;
  border-color: rgba(152, 4, 102, 0.8);
}

.module-berry-80:active:focus, .module-berry-80.active:focus,
.show > .module-berry-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 57, 167, 0.5);
}

.module-berry-80:disabled, .module-berry-80.disabled {
  color: #fff;
  background-color: rgba(214, 5, 144, 0.8);
  background-image: none;
  border-color: rgba(214, 5, 144, 0.8);
}

.module-berry-90 {
  color: #fff;
  background: rgba(214, 5, 144, 0.9) linear-gradient(180deg, rgba(218, 48, 163, 0.915), rgba(214, 5, 144, 0.9)) repeat-x;
  border-color: rgba(214, 5, 144, 0.9);
}

.module-berry-90 .icon-container {
  background-color: rgba(177, 4, 119, 0.9);
}

.module-berry-90 .icon-container.light {
  background-color: rgba(250, 45, 182, 0.9);
}

.module-berry-90:hover {
  color: #fff;
  background: rgba(177, 4, 119, 0.9) linear-gradient(180deg, rgba(188, 47, 142, 0.915), rgba(177, 4, 119, 0.9)) repeat-x;
  border-color: color-yig(rgba(177, 4, 119, 0.9));
}

.module-berry-90:focus, .module-berry-90.focus {
  color: #fff;
  background: rgba(177, 4, 119, 0.9) linear-gradient(180deg, rgba(188, 47, 142, 0.915), rgba(177, 4, 119, 0.9)) repeat-x;
  border-color: color-yig(rgba(177, 4, 119, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(221, 49, 164, 0.5);
}

.module-berry-90:active, .module-berry-90.active,
.show > .module-berry-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(164, 4, 110, 0.9);
  background-image: none;
  border-color: rgba(152, 4, 102, 0.9);
}

.module-berry-90:active:focus, .module-berry-90.active:focus,
.show > .module-berry-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 49, 164, 0.5);
}

.module-berry-90:disabled, .module-berry-90.disabled {
  color: #fff;
  background-color: rgba(214, 5, 144, 0.9);
  background-image: none;
  border-color: rgba(214, 5, 144, 0.9);
}

.module-fuchsia {
  color: #fff;
  background: #ff46c1 linear-gradient(180deg, #fc60ca, #ff46c1) repeat-x;
  border-color: #ff46c1;
}

.module-fuchsia .icon-container {
  background-color: #ff20b4;
}

.module-fuchsia .icon-container.light {
  background-color: #ff93db;
}

.module-fuchsia:hover {
  color: #fff;
  background: #ff20b4 linear-gradient(180deg, #fc40bf, #ff20b4) repeat-x;
  border-color: color-yig(#ff20b4);
}

.module-fuchsia:focus, .module-fuchsia.focus {
  color: #fff;
  background: #ff20b4 linear-gradient(180deg, #fc40bf, #ff20b4) repeat-x;
  border-color: color-yig(#ff20b4);
  box-shadow: 0 0 0 0.2rem rgba(255, 98, 202, 0.5);
}

.module-fuchsia:active, .module-fuchsia.active,
.show > .module-fuchsia.dropdown-toggle {
  color: #fff;
  background-color: #ff13b0;
  background-image: none;
  border-color: #ff06ac;
}

.module-fuchsia:active:focus, .module-fuchsia.active:focus,
.show > .module-fuchsia.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 98, 202, 0.5);
}

.module-fuchsia:disabled, .module-fuchsia.disabled {
  color: #fff;
  background-color: #ff46c1;
  background-image: none;
  border-color: #ff46c1;
}

.module-fuchsia-10 {
  color: #fff;
  background: rgba(255, 70, 193, 0.1) linear-gradient(180deg, rgba(242, 205, 237, 0.235), rgba(255, 70, 193, 0.1)) repeat-x;
  border-color: rgba(255, 70, 193, 0.1);
}

.module-fuchsia-10 .icon-container {
  background-color: rgba(255, 32, 180, 0.1);
}

.module-fuchsia-10 .icon-container.light {
  background-color: rgba(255, 147, 219, 0.1);
}

.module-fuchsia-10:hover {
  color: #fff;
  background: rgba(255, 32, 180, 0.1) linear-gradient(180deg, rgba(242, 196, 234, 0.235), rgba(255, 32, 180, 0.1)) repeat-x;
  border-color: color-yig(rgba(255, 32, 180, 0.1));
}

.module-fuchsia-10:focus, .module-fuchsia-10.focus {
  color: #fff;
  background: rgba(255, 32, 180, 0.1) linear-gradient(180deg, rgba(242, 196, 234, 0.235), rgba(255, 32, 180, 0.1)) repeat-x;
  border-color: color-yig(rgba(255, 32, 180, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(255, 213, 241, 0.5);
}

.module-fuchsia-10:active, .module-fuchsia-10.active,
.show > .module-fuchsia-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 19, 176, 0.1);
  background-image: none;
  border-color: rgba(255, 6, 172, 0.1);
}

.module-fuchsia-10:active:focus, .module-fuchsia-10.active:focus,
.show > .module-fuchsia-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 213, 241, 0.5);
}

.module-fuchsia-10:disabled, .module-fuchsia-10.disabled {
  color: #fff;
  background-color: rgba(255, 70, 193, 0.1);
  background-image: none;
  border-color: rgba(255, 70, 193, 0.1);
}

.module-fuchsia-20 {
  color: #fff;
  background: rgba(255, 70, 193, 0.2) linear-gradient(180deg, rgba(245, 177, 228, 0.32), rgba(255, 70, 193, 0.2)) repeat-x;
  border-color: rgba(255, 70, 193, 0.2);
}

.module-fuchsia-20 .icon-container {
  background-color: rgba(255, 32, 180, 0.2);
}

.module-fuchsia-20 .icon-container.light {
  background-color: rgba(255, 147, 219, 0.2);
}

.module-fuchsia-20:hover {
  color: #fff;
  background: rgba(255, 32, 180, 0.2) linear-gradient(180deg, rgba(245, 163, 223, 0.32), rgba(255, 32, 180, 0.2)) repeat-x;
  border-color: color-yig(rgba(255, 32, 180, 0.2));
}

.module-fuchsia-20:focus, .module-fuchsia-20.focus {
  color: #fff;
  background: rgba(255, 32, 180, 0.2) linear-gradient(180deg, rgba(245, 163, 223, 0.32), rgba(255, 32, 180, 0.2)) repeat-x;
  border-color: color-yig(rgba(255, 32, 180, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(255, 184, 231, 0.5);
}

.module-fuchsia-20:active, .module-fuchsia-20.active,
.show > .module-fuchsia-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 19, 176, 0.2);
  background-image: none;
  border-color: rgba(255, 6, 172, 0.2);
}

.module-fuchsia-20:active:focus, .module-fuchsia-20.active:focus,
.show > .module-fuchsia-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 184, 231, 0.5);
}

.module-fuchsia-20:disabled, .module-fuchsia-20.disabled {
  color: #fff;
  background-color: rgba(255, 70, 193, 0.2);
  background-image: none;
  border-color: rgba(255, 70, 193, 0.2);
}

.module-fuchsia-30 {
  color: #fff;
  background: rgba(255, 70, 193, 0.3) linear-gradient(180deg, rgba(247, 158, 222, 0.405), rgba(255, 70, 193, 0.3)) repeat-x;
  border-color: rgba(255, 70, 193, 0.3);
}

.module-fuchsia-30 .icon-container {
  background-color: rgba(255, 32, 180, 0.3);
}

.module-fuchsia-30 .icon-container.light {
  background-color: rgba(255, 147, 219, 0.3);
}

.module-fuchsia-30:hover {
  color: #fff;
  background: rgba(255, 32, 180, 0.3) linear-gradient(180deg, rgba(247, 138, 215, 0.405), rgba(255, 32, 180, 0.3)) repeat-x;
  border-color: color-yig(rgba(255, 32, 180, 0.3));
}

.module-fuchsia-30:focus, .module-fuchsia-30.focus {
  color: #fff;
  background: rgba(255, 32, 180, 0.3) linear-gradient(180deg, rgba(247, 138, 215, 0.405), rgba(255, 32, 180, 0.3)) repeat-x;
  border-color: color-yig(rgba(255, 32, 180, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(255, 163, 224, 0.5);
}

.module-fuchsia-30:active, .module-fuchsia-30.active,
.show > .module-fuchsia-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 19, 176, 0.3);
  background-image: none;
  border-color: rgba(255, 6, 172, 0.3);
}

.module-fuchsia-30:active:focus, .module-fuchsia-30.active:focus,
.show > .module-fuchsia-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 163, 224, 0.5);
}

.module-fuchsia-30:disabled, .module-fuchsia-30.disabled {
  color: #fff;
  background-color: rgba(255, 70, 193, 0.3);
  background-image: none;
  border-color: rgba(255, 70, 193, 0.3);
}

.module-fuchsia-40 {
  color: #fff;
  background: rgba(255, 70, 193, 0.4) linear-gradient(180deg, rgba(248, 142, 217, 0.49), rgba(255, 70, 193, 0.4)) repeat-x;
  border-color: rgba(255, 70, 193, 0.4);
}

.module-fuchsia-40 .icon-container {
  background-color: rgba(255, 32, 180, 0.4);
}

.module-fuchsia-40 .icon-container.light {
  background-color: rgba(255, 147, 219, 0.4);
}

.module-fuchsia-40:hover {
  color: #fff;
  background: rgba(255, 32, 180, 0.4) linear-gradient(180deg, rgba(248, 120, 209, 0.49), rgba(255, 32, 180, 0.4)) repeat-x;
  border-color: color-yig(rgba(255, 32, 180, 0.4));
}

.module-fuchsia-40:focus, .module-fuchsia-40.focus {
  color: #fff;
  background: rgba(255, 32, 180, 0.4) linear-gradient(180deg, rgba(248, 120, 209, 0.49), rgba(255, 32, 180, 0.4)) repeat-x;
  border-color: color-yig(rgba(255, 32, 180, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(255, 147, 219, 0.5);
}

.module-fuchsia-40:active, .module-fuchsia-40.active,
.show > .module-fuchsia-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 19, 176, 0.4);
  background-image: none;
  border-color: rgba(255, 6, 172, 0.4);
}

.module-fuchsia-40:active:focus, .module-fuchsia-40.active:focus,
.show > .module-fuchsia-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 147, 219, 0.5);
}

.module-fuchsia-40:disabled, .module-fuchsia-40.disabled {
  color: #fff;
  background-color: rgba(255, 70, 193, 0.4);
  background-image: none;
  border-color: rgba(255, 70, 193, 0.4);
}

.module-fuchsia-50 {
  color: #fff;
  background: rgba(255, 70, 193, 0.5) linear-gradient(180deg, rgba(249, 131, 213, 0.575), rgba(255, 70, 193, 0.5)) repeat-x;
  border-color: rgba(255, 70, 193, 0.5);
}

.module-fuchsia-50 .icon-container {
  background-color: rgba(255, 32, 180, 0.5);
}

.module-fuchsia-50 .icon-container.light {
  background-color: rgba(255, 147, 219, 0.5);
}

.module-fuchsia-50:hover {
  color: #fff;
  background: rgba(255, 32, 180, 0.5) linear-gradient(180deg, rgba(249, 106, 204, 0.575), rgba(255, 32, 180, 0.5)) repeat-x;
  border-color: color-yig(rgba(255, 32, 180, 0.5));
}

.module-fuchsia-50:focus, .module-fuchsia-50.focus {
  color: #fff;
  background: rgba(255, 32, 180, 0.5) linear-gradient(180deg, rgba(249, 106, 204, 0.575), rgba(255, 32, 180, 0.5)) repeat-x;
  border-color: color-yig(rgba(255, 32, 180, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(255, 134, 214, 0.5);
}

.module-fuchsia-50:active, .module-fuchsia-50.active,
.show > .module-fuchsia-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 19, 176, 0.5);
  background-image: none;
  border-color: rgba(255, 6, 172, 0.5);
}

.module-fuchsia-50:active:focus, .module-fuchsia-50.active:focus,
.show > .module-fuchsia-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 134, 214, 0.5);
}

.module-fuchsia-50:disabled, .module-fuchsia-50.disabled {
  color: #fff;
  background-color: rgba(255, 70, 193, 0.5);
  background-image: none;
  border-color: rgba(255, 70, 193, 0.5);
}

.module-fuchsia-60 {
  color: #fff;
  background: rgba(255, 70, 193, 0.6) linear-gradient(180deg, rgba(250, 121, 210, 0.66), rgba(255, 70, 193, 0.6)) repeat-x;
  border-color: rgba(255, 70, 193, 0.6);
}

.module-fuchsia-60 .icon-container {
  background-color: rgba(255, 32, 180, 0.6);
}

.module-fuchsia-60 .icon-container.light {
  background-color: rgba(255, 147, 219, 0.6);
}

.module-fuchsia-60:hover {
  color: #fff;
  background: rgba(255, 32, 180, 0.6) linear-gradient(180deg, rgba(250, 94, 201, 0.66), rgba(255, 32, 180, 0.6)) repeat-x;
  border-color: color-yig(rgba(255, 32, 180, 0.6));
}

.module-fuchsia-60:focus, .module-fuchsia-60.focus {
  color: #fff;
  background: rgba(255, 32, 180, 0.6) linear-gradient(180deg, rgba(250, 94, 201, 0.66), rgba(255, 32, 180, 0.6)) repeat-x;
  border-color: color-yig(rgba(255, 32, 180, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(255, 124, 211, 0.5);
}

.module-fuchsia-60:active, .module-fuchsia-60.active,
.show > .module-fuchsia-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 19, 176, 0.6);
  background-image: none;
  border-color: rgba(255, 6, 172, 0.6);
}

.module-fuchsia-60:active:focus, .module-fuchsia-60.active:focus,
.show > .module-fuchsia-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 124, 211, 0.5);
}

.module-fuchsia-60:disabled, .module-fuchsia-60.disabled {
  color: #fff;
  background-color: rgba(255, 70, 193, 0.6);
  background-image: none;
  border-color: rgba(255, 70, 193, 0.6);
}

.module-fuchsia-70 {
  color: #fff;
  background: rgba(255, 70, 193, 0.7) linear-gradient(180deg, rgba(251, 113, 207, 0.745), rgba(255, 70, 193, 0.7)) repeat-x;
  border-color: rgba(255, 70, 193, 0.7);
}

.module-fuchsia-70 .icon-container {
  background-color: rgba(255, 32, 180, 0.7);
}

.module-fuchsia-70 .icon-container.light {
  background-color: rgba(255, 147, 219, 0.7);
}

.module-fuchsia-70:hover {
  color: #fff;
  background: rgba(255, 32, 180, 0.7) linear-gradient(180deg, rgba(251, 84, 197, 0.745), rgba(255, 32, 180, 0.7)) repeat-x;
  border-color: color-yig(rgba(255, 32, 180, 0.7));
}

.module-fuchsia-70:focus, .module-fuchsia-70.focus {
  color: #fff;
  background: rgba(255, 32, 180, 0.7) linear-gradient(180deg, rgba(251, 84, 197, 0.745), rgba(255, 32, 180, 0.7)) repeat-x;
  border-color: color-yig(rgba(255, 32, 180, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(255, 116, 208, 0.5);
}

.module-fuchsia-70:active, .module-fuchsia-70.active,
.show > .module-fuchsia-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 19, 176, 0.7);
  background-image: none;
  border-color: rgba(255, 6, 172, 0.7);
}

.module-fuchsia-70:active:focus, .module-fuchsia-70.active:focus,
.show > .module-fuchsia-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 116, 208, 0.5);
}

.module-fuchsia-70:disabled, .module-fuchsia-70.disabled {
  color: #fff;
  background-color: rgba(255, 70, 193, 0.7);
  background-image: none;
  border-color: rgba(255, 70, 193, 0.7);
}

.module-fuchsia-80 {
  color: #fff;
  background: rgba(255, 70, 193, 0.8) linear-gradient(180deg, rgba(251, 107, 205, 0.83), rgba(255, 70, 193, 0.8)) repeat-x;
  border-color: rgba(255, 70, 193, 0.8);
}

.module-fuchsia-80 .icon-container {
  background-color: rgba(255, 32, 180, 0.8);
}

.module-fuchsia-80 .icon-container.light {
  background-color: rgba(255, 147, 219, 0.8);
}

.module-fuchsia-80:hover {
  color: #fff;
  background: rgba(255, 32, 180, 0.8) linear-gradient(180deg, rgba(251, 76, 195, 0.83), rgba(255, 32, 180, 0.8)) repeat-x;
  border-color: color-yig(rgba(255, 32, 180, 0.8));
}

.module-fuchsia-80:focus, .module-fuchsia-80.focus {
  color: #fff;
  background: rgba(255, 32, 180, 0.8) linear-gradient(180deg, rgba(251, 76, 195, 0.83), rgba(255, 32, 180, 0.8)) repeat-x;
  border-color: color-yig(rgba(255, 32, 180, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(255, 109, 206, 0.5);
}

.module-fuchsia-80:active, .module-fuchsia-80.active,
.show > .module-fuchsia-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 19, 176, 0.8);
  background-image: none;
  border-color: rgba(255, 6, 172, 0.8);
}

.module-fuchsia-80:active:focus, .module-fuchsia-80.active:focus,
.show > .module-fuchsia-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 109, 206, 0.5);
}

.module-fuchsia-80:disabled, .module-fuchsia-80.disabled {
  color: #fff;
  background-color: rgba(255, 70, 193, 0.8);
  background-image: none;
  border-color: rgba(255, 70, 193, 0.8);
}

.module-fuchsia-90 {
  color: #fff;
  background: rgba(255, 70, 193, 0.9) linear-gradient(180deg, rgba(252, 101, 203, 0.915), rgba(255, 70, 193, 0.9)) repeat-x;
  border-color: rgba(255, 70, 193, 0.9);
}

.module-fuchsia-90 .icon-container {
  background-color: rgba(255, 32, 180, 0.9);
}

.module-fuchsia-90 .icon-container.light {
  background-color: rgba(255, 147, 219, 0.9);
}

.module-fuchsia-90:hover {
  color: #fff;
  background: rgba(255, 32, 180, 0.9) linear-gradient(180deg, rgba(252, 70, 193, 0.915), rgba(255, 32, 180, 0.9)) repeat-x;
  border-color: color-yig(rgba(255, 32, 180, 0.9));
}

.module-fuchsia-90:focus, .module-fuchsia-90.focus {
  color: #fff;
  background: rgba(255, 32, 180, 0.9) linear-gradient(180deg, rgba(252, 70, 193, 0.915), rgba(255, 32, 180, 0.9)) repeat-x;
  border-color: color-yig(rgba(255, 32, 180, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(255, 103, 204, 0.5);
}

.module-fuchsia-90:active, .module-fuchsia-90.active,
.show > .module-fuchsia-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 19, 176, 0.9);
  background-image: none;
  border-color: rgba(255, 6, 172, 0.9);
}

.module-fuchsia-90:active:focus, .module-fuchsia-90.active:focus,
.show > .module-fuchsia-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 103, 204, 0.5);
}

.module-fuchsia-90:disabled, .module-fuchsia-90.disabled {
  color: #fff;
  background-color: rgba(255, 70, 193, 0.9);
  background-image: none;
  border-color: rgba(255, 70, 193, 0.9);
}

.module-flamingo {
  color: #273a4d;
  background: #ffa1e0 linear-gradient(180deg, #fcaee4, #ffa1e0) repeat-x;
  border-color: #ffa1e0;
}

.module-flamingo .icon-container {
  background-color: #ff7bd3;
}

.module-flamingo .icon-container.light {
  background-color: #ffeef9;
}

.module-flamingo:hover {
  color: #273a4d;
  background: #ff7bd3 linear-gradient(180deg, #fc8dd9, #ff7bd3) repeat-x;
  border-color: color-yig(#ff7bd3);
}

.module-flamingo:focus, .module-flamingo.focus {
  color: #273a4d;
  background: #ff7bd3 linear-gradient(180deg, #fc8dd9, #ff7bd3) repeat-x;
  border-color: color-yig(#ff7bd3);
  box-shadow: 0 0 0 0.2rem rgba(223, 146, 202, 0.5);
}

.module-flamingo:active, .module-flamingo.active,
.show > .module-flamingo.dropdown-toggle {
  color: #273a4d;
  background-color: #ff6ecf;
  background-image: none;
  border-color: #ff61cb;
}

.module-flamingo:active:focus, .module-flamingo.active:focus,
.show > .module-flamingo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 146, 202, 0.5);
}

.module-flamingo:disabled, .module-flamingo.disabled {
  color: #273a4d;
  background-color: #ffa1e0;
  background-image: none;
  border-color: #ffa1e0;
}

.module-flamingo-10 {
  color: #273a4d;
  background: rgba(255, 161, 224, 0.1) linear-gradient(180deg, rgba(242, 226, 244, 0.235), rgba(255, 161, 224, 0.1)) repeat-x;
  border-color: rgba(255, 161, 224, 0.1);
}

.module-flamingo-10 .icon-container {
  background-color: rgba(255, 123, 211, 0.1);
}

.module-flamingo-10 .icon-container.light {
  background-color: rgba(255, 238, 249, 0.1);
}

.module-flamingo-10:hover {
  color: #273a4d;
  background: rgba(255, 123, 211, 0.1) linear-gradient(180deg, rgba(242, 217, 241, 0.235), rgba(255, 123, 211, 0.1)) repeat-x;
  border-color: color-yig(rgba(255, 123, 211, 0.1));
}

.module-flamingo-10:focus, .module-flamingo-10.focus {
  color: #273a4d;
  background: rgba(255, 123, 211, 0.1) linear-gradient(180deg, rgba(242, 217, 241, 0.235), rgba(255, 123, 211, 0.1)) repeat-x;
  border-color: color-yig(rgba(255, 123, 211, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(89, 82, 111, 0.5);
}

.module-flamingo-10:active, .module-flamingo-10.active,
.show > .module-flamingo-10.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 110, 207, 0.1);
  background-image: none;
  border-color: rgba(255, 97, 203, 0.1);
}

.module-flamingo-10:active:focus, .module-flamingo-10.active:focus,
.show > .module-flamingo-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 82, 111, 0.5);
}

.module-flamingo-10:disabled, .module-flamingo-10.disabled {
  color: #273a4d;
  background-color: rgba(255, 161, 224, 0.1);
  background-image: none;
  border-color: rgba(255, 161, 224, 0.1);
}

.module-flamingo-20 {
  color: #273a4d;
  background: rgba(255, 161, 224, 0.2) linear-gradient(180deg, rgba(245, 213, 240, 0.32), rgba(255, 161, 224, 0.2)) repeat-x;
  border-color: rgba(255, 161, 224, 0.2);
}

.module-flamingo-20 .icon-container {
  background-color: rgba(255, 123, 211, 0.2);
}

.module-flamingo-20 .icon-container.light {
  background-color: rgba(255, 238, 249, 0.2);
}

.module-flamingo-20:hover {
  color: #273a4d;
  background: rgba(255, 123, 211, 0.2) linear-gradient(180deg, rgba(245, 198, 235, 0.32), rgba(255, 123, 211, 0.2)) repeat-x;
  border-color: color-yig(rgba(255, 123, 211, 0.2));
}

.module-flamingo-20:focus, .module-flamingo-20.focus {
  color: #273a4d;
  background: rgba(255, 123, 211, 0.2) linear-gradient(180deg, rgba(245, 198, 235, 0.32), rgba(255, 123, 211, 0.2)) repeat-x;
  border-color: color-yig(rgba(255, 123, 211, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(122, 98, 134, 0.5);
}

.module-flamingo-20:active, .module-flamingo-20.active,
.show > .module-flamingo-20.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 110, 207, 0.2);
  background-image: none;
  border-color: rgba(255, 97, 203, 0.2);
}

.module-flamingo-20:active:focus, .module-flamingo-20.active:focus,
.show > .module-flamingo-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 98, 134, 0.5);
}

.module-flamingo-20:disabled, .module-flamingo-20.disabled {
  color: #273a4d;
  background-color: rgba(255, 161, 224, 0.2);
  background-image: none;
  border-color: rgba(255, 161, 224, 0.2);
}

.module-flamingo-30 {
  color: #273a4d;
  background: rgba(255, 161, 224, 0.3) linear-gradient(180deg, rgba(247, 203, 237, 0.405), rgba(255, 161, 224, 0.3)) repeat-x;
  border-color: rgba(255, 161, 224, 0.3);
}

.module-flamingo-30 .icon-container {
  background-color: rgba(255, 123, 211, 0.3);
}

.module-flamingo-30 .icon-container.light {
  background-color: rgba(255, 238, 249, 0.3);
}

.module-flamingo-30:hover {
  color: #273a4d;
  background: rgba(255, 123, 211, 0.3) linear-gradient(180deg, rgba(247, 184, 231, 0.405), rgba(255, 123, 211, 0.3)) repeat-x;
  border-color: color-yig(rgba(255, 123, 211, 0.3));
}

.module-flamingo-30:focus, .module-flamingo-30.focus {
  color: #273a4d;
  background: rgba(255, 123, 211, 0.3) linear-gradient(180deg, rgba(247, 184, 231, 0.405), rgba(255, 123, 211, 0.3)) repeat-x;
  border-color: color-yig(rgba(255, 123, 211, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(147, 110, 151, 0.5);
}

.module-flamingo-30:active, .module-flamingo-30.active,
.show > .module-flamingo-30.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 110, 207, 0.3);
  background-image: none;
  border-color: rgba(255, 97, 203, 0.3);
}

.module-flamingo-30:active:focus, .module-flamingo-30.active:focus,
.show > .module-flamingo-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 110, 151, 0.5);
}

.module-flamingo-30:disabled, .module-flamingo-30.disabled {
  color: #273a4d;
  background-color: rgba(255, 161, 224, 0.3);
  background-image: none;
  border-color: rgba(255, 161, 224, 0.3);
}

.module-flamingo-40 {
  color: #273a4d;
  background: rgba(255, 161, 224, 0.4) linear-gradient(180deg, rgba(248, 196, 235, 0.49), rgba(255, 161, 224, 0.4)) repeat-x;
  border-color: rgba(255, 161, 224, 0.4);
}

.module-flamingo-40 .icon-container {
  background-color: rgba(255, 123, 211, 0.4);
}

.module-flamingo-40 .icon-container.light {
  background-color: rgba(255, 238, 249, 0.4);
}

.module-flamingo-40:hover {
  color: #273a4d;
  background: rgba(255, 123, 211, 0.4) linear-gradient(180deg, rgba(248, 173, 227, 0.49), rgba(255, 123, 211, 0.4)) repeat-x;
  border-color: color-yig(rgba(255, 123, 211, 0.4));
}

.module-flamingo-40:focus, .module-flamingo-40.focus {
  color: #273a4d;
  background: rgba(255, 123, 211, 0.4) linear-gradient(180deg, rgba(248, 173, 227, 0.49), rgba(255, 123, 211, 0.4)) repeat-x;
  border-color: color-yig(rgba(255, 123, 211, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(166, 118, 163, 0.5);
}

.module-flamingo-40:active, .module-flamingo-40.active,
.show > .module-flamingo-40.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 110, 207, 0.4);
  background-image: none;
  border-color: rgba(255, 97, 203, 0.4);
}

.module-flamingo-40:active:focus, .module-flamingo-40.active:focus,
.show > .module-flamingo-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 118, 163, 0.5);
}

.module-flamingo-40:disabled, .module-flamingo-40.disabled {
  color: #273a4d;
  background-color: rgba(255, 161, 224, 0.4);
  background-image: none;
  border-color: rgba(255, 161, 224, 0.4);
}

.module-flamingo-50 {
  color: #273a4d;
  background: rgba(255, 161, 224, 0.5) linear-gradient(180deg, rgba(249, 190, 233, 0.575), rgba(255, 161, 224, 0.5)) repeat-x;
  border-color: rgba(255, 161, 224, 0.5);
}

.module-flamingo-50 .icon-container {
  background-color: rgba(255, 123, 211, 0.5);
}

.module-flamingo-50 .icon-container.light {
  background-color: rgba(255, 238, 249, 0.5);
}

.module-flamingo-50:hover {
  color: #273a4d;
  background: rgba(255, 123, 211, 0.5) linear-gradient(180deg, rgba(249, 165, 225, 0.575), rgba(255, 123, 211, 0.5)) repeat-x;
  border-color: color-yig(rgba(255, 123, 211, 0.5));
}

.module-flamingo-50:focus, .module-flamingo-50.focus {
  color: #273a4d;
  background: rgba(255, 123, 211, 0.5) linear-gradient(180deg, rgba(249, 165, 225, 0.575), rgba(255, 123, 211, 0.5)) repeat-x;
  border-color: color-yig(rgba(255, 123, 211, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(180, 125, 173, 0.5);
}

.module-flamingo-50:active, .module-flamingo-50.active,
.show > .module-flamingo-50.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 110, 207, 0.5);
  background-image: none;
  border-color: rgba(255, 97, 203, 0.5);
}

.module-flamingo-50:active:focus, .module-flamingo-50.active:focus,
.show > .module-flamingo-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(180, 125, 173, 0.5);
}

.module-flamingo-50:disabled, .module-flamingo-50.disabled {
  color: #273a4d;
  background-color: rgba(255, 161, 224, 0.5);
  background-image: none;
  border-color: rgba(255, 161, 224, 0.5);
}

.module-flamingo-60 {
  color: #273a4d;
  background: rgba(255, 161, 224, 0.6) linear-gradient(180deg, rgba(250, 186, 232, 0.66), rgba(255, 161, 224, 0.6)) repeat-x;
  border-color: rgba(255, 161, 224, 0.6);
}

.module-flamingo-60 .icon-container {
  background-color: rgba(255, 123, 211, 0.6);
}

.module-flamingo-60 .icon-container.light {
  background-color: rgba(255, 238, 249, 0.6);
}

.module-flamingo-60:hover {
  color: #273a4d;
  background: rgba(255, 123, 211, 0.6) linear-gradient(180deg, rgba(250, 158, 223, 0.66), rgba(255, 123, 211, 0.6)) repeat-x;
  border-color: color-yig(rgba(255, 123, 211, 0.6));
}

.module-flamingo-60:focus, .module-flamingo-60.focus {
  color: #273a4d;
  background: rgba(255, 123, 211, 0.6) linear-gradient(180deg, rgba(250, 158, 223, 0.66), rgba(255, 123, 211, 0.6)) repeat-x;
  border-color: color-yig(rgba(255, 123, 211, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(192, 131, 181, 0.5);
}

.module-flamingo-60:active, .module-flamingo-60.active,
.show > .module-flamingo-60.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 110, 207, 0.6);
  background-image: none;
  border-color: rgba(255, 97, 203, 0.6);
}

.module-flamingo-60:active:focus, .module-flamingo-60.active:focus,
.show > .module-flamingo-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(192, 131, 181, 0.5);
}

.module-flamingo-60:disabled, .module-flamingo-60.disabled {
  color: #273a4d;
  background-color: rgba(255, 161, 224, 0.6);
  background-image: none;
  border-color: rgba(255, 161, 224, 0.6);
}

.module-flamingo-70 {
  color: #273a4d;
  background: rgba(255, 161, 224, 0.7) linear-gradient(180deg, rgba(251, 182, 230, 0.745), rgba(255, 161, 224, 0.7)) repeat-x;
  border-color: rgba(255, 161, 224, 0.7);
}

.module-flamingo-70 .icon-container {
  background-color: rgba(255, 123, 211, 0.7);
}

.module-flamingo-70 .icon-container.light {
  background-color: rgba(255, 238, 249, 0.7);
}

.module-flamingo-70:hover {
  color: #273a4d;
  background: rgba(255, 123, 211, 0.7) linear-gradient(180deg, rgba(251, 153, 221, 0.745), rgba(255, 123, 211, 0.7)) repeat-x;
  border-color: color-yig(rgba(255, 123, 211, 0.7));
}

.module-flamingo-70:focus, .module-flamingo-70.focus {
  color: #273a4d;
  background: rgba(255, 123, 211, 0.7) linear-gradient(180deg, rgba(251, 153, 221, 0.745), rgba(255, 123, 211, 0.7)) repeat-x;
  border-color: color-yig(rgba(255, 123, 211, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(202, 136, 188, 0.5);
}

.module-flamingo-70:active, .module-flamingo-70.active,
.show > .module-flamingo-70.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 110, 207, 0.7);
  background-image: none;
  border-color: rgba(255, 97, 203, 0.7);
}

.module-flamingo-70:active:focus, .module-flamingo-70.active:focus,
.show > .module-flamingo-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(202, 136, 188, 0.5);
}

.module-flamingo-70:disabled, .module-flamingo-70.disabled {
  color: #273a4d;
  background-color: rgba(255, 161, 224, 0.7);
  background-image: none;
  border-color: rgba(255, 161, 224, 0.7);
}

.module-flamingo-80 {
  color: #273a4d;
  background: rgba(255, 161, 224, 0.8) linear-gradient(180deg, rgba(251, 179, 229, 0.83), rgba(255, 161, 224, 0.8)) repeat-x;
  border-color: rgba(255, 161, 224, 0.8);
}

.module-flamingo-80 .icon-container {
  background-color: rgba(255, 123, 211, 0.8);
}

.module-flamingo-80 .icon-container.light {
  background-color: rgba(255, 238, 249, 0.8);
}

.module-flamingo-80:hover {
  color: #273a4d;
  background: rgba(255, 123, 211, 0.8) linear-gradient(180deg, rgba(251, 148, 219, 0.83), rgba(255, 123, 211, 0.8)) repeat-x;
  border-color: color-yig(rgba(255, 123, 211, 0.8));
}

.module-flamingo-80:focus, .module-flamingo-80.focus {
  color: #273a4d;
  background: rgba(255, 123, 211, 0.8) linear-gradient(180deg, rgba(251, 148, 219, 0.83), rgba(255, 123, 211, 0.8)) repeat-x;
  border-color: color-yig(rgba(255, 123, 211, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(210, 139, 193, 0.5);
}

.module-flamingo-80:active, .module-flamingo-80.active,
.show > .module-flamingo-80.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 110, 207, 0.8);
  background-image: none;
  border-color: rgba(255, 97, 203, 0.8);
}

.module-flamingo-80:active:focus, .module-flamingo-80.active:focus,
.show > .module-flamingo-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 139, 193, 0.5);
}

.module-flamingo-80:disabled, .module-flamingo-80.disabled {
  color: #273a4d;
  background-color: rgba(255, 161, 224, 0.8);
  background-image: none;
  border-color: rgba(255, 161, 224, 0.8);
}

.module-flamingo-90 {
  color: #273a4d;
  background: rgba(255, 161, 224, 0.9) linear-gradient(180deg, rgba(252, 176, 229, 0.915), rgba(255, 161, 224, 0.9)) repeat-x;
  border-color: rgba(255, 161, 224, 0.9);
}

.module-flamingo-90 .icon-container {
  background-color: rgba(255, 123, 211, 0.9);
}

.module-flamingo-90 .icon-container.light {
  background-color: rgba(255, 238, 249, 0.9);
}

.module-flamingo-90:hover {
  color: #273a4d;
  background: rgba(255, 123, 211, 0.9) linear-gradient(180deg, rgba(252, 144, 218, 0.915), rgba(255, 123, 211, 0.9)) repeat-x;
  border-color: color-yig(rgba(255, 123, 211, 0.9));
}

.module-flamingo-90:focus, .module-flamingo-90.focus {
  color: #273a4d;
  background: rgba(255, 123, 211, 0.9) linear-gradient(180deg, rgba(252, 144, 218, 0.915), rgba(255, 123, 211, 0.9)) repeat-x;
  border-color: color-yig(rgba(255, 123, 211, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(217, 143, 198, 0.5);
}

.module-flamingo-90:active, .module-flamingo-90.active,
.show > .module-flamingo-90.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(255, 110, 207, 0.9);
  background-image: none;
  border-color: rgba(255, 97, 203, 0.9);
}

.module-flamingo-90:active:focus, .module-flamingo-90.active:focus,
.show > .module-flamingo-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 143, 198, 0.5);
}

.module-flamingo-90:disabled, .module-flamingo-90.disabled {
  color: #273a4d;
  background-color: rgba(255, 161, 224, 0.9);
  background-image: none;
  border-color: rgba(255, 161, 224, 0.9);
}

.module-raisin {
  color: #fff;
  background: #371c40 linear-gradient(180deg, #523d5c, #371c40) repeat-x;
  border-color: #371c40;
}

.module-raisin .icon-container {
  background-color: #201025;
}

.module-raisin .icon-container.light {
  background-color: #653375;
}

.module-raisin:hover {
  color: #fff;
  background: #201025 linear-gradient(180deg, #3f3345, #201025) repeat-x;
  border-color: color-yig(#201025);
}

.module-raisin:focus, .module-raisin.focus {
  color: #fff;
  background: #201025 linear-gradient(180deg, #3f3345, #201025) repeat-x;
  border-color: color-yig(#201025);
  box-shadow: 0 0 0 0.2rem rgba(85, 62, 93, 0.5);
}

.module-raisin:active, .module-raisin.active,
.show > .module-raisin.dropdown-toggle {
  color: #fff;
  background-color: #190c1d;
  background-image: none;
  border-color: #110914;
}

.module-raisin:active:focus, .module-raisin.active:focus,
.show > .module-raisin.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(85, 62, 93, 0.5);
}

.module-raisin:disabled, .module-raisin.disabled {
  color: #fff;
  background-color: #371c40;
  background-image: none;
  border-color: #371c40;
}

.module-raisin-10 {
  color: #fff;
  background: rgba(55, 28, 64, 0.1) linear-gradient(180deg, rgba(196, 195, 207, 0.235), rgba(55, 28, 64, 0.1)) repeat-x;
  border-color: rgba(55, 28, 64, 0.1);
}

.module-raisin-10 .icon-container {
  background-color: rgba(32, 16, 37, 0.1);
}

.module-raisin-10 .icon-container.light {
  background-color: rgba(101, 51, 117, 0.1);
}

.module-raisin-10:hover {
  color: #fff;
  background: rgba(32, 16, 37, 0.1) linear-gradient(180deg, rgba(191, 192, 201, 0.235), rgba(32, 16, 37, 0.1)) repeat-x;
  border-color: color-yig(rgba(32, 16, 37, 0.1));
}

.module-raisin-10:focus, .module-raisin-10.focus {
  color: #fff;
  background: rgba(32, 16, 37, 0.1) linear-gradient(180deg, rgba(191, 192, 201, 0.235), rgba(32, 16, 37, 0.1)) repeat-x;
  border-color: color-yig(rgba(32, 16, 37, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(209, 203, 211, 0.5);
}

.module-raisin-10:active, .module-raisin-10.active,
.show > .module-raisin-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(25, 12, 29, 0.1);
  background-image: none;
  border-color: rgba(17, 9, 20, 0.1);
}

.module-raisin-10:active:focus, .module-raisin-10.active:focus,
.show > .module-raisin-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 203, 211, 0.5);
}

.module-raisin-10:disabled, .module-raisin-10.disabled {
  color: #fff;
  background-color: rgba(55, 28, 64, 0.1);
  background-image: none;
  border-color: rgba(55, 28, 64, 0.1);
}

.module-raisin-20 {
  color: #fff;
  background: rgba(55, 28, 64, 0.2) linear-gradient(180deg, rgba(167, 161, 178, 0.32), rgba(55, 28, 64, 0.2)) repeat-x;
  border-color: rgba(55, 28, 64, 0.2);
}

.module-raisin-20 .icon-container {
  background-color: rgba(32, 16, 37, 0.2);
}

.module-raisin-20 .icon-container.light {
  background-color: rgba(101, 51, 117, 0.2);
}

.module-raisin-20:hover {
  color: #fff;
  background: rgba(32, 16, 37, 0.2) linear-gradient(180deg, rgba(158, 157, 168, 0.32), rgba(32, 16, 37, 0.2)) repeat-x;
  border-color: color-yig(rgba(32, 16, 37, 0.2));
}

.module-raisin-20:focus, .module-raisin-20.focus {
  color: #fff;
  background: rgba(32, 16, 37, 0.2) linear-gradient(180deg, rgba(158, 157, 168, 0.32), rgba(32, 16, 37, 0.2)) repeat-x;
  border-color: color-yig(rgba(32, 16, 37, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(178, 167, 181, 0.5);
}

.module-raisin-20:active, .module-raisin-20.active,
.show > .module-raisin-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(25, 12, 29, 0.2);
  background-image: none;
  border-color: rgba(17, 9, 20, 0.2);
}

.module-raisin-20:active:focus, .module-raisin-20.active:focus,
.show > .module-raisin-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(178, 167, 181, 0.5);
}

.module-raisin-20:disabled, .module-raisin-20.disabled {
  color: #fff;
  background-color: rgba(55, 28, 64, 0.2);
  background-image: none;
  border-color: rgba(55, 28, 64, 0.2);
}

.module-raisin-30 {
  color: #fff;
  background: rgba(55, 28, 64, 0.3) linear-gradient(180deg, rgba(147, 137, 157, 0.405), rgba(55, 28, 64, 0.3)) repeat-x;
  border-color: rgba(55, 28, 64, 0.3);
}

.module-raisin-30 .icon-container {
  background-color: rgba(32, 16, 37, 0.3);
}

.module-raisin-30 .icon-container.light {
  background-color: rgba(101, 51, 117, 0.3);
}

.module-raisin-30:hover {
  color: #fff;
  background: rgba(32, 16, 37, 0.3) linear-gradient(180deg, rgba(135, 131, 144, 0.405), rgba(32, 16, 37, 0.3)) repeat-x;
  border-color: color-yig(rgba(32, 16, 37, 0.3));
}

.module-raisin-30:focus, .module-raisin-30.focus {
  color: #fff;
  background: rgba(32, 16, 37, 0.3) linear-gradient(180deg, rgba(135, 131, 144, 0.405), rgba(32, 16, 37, 0.3)) repeat-x;
  border-color: color-yig(rgba(32, 16, 37, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(155, 142, 160, 0.5);
}

.module-raisin-30:active, .module-raisin-30.active,
.show > .module-raisin-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(25, 12, 29, 0.3);
  background-image: none;
  border-color: rgba(17, 9, 20, 0.3);
}

.module-raisin-30:active:focus, .module-raisin-30.active:focus,
.show > .module-raisin-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(155, 142, 160, 0.5);
}

.module-raisin-30:disabled, .module-raisin-30.disabled {
  color: #fff;
  background-color: rgba(55, 28, 64, 0.3);
  background-image: none;
  border-color: rgba(55, 28, 64, 0.3);
}

.module-raisin-40 {
  color: #fff;
  background: rgba(55, 28, 64, 0.4) linear-gradient(180deg, rgba(131, 118, 141, 0.49), rgba(55, 28, 64, 0.4)) repeat-x;
  border-color: rgba(55, 28, 64, 0.4);
}

.module-raisin-40 .icon-container {
  background-color: rgba(32, 16, 37, 0.4);
}

.module-raisin-40 .icon-container.light {
  background-color: rgba(101, 51, 117, 0.4);
}

.module-raisin-40:hover {
  color: #fff;
  background: rgba(32, 16, 37, 0.4) linear-gradient(180deg, rgba(117, 111, 125, 0.49), rgba(32, 16, 37, 0.4)) repeat-x;
  border-color: color-yig(rgba(32, 16, 37, 0.4));
}

.module-raisin-40:focus, .module-raisin-40.focus {
  color: #fff;
  background: rgba(32, 16, 37, 0.4) linear-gradient(180deg, rgba(117, 111, 125, 0.49), rgba(32, 16, 37, 0.4)) repeat-x;
  border-color: color-yig(rgba(32, 16, 37, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(138, 122, 143, 0.5);
}

.module-raisin-40:active, .module-raisin-40.active,
.show > .module-raisin-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(25, 12, 29, 0.4);
  background-image: none;
  border-color: rgba(17, 9, 20, 0.4);
}

.module-raisin-40:active:focus, .module-raisin-40.active:focus,
.show > .module-raisin-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(138, 122, 143, 0.5);
}

.module-raisin-40:disabled, .module-raisin-40.disabled {
  color: #fff;
  background-color: rgba(55, 28, 64, 0.4);
  background-image: none;
  border-color: rgba(55, 28, 64, 0.4);
}

.module-raisin-50 {
  color: #fff;
  background: rgba(55, 28, 64, 0.5) linear-gradient(180deg, rgba(118, 103, 128, 0.575), rgba(55, 28, 64, 0.5)) repeat-x;
  border-color: rgba(55, 28, 64, 0.5);
}

.module-raisin-50 .icon-container {
  background-color: rgba(32, 16, 37, 0.5);
}

.module-raisin-50 .icon-container.light {
  background-color: rgba(101, 51, 117, 0.5);
}

.module-raisin-50:hover {
  color: #fff;
  background: rgba(32, 16, 37, 0.5) linear-gradient(180deg, rgba(103, 96, 111, 0.575), rgba(32, 16, 37, 0.5)) repeat-x;
  border-color: color-yig(rgba(32, 16, 37, 0.5));
}

.module-raisin-50:focus, .module-raisin-50.focus {
  color: #fff;
  background: rgba(32, 16, 37, 0.5) linear-gradient(180deg, rgba(103, 96, 111, 0.575), rgba(32, 16, 37, 0.5)) repeat-x;
  border-color: color-yig(rgba(32, 16, 37, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(124, 107, 130, 0.5);
}

.module-raisin-50:active, .module-raisin-50.active,
.show > .module-raisin-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(25, 12, 29, 0.5);
  background-image: none;
  border-color: rgba(17, 9, 20, 0.5);
}

.module-raisin-50:active:focus, .module-raisin-50.active:focus,
.show > .module-raisin-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(124, 107, 130, 0.5);
}

.module-raisin-50:disabled, .module-raisin-50.disabled {
  color: #fff;
  background-color: rgba(55, 28, 64, 0.5);
  background-image: none;
  border-color: rgba(55, 28, 64, 0.5);
}

.module-raisin-60 {
  color: #fff;
  background: rgba(55, 28, 64, 0.6) linear-gradient(180deg, rgba(108, 91, 118, 0.66), rgba(55, 28, 64, 0.6)) repeat-x;
  border-color: rgba(55, 28, 64, 0.6);
}

.module-raisin-60 .icon-container {
  background-color: rgba(32, 16, 37, 0.6);
}

.module-raisin-60 .icon-container.light {
  background-color: rgba(101, 51, 117, 0.6);
}

.module-raisin-60:hover {
  color: #fff;
  background: rgba(32, 16, 37, 0.6) linear-gradient(180deg, rgba(92, 83, 99, 0.66), rgba(32, 16, 37, 0.6)) repeat-x;
  border-color: color-yig(rgba(32, 16, 37, 0.6));
}

.module-raisin-60:focus, .module-raisin-60.focus {
  color: #fff;
  background: rgba(32, 16, 37, 0.6) linear-gradient(180deg, rgba(92, 83, 99, 0.66), rgba(32, 16, 37, 0.6)) repeat-x;
  border-color: color-yig(rgba(32, 16, 37, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(113, 94, 120, 0.5);
}

.module-raisin-60:active, .module-raisin-60.active,
.show > .module-raisin-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(25, 12, 29, 0.6);
  background-image: none;
  border-color: rgba(17, 9, 20, 0.6);
}

.module-raisin-60:active:focus, .module-raisin-60.active:focus,
.show > .module-raisin-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(113, 94, 120, 0.5);
}

.module-raisin-60:disabled, .module-raisin-60.disabled {
  color: #fff;
  background-color: rgba(55, 28, 64, 0.6);
  background-image: none;
  border-color: rgba(55, 28, 64, 0.6);
}

.module-raisin-70 {
  color: #fff;
  background: rgba(55, 28, 64, 0.7) linear-gradient(180deg, rgba(100, 82, 110, 0.745), rgba(55, 28, 64, 0.7)) repeat-x;
  border-color: rgba(55, 28, 64, 0.7);
}

.module-raisin-70 .icon-container {
  background-color: rgba(32, 16, 37, 0.7);
}

.module-raisin-70 .icon-container.light {
  background-color: rgba(101, 51, 117, 0.7);
}

.module-raisin-70:hover {
  color: #fff;
  background: rgba(32, 16, 37, 0.7) linear-gradient(180deg, rgba(83, 73, 90, 0.745), rgba(32, 16, 37, 0.7)) repeat-x;
  border-color: color-yig(rgba(32, 16, 37, 0.7));
}

.module-raisin-70:focus, .module-raisin-70.focus {
  color: #fff;
  background: rgba(32, 16, 37, 0.7) linear-gradient(180deg, rgba(83, 73, 90, 0.745), rgba(32, 16, 37, 0.7)) repeat-x;
  border-color: color-yig(rgba(32, 16, 37, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(104, 84, 111, 0.5);
}

.module-raisin-70:active, .module-raisin-70.active,
.show > .module-raisin-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(25, 12, 29, 0.7);
  background-image: none;
  border-color: rgba(17, 9, 20, 0.7);
}

.module-raisin-70:active:focus, .module-raisin-70.active:focus,
.show > .module-raisin-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(104, 84, 111, 0.5);
}

.module-raisin-70:disabled, .module-raisin-70.disabled {
  color: #fff;
  background-color: rgba(55, 28, 64, 0.7);
  background-image: none;
  border-color: rgba(55, 28, 64, 0.7);
}

.module-raisin-80 {
  color: #fff;
  background: rgba(55, 28, 64, 0.8) linear-gradient(180deg, rgba(93, 73, 103, 0.83), rgba(55, 28, 64, 0.8)) repeat-x;
  border-color: rgba(55, 28, 64, 0.8);
}

.module-raisin-80 .icon-container {
  background-color: rgba(32, 16, 37, 0.8);
}

.module-raisin-80 .icon-container.light {
  background-color: rgba(101, 51, 117, 0.8);
}

.module-raisin-80:hover {
  color: #fff;
  background: rgba(32, 16, 37, 0.8) linear-gradient(180deg, rgba(75, 64, 82, 0.83), rgba(32, 16, 37, 0.8)) repeat-x;
  border-color: color-yig(rgba(32, 16, 37, 0.8));
}

.module-raisin-80:focus, .module-raisin-80.focus {
  color: #fff;
  background: rgba(32, 16, 37, 0.8) linear-gradient(180deg, rgba(75, 64, 82, 0.83), rgba(32, 16, 37, 0.8)) repeat-x;
  border-color: color-yig(rgba(32, 16, 37, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(97, 76, 104, 0.5);
}

.module-raisin-80:active, .module-raisin-80.active,
.show > .module-raisin-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(25, 12, 29, 0.8);
  background-image: none;
  border-color: rgba(17, 9, 20, 0.8);
}

.module-raisin-80:active:focus, .module-raisin-80.active:focus,
.show > .module-raisin-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(97, 76, 104, 0.5);
}

.module-raisin-80:disabled, .module-raisin-80.disabled {
  color: #fff;
  background-color: rgba(55, 28, 64, 0.8);
  background-image: none;
  border-color: rgba(55, 28, 64, 0.8);
}

.module-raisin-90 {
  color: #fff;
  background: rgba(55, 28, 64, 0.9) linear-gradient(180deg, rgba(87, 67, 97, 0.915), rgba(55, 28, 64, 0.9)) repeat-x;
  border-color: rgba(55, 28, 64, 0.9);
}

.module-raisin-90 .icon-container {
  background-color: rgba(32, 16, 37, 0.9);
}

.module-raisin-90 .icon-container.light {
  background-color: rgba(101, 51, 117, 0.9);
}

.module-raisin-90:hover {
  color: #fff;
  background: rgba(32, 16, 37, 0.9) linear-gradient(180deg, rgba(69, 57, 75, 0.915), rgba(32, 16, 37, 0.9)) repeat-x;
  border-color: color-yig(rgba(32, 16, 37, 0.9));
}

.module-raisin-90:focus, .module-raisin-90.focus {
  color: #fff;
  background: rgba(32, 16, 37, 0.9) linear-gradient(180deg, rgba(69, 57, 75, 0.915), rgba(32, 16, 37, 0.9)) repeat-x;
  border-color: color-yig(rgba(32, 16, 37, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(90, 68, 98, 0.5);
}

.module-raisin-90:active, .module-raisin-90.active,
.show > .module-raisin-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(25, 12, 29, 0.9);
  background-image: none;
  border-color: rgba(17, 9, 20, 0.9);
}

.module-raisin-90:active:focus, .module-raisin-90.active:focus,
.show > .module-raisin-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(90, 68, 98, 0.5);
}

.module-raisin-90:disabled, .module-raisin-90.disabled {
  color: #fff;
  background-color: rgba(55, 28, 64, 0.9);
  background-image: none;
  border-color: rgba(55, 28, 64, 0.9);
}

.module-grape {
  color: #fff;
  background: #54116a linear-gradient(180deg, #6b3380, #54116a) repeat-x;
  border-color: #54116a;
}

.module-grape .icon-container {
  background-color: #3a0c49;
}

.module-grape .icon-container.light {
  background-color: #881cac;
}

.module-grape:hover {
  color: #fff;
  background: #3a0c49 linear-gradient(180deg, #552f64, #3a0c49) repeat-x;
  border-color: color-yig(#3a0c49);
}

.module-grape:focus, .module-grape.focus {
  color: #fff;
  background: #3a0c49 linear-gradient(180deg, #552f64, #3a0c49) repeat-x;
  border-color: color-yig(#3a0c49);
  box-shadow: 0 0 0 0.2rem rgba(110, 53, 128, 0.5);
}

.module-grape:active, .module-grape.active,
.show > .module-grape.dropdown-toggle {
  color: #fff;
  background-color: #310a3e;
  background-image: none;
  border-color: #280833;
}

.module-grape:active:focus, .module-grape.active:focus,
.show > .module-grape.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(110, 53, 128, 0.5);
}

.module-grape:disabled, .module-grape.disabled {
  color: #fff;
  background-color: #54116a;
  background-image: none;
  border-color: #54116a;
}

.module-grape-10 {
  color: #fff;
  background: rgba(84, 17, 106, 0.1) linear-gradient(180deg, rgba(203, 193, 217, 0.235), rgba(84, 17, 106, 0.1)) repeat-x;
  border-color: rgba(84, 17, 106, 0.1);
}

.module-grape-10 .icon-container {
  background-color: rgba(58, 12, 73, 0.1);
}

.module-grape-10 .icon-container.light {
  background-color: rgba(136, 28, 172, 0.1);
}

.module-grape-10:hover {
  color: #fff;
  background: rgba(58, 12, 73, 0.1) linear-gradient(180deg, rgba(197, 191, 209, 0.235), rgba(58, 12, 73, 0.1)) repeat-x;
  border-color: color-yig(rgba(58, 12, 73, 0.1));
}

.module-grape-10:focus, .module-grape-10.focus {
  color: #fff;
  background: rgba(58, 12, 73, 0.1) linear-gradient(180deg, rgba(197, 191, 209, 0.235), rgba(58, 12, 73, 0.1)) repeat-x;
  border-color: color-yig(rgba(58, 12, 73, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(216, 200, 221, 0.5);
}

.module-grape-10:active, .module-grape-10.active,
.show > .module-grape-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(49, 10, 62, 0.1);
  background-image: none;
  border-color: rgba(40, 8, 51, 0.1);
}

.module-grape-10:active:focus, .module-grape-10.active:focus,
.show > .module-grape-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 200, 221, 0.5);
}

.module-grape-10:disabled, .module-grape-10.disabled {
  color: #fff;
  background-color: rgba(84, 17, 106, 0.1);
  background-image: none;
  border-color: rgba(84, 17, 106, 0.1);
}

.module-grape-20 {
  color: #fff;
  background: rgba(84, 17, 106, 0.2) linear-gradient(180deg, rgba(179, 157, 194, 0.32), rgba(84, 17, 106, 0.2)) repeat-x;
  border-color: rgba(84, 17, 106, 0.2);
}

.module-grape-20 .icon-container {
  background-color: rgba(58, 12, 73, 0.2);
}

.module-grape-20 .icon-container.light {
  background-color: rgba(136, 28, 172, 0.2);
}

.module-grape-20:hover {
  color: #fff;
  background: rgba(58, 12, 73, 0.2) linear-gradient(180deg, rgba(168, 155, 182, 0.32), rgba(58, 12, 73, 0.2)) repeat-x;
  border-color: color-yig(rgba(58, 12, 73, 0.2));
}

.module-grape-20:focus, .module-grape-20.focus {
  color: #fff;
  background: rgba(58, 12, 73, 0.2) linear-gradient(180deg, rgba(168, 155, 182, 0.32), rgba(58, 12, 73, 0.2)) repeat-x;
  border-color: color-yig(rgba(58, 12, 73, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(189, 163, 197, 0.5);
}

.module-grape-20:active, .module-grape-20.active,
.show > .module-grape-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(49, 10, 62, 0.2);
  background-image: none;
  border-color: rgba(40, 8, 51, 0.2);
}

.module-grape-20:active:focus, .module-grape-20.active:focus,
.show > .module-grape-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(189, 163, 197, 0.5);
}

.module-grape-20:disabled, .module-grape-20.disabled {
  color: #fff;
  background-color: rgba(84, 17, 106, 0.2);
  background-image: none;
  border-color: rgba(84, 17, 106, 0.2);
}

.module-grape-30 {
  color: #fff;
  background: rgba(84, 17, 106, 0.3) linear-gradient(180deg, rgba(161, 131, 178, 0.405), rgba(84, 17, 106, 0.3)) repeat-x;
  border-color: rgba(84, 17, 106, 0.3);
}

.module-grape-30 .icon-container {
  background-color: rgba(58, 12, 73, 0.3);
}

.module-grape-30 .icon-container.light {
  background-color: rgba(136, 28, 172, 0.3);
}

.module-grape-30:hover {
  color: #fff;
  background: rgba(58, 12, 73, 0.3) linear-gradient(180deg, rgba(148, 128, 162, 0.405), rgba(58, 12, 73, 0.3)) repeat-x;
  border-color: color-yig(rgba(58, 12, 73, 0.3));
}

.module-grape-30:focus, .module-grape-30.focus {
  color: #fff;
  background: rgba(58, 12, 73, 0.3) linear-gradient(180deg, rgba(148, 128, 162, 0.405), rgba(58, 12, 73, 0.3)) repeat-x;
  border-color: color-yig(rgba(58, 12, 73, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(170, 136, 181, 0.5);
}

.module-grape-30:active, .module-grape-30.active,
.show > .module-grape-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(49, 10, 62, 0.3);
  background-image: none;
  border-color: rgba(40, 8, 51, 0.3);
}

.module-grape-30:active:focus, .module-grape-30.active:focus,
.show > .module-grape-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(170, 136, 181, 0.5);
}

.module-grape-30:disabled, .module-grape-30.disabled {
  color: #fff;
  background-color: rgba(84, 17, 106, 0.3);
  background-image: none;
  border-color: rgba(84, 17, 106, 0.3);
}

.module-grape-40 {
  color: #fff;
  background: rgba(84, 17, 106, 0.4) linear-gradient(180deg, rgba(148, 111, 166, 0.49), rgba(84, 17, 106, 0.4)) repeat-x;
  border-color: rgba(84, 17, 106, 0.4);
}

.module-grape-40 .icon-container {
  background-color: rgba(58, 12, 73, 0.4);
}

.module-grape-40 .icon-container.light {
  background-color: rgba(136, 28, 172, 0.4);
}

.module-grape-40:hover {
  color: #fff;
  background: rgba(58, 12, 73, 0.4) linear-gradient(180deg, rgba(132, 108, 146, 0.49), rgba(58, 12, 73, 0.4)) repeat-x;
  border-color: color-yig(rgba(58, 12, 73, 0.4));
}

.module-grape-40:focus, .module-grape-40.focus {
  color: #fff;
  background: rgba(58, 12, 73, 0.4) linear-gradient(180deg, rgba(132, 108, 146, 0.49), rgba(58, 12, 73, 0.4)) repeat-x;
  border-color: color-yig(rgba(58, 12, 73, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(155, 115, 168, 0.5);
}

.module-grape-40:active, .module-grape-40.active,
.show > .module-grape-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(49, 10, 62, 0.4);
  background-image: none;
  border-color: rgba(40, 8, 51, 0.4);
}

.module-grape-40:active:focus, .module-grape-40.active:focus,
.show > .module-grape-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(155, 115, 168, 0.5);
}

.module-grape-40:disabled, .module-grape-40.disabled {
  color: #fff;
  background-color: rgba(84, 17, 106, 0.4);
  background-image: none;
  border-color: rgba(84, 17, 106, 0.4);
}

.module-grape-50 {
  color: #fff;
  background: rgba(84, 17, 106, 0.5) linear-gradient(180deg, rgba(137, 96, 156, 0.575), rgba(84, 17, 106, 0.5)) repeat-x;
  border-color: rgba(84, 17, 106, 0.5);
}

.module-grape-50 .icon-container {
  background-color: rgba(58, 12, 73, 0.5);
}

.module-grape-50 .icon-container.light {
  background-color: rgba(136, 28, 172, 0.5);
}

.module-grape-50:hover {
  color: #fff;
  background: rgba(58, 12, 73, 0.5) linear-gradient(180deg, rgba(120, 92, 134, 0.575), rgba(58, 12, 73, 0.5)) repeat-x;
  border-color: color-yig(rgba(58, 12, 73, 0.5));
}

.module-grape-50:focus, .module-grape-50.focus {
  color: #fff;
  background: rgba(58, 12, 73, 0.5) linear-gradient(180deg, rgba(120, 92, 134, 0.575), rgba(58, 12, 73, 0.5)) repeat-x;
  border-color: color-yig(rgba(58, 12, 73, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(143, 99, 158, 0.5);
}

.module-grape-50:active, .module-grape-50.active,
.show > .module-grape-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(49, 10, 62, 0.5);
  background-image: none;
  border-color: rgba(40, 8, 51, 0.5);
}

.module-grape-50:active:focus, .module-grape-50.active:focus,
.show > .module-grape-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(143, 99, 158, 0.5);
}

.module-grape-50:disabled, .module-grape-50.disabled {
  color: #fff;
  background-color: rgba(84, 17, 106, 0.5);
  background-image: none;
  border-color: rgba(84, 17, 106, 0.5);
}

.module-grape-60 {
  color: #fff;
  background: rgba(84, 17, 106, 0.6) linear-gradient(180deg, rgba(129, 84, 148, 0.66), rgba(84, 17, 106, 0.6)) repeat-x;
  border-color: rgba(84, 17, 106, 0.6);
}

.module-grape-60 .icon-container {
  background-color: rgba(58, 12, 73, 0.6);
}

.module-grape-60 .icon-container.light {
  background-color: rgba(136, 28, 172, 0.6);
}

.module-grape-60:hover {
  color: #fff;
  background: rgba(58, 12, 73, 0.6) linear-gradient(180deg, rgba(110, 80, 125, 0.66), rgba(58, 12, 73, 0.6)) repeat-x;
  border-color: color-yig(rgba(58, 12, 73, 0.6));
}

.module-grape-60:focus, .module-grape-60.focus {
  color: #fff;
  background: rgba(58, 12, 73, 0.6) linear-gradient(180deg, rgba(110, 80, 125, 0.66), rgba(58, 12, 73, 0.6)) repeat-x;
  border-color: color-yig(rgba(58, 12, 73, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(134, 86, 149, 0.5);
}

.module-grape-60:active, .module-grape-60.active,
.show > .module-grape-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(49, 10, 62, 0.6);
  background-image: none;
  border-color: rgba(40, 8, 51, 0.6);
}

.module-grape-60:active:focus, .module-grape-60.active:focus,
.show > .module-grape-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(134, 86, 149, 0.5);
}

.module-grape-60:disabled, .module-grape-60.disabled {
  color: #fff;
  background-color: rgba(84, 17, 106, 0.6);
  background-image: none;
  border-color: rgba(84, 17, 106, 0.6);
}

.module-grape-70 {
  color: #fff;
  background: rgba(84, 17, 106, 0.7) linear-gradient(180deg, rgba(122, 73, 142, 0.745), rgba(84, 17, 106, 0.7)) repeat-x;
  border-color: rgba(84, 17, 106, 0.7);
}

.module-grape-70 .icon-container {
  background-color: rgba(58, 12, 73, 0.7);
}

.module-grape-70 .icon-container.light {
  background-color: rgba(136, 28, 172, 0.7);
}

.module-grape-70:hover {
  color: #fff;
  background: rgba(58, 12, 73, 0.7) linear-gradient(180deg, rgba(102, 69, 117, 0.745), rgba(58, 12, 73, 0.7)) repeat-x;
  border-color: color-yig(rgba(58, 12, 73, 0.7));
}

.module-grape-70:focus, .module-grape-70.focus {
  color: #fff;
  background: rgba(58, 12, 73, 0.7) linear-gradient(180deg, rgba(102, 69, 117, 0.745), rgba(58, 12, 73, 0.7)) repeat-x;
  border-color: color-yig(rgba(58, 12, 73, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(126, 76, 143, 0.5);
}

.module-grape-70:active, .module-grape-70.active,
.show > .module-grape-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(49, 10, 62, 0.7);
  background-image: none;
  border-color: rgba(40, 8, 51, 0.7);
}

.module-grape-70:active:focus, .module-grape-70.active:focus,
.show > .module-grape-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(126, 76, 143, 0.5);
}

.module-grape-70:disabled, .module-grape-70.disabled {
  color: #fff;
  background-color: rgba(84, 17, 106, 0.7);
  background-image: none;
  border-color: rgba(84, 17, 106, 0.7);
}

.module-grape-80 {
  color: #fff;
  background: rgba(84, 17, 106, 0.8) linear-gradient(180deg, rgba(116, 65, 136, 0.83), rgba(84, 17, 106, 0.8)) repeat-x;
  border-color: rgba(84, 17, 106, 0.8);
}

.module-grape-80 .icon-container {
  background-color: rgba(58, 12, 73, 0.8);
}

.module-grape-80 .icon-container.light {
  background-color: rgba(136, 28, 172, 0.8);
}

.module-grape-80:hover {
  color: #fff;
  background: rgba(58, 12, 73, 0.8) linear-gradient(180deg, rgba(96, 61, 110, 0.83), rgba(58, 12, 73, 0.8)) repeat-x;
  border-color: color-yig(rgba(58, 12, 73, 0.8));
}

.module-grape-80:focus, .module-grape-80.focus {
  color: #fff;
  background: rgba(58, 12, 73, 0.8) linear-gradient(180deg, rgba(96, 61, 110, 0.83), rgba(58, 12, 73, 0.8)) repeat-x;
  border-color: color-yig(rgba(58, 12, 73, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(120, 67, 137, 0.5);
}

.module-grape-80:active, .module-grape-80.active,
.show > .module-grape-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(49, 10, 62, 0.8);
  background-image: none;
  border-color: rgba(40, 8, 51, 0.8);
}

.module-grape-80:active:focus, .module-grape-80.active:focus,
.show > .module-grape-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 67, 137, 0.5);
}

.module-grape-80:disabled, .module-grape-80.disabled {
  color: #fff;
  background-color: rgba(84, 17, 106, 0.8);
  background-image: none;
  border-color: rgba(84, 17, 106, 0.8);
}

.module-grape-90 {
  color: #fff;
  background: rgba(84, 17, 106, 0.9) linear-gradient(180deg, rgba(111, 57, 132, 0.915), rgba(84, 17, 106, 0.9)) repeat-x;
  border-color: rgba(84, 17, 106, 0.9);
}

.module-grape-90 .icon-container {
  background-color: rgba(58, 12, 73, 0.9);
}

.module-grape-90 .icon-container.light {
  background-color: rgba(136, 28, 172, 0.9);
}

.module-grape-90:hover {
  color: #fff;
  background: rgba(58, 12, 73, 0.9) linear-gradient(180deg, rgba(90, 53, 104, 0.915), rgba(58, 12, 73, 0.9)) repeat-x;
  border-color: color-yig(rgba(58, 12, 73, 0.9));
}

.module-grape-90:focus, .module-grape-90.focus {
  color: #fff;
  background: rgba(58, 12, 73, 0.9) linear-gradient(180deg, rgba(90, 53, 104, 0.915), rgba(58, 12, 73, 0.9)) repeat-x;
  border-color: color-yig(rgba(58, 12, 73, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(114, 59, 132, 0.5);
}

.module-grape-90:active, .module-grape-90.active,
.show > .module-grape-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(49, 10, 62, 0.9);
  background-image: none;
  border-color: rgba(40, 8, 51, 0.9);
}

.module-grape-90:active:focus, .module-grape-90.active:focus,
.show > .module-grape-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(114, 59, 132, 0.5);
}

.module-grape-90:disabled, .module-grape-90.disabled {
  color: #fff;
  background-color: rgba(84, 17, 106, 0.9);
  background-image: none;
  border-color: rgba(84, 17, 106, 0.9);
}

.module-lavendar {
  color: #fff;
  background: #7d0fa2 linear-gradient(180deg, #8e32af, #7d0fa2) repeat-x;
  border-color: #7d0fa2;
}

.module-lavendar .icon-container {
  background-color: #620c7f;
}

.module-lavendar .icon-container.light {
  background-color: #b315e8;
}

.module-lavendar:hover {
  color: #fff;
  background: #620c7f linear-gradient(180deg, #772f91, #620c7f) repeat-x;
  border-color: color-yig(#620c7f);
}

.module-lavendar:focus, .module-lavendar.focus {
  color: #fff;
  background: #620c7f linear-gradient(180deg, #772f91, #620c7f) repeat-x;
  border-color: color-yig(#620c7f);
  box-shadow: 0 0 0 0.2rem rgba(145, 51, 176, 0.5);
}

.module-lavendar:active, .module-lavendar.active,
.show > .module-lavendar.dropdown-toggle {
  color: #fff;
  background-color: #590b73;
  background-image: none;
  border-color: #500a68;
}

.module-lavendar:active:focus, .module-lavendar.active:focus,
.show > .module-lavendar.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(145, 51, 176, 0.5);
}

.module-lavendar:disabled, .module-lavendar.disabled {
  color: #fff;
  background-color: #7d0fa2;
  background-image: none;
  border-color: #7d0fa2;
}

.module-lavendar-10 {
  color: #fff;
  background: rgba(125, 15, 162, 0.1) linear-gradient(180deg, rgba(212, 192, 230, 0.235), rgba(125, 15, 162, 0.1)) repeat-x;
  border-color: rgba(125, 15, 162, 0.1);
}

.module-lavendar-10 .icon-container {
  background-color: rgba(98, 12, 127, 0.1);
}

.module-lavendar-10 .icon-container.light {
  background-color: rgba(179, 21, 232, 0.1);
}

.module-lavendar-10:hover {
  color: #fff;
  background: rgba(98, 12, 127, 0.1) linear-gradient(180deg, rgba(206, 191, 222, 0.235), rgba(98, 12, 127, 0.1)) repeat-x;
  border-color: color-yig(rgba(98, 12, 127, 0.1));
}

.module-lavendar-10:focus, .module-lavendar-10.focus {
  color: #fff;
  background: rgba(98, 12, 127, 0.1) linear-gradient(180deg, rgba(206, 191, 222, 0.235), rgba(98, 12, 127, 0.1)) repeat-x;
  border-color: color-yig(rgba(98, 12, 127, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(225, 200, 234, 0.5);
}

.module-lavendar-10:active, .module-lavendar-10.active,
.show > .module-lavendar-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(89, 11, 115, 0.1);
  background-image: none;
  border-color: rgba(80, 10, 104, 0.1);
}

.module-lavendar-10:active:focus, .module-lavendar-10.active:focus,
.show > .module-lavendar-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 200, 234, 0.5);
}

.module-lavendar-10:disabled, .module-lavendar-10.disabled {
  color: #fff;
  background-color: rgba(125, 15, 162, 0.1);
  background-image: none;
  border-color: rgba(125, 15, 162, 0.1);
}

.module-lavendar-20 {
  color: #fff;
  background: rgba(125, 15, 162, 0.2) linear-gradient(180deg, rgba(194, 156, 216, 0.32), rgba(125, 15, 162, 0.2)) repeat-x;
  border-color: rgba(125, 15, 162, 0.2);
}

.module-lavendar-20 .icon-container {
  background-color: rgba(98, 12, 127, 0.2);
}

.module-lavendar-20 .icon-container.light {
  background-color: rgba(179, 21, 232, 0.2);
}

.module-lavendar-20:hover {
  color: #fff;
  background: rgba(98, 12, 127, 0.2) linear-gradient(180deg, rgba(184, 155, 202, 0.32), rgba(98, 12, 127, 0.2)) repeat-x;
  border-color: color-yig(rgba(98, 12, 127, 0.2));
}

.module-lavendar-20:focus, .module-lavendar-20.focus {
  color: #fff;
  background: rgba(98, 12, 127, 0.2) linear-gradient(180deg, rgba(184, 155, 202, 0.32), rgba(98, 12, 127, 0.2)) repeat-x;
  border-color: color-yig(rgba(98, 12, 127, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(205, 162, 219, 0.5);
}

.module-lavendar-20:active, .module-lavendar-20.active,
.show > .module-lavendar-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(89, 11, 115, 0.2);
  background-image: none;
  border-color: rgba(80, 10, 104, 0.2);
}

.module-lavendar-20:active:focus, .module-lavendar-20.active:focus,
.show > .module-lavendar-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(205, 162, 219, 0.5);
}

.module-lavendar-20:disabled, .module-lavendar-20.disabled {
  color: #fff;
  background-color: rgba(125, 15, 162, 0.2);
  background-image: none;
  border-color: rgba(125, 15, 162, 0.2);
}

.module-lavendar-30 {
  color: #fff;
  background: rgba(125, 15, 162, 0.3) linear-gradient(180deg, rgba(182, 130, 206, 0.405), rgba(125, 15, 162, 0.3)) repeat-x;
  border-color: rgba(125, 15, 162, 0.3);
}

.module-lavendar-30 .icon-container {
  background-color: rgba(98, 12, 127, 0.3);
}

.module-lavendar-30 .icon-container.light {
  background-color: rgba(179, 21, 232, 0.3);
}

.module-lavendar-30:hover {
  color: #fff;
  background: rgba(98, 12, 127, 0.3) linear-gradient(180deg, rgba(168, 128, 188, 0.405), rgba(98, 12, 127, 0.3)) repeat-x;
  border-color: color-yig(rgba(98, 12, 127, 0.3));
}

.module-lavendar-30:focus, .module-lavendar-30.focus {
  color: #fff;
  background: rgba(98, 12, 127, 0.3) linear-gradient(180deg, rgba(168, 128, 188, 0.405), rgba(98, 12, 127, 0.3)) repeat-x;
  border-color: color-yig(rgba(98, 12, 127, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(190, 135, 209, 0.5);
}

.module-lavendar-30:active, .module-lavendar-30.active,
.show > .module-lavendar-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(89, 11, 115, 0.3);
  background-image: none;
  border-color: rgba(80, 10, 104, 0.3);
}

.module-lavendar-30:active:focus, .module-lavendar-30.active:focus,
.show > .module-lavendar-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(190, 135, 209, 0.5);
}

.module-lavendar-30:disabled, .module-lavendar-30.disabled {
  color: #fff;
  background-color: rgba(125, 15, 162, 0.3);
  background-image: none;
  border-color: rgba(125, 15, 162, 0.3);
}

.module-lavendar-40 {
  color: #fff;
  background: rgba(125, 15, 162, 0.4) linear-gradient(180deg, rgba(172, 110, 198, 0.49), rgba(125, 15, 162, 0.4)) repeat-x;
  border-color: rgba(125, 15, 162, 0.4);
}

.module-lavendar-40 .icon-container {
  background-color: rgba(98, 12, 127, 0.4);
}

.module-lavendar-40 .icon-container.light {
  background-color: rgba(179, 21, 232, 0.4);
}

.module-lavendar-40:hover {
  color: #fff;
  background: rgba(98, 12, 127, 0.4) linear-gradient(180deg, rgba(156, 108, 178, 0.49), rgba(98, 12, 127, 0.4)) repeat-x;
  border-color: color-yig(rgba(98, 12, 127, 0.4));
}

.module-lavendar-40:focus, .module-lavendar-40.focus {
  color: #fff;
  background: rgba(98, 12, 127, 0.4) linear-gradient(180deg, rgba(156, 108, 178, 0.49), rgba(98, 12, 127, 0.4)) repeat-x;
  border-color: color-yig(rgba(98, 12, 127, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(179, 114, 200, 0.5);
}

.module-lavendar-40:active, .module-lavendar-40.active,
.show > .module-lavendar-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(89, 11, 115, 0.4);
  background-image: none;
  border-color: rgba(80, 10, 104, 0.4);
}

.module-lavendar-40:active:focus, .module-lavendar-40.active:focus,
.show > .module-lavendar-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(179, 114, 200, 0.5);
}

.module-lavendar-40:disabled, .module-lavendar-40.disabled {
  color: #fff;
  background-color: rgba(125, 15, 162, 0.4);
  background-image: none;
  border-color: rgba(125, 15, 162, 0.4);
}

.module-lavendar-50 {
  color: #fff;
  background: rgba(125, 15, 162, 0.5) linear-gradient(180deg, rgba(164, 95, 192, 0.575), rgba(125, 15, 162, 0.5)) repeat-x;
  border-color: rgba(125, 15, 162, 0.5);
}

.module-lavendar-50 .icon-container {
  background-color: rgba(98, 12, 127, 0.5);
}

.module-lavendar-50 .icon-container.light {
  background-color: rgba(179, 21, 232, 0.5);
}

.module-lavendar-50:hover {
  color: #fff;
  background: rgba(98, 12, 127, 0.5) linear-gradient(180deg, rgba(146, 92, 170, 0.575), rgba(98, 12, 127, 0.5)) repeat-x;
  border-color: color-yig(rgba(98, 12, 127, 0.5));
}

.module-lavendar-50:focus, .module-lavendar-50.focus {
  color: #fff;
  background: rgba(98, 12, 127, 0.5) linear-gradient(180deg, rgba(146, 92, 170, 0.575), rgba(98, 12, 127, 0.5)) repeat-x;
  border-color: color-yig(rgba(98, 12, 127, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(170, 98, 194, 0.5);
}

.module-lavendar-50:active, .module-lavendar-50.active,
.show > .module-lavendar-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(89, 11, 115, 0.5);
  background-image: none;
  border-color: rgba(80, 10, 104, 0.5);
}

.module-lavendar-50:active:focus, .module-lavendar-50.active:focus,
.show > .module-lavendar-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(170, 98, 194, 0.5);
}

.module-lavendar-50:disabled, .module-lavendar-50.disabled {
  color: #fff;
  background-color: rgba(125, 15, 162, 0.5);
  background-image: none;
  border-color: rgba(125, 15, 162, 0.5);
}

.module-lavendar-60 {
  color: #fff;
  background: rgba(125, 15, 162, 0.6) linear-gradient(180deg, rgba(158, 82, 188, 0.66), rgba(125, 15, 162, 0.6)) repeat-x;
  border-color: rgba(125, 15, 162, 0.6);
}

.module-lavendar-60 .icon-container {
  background-color: rgba(98, 12, 127, 0.6);
}

.module-lavendar-60 .icon-container.light {
  background-color: rgba(179, 21, 232, 0.6);
}

.module-lavendar-60:hover {
  color: #fff;
  background: rgba(98, 12, 127, 0.6) linear-gradient(180deg, rgba(139, 80, 163, 0.66), rgba(98, 12, 127, 0.6)) repeat-x;
  border-color: color-yig(rgba(98, 12, 127, 0.6));
}

.module-lavendar-60:focus, .module-lavendar-60.focus {
  color: #fff;
  background: rgba(98, 12, 127, 0.6) linear-gradient(180deg, rgba(139, 80, 163, 0.66), rgba(98, 12, 127, 0.6)) repeat-x;
  border-color: color-yig(rgba(98, 12, 127, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(163, 85, 189, 0.5);
}

.module-lavendar-60:active, .module-lavendar-60.active,
.show > .module-lavendar-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(89, 11, 115, 0.6);
  background-image: none;
  border-color: rgba(80, 10, 104, 0.6);
}

.module-lavendar-60:active:focus, .module-lavendar-60.active:focus,
.show > .module-lavendar-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(163, 85, 189, 0.5);
}

.module-lavendar-60:disabled, .module-lavendar-60.disabled {
  color: #fff;
  background-color: rgba(125, 15, 162, 0.6);
  background-image: none;
  border-color: rgba(125, 15, 162, 0.6);
}

.module-lavendar-70 {
  color: #fff;
  background: rgba(125, 15, 162, 0.7) linear-gradient(180deg, rgba(153, 72, 184, 0.745), rgba(125, 15, 162, 0.7)) repeat-x;
  border-color: rgba(125, 15, 162, 0.7);
}

.module-lavendar-70 .icon-container {
  background-color: rgba(98, 12, 127, 0.7);
}

.module-lavendar-70 .icon-container.light {
  background-color: rgba(179, 21, 232, 0.7);
}

.module-lavendar-70:hover {
  color: #fff;
  background: rgba(98, 12, 127, 0.7) linear-gradient(180deg, rgba(133, 69, 157, 0.745), rgba(98, 12, 127, 0.7)) repeat-x;
  border-color: color-yig(rgba(98, 12, 127, 0.7));
}

.module-lavendar-70:focus, .module-lavendar-70.focus {
  color: #fff;
  background: rgba(98, 12, 127, 0.7) linear-gradient(180deg, rgba(133, 69, 157, 0.745), rgba(98, 12, 127, 0.7)) repeat-x;
  border-color: color-yig(rgba(98, 12, 127, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(157, 74, 185, 0.5);
}

.module-lavendar-70:active, .module-lavendar-70.active,
.show > .module-lavendar-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(89, 11, 115, 0.7);
  background-image: none;
  border-color: rgba(80, 10, 104, 0.7);
}

.module-lavendar-70:active:focus, .module-lavendar-70.active:focus,
.show > .module-lavendar-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(157, 74, 185, 0.5);
}

.module-lavendar-70:disabled, .module-lavendar-70.disabled {
  color: #fff;
  background-color: rgba(125, 15, 162, 0.7);
  background-image: none;
  border-color: rgba(125, 15, 162, 0.7);
}

.module-lavendar-80 {
  color: #fff;
  background: rgba(125, 15, 162, 0.8) linear-gradient(180deg, rgba(149, 63, 180, 0.83), rgba(125, 15, 162, 0.8)) repeat-x;
  border-color: rgba(125, 15, 162, 0.8);
}

.module-lavendar-80 .icon-container {
  background-color: rgba(98, 12, 127, 0.8);
}

.module-lavendar-80 .icon-container.light {
  background-color: rgba(179, 21, 232, 0.8);
}

.module-lavendar-80:hover {
  color: #fff;
  background: rgba(98, 12, 127, 0.8) linear-gradient(180deg, rgba(127, 61, 153, 0.83), rgba(98, 12, 127, 0.8)) repeat-x;
  border-color: color-yig(rgba(98, 12, 127, 0.8));
}

.module-lavendar-80:focus, .module-lavendar-80.focus {
  color: #fff;
  background: rgba(98, 12, 127, 0.8) linear-gradient(180deg, rgba(127, 61, 153, 0.83), rgba(98, 12, 127, 0.8)) repeat-x;
  border-color: color-yig(rgba(98, 12, 127, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(152, 65, 181, 0.5);
}

.module-lavendar-80:active, .module-lavendar-80.active,
.show > .module-lavendar-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(89, 11, 115, 0.8);
  background-image: none;
  border-color: rgba(80, 10, 104, 0.8);
}

.module-lavendar-80:active:focus, .module-lavendar-80.active:focus,
.show > .module-lavendar-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(152, 65, 181, 0.5);
}

.module-lavendar-80:disabled, .module-lavendar-80.disabled {
  color: #fff;
  background-color: rgba(125, 15, 162, 0.8);
  background-image: none;
  border-color: rgba(125, 15, 162, 0.8);
}

.module-lavendar-90 {
  color: #fff;
  background: rgba(125, 15, 162, 0.9) linear-gradient(180deg, rgba(145, 56, 178, 0.915), rgba(125, 15, 162, 0.9)) repeat-x;
  border-color: rgba(125, 15, 162, 0.9);
}

.module-lavendar-90 .icon-container {
  background-color: rgba(98, 12, 127, 0.9);
}

.module-lavendar-90 .icon-container.light {
  background-color: rgba(179, 21, 232, 0.9);
}

.module-lavendar-90:hover {
  color: #fff;
  background: rgba(98, 12, 127, 0.9) linear-gradient(180deg, rgba(123, 53, 149, 0.915), rgba(98, 12, 127, 0.9)) repeat-x;
  border-color: color-yig(rgba(98, 12, 127, 0.9));
}

.module-lavendar-90:focus, .module-lavendar-90.focus {
  color: #fff;
  background: rgba(98, 12, 127, 0.9) linear-gradient(180deg, rgba(123, 53, 149, 0.915), rgba(98, 12, 127, 0.9)) repeat-x;
  border-color: color-yig(rgba(98, 12, 127, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(148, 58, 179, 0.5);
}

.module-lavendar-90:active, .module-lavendar-90.active,
.show > .module-lavendar-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(89, 11, 115, 0.9);
  background-image: none;
  border-color: rgba(80, 10, 104, 0.9);
}

.module-lavendar-90:active:focus, .module-lavendar-90.active:focus,
.show > .module-lavendar-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(148, 58, 179, 0.5);
}

.module-lavendar-90:disabled, .module-lavendar-90.disabled {
  color: #fff;
  background-color: rgba(125, 15, 162, 0.9);
  background-image: none;
  border-color: rgba(125, 15, 162, 0.9);
}

.module-amethyst {
  color: #fff;
  background: #9d02d0 linear-gradient(180deg, #a926d6, #9d02d0) repeat-x;
  border-color: #9d02d0;
}

.module-amethyst .icon-container {
  background-color: #8002aa;
}

.module-amethyst .icon-container.light {
  background-color: #c722fd;
}

.module-amethyst:hover {
  color: #fff;
  background: #8002aa linear-gradient(180deg, #9126b6, #8002aa) repeat-x;
  border-color: color-yig(#8002aa);
}

.module-amethyst:focus, .module-amethyst.focus {
  color: #fff;
  background: #8002aa linear-gradient(180deg, #9126b6, #8002aa) repeat-x;
  border-color: color-yig(#8002aa);
  box-shadow: 0 0 0 0.2rem rgba(172, 40, 215, 0.5);
}

.module-amethyst:active, .module-amethyst.active,
.show > .module-amethyst.dropdown-toggle {
  color: #fff;
  background-color: #77029d;
  background-image: none;
  border-color: #6d0191;
}

.module-amethyst:active:focus, .module-amethyst.active:focus,
.show > .module-amethyst.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(172, 40, 215, 0.5);
}

.module-amethyst:disabled, .module-amethyst.disabled {
  color: #fff;
  background-color: #9d02d0;
  background-image: none;
  border-color: #9d02d0;
}

.module-amethyst-10 {
  color: #fff;
  background: rgba(157, 2, 208, 0.1) linear-gradient(180deg, rgba(219, 189, 240, 0.235), rgba(157, 2, 208, 0.1)) repeat-x;
  border-color: rgba(157, 2, 208, 0.1);
}

.module-amethyst-10 .icon-container {
  background-color: rgba(128, 2, 170, 0.1);
}

.module-amethyst-10 .icon-container.light {
  background-color: rgba(199, 34, 253, 0.1);
}

.module-amethyst-10:hover {
  color: #fff;
  background: rgba(128, 2, 170, 0.1) linear-gradient(180deg, rgba(213, 189, 232, 0.235), rgba(128, 2, 170, 0.1)) repeat-x;
  border-color: color-yig(rgba(128, 2, 170, 0.1));
}

.module-amethyst-10:focus, .module-amethyst-10.focus {
  color: #fff;
  background: rgba(128, 2, 170, 0.1) linear-gradient(180deg, rgba(213, 189, 232, 0.235), rgba(128, 2, 170, 0.1)) repeat-x;
  border-color: color-yig(rgba(128, 2, 170, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(232, 197, 244, 0.5);
}

.module-amethyst-10:active, .module-amethyst-10.active,
.show > .module-amethyst-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(119, 2, 157, 0.1);
  background-image: none;
  border-color: rgba(109, 1, 145, 0.1);
}

.module-amethyst-10:active:focus, .module-amethyst-10.active:focus,
.show > .module-amethyst-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 197, 244, 0.5);
}

.module-amethyst-10:disabled, .module-amethyst-10.disabled {
  color: #fff;
  background-color: rgba(157, 2, 208, 0.1);
  background-image: none;
  border-color: rgba(157, 2, 208, 0.1);
}

.module-amethyst-20 {
  color: #fff;
  background: rgba(157, 2, 208, 0.2) linear-gradient(180deg, rgba(207, 151, 234, 0.32), rgba(157, 2, 208, 0.2)) repeat-x;
  border-color: rgba(157, 2, 208, 0.2);
}

.module-amethyst-20 .icon-container {
  background-color: rgba(128, 2, 170, 0.2);
}

.module-amethyst-20 .icon-container.light {
  background-color: rgba(199, 34, 253, 0.2);
}

.module-amethyst-20:hover {
  color: #fff;
  background: rgba(128, 2, 170, 0.2) linear-gradient(180deg, rgba(196, 151, 219, 0.32), rgba(128, 2, 170, 0.2)) repeat-x;
  border-color: color-yig(rgba(128, 2, 170, 0.2));
}

.module-amethyst-20:focus, .module-amethyst-20.focus {
  color: #fff;
  background: rgba(128, 2, 170, 0.2) linear-gradient(180deg, rgba(196, 151, 219, 0.32), rgba(128, 2, 170, 0.2)) repeat-x;
  border-color: color-yig(rgba(128, 2, 170, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(217, 157, 237, 0.5);
}

.module-amethyst-20:active, .module-amethyst-20.active,
.show > .module-amethyst-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(119, 2, 157, 0.2);
  background-image: none;
  border-color: rgba(109, 1, 145, 0.2);
}

.module-amethyst-20:active:focus, .module-amethyst-20.active:focus,
.show > .module-amethyst-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 157, 237, 0.5);
}

.module-amethyst-20:disabled, .module-amethyst-20.disabled {
  color: #fff;
  background-color: rgba(157, 2, 208, 0.2);
  background-image: none;
  border-color: rgba(157, 2, 208, 0.2);
}

.module-amethyst-30 {
  color: #fff;
  background: rgba(157, 2, 208, 0.3) linear-gradient(180deg, rgba(198, 124, 229, 0.405), rgba(157, 2, 208, 0.3)) repeat-x;
  border-color: rgba(157, 2, 208, 0.3);
}

.module-amethyst-30 .icon-container {
  background-color: rgba(128, 2, 170, 0.3);
}

.module-amethyst-30 .icon-container.light {
  background-color: rgba(199, 34, 253, 0.3);
}

.module-amethyst-30:hover {
  color: #fff;
  background: rgba(128, 2, 170, 0.3) linear-gradient(180deg, rgba(183, 123, 210, 0.405), rgba(128, 2, 170, 0.3)) repeat-x;
  border-color: color-yig(rgba(128, 2, 170, 0.3));
}

.module-amethyst-30:focus, .module-amethyst-30.focus {
  color: #fff;
  background: rgba(128, 2, 170, 0.3) linear-gradient(180deg, rgba(183, 123, 210, 0.405), rgba(128, 2, 170, 0.3)) repeat-x;
  border-color: color-yig(rgba(128, 2, 170, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(206, 129, 232, 0.5);
}

.module-amethyst-30:active, .module-amethyst-30.active,
.show > .module-amethyst-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(119, 2, 157, 0.3);
  background-image: none;
  border-color: rgba(109, 1, 145, 0.3);
}

.module-amethyst-30:active:focus, .module-amethyst-30.active:focus,
.show > .module-amethyst-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 129, 232, 0.5);
}

.module-amethyst-30:disabled, .module-amethyst-30.disabled {
  color: #fff;
  background-color: rgba(157, 2, 208, 0.3);
  background-image: none;
  border-color: rgba(157, 2, 208, 0.3);
}

.module-amethyst-40 {
  color: #fff;
  background: rgba(157, 2, 208, 0.4) linear-gradient(180deg, rgba(191, 103, 225, 0.49), rgba(157, 2, 208, 0.4)) repeat-x;
  border-color: rgba(157, 2, 208, 0.4);
}

.module-amethyst-40 .icon-container {
  background-color: rgba(128, 2, 170, 0.4);
}

.module-amethyst-40 .icon-container.light {
  background-color: rgba(199, 34, 253, 0.4);
}

.module-amethyst-40:hover {
  color: #fff;
  background: rgba(128, 2, 170, 0.4) linear-gradient(180deg, rgba(174, 102, 203, 0.49), rgba(128, 2, 170, 0.4)) repeat-x;
  border-color: color-yig(rgba(128, 2, 170, 0.4));
}

.module-amethyst-40:focus, .module-amethyst-40.focus {
  color: #fff;
  background: rgba(128, 2, 170, 0.4) linear-gradient(180deg, rgba(174, 102, 203, 0.49), rgba(128, 2, 170, 0.4)) repeat-x;
  border-color: color-yig(rgba(128, 2, 170, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(198, 107, 227, 0.5);
}

.module-amethyst-40:active, .module-amethyst-40.active,
.show > .module-amethyst-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(119, 2, 157, 0.4);
  background-image: none;
  border-color: rgba(109, 1, 145, 0.4);
}

.module-amethyst-40:active:focus, .module-amethyst-40.active:focus,
.show > .module-amethyst-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(198, 107, 227, 0.5);
}

.module-amethyst-40:disabled, .module-amethyst-40.disabled {
  color: #fff;
  background-color: rgba(157, 2, 208, 0.4);
  background-image: none;
  border-color: rgba(157, 2, 208, 0.4);
}

.module-amethyst-50 {
  color: #fff;
  background: rgba(157, 2, 208, 0.5) linear-gradient(180deg, rgba(185, 86, 223, 0.575), rgba(157, 2, 208, 0.5)) repeat-x;
  border-color: rgba(157, 2, 208, 0.5);
}

.module-amethyst-50 .icon-container {
  background-color: rgba(128, 2, 170, 0.5);
}

.module-amethyst-50 .icon-container.light {
  background-color: rgba(199, 34, 253, 0.5);
}

.module-amethyst-50:hover {
  color: #fff;
  background: rgba(128, 2, 170, 0.5) linear-gradient(180deg, rgba(166, 86, 198, 0.575), rgba(128, 2, 170, 0.5)) repeat-x;
  border-color: color-yig(rgba(128, 2, 170, 0.5));
}

.module-amethyst-50:focus, .module-amethyst-50.focus {
  color: #fff;
  background: rgba(128, 2, 170, 0.5) linear-gradient(180deg, rgba(166, 86, 198, 0.575), rgba(128, 2, 170, 0.5)) repeat-x;
  border-color: color-yig(rgba(128, 2, 170, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(191, 90, 224, 0.5);
}

.module-amethyst-50:active, .module-amethyst-50.active,
.show > .module-amethyst-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(119, 2, 157, 0.5);
  background-image: none;
  border-color: rgba(109, 1, 145, 0.5);
}

.module-amethyst-50:active:focus, .module-amethyst-50.active:focus,
.show > .module-amethyst-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(191, 90, 224, 0.5);
}

.module-amethyst-50:disabled, .module-amethyst-50.disabled {
  color: #fff;
  background-color: rgba(157, 2, 208, 0.5);
  background-image: none;
  border-color: rgba(157, 2, 208, 0.5);
}

.module-amethyst-60 {
  color: #fff;
  background: rgba(157, 2, 208, 0.6) linear-gradient(180deg, rgba(181, 73, 220, 0.66), rgba(157, 2, 208, 0.6)) repeat-x;
  border-color: rgba(157, 2, 208, 0.6);
}

.module-amethyst-60 .icon-container {
  background-color: rgba(128, 2, 170, 0.6);
}

.module-amethyst-60 .icon-container.light {
  background-color: rgba(199, 34, 253, 0.6);
}

.module-amethyst-60:hover {
  color: #fff;
  background: rgba(128, 2, 170, 0.6) linear-gradient(180deg, rgba(160, 73, 193, 0.66), rgba(128, 2, 170, 0.6)) repeat-x;
  border-color: color-yig(rgba(128, 2, 170, 0.6));
}

.module-amethyst-60:focus, .module-amethyst-60.focus {
  color: #fff;
  background: rgba(128, 2, 170, 0.6) linear-gradient(180deg, rgba(160, 73, 193, 0.66), rgba(128, 2, 170, 0.6)) repeat-x;
  border-color: color-yig(rgba(128, 2, 170, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(186, 76, 222, 0.5);
}

.module-amethyst-60:active, .module-amethyst-60.active,
.show > .module-amethyst-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(119, 2, 157, 0.6);
  background-image: none;
  border-color: rgba(109, 1, 145, 0.6);
}

.module-amethyst-60:active:focus, .module-amethyst-60.active:focus,
.show > .module-amethyst-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(186, 76, 222, 0.5);
}

.module-amethyst-60:disabled, .module-amethyst-60.disabled {
  color: #fff;
  background-color: rgba(157, 2, 208, 0.6);
  background-image: none;
  border-color: rgba(157, 2, 208, 0.6);
}

.module-amethyst-70 {
  color: #fff;
  background: rgba(157, 2, 208, 0.7) linear-gradient(180deg, rgba(177, 62, 218, 0.745), rgba(157, 2, 208, 0.7)) repeat-x;
  border-color: rgba(157, 2, 208, 0.7);
}

.module-amethyst-70 .icon-container {
  background-color: rgba(128, 2, 170, 0.7);
}

.module-amethyst-70 .icon-container.light {
  background-color: rgba(199, 34, 253, 0.7);
}

.module-amethyst-70:hover {
  color: #fff;
  background: rgba(128, 2, 170, 0.7) linear-gradient(180deg, rgba(155, 62, 190, 0.745), rgba(128, 2, 170, 0.7)) repeat-x;
  border-color: color-yig(rgba(128, 2, 170, 0.7));
}

.module-amethyst-70:focus, .module-amethyst-70.focus {
  color: #fff;
  background: rgba(128, 2, 170, 0.7) linear-gradient(180deg, rgba(155, 62, 190, 0.745), rgba(128, 2, 170, 0.7)) repeat-x;
  border-color: color-yig(rgba(128, 2, 170, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(181, 64, 220, 0.5);
}

.module-amethyst-70:active, .module-amethyst-70.active,
.show > .module-amethyst-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(119, 2, 157, 0.7);
  background-image: none;
  border-color: rgba(109, 1, 145, 0.7);
}

.module-amethyst-70:active:focus, .module-amethyst-70.active:focus,
.show > .module-amethyst-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(181, 64, 220, 0.5);
}

.module-amethyst-70:disabled, .module-amethyst-70.disabled {
  color: #fff;
  background-color: rgba(157, 2, 208, 0.7);
  background-image: none;
  border-color: rgba(157, 2, 208, 0.7);
}

.module-amethyst-80 {
  color: #fff;
  background: rgba(157, 2, 208, 0.8) linear-gradient(180deg, rgba(174, 53, 217, 0.83), rgba(157, 2, 208, 0.8)) repeat-x;
  border-color: rgba(157, 2, 208, 0.8);
}

.module-amethyst-80 .icon-container {
  background-color: rgba(128, 2, 170, 0.8);
}

.module-amethyst-80 .icon-container.light {
  background-color: rgba(199, 34, 253, 0.8);
}

.module-amethyst-80:hover {
  color: #fff;
  background: rgba(128, 2, 170, 0.8) linear-gradient(180deg, rgba(151, 53, 187, 0.83), rgba(128, 2, 170, 0.8)) repeat-x;
  border-color: color-yig(rgba(128, 2, 170, 0.8));
}

.module-amethyst-80:focus, .module-amethyst-80.focus {
  color: #fff;
  background: rgba(128, 2, 170, 0.8) linear-gradient(180deg, rgba(151, 53, 187, 0.83), rgba(128, 2, 170, 0.8)) repeat-x;
  border-color: color-yig(rgba(128, 2, 170, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(178, 55, 218, 0.5);
}

.module-amethyst-80:active, .module-amethyst-80.active,
.show > .module-amethyst-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(119, 2, 157, 0.8);
  background-image: none;
  border-color: rgba(109, 1, 145, 0.8);
}

.module-amethyst-80:active:focus, .module-amethyst-80.active:focus,
.show > .module-amethyst-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(178, 55, 218, 0.5);
}

.module-amethyst-80:disabled, .module-amethyst-80.disabled {
  color: #fff;
  background-color: rgba(157, 2, 208, 0.8);
  background-image: none;
  border-color: rgba(157, 2, 208, 0.8);
}

.module-amethyst-90 {
  color: #fff;
  background: rgba(157, 2, 208, 0.9) linear-gradient(180deg, rgba(171, 45, 215, 0.915), rgba(157, 2, 208, 0.9)) repeat-x;
  border-color: rgba(157, 2, 208, 0.9);
}

.module-amethyst-90 .icon-container {
  background-color: rgba(128, 2, 170, 0.9);
}

.module-amethyst-90 .icon-container.light {
  background-color: rgba(199, 34, 253, 0.9);
}

.module-amethyst-90:hover {
  color: #fff;
  background: rgba(128, 2, 170, 0.9) linear-gradient(180deg, rgba(148, 45, 184, 0.915), rgba(128, 2, 170, 0.9)) repeat-x;
  border-color: color-yig(rgba(128, 2, 170, 0.9));
}

.module-amethyst-90:focus, .module-amethyst-90.focus {
  color: #fff;
  background: rgba(128, 2, 170, 0.9) linear-gradient(180deg, rgba(148, 45, 184, 0.915), rgba(128, 2, 170, 0.9)) repeat-x;
  border-color: color-yig(rgba(128, 2, 170, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(174, 47, 216, 0.5);
}

.module-amethyst-90:active, .module-amethyst-90.active,
.show > .module-amethyst-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(119, 2, 157, 0.9);
  background-image: none;
  border-color: rgba(109, 1, 145, 0.9);
}

.module-amethyst-90:active:focus, .module-amethyst-90.active:focus,
.show > .module-amethyst-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(174, 47, 216, 0.5);
}

.module-amethyst-90:disabled, .module-amethyst-90.disabled {
  color: #fff;
  background-color: rgba(157, 2, 208, 0.9);
  background-image: none;
  border-color: rgba(157, 2, 208, 0.9);
}

.module-orchid {
  color: #fff;
  background: #c557e9 linear-gradient(180deg, #cb6fec, #c557e9) repeat-x;
  border-color: #c557e9;
}

.module-orchid .icon-container {
  background-color: #b935e5;
}

.module-orchid .icon-container.light {
  background-color: #dc9bf2;
}

.module-orchid:hover {
  color: #fff;
  background: #b935e5 linear-gradient(180deg, #c152e8, #b935e5) repeat-x;
  border-color: color-yig(#b935e5);
}

.module-orchid:focus, .module-orchid.focus {
  color: #fff;
  background: #b935e5 linear-gradient(180deg, #c152e8, #b935e5) repeat-x;
  border-color: color-yig(#b935e5);
  box-shadow: 0 0 0 0.2rem rgba(206, 112, 236, 0.5);
}

.module-orchid:active, .module-orchid.active,
.show > .module-orchid.dropdown-toggle {
  color: #fff;
  background-color: #b52ae3;
  background-image: none;
  border-color: #b21fe2;
}

.module-orchid:active:focus, .module-orchid.active:focus,
.show > .module-orchid.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 112, 236, 0.5);
}

.module-orchid:disabled, .module-orchid.disabled {
  color: #fff;
  background-color: #c557e9;
  background-image: none;
  border-color: #c557e9;
}

.module-orchid-10 {
  color: #fff;
  background: rgba(197, 87, 233, 0.1) linear-gradient(180deg, rgba(229, 209, 246, 0.235), rgba(197, 87, 233, 0.1)) repeat-x;
  border-color: rgba(197, 87, 233, 0.1);
}

.module-orchid-10 .icon-container {
  background-color: rgba(185, 53, 229, 0.1);
}

.module-orchid-10 .icon-container.light {
  background-color: rgba(220, 155, 242, 0.1);
}

.module-orchid-10:hover {
  color: #fff;
  background: rgba(185, 53, 229, 0.1) linear-gradient(180deg, rgba(226, 201, 245, 0.235), rgba(185, 53, 229, 0.1)) repeat-x;
  border-color: color-yig(rgba(185, 53, 229, 0.1));
}

.module-orchid-10:focus, .module-orchid-10.focus {
  color: #fff;
  background: rgba(185, 53, 229, 0.1) linear-gradient(180deg, rgba(226, 201, 245, 0.235), rgba(185, 53, 229, 0.1)) repeat-x;
  border-color: color-yig(rgba(185, 53, 229, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(242, 216, 250, 0.5);
}

.module-orchid-10:active, .module-orchid-10.active,
.show > .module-orchid-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(181, 42, 227, 0.1);
  background-image: none;
  border-color: rgba(178, 31, 226, 0.1);
}

.module-orchid-10:active:focus, .module-orchid-10.active:focus,
.show > .module-orchid-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 216, 250, 0.5);
}

.module-orchid-10:disabled, .module-orchid-10.disabled {
  color: #fff;
  background-color: rgba(197, 87, 233, 0.1);
  background-image: none;
  border-color: rgba(197, 87, 233, 0.1);
}

.module-orchid-20 {
  color: #fff;
  background: rgba(197, 87, 233, 0.2) linear-gradient(180deg, rgba(222, 184, 243, 0.32), rgba(197, 87, 233, 0.2)) repeat-x;
  border-color: rgba(197, 87, 233, 0.2);
}

.module-orchid-20 .icon-container {
  background-color: rgba(185, 53, 229, 0.2);
}

.module-orchid-20 .icon-container.light {
  background-color: rgba(220, 155, 242, 0.2);
}

.module-orchid-20:hover {
  color: #fff;
  background: rgba(185, 53, 229, 0.2) linear-gradient(180deg, rgba(218, 171, 242, 0.32), rgba(185, 53, 229, 0.2)) repeat-x;
  border-color: color-yig(rgba(185, 53, 229, 0.2));
}

.module-orchid-20:focus, .module-orchid-20.focus {
  color: #fff;
  background: rgba(185, 53, 229, 0.2) linear-gradient(180deg, rgba(218, 171, 242, 0.32), rgba(185, 53, 229, 0.2)) repeat-x;
  border-color: color-yig(rgba(185, 53, 229, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(233, 190, 247, 0.5);
}

.module-orchid-20:active, .module-orchid-20.active,
.show > .module-orchid-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(181, 42, 227, 0.2);
  background-image: none;
  border-color: rgba(178, 31, 226, 0.2);
}

.module-orchid-20:active:focus, .module-orchid-20.active:focus,
.show > .module-orchid-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 190, 247, 0.5);
}

.module-orchid-20:disabled, .module-orchid-20.disabled {
  color: #fff;
  background-color: rgba(197, 87, 233, 0.2);
  background-image: none;
  border-color: rgba(197, 87, 233, 0.2);
}

.module-orchid-30 {
  color: #fff;
  background: rgba(197, 87, 233, 0.3) linear-gradient(180deg, rgba(218, 166, 242, 0.405), rgba(197, 87, 233, 0.3)) repeat-x;
  border-color: rgba(197, 87, 233, 0.3);
}

.module-orchid-30 .icon-container {
  background-color: rgba(185, 53, 229, 0.3);
}

.module-orchid-30 .icon-container.light {
  background-color: rgba(220, 155, 242, 0.3);
}

.module-orchid-30:hover {
  color: #fff;
  background: rgba(185, 53, 229, 0.3) linear-gradient(180deg, rgba(212, 149, 239, 0.405), rgba(185, 53, 229, 0.3)) repeat-x;
  border-color: color-yig(rgba(185, 53, 229, 0.3));
}

.module-orchid-30:focus, .module-orchid-30.focus {
  color: #fff;
  background: rgba(185, 53, 229, 0.3) linear-gradient(180deg, rgba(212, 149, 239, 0.405), rgba(185, 53, 229, 0.3)) repeat-x;
  border-color: color-yig(rgba(185, 53, 229, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(226, 171, 244, 0.5);
}

.module-orchid-30:active, .module-orchid-30.active,
.show > .module-orchid-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(181, 42, 227, 0.3);
  background-image: none;
  border-color: rgba(178, 31, 226, 0.3);
}

.module-orchid-30:active:focus, .module-orchid-30.active:focus,
.show > .module-orchid-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(226, 171, 244, 0.5);
}

.module-orchid-30:disabled, .module-orchid-30.disabled {
  color: #fff;
  background-color: rgba(197, 87, 233, 0.3);
  background-image: none;
  border-color: rgba(197, 87, 233, 0.3);
}

.module-orchid-40 {
  color: #fff;
  background: rgba(197, 87, 233, 0.4) linear-gradient(180deg, rgba(214, 152, 240, 0.49), rgba(197, 87, 233, 0.4)) repeat-x;
  border-color: rgba(197, 87, 233, 0.4);
}

.module-orchid-40 .icon-container {
  background-color: rgba(185, 53, 229, 0.4);
}

.module-orchid-40 .icon-container.light {
  background-color: rgba(220, 155, 242, 0.4);
}

.module-orchid-40:hover {
  color: #fff;
  background: rgba(185, 53, 229, 0.4) linear-gradient(180deg, rgba(207, 133, 237, 0.49), rgba(185, 53, 229, 0.4)) repeat-x;
  border-color: color-yig(rgba(185, 53, 229, 0.4));
}

.module-orchid-40:focus, .module-orchid-40.focus {
  color: #fff;
  background: rgba(185, 53, 229, 0.4) linear-gradient(180deg, rgba(207, 133, 237, 0.49), rgba(185, 53, 229, 0.4)) repeat-x;
  border-color: color-yig(rgba(185, 53, 229, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(221, 157, 242, 0.5);
}

.module-orchid-40:active, .module-orchid-40.active,
.show > .module-orchid-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(181, 42, 227, 0.4);
  background-image: none;
  border-color: rgba(178, 31, 226, 0.4);
}

.module-orchid-40:active:focus, .module-orchid-40.active:focus,
.show > .module-orchid-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 157, 242, 0.5);
}

.module-orchid-40:disabled, .module-orchid-40.disabled {
  color: #fff;
  background-color: rgba(197, 87, 233, 0.4);
  background-image: none;
  border-color: rgba(197, 87, 233, 0.4);
}

.module-orchid-50 {
  color: #fff;
  background: rgba(197, 87, 233, 0.5) linear-gradient(180deg, rgba(211, 142, 239, 0.575), rgba(197, 87, 233, 0.5)) repeat-x;
  border-color: rgba(197, 87, 233, 0.5);
}

.module-orchid-50 .icon-container {
  background-color: rgba(185, 53, 229, 0.5);
}

.module-orchid-50 .icon-container.light {
  background-color: rgba(220, 155, 242, 0.5);
}

.module-orchid-50:hover {
  color: #fff;
  background: rgba(185, 53, 229, 0.5) linear-gradient(180deg, rgba(204, 120, 236, 0.575), rgba(185, 53, 229, 0.5)) repeat-x;
  border-color: color-yig(rgba(185, 53, 229, 0.5));
}

.module-orchid-50:focus, .module-orchid-50.focus {
  color: #fff;
  background: rgba(185, 53, 229, 0.5) linear-gradient(180deg, rgba(204, 120, 236, 0.575), rgba(185, 53, 229, 0.5)) repeat-x;
  border-color: color-yig(rgba(185, 53, 229, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(217, 145, 241, 0.5);
}

.module-orchid-50:active, .module-orchid-50.active,
.show > .module-orchid-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(181, 42, 227, 0.5);
  background-image: none;
  border-color: rgba(178, 31, 226, 0.5);
}

.module-orchid-50:active:focus, .module-orchid-50.active:focus,
.show > .module-orchid-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 145, 241, 0.5);
}

.module-orchid-50:disabled, .module-orchid-50.disabled {
  color: #fff;
  background-color: rgba(197, 87, 233, 0.5);
  background-image: none;
  border-color: rgba(197, 87, 233, 0.5);
}

.module-orchid-60 {
  color: #fff;
  background: rgba(197, 87, 233, 0.6) linear-gradient(180deg, rgba(209, 133, 238, 0.66), rgba(197, 87, 233, 0.6)) repeat-x;
  border-color: rgba(197, 87, 233, 0.6);
}

.module-orchid-60 .icon-container {
  background-color: rgba(185, 53, 229, 0.6);
}

.module-orchid-60 .icon-container.light {
  background-color: rgba(220, 155, 242, 0.6);
}

.module-orchid-60:hover {
  color: #fff;
  background: rgba(185, 53, 229, 0.6) linear-gradient(180deg, rgba(201, 109, 235, 0.66), rgba(185, 53, 229, 0.6)) repeat-x;
  border-color: color-yig(rgba(185, 53, 229, 0.6));
}

.module-orchid-60:focus, .module-orchid-60.focus {
  color: #fff;
  background: rgba(185, 53, 229, 0.6) linear-gradient(180deg, rgba(201, 109, 235, 0.66), rgba(185, 53, 229, 0.6)) repeat-x;
  border-color: color-yig(rgba(185, 53, 229, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(214, 136, 239, 0.5);
}

.module-orchid-60:active, .module-orchid-60.active,
.show > .module-orchid-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(181, 42, 227, 0.6);
  background-image: none;
  border-color: rgba(178, 31, 226, 0.6);
}

.module-orchid-60:active:focus, .module-orchid-60.active:focus,
.show > .module-orchid-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(214, 136, 239, 0.5);
}

.module-orchid-60:disabled, .module-orchid-60.disabled {
  color: #fff;
  background-color: rgba(197, 87, 233, 0.6);
  background-image: none;
  border-color: rgba(197, 87, 233, 0.6);
}

.module-orchid-70 {
  color: #fff;
  background: rgba(197, 87, 233, 0.7) linear-gradient(180deg, rgba(207, 126, 237, 0.745), rgba(197, 87, 233, 0.7)) repeat-x;
  border-color: rgba(197, 87, 233, 0.7);
}

.module-orchid-70 .icon-container {
  background-color: rgba(185, 53, 229, 0.7);
}

.module-orchid-70 .icon-container.light {
  background-color: rgba(220, 155, 242, 0.7);
}

.module-orchid-70:hover {
  color: #fff;
  background: rgba(185, 53, 229, 0.7) linear-gradient(180deg, rgba(198, 101, 234, 0.745), rgba(185, 53, 229, 0.7)) repeat-x;
  border-color: color-yig(rgba(185, 53, 229, 0.7));
}

.module-orchid-70:focus, .module-orchid-70.focus {
  color: #fff;
  background: rgba(185, 53, 229, 0.7) linear-gradient(180deg, rgba(198, 101, 234, 0.745), rgba(185, 53, 229, 0.7)) repeat-x;
  border-color: color-yig(rgba(185, 53, 229, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(211, 128, 238, 0.5);
}

.module-orchid-70:active, .module-orchid-70.active,
.show > .module-orchid-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(181, 42, 227, 0.7);
  background-image: none;
  border-color: rgba(178, 31, 226, 0.7);
}

.module-orchid-70:active:focus, .module-orchid-70.active:focus,
.show > .module-orchid-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 128, 238, 0.5);
}

.module-orchid-70:disabled, .module-orchid-70.disabled {
  color: #fff;
  background-color: rgba(197, 87, 233, 0.7);
  background-image: none;
  border-color: rgba(197, 87, 233, 0.7);
}

.module-orchid-80 {
  color: #fff;
  background: rgba(197, 87, 233, 0.8) linear-gradient(180deg, rgba(206, 120, 237, 0.83), rgba(197, 87, 233, 0.8)) repeat-x;
  border-color: rgba(197, 87, 233, 0.8);
}

.module-orchid-80 .icon-container {
  background-color: rgba(185, 53, 229, 0.8);
}

.module-orchid-80 .icon-container.light {
  background-color: rgba(220, 155, 242, 0.8);
}

.module-orchid-80:hover {
  color: #fff;
  background: rgba(185, 53, 229, 0.8) linear-gradient(180deg, rgba(196, 93, 233, 0.83), rgba(185, 53, 229, 0.8)) repeat-x;
  border-color: color-yig(rgba(185, 53, 229, 0.8));
}

.module-orchid-80:focus, .module-orchid-80.focus {
  color: #fff;
  background: rgba(185, 53, 229, 0.8) linear-gradient(180deg, rgba(196, 93, 233, 0.83), rgba(185, 53, 229, 0.8)) repeat-x;
  border-color: color-yig(rgba(185, 53, 229, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(209, 122, 238, 0.5);
}

.module-orchid-80:active, .module-orchid-80.active,
.show > .module-orchid-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(181, 42, 227, 0.8);
  background-image: none;
  border-color: rgba(178, 31, 226, 0.8);
}

.module-orchid-80:active:focus, .module-orchid-80.active:focus,
.show > .module-orchid-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 122, 238, 0.5);
}

.module-orchid-80:disabled, .module-orchid-80.disabled {
  color: #fff;
  background-color: rgba(197, 87, 233, 0.8);
  background-image: none;
  border-color: rgba(197, 87, 233, 0.8);
}

.module-orchid-90 {
  color: #fff;
  background: rgba(197, 87, 233, 0.9) linear-gradient(180deg, rgba(204, 115, 236, 0.915), rgba(197, 87, 233, 0.9)) repeat-x;
  border-color: rgba(197, 87, 233, 0.9);
}

.module-orchid-90 .icon-container {
  background-color: rgba(185, 53, 229, 0.9);
}

.module-orchid-90 .icon-container.light {
  background-color: rgba(220, 155, 242, 0.9);
}

.module-orchid-90:hover {
  color: #fff;
  background: rgba(185, 53, 229, 0.9) linear-gradient(180deg, rgba(195, 87, 232, 0.915), rgba(185, 53, 229, 0.9)) repeat-x;
  border-color: color-yig(rgba(185, 53, 229, 0.9));
}

.module-orchid-90:focus, .module-orchid-90.focus {
  color: #fff;
  background: rgba(185, 53, 229, 0.9) linear-gradient(180deg, rgba(195, 87, 232, 0.915), rgba(185, 53, 229, 0.9)) repeat-x;
  border-color: color-yig(rgba(185, 53, 229, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(207, 117, 237, 0.5);
}

.module-orchid-90:active, .module-orchid-90.active,
.show > .module-orchid-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(181, 42, 227, 0.9);
  background-image: none;
  border-color: rgba(178, 31, 226, 0.9);
}

.module-orchid-90:active:focus, .module-orchid-90.active:focus,
.show > .module-orchid-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(207, 117, 237, 0.5);
}

.module-orchid-90:disabled, .module-orchid-90.disabled {
  color: #fff;
  background-color: rgba(197, 87, 233, 0.9);
  background-image: none;
  border-color: rgba(197, 87, 233, 0.9);
}

.module-thistle {
  color: #273a4d;
  background: #e8a1ff linear-gradient(180deg, #e9aefe, #e8a1ff) repeat-x;
  border-color: #e8a1ff;
}

.module-thistle .icon-container {
  background-color: #df7bff;
}

.module-thistle .icon-container.light {
  background-color: #fbeeff;
}

.module-thistle:hover {
  color: #273a4d;
  background: #df7bff linear-gradient(180deg, #e18dfe, #df7bff) repeat-x;
  border-color: color-yig(#df7bff);
}

.module-thistle:focus, .module-thistle.focus {
  color: #273a4d;
  background: #df7bff linear-gradient(180deg, #e18dfe, #df7bff) repeat-x;
  border-color: color-yig(#df7bff);
  box-shadow: 0 0 0 0.2rem rgba(203, 146, 228, 0.5);
}

.module-thistle:active, .module-thistle.active,
.show > .module-thistle.dropdown-toggle {
  color: #273a4d;
  background-color: #dc6eff;
  background-image: none;
  border-color: #d861ff;
}

.module-thistle:active:focus, .module-thistle.active:focus,
.show > .module-thistle.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(203, 146, 228, 0.5);
}

.module-thistle:disabled, .module-thistle.disabled {
  color: #273a4d;
  background-color: #e8a1ff;
  background-image: none;
  border-color: #e8a1ff;
}

.module-thistle-10 {
  color: #273a4d;
  background: rgba(232, 161, 255, 0.1) linear-gradient(180deg, rgba(237, 226, 251, 0.235), rgba(232, 161, 255, 0.1)) repeat-x;
  border-color: rgba(232, 161, 255, 0.1);
}

.module-thistle-10 .icon-container {
  background-color: rgba(223, 123, 255, 0.1);
}

.module-thistle-10 .icon-container.light {
  background-color: rgba(251, 238, 255, 0.1);
}

.module-thistle-10:hover {
  color: #273a4d;
  background: rgba(223, 123, 255, 0.1) linear-gradient(180deg, rgba(234, 217, 251, 0.235), rgba(223, 123, 255, 0.1)) repeat-x;
  border-color: color-yig(rgba(223, 123, 255, 0.1));
}

.module-thistle-10:focus, .module-thistle-10.focus {
  color: #273a4d;
  background: rgba(223, 123, 255, 0.1) linear-gradient(180deg, rgba(234, 217, 251, 0.235), rgba(223, 123, 255, 0.1)) repeat-x;
  border-color: color-yig(rgba(223, 123, 255, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(83, 82, 118, 0.5);
}

.module-thistle-10:active, .module-thistle-10.active,
.show > .module-thistle-10.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(220, 110, 255, 0.1);
  background-image: none;
  border-color: rgba(216, 97, 255, 0.1);
}

.module-thistle-10:active:focus, .module-thistle-10.active:focus,
.show > .module-thistle-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(83, 82, 118, 0.5);
}

.module-thistle-10:disabled, .module-thistle-10.disabled {
  color: #273a4d;
  background-color: rgba(232, 161, 255, 0.1);
  background-image: none;
  border-color: rgba(232, 161, 255, 0.1);
}

.module-thistle-20 {
  color: #273a4d;
  background: rgba(232, 161, 255, 0.2) linear-gradient(180deg, rgba(236, 213, 252, 0.32), rgba(232, 161, 255, 0.2)) repeat-x;
  border-color: rgba(232, 161, 255, 0.2);
}

.module-thistle-20 .icon-container {
  background-color: rgba(223, 123, 255, 0.2);
}

.module-thistle-20 .icon-container.light {
  background-color: rgba(251, 238, 255, 0.2);
}

.module-thistle-20:hover {
  color: #273a4d;
  background: rgba(223, 123, 255, 0.2) linear-gradient(180deg, rgba(232, 198, 252, 0.32), rgba(223, 123, 255, 0.2)) repeat-x;
  border-color: color-yig(rgba(223, 123, 255, 0.2));
}

.module-thistle-20:focus, .module-thistle-20.focus {
  color: #273a4d;
  background: rgba(223, 123, 255, 0.2) linear-gradient(180deg, rgba(232, 198, 252, 0.32), rgba(223, 123, 255, 0.2)) repeat-x;
  border-color: color-yig(rgba(223, 123, 255, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(114, 98, 146, 0.5);
}

.module-thistle-20:active, .module-thistle-20.active,
.show > .module-thistle-20.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(220, 110, 255, 0.2);
  background-image: none;
  border-color: rgba(216, 97, 255, 0.2);
}

.module-thistle-20:active:focus, .module-thistle-20.active:focus,
.show > .module-thistle-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(114, 98, 146, 0.5);
}

.module-thistle-20:disabled, .module-thistle-20.disabled {
  color: #273a4d;
  background-color: rgba(232, 161, 255, 0.2);
  background-image: none;
  border-color: rgba(232, 161, 255, 0.2);
}

.module-thistle-30 {
  color: #273a4d;
  background: rgba(232, 161, 255, 0.3) linear-gradient(180deg, rgba(235, 203, 253, 0.405), rgba(232, 161, 255, 0.3)) repeat-x;
  border-color: rgba(232, 161, 255, 0.3);
}

.module-thistle-30 .icon-container {
  background-color: rgba(223, 123, 255, 0.3);
}

.module-thistle-30 .icon-container.light {
  background-color: rgba(251, 238, 255, 0.3);
}

.module-thistle-30:hover {
  color: #273a4d;
  background: rgba(223, 123, 255, 0.3) linear-gradient(180deg, rgba(230, 184, 253, 0.405), rgba(223, 123, 255, 0.3)) repeat-x;
  border-color: color-yig(rgba(223, 123, 255, 0.3));
}

.module-thistle-30:focus, .module-thistle-30.focus {
  color: #273a4d;
  background: rgba(223, 123, 255, 0.3) linear-gradient(180deg, rgba(230, 184, 253, 0.405), rgba(223, 123, 255, 0.3)) repeat-x;
  border-color: color-yig(rgba(223, 123, 255, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(136, 110, 166, 0.5);
}

.module-thistle-30:active, .module-thistle-30.active,
.show > .module-thistle-30.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(220, 110, 255, 0.3);
  background-image: none;
  border-color: rgba(216, 97, 255, 0.3);
}

.module-thistle-30:active:focus, .module-thistle-30.active:focus,
.show > .module-thistle-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(136, 110, 166, 0.5);
}

.module-thistle-30:disabled, .module-thistle-30.disabled {
  color: #273a4d;
  background-color: rgba(232, 161, 255, 0.3);
  background-image: none;
  border-color: rgba(232, 161, 255, 0.3);
}

.module-thistle-40 {
  color: #273a4d;
  background: rgba(232, 161, 255, 0.4) linear-gradient(180deg, rgba(234, 196, 253, 0.49), rgba(232, 161, 255, 0.4)) repeat-x;
  border-color: rgba(232, 161, 255, 0.4);
}

.module-thistle-40 .icon-container {
  background-color: rgba(223, 123, 255, 0.4);
}

.module-thistle-40 .icon-container.light {
  background-color: rgba(251, 238, 255, 0.4);
}

.module-thistle-40:hover {
  color: #273a4d;
  background: rgba(223, 123, 255, 0.4) linear-gradient(180deg, rgba(229, 173, 253, 0.49), rgba(223, 123, 255, 0.4)) repeat-x;
  border-color: color-yig(rgba(223, 123, 255, 0.4));
}

.module-thistle-40:focus, .module-thistle-40.focus {
  color: #273a4d;
  background: rgba(223, 123, 255, 0.4) linear-gradient(180deg, rgba(229, 173, 253, 0.49), rgba(223, 123, 255, 0.4)) repeat-x;
  border-color: color-yig(rgba(223, 123, 255, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(152, 118, 181, 0.5);
}

.module-thistle-40:active, .module-thistle-40.active,
.show > .module-thistle-40.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(220, 110, 255, 0.4);
  background-image: none;
  border-color: rgba(216, 97, 255, 0.4);
}

.module-thistle-40:active:focus, .module-thistle-40.active:focus,
.show > .module-thistle-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(152, 118, 181, 0.5);
}

.module-thistle-40:disabled, .module-thistle-40.disabled {
  color: #273a4d;
  background-color: rgba(232, 161, 255, 0.4);
  background-image: none;
  border-color: rgba(232, 161, 255, 0.4);
}

.module-thistle-50 {
  color: #273a4d;
  background: rgba(232, 161, 255, 0.5) linear-gradient(180deg, rgba(234, 190, 253, 0.575), rgba(232, 161, 255, 0.5)) repeat-x;
  border-color: rgba(232, 161, 255, 0.5);
}

.module-thistle-50 .icon-container {
  background-color: rgba(223, 123, 255, 0.5);
}

.module-thistle-50 .icon-container.light {
  background-color: rgba(251, 238, 255, 0.5);
}

.module-thistle-50:hover {
  color: #273a4d;
  background: rgba(223, 123, 255, 0.5) linear-gradient(180deg, rgba(228, 165, 253, 0.575), rgba(223, 123, 255, 0.5)) repeat-x;
  border-color: color-yig(rgba(223, 123, 255, 0.5));
}

.module-thistle-50:focus, .module-thistle-50.focus {
  color: #273a4d;
  background: rgba(223, 123, 255, 0.5) linear-gradient(180deg, rgba(228, 165, 253, 0.575), rgba(223, 123, 255, 0.5)) repeat-x;
  border-color: color-yig(rgba(223, 123, 255, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(165, 125, 193, 0.5);
}

.module-thistle-50:active, .module-thistle-50.active,
.show > .module-thistle-50.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(220, 110, 255, 0.5);
  background-image: none;
  border-color: rgba(216, 97, 255, 0.5);
}

.module-thistle-50:active:focus, .module-thistle-50.active:focus,
.show > .module-thistle-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(165, 125, 193, 0.5);
}

.module-thistle-50:disabled, .module-thistle-50.disabled {
  color: #273a4d;
  background-color: rgba(232, 161, 255, 0.5);
  background-image: none;
  border-color: rgba(232, 161, 255, 0.5);
}

.module-thistle-60 {
  color: #273a4d;
  background: rgba(232, 161, 255, 0.6) linear-gradient(180deg, rgba(234, 186, 254, 0.66), rgba(232, 161, 255, 0.6)) repeat-x;
  border-color: rgba(232, 161, 255, 0.6);
}

.module-thistle-60 .icon-container {
  background-color: rgba(223, 123, 255, 0.6);
}

.module-thistle-60 .icon-container.light {
  background-color: rgba(251, 238, 255, 0.6);
}

.module-thistle-60:hover {
  color: #273a4d;
  background: rgba(223, 123, 255, 0.6) linear-gradient(180deg, rgba(227, 158, 254, 0.66), rgba(223, 123, 255, 0.6)) repeat-x;
  border-color: color-yig(rgba(223, 123, 255, 0.6));
}

.module-thistle-60:focus, .module-thistle-60.focus {
  color: #273a4d;
  background: rgba(223, 123, 255, 0.6) linear-gradient(180deg, rgba(227, 158, 254, 0.66), rgba(223, 123, 255, 0.6)) repeat-x;
  border-color: color-yig(rgba(223, 123, 255, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(176, 131, 203, 0.5);
}

.module-thistle-60:active, .module-thistle-60.active,
.show > .module-thistle-60.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(220, 110, 255, 0.6);
  background-image: none;
  border-color: rgba(216, 97, 255, 0.6);
}

.module-thistle-60:active:focus, .module-thistle-60.active:focus,
.show > .module-thistle-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(176, 131, 203, 0.5);
}

.module-thistle-60:disabled, .module-thistle-60.disabled {
  color: #273a4d;
  background-color: rgba(232, 161, 255, 0.6);
  background-image: none;
  border-color: rgba(232, 161, 255, 0.6);
}

.module-thistle-70 {
  color: #273a4d;
  background: rgba(232, 161, 255, 0.7) linear-gradient(180deg, rgba(233, 182, 254, 0.745), rgba(232, 161, 255, 0.7)) repeat-x;
  border-color: rgba(232, 161, 255, 0.7);
}

.module-thistle-70 .icon-container {
  background-color: rgba(223, 123, 255, 0.7);
}

.module-thistle-70 .icon-container.light {
  background-color: rgba(251, 238, 255, 0.7);
}

.module-thistle-70:hover {
  color: #273a4d;
  background: rgba(223, 123, 255, 0.7) linear-gradient(180deg, rgba(226, 153, 254, 0.745), rgba(223, 123, 255, 0.7)) repeat-x;
  border-color: color-yig(rgba(223, 123, 255, 0.7));
}

.module-thistle-70:focus, .module-thistle-70.focus {
  color: #273a4d;
  background: rgba(223, 123, 255, 0.7) linear-gradient(180deg, rgba(226, 153, 254, 0.745), rgba(223, 123, 255, 0.7)) repeat-x;
  border-color: color-yig(rgba(223, 123, 255, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(184, 136, 211, 0.5);
}

.module-thistle-70:active, .module-thistle-70.active,
.show > .module-thistle-70.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(220, 110, 255, 0.7);
  background-image: none;
  border-color: rgba(216, 97, 255, 0.7);
}

.module-thistle-70:active:focus, .module-thistle-70.active:focus,
.show > .module-thistle-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 136, 211, 0.5);
}

.module-thistle-70:disabled, .module-thistle-70.disabled {
  color: #273a4d;
  background-color: rgba(232, 161, 255, 0.7);
  background-image: none;
  border-color: rgba(232, 161, 255, 0.7);
}

.module-thistle-80 {
  color: #273a4d;
  background: rgba(232, 161, 255, 0.8) linear-gradient(180deg, rgba(233, 179, 254, 0.83), rgba(232, 161, 255, 0.8)) repeat-x;
  border-color: rgba(232, 161, 255, 0.8);
}

.module-thistle-80 .icon-container {
  background-color: rgba(223, 123, 255, 0.8);
}

.module-thistle-80 .icon-container.light {
  background-color: rgba(251, 238, 255, 0.8);
}

.module-thistle-80:hover {
  color: #273a4d;
  background: rgba(223, 123, 255, 0.8) linear-gradient(180deg, rgba(226, 148, 254, 0.83), rgba(223, 123, 255, 0.8)) repeat-x;
  border-color: color-yig(rgba(223, 123, 255, 0.8));
}

.module-thistle-80:focus, .module-thistle-80.focus {
  color: #273a4d;
  background: rgba(223, 123, 255, 0.8) linear-gradient(180deg, rgba(226, 148, 254, 0.83), rgba(223, 123, 255, 0.8)) repeat-x;
  border-color: color-yig(rgba(223, 123, 255, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(192, 139, 218, 0.5);
}

.module-thistle-80:active, .module-thistle-80.active,
.show > .module-thistle-80.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(220, 110, 255, 0.8);
  background-image: none;
  border-color: rgba(216, 97, 255, 0.8);
}

.module-thistle-80:active:focus, .module-thistle-80.active:focus,
.show > .module-thistle-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(192, 139, 218, 0.5);
}

.module-thistle-80:disabled, .module-thistle-80.disabled {
  color: #273a4d;
  background-color: rgba(232, 161, 255, 0.8);
  background-image: none;
  border-color: rgba(232, 161, 255, 0.8);
}

.module-thistle-90 {
  color: #273a4d;
  background: rgba(232, 161, 255, 0.9) linear-gradient(180deg, rgba(233, 176, 254, 0.915), rgba(232, 161, 255, 0.9)) repeat-x;
  border-color: rgba(232, 161, 255, 0.9);
}

.module-thistle-90 .icon-container {
  background-color: rgba(223, 123, 255, 0.9);
}

.module-thistle-90 .icon-container.light {
  background-color: rgba(251, 238, 255, 0.9);
}

.module-thistle-90:hover {
  color: #273a4d;
  background: rgba(223, 123, 255, 0.9) linear-gradient(180deg, rgba(225, 144, 254, 0.915), rgba(223, 123, 255, 0.9)) repeat-x;
  border-color: color-yig(rgba(223, 123, 255, 0.9));
}

.module-thistle-90:focus, .module-thistle-90.focus {
  color: #273a4d;
  background: rgba(223, 123, 255, 0.9) linear-gradient(180deg, rgba(225, 144, 254, 0.915), rgba(223, 123, 255, 0.9)) repeat-x;
  border-color: color-yig(rgba(223, 123, 255, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(198, 143, 223, 0.5);
}

.module-thistle-90:active, .module-thistle-90.active,
.show > .module-thistle-90.dropdown-toggle {
  color: #273a4d;
  background-color: rgba(220, 110, 255, 0.9);
  background-image: none;
  border-color: rgba(216, 97, 255, 0.9);
}

.module-thistle-90:active:focus, .module-thistle-90.active:focus,
.show > .module-thistle-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(198, 143, 223, 0.5);
}

.module-thistle-90:disabled, .module-thistle-90.disabled {
  color: #273a4d;
  background-color: rgba(232, 161, 255, 0.9);
  background-image: none;
  border-color: rgba(232, 161, 255, 0.9);
}

.module-magenta {
  color: #fff;
  background: #ff0080 linear-gradient(180deg, #fc2592, #ff0080) repeat-x;
  border-color: #ff0080;
}

.module-magenta .icon-container {
  background-color: #d9006d;
}

.module-magenta .icon-container.light {
  background-color: #ff4da6;
}

.module-magenta:hover {
  color: #fff;
  background: #d9006d linear-gradient(180deg, #dc2582, #d9006d) repeat-x;
  border-color: color-yig(#d9006d);
}

.module-magenta:focus, .module-magenta.focus {
  color: #fff;
  background: #d9006d linear-gradient(180deg, #dc2582, #d9006d) repeat-x;
  border-color: color-yig(#d9006d);
  box-shadow: 0 0 0 0.2rem rgba(255, 38, 147, 0.5);
}

.module-magenta:active, .module-magenta.active,
.show > .module-magenta.dropdown-toggle {
  color: #fff;
  background-color: #cc0066;
  background-image: none;
  border-color: #bf0060;
}

.module-magenta:active:focus, .module-magenta.active:focus,
.show > .module-magenta.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 38, 147, 0.5);
}

.module-magenta:disabled, .module-magenta.disabled {
  color: #fff;
  background-color: #ff0080;
  background-image: none;
  border-color: #ff0080;
}

.module-magenta-10 {
  color: #fff;
  background: rgba(255, 0, 128, 0.1) linear-gradient(180deg, rgba(242, 189, 222, 0.235), rgba(255, 0, 128, 0.1)) repeat-x;
  border-color: rgba(255, 0, 128, 0.1);
}

.module-magenta-10 .icon-container {
  background-color: rgba(217, 0, 109, 0.1);
}

.module-magenta-10 .icon-container.light {
  background-color: rgba(255, 77, 166, 0.1);
}

.module-magenta-10:hover {
  color: #fff;
  background: rgba(217, 0, 109, 0.1) linear-gradient(180deg, rgba(233, 189, 218, 0.235), rgba(217, 0, 109, 0.1)) repeat-x;
  border-color: color-yig(rgba(217, 0, 109, 0.1));
}

.module-magenta-10:focus, .module-magenta-10.focus {
  color: #fff;
  background: rgba(217, 0, 109, 0.1) linear-gradient(180deg, rgba(233, 189, 218, 0.235), rgba(217, 0, 109, 0.1)) repeat-x;
  border-color: color-yig(rgba(217, 0, 109, 0.1));
  box-shadow: 0 0 0 0.2rem rgba(255, 196, 226, 0.5);
}

.module-magenta-10:active, .module-magenta-10.active,
.show > .module-magenta-10.dropdown-toggle {
  color: #fff;
  background-color: rgba(204, 0, 102, 0.1);
  background-image: none;
  border-color: rgba(191, 0, 96, 0.1);
}

.module-magenta-10:active:focus, .module-magenta-10.active:focus,
.show > .module-magenta-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 196, 226, 0.5);
}

.module-magenta-10:disabled, .module-magenta-10.disabled {
  color: #fff;
  background-color: rgba(255, 0, 128, 0.1);
  background-image: none;
  border-color: rgba(255, 0, 128, 0.1);
}

.module-magenta-20 {
  color: #fff;
  background: rgba(255, 0, 128, 0.2) linear-gradient(180deg, rgba(245, 150, 203, 0.32), rgba(255, 0, 128, 0.2)) repeat-x;
  border-color: rgba(255, 0, 128, 0.2);
}

.module-magenta-20 .icon-container {
  background-color: rgba(217, 0, 109, 0.2);
}

.module-magenta-20 .icon-container.light {
  background-color: rgba(255, 77, 166, 0.2);
}

.module-magenta-20:hover {
  color: #fff;
  background: rgba(217, 0, 109, 0.2) linear-gradient(180deg, rgba(230, 150, 195, 0.32), rgba(217, 0, 109, 0.2)) repeat-x;
  border-color: color-yig(rgba(217, 0, 109, 0.2));
}

.module-magenta-20:focus, .module-magenta-20.focus {
  color: #fff;
  background: rgba(217, 0, 109, 0.2) linear-gradient(180deg, rgba(230, 150, 195, 0.32), rgba(217, 0, 109, 0.2)) repeat-x;
  border-color: color-yig(rgba(217, 0, 109, 0.2));
  box-shadow: 0 0 0 0.2rem rgba(255, 156, 206, 0.5);
}

.module-magenta-20:active, .module-magenta-20.active,
.show > .module-magenta-20.dropdown-toggle {
  color: #fff;
  background-color: rgba(204, 0, 102, 0.2);
  background-image: none;
  border-color: rgba(191, 0, 96, 0.2);
}

.module-magenta-20:active:focus, .module-magenta-20.active:focus,
.show > .module-magenta-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 156, 206, 0.5);
}

.module-magenta-20:disabled, .module-magenta-20.disabled {
  color: #fff;
  background-color: rgba(255, 0, 128, 0.2);
  background-image: none;
  border-color: rgba(255, 0, 128, 0.2);
}

.module-magenta-30 {
  color: #fff;
  background: rgba(255, 0, 128, 0.3) linear-gradient(180deg, rgba(247, 123, 189, 0.405), rgba(255, 0, 128, 0.3)) repeat-x;
  border-color: rgba(255, 0, 128, 0.3);
}

.module-magenta-30 .icon-container {
  background-color: rgba(217, 0, 109, 0.3);
}

.module-magenta-30 .icon-container.light {
  background-color: rgba(255, 77, 166, 0.3);
}

.module-magenta-30:hover {
  color: #fff;
  background: rgba(217, 0, 109, 0.3) linear-gradient(180deg, rgba(227, 123, 179, 0.405), rgba(217, 0, 109, 0.3)) repeat-x;
  border-color: color-yig(rgba(217, 0, 109, 0.3));
}

.module-magenta-30:focus, .module-magenta-30.focus {
  color: #fff;
  background: rgba(217, 0, 109, 0.3) linear-gradient(180deg, rgba(227, 123, 179, 0.405), rgba(217, 0, 109, 0.3)) repeat-x;
  border-color: color-yig(rgba(217, 0, 109, 0.3));
  box-shadow: 0 0 0 0.2rem rgba(255, 128, 192, 0.5);
}

.module-magenta-30:active, .module-magenta-30.active,
.show > .module-magenta-30.dropdown-toggle {
  color: #fff;
  background-color: rgba(204, 0, 102, 0.3);
  background-image: none;
  border-color: rgba(191, 0, 96, 0.3);
}

.module-magenta-30:active:focus, .module-magenta-30.active:focus,
.show > .module-magenta-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 128, 192, 0.5);
}

.module-magenta-30:disabled, .module-magenta-30.disabled {
  color: #fff;
  background-color: rgba(255, 0, 128, 0.3);
  background-image: none;
  border-color: rgba(255, 0, 128, 0.3);
}

.module-magenta-40 {
  color: #fff;
  background: rgba(255, 0, 128, 0.4) linear-gradient(180deg, rgba(248, 101, 178, 0.49), rgba(255, 0, 128, 0.4)) repeat-x;
  border-color: rgba(255, 0, 128, 0.4);
}

.module-magenta-40 .icon-container {
  background-color: rgba(217, 0, 109, 0.4);
}

.module-magenta-40 .icon-container.light {
  background-color: rgba(255, 77, 166, 0.4);
}

.module-magenta-40:hover {
  color: #fff;
  background: rgba(217, 0, 109, 0.4) linear-gradient(180deg, rgba(226, 101, 167, 0.49), rgba(217, 0, 109, 0.4)) repeat-x;
  border-color: color-yig(rgba(217, 0, 109, 0.4));
}

.module-magenta-40:focus, .module-magenta-40.focus {
  color: #fff;
  background: rgba(217, 0, 109, 0.4) linear-gradient(180deg, rgba(226, 101, 167, 0.49), rgba(217, 0, 109, 0.4)) repeat-x;
  border-color: color-yig(rgba(217, 0, 109, 0.4));
  box-shadow: 0 0 0 0.2rem rgba(255, 106, 181, 0.5);
}

.module-magenta-40:active, .module-magenta-40.active,
.show > .module-magenta-40.dropdown-toggle {
  color: #fff;
  background-color: rgba(204, 0, 102, 0.4);
  background-image: none;
  border-color: rgba(191, 0, 96, 0.4);
}

.module-magenta-40:active:focus, .module-magenta-40.active:focus,
.show > .module-magenta-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 106, 181, 0.5);
}

.module-magenta-40:disabled, .module-magenta-40.disabled {
  color: #fff;
  background-color: rgba(255, 0, 128, 0.4);
  background-image: none;
  border-color: rgba(255, 0, 128, 0.4);
}

.module-magenta-50 {
  color: #fff;
  background: rgba(255, 0, 128, 0.5) linear-gradient(180deg, rgba(249, 85, 170, 0.575), rgba(255, 0, 128, 0.5)) repeat-x;
  border-color: rgba(255, 0, 128, 0.5);
}

.module-magenta-50 .icon-container {
  background-color: rgba(217, 0, 109, 0.5);
}

.module-magenta-50 .icon-container.light {
  background-color: rgba(255, 77, 166, 0.5);
}

.module-magenta-50:hover {
  color: #fff;
  background: rgba(217, 0, 109, 0.5) linear-gradient(180deg, rgba(224, 85, 158, 0.575), rgba(217, 0, 109, 0.5)) repeat-x;
  border-color: color-yig(rgba(217, 0, 109, 0.5));
}

.module-magenta-50:focus, .module-magenta-50.focus {
  color: #fff;
  background: rgba(217, 0, 109, 0.5) linear-gradient(180deg, rgba(224, 85, 158, 0.575), rgba(217, 0, 109, 0.5)) repeat-x;
  border-color: color-yig(rgba(217, 0, 109, 0.5));
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 172, 0.5);
}

.module-magenta-50:active, .module-magenta-50.active,
.show > .module-magenta-50.dropdown-toggle {
  color: #fff;
  background-color: rgba(204, 0, 102, 0.5);
  background-image: none;
  border-color: rgba(191, 0, 96, 0.5);
}

.module-magenta-50:active:focus, .module-magenta-50.active:focus,
.show > .module-magenta-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 172, 0.5);
}

.module-magenta-50:disabled, .module-magenta-50.disabled {
  color: #fff;
  background-color: rgba(255, 0, 128, 0.5);
  background-image: none;
  border-color: rgba(255, 0, 128, 0.5);
}

.module-magenta-60 {
  color: #fff;
  background: rgba(255, 0, 128, 0.6) linear-gradient(180deg, rgba(250, 71, 164, 0.66), rgba(255, 0, 128, 0.6)) repeat-x;
  border-color: rgba(255, 0, 128, 0.6);
}

.module-magenta-60 .icon-container {
  background-color: rgba(217, 0, 109, 0.6);
}

.module-magenta-60 .icon-container.light {
  background-color: rgba(255, 77, 166, 0.6);
}

.module-magenta-60:hover {
  color: #fff;
  background: rgba(217, 0, 109, 0.6) linear-gradient(180deg, rgba(223, 71, 150, 0.66), rgba(217, 0, 109, 0.6)) repeat-x;
  border-color: color-yig(rgba(217, 0, 109, 0.6));
}

.module-magenta-60:focus, .module-magenta-60.focus {
  color: #fff;
  background: rgba(217, 0, 109, 0.6) linear-gradient(180deg, rgba(223, 71, 150, 0.66), rgba(217, 0, 109, 0.6)) repeat-x;
  border-color: color-yig(rgba(217, 0, 109, 0.6));
  box-shadow: 0 0 0 0.2rem rgba(255, 74, 165, 0.5);
}

.module-magenta-60:active, .module-magenta-60.active,
.show > .module-magenta-60.dropdown-toggle {
  color: #fff;
  background-color: rgba(204, 0, 102, 0.6);
  background-image: none;
  border-color: rgba(191, 0, 96, 0.6);
}

.module-magenta-60:active:focus, .module-magenta-60.active:focus,
.show > .module-magenta-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 74, 165, 0.5);
}

.module-magenta-60:disabled, .module-magenta-60.disabled {
  color: #fff;
  background-color: rgba(255, 0, 128, 0.6);
  background-image: none;
  border-color: rgba(255, 0, 128, 0.6);
}

.module-magenta-70 {
  color: #fff;
  background: rgba(255, 0, 128, 0.7) linear-gradient(180deg, rgba(251, 60, 158, 0.745), rgba(255, 0, 128, 0.7)) repeat-x;
  border-color: rgba(255, 0, 128, 0.7);
}

.module-magenta-70 .icon-container {
  background-color: rgba(217, 0, 109, 0.7);
}

.module-magenta-70 .icon-container.light {
  background-color: rgba(255, 77, 166, 0.7);
}

.module-magenta-70:hover {
  color: #fff;
  background: rgba(217, 0, 109, 0.7) linear-gradient(180deg, rgba(222, 60, 144, 0.745), rgba(217, 0, 109, 0.7)) repeat-x;
  border-color: color-yig(rgba(217, 0, 109, 0.7));
}

.module-magenta-70:focus, .module-magenta-70.focus {
  color: #fff;
  background: rgba(217, 0, 109, 0.7) linear-gradient(180deg, rgba(222, 60, 144, 0.745), rgba(217, 0, 109, 0.7)) repeat-x;
  border-color: color-yig(rgba(217, 0, 109, 0.7));
  box-shadow: 0 0 0 0.2rem rgba(255, 63, 159, 0.5);
}

.module-magenta-70:active, .module-magenta-70.active,
.show > .module-magenta-70.dropdown-toggle {
  color: #fff;
  background-color: rgba(204, 0, 102, 0.7);
  background-image: none;
  border-color: rgba(191, 0, 96, 0.7);
}

.module-magenta-70:active:focus, .module-magenta-70.active:focus,
.show > .module-magenta-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 63, 159, 0.5);
}

.module-magenta-70:disabled, .module-magenta-70.disabled {
  color: #fff;
  background-color: rgba(255, 0, 128, 0.7);
  background-image: none;
  border-color: rgba(255, 0, 128, 0.7);
}

.module-magenta-80 {
  color: #fff;
  background: rgba(255, 0, 128, 0.8) linear-gradient(180deg, rgba(251, 51, 154, 0.83), rgba(255, 0, 128, 0.8)) repeat-x;
  border-color: rgba(255, 0, 128, 0.8);
}

.module-magenta-80 .icon-container {
  background-color: rgba(217, 0, 109, 0.8);
}

.module-magenta-80 .icon-container.light {
  background-color: rgba(255, 77, 166, 0.8);
}

.module-magenta-80:hover {
  color: #fff;
  background: rgba(217, 0, 109, 0.8) linear-gradient(180deg, rgba(221, 51, 138, 0.83), rgba(217, 0, 109, 0.8)) repeat-x;
  border-color: color-yig(rgba(217, 0, 109, 0.8));
}

.module-magenta-80:focus, .module-magenta-80.focus {
  color: #fff;
  background: rgba(217, 0, 109, 0.8) linear-gradient(180deg, rgba(221, 51, 138, 0.83), rgba(217, 0, 109, 0.8)) repeat-x;
  border-color: color-yig(rgba(217, 0, 109, 0.8));
  box-shadow: 0 0 0 0.2rem rgba(255, 53, 155, 0.5);
}

.module-magenta-80:active, .module-magenta-80.active,
.show > .module-magenta-80.dropdown-toggle {
  color: #fff;
  background-color: rgba(204, 0, 102, 0.8);
  background-image: none;
  border-color: rgba(191, 0, 96, 0.8);
}

.module-magenta-80:active:focus, .module-magenta-80.active:focus,
.show > .module-magenta-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 53, 155, 0.5);
}

.module-magenta-80:disabled, .module-magenta-80.disabled {
  color: #fff;
  background-color: rgba(255, 0, 128, 0.8);
  background-image: none;
  border-color: rgba(255, 0, 128, 0.8);
}

.module-magenta-90 {
  color: #fff;
  background: rgba(255, 0, 128, 0.9) linear-gradient(180deg, rgba(252, 43, 150, 0.915), rgba(255, 0, 128, 0.9)) repeat-x;
  border-color: rgba(255, 0, 128, 0.9);
}

.module-magenta-90 .icon-container {
  background-color: rgba(217, 0, 109, 0.9);
}

.module-magenta-90 .icon-container.light {
  background-color: rgba(255, 77, 166, 0.9);
}

.module-magenta-90:hover {
  color: #fff;
  background: rgba(217, 0, 109, 0.9) linear-gradient(180deg, rgba(221, 43, 134, 0.915), rgba(217, 0, 109, 0.9)) repeat-x;
  border-color: color-yig(rgba(217, 0, 109, 0.9));
}

.module-magenta-90:focus, .module-magenta-90.focus {
  color: #fff;
  background: rgba(217, 0, 109, 0.9) linear-gradient(180deg, rgba(221, 43, 134, 0.915), rgba(217, 0, 109, 0.9)) repeat-x;
  border-color: color-yig(rgba(217, 0, 109, 0.9));
  box-shadow: 0 0 0 0.2rem rgba(255, 45, 151, 0.5);
}

.module-magenta-90:active, .module-magenta-90.active,
.show > .module-magenta-90.dropdown-toggle {
  color: #fff;
  background-color: rgba(204, 0, 102, 0.9);
  background-image: none;
  border-color: rgba(191, 0, 96, 0.9);
}

.module-magenta-90:active:focus, .module-magenta-90.active:focus,
.show > .module-magenta-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 45, 151, 0.5);
}

.module-magenta-90:disabled, .module-magenta-90.disabled {
  color: #fff;
  background-color: rgba(255, 0, 128, 0.9);
  background-image: none;
  border-color: rgba(255, 0, 128, 0.9);
}

.module-contrast-0 {
  color: #273a4d;
  background: #fff linear-gradient(180deg, #fcfefe, #fff) repeat-x;
  border-color: #fff;
}

.module-contrast-0 .icon-container {
  background-color: #ececec;
}

.module-contrast-0 .icon-container.light {
  background-color: white;
}

.module-contrast-0:hover {
  color: #273a4d;
  background: #ececec linear-gradient(180deg, #ecedee, #ececec) repeat-x;
  border-color: color-yig(#ececec);
}

.module-contrast-0:focus, .module-contrast-0.focus {
  color: #273a4d;
  background: #ececec linear-gradient(180deg, #ecedee, #ececec) repeat-x;
  border-color: color-yig(#ececec);
  box-shadow: 0 0 0 0.2rem rgba(223, 225, 228, 0.5);
}

.module-contrast-0:active, .module-contrast-0.active,
.show > .module-contrast-0.dropdown-toggle {
  color: #273a4d;
  background-color: #e6e6e6;
  background-image: none;
  border-color: #dfdfdf;
}

.module-contrast-0:active:focus, .module-contrast-0.active:focus,
.show > .module-contrast-0.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 225, 228, 0.5);
}

.module-contrast-0:disabled, .module-contrast-0.disabled {
  color: #273a4d;
  background-color: #fff;
  background-image: none;
  border-color: #fff;
}

.module-contrast-5 {
  color: #273a4d;
  background: #f2f2f2 linear-gradient(180deg, #f1f2f3, #f2f2f2) repeat-x;
  border-color: #f2f2f2;
}

.module-contrast-5 .icon-container {
  background-color: #dfdfdf;
}

.module-contrast-5 .icon-container.light {
  background-color: white;
}

.module-contrast-5:hover {
  color: #273a4d;
  background: #dfdfdf linear-gradient(180deg, #e1e2e3, #dfdfdf) repeat-x;
  border-color: color-yig(#dfdfdf);
}

.module-contrast-5:focus, .module-contrast-5.focus {
  color: #273a4d;
  background: #dfdfdf linear-gradient(180deg, #e1e2e3, #dfdfdf) repeat-x;
  border-color: color-yig(#dfdfdf);
  box-shadow: 0 0 0 0.2rem rgba(212, 214, 217, 0.5);
}

.module-contrast-5:active, .module-contrast-5.active,
.show > .module-contrast-5.dropdown-toggle {
  color: #273a4d;
  background-color: #d9d9d9;
  background-image: none;
  border-color: #d2d2d2;
}

.module-contrast-5:active:focus, .module-contrast-5.active:focus,
.show > .module-contrast-5.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 214, 217, 0.5);
}

.module-contrast-5:disabled, .module-contrast-5.disabled {
  color: #273a4d;
  background-color: #f2f2f2;
  background-image: none;
  border-color: #f2f2f2;
}

.module-contrast-10 {
  color: #273a4d;
  background: #e6e6e6 linear-gradient(180deg, #e7e8e9, #e6e6e6) repeat-x;
  border-color: #e6e6e6;
}

.module-contrast-10 .icon-container {
  background-color: lightgray;
}

.module-contrast-10 .icon-container.light {
  background-color: white;
}

.module-contrast-10:hover {
  color: #273a4d;
  background: lightgray linear-gradient(180deg, #d7d8d9, lightgray) repeat-x;
  border-color: color-yig(lightgray);
}

.module-contrast-10:focus, .module-contrast-10.focus {
  color: #273a4d;
  background: lightgray linear-gradient(180deg, #d7d8d9, lightgray) repeat-x;
  border-color: color-yig(lightgray);
  box-shadow: 0 0 0 0.2rem rgba(201, 204, 207, 0.5);
}

.module-contrast-10:active, .module-contrast-10.active,
.show > .module-contrast-10.dropdown-toggle {
  color: #273a4d;
  background-color: #cdcdcd;
  background-image: none;
  border-color: #c6c6c6;
}

.module-contrast-10:active:focus, .module-contrast-10.active:focus,
.show > .module-contrast-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(201, 204, 207, 0.5);
}

.module-contrast-10:disabled, .module-contrast-10.disabled {
  color: #273a4d;
  background-color: #e6e6e6;
  background-image: none;
  border-color: #e6e6e6;
}

.module-contrast-15 {
  color: #273a4d;
  background: #d9d9d9 linear-gradient(180deg, #dcddde, #d9d9d9) repeat-x;
  border-color: #d9d9d9;
}

.module-contrast-15 .icon-container {
  background-color: #c6c6c6;
}

.module-contrast-15 .icon-container.light {
  background-color: white;
}

.module-contrast-15:hover {
  color: #273a4d;
  background: #c6c6c6 linear-gradient(180deg, #cccdce, #c6c6c6) repeat-x;
  border-color: color-yig(#c6c6c6);
}

.module-contrast-15:focus, .module-contrast-15.focus {
  color: #273a4d;
  background: #c6c6c6 linear-gradient(180deg, #cccdce, #c6c6c6) repeat-x;
  border-color: color-yig(#c6c6c6);
  box-shadow: 0 0 0 0.2rem rgba(190, 193, 196, 0.5);
}

.module-contrast-15:active, .module-contrast-15.active,
.show > .module-contrast-15.dropdown-toggle {
  color: #273a4d;
  background-color: silver;
  background-image: none;
  border-color: #b9b9b9;
}

.module-contrast-15:active:focus, .module-contrast-15.active:focus,
.show > .module-contrast-15.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(190, 193, 196, 0.5);
}

.module-contrast-15:disabled, .module-contrast-15.disabled {
  color: #273a4d;
  background-color: #d9d9d9;
  background-image: none;
  border-color: #d9d9d9;
}

.module-contrast-20 {
  color: #273a4d;
  background: #ccc linear-gradient(180deg, #d1d2d3, #ccc) repeat-x;
  border-color: #ccc;
}

.module-contrast-20 .icon-container {
  background-color: #b9b9b9;
}

.module-contrast-20 .icon-container.light {
  background-color: #f2f2f2;
}

.module-contrast-20:hover {
  color: #273a4d;
  background: #b9b9b9 linear-gradient(180deg, #c1c2c3, #b9b9b9) repeat-x;
  border-color: color-yig(#b9b9b9);
}

.module-contrast-20:focus, .module-contrast-20.focus {
  color: #273a4d;
  background: #b9b9b9 linear-gradient(180deg, #c1c2c3, #b9b9b9) repeat-x;
  border-color: color-yig(#b9b9b9);
  box-shadow: 0 0 0 0.2rem rgba(179, 182, 185, 0.5);
}

.module-contrast-20:active, .module-contrast-20.active,
.show > .module-contrast-20.dropdown-toggle {
  color: #273a4d;
  background-color: #b3b3b3;
  background-image: none;
  border-color: #acacac;
}

.module-contrast-20:active:focus, .module-contrast-20.active:focus,
.show > .module-contrast-20.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(179, 182, 185, 0.5);
}

.module-contrast-20:disabled, .module-contrast-20.disabled {
  color: #273a4d;
  background-color: #ccc;
  background-image: none;
  border-color: #ccc;
}

.module-contrast-25 {
  color: #273a4d;
  background: #bfbfbf linear-gradient(180deg, #c6c7c8, #bfbfbf) repeat-x;
  border-color: #bfbfbf;
}

.module-contrast-25 .icon-container {
  background-color: #acacac;
}

.module-contrast-25 .icon-container.light {
  background-color: #e5e5e5;
}

.module-contrast-25:hover {
  color: #273a4d;
  background: #acacac linear-gradient(180deg, #b6b7b8, #acacac) repeat-x;
  border-color: color-yig(#acacac);
}

.module-contrast-25:focus, .module-contrast-25.focus {
  color: #273a4d;
  background: #acacac linear-gradient(180deg, #b6b7b8, #acacac) repeat-x;
  border-color: color-yig(#acacac);
  box-shadow: 0 0 0 0.2rem rgba(168, 171, 174, 0.5);
}

.module-contrast-25:active, .module-contrast-25.active,
.show > .module-contrast-25.dropdown-toggle {
  color: #273a4d;
  background-color: #a6a6a6;
  background-image: none;
  border-color: #9f9f9f;
}

.module-contrast-25:active:focus, .module-contrast-25.active:focus,
.show > .module-contrast-25.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(168, 171, 174, 0.5);
}

.module-contrast-25:disabled, .module-contrast-25.disabled {
  color: #273a4d;
  background-color: #bfbfbf;
  background-image: none;
  border-color: #bfbfbf;
}

.module-contrast-30 {
  color: #273a4d;
  background: #b3b3b3 linear-gradient(180deg, #bcbdbe, #b3b3b3) repeat-x;
  border-color: #b3b3b3;
}

.module-contrast-30 .icon-container {
  background-color: #a0a0a0;
}

.module-contrast-30 .icon-container.light {
  background-color: #d9d9d9;
}

.module-contrast-30:hover {
  color: #273a4d;
  background: #a0a0a0 linear-gradient(180deg, #acadad, #a0a0a0) repeat-x;
  border-color: color-yig(#a0a0a0);
}

.module-contrast-30:focus, .module-contrast-30.focus {
  color: #273a4d;
  background: #a0a0a0 linear-gradient(180deg, #acadad, #a0a0a0) repeat-x;
  border-color: color-yig(#a0a0a0);
  box-shadow: 0 0 0 0.2rem rgba(158, 161, 164, 0.5);
}

.module-contrast-30:active, .module-contrast-30.active,
.show > .module-contrast-30.dropdown-toggle {
  color: #273a4d;
  background-color: #9a9a9a;
  background-image: none;
  border-color: #939393;
}

.module-contrast-30:active:focus, .module-contrast-30.active:focus,
.show > .module-contrast-30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(158, 161, 164, 0.5);
}

.module-contrast-30:disabled, .module-contrast-30.disabled {
  color: #273a4d;
  background-color: #b3b3b3;
  background-image: none;
  border-color: #b3b3b3;
}

.module-contrast-35 {
  color: #273a4d;
  background: #a6a6a6 linear-gradient(180deg, #b1b2b3, #a6a6a6) repeat-x;
  border-color: #a6a6a6;
}

.module-contrast-35 .icon-container {
  background-color: #939393;
}

.module-contrast-35 .icon-container.light {
  background-color: #cccccc;
}

.module-contrast-35:hover {
  color: #fff;
  background: #939393 linear-gradient(180deg, #a1a2a2, #939393) repeat-x;
  border-color: color-yig(#939393);
}

.module-contrast-35:focus, .module-contrast-35.focus {
  color: #fff;
  background: #939393 linear-gradient(180deg, #a1a2a2, #939393) repeat-x;
  border-color: color-yig(#939393);
  box-shadow: 0 0 0 0.2rem rgba(147, 150, 153, 0.5);
}

.module-contrast-35:active, .module-contrast-35.active,
.show > .module-contrast-35.dropdown-toggle {
  color: #fff;
  background-color: #8d8d8d;
  background-image: none;
  border-color: #868686;
}

.module-contrast-35:active:focus, .module-contrast-35.active:focus,
.show > .module-contrast-35.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 150, 153, 0.5);
}

.module-contrast-35:disabled, .module-contrast-35.disabled {
  color: #273a4d;
  background-color: #a6a6a6;
  background-image: none;
  border-color: #a6a6a6;
}

.module-contrast-40 {
  color: #273a4d;
  background: #999 linear-gradient(180deg, #a6a7a8, #999) repeat-x;
  border-color: #999;
}

.module-contrast-40 .icon-container {
  background-color: #868686;
}

.module-contrast-40 .icon-container.light {
  background-color: #bfbfbf;
}

.module-contrast-40:hover {
  color: #fff;
  background: #868686 linear-gradient(180deg, #959797, #868686) repeat-x;
  border-color: color-yig(#868686);
}

.module-contrast-40:focus, .module-contrast-40.focus {
  color: #fff;
  background: #868686 linear-gradient(180deg, #959797, #868686) repeat-x;
  border-color: color-yig(#868686);
  box-shadow: 0 0 0 0.2rem rgba(136, 139, 142, 0.5);
}

.module-contrast-40:active, .module-contrast-40.active,
.show > .module-contrast-40.dropdown-toggle {
  color: #fff;
  background-color: gray;
  background-image: none;
  border-color: #797979;
}

.module-contrast-40:active:focus, .module-contrast-40.active:focus,
.show > .module-contrast-40.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(136, 139, 142, 0.5);
}

.module-contrast-40:disabled, .module-contrast-40.disabled {
  color: #273a4d;
  background-color: #999;
  background-image: none;
  border-color: #999;
}

.module-contrast-45 {
  color: #fff;
  background: #8c8c8c linear-gradient(180deg, #9b9c9d, #8c8c8c) repeat-x;
  border-color: #8c8c8c;
}

.module-contrast-45 .icon-container {
  background-color: #797979;
}

.module-contrast-45 .icon-container.light {
  background-color: #b2b2b2;
}

.module-contrast-45:hover {
  color: #fff;
  background: #797979 linear-gradient(180deg, #8a8b8c, #797979) repeat-x;
  border-color: color-yig(#797979);
}

.module-contrast-45:focus, .module-contrast-45.focus {
  color: #fff;
  background: #797979 linear-gradient(180deg, #8a8b8c, #797979) repeat-x;
  border-color: color-yig(#797979);
  box-shadow: 0 0 0 0.2rem rgba(157, 157, 157, 0.5);
}

.module-contrast-45:active, .module-contrast-45.active,
.show > .module-contrast-45.dropdown-toggle {
  color: #fff;
  background-color: #737373;
  background-image: none;
  border-color: #6c6c6c;
}

.module-contrast-45:active:focus, .module-contrast-45.active:focus,
.show > .module-contrast-45.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(157, 157, 157, 0.5);
}

.module-contrast-45:disabled, .module-contrast-45.disabled {
  color: #fff;
  background-color: #8c8c8c;
  background-image: none;
  border-color: #8c8c8c;
}

.module-contrast-50 {
  color: #fff;
  background: #808080 linear-gradient(180deg, #919292, #808080) repeat-x;
  border-color: #808080;
}

.module-contrast-50 .icon-container {
  background-color: #6d6d6d;
}

.module-contrast-50 .icon-container.light {
  background-color: #a6a6a6;
}

.module-contrast-50:hover {
  color: #fff;
  background: #6d6d6d linear-gradient(180deg, #808182, #6d6d6d) repeat-x;
  border-color: color-yig(#6d6d6d);
}

.module-contrast-50:focus, .module-contrast-50.focus {
  color: #fff;
  background: #6d6d6d linear-gradient(180deg, #808182, #6d6d6d) repeat-x;
  border-color: color-yig(#6d6d6d);
  box-shadow: 0 0 0 0.2rem rgba(147, 147, 147, 0.5);
}

.module-contrast-50:active, .module-contrast-50.active,
.show > .module-contrast-50.dropdown-toggle {
  color: #fff;
  background-color: #676767;
  background-image: none;
  border-color: #606060;
}

.module-contrast-50:active:focus, .module-contrast-50.active:focus,
.show > .module-contrast-50.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 147, 147, 0.5);
}

.module-contrast-50:disabled, .module-contrast-50.disabled {
  color: #fff;
  background-color: #808080;
  background-image: none;
  border-color: #808080;
}

.module-contrast-55 {
  color: #fff;
  background: #737373 linear-gradient(180deg, #858787, #737373) repeat-x;
  border-color: #737373;
}

.module-contrast-55 .icon-container {
  background-color: #606060;
}

.module-contrast-55 .icon-container.light {
  background-color: #999999;
}

.module-contrast-55:hover {
  color: #fff;
  background: #606060 linear-gradient(180deg, #757677, #606060) repeat-x;
  border-color: color-yig(#606060);
}

.module-contrast-55:focus, .module-contrast-55.focus {
  color: #fff;
  background: #606060 linear-gradient(180deg, #757677, #606060) repeat-x;
  border-color: color-yig(#606060);
  box-shadow: 0 0 0 0.2rem rgba(136, 136, 136, 0.5);
}

.module-contrast-55:active, .module-contrast-55.active,
.show > .module-contrast-55.dropdown-toggle {
  color: #fff;
  background-color: #5a5a5a;
  background-image: none;
  border-color: #535353;
}

.module-contrast-55:active:focus, .module-contrast-55.active:focus,
.show > .module-contrast-55.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(136, 136, 136, 0.5);
}

.module-contrast-55:disabled, .module-contrast-55.disabled {
  color: #fff;
  background-color: #737373;
  background-image: none;
  border-color: #737373;
}

.module-contrast-60 {
  color: #fff;
  background: #666 linear-gradient(180deg, #7a7b7c, #666) repeat-x;
  border-color: #666;
}

.module-contrast-60 .icon-container {
  background-color: #535353;
}

.module-contrast-60 .icon-container.light {
  background-color: #8c8c8c;
}

.module-contrast-60:hover {
  color: #fff;
  background: #535353 linear-gradient(180deg, #6a6b6c, #535353) repeat-x;
  border-color: color-yig(#535353);
}

.module-contrast-60:focus, .module-contrast-60.focus {
  color: #fff;
  background: #535353 linear-gradient(180deg, #6a6b6c, #535353) repeat-x;
  border-color: color-yig(#535353);
  box-shadow: 0 0 0 0.2rem rgba(125, 125, 125, 0.5);
}

.module-contrast-60:active, .module-contrast-60.active,
.show > .module-contrast-60.dropdown-toggle {
  color: #fff;
  background-color: #4d4d4d;
  background-image: none;
  border-color: #464646;
}

.module-contrast-60:active:focus, .module-contrast-60.active:focus,
.show > .module-contrast-60.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 125, 125, 0.5);
}

.module-contrast-60:disabled, .module-contrast-60.disabled {
  color: #fff;
  background-color: #666;
  background-image: none;
  border-color: #666;
}

.module-contrast-65 {
  color: #fff;
  background: #595959 linear-gradient(180deg, #6f7071, #595959) repeat-x;
  border-color: #595959;
}

.module-contrast-65 .icon-container {
  background-color: #464646;
}

.module-contrast-65 .icon-container.light {
  background-color: #7f7f7f;
}

.module-contrast-65:hover {
  color: #fff;
  background: #464646 linear-gradient(180deg, #5f6061, #464646) repeat-x;
  border-color: color-yig(#464646);
}

.module-contrast-65:focus, .module-contrast-65.focus {
  color: #fff;
  background: #464646 linear-gradient(180deg, #5f6061, #464646) repeat-x;
  border-color: color-yig(#464646);
  box-shadow: 0 0 0 0.2rem rgba(114, 114, 114, 0.5);
}

.module-contrast-65:active, .module-contrast-65.active,
.show > .module-contrast-65.dropdown-toggle {
  color: #fff;
  background-color: #404040;
  background-image: none;
  border-color: #393939;
}

.module-contrast-65:active:focus, .module-contrast-65.active:focus,
.show > .module-contrast-65.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(114, 114, 114, 0.5);
}

.module-contrast-65:disabled, .module-contrast-65.disabled {
  color: #fff;
  background-color: #595959;
  background-image: none;
  border-color: #595959;
}

.module-contrast-70 {
  color: #fff;
  background: #4d4d4d linear-gradient(180deg, #656667, #4d4d4d) repeat-x;
  border-color: #4d4d4d;
}

.module-contrast-70 .icon-container {
  background-color: #3a3a3a;
}

.module-contrast-70 .icon-container.light {
  background-color: #737373;
}

.module-contrast-70:hover {
  color: #fff;
  background: #3a3a3a linear-gradient(180deg, #555657, #3a3a3a) repeat-x;
  border-color: color-yig(#3a3a3a);
}

.module-contrast-70:focus, .module-contrast-70.focus {
  color: #fff;
  background: #3a3a3a linear-gradient(180deg, #555657, #3a3a3a) repeat-x;
  border-color: color-yig(#3a3a3a);
  box-shadow: 0 0 0 0.2rem rgba(104, 104, 104, 0.5);
}

.module-contrast-70:active, .module-contrast-70.active,
.show > .module-contrast-70.dropdown-toggle {
  color: #fff;
  background-color: #343434;
  background-image: none;
  border-color: #2d2d2d;
}

.module-contrast-70:active:focus, .module-contrast-70.active:focus,
.show > .module-contrast-70.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(104, 104, 104, 0.5);
}

.module-contrast-70:disabled, .module-contrast-70.disabled {
  color: #fff;
  background-color: #4d4d4d;
  background-image: none;
  border-color: #4d4d4d;
}

.module-contrast-75 {
  color: #fff;
  background: #404040 linear-gradient(180deg, #5a5b5c, #404040) repeat-x;
  border-color: #404040;
}

.module-contrast-75 .icon-container {
  background-color: #2d2d2d;
}

.module-contrast-75 .icon-container.light {
  background-color: #666666;
}

.module-contrast-75:hover {
  color: #fff;
  background: #2d2d2d linear-gradient(180deg, #4a4b4c, #2d2d2d) repeat-x;
  border-color: color-yig(#2d2d2d);
}

.module-contrast-75:focus, .module-contrast-75.focus {
  color: #fff;
  background: #2d2d2d linear-gradient(180deg, #4a4b4c, #2d2d2d) repeat-x;
  border-color: color-yig(#2d2d2d);
  box-shadow: 0 0 0 0.2rem rgba(93, 93, 93, 0.5);
}

.module-contrast-75:active, .module-contrast-75.active,
.show > .module-contrast-75.dropdown-toggle {
  color: #fff;
  background-color: #272727;
  background-image: none;
  border-color: #202020;
}

.module-contrast-75:active:focus, .module-contrast-75.active:focus,
.show > .module-contrast-75.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(93, 93, 93, 0.5);
}

.module-contrast-75:disabled, .module-contrast-75.disabled {
  color: #fff;
  background-color: #404040;
  background-image: none;
  border-color: #404040;
}

.module-contrast-80 {
  color: #fff;
  background: #333 linear-gradient(180deg, #4f5051, #333) repeat-x;
  border-color: #333;
}

.module-contrast-80 .icon-container {
  background-color: #202020;
}

.module-contrast-80 .icon-container.light {
  background-color: #595959;
}

.module-contrast-80:hover {
  color: #fff;
  background: #202020 linear-gradient(180deg, #3f4041, #202020) repeat-x;
  border-color: color-yig(#202020);
}

.module-contrast-80:focus, .module-contrast-80.focus {
  color: #fff;
  background: #202020 linear-gradient(180deg, #3f4041, #202020) repeat-x;
  border-color: color-yig(#202020);
  box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
}

.module-contrast-80:active, .module-contrast-80.active,
.show > .module-contrast-80.dropdown-toggle {
  color: #fff;
  background-color: #1a1a1a;
  background-image: none;
  border-color: #131313;
}

.module-contrast-80:active:focus, .module-contrast-80.active:focus,
.show > .module-contrast-80.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
}

.module-contrast-80:disabled, .module-contrast-80.disabled {
  color: #fff;
  background-color: #333;
  background-image: none;
  border-color: #333;
}

.module-contrast-85 {
  color: #fff;
  background: #262626 linear-gradient(180deg, #444546, #262626) repeat-x;
  border-color: #262626;
}

.module-contrast-85 .icon-container {
  background-color: #131313;
}

.module-contrast-85 .icon-container.light {
  background-color: #4c4c4c;
}

.module-contrast-85:hover {
  color: #fff;
  background: #131313 linear-gradient(180deg, #343536, #131313) repeat-x;
  border-color: color-yig(#131313);
}

.module-contrast-85:focus, .module-contrast-85.focus {
  color: #fff;
  background: #131313 linear-gradient(180deg, #343536, #131313) repeat-x;
  border-color: color-yig(#131313);
  box-shadow: 0 0 0 0.2rem rgba(71, 71, 71, 0.5);
}

.module-contrast-85:active, .module-contrast-85.active,
.show > .module-contrast-85.dropdown-toggle {
  color: #fff;
  background-color: #0d0d0d;
  background-image: none;
  border-color: #060606;
}

.module-contrast-85:active:focus, .module-contrast-85.active:focus,
.show > .module-contrast-85.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 71, 71, 0.5);
}

.module-contrast-85:disabled, .module-contrast-85.disabled {
  color: #fff;
  background-color: #262626;
  background-image: none;
  border-color: #262626;
}

.module-contrast-90 {
  color: #fff;
  background: #1a1a1a linear-gradient(180deg, #3a3b3c, #1a1a1a) repeat-x;
  border-color: #1a1a1a;
}

.module-contrast-90 .icon-container {
  background-color: #070707;
}

.module-contrast-90 .icon-container.light {
  background-color: #404040;
}

.module-contrast-90:hover {
  color: #fff;
  background: #070707 linear-gradient(180deg, #2a2b2b, #070707) repeat-x;
  border-color: color-yig(#070707);
}

.module-contrast-90:focus, .module-contrast-90.focus {
  color: #fff;
  background: #070707 linear-gradient(180deg, #2a2b2b, #070707) repeat-x;
  border-color: color-yig(#070707);
  box-shadow: 0 0 0 0.2rem rgba(60, 60, 60, 0.5);
}

.module-contrast-90:active, .module-contrast-90.active,
.show > .module-contrast-90.dropdown-toggle {
  color: #fff;
  background-color: #010101;
  background-image: none;
  border-color: black;
}

.module-contrast-90:active:focus, .module-contrast-90.active:focus,
.show > .module-contrast-90.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 60, 60, 0.5);
}

.module-contrast-90:disabled, .module-contrast-90.disabled {
  color: #fff;
  background-color: #1a1a1a;
  background-image: none;
  border-color: #1a1a1a;
}

.module-contrast-95 {
  color: #fff;
  background: #0d0d0d linear-gradient(180deg, #2f3031, #0d0d0d) repeat-x;
  border-color: #0d0d0d;
}

.module-contrast-95 .icon-container {
  background-color: black;
}

.module-contrast-95 .icon-container.light {
  background-color: #333333;
}

.module-contrast-95:hover {
  color: #fff;
  background: black linear-gradient(180deg, #242526, black) repeat-x;
  border-color: color-yig(black);
}

.module-contrast-95:focus, .module-contrast-95.focus {
  color: #fff;
  background: black linear-gradient(180deg, #242526, black) repeat-x;
  border-color: color-yig(black);
  box-shadow: 0 0 0 0.2rem rgba(49, 49, 49, 0.5);
}

.module-contrast-95:active, .module-contrast-95.active,
.show > .module-contrast-95.dropdown-toggle {
  color: #fff;
  background-color: black;
  background-image: none;
  border-color: black;
}

.module-contrast-95:active:focus, .module-contrast-95.active:focus,
.show > .module-contrast-95.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 49, 49, 0.5);
}

.module-contrast-95:disabled, .module-contrast-95.disabled {
  color: #fff;
  background-color: #0d0d0d;
  background-image: none;
  border-color: #0d0d0d;
}

.module-contrast-100 {
  color: #fff;
  background: #000 linear-gradient(180deg, #242526, #000) repeat-x;
  border-color: #000;
}

.module-contrast-100 .icon-container {
  background-color: black;
}

.module-contrast-100 .icon-container.light {
  background-color: #262626;
}

.module-contrast-100:hover {
  color: #fff;
  background: black linear-gradient(180deg, #242526, black) repeat-x;
  border-color: color-yig(black);
}

.module-contrast-100:focus, .module-contrast-100.focus {
  color: #fff;
  background: black linear-gradient(180deg, #242526, black) repeat-x;
  border-color: color-yig(black);
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.module-contrast-100:active, .module-contrast-100.active,
.show > .module-contrast-100.dropdown-toggle {
  color: #fff;
  background-color: black;
  background-image: none;
  border-color: black;
}

.module-contrast-100:active:focus, .module-contrast-100.active:focus,
.show > .module-contrast-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.module-contrast-100:disabled, .module-contrast-100.disabled {
  color: #fff;
  background-color: #000;
  background-image: none;
  border-color: #000;
}

.module-outline-ice {
  color: #eef5fa;
  border: 2px solid #eef5fa;
}

.module-outline-ice .icon-container {
  border: 2px solid #eef5fa;
}

.module-outline-ice:hover {
  background: #eef5fa linear-gradient(180deg, #eef5fa, #eef5fa) repeat-x;
  color: #273a4d;
  border-color: #eef5fa;
}

.module-outline-ice:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-ice:hover .icon-container * {
  color: #273a4d;
}

.module-outline-ice:focus, .module-outline-ice.focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 245, 250, 0.5);
}

.module-outline-ice:active, .module-outline-ice.active,
.show > .module-outline-ice.dropdown-toggle {
  color: #273a4d;
  background-color: #eef5fa;
  border-color: #eef5fa;
}

.module-outline-ice:active:focus, .module-outline-ice.active:focus,
.show > .module-outline-ice.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 245, 250, 0.5);
}

.module-outline-ice:disabled, .module-outline-ice.disabled {
  color: #eef5fa;
  background-color: transparent;
}

.module-outline-super-light {
  color: #deedf6;
  border: 2px solid #deedf6;
}

.module-outline-super-light .icon-container {
  border: 2px solid #deedf6;
}

.module-outline-super-light:hover {
  background: #deedf6 linear-gradient(180deg, #e0eef7, #deedf6) repeat-x;
  color: #273a4d;
  border-color: #deedf6;
}

.module-outline-super-light:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-super-light:hover .icon-container * {
  color: #273a4d;
}

.module-outline-super-light:focus, .module-outline-super-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 237, 246, 0.5);
}

.module-outline-super-light:active, .module-outline-super-light.active,
.show > .module-outline-super-light.dropdown-toggle {
  color: #273a4d;
  background-color: #deedf6;
  border-color: #deedf6;
}

.module-outline-super-light:active:focus, .module-outline-super-light.active:focus,
.show > .module-outline-super-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 237, 246, 0.5);
}

.module-outline-super-light:disabled, .module-outline-super-light.disabled {
  color: #deedf6;
  background-color: transparent;
}

.module-outline-light {
  color: #b9d6e6;
  border: 2px solid #b9d6e6;
}

.module-outline-light .icon-container {
  border: 2px solid #b9d6e6;
}

.module-outline-light:hover {
  background: #b9d6e6 linear-gradient(180deg, #c1dbe9, #b9d6e6) repeat-x;
  color: #273a4d;
  border-color: #b9d6e6;
}

.module-outline-light:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-light:hover .icon-container * {
  color: #273a4d;
}

.module-outline-light:focus, .module-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(185, 214, 230, 0.5);
}

.module-outline-light:active, .module-outline-light.active,
.show > .module-outline-light.dropdown-toggle {
  color: #273a4d;
  background-color: #b9d6e6;
  border-color: #b9d6e6;
}

.module-outline-light:active:focus, .module-outline-light.active:focus,
.show > .module-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(185, 214, 230, 0.5);
}

.module-outline-light:disabled, .module-outline-light.disabled {
  color: #b9d6e6;
  background-color: transparent;
}

.module-outline-steel {
  color: #71a3bd;
  border: 2px solid #71a3bd;
}

.module-outline-steel .icon-container {
  border: 2px solid #71a3bd;
}

.module-outline-steel:hover {
  background: #71a3bd linear-gradient(180deg, #84afc6, #71a3bd) repeat-x;
  color: #273a4d;
  border-color: #71a3bd;
}

.module-outline-steel:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-steel:hover .icon-container * {
  color: #273a4d;
}

.module-outline-steel:focus, .module-outline-steel.focus {
  box-shadow: 0 0 0 0.2rem rgba(113, 163, 189, 0.5);
}

.module-outline-steel:active, .module-outline-steel.active,
.show > .module-outline-steel.dropdown-toggle {
  color: #273a4d;
  background-color: #71a3bd;
  border-color: #71a3bd;
}

.module-outline-steel:active:focus, .module-outline-steel.active:focus,
.show > .module-outline-steel.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(113, 163, 189, 0.5);
}

.module-outline-steel:disabled, .module-outline-steel.disabled {
  color: #71a3bd;
  background-color: transparent;
}

.module-outline-ocean {
  color: #48748b;
  border: 2px solid #48748b;
}

.module-outline-ocean .icon-container {
  border: 2px solid #48748b;
}

.module-outline-ocean:hover {
  background: #48748b linear-gradient(180deg, #61879c, #48748b) repeat-x;
  color: #fff;
  border-color: #48748b;
}

.module-outline-ocean:hover .icon-container {
  border-color: #fff;
}

.module-outline-ocean:hover .icon-container * {
  color: #fff;
}

.module-outline-ocean:focus, .module-outline-ocean.focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 116, 139, 0.5);
}

.module-outline-ocean:active, .module-outline-ocean.active,
.show > .module-outline-ocean.dropdown-toggle {
  color: #fff;
  background-color: #48748b;
  border-color: #48748b;
}

.module-outline-ocean:active:focus, .module-outline-ocean.active:focus,
.show > .module-outline-ocean.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 116, 139, 0.5);
}

.module-outline-ocean:disabled, .module-outline-ocean.disabled {
  color: #48748b;
  background-color: transparent;
}

.module-outline-midnight {
  color: #354f68;
  border: 2px solid #354f68;
}

.module-outline-midnight .icon-container {
  border: 2px solid #354f68;
}

.module-outline-midnight:hover {
  background: #354f68 linear-gradient(180deg, #51687e, #354f68) repeat-x;
  color: #fff;
  border-color: #354f68;
}

.module-outline-midnight:hover .icon-container {
  border-color: #fff;
}

.module-outline-midnight:hover .icon-container * {
  color: #fff;
}

.module-outline-midnight:focus, .module-outline-midnight.focus {
  box-shadow: 0 0 0 0.2rem rgba(53, 79, 104, 0.5);
}

.module-outline-midnight:active, .module-outline-midnight.active,
.show > .module-outline-midnight.dropdown-toggle {
  color: #fff;
  background-color: #354f68;
  border-color: #354f68;
}

.module-outline-midnight:active:focus, .module-outline-midnight.active:focus,
.show > .module-outline-midnight.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(53, 79, 104, 0.5);
}

.module-outline-midnight:disabled, .module-outline-midnight.disabled {
  color: #354f68;
  background-color: transparent;
}

.module-outline-cosmos {
  color: #273a4d;
  border: 2px solid #273a4d;
}

.module-outline-cosmos .icon-container {
  border: 2px solid #273a4d;
}

.module-outline-cosmos:hover {
  background: #273a4d linear-gradient(180deg, #455667, #273a4d) repeat-x;
  color: #fff;
  border-color: #273a4d;
}

.module-outline-cosmos:hover .icon-container {
  border-color: #fff;
}

.module-outline-cosmos:hover .icon-container * {
  color: #fff;
}

.module-outline-cosmos:focus, .module-outline-cosmos.focus {
  box-shadow: 0 0 0 0.2rem rgba(39, 58, 77, 0.5);
}

.module-outline-cosmos:active, .module-outline-cosmos.active,
.show > .module-outline-cosmos.dropdown-toggle {
  color: #fff;
  background-color: #273a4d;
  border-color: #273a4d;
}

.module-outline-cosmos:active:focus, .module-outline-cosmos.active:focus,
.show > .module-outline-cosmos.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(39, 58, 77, 0.5);
}

.module-outline-cosmos:disabled, .module-outline-cosmos.disabled {
  color: #273a4d;
  background-color: transparent;
}

.module-outline-deepdark {
  color: #0b1b2c;
  border: 2px solid #0b1b2c;
}

.module-outline-deepdark .icon-container {
  border: 2px solid #0b1b2c;
}

.module-outline-deepdark:hover {
  background: #0b1b2c linear-gradient(180deg, #2d3c4b, #0b1b2c) repeat-x;
  color: #fff;
  border-color: #0b1b2c;
}

.module-outline-deepdark:hover .icon-container {
  border-color: #fff;
}

.module-outline-deepdark:hover .icon-container * {
  color: #fff;
}

.module-outline-deepdark:focus, .module-outline-deepdark.focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 27, 44, 0.5);
}

.module-outline-deepdark:active, .module-outline-deepdark.active,
.show > .module-outline-deepdark.dropdown-toggle {
  color: #fff;
  background-color: #0b1b2c;
  border-color: #0b1b2c;
}

.module-outline-deepdark:active:focus, .module-outline-deepdark.active:focus,
.show > .module-outline-deepdark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 27, 44, 0.5);
}

.module-outline-deepdark:disabled, .module-outline-deepdark.disabled {
  color: #0b1b2c;
  background-color: transparent;
}

.module-outline-primary {
  color: #354f68;
  border: 2px solid #354f68;
}

.module-outline-primary .icon-container {
  border: 2px solid #354f68;
}

.module-outline-primary:hover {
  background: #354f68 linear-gradient(180deg, #51687e, #354f68) repeat-x;
  color: #fff;
  border-color: #354f68;
}

.module-outline-primary:hover .icon-container {
  border-color: #fff;
}

.module-outline-primary:hover .icon-container * {
  color: #fff;
}

.module-outline-primary:focus, .module-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(53, 79, 104, 0.5);
}

.module-outline-primary:active, .module-outline-primary.active,
.show > .module-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #354f68;
  border-color: #354f68;
}

.module-outline-primary:active:focus, .module-outline-primary.active:focus,
.show > .module-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(53, 79, 104, 0.5);
}

.module-outline-primary:disabled, .module-outline-primary.disabled {
  color: #354f68;
  background-color: transparent;
}

.module-outline-success {
  color: #5a9956;
  border: 2px solid #5a9956;
}

.module-outline-success .icon-container {
  border: 2px solid #5a9956;
}

.module-outline-success:hover {
  background: #5a9956 linear-gradient(180deg, #70a76f, #5a9956) repeat-x;
  color: #fff;
  border-color: #5a9956;
}

.module-outline-success:hover .icon-container {
  border-color: #fff;
}

.module-outline-success:hover .icon-container * {
  color: #fff;
}

.module-outline-success:focus, .module-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(90, 153, 86, 0.5);
}

.module-outline-success:active, .module-outline-success.active,
.show > .module-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #5a9956;
  border-color: #5a9956;
}

.module-outline-success:active:focus, .module-outline-success.active:focus,
.show > .module-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(90, 153, 86, 0.5);
}

.module-outline-success:disabled, .module-outline-success.disabled {
  color: #5a9956;
  background-color: transparent;
}

.module-outline-info {
  color: #5299e0;
  border: 2px solid #5299e0;
}

.module-outline-info .icon-container {
  border: 2px solid #5299e0;
}

.module-outline-info:hover {
  background: #5299e0 linear-gradient(180deg, #69a7e4, #5299e0) repeat-x;
  color: #fff;
  border-color: #5299e0;
}

.module-outline-info:hover .icon-container {
  border-color: #fff;
}

.module-outline-info:hover .icon-container * {
  color: #fff;
}

.module-outline-info:focus, .module-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 153, 224, 0.5);
}

.module-outline-info:active, .module-outline-info.active,
.show > .module-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #5299e0;
  border-color: #5299e0;
}

.module-outline-info:active:focus, .module-outline-info.active:focus,
.show > .module-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 153, 224, 0.5);
}

.module-outline-info:disabled, .module-outline-info.disabled {
  color: #5299e0;
  background-color: transparent;
}

.module-outline-warning {
  color: #f2d585;
  border: 2px solid #f2d585;
}

.module-outline-warning .icon-container {
  border: 2px solid #f2d585;
}

.module-outline-warning:hover {
  background: #f2d585 linear-gradient(180deg, #f1da97, #f2d585) repeat-x;
  color: #273a4d;
  border-color: #f2d585;
}

.module-outline-warning:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-warning:hover .icon-container * {
  color: #273a4d;
}

.module-outline-warning:focus, .module-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 213, 133, 0.5);
}

.module-outline-warning:active, .module-outline-warning.active,
.show > .module-outline-warning.dropdown-toggle {
  color: #273a4d;
  background-color: #f2d585;
  border-color: #f2d585;
}

.module-outline-warning:active:focus, .module-outline-warning.active:focus,
.show > .module-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 213, 133, 0.5);
}

.module-outline-warning:disabled, .module-outline-warning.disabled {
  color: #f2d585;
  background-color: transparent;
}

.module-outline-danger {
  color: #ff4136;
  border: 2px solid #ff4136;
}

.module-outline-danger .icon-container {
  border: 2px solid #ff4136;
}

.module-outline-danger:hover {
  background: #ff4136 linear-gradient(180deg, #fc5c53, #ff4136) repeat-x;
  color: #fff;
  border-color: #ff4136;
}

.module-outline-danger:hover .icon-container {
  border-color: #fff;
}

.module-outline-danger:hover .icon-container * {
  color: #fff;
}

.module-outline-danger:focus, .module-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 65, 54, 0.5);
}

.module-outline-danger:active, .module-outline-danger.active,
.show > .module-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #ff4136;
  border-color: #ff4136;
}

.module-outline-danger:active:focus, .module-outline-danger.active:focus,
.show > .module-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 65, 54, 0.5);
}

.module-outline-danger:disabled, .module-outline-danger.disabled {
  color: #ff4136;
  background-color: transparent;
}

.module-outline-dark {
  color: #333;
  border: 2px solid #333;
}

.module-outline-dark .icon-container {
  border: 2px solid #333;
}

.module-outline-dark:hover {
  background: #333 linear-gradient(180deg, #4f5051, #333) repeat-x;
  color: #fff;
  border-color: #333;
}

.module-outline-dark:hover .icon-container {
  border-color: #fff;
}

.module-outline-dark:hover .icon-container * {
  color: #fff;
}

.module-outline-dark:focus, .module-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
}

.module-outline-dark:active, .module-outline-dark.active,
.show > .module-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.module-outline-dark:active:focus, .module-outline-dark.active:focus,
.show > .module-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
}

.module-outline-dark:disabled, .module-outline-dark.disabled {
  color: #333;
  background-color: transparent;
}

.module-outline-deep-ocean {
  color: #023946;
  border: 2px solid #023946;
}

.module-outline-deep-ocean .icon-container {
  border: 2px solid #023946;
}

.module-outline-deep-ocean:hover {
  background: #023946 linear-gradient(180deg, #255561, #023946) repeat-x;
  color: #fff;
  border-color: #023946;
}

.module-outline-deep-ocean:hover .icon-container {
  border-color: #fff;
}

.module-outline-deep-ocean:hover .icon-container * {
  color: #fff;
}

.module-outline-deep-ocean:focus, .module-outline-deep-ocean.focus {
  box-shadow: 0 0 0 0.2rem rgba(2, 57, 70, 0.5);
}

.module-outline-deep-ocean:active, .module-outline-deep-ocean.active,
.show > .module-outline-deep-ocean.dropdown-toggle {
  color: #fff;
  background-color: #023946;
  border-color: #023946;
}

.module-outline-deep-ocean:active:focus, .module-outline-deep-ocean.active:focus,
.show > .module-outline-deep-ocean.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(2, 57, 70, 0.5);
}

.module-outline-deep-ocean:disabled, .module-outline-deep-ocean.disabled {
  color: #023946;
  background-color: transparent;
}

.module-outline-teal {
  color: #035f75;
  border: 2px solid #035f75;
}

.module-outline-teal .icon-container {
  border: 2px solid #035f75;
}

.module-outline-teal:hover {
  background: #035f75 linear-gradient(180deg, #267689, #035f75) repeat-x;
  color: #fff;
  border-color: #035f75;
}

.module-outline-teal:hover .icon-container {
  border-color: #fff;
}

.module-outline-teal:hover .icon-container * {
  color: #fff;
}

.module-outline-teal:focus, .module-outline-teal.focus {
  box-shadow: 0 0 0 0.2rem rgba(3, 95, 117, 0.5);
}

.module-outline-teal:active, .module-outline-teal.active,
.show > .module-outline-teal.dropdown-toggle {
  color: #fff;
  background-color: #035f75;
  border-color: #035f75;
}

.module-outline-teal:active:focus, .module-outline-teal.active:focus,
.show > .module-outline-teal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(3, 95, 117, 0.5);
}

.module-outline-teal:disabled, .module-outline-teal.disabled {
  color: #035f75;
  background-color: transparent;
}

.module-outline-petrol {
  color: #0f99ba;
  border: 2px solid #0f99ba;
}

.module-outline-petrol .icon-container {
  border: 2px solid #0f99ba;
}

.module-outline-petrol:hover {
  background: #0f99ba linear-gradient(180deg, #30a7c4, #0f99ba) repeat-x;
  color: #fff;
  border-color: #0f99ba;
}

.module-outline-petrol:hover .icon-container {
  border-color: #fff;
}

.module-outline-petrol:hover .icon-container * {
  color: #fff;
}

.module-outline-petrol:focus, .module-outline-petrol.focus {
  box-shadow: 0 0 0 0.2rem rgba(15, 153, 186, 0.5);
}

.module-outline-petrol:active, .module-outline-petrol.active,
.show > .module-outline-petrol.dropdown-toggle {
  color: #fff;
  background-color: #0f99ba;
  border-color: #0f99ba;
}

.module-outline-petrol:active:focus, .module-outline-petrol.active:focus,
.show > .module-outline-petrol.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(15, 153, 186, 0.5);
}

.module-outline-petrol:disabled, .module-outline-petrol.disabled {
  color: #0f99ba;
  background-color: transparent;
}

.module-outline-turquoise {
  color: #14b1d7;
  border: 2px solid #14b1d7;
}

.module-outline-turquoise .icon-container {
  border: 2px solid #14b1d7;
}

.module-outline-turquoise:hover {
  background: #14b1d7 linear-gradient(180deg, #35bbdc, #14b1d7) repeat-x;
  color: #fff;
  border-color: #14b1d7;
}

.module-outline-turquoise:hover .icon-container {
  border-color: #fff;
}

.module-outline-turquoise:hover .icon-container * {
  color: #fff;
}

.module-outline-turquoise:focus, .module-outline-turquoise.focus {
  box-shadow: 0 0 0 0.2rem rgba(20, 177, 215, 0.5);
}

.module-outline-turquoise:active, .module-outline-turquoise.active,
.show > .module-outline-turquoise.dropdown-toggle {
  color: #fff;
  background-color: #14b1d7;
  border-color: #14b1d7;
}

.module-outline-turquoise:active:focus, .module-outline-turquoise.active:focus,
.show > .module-outline-turquoise.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(20, 177, 215, 0.5);
}

.module-outline-turquoise:disabled, .module-outline-turquoise.disabled {
  color: #14b1d7;
  background-color: transparent;
}

.module-outline-sky {
  color: #8cd8eb;
  border: 2px solid #8cd8eb;
}

.module-outline-sky .icon-container {
  border: 2px solid #8cd8eb;
}

.module-outline-sky:hover {
  background: #8cd8eb linear-gradient(180deg, #9bdced, #8cd8eb) repeat-x;
  color: #273a4d;
  border-color: #8cd8eb;
}

.module-outline-sky:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-sky:hover .icon-container * {
  color: #273a4d;
}

.module-outline-sky:focus, .module-outline-sky.focus {
  box-shadow: 0 0 0 0.2rem rgba(140, 216, 235, 0.5);
}

.module-outline-sky:active, .module-outline-sky.active,
.show > .module-outline-sky.dropdown-toggle {
  color: #273a4d;
  background-color: #8cd8eb;
  border-color: #8cd8eb;
}

.module-outline-sky:active:focus, .module-outline-sky.active:focus,
.show > .module-outline-sky.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(140, 216, 235, 0.5);
}

.module-outline-sky:disabled, .module-outline-sky.disabled {
  color: #8cd8eb;
  background-color: transparent;
}

.module-outline-mountain-river {
  color: #c4eaf3;
  border: 2px solid #c4eaf3;
}

.module-outline-mountain-river .icon-container {
  border: 2px solid #c4eaf3;
}

.module-outline-mountain-river:hover {
  background: #c4eaf3 linear-gradient(180deg, #caecf4, #c4eaf3) repeat-x;
  color: #273a4d;
  border-color: #c4eaf3;
}

.module-outline-mountain-river:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-mountain-river:hover .icon-container * {
  color: #273a4d;
}

.module-outline-mountain-river:focus, .module-outline-mountain-river.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 234, 243, 0.5);
}

.module-outline-mountain-river:active, .module-outline-mountain-river.active,
.show > .module-outline-mountain-river.dropdown-toggle {
  color: #273a4d;
  background-color: #c4eaf3;
  border-color: #c4eaf3;
}

.module-outline-mountain-river:active:focus, .module-outline-mountain-river.active:focus,
.show > .module-outline-mountain-river.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 234, 243, 0.5);
}

.module-outline-mountain-river:disabled, .module-outline-mountain-river.disabled {
  color: #c4eaf3;
  background-color: transparent;
}

.module-outline-dark-forest {
  color: #1c401d;
  border: 2px solid #1c401d;
}

.module-outline-dark-forest .icon-container {
  border: 2px solid #1c401d;
}

.module-outline-dark-forest:hover {
  background: #1c401d linear-gradient(180deg, #3c5b3e, #1c401d) repeat-x;
  color: #fff;
  border-color: #1c401d;
}

.module-outline-dark-forest:hover .icon-container {
  border-color: #fff;
}

.module-outline-dark-forest:hover .icon-container * {
  color: #fff;
}

.module-outline-dark-forest:focus, .module-outline-dark-forest.focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 64, 29, 0.5);
}

.module-outline-dark-forest:active, .module-outline-dark-forest.active,
.show > .module-outline-dark-forest.dropdown-toggle {
  color: #fff;
  background-color: #1c401d;
  border-color: #1c401d;
}

.module-outline-dark-forest:active:focus, .module-outline-dark-forest.active:focus,
.show > .module-outline-dark-forest.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 64, 29, 0.5);
}

.module-outline-dark-forest:disabled, .module-outline-dark-forest.disabled {
  color: #1c401d;
  background-color: transparent;
}

.module-outline-fir {
  color: #137117;
  border: 2px solid #137117;
}

.module-outline-fir .icon-container {
  border: 2px solid #137117;
}

.module-outline-fir:hover {
  background: #137117 linear-gradient(180deg, #348539, #137117) repeat-x;
  color: #fff;
  border-color: #137117;
}

.module-outline-fir:hover .icon-container {
  border-color: #fff;
}

.module-outline-fir:hover .icon-container * {
  color: #fff;
}

.module-outline-fir:focus, .module-outline-fir.focus {
  box-shadow: 0 0 0 0.2rem rgba(19, 113, 23, 0.5);
}

.module-outline-fir:active, .module-outline-fir.active,
.show > .module-outline-fir.dropdown-toggle {
  color: #fff;
  background-color: #137117;
  border-color: #137117;
}

.module-outline-fir:active:focus, .module-outline-fir.active:focus,
.show > .module-outline-fir.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(19, 113, 23, 0.5);
}

.module-outline-fir:disabled, .module-outline-fir.disabled {
  color: #137117;
  background-color: transparent;
}

.module-outline-larch {
  color: #1e9d23;
  border: 2px solid #1e9d23;
}

.module-outline-larch .icon-container {
  border: 2px solid #1e9d23;
}

.module-outline-larch:hover {
  background: #1e9d23 linear-gradient(180deg, #3daa43, #1e9d23) repeat-x;
  color: #fff;
  border-color: #1e9d23;
}

.module-outline-larch:hover .icon-container {
  border-color: #fff;
}

.module-outline-larch:hover .icon-container * {
  color: #fff;
}

.module-outline-larch:focus, .module-outline-larch.focus {
  box-shadow: 0 0 0 0.2rem rgba(30, 157, 35, 0.5);
}

.module-outline-larch:active, .module-outline-larch.active,
.show > .module-outline-larch.dropdown-toggle {
  color: #fff;
  background-color: #1e9d23;
  border-color: #1e9d23;
}

.module-outline-larch:active:focus, .module-outline-larch.active:focus,
.show > .module-outline-larch.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(30, 157, 35, 0.5);
}

.module-outline-larch:disabled, .module-outline-larch.disabled {
  color: #1e9d23;
  background-color: transparent;
}

.module-outline-gra {
  color: #47ca4b;
  border: 2px solid #47ca4b;
}

.module-outline-gra .icon-container {
  border: 2px solid #47ca4b;
}

.module-outline-gra:hover {
  background: #47ca4b linear-gradient(180deg, #60d065, #47ca4b) repeat-x;
  color: #fff;
  border-color: #47ca4b;
}

.module-outline-gra:hover .icon-container {
  border-color: #fff;
}

.module-outline-gra:hover .icon-container * {
  color: #fff;
}

.module-outline-gra:focus, .module-outline-gra.focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 202, 75, 0.5);
}

.module-outline-gra:active, .module-outline-gra.active,
.show > .module-outline-gra.dropdown-toggle {
  color: #fff;
  background-color: #47ca4b;
  border-color: #47ca4b;
}

.module-outline-gra:active:focus, .module-outline-gra.active:focus,
.show > .module-outline-gra.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 202, 75, 0.5);
}

.module-outline-gra:disabled, .module-outline-gra.disabled {
  color: #47ca4b;
  background-color: transparent;
}

.module-outline-fern {
  color: #7adf7e;
  border: 2px solid #7adf7e;
}

.module-outline-fern .icon-container {
  border: 2px solid #7adf7e;
}

.module-outline-fern:hover {
  background: #7adf7e linear-gradient(180deg, #8be291, #7adf7e) repeat-x;
  color: #273a4d;
  border-color: #7adf7e;
}

.module-outline-fern:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-fern:hover .icon-container * {
  color: #273a4d;
}

.module-outline-fern:focus, .module-outline-fern.focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 223, 126, 0.5);
}

.module-outline-fern:active, .module-outline-fern.active,
.show > .module-outline-fern.dropdown-toggle {
  color: #273a4d;
  background-color: #7adf7e;
  border-color: #7adf7e;
}

.module-outline-fern:active:focus, .module-outline-fern.active:focus,
.show > .module-outline-fern.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 223, 126, 0.5);
}

.module-outline-fern:disabled, .module-outline-fern.disabled {
  color: #7adf7e;
  background-color: transparent;
}

.module-outline-min {
  color: #beefbf;
  border: 2px solid #beefbf;
}

.module-outline-min .icon-container {
  border: 2px solid #beefbf;
}

.module-outline-min:hover {
  background: #beefbf linear-gradient(180deg, #c5f0c8, #beefbf) repeat-x;
  color: #273a4d;
  border-color: #beefbf;
}

.module-outline-min:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-min:hover .icon-container * {
  color: #273a4d;
}

.module-outline-min:focus, .module-outline-min.focus {
  box-shadow: 0 0 0 0.2rem rgba(190, 239, 191, 0.5);
}

.module-outline-min:active, .module-outline-min.active,
.show > .module-outline-min.dropdown-toggle {
  color: #273a4d;
  background-color: #beefbf;
  border-color: #beefbf;
}

.module-outline-min:active:focus, .module-outline-min.active:focus,
.show > .module-outline-min.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(190, 239, 191, 0.5);
}

.module-outline-min:disabled, .module-outline-min.disabled {
  color: #beefbf;
  background-color: transparent;
}

.module-outline-seaweed {
  color: #3a401c;
  border: 2px solid #3a401c;
}

.module-outline-seaweed .icon-container {
  border: 2px solid #3a401c;
}

.module-outline-seaweed:hover {
  background: #3a401c linear-gradient(180deg, #555b3d, #3a401c) repeat-x;
  color: #fff;
  border-color: #3a401c;
}

.module-outline-seaweed:hover .icon-container {
  border-color: #fff;
}

.module-outline-seaweed:hover .icon-container * {
  color: #fff;
}

.module-outline-seaweed:focus, .module-outline-seaweed.focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 64, 28, 0.5);
}

.module-outline-seaweed:active, .module-outline-seaweed.active,
.show > .module-outline-seaweed.dropdown-toggle {
  color: #fff;
  background-color: #3a401c;
  border-color: #3a401c;
}

.module-outline-seaweed:active:focus, .module-outline-seaweed.active:focus,
.show > .module-outline-seaweed.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 64, 28, 0.5);
}

.module-outline-seaweed:disabled, .module-outline-seaweed.disabled {
  color: #3a401c;
  background-color: transparent;
}

.module-outline-army {
  color: #515c1d;
  border: 2px solid #515c1d;
}

.module-outline-army .icon-container {
  border: 2px solid #515c1d;
}

.module-outline-army:hover {
  background: #515c1d linear-gradient(180deg, #69733e, #515c1d) repeat-x;
  color: #fff;
  border-color: #515c1d;
}

.module-outline-army:hover .icon-container {
  border-color: #fff;
}

.module-outline-army:hover .icon-container * {
  color: #fff;
}

.module-outline-army:focus, .module-outline-army.focus {
  box-shadow: 0 0 0 0.2rem rgba(81, 92, 29, 0.5);
}

.module-outline-army:active, .module-outline-army.active,
.show > .module-outline-army.dropdown-toggle {
  color: #fff;
  background-color: #515c1d;
  border-color: #515c1d;
}

.module-outline-army:active:focus, .module-outline-army.active:focus,
.show > .module-outline-army.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(81, 92, 29, 0.5);
}

.module-outline-army:disabled, .module-outline-army.disabled {
  color: #515c1d;
  background-color: transparent;
}

.module-outline-green-olive {
  color: #74861f;
  border: 2px solid #74861f;
}

.module-outline-green-olive .icon-container {
  border: 2px solid #74861f;
}

.module-outline-green-olive:hover {
  background: #74861f linear-gradient(180deg, #869740, #74861f) repeat-x;
  color: #fff;
  border-color: #74861f;
}

.module-outline-green-olive:hover .icon-container {
  border-color: #fff;
}

.module-outline-green-olive:hover .icon-container * {
  color: #fff;
}

.module-outline-green-olive:focus, .module-outline-green-olive.focus {
  box-shadow: 0 0 0 0.2rem rgba(116, 134, 31, 0.5);
}

.module-outline-green-olive:active, .module-outline-green-olive.active,
.show > .module-outline-green-olive.dropdown-toggle {
  color: #fff;
  background-color: #74861f;
  border-color: #74861f;
}

.module-outline-green-olive:active:focus, .module-outline-green-olive.active:focus,
.show > .module-outline-green-olive.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(116, 134, 31, 0.5);
}

.module-outline-green-olive:disabled, .module-outline-green-olive.disabled {
  color: #74861f;
  background-color: transparent;
}

.module-outline-apple {
  color: #a7c80d;
  border: 2px solid #a7c80d;
}

.module-outline-apple .icon-container {
  border: 2px solid #a7c80d;
}

.module-outline-apple:hover {
  background: #a7c80d linear-gradient(180deg, #b2cf31, #a7c80d) repeat-x;
  color: #273a4d;
  border-color: #a7c80d;
}

.module-outline-apple:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-apple:hover .icon-container * {
  color: #273a4d;
}

.module-outline-apple:focus, .module-outline-apple.focus {
  box-shadow: 0 0 0 0.2rem rgba(167, 200, 13, 0.5);
}

.module-outline-apple:active, .module-outline-apple.active,
.show > .module-outline-apple.dropdown-toggle {
  color: #273a4d;
  background-color: #a7c80d;
  border-color: #a7c80d;
}

.module-outline-apple:active:focus, .module-outline-apple.active:focus,
.show > .module-outline-apple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(167, 200, 13, 0.5);
}

.module-outline-apple:disabled, .module-outline-apple.disabled {
  color: #a7c80d;
  background-color: transparent;
}

.module-outline-lime {
  color: #c7e82e;
  border: 2px solid #c7e82e;
}

.module-outline-lime .icon-container {
  border: 2px solid #c7e82e;
}

.module-outline-lime:hover {
  background: #c7e82e linear-gradient(180deg, #cdea4d, #c7e82e) repeat-x;
  color: #273a4d;
  border-color: #c7e82e;
}

.module-outline-lime:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-lime:hover .icon-container * {
  color: #273a4d;
}

.module-outline-lime:focus, .module-outline-lime.focus {
  box-shadow: 0 0 0 0.2rem rgba(199, 232, 46, 0.5);
}

.module-outline-lime:active, .module-outline-lime.active,
.show > .module-outline-lime.dropdown-toggle {
  color: #273a4d;
  background-color: #c7e82e;
  border-color: #c7e82e;
}

.module-outline-lime:active:focus, .module-outline-lime.active:focus,
.show > .module-outline-lime.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(199, 232, 46, 0.5);
}

.module-outline-lime:disabled, .module-outline-lime.disabled {
  color: #c7e82e;
  background-color: transparent;
}

.module-outline-tea {
  color: #e6f797;
  border: 2px solid #e6f797;
}

.module-outline-tea .icon-container {
  border: 2px solid #e6f797;
}

.module-outline-tea:hover {
  background: #e6f797 linear-gradient(180deg, #e7f7a6, #e6f797) repeat-x;
  color: #273a4d;
  border-color: #e6f797;
}

.module-outline-tea:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-tea:hover .icon-container * {
  color: #273a4d;
}

.module-outline-tea:focus, .module-outline-tea.focus {
  box-shadow: 0 0 0 0.2rem rgba(230, 247, 151, 0.5);
}

.module-outline-tea:active, .module-outline-tea.active,
.show > .module-outline-tea.dropdown-toggle {
  color: #273a4d;
  background-color: #e6f797;
  border-color: #e6f797;
}

.module-outline-tea:active:focus, .module-outline-tea.active:focus,
.show > .module-outline-tea.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(230, 247, 151, 0.5);
}

.module-outline-tea:disabled, .module-outline-tea.disabled {
  color: #e6f797;
  background-color: transparent;
}

.module-outline-weather-oak {
  color: #403b1c;
  border: 2px solid #403b1c;
}

.module-outline-weather-oak .icon-container {
  border: 2px solid #403b1c;
}

.module-outline-weather-oak:hover {
  background: #403b1c linear-gradient(180deg, #5a573d, #403b1c) repeat-x;
  color: #fff;
  border-color: #403b1c;
}

.module-outline-weather-oak:hover .icon-container {
  border-color: #fff;
}

.module-outline-weather-oak:hover .icon-container * {
  color: #fff;
}

.module-outline-weather-oak:focus, .module-outline-weather-oak.focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 59, 28, 0.5);
}

.module-outline-weather-oak:active, .module-outline-weather-oak.active,
.show > .module-outline-weather-oak.dropdown-toggle {
  color: #fff;
  background-color: #403b1c;
  border-color: #403b1c;
}

.module-outline-weather-oak:active:focus, .module-outline-weather-oak.active:focus,
.show > .module-outline-weather-oak.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 59, 28, 0.5);
}

.module-outline-weather-oak:disabled, .module-outline-weather-oak.disabled {
  color: #403b1c;
  background-color: transparent;
}

.module-outline-avocado {
  color: #5c541d;
  border: 2px solid #5c541d;
}

.module-outline-avocado .icon-container {
  border: 2px solid #5c541d;
}

.module-outline-avocado:hover {
  background: #5c541d linear-gradient(180deg, #726c3e, #5c541d) repeat-x;
  color: #fff;
  border-color: #5c541d;
}

.module-outline-avocado:hover .icon-container {
  border-color: #fff;
}

.module-outline-avocado:hover .icon-container * {
  color: #fff;
}

.module-outline-avocado:focus, .module-outline-avocado.focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 84, 29, 0.5);
}

.module-outline-avocado:active, .module-outline-avocado.active,
.show > .module-outline-avocado.dropdown-toggle {
  color: #fff;
  background-color: #5c541d;
  border-color: #5c541d;
}

.module-outline-avocado:active:focus, .module-outline-avocado.active:focus,
.show > .module-outline-avocado.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 84, 29, 0.5);
}

.module-outline-avocado:disabled, .module-outline-avocado.disabled {
  color: #5c541d;
  background-color: transparent;
}

.module-outline-olive {
  color: #a08c0d;
  border: 2px solid #a08c0d;
}

.module-outline-olive .icon-container {
  border: 2px solid #a08c0d;
}

.module-outline-olive:hover {
  background: #a08c0d linear-gradient(180deg, #ac9c31, #a08c0d) repeat-x;
  color: #fff;
  border-color: #a08c0d;
}

.module-outline-olive:hover .icon-container {
  border-color: #fff;
}

.module-outline-olive:hover .icon-container * {
  color: #fff;
}

.module-outline-olive:focus, .module-outline-olive.focus {
  box-shadow: 0 0 0 0.2rem rgba(160, 140, 13, 0.5);
}

.module-outline-olive:active, .module-outline-olive.active,
.show > .module-outline-olive.dropdown-toggle {
  color: #fff;
  background-color: #a08c0d;
  border-color: #a08c0d;
}

.module-outline-olive:active:focus, .module-outline-olive.active:focus,
.show > .module-outline-olive.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(160, 140, 13, 0.5);
}

.module-outline-olive:disabled, .module-outline-olive.disabled {
  color: #a08c0d;
  background-color: transparent;
}

.module-outline-gold {
  color: #ddbf00;
  border: 2px solid #ddbf00;
}

.module-outline-gold .icon-container {
  border: 2px solid #ddbf00;
}

.module-outline-gold:hover {
  background: #ddbf00 linear-gradient(180deg, #e0c726, #ddbf00) repeat-x;
  color: #273a4d;
  border-color: #ddbf00;
}

.module-outline-gold:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-gold:hover .icon-container * {
  color: #273a4d;
}

.module-outline-gold:focus, .module-outline-gold.focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 191, 0, 0.5);
}

.module-outline-gold:active, .module-outline-gold.active,
.show > .module-outline-gold.dropdown-toggle {
  color: #273a4d;
  background-color: #ddbf00;
  border-color: #ddbf00;
}

.module-outline-gold:active:focus, .module-outline-gold.active:focus,
.show > .module-outline-gold.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 191, 0, 0.5);
}

.module-outline-gold:disabled, .module-outline-gold.disabled {
  color: #ddbf00;
  background-color: transparent;
}

.module-outline-sun {
  color: #ffe854;
  border: 2px solid #ffe854;
}

.module-outline-sun .icon-container {
  border: 2px solid #ffe854;
}

.module-outline-sun:hover {
  background: #ffe854 linear-gradient(180deg, #fcea6d, #ffe854) repeat-x;
  color: #273a4d;
  border-color: #ffe854;
}

.module-outline-sun:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-sun:hover .icon-container * {
  color: #273a4d;
}

.module-outline-sun:focus, .module-outline-sun.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 232, 84, 0.5);
}

.module-outline-sun:active, .module-outline-sun.active,
.show > .module-outline-sun.dropdown-toggle {
  color: #273a4d;
  background-color: #ffe854;
  border-color: #ffe854;
}

.module-outline-sun:active:focus, .module-outline-sun.active:focus,
.show > .module-outline-sun.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 232, 84, 0.5);
}

.module-outline-sun:disabled, .module-outline-sun.disabled {
  color: #ffe854;
  background-color: transparent;
}

.module-outline-banana {
  color: #fff3a7;
  border: 2px solid #fff3a7;
}

.module-outline-banana .icon-container {
  border: 2px solid #fff3a7;
}

.module-outline-banana:hover {
  background: #fff3a7 linear-gradient(180deg, #fcf3b3, #fff3a7) repeat-x;
  color: #273a4d;
  border-color: #fff3a7;
}

.module-outline-banana:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-banana:hover .icon-container * {
  color: #273a4d;
}

.module-outline-banana:focus, .module-outline-banana.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 243, 167, 0.5);
}

.module-outline-banana:active, .module-outline-banana.active,
.show > .module-outline-banana.dropdown-toggle {
  color: #273a4d;
  background-color: #fff3a7;
  border-color: #fff3a7;
}

.module-outline-banana:active:focus, .module-outline-banana.active:focus,
.show > .module-outline-banana.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 243, 167, 0.5);
}

.module-outline-banana:disabled, .module-outline-banana.disabled {
  color: #fff3a7;
  background-color: transparent;
}

.module-outline-walnut {
  color: #40331c;
  border: 2px solid #40331c;
}

.module-outline-walnut .icon-container {
  border: 2px solid #40331c;
}

.module-outline-walnut:hover {
  background: #40331c linear-gradient(180deg, #5a503d, #40331c) repeat-x;
  color: #fff;
  border-color: #40331c;
}

.module-outline-walnut:hover .icon-container {
  border-color: #fff;
}

.module-outline-walnut:hover .icon-container * {
  color: #fff;
}

.module-outline-walnut:focus, .module-outline-walnut.focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 51, 28, 0.5);
}

.module-outline-walnut:active, .module-outline-walnut.active,
.show > .module-outline-walnut.dropdown-toggle {
  color: #fff;
  background-color: #40331c;
  border-color: #40331c;
}

.module-outline-walnut:active:focus, .module-outline-walnut.active:focus,
.show > .module-outline-walnut.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 51, 28, 0.5);
}

.module-outline-walnut:disabled, .module-outline-walnut.disabled {
  color: #40331c;
  background-color: transparent;
}

.module-outline-flaxen {
  color: #6a4a11;
  border: 2px solid #6a4a11;
}

.module-outline-flaxen .icon-container {
  border: 2px solid #6a4a11;
}

.module-outline-flaxen:hover {
  background: #6a4a11 linear-gradient(180deg, #7e6434, #6a4a11) repeat-x;
  color: #fff;
  border-color: #6a4a11;
}

.module-outline-flaxen:hover .icon-container {
  border-color: #fff;
}

.module-outline-flaxen:hover .icon-container * {
  color: #fff;
}

.module-outline-flaxen:focus, .module-outline-flaxen.focus {
  box-shadow: 0 0 0 0.2rem rgba(106, 74, 17, 0.5);
}

.module-outline-flaxen:active, .module-outline-flaxen.active,
.show > .module-outline-flaxen.dropdown-toggle {
  color: #fff;
  background-color: #6a4a11;
  border-color: #6a4a11;
}

.module-outline-flaxen:active:focus, .module-outline-flaxen.active:focus,
.show > .module-outline-flaxen.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(106, 74, 17, 0.5);
}

.module-outline-flaxen:disabled, .module-outline-flaxen.disabled {
  color: #6a4a11;
  background-color: transparent;
}

.module-outline-sand-stone {
  color: #9b670e;
  border: 2px solid #9b670e;
}

.module-outline-sand-stone .icon-container {
  border: 2px solid #9b670e;
}

.module-outline-sand-stone:hover {
  background: #9b670e linear-gradient(180deg, #a77c31, #9b670e) repeat-x;
  color: #fff;
  border-color: #9b670e;
}

.module-outline-sand-stone:hover .icon-container {
  border-color: #fff;
}

.module-outline-sand-stone:hover .icon-container * {
  color: #fff;
}

.module-outline-sand-stone:focus, .module-outline-sand-stone.focus {
  box-shadow: 0 0 0 0.2rem rgba(155, 103, 14, 0.5);
}

.module-outline-sand-stone:active, .module-outline-sand-stone.active,
.show > .module-outline-sand-stone.dropdown-toggle {
  color: #fff;
  background-color: #9b670e;
  border-color: #9b670e;
}

.module-outline-sand-stone:active:focus, .module-outline-sand-stone.active:focus,
.show > .module-outline-sand-stone.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(155, 103, 14, 0.5);
}

.module-outline-sand-stone:disabled, .module-outline-sand-stone.disabled {
  color: #9b670e;
  background-color: transparent;
}

.module-outline-carrot {
  color: #f09800;
  border: 2px solid #f09800;
}

.module-outline-carrot .icon-container {
  border: 2px solid #f09800;
}

.module-outline-carrot:hover {
  background: #f09800 linear-gradient(180deg, #f0a626, #f09800) repeat-x;
  color: #273a4d;
  border-color: #f09800;
}

.module-outline-carrot:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-carrot:hover .icon-container * {
  color: #273a4d;
}

.module-outline-carrot:focus, .module-outline-carrot.focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 152, 0, 0.5);
}

.module-outline-carrot:active, .module-outline-carrot.active,
.show > .module-outline-carrot.dropdown-toggle {
  color: #273a4d;
  background-color: #f09800;
  border-color: #f09800;
}

.module-outline-carrot:active:focus, .module-outline-carrot.active:focus,
.show > .module-outline-carrot.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 152, 0, 0.5);
}

.module-outline-carrot:disabled, .module-outline-carrot.disabled {
  color: #f09800;
  background-color: transparent;
}

.module-outline-apricot {
  color: #ffc155;
  border: 2px solid #ffc155;
}

.module-outline-apricot .icon-container {
  border: 2px solid #ffc155;
}

.module-outline-apricot:hover {
  background: #ffc155 linear-gradient(180deg, #fcc96e, #ffc155) repeat-x;
  color: #273a4d;
  border-color: #ffc155;
}

.module-outline-apricot:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-apricot:hover .icon-container * {
  color: #273a4d;
}

.module-outline-apricot:focus, .module-outline-apricot.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 85, 0.5);
}

.module-outline-apricot:active, .module-outline-apricot.active,
.show > .module-outline-apricot.dropdown-toggle {
  color: #273a4d;
  background-color: #ffc155;
  border-color: #ffc155;
}

.module-outline-apricot:active:focus, .module-outline-apricot.active:focus,
.show > .module-outline-apricot.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 85, 0.5);
}

.module-outline-apricot:disabled, .module-outline-apricot.disabled {
  color: #ffc155;
  background-color: transparent;
}

.module-outline-sugar-melon {
  color: #ffdda1;
  border: 2px solid #ffdda1;
}

.module-outline-sugar-melon .icon-container {
  border: 2px solid #ffdda1;
}

.module-outline-sugar-melon:hover {
  background: #ffdda1 linear-gradient(180deg, #fce1ae, #ffdda1) repeat-x;
  color: #273a4d;
  border-color: #ffdda1;
}

.module-outline-sugar-melon:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-sugar-melon:hover .icon-container * {
  color: #273a4d;
}

.module-outline-sugar-melon:focus, .module-outline-sugar-melon.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 221, 161, 0.5);
}

.module-outline-sugar-melon:active, .module-outline-sugar-melon.active,
.show > .module-outline-sugar-melon.dropdown-toggle {
  color: #273a4d;
  background-color: #ffdda1;
  border-color: #ffdda1;
}

.module-outline-sugar-melon:active:focus, .module-outline-sugar-melon.active:focus,
.show > .module-outline-sugar-melon.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 221, 161, 0.5);
}

.module-outline-sugar-melon:disabled, .module-outline-sugar-melon.disabled {
  color: #ffdda1;
  background-color: transparent;
}

.module-outline-chocolate {
  color: #40241c;
  border: 2px solid #40241c;
}

.module-outline-chocolate .icon-container {
  border: 2px solid #40241c;
}

.module-outline-chocolate:hover {
  background: #40241c linear-gradient(180deg, #5a433d, #40241c) repeat-x;
  color: #fff;
  border-color: #40241c;
}

.module-outline-chocolate:hover .icon-container {
  border-color: #fff;
}

.module-outline-chocolate:hover .icon-container * {
  color: #fff;
}

.module-outline-chocolate:focus, .module-outline-chocolate.focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 36, 28, 0.5);
}

.module-outline-chocolate:active, .module-outline-chocolate.active,
.show > .module-outline-chocolate.dropdown-toggle {
  color: #fff;
  background-color: #40241c;
  border-color: #40241c;
}

.module-outline-chocolate:active:focus, .module-outline-chocolate.active:focus,
.show > .module-outline-chocolate.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 36, 28, 0.5);
}

.module-outline-chocolate:disabled, .module-outline-chocolate.disabled {
  color: #40241c;
  background-color: transparent;
}

.module-outline-blood {
  color: #6a2511;
  border: 2px solid #6a2511;
}

.module-outline-blood .icon-container {
  border: 2px solid #6a2511;
}

.module-outline-blood:hover {
  background: #6a2511 linear-gradient(180deg, #7e4434, #6a2511) repeat-x;
  color: #fff;
  border-color: #6a2511;
}

.module-outline-blood:hover .icon-container {
  border-color: #fff;
}

.module-outline-blood:hover .icon-container * {
  color: #fff;
}

.module-outline-blood:focus, .module-outline-blood.focus {
  box-shadow: 0 0 0 0.2rem rgba(106, 37, 17, 0.5);
}

.module-outline-blood:active, .module-outline-blood.active,
.show > .module-outline-blood.dropdown-toggle {
  color: #fff;
  background-color: #6a2511;
  border-color: #6a2511;
}

.module-outline-blood:active:focus, .module-outline-blood.active:focus,
.show > .module-outline-blood.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(106, 37, 17, 0.5);
}

.module-outline-blood:disabled, .module-outline-blood.disabled {
  color: #6a2511;
  background-color: transparent;
}

.module-outline-brick {
  color: #9b2d0e;
  border: 2px solid #9b2d0e;
}

.module-outline-brick .icon-container {
  border: 2px solid #9b2d0e;
}

.module-outline-brick:hover {
  background: #9b2d0e linear-gradient(180deg, #a74b31, #9b2d0e) repeat-x;
  color: #fff;
  border-color: #9b2d0e;
}

.module-outline-brick:hover .icon-container {
  border-color: #fff;
}

.module-outline-brick:hover .icon-container * {
  color: #fff;
}

.module-outline-brick:focus, .module-outline-brick.focus {
  box-shadow: 0 0 0 0.2rem rgba(155, 45, 14, 0.5);
}

.module-outline-brick:active, .module-outline-brick.active,
.show > .module-outline-brick.dropdown-toggle {
  color: #fff;
  background-color: #9b2d0e;
  border-color: #9b2d0e;
}

.module-outline-brick:active:focus, .module-outline-brick.active:focus,
.show > .module-outline-brick.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(155, 45, 14, 0.5);
}

.module-outline-brick:disabled, .module-outline-brick.disabled {
  color: #9b2d0e;
  background-color: transparent;
}

.module-outline-chili {
  color: #e83200;
  border: 2px solid #e83200;
}

.module-outline-chili .icon-container {
  border: 2px solid #e83200;
}

.module-outline-chili:hover {
  background: #e83200 linear-gradient(180deg, #e94f26, #e83200) repeat-x;
  color: #fff;
  border-color: #e83200;
}

.module-outline-chili:hover .icon-container {
  border-color: #fff;
}

.module-outline-chili:hover .icon-container * {
  color: #fff;
}

.module-outline-chili:focus, .module-outline-chili.focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 50, 0, 0.5);
}

.module-outline-chili:active, .module-outline-chili.active,
.show > .module-outline-chili.dropdown-toggle {
  color: #fff;
  background-color: #e83200;
  border-color: #e83200;
}

.module-outline-chili:active:focus, .module-outline-chili.active:focus,
.show > .module-outline-chili.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 50, 0, 0.5);
}

.module-outline-chili:disabled, .module-outline-chili.disabled {
  color: #e83200;
  background-color: transparent;
}

.module-outline-pumpkin {
  color: #ff7a55;
  border: 2px solid #ff7a55;
}

.module-outline-pumpkin .icon-container {
  border: 2px solid #ff7a55;
}

.module-outline-pumpkin:hover {
  background: #ff7a55 linear-gradient(180deg, #fc8c6e, #ff7a55) repeat-x;
  color: #273a4d;
  border-color: #ff7a55;
}

.module-outline-pumpkin:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-pumpkin:hover .icon-container * {
  color: #273a4d;
}

.module-outline-pumpkin:focus, .module-outline-pumpkin.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 122, 85, 0.5);
}

.module-outline-pumpkin:active, .module-outline-pumpkin.active,
.show > .module-outline-pumpkin.dropdown-toggle {
  color: #273a4d;
  background-color: #ff7a55;
  border-color: #ff7a55;
}

.module-outline-pumpkin:active:focus, .module-outline-pumpkin.active:focus,
.show > .module-outline-pumpkin.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 122, 85, 0.5);
}

.module-outline-pumpkin:disabled, .module-outline-pumpkin.disabled {
  color: #ff7a55;
  background-color: transparent;
}

.module-outline-salmon {
  color: #ffb5a1;
  border: 2px solid #ffb5a1;
}

.module-outline-salmon .icon-container {
  border: 2px solid #ffb5a1;
}

.module-outline-salmon:hover {
  background: #ffb5a1 linear-gradient(180deg, #fcbfae, #ffb5a1) repeat-x;
  color: #273a4d;
  border-color: #ffb5a1;
}

.module-outline-salmon:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-salmon:hover .icon-container * {
  color: #273a4d;
}

.module-outline-salmon:focus, .module-outline-salmon.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 181, 161, 0.5);
}

.module-outline-salmon:active, .module-outline-salmon.active,
.show > .module-outline-salmon.dropdown-toggle {
  color: #273a4d;
  background-color: #ffb5a1;
  border-color: #ffb5a1;
}

.module-outline-salmon:active:focus, .module-outline-salmon.active:focus,
.show > .module-outline-salmon.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 181, 161, 0.5);
}

.module-outline-salmon:disabled, .module-outline-salmon.disabled {
  color: #ffb5a1;
  background-color: transparent;
}

.module-outline-eggplant {
  color: #401c34;
  border: 2px solid #401c34;
}

.module-outline-eggplant .icon-container {
  border: 2px solid #401c34;
}

.module-outline-eggplant:hover {
  background: #401c34 linear-gradient(180deg, #5a3d52, #401c34) repeat-x;
  color: #fff;
  border-color: #401c34;
}

.module-outline-eggplant:hover .icon-container {
  border-color: #fff;
}

.module-outline-eggplant:hover .icon-container * {
  color: #fff;
}

.module-outline-eggplant:focus, .module-outline-eggplant.focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 28, 52, 0.5);
}

.module-outline-eggplant:active, .module-outline-eggplant.active,
.show > .module-outline-eggplant.dropdown-toggle {
  color: #fff;
  background-color: #401c34;
  border-color: #401c34;
}

.module-outline-eggplant:active:focus, .module-outline-eggplant.active:focus,
.show > .module-outline-eggplant.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 28, 52, 0.5);
}

.module-outline-eggplant:disabled, .module-outline-eggplant.disabled {
  color: #401c34;
  background-color: transparent;
}

.module-outline-plum {
  color: #6a114d;
  border: 2px solid #6a114d;
}

.module-outline-plum .icon-container {
  border: 2px solid #6a114d;
}

.module-outline-plum:hover {
  background: #6a114d linear-gradient(180deg, #7e3367, #6a114d) repeat-x;
  color: #fff;
  border-color: #6a114d;
}

.module-outline-plum:hover .icon-container {
  border-color: #fff;
}

.module-outline-plum:hover .icon-container * {
  color: #fff;
}

.module-outline-plum:focus, .module-outline-plum.focus {
  box-shadow: 0 0 0 0.2rem rgba(106, 17, 77, 0.5);
}

.module-outline-plum:active, .module-outline-plum.active,
.show > .module-outline-plum.dropdown-toggle {
  color: #fff;
  background-color: #6a114d;
  border-color: #6a114d;
}

.module-outline-plum:active:focus, .module-outline-plum.active:focus,
.show > .module-outline-plum.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(106, 17, 77, 0.5);
}

.module-outline-plum:disabled, .module-outline-plum.disabled {
  color: #6a114d;
  background-color: transparent;
}

.module-outline-ruby {
  color: #9b0e6c;
  border: 2px solid #9b0e6c;
}

.module-outline-ruby .icon-container {
  border: 2px solid #9b0e6c;
}

.module-outline-ruby:hover {
  background: #9b0e6c linear-gradient(180deg, #a73181, #9b0e6c) repeat-x;
  color: #fff;
  border-color: #9b0e6c;
}

.module-outline-ruby:hover .icon-container {
  border-color: #fff;
}

.module-outline-ruby:hover .icon-container * {
  color: #fff;
}

.module-outline-ruby:focus, .module-outline-ruby.focus {
  box-shadow: 0 0 0 0.2rem rgba(155, 14, 108, 0.5);
}

.module-outline-ruby:active, .module-outline-ruby.active,
.show > .module-outline-ruby.dropdown-toggle {
  color: #fff;
  background-color: #9b0e6c;
  border-color: #9b0e6c;
}

.module-outline-ruby:active:focus, .module-outline-ruby.active:focus,
.show > .module-outline-ruby.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(155, 14, 108, 0.5);
}

.module-outline-ruby:disabled, .module-outline-ruby.disabled {
  color: #9b0e6c;
  background-color: transparent;
}

.module-outline-berry {
  color: #d60590;
  border: 2px solid #d60590;
}

.module-outline-berry .icon-container {
  border: 2px solid #d60590;
}

.module-outline-berry:hover {
  background: #d60590 linear-gradient(180deg, #da29a0, #d60590) repeat-x;
  color: #fff;
  border-color: #d60590;
}

.module-outline-berry:hover .icon-container {
  border-color: #fff;
}

.module-outline-berry:hover .icon-container * {
  color: #fff;
}

.module-outline-berry:focus, .module-outline-berry.focus {
  box-shadow: 0 0 0 0.2rem rgba(214, 5, 144, 0.5);
}

.module-outline-berry:active, .module-outline-berry.active,
.show > .module-outline-berry.dropdown-toggle {
  color: #fff;
  background-color: #d60590;
  border-color: #d60590;
}

.module-outline-berry:active:focus, .module-outline-berry.active:focus,
.show > .module-outline-berry.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(214, 5, 144, 0.5);
}

.module-outline-berry:disabled, .module-outline-berry.disabled {
  color: #d60590;
  background-color: transparent;
}

.module-outline-fuchsia {
  color: #ff46c1;
  border: 2px solid #ff46c1;
}

.module-outline-fuchsia .icon-container {
  border: 2px solid #ff46c1;
}

.module-outline-fuchsia:hover {
  background: #ff46c1 linear-gradient(180deg, #fc60ca, #ff46c1) repeat-x;
  color: #fff;
  border-color: #ff46c1;
}

.module-outline-fuchsia:hover .icon-container {
  border-color: #fff;
}

.module-outline-fuchsia:hover .icon-container * {
  color: #fff;
}

.module-outline-fuchsia:focus, .module-outline-fuchsia.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 70, 193, 0.5);
}

.module-outline-fuchsia:active, .module-outline-fuchsia.active,
.show > .module-outline-fuchsia.dropdown-toggle {
  color: #fff;
  background-color: #ff46c1;
  border-color: #ff46c1;
}

.module-outline-fuchsia:active:focus, .module-outline-fuchsia.active:focus,
.show > .module-outline-fuchsia.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 70, 193, 0.5);
}

.module-outline-fuchsia:disabled, .module-outline-fuchsia.disabled {
  color: #ff46c1;
  background-color: transparent;
}

.module-outline-flamingo {
  color: #ffa1e0;
  border: 2px solid #ffa1e0;
}

.module-outline-flamingo .icon-container {
  border: 2px solid #ffa1e0;
}

.module-outline-flamingo:hover {
  background: #ffa1e0 linear-gradient(180deg, #fcaee4, #ffa1e0) repeat-x;
  color: #273a4d;
  border-color: #ffa1e0;
}

.module-outline-flamingo:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-flamingo:hover .icon-container * {
  color: #273a4d;
}

.module-outline-flamingo:focus, .module-outline-flamingo.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 161, 224, 0.5);
}

.module-outline-flamingo:active, .module-outline-flamingo.active,
.show > .module-outline-flamingo.dropdown-toggle {
  color: #273a4d;
  background-color: #ffa1e0;
  border-color: #ffa1e0;
}

.module-outline-flamingo:active:focus, .module-outline-flamingo.active:focus,
.show > .module-outline-flamingo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 161, 224, 0.5);
}

.module-outline-flamingo:disabled, .module-outline-flamingo.disabled {
  color: #ffa1e0;
  background-color: transparent;
}

.module-outline-raisin {
  color: #371c40;
  border: 2px solid #371c40;
}

.module-outline-raisin .icon-container {
  border: 2px solid #371c40;
}

.module-outline-raisin:hover {
  background: #371c40 linear-gradient(180deg, #523d5c, #371c40) repeat-x;
  color: #fff;
  border-color: #371c40;
}

.module-outline-raisin:hover .icon-container {
  border-color: #fff;
}

.module-outline-raisin:hover .icon-container * {
  color: #fff;
}

.module-outline-raisin:focus, .module-outline-raisin.focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 28, 64, 0.5);
}

.module-outline-raisin:active, .module-outline-raisin.active,
.show > .module-outline-raisin.dropdown-toggle {
  color: #fff;
  background-color: #371c40;
  border-color: #371c40;
}

.module-outline-raisin:active:focus, .module-outline-raisin.active:focus,
.show > .module-outline-raisin.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 28, 64, 0.5);
}

.module-outline-raisin:disabled, .module-outline-raisin.disabled {
  color: #371c40;
  background-color: transparent;
}

.module-outline-grape {
  color: #54116a;
  border: 2px solid #54116a;
}

.module-outline-grape .icon-container {
  border: 2px solid #54116a;
}

.module-outline-grape:hover {
  background: #54116a linear-gradient(180deg, #6b3380, #54116a) repeat-x;
  color: #fff;
  border-color: #54116a;
}

.module-outline-grape:hover .icon-container {
  border-color: #fff;
}

.module-outline-grape:hover .icon-container * {
  color: #fff;
}

.module-outline-grape:focus, .module-outline-grape.focus {
  box-shadow: 0 0 0 0.2rem rgba(84, 17, 106, 0.5);
}

.module-outline-grape:active, .module-outline-grape.active,
.show > .module-outline-grape.dropdown-toggle {
  color: #fff;
  background-color: #54116a;
  border-color: #54116a;
}

.module-outline-grape:active:focus, .module-outline-grape.active:focus,
.show > .module-outline-grape.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(84, 17, 106, 0.5);
}

.module-outline-grape:disabled, .module-outline-grape.disabled {
  color: #54116a;
  background-color: transparent;
}

.module-outline-lavendar {
  color: #7d0fa2;
  border: 2px solid #7d0fa2;
}

.module-outline-lavendar .icon-container {
  border: 2px solid #7d0fa2;
}

.module-outline-lavendar:hover {
  background: #7d0fa2 linear-gradient(180deg, #8e32af, #7d0fa2) repeat-x;
  color: #fff;
  border-color: #7d0fa2;
}

.module-outline-lavendar:hover .icon-container {
  border-color: #fff;
}

.module-outline-lavendar:hover .icon-container * {
  color: #fff;
}

.module-outline-lavendar:focus, .module-outline-lavendar.focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 15, 162, 0.5);
}

.module-outline-lavendar:active, .module-outline-lavendar.active,
.show > .module-outline-lavendar.dropdown-toggle {
  color: #fff;
  background-color: #7d0fa2;
  border-color: #7d0fa2;
}

.module-outline-lavendar:active:focus, .module-outline-lavendar.active:focus,
.show > .module-outline-lavendar.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 15, 162, 0.5);
}

.module-outline-lavendar:disabled, .module-outline-lavendar.disabled {
  color: #7d0fa2;
  background-color: transparent;
}

.module-outline-amethyst {
  color: #9d02d0;
  border: 2px solid #9d02d0;
}

.module-outline-amethyst .icon-container {
  border: 2px solid #9d02d0;
}

.module-outline-amethyst:hover {
  background: #9d02d0 linear-gradient(180deg, #a926d6, #9d02d0) repeat-x;
  color: #fff;
  border-color: #9d02d0;
}

.module-outline-amethyst:hover .icon-container {
  border-color: #fff;
}

.module-outline-amethyst:hover .icon-container * {
  color: #fff;
}

.module-outline-amethyst:focus, .module-outline-amethyst.focus {
  box-shadow: 0 0 0 0.2rem rgba(157, 2, 208, 0.5);
}

.module-outline-amethyst:active, .module-outline-amethyst.active,
.show > .module-outline-amethyst.dropdown-toggle {
  color: #fff;
  background-color: #9d02d0;
  border-color: #9d02d0;
}

.module-outline-amethyst:active:focus, .module-outline-amethyst.active:focus,
.show > .module-outline-amethyst.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(157, 2, 208, 0.5);
}

.module-outline-amethyst:disabled, .module-outline-amethyst.disabled {
  color: #9d02d0;
  background-color: transparent;
}

.module-outline-orchid {
  color: #c557e9;
  border: 2px solid #c557e9;
}

.module-outline-orchid .icon-container {
  border: 2px solid #c557e9;
}

.module-outline-orchid:hover {
  background: #c557e9 linear-gradient(180deg, #cb6fec, #c557e9) repeat-x;
  color: #fff;
  border-color: #c557e9;
}

.module-outline-orchid:hover .icon-container {
  border-color: #fff;
}

.module-outline-orchid:hover .icon-container * {
  color: #fff;
}

.module-outline-orchid:focus, .module-outline-orchid.focus {
  box-shadow: 0 0 0 0.2rem rgba(197, 87, 233, 0.5);
}

.module-outline-orchid:active, .module-outline-orchid.active,
.show > .module-outline-orchid.dropdown-toggle {
  color: #fff;
  background-color: #c557e9;
  border-color: #c557e9;
}

.module-outline-orchid:active:focus, .module-outline-orchid.active:focus,
.show > .module-outline-orchid.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(197, 87, 233, 0.5);
}

.module-outline-orchid:disabled, .module-outline-orchid.disabled {
  color: #c557e9;
  background-color: transparent;
}

.module-outline-thistle {
  color: #e8a1ff;
  border: 2px solid #e8a1ff;
}

.module-outline-thistle .icon-container {
  border: 2px solid #e8a1ff;
}

.module-outline-thistle:hover {
  background: #e8a1ff linear-gradient(180deg, #e9aefe, #e8a1ff) repeat-x;
  color: #273a4d;
  border-color: #e8a1ff;
}

.module-outline-thistle:hover .icon-container {
  border-color: #273a4d;
}

.module-outline-thistle:hover .icon-container * {
  color: #273a4d;
}

.module-outline-thistle:focus, .module-outline-thistle.focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 161, 255, 0.5);
}

.module-outline-thistle:active, .module-outline-thistle.active,
.show > .module-outline-thistle.dropdown-toggle {
  color: #273a4d;
  background-color: #e8a1ff;
  border-color: #e8a1ff;
}

.module-outline-thistle:active:focus, .module-outline-thistle.active:focus,
.show > .module-outline-thistle.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 161, 255, 0.5);
}

.module-outline-thistle:disabled, .module-outline-thistle.disabled {
  color: #e8a1ff;
  background-color: transparent;
}

.module-outline-magenta {
  color: #ff0080;
  border: 2px solid #ff0080;
}

.module-outline-magenta .icon-container {
  border: 2px solid #ff0080;
}

.module-outline-magenta:hover {
  background: #ff0080 linear-gradient(180deg, #fc2592, #ff0080) repeat-x;
  color: #fff;
  border-color: #ff0080;
}

.module-outline-magenta:hover .icon-container {
  border-color: #fff;
}

.module-outline-magenta:hover .icon-container * {
  color: #fff;
}

.module-outline-magenta:focus, .module-outline-magenta.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 128, 0.5);
}

.module-outline-magenta:active, .module-outline-magenta.active,
.show > .module-outline-magenta.dropdown-toggle {
  color: #fff;
  background-color: #ff0080;
  border-color: #ff0080;
}

.module-outline-magenta:active:focus, .module-outline-magenta.active:focus,
.show > .module-outline-magenta.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 128, 0.5);
}

.module-outline-magenta:disabled, .module-outline-magenta.disabled {
  color: #ff0080;
  background-color: transparent;
}

.module-inverted-ice {
  color: #273a4d;
  background: #eef5fa linear-gradient(180deg, #eef5fa, #eef5fa) repeat-x;
  border-color: #eef5fa;
}

.module-inverted-ice .icon-container {
  background-color: #273a4d;
}

.module-inverted-ice .icon-container * {
  color: #eef5fa;
}

.module-inverted-ice:hover {
  color: #273a4d;
  background: #d0e4f1 linear-gradient(180deg, #d5e6f3, #d0e4f1) repeat-x;
  border-color: #c7deee;
}

.module-inverted-ice:focus, .module-inverted-ice.focus {
  color: #273a4d;
  background: #d0e4f1 linear-gradient(180deg, #d5e6f3, #d0e4f1) repeat-x;
  border-color: #c7deee;
  box-shadow: 0 0 0 0.2rem rgba(208, 217, 224, 0.5);
}

.module-inverted-ice:active, .module-inverted-ice.active,
.show > .module-inverted-ice.dropdown-toggle {
  color: #273a4d;
  background-color: #c7deee;
  background-image: none;
  border-color: #bdd8ec;
}

.module-inverted-ice:active:focus, .module-inverted-ice.active:focus,
.show > .module-inverted-ice.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(208, 217, 224, 0.5);
}

.module-inverted-ice:disabled, .module-inverted-ice.disabled {
  color: #273a4d;
  background-color: #eef5fa;
  background-image: none;
  border-color: #eef5fa;
}

.module-inverted-super-light {
  color: #273a4d;
  background: #deedf6 linear-gradient(180deg, #e0eef7, #deedf6) repeat-x;
  border-color: #deedf6;
}

.module-inverted-super-light .icon-container {
  background-color: #273a4d;
}

.module-inverted-super-light .icon-container * {
  color: #deedf6;
}

.module-inverted-super-light:hover {
  color: #273a4d;
  background: #c0ddee linear-gradient(180deg, #c7e0f0, #c0ddee) repeat-x;
  border-color: #b6d7eb;
}

.module-inverted-super-light:focus, .module-inverted-super-light.focus {
  color: #273a4d;
  background: #c0ddee linear-gradient(180deg, #c7e0f0, #c0ddee) repeat-x;
  border-color: #b6d7eb;
  box-shadow: 0 0 0 0.2rem rgba(195, 210, 221, 0.5);
}

.module-inverted-super-light:active, .module-inverted-super-light.active,
.show > .module-inverted-super-light.dropdown-toggle {
  color: #273a4d;
  background-color: #b6d7eb;
  background-image: none;
  border-color: #acd2e8;
}

.module-inverted-super-light:active:focus, .module-inverted-super-light.active:focus,
.show > .module-inverted-super-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(195, 210, 221, 0.5);
}

.module-inverted-super-light:disabled, .module-inverted-super-light.disabled {
  color: #273a4d;
  background-color: #deedf6;
  background-image: none;
  border-color: #deedf6;
}

.module-inverted-light {
  color: #273a4d;
  background: #b9d6e6 linear-gradient(180deg, #c1dbe9, #b9d6e6) repeat-x;
  border-color: #b9d6e6;
}

.module-inverted-light .icon-container {
  background-color: #273a4d;
}

.module-inverted-light .icon-container * {
  color: #b9d6e6;
}

.module-inverted-light:hover {
  color: #273a4d;
  background: #9dc5dc linear-gradient(180deg, #a9cde0, #9dc5dc) repeat-x;
  border-color: #93c0d9;
}

.module-inverted-light:focus, .module-inverted-light.focus {
  color: #273a4d;
  background: #9dc5dc linear-gradient(180deg, #a9cde0, #9dc5dc) repeat-x;
  border-color: #93c0d9;
  box-shadow: 0 0 0 0.2rem rgba(163, 191, 207, 0.5);
}

.module-inverted-light:active, .module-inverted-light.active,
.show > .module-inverted-light.dropdown-toggle {
  color: #273a4d;
  background-color: #93c0d9;
  background-image: none;
  border-color: #8abad5;
}

.module-inverted-light:active:focus, .module-inverted-light.active:focus,
.show > .module-inverted-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(163, 191, 207, 0.5);
}

.module-inverted-light:disabled, .module-inverted-light.disabled {
  color: #273a4d;
  background-color: #b9d6e6;
  background-image: none;
  border-color: #b9d6e6;
}

.module-inverted-steel {
  color: #273a4d;
  background: #71a3bd linear-gradient(180deg, #84afc6, #71a3bd) repeat-x;
  border-color: #71a3bd;
}

.module-inverted-steel .icon-container {
  background-color: #273a4d;
}

.module-inverted-steel .icon-container * {
  color: #71a3bd;
}

.module-inverted-steel:hover {
  color: #fff;
  background: #5792b1 linear-gradient(180deg, #6ea1bc, #5792b1) repeat-x;
  border-color: #508cab;
}

.module-inverted-steel:focus, .module-inverted-steel.focus {
  color: #fff;
  background: #5792b1 linear-gradient(180deg, #6ea1bc, #5792b1) repeat-x;
  border-color: #508cab;
  box-shadow: 0 0 0 0.2rem rgba(102, 147, 172, 0.5);
}

.module-inverted-steel:active, .module-inverted-steel.active,
.show > .module-inverted-steel.dropdown-toggle {
  color: #fff;
  background-color: #508cab;
  background-image: none;
  border-color: #4c85a3;
}

.module-inverted-steel:active:focus, .module-inverted-steel.active:focus,
.show > .module-inverted-steel.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 147, 172, 0.5);
}

.module-inverted-steel:disabled, .module-inverted-steel.disabled {
  color: #273a4d;
  background-color: #71a3bd;
  background-image: none;
  border-color: #71a3bd;
}

.module-inverted-ocean {
  color: #fff;
  background: #48748b linear-gradient(180deg, #61879c, #48748b) repeat-x;
  border-color: #48748b;
}

.module-inverted-ocean .icon-container {
  background-color: #273a4d;
}

.module-inverted-ocean .icon-container * {
  color: #48748b;
}

.module-inverted-ocean:hover {
  color: #fff;
  background: #3b5f72 linear-gradient(180deg, #567586, #3b5f72) repeat-x;
  border-color: #375869;
}

.module-inverted-ocean:focus, .module-inverted-ocean.focus {
  color: #fff;
  background: #3b5f72 linear-gradient(180deg, #567586, #3b5f72) repeat-x;
  border-color: #375869;
  box-shadow: 0 0 0 0.2rem rgba(99, 137, 156, 0.5);
}

.module-inverted-ocean:active, .module-inverted-ocean.active,
.show > .module-inverted-ocean.dropdown-toggle {
  color: #fff;
  background-color: #375869;
  background-image: none;
  border-color: #325161;
}

.module-inverted-ocean:active:focus, .module-inverted-ocean.active:focus,
.show > .module-inverted-ocean.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 137, 156, 0.5);
}

.module-inverted-ocean:disabled, .module-inverted-ocean.disabled {
  color: #fff;
  background-color: #48748b;
  background-image: none;
  border-color: #48748b;
}

.module-inverted-midnight {
  color: #fff;
  background: #354f68 linear-gradient(180deg, #51687e, #354f68) repeat-x;
  border-color: #354f68;
}

.module-inverted-midnight .icon-container {
  background-color: #273a4d;
}

.module-inverted-midnight .icon-container * {
  color: #354f68;
}

.module-inverted-midnight:hover {
  color: #fff;
  background: #283c4f linear-gradient(180deg, #465868, #283c4f) repeat-x;
  border-color: #243546;
}

.module-inverted-midnight:focus, .module-inverted-midnight.focus {
  color: #fff;
  background: #283c4f linear-gradient(180deg, #465868, #283c4f) repeat-x;
  border-color: #243546;
  box-shadow: 0 0 0 0.2rem rgba(83, 105, 127, 0.5);
}

.module-inverted-midnight:active, .module-inverted-midnight.active,
.show > .module-inverted-midnight.dropdown-toggle {
  color: #fff;
  background-color: #243546;
  background-image: none;
  border-color: #1f2f3e;
}

.module-inverted-midnight:active:focus, .module-inverted-midnight.active:focus,
.show > .module-inverted-midnight.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(83, 105, 127, 0.5);
}

.module-inverted-midnight:disabled, .module-inverted-midnight.disabled {
  color: #fff;
  background-color: #354f68;
  background-image: none;
  border-color: #354f68;
}

.module-inverted-cosmos {
  color: #fff;
  background: #273a4d linear-gradient(180deg, #455667, #273a4d) repeat-x;
  border-color: #273a4d;
}

.module-inverted-cosmos .icon-container {
  background-color: #273a4d;
}

.module-inverted-cosmos .icon-container * {
  color: #273a4d;
}

.module-inverted-cosmos:hover {
  color: #fff;
  background: #1a2734 linear-gradient(180deg, #3a4651, #1a2734) repeat-x;
  border-color: #16212b;
}

.module-inverted-cosmos:focus, .module-inverted-cosmos.focus {
  color: #fff;
  background: #1a2734 linear-gradient(180deg, #3a4651, #1a2734) repeat-x;
  border-color: #16212b;
  box-shadow: 0 0 0 0.2rem rgba(71, 88, 104, 0.5);
}

.module-inverted-cosmos:active, .module-inverted-cosmos.active,
.show > .module-inverted-cosmos.dropdown-toggle {
  color: #fff;
  background-color: #16212b;
  background-image: none;
  border-color: #121a23;
}

.module-inverted-cosmos:active:focus, .module-inverted-cosmos.active:focus,
.show > .module-inverted-cosmos.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 88, 104, 0.5);
}

.module-inverted-cosmos:disabled, .module-inverted-cosmos.disabled {
  color: #fff;
  background-color: #273a4d;
  background-image: none;
  border-color: #273a4d;
}

.module-inverted-deepdark {
  color: #fff;
  background: #0b1b2c linear-gradient(180deg, #2d3c4b, #0b1b2c) repeat-x;
  border-color: #0b1b2c;
}

.module-inverted-deepdark .icon-container {
  background-color: #273a4d;
}

.module-inverted-deepdark .icon-container * {
  color: #0b1b2c;
}

.module-inverted-deepdark:hover {
  color: #fff;
  background: #03080d linear-gradient(180deg, #272c31, #03080d) repeat-x;
  border-color: #010203;
}

.module-inverted-deepdark:focus, .module-inverted-deepdark.focus {
  color: #fff;
  background: #03080d linear-gradient(180deg, #272c31, #03080d) repeat-x;
  border-color: #010203;
  box-shadow: 0 0 0 0.2rem rgba(48, 61, 76, 0.5);
}

.module-inverted-deepdark:active, .module-inverted-deepdark.active,
.show > .module-inverted-deepdark.dropdown-toggle {
  color: #fff;
  background-color: #010203;
  background-image: none;
  border-color: black;
}

.module-inverted-deepdark:active:focus, .module-inverted-deepdark.active:focus,
.show > .module-inverted-deepdark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(48, 61, 76, 0.5);
}

.module-inverted-deepdark:disabled, .module-inverted-deepdark.disabled {
  color: #fff;
  background-color: #0b1b2c;
  background-image: none;
  border-color: #0b1b2c;
}

.module-inverted-primary {
  color: #fff;
  background: #354f68 linear-gradient(180deg, #51687e, #354f68) repeat-x;
  border-color: #354f68;
}

.module-inverted-primary .icon-container {
  background-color: #273a4d;
}

.module-inverted-primary .icon-container * {
  color: #354f68;
}

.module-inverted-primary:hover {
  color: #fff;
  background: #283c4f linear-gradient(180deg, #465868, #283c4f) repeat-x;
  border-color: #243546;
}

.module-inverted-primary:focus, .module-inverted-primary.focus {
  color: #fff;
  background: #283c4f linear-gradient(180deg, #465868, #283c4f) repeat-x;
  border-color: #243546;
  box-shadow: 0 0 0 0.2rem rgba(83, 105, 127, 0.5);
}

.module-inverted-primary:active, .module-inverted-primary.active,
.show > .module-inverted-primary.dropdown-toggle {
  color: #fff;
  background-color: #243546;
  background-image: none;
  border-color: #1f2f3e;
}

.module-inverted-primary:active:focus, .module-inverted-primary.active:focus,
.show > .module-inverted-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(83, 105, 127, 0.5);
}

.module-inverted-primary:disabled, .module-inverted-primary.disabled {
  color: #fff;
  background-color: #354f68;
  background-image: none;
  border-color: #354f68;
}

.module-inverted-success {
  color: #fff;
  background: #5a9956 linear-gradient(180deg, #70a76f, #5a9956) repeat-x;
  border-color: #5a9956;
}

.module-inverted-success .icon-container {
  background-color: #273a4d;
}

.module-inverted-success .icon-container * {
  color: #5a9956;
}

.module-inverted-success:hover {
  color: #fff;
  background: #4c8148 linear-gradient(180deg, #649263, #4c8148) repeat-x;
  border-color: #477844;
}

.module-inverted-success:focus, .module-inverted-success.focus {
  color: #fff;
  background: #4c8148 linear-gradient(180deg, #649263, #4c8148) repeat-x;
  border-color: #477844;
  box-shadow: 0 0 0 0.2rem rgba(115, 168, 111, 0.5);
}

.module-inverted-success:active, .module-inverted-success.active,
.show > .module-inverted-success.dropdown-toggle {
  color: #fff;
  background-color: #477844;
  background-image: none;
  border-color: #42703f;
}

.module-inverted-success:active:focus, .module-inverted-success.active:focus,
.show > .module-inverted-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(115, 168, 111, 0.5);
}

.module-inverted-success:disabled, .module-inverted-success.disabled {
  color: #fff;
  background-color: #5a9956;
  background-image: none;
  border-color: #5a9956;
}

.module-inverted-info {
  color: #fff;
  background: #5299e0 linear-gradient(180deg, #69a7e4, #5299e0) repeat-x;
  border-color: #5299e0;
}

.module-inverted-info .icon-container {
  background-color: #273a4d;
}

.module-inverted-info .icon-container * {
  color: #5299e0;
}

.module-inverted-info:hover {
  color: #fff;
  background: #3286da linear-gradient(180deg, #4e97df, #3286da) repeat-x;
  border-color: #2780d8;
}

.module-inverted-info:focus, .module-inverted-info.focus {
  color: #fff;
  background: #3286da linear-gradient(180deg, #4e97df, #3286da) repeat-x;
  border-color: #2780d8;
  box-shadow: 0 0 0 0.2rem rgba(108, 168, 229, 0.5);
}

.module-inverted-info:active, .module-inverted-info.active,
.show > .module-inverted-info.dropdown-toggle {
  color: #fff;
  background-color: #2780d8;
  background-image: none;
  border-color: #2579cd;
}

.module-inverted-info:active:focus, .module-inverted-info.active:focus,
.show > .module-inverted-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 168, 229, 0.5);
}

.module-inverted-info:disabled, .module-inverted-info.disabled {
  color: #fff;
  background-color: #5299e0;
  background-image: none;
  border-color: #5299e0;
}

.module-inverted-warning {
  color: #273a4d;
  background: #f2d585 linear-gradient(180deg, #f1da97, #f2d585) repeat-x;
  border-color: #f2d585;
}

.module-inverted-warning .icon-container {
  background-color: #273a4d;
}

.module-inverted-warning .icon-container * {
  color: #f2d585;
}

.module-inverted-warning:hover {
  color: #273a4d;
  background: #eec962 linear-gradient(180deg, #eed079, #eec962) repeat-x;
  border-color: #edc557;
}

.module-inverted-warning:focus, .module-inverted-warning.focus {
  color: #273a4d;
  background: #eec962 linear-gradient(180deg, #eed079, #eec962) repeat-x;
  border-color: #edc557;
  box-shadow: 0 0 0 0.2rem rgba(212, 190, 125, 0.5);
}

.module-inverted-warning:active, .module-inverted-warning.active,
.show > .module-inverted-warning.dropdown-toggle {
  color: #273a4d;
  background-color: #edc557;
  background-image: none;
  border-color: #ecc14b;
}

.module-inverted-warning:active:focus, .module-inverted-warning.active:focus,
.show > .module-inverted-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 190, 125, 0.5);
}

.module-inverted-warning:disabled, .module-inverted-warning.disabled {
  color: #273a4d;
  background-color: #f2d585;
  background-image: none;
  border-color: #f2d585;
}

.module-inverted-danger {
  color: #fff;
  background: #ff4136 linear-gradient(180deg, #fc5c53, #ff4136) repeat-x;
  border-color: #ff4136;
}

.module-inverted-danger .icon-container {
  background-color: #273a4d;
}

.module-inverted-danger .icon-container * {
  color: #ff4136;
}

.module-inverted-danger:hover {
  color: #fff;
  background: #ff1d10 linear-gradient(180deg, #fc3d33, #ff1d10) repeat-x;
  border-color: #ff1103;
}

.module-inverted-danger:focus, .module-inverted-danger.focus {
  color: #fff;
  background: #ff1d10 linear-gradient(180deg, #fc3d33, #ff1d10) repeat-x;
  border-color: #ff1103;
  box-shadow: 0 0 0 0.2rem rgba(255, 94, 84, 0.5);
}

.module-inverted-danger:active, .module-inverted-danger.active,
.show > .module-inverted-danger.dropdown-toggle {
  color: #fff;
  background-color: #ff1103;
  background-image: none;
  border-color: #f50d00;
}

.module-inverted-danger:active:focus, .module-inverted-danger.active:focus,
.show > .module-inverted-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 94, 84, 0.5);
}

.module-inverted-danger:disabled, .module-inverted-danger.disabled {
  color: #fff;
  background-color: #ff4136;
  background-image: none;
  border-color: #ff4136;
}

.module-inverted-dark {
  color: #fff;
  background: #333 linear-gradient(180deg, #4f5051, #333) repeat-x;
  border-color: #333;
}

.module-inverted-dark .icon-container {
  background-color: #273a4d;
}

.module-inverted-dark .icon-container * {
  color: #333;
}

.module-inverted-dark:hover {
  color: #fff;
  background: #202020 linear-gradient(180deg, #3f4041, #202020) repeat-x;
  border-color: #1a1a1a;
}

.module-inverted-dark:focus, .module-inverted-dark.focus {
  color: #fff;
  background: #202020 linear-gradient(180deg, #3f4041, #202020) repeat-x;
  border-color: #1a1a1a;
  box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
}

.module-inverted-dark:active, .module-inverted-dark.active,
.show > .module-inverted-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1a1a;
  background-image: none;
  border-color: #131313;
}

.module-inverted-dark:active:focus, .module-inverted-dark.active:focus,
.show > .module-inverted-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
}

.module-inverted-dark:disabled, .module-inverted-dark.disabled {
  color: #fff;
  background-color: #333;
  background-image: none;
  border-color: #333;
}

.module-inverted-deep-ocean {
  color: #fff;
  background: #023946 linear-gradient(180deg, #255561, #023946) repeat-x;
  border-color: #023946;
}

.module-inverted-deep-ocean .icon-container {
  background-color: #273a4d;
}

.module-inverted-deep-ocean .icon-container * {
  color: #023946;
}

.module-inverted-deep-ocean:hover {
  color: #fff;
  background: #011b21 linear-gradient(180deg, #243b41, #011b21) repeat-x;
  border-color: #011114;
}

.module-inverted-deep-ocean:focus, .module-inverted-deep-ocean.focus {
  color: #fff;
  background: #011b21 linear-gradient(180deg, #243b41, #011b21) repeat-x;
  border-color: #011114;
  box-shadow: 0 0 0 0.2rem rgba(40, 87, 98, 0.5);
}

.module-inverted-deep-ocean:active, .module-inverted-deep-ocean.active,
.show > .module-inverted-deep-ocean.dropdown-toggle {
  color: #fff;
  background-color: #011114;
  background-image: none;
  border-color: #000708;
}

.module-inverted-deep-ocean:active:focus, .module-inverted-deep-ocean.active:focus,
.show > .module-inverted-deep-ocean.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 87, 98, 0.5);
}

.module-inverted-deep-ocean:disabled, .module-inverted-deep-ocean.disabled {
  color: #fff;
  background-color: #023946;
  background-image: none;
  border-color: #023946;
}

.module-inverted-teal {
  color: #fff;
  background: #035f75 linear-gradient(180deg, #267689, #035f75) repeat-x;
  border-color: #035f75;
}

.module-inverted-teal .icon-container {
  background-color: #273a4d;
}

.module-inverted-teal .icon-container * {
  color: #035f75;
}

.module-inverted-teal:hover {
  color: #fff;
  background: #024150 linear-gradient(180deg, #255c69, #024150) repeat-x;
  border-color: #023743;
}

.module-inverted-teal:focus, .module-inverted-teal.focus {
  color: #fff;
  background: #024150 linear-gradient(180deg, #255c69, #024150) repeat-x;
  border-color: #023743;
  box-shadow: 0 0 0 0.2rem rgba(41, 119, 138, 0.5);
}

.module-inverted-teal:active, .module-inverted-teal.active,
.show > .module-inverted-teal.dropdown-toggle {
  color: #fff;
  background-color: #023743;
  background-image: none;
  border-color: #012d37;
}

.module-inverted-teal:active:focus, .module-inverted-teal.active:focus,
.show > .module-inverted-teal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(41, 119, 138, 0.5);
}

.module-inverted-teal:disabled, .module-inverted-teal.disabled {
  color: #fff;
  background-color: #035f75;
  background-image: none;
  border-color: #035f75;
}

.module-inverted-petrol {
  color: #fff;
  background: #0f99ba linear-gradient(180deg, #30a7c4, #0f99ba) repeat-x;
  border-color: #0f99ba;
}

.module-inverted-petrol .icon-container {
  background-color: #273a4d;
}

.module-inverted-petrol .icon-container * {
  color: #0f99ba;
}

.module-inverted-petrol:hover {
  color: #fff;
  background: #0c7c97 linear-gradient(180deg, #2e8ea6, #0c7c97) repeat-x;
  border-color: #0b728b;
}

.module-inverted-petrol:focus, .module-inverted-petrol.focus {
  color: #fff;
  background: #0c7c97 linear-gradient(180deg, #2e8ea6, #0c7c97) repeat-x;
  border-color: #0b728b;
  box-shadow: 0 0 0 0.2rem rgba(51, 168, 196, 0.5);
}

.module-inverted-petrol:active, .module-inverted-petrol.active,
.show > .module-inverted-petrol.dropdown-toggle {
  color: #fff;
  background-color: #0b728b;
  background-image: none;
  border-color: #0a687f;
}

.module-inverted-petrol:active:focus, .module-inverted-petrol.active:focus,
.show > .module-inverted-petrol.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 168, 196, 0.5);
}

.module-inverted-petrol:disabled, .module-inverted-petrol.disabled {
  color: #fff;
  background-color: #0f99ba;
  background-image: none;
  border-color: #0f99ba;
}

.module-inverted-turquoise {
  color: #fff;
  background: #14b1d7 linear-gradient(180deg, #35bbdc, #14b1d7) repeat-x;
  border-color: #14b1d7;
}

.module-inverted-turquoise .icon-container {
  background-color: #273a4d;
}

.module-inverted-turquoise .icon-container * {
  color: #14b1d7;
}

.module-inverted-turquoise:hover {
  color: #fff;
  background: #1194b4 linear-gradient(180deg, #32a3bf, #1194b4) repeat-x;
  border-color: #108ba8;
}

.module-inverted-turquoise:focus, .module-inverted-turquoise.focus {
  color: #fff;
  background: #1194b4 linear-gradient(180deg, #32a3bf, #1194b4) repeat-x;
  border-color: #108ba8;
  box-shadow: 0 0 0 0.2rem rgba(55, 189, 221, 0.5);
}

.module-inverted-turquoise:active, .module-inverted-turquoise.active,
.show > .module-inverted-turquoise.dropdown-toggle {
  color: #fff;
  background-color: #108ba8;
  background-image: none;
  border-color: #0f819d;
}

.module-inverted-turquoise:active:focus, .module-inverted-turquoise.active:focus,
.show > .module-inverted-turquoise.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 189, 221, 0.5);
}

.module-inverted-turquoise:disabled, .module-inverted-turquoise.disabled {
  color: #fff;
  background-color: #14b1d7;
  background-image: none;
  border-color: #14b1d7;
}

.module-inverted-sky {
  color: #273a4d;
  background: #8cd8eb linear-gradient(180deg, #9bdced, #8cd8eb) repeat-x;
  border-color: #8cd8eb;
}

.module-inverted-sky .icon-container {
  background-color: #273a4d;
}

.module-inverted-sky .icon-container * {
  color: #8cd8eb;
}

.module-inverted-sky:hover {
  color: #273a4d;
  background: #6bcde5 linear-gradient(180deg, #7fd3e8, #6bcde5) repeat-x;
  border-color: #61c9e3;
}

.module-inverted-sky:focus, .module-inverted-sky.focus {
  color: #273a4d;
  background: #6bcde5 linear-gradient(180deg, #7fd3e8, #6bcde5) repeat-x;
  border-color: #61c9e3;
  box-shadow: 0 0 0 0.2rem rgba(125, 192, 211, 0.5);
}

.module-inverted-sky:active, .module-inverted-sky.active,
.show > .module-inverted-sky.dropdown-toggle {
  color: #273a4d;
  background-color: #61c9e3;
  background-image: none;
  border-color: #56c6e2;
}

.module-inverted-sky:active:focus, .module-inverted-sky.active:focus,
.show > .module-inverted-sky.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 192, 211, 0.5);
}

.module-inverted-sky:disabled, .module-inverted-sky.disabled {
  color: #273a4d;
  background-color: #8cd8eb;
  background-image: none;
  border-color: #8cd8eb;
}

.module-inverted-mountain-river {
  color: #273a4d;
  background: #c4eaf3 linear-gradient(180deg, #caecf4, #c4eaf3) repeat-x;
  border-color: #c4eaf3;
}

.module-inverted-mountain-river .icon-container {
  background-color: #273a4d;
}

.module-inverted-mountain-river .icon-container * {
  color: #c4eaf3;
}

.module-inverted-mountain-river:hover {
  color: #273a4d;
  background: #a4dfed linear-gradient(180deg, #afe2ef, #a4dfed) repeat-x;
  border-color: #9adbea;
}

.module-inverted-mountain-river:focus, .module-inverted-mountain-river.focus {
  color: #273a4d;
  background: #a4dfed linear-gradient(180deg, #afe2ef, #a4dfed) repeat-x;
  border-color: #9adbea;
  box-shadow: 0 0 0 0.2rem rgba(172, 208, 218, 0.5);
}

.module-inverted-mountain-river:active, .module-inverted-mountain-river.active,
.show > .module-inverted-mountain-river.dropdown-toggle {
  color: #273a4d;
  background-color: #9adbea;
  background-image: none;
  border-color: #8fd7e8;
}

.module-inverted-mountain-river:active:focus, .module-inverted-mountain-river.active:focus,
.show > .module-inverted-mountain-river.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(172, 208, 218, 0.5);
}

.module-inverted-mountain-river:disabled, .module-inverted-mountain-river.disabled {
  color: #273a4d;
  background-color: #c4eaf3;
  background-image: none;
  border-color: #c4eaf3;
}

.module-inverted-dark-forest {
  color: #fff;
  background: #1c401d linear-gradient(180deg, #3c5b3e, #1c401d) repeat-x;
  border-color: #1c401d;
}

.module-inverted-dark-forest .icon-container {
  background-color: #273a4d;
}

.module-inverted-dark-forest .icon-container * {
  color: #1c401d;
}

.module-inverted-dark-forest:hover {
  color: #fff;
  background: #102511 linear-gradient(180deg, #324534, #102511) repeat-x;
  border-color: #0c1d0d;
}

.module-inverted-dark-forest:focus, .module-inverted-dark-forest.focus {
  color: #fff;
  background: #102511 linear-gradient(180deg, #324534, #102511) repeat-x;
  border-color: #0c1d0d;
  box-shadow: 0 0 0 0.2rem rgba(62, 93, 63, 0.5);
}

.module-inverted-dark-forest:active, .module-inverted-dark-forest.active,
.show > .module-inverted-dark-forest.dropdown-toggle {
  color: #fff;
  background-color: #0c1d0d;
  background-image: none;
  border-color: #091409;
}

.module-inverted-dark-forest:active:focus, .module-inverted-dark-forest.active:focus,
.show > .module-inverted-dark-forest.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(62, 93, 63, 0.5);
}

.module-inverted-dark-forest:disabled, .module-inverted-dark-forest.disabled {
  color: #fff;
  background-color: #1c401d;
  background-image: none;
  border-color: #1c401d;
}

.module-inverted-fir {
  color: #fff;
  background: #137117 linear-gradient(180deg, #348539, #137117) repeat-x;
  border-color: #137117;
}

.module-inverted-fir .icon-container {
  background-color: #273a4d;
}

.module-inverted-fir .icon-container * {
  color: #137117;
}

.module-inverted-fir:hover {
  color: #fff;
  background: #0d5010 linear-gradient(180deg, #2f6933, #0d5010) repeat-x;
  border-color: #0c450e;
}

.module-inverted-fir:focus, .module-inverted-fir.focus {
  color: #fff;
  background: #0d5010 linear-gradient(180deg, #2f6933, #0d5010) repeat-x;
  border-color: #0c450e;
  box-shadow: 0 0 0 0.2rem rgba(54, 134, 58, 0.5);
}

.module-inverted-fir:active, .module-inverted-fir.active,
.show > .module-inverted-fir.dropdown-toggle {
  color: #fff;
  background-color: #0c450e;
  background-image: none;
  border-color: #0a3a0c;
}

.module-inverted-fir:active:focus, .module-inverted-fir.active:focus,
.show > .module-inverted-fir.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(54, 134, 58, 0.5);
}

.module-inverted-fir:disabled, .module-inverted-fir.disabled {
  color: #fff;
  background-color: #137117;
  background-image: none;
  border-color: #137117;
}

.module-inverted-larch {
  color: #fff;
  background: #1e9d23 linear-gradient(180deg, #3daa43, #1e9d23) repeat-x;
  border-color: #1e9d23;
}

.module-inverted-larch .icon-container {
  background-color: #273a4d;
}

.module-inverted-larch .icon-container * {
  color: #1e9d23;
}

.module-inverted-larch:hover {
  color: #fff;
  background: #187d1c linear-gradient(180deg, #388f3d, #187d1c) repeat-x;
  border-color: #167219;
}

.module-inverted-larch:focus, .module-inverted-larch.focus {
  color: #fff;
  background: #187d1c linear-gradient(180deg, #388f3d, #187d1c) repeat-x;
  border-color: #167219;
  box-shadow: 0 0 0 0.2rem rgba(64, 172, 68, 0.5);
}

.module-inverted-larch:active, .module-inverted-larch.active,
.show > .module-inverted-larch.dropdown-toggle {
  color: #fff;
  background-color: #167219;
  background-image: none;
  border-color: #146717;
}

.module-inverted-larch:active:focus, .module-inverted-larch.active:focus,
.show > .module-inverted-larch.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 172, 68, 0.5);
}

.module-inverted-larch:disabled, .module-inverted-larch.disabled {
  color: #fff;
  background-color: #1e9d23;
  background-image: none;
  border-color: #1e9d23;
}

.module-inverted-gra {
  color: #fff;
  background: #47ca4b linear-gradient(180deg, #60d065, #47ca4b) repeat-x;
  border-color: #47ca4b;
}

.module-inverted-gra .icon-container {
  background-color: #273a4d;
}

.module-inverted-gra .icon-container * {
  color: #47ca4b;
}

.module-inverted-gra:hover {
  color: #fff;
  background: #34b638 linear-gradient(180deg, #50c055, #34b638) repeat-x;
  border-color: #32ac35;
}

.module-inverted-gra:focus, .module-inverted-gra.focus {
  color: #fff;
  background: #34b638 linear-gradient(180deg, #50c055, #34b638) repeat-x;
  border-color: #32ac35;
  box-shadow: 0 0 0 0.2rem rgba(99, 210, 102, 0.5);
}

.module-inverted-gra:active, .module-inverted-gra.active,
.show > .module-inverted-gra.dropdown-toggle {
  color: #fff;
  background-color: #32ac35;
  background-image: none;
  border-color: #2fa232;
}

.module-inverted-gra:active:focus, .module-inverted-gra.active:focus,
.show > .module-inverted-gra.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 210, 102, 0.5);
}

.module-inverted-gra:disabled, .module-inverted-gra.disabled {
  color: #fff;
  background-color: #47ca4b;
  background-image: none;
  border-color: #47ca4b;
}

.module-inverted-fern {
  color: #273a4d;
  background: #7adf7e linear-gradient(180deg, #8be291, #7adf7e) repeat-x;
  border-color: #7adf7e;
}

.module-inverted-fern .icon-container {
  background-color: #273a4d;
}

.module-inverted-fern .icon-container * {
  color: #7adf7e;
}

.module-inverted-fern:hover {
  color: #273a4d;
  background: #5bd860 linear-gradient(180deg, #71dc77, #5bd860) repeat-x;
  border-color: #51d556;
}

.module-inverted-fern:focus, .module-inverted-fern.focus {
  color: #273a4d;
  background: #5bd860 linear-gradient(180deg, #71dc77, #5bd860) repeat-x;
  border-color: #51d556;
  box-shadow: 0 0 0 0.2rem rgba(110, 198, 119, 0.5);
}

.module-inverted-fern:active, .module-inverted-fern.active,
.show > .module-inverted-fern.dropdown-toggle {
  color: #273a4d;
  background-color: #51d556;
  background-image: none;
  border-color: #47d34c;
}

.module-inverted-fern:active:focus, .module-inverted-fern.active:focus,
.show > .module-inverted-fern.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(110, 198, 119, 0.5);
}

.module-inverted-fern:disabled, .module-inverted-fern.disabled {
  color: #273a4d;
  background-color: #7adf7e;
  background-image: none;
  border-color: #7adf7e;
}

.module-inverted-min {
  color: #273a4d;
  background: #beefbf linear-gradient(180deg, #c5f0c8, #beefbf) repeat-x;
  border-color: #beefbf;
}

.module-inverted-min .icon-container {
  background-color: #273a4d;
}

.module-inverted-min .icon-container * {
  color: #beefbf;
}

.module-inverted-min:hover {
  color: #273a4d;
  background: #9fe7a1 linear-gradient(180deg, #abe9ae, #9fe7a1) repeat-x;
  border-color: #95e597;
}

.module-inverted-min:focus, .module-inverted-min.focus {
  color: #273a4d;
  background: #9fe7a1 linear-gradient(180deg, #abe9ae, #9fe7a1) repeat-x;
  border-color: #95e597;
  box-shadow: 0 0 0 0.2rem rgba(167, 212, 174, 0.5);
}

.module-inverted-min:active, .module-inverted-min.active,
.show > .module-inverted-min.dropdown-toggle {
  color: #273a4d;
  background-color: #95e597;
  background-image: none;
  border-color: #8be28d;
}

.module-inverted-min:active:focus, .module-inverted-min.active:focus,
.show > .module-inverted-min.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(167, 212, 174, 0.5);
}

.module-inverted-min:disabled, .module-inverted-min.disabled {
  color: #273a4d;
  background-color: #beefbf;
  background-image: none;
  border-color: #beefbf;
}

.module-inverted-seaweed {
  color: #fff;
  background: #3a401c linear-gradient(180deg, #555b3d, #3a401c) repeat-x;
  border-color: #3a401c;
}

.module-inverted-seaweed .icon-container {
  background-color: #273a4d;
}

.module-inverted-seaweed .icon-container * {
  color: #3a401c;
}

.module-inverted-seaweed:hover {
  color: #fff;
  background: #222510 linear-gradient(180deg, #414533, #222510) repeat-x;
  border-color: #1a1d0c;
}

.module-inverted-seaweed:focus, .module-inverted-seaweed.focus {
  color: #fff;
  background: #222510 linear-gradient(180deg, #414533, #222510) repeat-x;
  border-color: #1a1d0c;
  box-shadow: 0 0 0 0.2rem rgba(88, 93, 62, 0.5);
}

.module-inverted-seaweed:active, .module-inverted-seaweed.active,
.show > .module-inverted-seaweed.dropdown-toggle {
  color: #fff;
  background-color: #1a1d0c;
  background-image: none;
  border-color: #121409;
}

.module-inverted-seaweed:active:focus, .module-inverted-seaweed.active:focus,
.show > .module-inverted-seaweed.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 93, 62, 0.5);
}

.module-inverted-seaweed:disabled, .module-inverted-seaweed.disabled {
  color: #fff;
  background-color: #3a401c;
  background-image: none;
  border-color: #3a401c;
}

.module-inverted-army {
  color: #fff;
  background: #515c1d linear-gradient(180deg, #69733e, #515c1d) repeat-x;
  border-color: #515c1d;
}

.module-inverted-army .icon-container {
  background-color: #273a4d;
}

.module-inverted-army .icon-container * {
  color: #515c1d;
}

.module-inverted-army:hover {
  color: #fff;
  background: #373f14 linear-gradient(180deg, #535a36, #373f14) repeat-x;
  border-color: #2f3511;
}

.module-inverted-army:focus, .module-inverted-army.focus {
  color: #fff;
  background: #373f14 linear-gradient(180deg, #535a36, #373f14) repeat-x;
  border-color: #2f3511;
  box-shadow: 0 0 0 0.2rem rgba(107, 116, 63, 0.5);
}

.module-inverted-army:active, .module-inverted-army.active,
.show > .module-inverted-army.dropdown-toggle {
  color: #fff;
  background-color: #2f3511;
  background-image: none;
  border-color: #262c0e;
}

.module-inverted-army:active:focus, .module-inverted-army.active:focus,
.show > .module-inverted-army.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(107, 116, 63, 0.5);
}

.module-inverted-army:disabled, .module-inverted-army.disabled {
  color: #fff;
  background-color: #515c1d;
  background-image: none;
  border-color: #515c1d;
}

.module-inverted-green-olive {
  color: #fff;
  background: #74861f linear-gradient(180deg, #869740, #74861f) repeat-x;
  border-color: #74861f;
}

.module-inverted-green-olive .icon-container {
  background-color: #273a4d;
}

.module-inverted-green-olive .icon-container * {
  color: #74861f;
}

.module-inverted-green-olive:hover {
  color: #fff;
  background: #596718 linear-gradient(180deg, #6f7c3a, #596718) repeat-x;
  border-color: #505d15;
}

.module-inverted-green-olive:focus, .module-inverted-green-olive.focus {
  color: #fff;
  background: #596718 linear-gradient(180deg, #6f7c3a, #596718) repeat-x;
  border-color: #505d15;
  box-shadow: 0 0 0 0.2rem rgba(137, 152, 65, 0.5);
}

.module-inverted-green-olive:active, .module-inverted-green-olive.active,
.show > .module-inverted-green-olive.dropdown-toggle {
  color: #fff;
  background-color: #505d15;
  background-image: none;
  border-color: #475213;
}

.module-inverted-green-olive:active:focus, .module-inverted-green-olive.active:focus,
.show > .module-inverted-green-olive.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(137, 152, 65, 0.5);
}

.module-inverted-green-olive:disabled, .module-inverted-green-olive.disabled {
  color: #fff;
  background-color: #74861f;
  background-image: none;
  border-color: #74861f;
}

.module-inverted-apple {
  color: #273a4d;
  background: #a7c80d linear-gradient(180deg, #b2cf31, #a7c80d) repeat-x;
  border-color: #a7c80d;
}

.module-inverted-apple .icon-container {
  background-color: #273a4d;
}

.module-inverted-apple .icon-container * {
  color: #a7c80d;
}

.module-inverted-apple:hover {
  color: #fff;
  background: #89a40b linear-gradient(180deg, #98b02f, #89a40b) repeat-x;
  border-color: #7f980a;
}

.module-inverted-apple:focus, .module-inverted-apple.focus {
  color: #fff;
  background: #89a40b linear-gradient(180deg, #98b02f, #89a40b) repeat-x;
  border-color: #7f980a;
  box-shadow: 0 0 0 0.2rem rgba(148, 179, 23, 0.5);
}

.module-inverted-apple:active, .module-inverted-apple.active,
.show > .module-inverted-apple.dropdown-toggle {
  color: #fff;
  background-color: #7f980a;
  background-image: none;
  border-color: #758c09;
}

.module-inverted-apple:active:focus, .module-inverted-apple.active:focus,
.show > .module-inverted-apple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(148, 179, 23, 0.5);
}

.module-inverted-apple:disabled, .module-inverted-apple.disabled {
  color: #273a4d;
  background-color: #a7c80d;
  background-image: none;
  border-color: #a7c80d;
}

.module-inverted-lime {
  color: #273a4d;
  background: #c7e82e linear-gradient(180deg, #cdea4d, #c7e82e) repeat-x;
  border-color: #c7e82e;
}

.module-inverted-lime .icon-container {
  background-color: #273a4d;
}

.module-inverted-lime .icon-container * {
  color: #c7e82e;
}

.module-inverted-lime:hover {
  color: #273a4d;
  background: #b6d818 linear-gradient(180deg, #bedc3a, #b6d818) repeat-x;
  border-color: #accc17;
}

.module-inverted-lime:focus, .module-inverted-lime.focus {
  color: #273a4d;
  background: #b6d818 linear-gradient(180deg, #bedc3a, #b6d818) repeat-x;
  border-color: #accc17;
  box-shadow: 0 0 0 0.2rem rgba(175, 206, 51, 0.5);
}

.module-inverted-lime:active, .module-inverted-lime.active,
.show > .module-inverted-lime.dropdown-toggle {
  color: #273a4d;
  background-color: #accc17;
  background-image: none;
  border-color: #a3c115;
}

.module-inverted-lime:active:focus, .module-inverted-lime.active:focus,
.show > .module-inverted-lime.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(175, 206, 51, 0.5);
}

.module-inverted-lime:disabled, .module-inverted-lime.disabled {
  color: #273a4d;
  background-color: #c7e82e;
  background-image: none;
  border-color: #c7e82e;
}

.module-inverted-tea {
  color: #273a4d;
  background: #e6f797 linear-gradient(180deg, #e7f7a6, #e6f797) repeat-x;
  border-color: #e6f797;
}

.module-inverted-tea .icon-container {
  background-color: #273a4d;
}

.module-inverted-tea .icon-container * {
  color: #e6f797;
}

.module-inverted-tea:hover {
  color: #273a4d;
  background: #ddf473 linear-gradient(180deg, #e0f488, #ddf473) repeat-x;
  border-color: #dbf368;
}

.module-inverted-tea:focus, .module-inverted-tea.focus {
  color: #273a4d;
  background: #ddf473 linear-gradient(180deg, #e0f488, #ddf473) repeat-x;
  border-color: #dbf368;
  box-shadow: 0 0 0 0.2rem rgba(201, 219, 140, 0.5);
}

.module-inverted-tea:active, .module-inverted-tea.active,
.show > .module-inverted-tea.dropdown-toggle {
  color: #273a4d;
  background-color: #dbf368;
  background-image: none;
  border-color: #d8f25c;
}

.module-inverted-tea:active:focus, .module-inverted-tea.active:focus,
.show > .module-inverted-tea.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(201, 219, 140, 0.5);
}

.module-inverted-tea:disabled, .module-inverted-tea.disabled {
  color: #273a4d;
  background-color: #e6f797;
  background-image: none;
  border-color: #e6f797;
}

.module-inverted-weather-oak {
  color: #fff;
  background: #403b1c linear-gradient(180deg, #5a573d, #403b1c) repeat-x;
  border-color: #403b1c;
}

.module-inverted-weather-oak .icon-container {
  background-color: #273a4d;
}

.module-inverted-weather-oak .icon-container * {
  color: #403b1c;
}

.module-inverted-weather-oak:hover {
  color: #fff;
  background: #252210 linear-gradient(180deg, #434233, #252210) repeat-x;
  border-color: #1d1a0c;
}

.module-inverted-weather-oak:focus, .module-inverted-weather-oak.focus {
  color: #fff;
  background: #252210 linear-gradient(180deg, #434233, #252210) repeat-x;
  border-color: #1d1a0c;
  box-shadow: 0 0 0 0.2rem rgba(93, 88, 62, 0.5);
}

.module-inverted-weather-oak:active, .module-inverted-weather-oak.active,
.show > .module-inverted-weather-oak.dropdown-toggle {
  color: #fff;
  background-color: #1d1a0c;
  background-image: none;
  border-color: #141209;
}

.module-inverted-weather-oak:active:focus, .module-inverted-weather-oak.active:focus,
.show > .module-inverted-weather-oak.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(93, 88, 62, 0.5);
}

.module-inverted-weather-oak:disabled, .module-inverted-weather-oak.disabled {
  color: #fff;
  background-color: #403b1c;
  background-image: none;
  border-color: #403b1c;
}

.module-inverted-avocado {
  color: #fff;
  background: #5c541d linear-gradient(180deg, #726c3e, #5c541d) repeat-x;
  border-color: #5c541d;
}

.module-inverted-avocado .icon-container {
  background-color: #273a4d;
}

.module-inverted-avocado .icon-container * {
  color: #5c541d;
}

.module-inverted-avocado:hover {
  color: #fff;
  background: #3f3914 linear-gradient(180deg, #595636, #3f3914) repeat-x;
  border-color: #353111;
}

.module-inverted-avocado:focus, .module-inverted-avocado.focus {
  color: #fff;
  background: #3f3914 linear-gradient(180deg, #595636, #3f3914) repeat-x;
  border-color: #353111;
  box-shadow: 0 0 0 0.2rem rgba(116, 110, 63, 0.5);
}

.module-inverted-avocado:active, .module-inverted-avocado.active,
.show > .module-inverted-avocado.dropdown-toggle {
  color: #fff;
  background-color: #353111;
  background-image: none;
  border-color: #2c280e;
}

.module-inverted-avocado:active:focus, .module-inverted-avocado.active:focus,
.show > .module-inverted-avocado.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(116, 110, 63, 0.5);
}

.module-inverted-avocado:disabled, .module-inverted-avocado.disabled {
  color: #fff;
  background-color: #5c541d;
  background-image: none;
  border-color: #5c541d;
}

.module-inverted-olive {
  color: #fff;
  background: #a08c0d linear-gradient(180deg, #ac9c31, #a08c0d) repeat-x;
  border-color: #a08c0d;
}

.module-inverted-olive .icon-container {
  background-color: #273a4d;
}

.module-inverted-olive .icon-container * {
  color: #a08c0d;
}

.module-inverted-olive:hover {
  color: #fff;
  background: #7d6d0a linear-gradient(180deg, #8e812e, #7d6d0a) repeat-x;
  border-color: #716309;
}

.module-inverted-olive:focus, .module-inverted-olive.focus {
  color: #fff;
  background: #7d6d0a linear-gradient(180deg, #8e812e, #7d6d0a) repeat-x;
  border-color: #716309;
  box-shadow: 0 0 0 0.2rem rgba(174, 157, 49, 0.5);
}

.module-inverted-olive:active, .module-inverted-olive.active,
.show > .module-inverted-olive.dropdown-toggle {
  color: #fff;
  background-color: #716309;
  background-image: none;
  border-color: #655808;
}

.module-inverted-olive:active:focus, .module-inverted-olive.active:focus,
.show > .module-inverted-olive.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(174, 157, 49, 0.5);
}

.module-inverted-olive:disabled, .module-inverted-olive.disabled {
  color: #fff;
  background-color: #a08c0d;
  background-image: none;
  border-color: #a08c0d;
}

.module-inverted-gold {
  color: #273a4d;
  background: #ddbf00 linear-gradient(180deg, #e0c726, #ddbf00) repeat-x;
  border-color: #ddbf00;
}

.module-inverted-gold .icon-container {
  background-color: #273a4d;
}

.module-inverted-gold .icon-container * {
  color: #ddbf00;
}

.module-inverted-gold:hover {
  color: #fff;
  background: #b79e00 linear-gradient(180deg, #bfab26, #b79e00) repeat-x;
  border-color: #aa9300;
}

.module-inverted-gold:focus, .module-inverted-gold.focus {
  color: #fff;
  background: #b79e00 linear-gradient(180deg, #bfab26, #b79e00) repeat-x;
  border-color: #aa9300;
  box-shadow: 0 0 0 0.2rem rgba(194, 171, 12, 0.5);
}

.module-inverted-gold:active, .module-inverted-gold.active,
.show > .module-inverted-gold.dropdown-toggle {
  color: #fff;
  background-color: #aa9300;
  background-image: none;
  border-color: #9d8800;
}

.module-inverted-gold:active:focus, .module-inverted-gold.active:focus,
.show > .module-inverted-gold.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(194, 171, 12, 0.5);
}

.module-inverted-gold:disabled, .module-inverted-gold.disabled {
  color: #273a4d;
  background-color: #ddbf00;
  background-image: none;
  border-color: #ddbf00;
}

.module-inverted-sun {
  color: #273a4d;
  background: #ffe854 linear-gradient(180deg, #fcea6d, #ffe854) repeat-x;
  border-color: #ffe854;
}

.module-inverted-sun .icon-container {
  background-color: #273a4d;
}

.module-inverted-sun .icon-container * {
  color: #ffe854;
}

.module-inverted-sun:hover {
  color: #273a4d;
  background: #ffe32e linear-gradient(180deg, #fce64c, #ffe32e) repeat-x;
  border-color: #ffe121;
}

.module-inverted-sun:focus, .module-inverted-sun.focus {
  color: #273a4d;
  background: #ffe32e linear-gradient(180deg, #fce64c, #ffe32e) repeat-x;
  border-color: #ffe121;
  box-shadow: 0 0 0 0.2rem rgba(223, 206, 83, 0.5);
}

.module-inverted-sun:active, .module-inverted-sun.active,
.show > .module-inverted-sun.dropdown-toggle {
  color: #273a4d;
  background-color: #ffe121;
  background-image: none;
  border-color: #ffdf14;
}

.module-inverted-sun:active:focus, .module-inverted-sun.active:focus,
.show > .module-inverted-sun.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 206, 83, 0.5);
}

.module-inverted-sun:disabled, .module-inverted-sun.disabled {
  color: #273a4d;
  background-color: #ffe854;
  background-image: none;
  border-color: #ffe854;
}

.module-inverted-banana {
  color: #273a4d;
  background: #fff3a7 linear-gradient(180deg, #fcf3b3, #fff3a7) repeat-x;
  border-color: #fff3a7;
}

.module-inverted-banana .icon-container {
  background-color: #273a4d;
}

.module-inverted-banana .icon-container * {
  color: #fff3a7;
}

.module-inverted-banana:hover {
  color: #273a4d;
  background: #ffee81 linear-gradient(180deg, #fcef93, #ffee81) repeat-x;
  border-color: #ffec74;
}

.module-inverted-banana:focus, .module-inverted-banana.focus {
  color: #273a4d;
  background: #ffee81 linear-gradient(180deg, #fcef93, #ffee81) repeat-x;
  border-color: #ffec74;
  box-shadow: 0 0 0 0.2rem rgba(223, 215, 154, 0.5);
}

.module-inverted-banana:active, .module-inverted-banana.active,
.show > .module-inverted-banana.dropdown-toggle {
  color: #273a4d;
  background-color: #ffec74;
  background-image: none;
  border-color: #ffea67;
}

.module-inverted-banana:active:focus, .module-inverted-banana.active:focus,
.show > .module-inverted-banana.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 215, 154, 0.5);
}

.module-inverted-banana:disabled, .module-inverted-banana.disabled {
  color: #273a4d;
  background-color: #fff3a7;
  background-image: none;
  border-color: #fff3a7;
}

.module-inverted-walnut {
  color: #fff;
  background: #40331c linear-gradient(180deg, #5a503d, #40331c) repeat-x;
  border-color: #40331c;
}

.module-inverted-walnut .icon-container {
  background-color: #273a4d;
}

.module-inverted-walnut .icon-container * {
  color: #40331c;
}

.module-inverted-walnut:hover {
  color: #fff;
  background: #251e10 linear-gradient(180deg, #433e33, #251e10) repeat-x;
  border-color: #1d170c;
}

.module-inverted-walnut:focus, .module-inverted-walnut.focus {
  color: #fff;
  background: #251e10 linear-gradient(180deg, #433e33, #251e10) repeat-x;
  border-color: #1d170c;
  box-shadow: 0 0 0 0.2rem rgba(93, 82, 62, 0.5);
}

.module-inverted-walnut:active, .module-inverted-walnut.active,
.show > .module-inverted-walnut.dropdown-toggle {
  color: #fff;
  background-color: #1d170c;
  background-image: none;
  border-color: #141009;
}

.module-inverted-walnut:active:focus, .module-inverted-walnut.active:focus,
.show > .module-inverted-walnut.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(93, 82, 62, 0.5);
}

.module-inverted-walnut:disabled, .module-inverted-walnut.disabled {
  color: #fff;
  background-color: #40331c;
  background-image: none;
  border-color: #40331c;
}

.module-inverted-flaxen {
  color: #fff;
  background: #6a4a11 linear-gradient(180deg, #7e6434, #6a4a11) repeat-x;
  border-color: #6a4a11;
}

.module-inverted-flaxen .icon-container {
  background-color: #273a4d;
}

.module-inverted-flaxen .icon-container * {
  color: #6a4a11;
}

.module-inverted-flaxen:hover {
  color: #fff;
  background: #49330c linear-gradient(180deg, #62502f, #49330c) repeat-x;
  border-color: #3e2b0a;
}

.module-inverted-flaxen:focus, .module-inverted-flaxen.focus {
  color: #fff;
  background: #49330c linear-gradient(180deg, #62502f, #49330c) repeat-x;
  border-color: #3e2b0a;
  box-shadow: 0 0 0 0.2rem rgba(128, 101, 53, 0.5);
}

.module-inverted-flaxen:active, .module-inverted-flaxen.active,
.show > .module-inverted-flaxen.dropdown-toggle {
  color: #fff;
  background-color: #3e2b0a;
  background-image: none;
  border-color: #332408;
}

.module-inverted-flaxen:active:focus, .module-inverted-flaxen.active:focus,
.show > .module-inverted-flaxen.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 101, 53, 0.5);
}

.module-inverted-flaxen:disabled, .module-inverted-flaxen.disabled {
  color: #fff;
  background-color: #6a4a11;
  background-image: none;
  border-color: #6a4a11;
}

.module-inverted-sand-stone {
  color: #fff;
  background: #9b670e linear-gradient(180deg, #a77c31, #9b670e) repeat-x;
  border-color: #9b670e;
}

.module-inverted-sand-stone .icon-container {
  background-color: #273a4d;
}

.module-inverted-sand-stone .icon-container * {
  color: #9b670e;
}

.module-inverted-sand-stone:hover {
  color: #fff;
  background: #78500b linear-gradient(180deg, #8a682f, #78500b) repeat-x;
  border-color: #6c480a;
}

.module-inverted-sand-stone:focus, .module-inverted-sand-stone.focus {
  color: #fff;
  background: #78500b linear-gradient(180deg, #8a682f, #78500b) repeat-x;
  border-color: #6c480a;
  box-shadow: 0 0 0 0.2rem rgba(170, 126, 50, 0.5);
}

.module-inverted-sand-stone:active, .module-inverted-sand-stone.active,
.show > .module-inverted-sand-stone.dropdown-toggle {
  color: #fff;
  background-color: #6c480a;
  background-image: none;
  border-color: #614009;
}

.module-inverted-sand-stone:active:focus, .module-inverted-sand-stone.active:focus,
.show > .module-inverted-sand-stone.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(170, 126, 50, 0.5);
}

.module-inverted-sand-stone:disabled, .module-inverted-sand-stone.disabled {
  color: #fff;
  background-color: #9b670e;
  background-image: none;
  border-color: #9b670e;
}

.module-inverted-carrot {
  color: #273a4d;
  background: #f09800 linear-gradient(180deg, #f0a626, #f09800) repeat-x;
  border-color: #f09800;
}

.module-inverted-carrot .icon-container {
  background-color: #273a4d;
}

.module-inverted-carrot .icon-container * {
  color: #f09800;
}

.module-inverted-carrot:hover {
  color: #fff;
  background: #ca8000 linear-gradient(180deg, #cf9126, #ca8000) repeat-x;
  border-color: #bd7800;
}

.module-inverted-carrot:focus, .module-inverted-carrot.focus {
  color: #fff;
  background: #ca8000 linear-gradient(180deg, #cf9126, #ca8000) repeat-x;
  border-color: #bd7800;
  box-shadow: 0 0 0 0.2rem rgba(210, 138, 12, 0.5);
}

.module-inverted-carrot:active, .module-inverted-carrot.active,
.show > .module-inverted-carrot.dropdown-toggle {
  color: #fff;
  background-color: #bd7800;
  background-image: none;
  border-color: #b07000;
}

.module-inverted-carrot:active:focus, .module-inverted-carrot.active:focus,
.show > .module-inverted-carrot.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 138, 12, 0.5);
}

.module-inverted-carrot:disabled, .module-inverted-carrot.disabled {
  color: #273a4d;
  background-color: #f09800;
  background-image: none;
  border-color: #f09800;
}

.module-inverted-apricot {
  color: #273a4d;
  background: #ffc155 linear-gradient(180deg, #fcc96e, #ffc155) repeat-x;
  border-color: #ffc155;
}

.module-inverted-apricot .icon-container {
  background-color: #273a4d;
}

.module-inverted-apricot .icon-container * {
  color: #ffc155;
}

.module-inverted-apricot:hover {
  color: #273a4d;
  background: #ffb32f linear-gradient(180deg, #fcbd4d, #ffb32f) repeat-x;
  border-color: #ffae22;
}

.module-inverted-apricot:focus, .module-inverted-apricot.focus {
  color: #273a4d;
  background: #ffb32f linear-gradient(180deg, #fcbd4d, #ffb32f) repeat-x;
  border-color: #ffae22;
  box-shadow: 0 0 0 0.2rem rgba(223, 173, 84, 0.5);
}

.module-inverted-apricot:active, .module-inverted-apricot.active,
.show > .module-inverted-apricot.dropdown-toggle {
  color: #273a4d;
  background-color: #ffae22;
  background-image: none;
  border-color: #ffaa15;
}

.module-inverted-apricot:active:focus, .module-inverted-apricot.active:focus,
.show > .module-inverted-apricot.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 173, 84, 0.5);
}

.module-inverted-apricot:disabled, .module-inverted-apricot.disabled {
  color: #273a4d;
  background-color: #ffc155;
  background-image: none;
  border-color: #ffc155;
}

.module-inverted-sugar-melon {
  color: #273a4d;
  background: #ffdda1 linear-gradient(180deg, #fce1ae, #ffdda1) repeat-x;
  border-color: #ffdda1;
}

.module-inverted-sugar-melon .icon-container {
  background-color: #273a4d;
}

.module-inverted-sugar-melon .icon-container * {
  color: #ffdda1;
}

.module-inverted-sugar-melon:hover {
  color: #273a4d;
  background: #ffcf7b linear-gradient(180deg, #fcd58e, #ffcf7b) repeat-x;
  border-color: #ffcb6e;
}

.module-inverted-sugar-melon:focus, .module-inverted-sugar-melon.focus {
  color: #273a4d;
  background: #ffcf7b linear-gradient(180deg, #fcd58e, #ffcf7b) repeat-x;
  border-color: #ffcb6e;
  box-shadow: 0 0 0 0.2rem rgba(223, 197, 148, 0.5);
}

.module-inverted-sugar-melon:active, .module-inverted-sugar-melon.active,
.show > .module-inverted-sugar-melon.dropdown-toggle {
  color: #273a4d;
  background-color: #ffcb6e;
  background-image: none;
  border-color: #ffc661;
}

.module-inverted-sugar-melon:active:focus, .module-inverted-sugar-melon.active:focus,
.show > .module-inverted-sugar-melon.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 197, 148, 0.5);
}

.module-inverted-sugar-melon:disabled, .module-inverted-sugar-melon.disabled {
  color: #273a4d;
  background-color: #ffdda1;
  background-image: none;
  border-color: #ffdda1;
}

.module-inverted-chocolate {
  color: #fff;
  background: #40241c linear-gradient(180deg, #5a433d, #40241c) repeat-x;
  border-color: #40241c;
}

.module-inverted-chocolate .icon-container {
  background-color: #273a4d;
}

.module-inverted-chocolate .icon-container * {
  color: #40241c;
}

.module-inverted-chocolate:hover {
  color: #fff;
  background: #251510 linear-gradient(180deg, #433733, #251510) repeat-x;
  border-color: #1d100c;
}

.module-inverted-chocolate:focus, .module-inverted-chocolate.focus {
  color: #fff;
  background: #251510 linear-gradient(180deg, #433733, #251510) repeat-x;
  border-color: #1d100c;
  box-shadow: 0 0 0 0.2rem rgba(93, 69, 62, 0.5);
}

.module-inverted-chocolate:active, .module-inverted-chocolate.active,
.show > .module-inverted-chocolate.dropdown-toggle {
  color: #fff;
  background-color: #1d100c;
  background-image: none;
  border-color: #140b09;
}

.module-inverted-chocolate:active:focus, .module-inverted-chocolate.active:focus,
.show > .module-inverted-chocolate.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(93, 69, 62, 0.5);
}

.module-inverted-chocolate:disabled, .module-inverted-chocolate.disabled {
  color: #fff;
  background-color: #40241c;
  background-image: none;
  border-color: #40241c;
}

.module-inverted-blood {
  color: #fff;
  background: #6a2511 linear-gradient(180deg, #7e4434, #6a2511) repeat-x;
  border-color: #6a2511;
}

.module-inverted-blood .icon-container {
  background-color: #273a4d;
}

.module-inverted-blood .icon-container * {
  color: #6a2511;
}

.module-inverted-blood:hover {
  color: #fff;
  background: #49190c linear-gradient(180deg, #623a2f, #49190c) repeat-x;
  border-color: #3e160a;
}

.module-inverted-blood:focus, .module-inverted-blood.focus {
  color: #fff;
  background: #49190c linear-gradient(180deg, #623a2f, #49190c) repeat-x;
  border-color: #3e160a;
  box-shadow: 0 0 0 0.2rem rgba(128, 70, 53, 0.5);
}

.module-inverted-blood:active, .module-inverted-blood.active,
.show > .module-inverted-blood.dropdown-toggle {
  color: #fff;
  background-color: #3e160a;
  background-image: none;
  border-color: #331208;
}

.module-inverted-blood:active:focus, .module-inverted-blood.active:focus,
.show > .module-inverted-blood.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 70, 53, 0.5);
}

.module-inverted-blood:disabled, .module-inverted-blood.disabled {
  color: #fff;
  background-color: #6a2511;
  background-image: none;
  border-color: #6a2511;
}

.module-inverted-brick {
  color: #fff;
  background: #9b2d0e linear-gradient(180deg, #a74b31, #9b2d0e) repeat-x;
  border-color: #9b2d0e;
}

.module-inverted-brick .icon-container {
  background-color: #273a4d;
}

.module-inverted-brick .icon-container * {
  color: #9b2d0e;
}

.module-inverted-brick:hover {
  color: #fff;
  background: #78230b linear-gradient(180deg, #8a422f, #78230b) repeat-x;
  border-color: #6c1f0a;
}

.module-inverted-brick:focus, .module-inverted-brick.focus {
  color: #fff;
  background: #78230b linear-gradient(180deg, #8a422f, #78230b) repeat-x;
  border-color: #6c1f0a;
  box-shadow: 0 0 0 0.2rem rgba(170, 77, 50, 0.5);
}

.module-inverted-brick:active, .module-inverted-brick.active,
.show > .module-inverted-brick.dropdown-toggle {
  color: #fff;
  background-color: #6c1f0a;
  background-image: none;
  border-color: #611c09;
}

.module-inverted-brick:active:focus, .module-inverted-brick.active:focus,
.show > .module-inverted-brick.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(170, 77, 50, 0.5);
}

.module-inverted-brick:disabled, .module-inverted-brick.disabled {
  color: #fff;
  background-color: #9b2d0e;
  background-image: none;
  border-color: #9b2d0e;
}

.module-inverted-chili {
  color: #fff;
  background: #e83200 linear-gradient(180deg, #e94f26, #e83200) repeat-x;
  border-color: #e83200;
}

.module-inverted-chili .icon-container {
  background-color: #273a4d;
}

.module-inverted-chili .icon-container * {
  color: #e83200;
}

.module-inverted-chili:hover {
  color: #fff;
  background: #c22a00 linear-gradient(180deg, #c84826, #c22a00) repeat-x;
  border-color: #b52700;
}

.module-inverted-chili:focus, .module-inverted-chili.focus {
  color: #fff;
  background: #c22a00 linear-gradient(180deg, #c84826, #c22a00) repeat-x;
  border-color: #b52700;
  box-shadow: 0 0 0 0.2rem rgba(235, 81, 38, 0.5);
}

.module-inverted-chili:active, .module-inverted-chili.active,
.show > .module-inverted-chili.dropdown-toggle {
  color: #fff;
  background-color: #b52700;
  background-image: none;
  border-color: #a82400;
}

.module-inverted-chili:active:focus, .module-inverted-chili.active:focus,
.show > .module-inverted-chili.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 81, 38, 0.5);
}

.module-inverted-chili:disabled, .module-inverted-chili.disabled {
  color: #fff;
  background-color: #e83200;
  background-image: none;
  border-color: #e83200;
}

.module-inverted-pumpkin {
  color: #273a4d;
  background: #ff7a55 linear-gradient(180deg, #fc8c6e, #ff7a55) repeat-x;
  border-color: #ff7a55;
}

.module-inverted-pumpkin .icon-container {
  background-color: #273a4d;
}

.module-inverted-pumpkin .icon-container * {
  color: #ff7a55;
}

.module-inverted-pumpkin:hover {
  color: #fff;
  background: #ff5c2f linear-gradient(180deg, #fc734d, #ff5c2f) repeat-x;
  border-color: #ff5222;
}

.module-inverted-pumpkin:focus, .module-inverted-pumpkin.focus {
  color: #fff;
  background: #ff5c2f linear-gradient(180deg, #fc734d, #ff5c2f) repeat-x;
  border-color: #ff5222;
  box-shadow: 0 0 0 0.2rem rgba(223, 112, 84, 0.5);
}

.module-inverted-pumpkin:active, .module-inverted-pumpkin.active,
.show > .module-inverted-pumpkin.dropdown-toggle {
  color: #fff;
  background-color: #ff5222;
  background-image: none;
  border-color: #ff4815;
}

.module-inverted-pumpkin:active:focus, .module-inverted-pumpkin.active:focus,
.show > .module-inverted-pumpkin.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 112, 84, 0.5);
}

.module-inverted-pumpkin:disabled, .module-inverted-pumpkin.disabled {
  color: #273a4d;
  background-color: #ff7a55;
  background-image: none;
  border-color: #ff7a55;
}

.module-inverted-salmon {
  color: #273a4d;
  background: #ffb5a1 linear-gradient(180deg, #fcbfae, #ffb5a1) repeat-x;
  border-color: #ffb5a1;
}

.module-inverted-salmon .icon-container {
  background-color: #273a4d;
}

.module-inverted-salmon .icon-container * {
  color: #ffb5a1;
}

.module-inverted-salmon:hover {
  color: #273a4d;
  background: #ff977b linear-gradient(180deg, #fca58e, #ff977b) repeat-x;
  border-color: #ff8d6e;
}

.module-inverted-salmon:focus, .module-inverted-salmon.focus {
  color: #273a4d;
  background: #ff977b linear-gradient(180deg, #fca58e, #ff977b) repeat-x;
  border-color: #ff8d6e;
  box-shadow: 0 0 0 0.2rem rgba(223, 163, 148, 0.5);
}

.module-inverted-salmon:active, .module-inverted-salmon.active,
.show > .module-inverted-salmon.dropdown-toggle {
  color: #273a4d;
  background-color: #ff8d6e;
  background-image: none;
  border-color: #ff8361;
}

.module-inverted-salmon:active:focus, .module-inverted-salmon.active:focus,
.show > .module-inverted-salmon.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 163, 148, 0.5);
}

.module-inverted-salmon:disabled, .module-inverted-salmon.disabled {
  color: #273a4d;
  background-color: #ffb5a1;
  background-image: none;
  border-color: #ffb5a1;
}

.module-inverted-eggplant {
  color: #fff;
  background: #401c34 linear-gradient(180deg, #5a3d52, #401c34) repeat-x;
  border-color: #401c34;
}

.module-inverted-eggplant .icon-container {
  background-color: #273a4d;
}

.module-inverted-eggplant .icon-container * {
  color: #401c34;
}

.module-inverted-eggplant:hover {
  color: #fff;
  background: #25101e linear-gradient(180deg, #43333f, #25101e) repeat-x;
  border-color: #1d0c17;
}

.module-inverted-eggplant:focus, .module-inverted-eggplant.focus {
  color: #fff;
  background: #25101e linear-gradient(180deg, #43333f, #25101e) repeat-x;
  border-color: #1d0c17;
  box-shadow: 0 0 0 0.2rem rgba(93, 62, 82, 0.5);
}

.module-inverted-eggplant:active, .module-inverted-eggplant.active,
.show > .module-inverted-eggplant.dropdown-toggle {
  color: #fff;
  background-color: #1d0c17;
  background-image: none;
  border-color: #140910;
}

.module-inverted-eggplant:active:focus, .module-inverted-eggplant.active:focus,
.show > .module-inverted-eggplant.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(93, 62, 82, 0.5);
}

.module-inverted-eggplant:disabled, .module-inverted-eggplant.disabled {
  color: #fff;
  background-color: #401c34;
  background-image: none;
  border-color: #401c34;
}

.module-inverted-plum {
  color: #fff;
  background: #6a114d linear-gradient(180deg, #7e3367, #6a114d) repeat-x;
  border-color: #6a114d;
}

.module-inverted-plum .icon-container {
  background-color: #273a4d;
}

.module-inverted-plum .icon-container * {
  color: #6a114d;
}

.module-inverted-plum:hover {
  color: #fff;
  background: #490c35 linear-gradient(180deg, #622f53, #490c35) repeat-x;
  border-color: #3e0a2d;
}

.module-inverted-plum:focus, .module-inverted-plum.focus {
  color: #fff;
  background: #490c35 linear-gradient(180deg, #622f53, #490c35) repeat-x;
  border-color: #3e0a2d;
  box-shadow: 0 0 0 0.2rem rgba(128, 53, 104, 0.5);
}

.module-inverted-plum:active, .module-inverted-plum.active,
.show > .module-inverted-plum.dropdown-toggle {
  color: #fff;
  background-color: #3e0a2d;
  background-image: none;
  border-color: #330825;
}

.module-inverted-plum:active:focus, .module-inverted-plum.active:focus,
.show > .module-inverted-plum.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 53, 104, 0.5);
}

.module-inverted-plum:disabled, .module-inverted-plum.disabled {
  color: #fff;
  background-color: #6a114d;
  background-image: none;
  border-color: #6a114d;
}

.module-inverted-ruby {
  color: #fff;
  background: #9b0e6c linear-gradient(180deg, #a73181, #9b0e6c) repeat-x;
  border-color: #9b0e6c;
}

.module-inverted-ruby .icon-container {
  background-color: #273a4d;
}

.module-inverted-ruby .icon-container * {
  color: #9b0e6c;
}

.module-inverted-ruby:hover {
  color: #fff;
  background: #780b54 linear-gradient(180deg, #8a2e6d, #780b54) repeat-x;
  border-color: #6c0a4b;
}

.module-inverted-ruby:focus, .module-inverted-ruby.focus {
  color: #fff;
  background: #780b54 linear-gradient(180deg, #8a2e6d, #780b54) repeat-x;
  border-color: #6c0a4b;
  box-shadow: 0 0 0 0.2rem rgba(170, 50, 130, 0.5);
}

.module-inverted-ruby:active, .module-inverted-ruby.active,
.show > .module-inverted-ruby.dropdown-toggle {
  color: #fff;
  background-color: #6c0a4b;
  background-image: none;
  border-color: #610943;
}

.module-inverted-ruby:active:focus, .module-inverted-ruby.active:focus,
.show > .module-inverted-ruby.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(170, 50, 130, 0.5);
}

.module-inverted-ruby:disabled, .module-inverted-ruby.disabled {
  color: #fff;
  background-color: #9b0e6c;
  background-image: none;
  border-color: #9b0e6c;
}

.module-inverted-berry {
  color: #fff;
  background: #d60590 linear-gradient(180deg, #da29a0, #d60590) repeat-x;
  border-color: #d60590;
}

.module-inverted-berry .icon-container {
  background-color: #273a4d;
}

.module-inverted-berry .icon-container * {
  color: #d60590;
}

.module-inverted-berry:hover {
  color: #fff;
  background: #b10477 linear-gradient(180deg, #ba288b, #b10477) repeat-x;
  border-color: #a4046e;
}

.module-inverted-berry:focus, .module-inverted-berry.focus {
  color: #fff;
  background: #b10477 linear-gradient(180deg, #ba288b, #b10477) repeat-x;
  border-color: #a4046e;
  box-shadow: 0 0 0 0.2rem rgba(220, 43, 161, 0.5);
}

.module-inverted-berry:active, .module-inverted-berry.active,
.show > .module-inverted-berry.dropdown-toggle {
  color: #fff;
  background-color: #a4046e;
  background-image: none;
  border-color: #980466;
}

.module-inverted-berry:active:focus, .module-inverted-berry.active:focus,
.show > .module-inverted-berry.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 43, 161, 0.5);
}

.module-inverted-berry:disabled, .module-inverted-berry.disabled {
  color: #fff;
  background-color: #d60590;
  background-image: none;
  border-color: #d60590;
}

.module-inverted-fuchsia {
  color: #fff;
  background: #ff46c1 linear-gradient(180deg, #fc60ca, #ff46c1) repeat-x;
  border-color: #ff46c1;
}

.module-inverted-fuchsia .icon-container {
  background-color: #273a4d;
}

.module-inverted-fuchsia .icon-container * {
  color: #ff46c1;
}

.module-inverted-fuchsia:hover {
  color: #fff;
  background: #ff20b4 linear-gradient(180deg, #fc40bf, #ff20b4) repeat-x;
  border-color: #ff13b0;
}

.module-inverted-fuchsia:focus, .module-inverted-fuchsia.focus {
  color: #fff;
  background: #ff20b4 linear-gradient(180deg, #fc40bf, #ff20b4) repeat-x;
  border-color: #ff13b0;
  box-shadow: 0 0 0 0.2rem rgba(255, 98, 202, 0.5);
}

.module-inverted-fuchsia:active, .module-inverted-fuchsia.active,
.show > .module-inverted-fuchsia.dropdown-toggle {
  color: #fff;
  background-color: #ff13b0;
  background-image: none;
  border-color: #ff06ac;
}

.module-inverted-fuchsia:active:focus, .module-inverted-fuchsia.active:focus,
.show > .module-inverted-fuchsia.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 98, 202, 0.5);
}

.module-inverted-fuchsia:disabled, .module-inverted-fuchsia.disabled {
  color: #fff;
  background-color: #ff46c1;
  background-image: none;
  border-color: #ff46c1;
}

.module-inverted-flamingo {
  color: #273a4d;
  background: #ffa1e0 linear-gradient(180deg, #fcaee4, #ffa1e0) repeat-x;
  border-color: #ffa1e0;
}

.module-inverted-flamingo .icon-container {
  background-color: #273a4d;
}

.module-inverted-flamingo .icon-container * {
  color: #ffa1e0;
}

.module-inverted-flamingo:hover {
  color: #273a4d;
  background: #ff7bd3 linear-gradient(180deg, #fc8dd9, #ff7bd3) repeat-x;
  border-color: #ff6ecf;
}

.module-inverted-flamingo:focus, .module-inverted-flamingo.focus {
  color: #273a4d;
  background: #ff7bd3 linear-gradient(180deg, #fc8dd9, #ff7bd3) repeat-x;
  border-color: #ff6ecf;
  box-shadow: 0 0 0 0.2rem rgba(223, 146, 202, 0.5);
}

.module-inverted-flamingo:active, .module-inverted-flamingo.active,
.show > .module-inverted-flamingo.dropdown-toggle {
  color: #273a4d;
  background-color: #ff6ecf;
  background-image: none;
  border-color: #ff61cb;
}

.module-inverted-flamingo:active:focus, .module-inverted-flamingo.active:focus,
.show > .module-inverted-flamingo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 146, 202, 0.5);
}

.module-inverted-flamingo:disabled, .module-inverted-flamingo.disabled {
  color: #273a4d;
  background-color: #ffa1e0;
  background-image: none;
  border-color: #ffa1e0;
}

.module-inverted-raisin {
  color: #fff;
  background: #371c40 linear-gradient(180deg, #523d5c, #371c40) repeat-x;
  border-color: #371c40;
}

.module-inverted-raisin .icon-container {
  background-color: #273a4d;
}

.module-inverted-raisin .icon-container * {
  color: #371c40;
}

.module-inverted-raisin:hover {
  color: #fff;
  background: #201025 linear-gradient(180deg, #3f3345, #201025) repeat-x;
  border-color: #190c1d;
}

.module-inverted-raisin:focus, .module-inverted-raisin.focus {
  color: #fff;
  background: #201025 linear-gradient(180deg, #3f3345, #201025) repeat-x;
  border-color: #190c1d;
  box-shadow: 0 0 0 0.2rem rgba(85, 62, 93, 0.5);
}

.module-inverted-raisin:active, .module-inverted-raisin.active,
.show > .module-inverted-raisin.dropdown-toggle {
  color: #fff;
  background-color: #190c1d;
  background-image: none;
  border-color: #110914;
}

.module-inverted-raisin:active:focus, .module-inverted-raisin.active:focus,
.show > .module-inverted-raisin.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(85, 62, 93, 0.5);
}

.module-inverted-raisin:disabled, .module-inverted-raisin.disabled {
  color: #fff;
  background-color: #371c40;
  background-image: none;
  border-color: #371c40;
}

.module-inverted-grape {
  color: #fff;
  background: #54116a linear-gradient(180deg, #6b3380, #54116a) repeat-x;
  border-color: #54116a;
}

.module-inverted-grape .icon-container {
  background-color: #273a4d;
}

.module-inverted-grape .icon-container * {
  color: #54116a;
}

.module-inverted-grape:hover {
  color: #fff;
  background: #3a0c49 linear-gradient(180deg, #552f64, #3a0c49) repeat-x;
  border-color: #310a3e;
}

.module-inverted-grape:focus, .module-inverted-grape.focus {
  color: #fff;
  background: #3a0c49 linear-gradient(180deg, #552f64, #3a0c49) repeat-x;
  border-color: #310a3e;
  box-shadow: 0 0 0 0.2rem rgba(110, 53, 128, 0.5);
}

.module-inverted-grape:active, .module-inverted-grape.active,
.show > .module-inverted-grape.dropdown-toggle {
  color: #fff;
  background-color: #310a3e;
  background-image: none;
  border-color: #280833;
}

.module-inverted-grape:active:focus, .module-inverted-grape.active:focus,
.show > .module-inverted-grape.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(110, 53, 128, 0.5);
}

.module-inverted-grape:disabled, .module-inverted-grape.disabled {
  color: #fff;
  background-color: #54116a;
  background-image: none;
  border-color: #54116a;
}

.module-inverted-lavendar {
  color: #fff;
  background: #7d0fa2 linear-gradient(180deg, #8e32af, #7d0fa2) repeat-x;
  border-color: #7d0fa2;
}

.module-inverted-lavendar .icon-container {
  background-color: #273a4d;
}

.module-inverted-lavendar .icon-container * {
  color: #7d0fa2;
}

.module-inverted-lavendar:hover {
  color: #fff;
  background: #620c7f linear-gradient(180deg, #772f91, #620c7f) repeat-x;
  border-color: #590b73;
}

.module-inverted-lavendar:focus, .module-inverted-lavendar.focus {
  color: #fff;
  background: #620c7f linear-gradient(180deg, #772f91, #620c7f) repeat-x;
  border-color: #590b73;
  box-shadow: 0 0 0 0.2rem rgba(145, 51, 176, 0.5);
}

.module-inverted-lavendar:active, .module-inverted-lavendar.active,
.show > .module-inverted-lavendar.dropdown-toggle {
  color: #fff;
  background-color: #590b73;
  background-image: none;
  border-color: #500a68;
}

.module-inverted-lavendar:active:focus, .module-inverted-lavendar.active:focus,
.show > .module-inverted-lavendar.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(145, 51, 176, 0.5);
}

.module-inverted-lavendar:disabled, .module-inverted-lavendar.disabled {
  color: #fff;
  background-color: #7d0fa2;
  background-image: none;
  border-color: #7d0fa2;
}

.module-inverted-amethyst {
  color: #fff;
  background: #9d02d0 linear-gradient(180deg, #a926d6, #9d02d0) repeat-x;
  border-color: #9d02d0;
}

.module-inverted-amethyst .icon-container {
  background-color: #273a4d;
}

.module-inverted-amethyst .icon-container * {
  color: #9d02d0;
}

.module-inverted-amethyst:hover {
  color: #fff;
  background: #8002aa linear-gradient(180deg, #9126b6, #8002aa) repeat-x;
  border-color: #77029d;
}

.module-inverted-amethyst:focus, .module-inverted-amethyst.focus {
  color: #fff;
  background: #8002aa linear-gradient(180deg, #9126b6, #8002aa) repeat-x;
  border-color: #77029d;
  box-shadow: 0 0 0 0.2rem rgba(172, 40, 215, 0.5);
}

.module-inverted-amethyst:active, .module-inverted-amethyst.active,
.show > .module-inverted-amethyst.dropdown-toggle {
  color: #fff;
  background-color: #77029d;
  background-image: none;
  border-color: #6d0191;
}

.module-inverted-amethyst:active:focus, .module-inverted-amethyst.active:focus,
.show > .module-inverted-amethyst.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(172, 40, 215, 0.5);
}

.module-inverted-amethyst:disabled, .module-inverted-amethyst.disabled {
  color: #fff;
  background-color: #9d02d0;
  background-image: none;
  border-color: #9d02d0;
}

.module-inverted-orchid {
  color: #fff;
  background: #c557e9 linear-gradient(180deg, #cb6fec, #c557e9) repeat-x;
  border-color: #c557e9;
}

.module-inverted-orchid .icon-container {
  background-color: #273a4d;
}

.module-inverted-orchid .icon-container * {
  color: #c557e9;
}

.module-inverted-orchid:hover {
  color: #fff;
  background: #b935e5 linear-gradient(180deg, #c152e8, #b935e5) repeat-x;
  border-color: #b52ae3;
}

.module-inverted-orchid:focus, .module-inverted-orchid.focus {
  color: #fff;
  background: #b935e5 linear-gradient(180deg, #c152e8, #b935e5) repeat-x;
  border-color: #b52ae3;
  box-shadow: 0 0 0 0.2rem rgba(206, 112, 236, 0.5);
}

.module-inverted-orchid:active, .module-inverted-orchid.active,
.show > .module-inverted-orchid.dropdown-toggle {
  color: #fff;
  background-color: #b52ae3;
  background-image: none;
  border-color: #b21fe2;
}

.module-inverted-orchid:active:focus, .module-inverted-orchid.active:focus,
.show > .module-inverted-orchid.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 112, 236, 0.5);
}

.module-inverted-orchid:disabled, .module-inverted-orchid.disabled {
  color: #fff;
  background-color: #c557e9;
  background-image: none;
  border-color: #c557e9;
}

.module-inverted-thistle {
  color: #273a4d;
  background: #e8a1ff linear-gradient(180deg, #e9aefe, #e8a1ff) repeat-x;
  border-color: #e8a1ff;
}

.module-inverted-thistle .icon-container {
  background-color: #273a4d;
}

.module-inverted-thistle .icon-container * {
  color: #e8a1ff;
}

.module-inverted-thistle:hover {
  color: #273a4d;
  background: #df7bff linear-gradient(180deg, #e18dfe, #df7bff) repeat-x;
  border-color: #dc6eff;
}

.module-inverted-thistle:focus, .module-inverted-thistle.focus {
  color: #273a4d;
  background: #df7bff linear-gradient(180deg, #e18dfe, #df7bff) repeat-x;
  border-color: #dc6eff;
  box-shadow: 0 0 0 0.2rem rgba(203, 146, 228, 0.5);
}

.module-inverted-thistle:active, .module-inverted-thistle.active,
.show > .module-inverted-thistle.dropdown-toggle {
  color: #273a4d;
  background-color: #dc6eff;
  background-image: none;
  border-color: #d861ff;
}

.module-inverted-thistle:active:focus, .module-inverted-thistle.active:focus,
.show > .module-inverted-thistle.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(203, 146, 228, 0.5);
}

.module-inverted-thistle:disabled, .module-inverted-thistle.disabled {
  color: #273a4d;
  background-color: #e8a1ff;
  background-image: none;
  border-color: #e8a1ff;
}

.module-inverted-magenta {
  color: #fff;
  background: #ff0080 linear-gradient(180deg, #fc2592, #ff0080) repeat-x;
  border-color: #ff0080;
}

.module-inverted-magenta .icon-container {
  background-color: #273a4d;
}

.module-inverted-magenta .icon-container * {
  color: #ff0080;
}

.module-inverted-magenta:hover {
  color: #fff;
  background: #d9006d linear-gradient(180deg, #dc2582, #d9006d) repeat-x;
  border-color: #cc0066;
}

.module-inverted-magenta:focus, .module-inverted-magenta.focus {
  color: #fff;
  background: #d9006d linear-gradient(180deg, #dc2582, #d9006d) repeat-x;
  border-color: #cc0066;
  box-shadow: 0 0 0 0.2rem rgba(255, 38, 147, 0.5);
}

.module-inverted-magenta:active, .module-inverted-magenta.active,
.show > .module-inverted-magenta.dropdown-toggle {
  color: #fff;
  background-color: #cc0066;
  background-image: none;
  border-color: #bf0060;
}

.module-inverted-magenta:active:focus, .module-inverted-magenta.active:focus,
.show > .module-inverted-magenta.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 38, 147, 0.5);
}

.module-inverted-magenta:disabled, .module-inverted-magenta.disabled {
  color: #fff;
  background-color: #ff0080;
  background-image: none;
  border-color: #ff0080;
}
/*# sourceMappingURL=industrial-modules.css.map */