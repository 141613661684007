@import "bootstrap/functions";

@import "colors";
@import "variables";
@import "bootstrap/variables";
@import "bootstrap/mixins";

@import "mixins/module";

@import "bootstrap/utilities/borders";

.module-container {

  [class^="icon-"],
  [class*=" icon-"] {
    vertical-align: baseline;
  }

  .row {
    margin-right: 0;
    margin-left: 0;

    [class^="col-"],
    [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  padding: 2px;
  margin-right: auto;
  margin-left: auto;

  .module-categorie-frame {
    padding: 2px;

    .module-categorie {
      //margin: 2px;
      font-family: khand, Arial, Helvetica, sans-serif;
      background-color: $contrast0perBlack;
      border: 5px solid $contrast0perBlack;
      border-radius: 18px;

      .header {
        padding-left: 5px;
        font-size: $module-categorie-font-size;
        font-weight: 600;
        text-transform: uppercase;
      }

      .body {
        padding: 3px;
        background-color: $elements3lightBlue;
        border-radius: $module-categorie-border-radius;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
      }

      .header+.body {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}

.module-frame {
  width: 100%;
  height: ($module-unit) + (2 * $module-frame-padding);
  padding: $module-frame-padding;
  display: block;
  position: relative;

  &:not(:disabled):not(.disabled) {
    cursor: if($enable-pointer-cursor-for-buttons, pointer, null);
  }

  &.disabled {
    cursor: not-allowed;
  }

  &:hover {
    text-decoration: none;
    text-decoration-line: none;
  }


  &.h-1 {
    &.centered {
      .icon-container {
        width: $module-centered-h-1-icon-container-size;
        height: $module-centered-h-1-icon-container-size;
        font-size: $module-centered-h-1-icon-size;
      }

      .title {
        font-size: $module-centered-h-1-title-size;
      }

      .module {
        padding: $module-centered-h-1-padding;
      }
    }

    &.left-aligned {
      .icon-container {
        display: block;
        width: $module-left-aligned-h-1-icon-container-size;
        min-width: $module-left-aligned-h-1-icon-container-size;
        height: $module-left-aligned-h-1-icon-container-size;
        margin-right: $module-left-aligned-h-1-padding;
        margin-left: unset;
        font-size: $module-left-aligned-h-1-icon-size;
      }

      .title {
        display: block;
        font-size: $module-left-aligned-h-1-title-size;
        text-align: left;
      }

      .module {
        display: flex;
        align-items: center;
        padding: $module-left-aligned-h-1-padding;
        text-align: left;
      }
    }
  }

  &.h-2 {
    height: ($module-unit * 2) + ($module-frame-padding * 4);

    .icon-container {
      width: $module-centered-h-2-icon-container-size;
      height: $module-centered-h-2-icon-container-size;
      margin-bottom: 10px;
      font-size: $module-centered-h-2-icon-size;
    }

    .module {
      padding: $module-centered-h-2-padding;
    }

    .title {
      font-size: $module-centered-h-2-title-size;
    }
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    .module {
      // stylelint-disable-line selector-no-qualifying-type
      pointer-events: none;
      opacity: $btn-disabled-opacity;
      @include box-shadow(none);
    }
  }

  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      &.module-frame#{$infix}-h-1-centered {
        .icon-container {
          width: $module-centered-h-1-icon-container-size;
          height: $module-centered-h-1-icon-container-size;
          font-size: $module-centered-h-1-icon-size;
        }

        .title {
          font-size: $module-centered-h-1-title-size;
        }

        .module {
          padding: $module-centered-h-1-padding;
        }
      }

      &.module-frame#{$infix}-h-1-left-aligned {
        .icon-container {
          display: block;
          width: $module-left-aligned-h-1-icon-container-size;
          min-width: $module-left-aligned-h-1-icon-container-size;
          height: $module-left-aligned-h-1-icon-container-size;
          margin-right: $module-left-aligned-h-1-padding;
          margin-left: unset;
          font-size: $module-left-aligned-h-1-icon-size;
        }

        .title {
          display: block;
          font-size: $module-left-aligned-h-1-title-size;
          text-align: left;
        }

        .module {
          display: flex;
          align-items: center;
          padding: $module-left-aligned-h-1-padding;
          text-align: left;
        }
      }

      &.module-frame#{$infix}-h-2 {
        height: ($module-unit * 2) + ($module-frame-padding);

        .icon-container {
          width: $module-centered-h-2-icon-container-size;
          height: $module-centered-h-2-icon-container-size;
          margin-right: auto;
          margin-bottom: 10px;
          margin-left: auto;
          font-size: $module-centered-h-2-icon-size;
        }

        .module {
          display: block;
          padding: $module-centered-h-2-padding;
          text-align: center;
        }

        .title {
          display: block;
          font-size: $module-centered-h-2-title-size;
          text-align: center;
        }
      }
    }
  }
}


.module-dummy {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: $module-border-radius;
  background-color: $contrast0perBlack;
}

.module {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px;
  font-family: $btn-font-family;
  font-size: $module-left-aligned-h-1-title-size;
  font-weight: $btn-font-weight;
  line-height: $btn-line-height;
  text-align: center;
  text-decoration: if($link-decoration ==none, null, none);
  white-space: $btn-white-space;
  user-select: none;
  border: $btn-border-width solid transparent;
  border-radius: $module-border-radius;

  .icon-container {
    position: relative;
    width: 80px;
    height: 80px;
    margin-right: auto;
    margin-left: auto;
    font-size: 40px;
    text-align: center;
    @extend .rounded-circle;

    .badge.module-badge {
      position: absolute;
      top: $module-badge-position-top;
      left: $module-badge-position-left;
      font-size: $module-badge-font-size;

    }

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }


  .title {
    font-family: khand, Arial, Helvetica, sans-serif;
    font-size: $module-left-aligned-h-1-title-size;
    font-weight: 600;
    text-transform: uppercase;
  }

  &:hover {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  &:active,
  &.active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }
}

.module-informations {
  font-size: $module-information-font-size;
  text-align: center;
  font-weight: bold;
  width: subtract(100%, $module-frame-padding * 2);
  position: absolute;
  bottom: $module-frame-padding + 1px;
  left: $module-frame-padding;
  right: $module-frame-padding;
  display: flex;

  .module-information {
    margin-left: $module-information-space;
    margin-right: $module-information-space;
    background-color: rgba(255, 255, 255, 0.9);

    &:first-child {
      border-bottom-left-radius: $module-border-radius;
    }

    &:last-child {
      border-bottom-right-radius: $module-border-radius;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

@each $color,
$value in $module-colors {
  .module-#{$color} {
    @include module-variant($value, $value);
  }

  @each $variant,
  $opacity in $colors-variants {
    .module-#{$color}-#{$variant} {
      @include module-variant(rgba($value, $opacity), rgba($value, $opacity));
    }
  }
}

@each $color,
$value in $contrast-colors {
  .module-#{$color} {
    @include module-variant($value, $value);
  }
}

@each $color,
$value in $module-colors {
  .module-outline-#{$color} {
    @include module-outline-variant($value);
  }
}

@each $color,
$value in $module-colors {
  .module-inverted-#{$color} {
    @include module-inverted-variant($value, $value);
  }
}
